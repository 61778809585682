import "./csrf-xhr";
import Headroom from "headroom.js";
const storageKey = "store";
const html = document.querySelector("html");
const data = html.dataset;

// Prepare GTM tracking
window.dataLayer = window.dataLayer ?? [];

var csrfTokenNode, csrfToken;

try {
    csrfTokenNode = document.head.querySelector('meta[name="csrf-token"]');
} catch (e) {
    // ignore document-based errors
    csrfTokenNode = null;
}

if (csrfTokenNode !== null && typeof csrfTokenNode.content === "string") {
    csrfToken = csrfTokenNode.content;
} else {
    console.warn(
        "csrf-xhr was unable to add a X-CSRF-Token header to a " +
            method +
            " to " +
            url +
            ' because when csrf-xhr originally loaded, it could not find a <meta> element in the <head> with name="csrf-token" and a Laravel CSRF token in a content attribute.'
    );
}

let user = JSON.parse(data.user);

const d = new Date();

let flags = {
    user: user,
    categories: JSON.parse(data.categories),
    csrfToken: csrfToken,
    openingHours: data.openingHours,
    timezoneOffset: -d.getTimezoneOffset(),
    time: d.getTime()
};

if (user) {
    if (user.search_history) {
        flags.search_history = user.search_history;
        delete user.search_history;
    }

    // Add user_id to GTM
    window.dataLayer.push({ user_id: user.id });

    // add user to Rollbar
    if (window.hasOwnProperty("Rollbar")) {
        Rollbar.configure({
            payload: { person: { id: user.id } }
        });
    }
} else {
    const currentStorage = JSON.parse(localStorage.getItem(storageKey));

    if (currentStorage?.layout) {
        flags.layout = currentStorage.layout;
    }
}

html.removeAttribute("data-user");
html.removeAttribute("data-categories");

updateStorage(flags);

import { Elm } from "../elm/app/src/Main.elm";
const app = Elm.Main.init({ flags });

// PORTS

if (app?.ports?.onSessionChange) {
    app.ports.storeSession.subscribe(function(val) {
        let newStorage = null;
        if (val === null) {
            localStorage.removeItem(storageKey);
        } else {
            // Update values, do not replace them
            newStorage = updateStorage(val);
        }
        // Report that the new session was stored succesfully.
        setTimeout(function() {
            app.ports.onSessionChange.send(newStorage);
        }, 0);
    });
    // Whenever localStorage changes in another tab, report it if necessary.
    window.addEventListener(
        "storage",
        function(event) {
            if (
                event.storageArea === localStorage &&
                event.key === storageKey
            ) {
                app.ports.onSessionChange.send(JSON.parse(event.newValue));
            }
        },
        false
    );
}

function updateStorage(storage) {
    const currentStorage = JSON.parse(localStorage.getItem(storageKey));
    const newStorage = Object.assign({}, currentStorage, storage);
    localStorage.setItem(storageKey, JSON.stringify(newStorage));
    return newStorage;
}

app.ports.updateMeta.subscribe(function(data) {
    document.querySelector('meta[name="description"]').content =
        data.description;
    document.querySelector('meta[name="og:image"]').content = data.image;
});

if (app?.ports?.trackAnalytics) {
    app.ports.trackAnalytics.subscribe(payload => {
        if (payload.reset && payload.reset === true) {
            window.dataLayer.push({ ecommerce: null });
            delete payload.reset;
        }

        window.dataLayer.push(payload);
    });
}

if (app?.ports?.trackError && window.hasOwnProperty("Rollbar")) {
    app.ports.trackError.subscribe(data => {
        Rollbar.error(data.message, data.payload);
    });
}

// COOKIEPRO

// listen for cookie consent changes to display placeholder messages for embeds from hosts without consent
window.OptanonWrapper = function() {
    if (
        app?.ports?.onCookieConsentChange &&
        window.Optanon?.GetDomainData?.().Groups &&
        OptanonActiveGroups
    ) {
        app.ports.onCookieConsentChange.send({
            allGroups: window.Optanon.GetDomainData().Groups,
            activeGroupIdsString: OptanonActiveGroups
        });
    }
};

document.body.addEventListener("click", function(ev) {
    if (
        ev.target.classList.contains("se-custom-ot-sdk-show-settings") &&
        window.OneTrust
    ) {
        OneTrust.ToggleInfoDisplay();
    }
});

// HEADROOM

window.addEventListener("DOMContentLoaded", function(ev) {
    const header = document.querySelector("header");
    if (null !== header && Headroom.cutsTheMustard) {
        new Headroom(header, { tolerance: 25 }).init();
    }
});
