(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.o.bS === region.i.bS)
	{
		return 'on line ' + region.o.bS;
	}
	return 'on lines ' + region.o.bS + ' through ' + region.i.bS;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hB,
		impl.iU,
		impl.iL,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		fx: func(record.fx),
		d9: record.d9,
		d_: record.d_
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.fx;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.d9;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.d_) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hB,
		impl.iU,
		impl.iL,
		function(sendToApp, initialModel) {
			var view = impl.iZ;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hB,
		impl.iU,
		impl.iL,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.d5 && impl.d5(sendToApp)
			var view = impl.iZ;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bI);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.gr) && (_VirtualDom_doc.title = title = doc.gr);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.h5;
	var onUrlRequest = impl.h6;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		d5: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.fV === next.fV
							&& curr.hv === next.hv
							&& curr.fL.a === next.fL.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		hB: function(flags)
		{
			return A3(impl.hB, flags, _Browser_getUrl(), key);
		},
		iZ: impl.iZ,
		iU: impl.iU,
		iL: impl.iL
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { ht: 'hidden', gW: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { ht: 'mozHidden', gW: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { ht: 'msHidden', gW: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { ht: 'webkitHidden', gW: 'webkitvisibilitychange' }
		: { ht: 'hidden', gW: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		f6: _Browser_getScene(),
		gD: {
			dg: _Browser_window.pageXOffset,
			dh: _Browser_window.pageYOffset,
			gE: _Browser_doc.documentElement.clientWidth,
			e7: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		gE: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		e7: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			f6: {
				gE: node.scrollWidth,
				e7: node.scrollHeight
			},
			gD: {
				dg: node.scrollLeft,
				dh: node.scrollTop,
				gE: node.clientWidth,
				e7: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			f6: _Browser_getScene(),
			gD: {
				dg: x,
				dh: y,
				gE: _Browser_doc.documentElement.clientWidth,
				e7: _Browser_doc.documentElement.clientHeight
			},
			hb: {
				dg: x + rect.left,
				dh: y + rect.top,
				gE: rect.width,
				e7: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.bN.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.bN.b, xhr)); });
		$elm$core$Maybe$isJust(request.cc) && _Http_track(router, xhr, request.cc.a);

		try {
			xhr.open(request.bW, request.cg, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.cg));
		}

		_Http_configureRequest(xhr, request);

		request.bI.a && xhr.setRequestHeader('Content-Type', request.bI.a);
		xhr.send(request.bI.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bQ; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.b7.a || 0;
	xhr.responseType = request.bN.d;
	xhr.withCredentials = request.gL;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		cg: xhr.responseURL,
		gi: xhr.status,
		iJ: xhr.statusText,
		bQ: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			iE: event.loaded,
			d6: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			io: event.loaded,
			d6: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.hS) { flags += 'm'; }
	if (options.gU) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Main$ChangedUrl = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$ClickedLink = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.v) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.x),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.x);
		} else {
			var treeLen = builder.v * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.z) : builder.z;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.v);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.x) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.x);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{z: nodeList, v: (len / $elm$core$Array$branchFactor) | 0, x: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {e3: fragment, hv: host, fI: path, fL: port_, fV: protocol, im: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Main$DecodeError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Main$Redirect = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Account = function (a) {
	return {$: 16, a: a};
};
var $author$project$Main$AccountAdministration = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$AccountPassword = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$AccountProfile = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$AccountSettings = function (a) {
	return {$: 18, a: a};
};
var $author$project$Main$Bid = function (a) {
	return {$: 24, a: a};
};
var $author$project$Main$Bids = function (a) {
	return {$: 23, a: a};
};
var $author$project$Route$BrandService = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$Brands = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$Checkout = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$Content = function (a) {
	return {$: 21, a: a};
};
var $author$project$Route$DeliveryTerms = {$: 2};
var $author$project$Main$GotAccountAdministrationMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$GotAccountMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$Main$GotAccountPasswordMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Main$GotAccountProfileMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$GotAccountSettingsMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$GotBidMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$GotBidsMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$GotBrandsMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$GotCheckoutMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$GotContentMsg = function (a) {
	return {$: 23, a: a};
};
var $author$project$Main$GotHomeMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$GotInvoicesMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$GotLoginMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$GotMainCategoryMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotNotFoundMsg = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$GotOrderMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotOrdersMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$GotProductMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotProductsMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotRequestNewPasswordMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$GotResetPasswordMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$GotServiceMsg = function (a) {
	return {$: 24, a: a};
};
var $author$project$Main$GotThankYouMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Main$Home = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$Ignored = {$: 0};
var $author$project$Main$Invoices = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$Login = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$MainCategory = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$NotFound = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Order = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$Orders = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$Product = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$Products = function (a) {
	return {$: 5, a: a};
};
var $author$project$Route$RMA = {$: 1};
var $author$project$Main$RequestNewPassword = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$ResetPassword = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$Service = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$ThankYou = function (a) {
	return {$: 14, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Session$userInterface = function (session) {
	if (!session.$) {
		var ui = session.c;
		return ui;
	} else {
		var ui = session.b;
		return ui;
	}
};
var $author$project$Session$categories = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$userInterface,
	function ($) {
		return $.gV;
	});
var $author$project$SE$UI$Navbar$V2$defaultModel = {ai: '', aw: false};
var $author$project$Session$Guest = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Session$LoggedIn = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Session$mapUI = F2(
	function (transform, session) {
		if (!session.$) {
			var key = session.a;
			var u = session.b;
			var ui = session.c;
			return A3(
				$author$project$Session$LoggedIn,
				key,
				u,
				transform(ui));
		} else {
			var key = session.a;
			var ui = session.b;
			return A2(
				$author$project$Session$Guest,
				key,
				transform(ui));
		}
	});
var $author$project$Session$closeNavbar = $author$project$Session$mapUI(
	function (ui) {
		return _Utils_update(
			ui,
			{bX: $author$project$SE$UI$Navbar$V2$defaultModel});
	});
var $author$project$Helpers$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $zwilias$elm_rosetree$Tree$label = function (_v0) {
	var v = _v0.a;
	return v;
};
var $zwilias$elm_rosetree$Tree$Zipper$tree = function (_v0) {
	var focus = _v0.hp;
	return focus;
};
var $zwilias$elm_rosetree$Tree$Zipper$label = function (zipper) {
	return $zwilias$elm_rosetree$Tree$label(
		$zwilias$elm_rosetree$Tree$Zipper$tree(zipper));
};
var $zwilias$elm_rosetree$Tree$Zipper$find = F3(
	function (predicate, move, zipper) {
		find:
		while (true) {
			var _v0 = move(zipper);
			if (!_v0.$) {
				var next = _v0.a;
				if (predicate(
					$zwilias$elm_rosetree$Tree$Zipper$label(next))) {
					return $elm$core$Maybe$Just(next);
				} else {
					var $temp$predicate = predicate,
						$temp$move = move,
						$temp$zipper = next;
					predicate = $temp$predicate;
					move = $temp$move;
					zipper = $temp$zipper;
					continue find;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	});
var $zwilias$elm_rosetree$Tree$Zipper$Zipper = $elm$core$Basics$identity;
var $zwilias$elm_rosetree$Tree$children = function (_v0) {
	var c = _v0.b;
	return c;
};
var $zwilias$elm_rosetree$Tree$Zipper$firstChild = function (_v0) {
	var zipper = _v0;
	var _v1 = $zwilias$elm_rosetree$Tree$children(zipper.hp);
	if (!_v1.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var c = _v1.a;
		var cs = _v1.b;
		return $elm$core$Maybe$Just(
			{
				l: cs,
				p: _List_Nil,
				w: A2(
					$elm$core$List$cons,
					{
						l: zipper.l,
						p: zipper.p,
						cB: $zwilias$elm_rosetree$Tree$label(zipper.hp)
					},
					zipper.w),
				hp: c
			});
	}
};
var $zwilias$elm_rosetree$Tree$Zipper$firstOf = F2(
	function (options, v) {
		firstOf:
		while (true) {
			if (!options.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var option = options.a;
				var rest = options.b;
				var _v1 = option(v);
				if (!_v1.$) {
					var r = _v1.a;
					return $elm$core$Maybe$Just(r);
				} else {
					var $temp$options = rest,
						$temp$v = v;
					options = $temp$options;
					v = $temp$v;
					continue firstOf;
				}
			}
		}
	});
var $zwilias$elm_rosetree$Tree$Zipper$nextSibling = function (_v0) {
	var zipper = _v0;
	var _v1 = zipper.l;
	if (!_v1.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var next = _v1.a;
		var rest = _v1.b;
		return $elm$core$Maybe$Just(
			{
				l: rest,
				p: A2($elm$core$List$cons, zipper.hp, zipper.p),
				w: zipper.w,
				hp: next
			});
	}
};
var $zwilias$elm_rosetree$Tree$Tree = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $zwilias$elm_rosetree$Tree$tree = $zwilias$elm_rosetree$Tree$Tree;
var $zwilias$elm_rosetree$Tree$Zipper$reconstruct = F4(
	function (focus, before, after, l) {
		return A2(
			$zwilias$elm_rosetree$Tree$tree,
			l,
			_Utils_ap(
				$elm$core$List$reverse(before),
				_Utils_ap(
					_List_fromArray(
						[focus]),
					after)));
	});
var $zwilias$elm_rosetree$Tree$Zipper$parent = function (_v0) {
	var zipper = _v0;
	var _v1 = zipper.w;
	if (!_v1.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var crumb = _v1.a;
		var rest = _v1.b;
		return $elm$core$Maybe$Just(
			{
				l: crumb.l,
				p: crumb.p,
				w: rest,
				hp: A4($zwilias$elm_rosetree$Tree$Zipper$reconstruct, zipper.hp, zipper.p, zipper.l, crumb.cB)
			});
	}
};
var $zwilias$elm_rosetree$Tree$Zipper$nextSiblingOfAncestor = function (zipper) {
	nextSiblingOfAncestor:
	while (true) {
		var _v0 = $zwilias$elm_rosetree$Tree$Zipper$parent(zipper);
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var parent_ = _v0.a;
			var _v1 = $zwilias$elm_rosetree$Tree$Zipper$nextSibling(parent_);
			if (_v1.$ === 1) {
				var $temp$zipper = parent_;
				zipper = $temp$zipper;
				continue nextSiblingOfAncestor;
			} else {
				var s = _v1.a;
				return $elm$core$Maybe$Just(s);
			}
		}
	}
};
var $zwilias$elm_rosetree$Tree$Zipper$forward = function (zipper) {
	return A2(
		$zwilias$elm_rosetree$Tree$Zipper$firstOf,
		_List_fromArray(
			[$zwilias$elm_rosetree$Tree$Zipper$firstChild, $zwilias$elm_rosetree$Tree$Zipper$nextSibling, $zwilias$elm_rosetree$Tree$Zipper$nextSiblingOfAncestor]),
		zipper);
};
var $zwilias$elm_rosetree$Tree$Zipper$findNext = F2(
	function (f, zipper) {
		return A3($zwilias$elm_rosetree$Tree$Zipper$find, f, $zwilias$elm_rosetree$Tree$Zipper$forward, zipper);
	});
var $zwilias$elm_rosetree$Tree$Zipper$previousSibling = function (_v0) {
	var zipper = _v0;
	var _v1 = zipper.p;
	if (!_v1.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var previous = _v1.a;
		var rest = _v1.b;
		return $elm$core$Maybe$Just(
			{
				l: A2($elm$core$List$cons, zipper.hp, zipper.l),
				p: rest,
				w: zipper.w,
				hp: previous
			});
	}
};
var $zwilias$elm_rosetree$Tree$Zipper$firstSibling = function (zipper) {
	firstSibling:
	while (true) {
		var _v0 = $zwilias$elm_rosetree$Tree$Zipper$previousSibling(zipper);
		if (_v0.$ === 1) {
			return zipper;
		} else {
			var z = _v0.a;
			var $temp$zipper = z;
			zipper = $temp$zipper;
			continue firstSibling;
		}
	}
};
var $zwilias$elm_rosetree$Tree$Zipper$root = function (zipper) {
	root:
	while (true) {
		var _v0 = $zwilias$elm_rosetree$Tree$Zipper$parent(zipper);
		if (_v0.$ === 1) {
			return $zwilias$elm_rosetree$Tree$Zipper$firstSibling(zipper);
		} else {
			var z = _v0.a;
			var $temp$zipper = z;
			zipper = $temp$zipper;
			continue root;
		}
	}
};
var $zwilias$elm_rosetree$Tree$Zipper$findFromRoot = F2(
	function (f, zipper) {
		var r = $zwilias$elm_rosetree$Tree$Zipper$root(zipper);
		return f(
			$zwilias$elm_rosetree$Tree$Zipper$label(r)) ? $elm$core$Maybe$Just(r) : A2($zwilias$elm_rosetree$Tree$Zipper$findNext, f, r);
	});
var $zwilias$elm_rosetree$Tree$Zipper$fromTree = function (t) {
	return {l: _List_Nil, p: _List_Nil, w: _List_Nil, hp: t};
};
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $author$project$Main$getProductModel = function (model) {
	if (model.$ === 6) {
		var subModel = model.a;
		return $elm$core$Maybe$Just(subModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$getProductsModel = function (model) {
	if (model.$ === 5) {
		var subModel = model.a;
		return $elm$core$Maybe$Just(subModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Category$id = function (_v0) {
	var internals = _v0.a;
	return internals.hx;
};
var $author$project$Page$Account$CompletedLoadAdministrators = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Account$CompletedLoadCompany = function (a) {
	return {$: 2, a: a};
};
var $author$project$Status$Loading = {$: 0};
var $author$project$Api$Endpoint$Endpoint = $elm$core$Basics$identity;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Api$Endpoint$url = F2(
	function (paths, queryParams) {
		return A2(
			$elm$url$Url$Builder$absolute,
			A2($elm$core$List$cons, '_json', paths),
			queryParams);
	});
var $author$project$Api$Endpoint$administrators = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['users', 'administrators']),
	_List_Nil);
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $author$project$Api$BadBody = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$NetworkError = {$: 2};
var $author$project$Api$Timeout = {$: 1};
var $author$project$Api$Unauthorized = {$: 6};
var $author$project$Api$ValidationError = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Problem = F2(
	function (message, errors) {
		return {hd: errors, fx: message};
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Api$decodeError = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'errors',
	$elm$json$Json$Decode$dict(
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'message',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Api$Problem)));
var $author$project$Api$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$author$project$Api$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($author$project$Api$Timeout);
			case 2:
				return $elm$core$Result$Err($author$project$Api$NetworkError);
			case 3:
				var metadata = response.a;
				var body = response.b;
				var _v1 = metadata.gi;
				switch (_v1) {
					case 422:
						var _v2 = A2($elm$json$Json$Decode$decodeString, $author$project$Api$decodeError, body);
						if (!_v2.$) {
							var problem = _v2.a;
							return $elm$core$Result$Err(
								$author$project$Api$ValidationError(problem));
						} else {
							var err = _v2.a;
							return $elm$core$Result$Err(
								$author$project$Api$BadBody(
									$elm$json$Json$Decode$errorToString(err)));
						}
					case 401:
						return $elm$core$Result$Err($author$project$Api$Unauthorized);
					default:
						return $elm$core$Result$Err(
							$author$project$Api$BadStatus(metadata.gi));
				}
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$author$project$Api$BadBody,
					toResult(body));
		}
	});
var $author$project$Api$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$author$project$Api$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {f0: reqs, gm: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.cc;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.f0));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.gm)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					gL: r.gL,
					bI: r.bI,
					bN: A2(_Http_mapExpect, func, r.bN),
					bQ: r.bQ,
					bW: r.bW,
					b7: r.b7,
					cc: r.cc,
					cg: r.cg
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{gL: false, bI: r.bI, bN: r.bN, bQ: r.bQ, bW: r.bW, b7: r.b7, cc: r.cc, cg: r.cg}));
};
var $author$project$Api$Endpoint$unwrap = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$Api$Endpoint$request = function (config) {
	return $elm$http$Http$request(
		{
			bI: config.bI,
			bN: config.bN,
			bQ: A2(
				$elm$core$List$cons,
				A2($elm$http$Http$header, 'X-Requested-With', 'XMLHttpRequest'),
				config.bQ),
			bW: config.bW,
			b7: config.b7,
			cc: config.cc,
			cg: $author$project$Api$Endpoint$unwrap(config.cg)
		});
};
var $author$project$Api$get = F3(
	function (url, decoder, msg) {
		return $author$project$Api$Endpoint$request(
			{
				bI: $elm$http$Http$emptyBody,
				bN: A2($author$project$Api$expectJson, msg, decoder),
				bQ: _List_Nil,
				bW: 'GET',
				b7: $elm$core$Maybe$Nothing,
				cc: $elm$core$Maybe$Nothing,
				cg: url
			});
	});
var $author$project$User$Minimal = F7(
	function (id, firstName, lastName, email, role, lastLoggedIn, email_verified_at) {
		return {be: email, hc: email_verified_at, ho: firstName, hx: id, hH: lastLoggedIn, hI: lastName, it: role};
	});
var $author$project$Id$Id = $elm$core$Basics$identity;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Id$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$Role$Admin = 1;
var $author$project$Role$Regular = 0;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Role$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'admin':
				return $elm$json$Json$Decode$succeed(1);
			case 'regular':
				return $elm$json$Json$Decode$succeed(0);
			default:
				return $elm$json$Json$Decode$fail('User role \'' + (s + '\' is not supported.'));
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Helpers$posixDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (secs) {
		return $elm$json$Json$Decode$succeed(
			$elm$time$Time$millisToPosix(secs * 1000));
	},
	$elm$json$Json$Decode$int);
var $author$project$User$minimalDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'email_verified_at',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Helpers$posixDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'last_logged_in',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Helpers$posixDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'role',
			$author$project$Role$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'email',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'last_name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'first_name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$Id$decoder,
							$elm$json$Json$Decode$succeed($author$project$User$Minimal))))))));
var $author$project$User$administrators = function (msg) {
	return A3(
		$author$project$Api$get,
		$author$project$Api$Endpoint$administrators,
		$elm$json$Json$Decode$list($author$project$User$minimalDecoder),
		msg);
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Company$Company = F7(
	function (id, external_id, name, billing, delivery_addresses, pickup, status) {
		return {gR: billing, g9: delivery_addresses, hi: external_id, hx: id, hT: name, ic: pickup, gh: status};
	});
var $author$project$Company$addressDecoderHelp = F2(
	function (address, list) {
		if (address.$ === 2) {
			var delivery = address.a;
			return A2($elm$core$List$cons, delivery, list);
		} else {
			return list;
		}
	});
var $author$project$Address$IsDelivery = function (a) {
	return {$: 2, a: a};
};
var $author$project$Address$IsPostal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Address$IsVisiting = function (a) {
	return {$: 0, a: a};
};
var $author$project$Address$Registered = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Address$Metadata = F6(
	function (row1, row2, row3, row4, reference, country) {
		return {g_: country, ip: reference, iv: row1, iw: row2, ix: row3, iy: row4};
	});
var $author$project$Country$Country = $elm$core$Basics$identity;
var $author$project$Country$Internals = F2(
	function (code, name) {
		return {eI: code, hT: name};
	});
var $author$project$Country$Code = $elm$core$Basics$identity;
var $author$project$Country$codeDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Country$internalsDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Country$Internals,
	A2($elm$json$Json$Decode$field, 'code', $author$project$Country$codeDecoder),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $author$project$Country$decoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Country$internalsDecoder,
	$elm$json$Json$Decode$succeed($elm$core$Basics$identity));
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Address$metadataDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Address$Metadata,
	A2($elm$json$Json$Decode$field, 'row1', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'row2', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'row3', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'row4', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'reference', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'country', $author$project$Country$decoder));
var $author$project$Address$Name = $elm$core$Basics$identity;
var $author$project$Address$nameDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $author$project$Address$registeredDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Address$Registered,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
	$author$project$Address$nameDecoder,
	$author$project$Address$metadataDecoder);
var $author$project$Address$typeDecoder = F2(
	function (type_, fn) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (str) {
				return _Utils_eq(str, type_) ? fn : $elm$json$Json$Decode$fail('Type \'' + (str + ('\' does not match \'' + (type_ + '\''))));
			},
			A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
	});
var $author$project$Address$deliveryDecoder = A2($author$project$Address$typeDecoder, 'delivery', $author$project$Address$registeredDecoder);
var $author$project$Address$Postal = $elm$core$Basics$identity;
var $author$project$Address$postalDecoder = A2(
	$author$project$Address$typeDecoder,
	'postal',
	A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Address$metadataDecoder));
var $author$project$Address$Visiting = $elm$core$Basics$identity;
var $author$project$Address$visitingDecoder = A2(
	$author$project$Address$typeDecoder,
	'visiting',
	A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Address$metadataDecoder));
var $author$project$Address$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$Address$IsDelivery, $author$project$Address$deliveryDecoder),
			A2($elm$json$Json$Decode$map, $author$project$Address$IsVisiting, $author$project$Address$visitingDecoder),
			A2($elm$json$Json$Decode$map, $author$project$Address$IsPostal, $author$project$Address$postalDecoder)
		]));
var $author$project$Company$addressDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (addresses) {
		return $elm$json$Json$Decode$succeed(
			A3($elm$core$List$foldr, $author$project$Company$addressDecoderHelp, _List_Nil, addresses));
	},
	$elm$json$Json$Decode$list($author$project$Address$decoder));
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Billing$InvoiceCompany = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Billing$ProForma = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Billing$Electronic = function (a) {
	return {$: 0, a: a};
};
var $author$project$Billing$electronicDecoderHelp = function (idStr) {
	if (idStr === '') {
		return $elm$json$Json$Decode$fail('GLN is missing');
	} else {
		var id = idStr;
		return $elm$json$Json$Decode$succeed(
			$author$project$Billing$Electronic(id));
	}
};
var $author$project$Billing$electronicDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Billing$electronicDecoderHelp,
	A2($elm$json$Json$Decode$field, 'gln', $elm$json$Json$Decode$string));
var $author$project$Billing$Email = function (a) {
	return {$: 1, a: a};
};
var $author$project$Billing$emailDecoderHelp = function (emailStr) {
	if (emailStr === '') {
		return $elm$json$Json$Decode$fail('Invoice email is missing');
	} else {
		var email = emailStr;
		return $elm$json$Json$Decode$succeed(
			$author$project$Billing$Email(email));
	}
};
var $author$project$Billing$emailDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Billing$emailDecoderHelp,
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $author$project$Helpers$payloadDecoder = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'payload', decoder);
};
var $author$project$Billing$Postal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Billing$postalDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Billing$Postal,
	A2($elm$json$Json$Decode$field, 'address', $author$project$Address$postalDecoder));
function $author$project$Billing$cyclic$invoiceCompanyDecoder() {
	return A3(
		$elm$json$Json$Decode$map2,
		$author$project$Billing$InvoiceCompany,
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'billing',
			$elm$json$Json$Decode$lazy(
				$elm$core$Basics$always(
					$author$project$Billing$cyclic$decoder()))));
}
function $author$project$Billing$cyclic$decoder() {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (proforma) {
			return proforma ? A2(
				$elm$json$Json$Decode$map,
				$author$project$Billing$ProForma,
				$author$project$Billing$cyclic$decoderHelper()) : $author$project$Billing$cyclic$decoderHelper();
		},
		A2($elm$json$Json$Decode$field, 'proforma', $elm$json$Json$Decode$bool));
}
function $author$project$Billing$cyclic$decoderHelper() {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			switch (str) {
				case 'electronic':
					return $author$project$Helpers$payloadDecoder($author$project$Billing$electronicDecoder);
				case 'email':
					return $author$project$Helpers$payloadDecoder($author$project$Billing$emailDecoder);
				case 'invoice_company':
					return $author$project$Helpers$payloadDecoder(
						$author$project$Billing$cyclic$invoiceCompanyDecoder());
				case 'postal':
					return $author$project$Helpers$payloadDecoder($author$project$Billing$postalDecoder);
				default:
					var other = str;
					return $elm$json$Json$Decode$fail('Billing type \"' + (other + '\" not supported.'));
			}
		},
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}
var $author$project$Billing$invoiceCompanyDecoder = $author$project$Billing$cyclic$invoiceCompanyDecoder();
$author$project$Billing$cyclic$invoiceCompanyDecoder = function () {
	return $author$project$Billing$invoiceCompanyDecoder;
};
var $author$project$Billing$decoder = $author$project$Billing$cyclic$decoder();
$author$project$Billing$cyclic$decoder = function () {
	return $author$project$Billing$decoder;
};
var $author$project$Billing$decoderHelper = $author$project$Billing$cyclic$decoderHelper();
$author$project$Billing$cyclic$decoderHelper = function () {
	return $author$project$Billing$decoderHelper;
};
var $author$project$CompanyStatus$OK = 0;
var $author$project$CompanyStatus$Warning = 1;
var $author$project$CompanyStatus$fromString = function (str) {
	switch (str) {
		case 'OK':
			return $elm$core$Maybe$Just(0);
		case 'warning':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$CompanyStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		A2(
			$elm$core$Basics$composeR,
			$author$project$CompanyStatus$fromString,
			$elm$core$Maybe$map($elm$json$Json$Decode$succeed)),
		$elm$core$Maybe$withDefault(
			$elm$json$Json$Decode$fail('company status not supported.'))),
	$elm$json$Json$Decode$string);
var $author$project$Company$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'status',
	$author$project$CompanyStatus$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'pickup',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'addresses',
			$author$project$Company$addressDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'billing',
				$author$project$Billing$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'external_id',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Company$Company))))))));
var $author$project$Api$Endpoint$meCompany = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['me', 'company']),
	_List_Nil);
var $author$project$Company$my = function (msg) {
	return A3($author$project$Api$get, $author$project$Api$Endpoint$meCompany, $author$project$Company$decoder, msg);
};
var $author$project$Page$Account$init = function (session) {
	return _Utils_Tuple2(
		{bB: $author$project$Status$Loading, bL: $author$project$Status$Loading, K: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Company$my($author$project$Page$Account$CompletedLoadCompany),
					$author$project$User$administrators($author$project$Page$Account$CompletedLoadAdministrators)
				])));
};
var $author$project$Page$Account$Administration$CompletedLoadUsers = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Account$Administration$Editing = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$Endpoint$users = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['users']),
	_List_Nil);
var $author$project$User$list = function (msg) {
	return A3(
		$author$project$Api$get,
		$author$project$Api$Endpoint$users,
		$elm$json$Json$Decode$list($author$project$User$minimalDecoder),
		msg);
};
var $author$project$Page$Account$Administration$init = function (session) {
	return _Utils_Tuple2(
		{
			aW: $elm$core$Maybe$Nothing,
			fx: '',
			U: A2($author$project$Page$Account$Administration$Editing, '', ''),
			bo: '',
			K: session,
			ar: $author$project$Status$Loading
		},
		$author$project$User$list($author$project$Page$Account$Administration$CompletedLoadUsers));
};
var $author$project$Page$Account$Password$NotValidated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Account$Password$emptyInputs = {S: '', N: '', ak: ''};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Page$Account$Password$init = function (session) {
	return _Utils_Tuple2(
		{
			fx: '',
			K: session,
			gh: $author$project$Page$Account$Password$NotValidated($author$project$Page$Account$Password$emptyInputs)
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Account$Profile$CompletedLoadCountries = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Account$Profile$CompletedLoadNewsletters = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Account$Profile$Loading = {$: 0};
var $author$project$User$Newsletters$Newsletters = $elm$core$Basics$identity;
var $author$project$User$Newsletters$Internals = F3(
	function (isBlacklisted, hasDoubleOptIn, lists) {
		return {e6: hasDoubleOptIn, fk: isBlacklisted, a0: lists};
	});
var $author$project$NewsletterList$NewsletterList = $elm$core$Basics$identity;
var $author$project$NewsletterList$Internals = F4(
	function (externalId, name, description, isSubscribed) {
		return {eT: description, hh: externalId, fm: isSubscribed, hT: name};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$NewsletterList$internalsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$NewsletterList$Internals,
	A2($elm$json$Json$Decode$field, 'external_id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'isSubscribed', $elm$json$Json$Decode$bool));
var $author$project$NewsletterList$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$NewsletterList$internalsDecoder);
var $author$project$User$Newsletters$internalsDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$User$Newsletters$Internals,
	A2($elm$json$Json$Decode$field, 'isBlacklisted', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'hasDoubleOptIn', $elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$field,
		'lists',
		$elm$json$Json$Decode$list($author$project$NewsletterList$decoder)));
var $author$project$User$Newsletters$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$User$Newsletters$internalsDecoder);
var $author$project$Api$Endpoint$meNewsletters = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['me', 'newsletters']),
	_List_Nil);
var $author$project$User$Newsletters$get = function (msg) {
	return A3($author$project$Api$get, $author$project$Api$Endpoint$meNewsletters, $author$project$User$Newsletters$decoder, msg);
};
var $author$project$Api$Endpoint$countries = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['countries']),
	_List_Nil);
var $author$project$Country$list = function (msg) {
	return A3(
		$author$project$Api$get,
		$author$project$Api$Endpoint$countries,
		$elm$json$Json$Decode$list($author$project$Country$decoder),
		msg);
};
var $author$project$Page$Account$Profile$init = function (session) {
	return _Utils_Tuple2(
		{cr: _List_Nil, C: $author$project$Page$Account$Profile$Loading, fx: '', al: $author$project$Status$Loading, cL: '', K: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Country$list($author$project$Page$Account$Profile$CompletedLoadCountries),
					$author$project$User$Newsletters$get(
					$author$project$Page$Account$Profile$CompletedLoadNewsletters(''))
				])));
};
var $author$project$Page$Account$Settings$init = function (session) {
	return _Utils_Tuple2(
		{K: session, gr: 'Generella inställningar'},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Bid$CompletedLoadBid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Id$toInt = function (_v0) {
	var a = _v0;
	return a;
};
var $author$project$Api$Endpoint$bid = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'bids',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id))
			]),
		_List_Nil);
};
var $author$project$Bid$Bid = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Bid$Internals = function (id) {
	return function (externalId) {
		return function (pricingId) {
			return function (name) {
				return function (validFrom) {
					return function (validTo) {
						return function (warningFrom) {
							return function (noOrdersAfter) {
								return function (status) {
									return function (createdAt) {
										return function (updatedAt) {
											return {g$: createdAt, hh: externalId, hx: id, hT: name, dQ: noOrdersAfter, ih: pricingId, gh: status, iW: updatedAt, df: validFrom, iX: validTo, i_: warningFrom};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ExternalId$ExternalId = $elm$core$Basics$identity;
var $author$project$ExternalId$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Bid$internalsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updated_at',
	$author$project$Helpers$posixDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'created_at',
		$author$project$Helpers$posixDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'status',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'no_orders_after',
				$author$project$Helpers$posixDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'warning_from',
					$author$project$Helpers$posixDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'valid_to',
						$author$project$Helpers$posixDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'valid_from',
							$author$project$Helpers$posixDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'name',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'pricing_id',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'external_id',
										$author$project$ExternalId$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$author$project$Id$decoder,
											$elm$json$Json$Decode$succeed($author$project$Bid$Internals))))))))))));
var $author$project$Bid$decoder = A2($elm$json$Json$Decode$map2, $author$project$Bid$Bid, $author$project$Bid$internalsDecoder);
var $author$project$Bid$Full = $elm$core$Basics$identity;
var $author$project$BidRow$BidRow = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Product$Full = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Availability$Available = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Availability$NotAvailable = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Availability$Confirmed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Availability$NoAvailableDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Availability$Unconfirmed = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Availability$dataToIncoming = function (data) {
	var _v0 = data.gq;
	if (!_v0.$) {
		var timestamp = _v0.a;
		return data.eL ? A2($author$project$Availability$Confirmed, data.cj, timestamp) : A2($author$project$Availability$Unconfirmed, data.cj, timestamp);
	} else {
		return $author$project$Availability$NoAvailableDate(data.cj);
	}
};
var $author$project$Availability$IncomingData = F3(
	function (confirmed, amount, timestamp) {
		return {cj: amount, eL: confirmed, gq: timestamp};
	});
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Availability$decodeIncomingData = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Availability$IncomingData,
	A2($elm$json$Json$Decode$field, 'confirmed', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'amount', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'timestamp',
		$elm$json$Json$Decode$nullable($author$project$Helpers$posixDecoder)));
var $author$project$Availability$decodeIncoming = A2($elm$json$Json$Decode$map, $author$project$Availability$dataToIncoming, $author$project$Availability$decodeIncomingData);
var $author$project$Availability$LeadTime = function (a) {
	return {$: 0, a: a};
};
var $author$project$Availability$NoLeadTime = {$: 1};
var $author$project$Availability$leadTimeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (maybeLeadtime) {
		if (!maybeLeadtime.$) {
			var stringValue = maybeLeadtime.a;
			return $elm$json$Json$Decode$succeed(
				$author$project$Availability$LeadTime(stringValue));
		} else {
			return $elm$json$Json$Decode$succeed($author$project$Availability$NoLeadTime);
		}
	},
	A2(
		$elm$json$Json$Decode$field,
		'leadtime',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)));
var $author$project$Availability$missing = A2($author$project$Availability$NotAvailable, _List_Nil, $author$project$Availability$NoLeadTime);
var $author$project$Availability$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (balance) {
				var incoming = A2(
					$elm$json$Json$Decode$field,
					'incoming',
					$elm$json$Json$Decode$list($author$project$Availability$decodeIncoming));
				return (balance <= 0) ? A3($elm$json$Json$Decode$map2, $author$project$Availability$NotAvailable, incoming, $author$project$Availability$leadTimeDecoder) : A2(
					$elm$json$Json$Decode$map,
					$author$project$Availability$Available(balance),
					incoming);
			},
			A2($elm$json$Json$Decode$field, 'balance', $elm$json$Json$Decode$int)),
			$elm$json$Json$Decode$succeed($author$project$Availability$missing)
		]));
var $author$project$Price$IsMissing = {$: 0};
var $author$project$Price$missingPriceDecoder = $elm$json$Json$Decode$succeed($author$project$Price$IsMissing);
var $author$project$Price$IsPresent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Price$Data = $elm$core$Basics$identity;
var $author$project$Price$PriceRec = F5(
	function (net, base, vatRate, chemicalTax, unit) {
		return {dm: base, dq: chemicalTax, dO: net, em: unit, gC: vatRate};
	});
var $author$project$Unit$Centimeter = 7;
var $author$project$Unit$FiveHundredMeter = 8;
var $author$project$Unit$Hour = 2;
var $author$project$Unit$HundredPackage = 6;
var $author$project$Unit$Kilogram = 1;
var $author$project$Unit$Kilometer = 4;
var $author$project$Unit$Kit = 14;
var $author$project$Unit$Meter = 3;
var $author$project$Unit$Minute = 11;
var $author$project$Unit$Month = 12;
var $author$project$Unit$Package = 10;
var $author$project$Unit$Pair = 9;
var $author$project$Unit$Piece = 0;
var $author$project$Unit$Room = 15;
var $author$project$Unit$SquareMeter = 5;
var $author$project$Unit$Year = 13;
var $author$project$Unit$fromKey = function (s) {
	switch (s) {
		case 'piece':
			return $elm$core$Maybe$Just(0);
		case 'kilogram':
			return $elm$core$Maybe$Just(1);
		case 'hour':
			return $elm$core$Maybe$Just(2);
		case 'meter':
			return $elm$core$Maybe$Just(3);
		case 'kilometer':
			return $elm$core$Maybe$Just(4);
		case 'square_meter':
			return $elm$core$Maybe$Just(5);
		case 'hundred_package':
			return $elm$core$Maybe$Just(6);
		case 'centimeter':
			return $elm$core$Maybe$Just(7);
		case 'five_hundred_meter':
			return $elm$core$Maybe$Just(8);
		case 'pair':
			return $elm$core$Maybe$Just(9);
		case 'package':
			return $elm$core$Maybe$Just(10);
		case 'minute':
			return $elm$core$Maybe$Just(11);
		case 'month':
			return $elm$core$Maybe$Just(12);
		case 'year':
			return $elm$core$Maybe$Just(13);
		case 'kit':
			return $elm$core$Maybe$Just(14);
		case 'room':
			return $elm$core$Maybe$Just(15);
		case 'not_found':
			return $elm$core$Maybe$Just(0);
		default:
			return $elm$core$Maybe$Just(0);
	}
};
var $elm_community$json_extra$Json$Decode$Extra$fromMaybe = F2(
	function (error, val) {
		if (!val.$) {
			var v = val.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			return $elm$json$Json$Decode$fail(error);
		}
	});
var $author$project$Unit$decoder = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Unit$fromKey,
		$elm_community$json_extra$Json$Decode$Extra$fromMaybe('Unit not found')),
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$VAT$Rate = $elm$core$Basics$identity;
var $author$project$VAT$rateDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$float);
var $author$project$Price$dataDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'unit',
		$author$project$Unit$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'chemical_tax',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'vat_rate',
				$author$project$VAT$rateDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'base_price',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'price',
						$elm$json$Json$Decode$float,
						$elm$json$Json$Decode$succeed($author$project$Price$PriceRec)))))));
var $author$project$Price$presentDecoder = A2($elm$json$Json$Decode$map, $author$project$Price$IsPresent, $author$project$Price$dataDecoder);
var $author$project$Price$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Price$presentDecoder, $author$project$Price$missingPriceDecoder]));
var $author$project$Product$Internals = F4(
	function (id, externalId, slug, metadata) {
		return {hh: externalId, hx: id, bV: metadata, gb: slug};
	});
var $author$project$Slug$Slug = $elm$core$Basics$identity;
var $author$project$Slug$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Image$Image = $elm$core$Basics$identity;
var $author$project$Image$Internals = F3(
	function (id, order, sizes) {
		return {hx: id, h9: order, c1: sizes};
	});
var $author$project$Image$Size = F4(
	function (url, resolution, width, height) {
		return {e7: height, d3: resolution, cg: url, gE: width};
	});
var $author$project$Image$sizeDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Image$Size,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'resolution', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$int));
var $author$project$Image$internalsDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Image$Internals,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'order', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'sizes',
		$elm$json$Json$Decode$dict(
			$elm$json$Json$Decode$list($author$project$Image$sizeDecoder))));
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Image$decoder = $elm$json$Json$Decode$maybe(
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Image$internalsDecoder,
		$elm$json$Json$Decode$succeed($elm$core$Basics$identity)));
var $author$project$Image$listDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$List$filterMap($elm$core$Basics$identity),
	$elm$json$Json$Decode$list($author$project$Image$decoder));
var $author$project$Embed$IsImage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Product$Metadata = function (name) {
	return function (description) {
		return function (brand) {
			return function (manufacturer_product_code) {
				return function (e_number) {
					return function (images) {
						return function (file) {
							return function (sticker) {
								return function (status) {
									return function (categories) {
										return function (attributes) {
											return function (campaigns) {
												return function (embeds) {
													return {gQ: attributes, dp: brand, eE: campaigns, gV: categories, eT: description, eX: e_number, eY: embeds, hm: file, fd: images, fv: manufacturer_product_code, hT: name, gh: status, d8: sticker};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Product$Header = function (a) {
	return {$: 1, a: a};
};
var $author$project$Product$attributeHeaderDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Product$Header,
	A2($elm$json$Json$Decode$field, 'header', $elm$json$Json$Decode$string));
var $author$project$Product$Attribute = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Product$attributeHelperDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Product$Attribute,
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
var $author$project$Product$attributeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Product$attributeHelperDecoder, $author$project$Product$attributeHeaderDecoder]));
var $author$project$Brand$Brand = $elm$core$Basics$identity;
var $author$project$Brand$Internals = F7(
	function (id, name, slug, description, logo, images, embeds) {
		return {eT: description, eY: embeds, hx: id, fd: images, fu: logo, hT: name, gb: slug};
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Embed$hostDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (url) {
		var _v0 = $elm$url$Url$fromString(url);
		if (!_v0.$) {
			var host = _v0.a.hv;
			return $elm$json$Json$Decode$succeed(
				A3($elm$core$String$replace, 'www.', '', host));
		} else {
			return $elm$json$Json$Decode$fail(url + ' is not a proper url');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Embed$IsVideo = function (a) {
	return {$: 0, a: a};
};
var $author$project$Embed$Video$Video = $elm$core$Basics$identity;
var $author$project$Embed$Video$VideoRec = F6(
	function (title, thumbnail_url, html, width, height, host) {
		return {e7: height, hv: host, fa: html, gp: thumbnail_url, gr: title, gE: width};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode = $rtfeldman$elm_css$VirtualDom$Styled$Unstyled;
var $rtfeldman$elm_css$Html$Styled$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode;
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {eJ: col, gZ: contextStack, ij: problem, iu: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.iu, s.eJ, x, s.g));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.h4, s.iI);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{eJ: 1, g: s.g, j: s.j, h4: s.h4 + 1, iu: s.iu + 1, iI: s.iI}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{eJ: s.eJ + 1, g: s.g, j: s.j, h4: newOffset, iu: s.iu, iI: s.iI}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.iI);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.h4, offset) < 0,
					0,
					{eJ: col, g: s0.g, j: s0.j, h4: offset, iu: row, iI: s0.iI});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.h4, s.iu, s.eJ, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.h4, s1.h4, s0.iI),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toLower = _String_toLower;
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.h4, s.iu, s.eJ, s.iI);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.g)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.h4, newOffset) < 0,
			0,
			{eJ: newCol, g: s.g, j: s.j, h4: newOffset, iu: newRow, iI: s.iI});
	};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.h4, s.iu, s.eJ, s.iI);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{eJ: newCol, g: s.g, j: s.j, h4: newOffset, iu: newRow, iI: s.iI});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $hecrj$html_parser$Html$Parser$comment = A2($elm$parser$Parser$map, $hecrj$html_parser$Html$Parser$Comment, $hecrj$html_parser$Html$Parser$commentString);
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {eJ: s.eJ + (newOffset - s.h4), g: s.g, j: s.j, h4: newOffset, iu: s.iu, iI: s.iI};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.h4, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.iI);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.iu, s.eJ - (floatOffset + s.h4), invalid, s.g));
		} else {
			if (_Utils_eq(s.h4, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.h4, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.h4, floatOffset, s.iI));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.h4, s.iI)) {
			var zeroOffset = s.h4 + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.iI) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.hC,
				c.e8,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.iI),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.iI) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.hC,
				c.fE,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.iI),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.iI) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.hC,
				c.ey,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.iI),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.hC,
				c.e$,
				c.fi,
				c.e2,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.hC,
				c.e$,
				c.fi,
				c.e2,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.h4, s.iI),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				ey: $elm$core$Result$Err(invalid),
				e$: expecting,
				e2: $elm$core$Result$Err(invalid),
				e8: $elm$core$Result$Err(invalid),
				fi: $elm$core$Result$Ok($elm$core$Basics$identity),
				hC: invalid,
				fE: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {eJ: col, ij: problem, iu: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.iu, p.eJ, p.ij);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{eJ: 1, g: _List_Nil, j: 1, h4: 0, iu: 1, iI: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Embed$Video$htmlParser = function (raw) {
	var _v0 = $hecrj$html_parser$Html$Parser$run(raw);
	if (!_v0.$) {
		var nodes = _v0.a;
		return $elm$json$Json$Decode$succeed(
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Html$Styled$fromUnstyled,
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(nodes)));
	} else {
		return $elm$json$Json$Decode$fail('Could not parse embed html');
	}
};
var $author$project$Embed$Video$decoder = function (host) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$identity,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			host,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'height',
				$elm$json$Json$Decode$int,
				0,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'width',
					$elm$json$Json$Decode$int,
					0,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'html',
						A2($elm$json$Json$Decode$andThen, $author$project$Embed$Video$htmlParser, $elm$json$Json$Decode$string),
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'thumbnail_url',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'title',
								$elm$json$Json$Decode$string,
								'',
								$elm$json$Json$Decode$succeed($author$project$Embed$Video$VideoRec))))))));
};
var $author$project$Embed$payloadDecoderHelper = F2(
	function (host, type_) {
		if (type_ === 'video') {
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Embed$IsVideo,
				$author$project$Embed$Video$decoder(host));
		} else {
			return $elm$json$Json$Decode$fail('type: ' + (type_ + ' not supported'));
		}
	});
var $author$project$Embed$payloadDecoder = function (host) {
	return A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Embed$payloadDecoderHelper(host),
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
};
var $author$project$Embed$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (url) {
		return A2(
			$elm$json$Json$Decode$field,
			'payload',
			$author$project$Embed$payloadDecoder(url));
	},
	A2($elm$json$Json$Decode$field, 'url', $author$project$Embed$hostDecoder));
var $author$project$Brand$internalsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'embeds',
	$elm$json$Json$Decode$list($author$project$Embed$decoder),
	_List_Nil,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'images',
		$author$project$Image$listDecoder,
		_List_Nil,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'logo',
			$author$project$Image$decoder,
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'description',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'slug',
					$author$project$Slug$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$Id$decoder,
							$elm$json$Json$Decode$succeed($author$project$Brand$Internals))))))));
var $author$project$Brand$decoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Brand$internalsDecoder,
	$elm$json$Json$Decode$succeed($elm$core$Basics$identity));
var $author$project$Campaign$Campaign = $elm$core$Basics$identity;
var $author$project$Campaign$Internals = F4(
	function (id, name, description, end_at) {
		return {eT: description, eZ: end_at, hx: id, hT: name};
	});
var $author$project$Campaign$internalsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Campaign$Internals,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'end_at', $author$project$Helpers$posixDecoder));
var $author$project$Campaign$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Campaign$internalsDecoder);
var $author$project$File$File = F4(
	function (id, order, name, url) {
		return {hx: id, hT: name, h9: order, cg: url};
	});
var $author$project$File$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$File$File,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'order', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string));
var $author$project$ProductStatus$Locked = 2;
var $author$project$ProductStatus$Ok = 0;
var $author$project$ProductStatus$Outbound = 1;
var $author$project$ProductStatus$fromString = function (string) {
	switch (string) {
		case 'ok':
			return $elm$core$Maybe$Just(0);
		case 'outbound':
			return $elm$core$Maybe$Just(1);
		case 'locked':
			return $elm$core$Maybe$Just(2);
		case 'not_found':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$ProductStatus$decoderHelper = function (string) {
	var _v0 = $author$project$ProductStatus$fromString(string);
	if (!_v0.$) {
		var v = _v0.a;
		return $elm$json$Json$Decode$succeed(v);
	} else {
		return $elm$json$Json$Decode$fail('Product status \'' + (string + '\' is not supported.'));
	}
};
var $author$project$ProductStatus$decoder = A2($elm$json$Json$Decode$andThen, $author$project$ProductStatus$decoderHelper, $elm$json$Json$Decode$string);
var $author$project$Sticker$Bargain = 1;
var $author$project$Sticker$Campaign = 2;
var $author$project$Sticker$New = 0;
var $author$project$Sticker$decoderHelper = function (strings) {
	if (!strings.b) {
		return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
	} else {
		switch (strings.a) {
			case 'new':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(0));
			case 'bargain':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(1));
			case 'campaign':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(2));
			default:
				return $elm$json$Json$Decode$fail(
					'Stickers \'' + (A2($elm$core$String$join, ', ', strings) + '\' is not supported.'));
		}
	}
};
var $author$project$Sticker$decoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Sticker$decoderHelper,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Category$Category = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Category$Preview = 0;
var $author$project$Category$Internals = F4(
	function (id, name, slug, productCount) {
		return {hx: id, hT: name, cS: productCount, gb: slug};
	});
var $author$project$Category$internalsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Category$Internals,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'product_count', $elm$json$Json$Decode$int));
var $author$project$Category$previewDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	0,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Category$internalsDecoder,
		$elm$json$Json$Decode$succeed($author$project$Category$Category)));
var $author$project$Product$metadataDecoderHelper = function (images) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'embeds',
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Basics$append(
				A2($elm$core$List$map, $author$project$Embed$IsImage, images)),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$List$filterMap($elm$core$Basics$identity),
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$maybe($author$project$Embed$decoder)))),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'campaigns',
			$elm$json$Json$Decode$list($author$project$Campaign$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'attributes_table',
				$elm$json$Json$Decode$list($author$project$Product$attributeDecoder),
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'categories',
					$elm$json$Json$Decode$list($author$project$Category$previewDecoder),
					_List_Nil,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'status',
						$author$project$ProductStatus$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'stickers',
							$author$project$Sticker$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'file',
								$elm$json$Json$Decode$nullable($author$project$File$decoder),
								A2(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
									images,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'e_number',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'manufacturer_product_code',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'brand',
												$author$project$Brand$decoder,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'description',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'name',
														$elm$json$Json$Decode$string,
														$elm$json$Json$Decode$succeed($author$project$Product$Metadata))))))))))))));
};
var $author$project$Product$metadataDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Product$metadataDecoderHelper,
	A2($elm$json$Json$Decode$field, 'images', $author$project$Image$listDecoder));
var $author$project$Product$internalsDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Product$metadataDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'slug',
		$author$project$Slug$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'product_code',
			$author$project$ExternalId$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$Id$decoder,
				$elm$json$Json$Decode$succeed($author$project$Product$Internals)))));
var $author$project$Product$fullDecoder = function (_v0) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'availability',
		$author$project$Availability$decoder,
		$author$project$Availability$missing,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			$author$project$Price$decoder,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
				$author$project$Product$internalsDecoder,
				$elm$json$Json$Decode$succeed($author$project$Product$Full))));
};
var $author$project$BidRow$Internals = F5(
	function (id, pricingid, quantity, usedQuantity, priceData) {
		return {hx: id, fP: priceData, ii: pricingid, fW: quantity, eo: usedQuantity};
	});
var $author$project$BidRow$internalsDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Price$dataDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'used_quantity',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'quantity',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'pricing_id',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$author$project$Id$decoder,
					$elm$json$Json$Decode$succeed($author$project$BidRow$Internals))))));
var $author$project$BidRow$decoderWithProduct = function (userId) {
	return A3(
		$elm$json$Json$Decode$map2,
		$author$project$BidRow$BidRow,
		$author$project$BidRow$internalsDecoder,
		A2(
			$elm$json$Json$Decode$field,
			'product',
			$author$project$Product$fullDecoder(userId)));
};
var $author$project$Bid$fullDecoderWithProduct = function (userId) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$identity,
		A2(
			$elm$json$Json$Decode$field,
			'rows',
			$elm$json$Json$Decode$list(
				$author$project$BidRow$decoderWithProduct(userId))));
};
var $author$project$Bid$fetch = F3(
	function (msg, userId, bidId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$bid(bidId),
			$author$project$Bid$decoder(
				$author$project$Bid$fullDecoderWithProduct(userId)),
			msg);
	});
var $author$project$Session$time = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$userInterface,
	function ($) {
		return $.el;
	});
var $author$project$Session$user = function (session) {
	if (!session.$) {
		var val = session.b;
		return $elm$core$Maybe$Just(val);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$userId = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$user,
	$elm$core$Maybe$map(
		function ($) {
			return $.hx;
		}));
var $author$project$Page$Bid$init = F2(
	function (session, id) {
		return _Utils_Tuple2(
			{
				bE: _List_Nil,
				aF: $author$project$Status$Loading,
				K: session,
				el: $author$project$Session$time(session)
			},
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (userId) {
						return A3($author$project$Bid$fetch, $author$project$Page$Bid$CompletedLoadBid, userId, id);
					},
					$author$project$Session$userId(session))));
	});
var $author$project$Page$Bids$CompletedLoadNow = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Bids$None = 0;
var $author$project$Bid$defaultQueryParams = {dW: 1, dY: 50, d0: ''};
var $author$project$Page$Bids$CompletedLoadBids = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Endpoint$bids = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['bids']),
		params);
};
var $author$project$Bid$Collection = F3(
	function (data, lastPage, currentPage) {
		return {g1: currentPage, g2: data, hJ: lastPage};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Bid$collectionDecoder = function (bodyDecoder) {
	return A4(
		$elm$json$Json$Decode$map3,
		$author$project$Bid$Collection,
		A2(
			$elm$json$Json$Decode$field,
			'data',
			$elm$json$Json$Decode$list(
				$author$project$Bid$decoder(bodyDecoder))),
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['meta', 'last_page']),
			$elm$json$Json$Decode$int),
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['meta', 'current_page']),
			$elm$json$Json$Decode$int));
};
var $author$project$Bid$Preview = 0;
var $author$project$Bid$previewDecoder = $elm$json$Json$Decode$succeed(0);
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Bid$toQueryParameters = function (params) {
	return _List_fromArray(
		[
			A2($elm$url$Url$Builder$string, 'q', params.d0),
			A2(
			$elm$url$Url$Builder$int,
			'page',
			A2($elm$core$Basics$max, 1, params.dW)),
			A2(
			$elm$url$Url$Builder$int,
			'perPage',
			A3($elm$core$Basics$clamp, 1, 50, params.dY))
		]);
};
var $author$project$Bid$list = F2(
	function (msg, params) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$bids(
				$author$project$Bid$toQueryParameters(params)),
			$author$project$Bid$collectionDecoder($author$project$Bid$previewDecoder),
			msg);
	});
var $author$project$Page$Bids$loadBids = $author$project$Bid$list($author$project$Page$Bids$CompletedLoadBids);
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Page$Bids$init = function (session) {
	var newModel = {
		R: $author$project$Status$Loading,
		cH: 0,
		d0: '',
		K: session,
		el: $author$project$Session$time(session)
	};
	return _Utils_Tuple2(
		newModel,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Task$perform, $author$project$Page$Bids$CompletedLoadNow, $elm$time$Time$now),
					$author$project$Page$Bids$loadBids($author$project$Bid$defaultQueryParams)
				])));
};
var $author$project$Page$Brands$CompletedLoadBrands = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Endpoint$brands = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['brands']),
	_List_Nil);
var $author$project$Brand$list = function (msg) {
	return A3(
		$author$project$Api$get,
		$author$project$Api$Endpoint$brands,
		$elm$json$Json$Decode$list($author$project$Brand$decoder),
		msg);
};
var $author$project$Page$Brands$init = function (session) {
	return _Utils_Tuple2(
		{bK: $author$project$Status$Loading, K: session},
		$author$project$Brand$list($author$project$Page$Brands$CompletedLoadBrands));
};
var $author$project$Page$Checkout$CompletedLoadCompany = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Checkout$CompletedLoadCountries = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Checkout$Field = F4(
	function (label, value, validations, errors) {
		return {hd: errors, cB: label, gz: validations, iY: value};
	});
var $author$project$Page$Checkout$NotSelected = {$: 0};
var $author$project$Page$Checkout$NotValidated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Country$codeToString = function (_v0) {
	var code_ = _v0;
	return code_;
};
var $author$project$Country$defaultCode = 'SE';
var $author$project$Page$Checkout$isMax = F2(
	function (max, value) {
		return (_Utils_cmp(
			$elm$core$String$length(value),
			max) < 1) ? $elm$core$Result$Ok(0) : $elm$core$Result$Err(
			'Max ' + ($elm$core$String$fromInt(max) + ' tecken.'));
	});
var $author$project$Page$Checkout$isRequired = function (value) {
	return $elm$core$String$isEmpty(value) ? $elm$core$Result$Err('Vänligen fyll i detta fält.') : $elm$core$Result$Ok(0);
};
var $author$project$Page$Checkout$emptyForm = $author$project$Page$Checkout$NotValidated(
	{
		g_: A4(
			$author$project$Page$Checkout$Field,
			'Land',
			$author$project$Country$codeToString($author$project$Country$defaultCode),
			_List_fromArray(
				[$author$project$Page$Checkout$isRequired]),
			$elm$core$Result$Ok(0)),
		I: $author$project$Page$Checkout$NotSelected,
		aa: A4(
			$author$project$Page$Checkout$Field,
			'Godsmärke',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(30)
				]),
			$elm$core$Result$Ok(0)),
		fx: A4(
			$author$project$Page$Checkout$Field,
			'Meddelande',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(200)
				]),
			$elm$core$Result$Ok(0)),
		ip: A4(
			$author$project$Page$Checkout$Field,
			'Referens',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(40),
					$author$project$Page$Checkout$isRequired
				]),
			$elm$core$Result$Ok(0)),
		iv: A4(
			$author$project$Page$Checkout$Field,
			'Företagsnamn',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(80),
					$author$project$Page$Checkout$isRequired
				]),
			$elm$core$Result$Ok(0)),
		iw: A4(
			$author$project$Page$Checkout$Field,
			'Adress',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(80),
					$author$project$Page$Checkout$isRequired
				]),
			$elm$core$Result$Ok(0)),
		ix: A4(
			$author$project$Page$Checkout$Field,
			'Postnummer och ort',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(80),
					$author$project$Page$Checkout$isRequired
				]),
			$elm$core$Result$Ok(0)),
		ag: A4(
			$author$project$Page$Checkout$Field,
			'Ert ordernummer',
			'',
			_List_fromArray(
				[
					$author$project$Page$Checkout$isMax(30)
				]),
			$elm$core$Result$Ok(0))
	});
var $specialelektronik$elm_autocomplete$Autocomplete$Autocomplete = $elm$core$Basics$identity;
var $jinjor$elm_debounce$Debounce$Debounce = $elm$core$Basics$identity;
var $jinjor$elm_debounce$Debounce$init = {av: _List_Nil, bT: false};
var $specialelektronik$elm_autocomplete$Autocomplete$init = function (q) {
	return {bb: $jinjor$elm_debounce$Debounce$init, hA: $elm$core$Maybe$Nothing, im: q, iM: _List_Nil};
};
var $author$project$Page$Checkout$init = function (session) {
	return _Utils_Tuple2(
		{
			bL: $author$project$Status$Loading,
			cr: $author$project$Status$Loading,
			C: $author$project$Page$Checkout$emptyForm,
			aR: 1,
			V: $specialelektronik$elm_autocomplete$Autocomplete$init(''),
			br: '',
			K: session
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Company$my($author$project$Page$Checkout$CompletedLoadCompany),
					$author$project$Country$list($author$project$Page$Checkout$CompletedLoadCountries)
				])));
};
var $author$project$Page$Content$CompletedLoadContent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Endpoint$content = function (route) {
	var params = A2(
		$elm$core$String$split,
		'/',
		A2($elm$core$String$dropLeft, 1, route));
	return A2(
		$author$project$Api$Endpoint$url,
		A2($elm$core$List$cons, 'content', params),
		_List_Nil);
};
var $author$project$Content$Content = F3(
	function (title, body, files) {
		return {bI: body, hn: files, gr: title};
	});
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$auto = {gK: 0, a: 0, bf: 0, cA: 0, hG: 0, bl: 0, aM: 0, ax: 0, bp: 0, ap: 0, c6: 0, bx: 0, Y: 0, iY: 'auto'};
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.iY);
};
var $author$project$SE$UI$Colors$Hsla = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$SE$UI$Colors$black = A4($author$project$SE$UI$Colors$Hsla, 0, 0, 0, 1);
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.iY);
	});
var $rtfeldman$elm_css$Css$marginBottom = $rtfeldman$elm_css$Css$prop1('margin-bottom');
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var $rtfeldman$elm_css$Css$RemUnits = 0;
var $elm$core$String$fromFloat = _String_fromNumber;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			eq: 0,
			eD: 0,
			bf: 0,
			L: 0,
			b: 0,
			bl: 0,
			aM: 0,
			bm: 0,
			bn: 0,
			aZ: 0,
			a_: 0,
			ax: 0,
			aO: numericValue,
			bw: 0,
			bz: unitLabel,
			cf: units,
			iY: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$rem = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'rem');
var $author$project$SE$UI$Utils$block = A2(
	$rtfeldman$elm_css$Css$pseudoClass,
	'not(:last-child)',
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$marginBottom(
			$rtfeldman$elm_css$Css$rem(1.5))
		]));
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$TypeSelector = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Global$typeSelector = F2(
	function (selectorStr, styles) {
		var sequence = A2($rtfeldman$elm_css$Css$Structure$TypeSelectorSequence, selectorStr, _List_Nil);
		var sel = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, sel, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$Css$Global$blockquote = $rtfeldman$elm_css$Css$Global$typeSelector('blockquote');
var $author$project$SE$UI$Colors$lighter = A4($author$project$SE$UI$Colors$Hsla, 180, 0.02, 0.9, 1);
var $author$project$SE$UI$Colors$border = $author$project$SE$UI$Colors$lighter;
var $rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.iY, argB.iY, argC.iY])));
	});
var $rtfeldman$elm_css$Css$border3 = $rtfeldman$elm_css$Css$prop3('border');
var $rtfeldman$elm_css$Css$borderBottomWidth = $rtfeldman$elm_css$Css$prop1('border-bottom-width');
var $rtfeldman$elm_css$Css$borderLeft3 = $rtfeldman$elm_css$Css$prop3('border-left');
var $rtfeldman$elm_css$Css$borderWidth3 = $rtfeldman$elm_css$Css$prop3('border-width');
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$circle = {e: 0, c: 0, iY: 'circle'};
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $rtfeldman$elm_css$Html$Styled$Attributes$classList = function (classes) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.iY);
};
var $rtfeldman$elm_css$Css$Global$dd = $rtfeldman$elm_css$Css$Global$typeSelector('dd');
var $rtfeldman$elm_css$Css$decimal = {e: 0, c: 0, iY: 'decimal'};
var $rtfeldman$elm_css$Css$Structure$Descendant = 3;
var $rtfeldman$elm_css$Css$Preprocess$NestSnippet = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Global$descendants = $rtfeldman$elm_css$Css$Preprocess$NestSnippet(3);
var $rtfeldman$elm_css$Css$disc = {e: 0, c: 0, iY: 'disc'};
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $rtfeldman$elm_css$Css$Global$dl = $rtfeldman$elm_css$Css$Global$typeSelector('dl');
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Global$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v5 = declarations.a.a;
				var firstSelector = _v5.a;
				var otherSelectors = _v5.b;
				var styles = _v5.c;
				var rest = declarations.b;
				return _Utils_ap(
					A2(
						$elm$core$List$cons,
						A2($rtfeldman$elm_css$Css$Global$unwrapSelector, firstSelector, styles),
						otherSelectors),
					$rtfeldman$elm_css$Css$Global$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Global$unwrapSelector = F2(
	function (_v0, styles) {
		var sequence = _v0.a;
		var combinators = _v0.b;
		var mPseudo = _v0.c;
		var unwrapSequenceSelector = F2(
			function (style, s) {
				if (style.$ === 1) {
					var nestedSelector = style.a;
					var evenMoreNestedStyles = style.b;
					return A3(
						$elm$core$List$foldr,
						unwrapSequenceSelector,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, nestedSelector, s),
						evenMoreNestedStyles);
				} else {
					return s;
				}
			});
		var unwrapCombinatorSelector = F2(
			function (style, cs) {
				if (style.$ === 2) {
					var combinator = style.a;
					var snippets = style.b;
					return A2(
						$elm$core$List$append,
						cs,
						A2(
							$elm$core$List$map,
							function (_v3) {
								var s = _v3.a;
								return _Utils_Tuple2(combinator, s);
							},
							A2(
								$elm$core$List$concatMap,
								A2($elm$core$Basics$composeR, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, $rtfeldman$elm_css$Css$Global$collectSelectors),
								snippets)));
				} else {
					return cs;
				}
			});
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			A3($elm$core$List$foldr, unwrapSequenceSelector, sequence, styles),
			A3($elm$core$List$foldr, unwrapCombinatorSelector, combinators, styles),
			mPseudo);
	});
var $rtfeldman$elm_css$Css$Global$each = F2(
	function (snippetCreators, styles) {
		var selectorsToSnippet = function (selectors) {
			if (!selectors.b) {
				return _List_Nil;
			} else {
				var first = selectors.a;
				var rest = selectors.b;
				return _List_fromArray(
					[
						$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
						A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, first, rest, styles))
					]);
			}
		};
		return selectorsToSnippet(
			$rtfeldman$elm_css$Css$Global$collectSelectors(
				A2(
					$elm$core$List$concatMap,
					$rtfeldman$elm_css$Css$Preprocess$unwrapSnippet,
					A2(
						$elm$core$List$map,
						$elm$core$Basics$apR(_List_Nil),
						snippetCreators))));
	});
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$fontStyle = $rtfeldman$elm_css$Css$prop1('font-style');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.iY;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Css$Global$h1 = $rtfeldman$elm_css$Css$Global$typeSelector('h1');
var $rtfeldman$elm_css$Css$Global$h2 = $rtfeldman$elm_css$Css$Global$typeSelector('h2');
var $rtfeldman$elm_css$Css$Global$h3 = $rtfeldman$elm_css$Css$Global$typeSelector('h3');
var $rtfeldman$elm_css$Css$Global$h4 = $rtfeldman$elm_css$Css$Global$typeSelector('h4');
var $rtfeldman$elm_css$Css$Global$h5 = $rtfeldman$elm_css$Css$Global$typeSelector('h5');
var $rtfeldman$elm_css$Css$Global$h6 = $rtfeldman$elm_css$Css$Global$typeSelector('h6');
var $rtfeldman$elm_css$Css$Global$img = $rtfeldman$elm_css$Css$Global$typeSelector('img');
var $rtfeldman$elm_css$Css$inlineBlock = {u: 0, iY: 'inline-block'};
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		aL: 0,
		cA: 0,
		a_: 0,
		ax: 0,
		fD: 0,
		cM: 0,
		aO: val,
		bz: '',
		cf: 0,
		iY: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$italic = {bg: 0, iY: 'italic'};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $author$project$SE$UI$Colors$lightest = A4($author$project$SE$UI$Colors$Hsla, 0, 0, 0.96, 1);
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.iY, argB.iY])));
	});
var $rtfeldman$elm_css$Css$listStyle2 = $rtfeldman$elm_css$Css$prop2('list-style');
var $rtfeldman$elm_css$Css$listStylePosition = $rtfeldman$elm_css$Css$prop1('list-style-position');
var $rtfeldman$elm_css$Css$listStyleType = $rtfeldman$elm_css$Css$prop1('list-style-type');
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$marginRight = $rtfeldman$elm_css$Css$prop1('margin-right');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		a_: 0,
		ax: 0,
		fD: 0,
		cM: 0,
		aO: val,
		bz: '',
		cf: 0,
		iY: $elm$core$String$fromFloat(val)
	};
};
var $rtfeldman$elm_css$Css$Global$ol = $rtfeldman$elm_css$Css$Global$typeSelector('ol');
var $rtfeldman$elm_css$Css$outside = {cE: 0, c: 0, iY: 'outside'};
var $rtfeldman$elm_css$Css$overflowX = $rtfeldman$elm_css$Css$prop1('overflow-x');
var $rtfeldman$elm_css$Css$Global$p = $rtfeldman$elm_css$Css$Global$typeSelector('p');
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$pre = {iY: 'pre', aU: 0};
var $rtfeldman$elm_css$Css$Global$pre = $rtfeldman$elm_css$Css$Global$typeSelector('pre');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$Css$Global$selector = F2(
	function (selectorStr, styles) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
			styles,
			A2($rtfeldman$elm_css$Css$Structure$CustomSelector, selectorStr, _List_Nil));
	});
var $rtfeldman$elm_css$Css$solid = {Q: 0, a4: 0, iY: 'solid'};
var $rtfeldman$elm_css$Css$square = {e: 0, c: 0, iY: 'square'};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.g5) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.hT, record.g5, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{g5: decl, hT: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.eG;
	var imports = _v0.fe;
	var namespaces = _v0.fA;
	var declarations = _v0.g6;
	var _v1 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v1.a;
	var compactedDeclarations = _v1.b;
	var finalDeclarations = A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {eG: charset, g6: finalDeclarations, fe: imports, fA: namespaces};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.e0 + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.iY)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var $rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var $rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var $rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Css$Structure$Output$indent, $rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		$elm$core$String$append,
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2($rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, $rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$rtfeldman$elm_css$Css$Structure$Output$indent,
						$rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock($rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.hT;
			var declaration = decl.a.g5;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.eG;
	var imports = _v0.fe;
	var namespaces = _v0.fA;
	var declarations = _v0.g6;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {a9: charsProcessed, bj: hash, X: seed, bt: shift};
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1 = 3432918353;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize = function (data) {
	var acc = (!(!data.bj)) ? (data.X ^ A2(
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
		A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
			15,
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, data.bj)))) : data.X;
	var h0 = acc ^ data.a9;
	var h1 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
			5,
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
					A2(
						$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
						15,
						A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, k1))))) + 3864292196;
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.bj | ((255 & $elm$core$Char$toCode(c)) << data.bt);
		var _v0 = data.bt;
		if (_v0 === 24) {
			return {
				a9: data.a9 + 1,
				bj: 0,
				X: A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$mix, data.X, res),
				bt: 0
			};
		} else {
			return {a9: data.a9 + 1, bj: res, X: data.X, bt: data.bt + 8};
		}
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString = F2(
	function (seed, str) {
		return $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold,
				A4($rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$murmurSeed = 15739;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString, $rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{g5: str, hT: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.eG;
	var imports = _v0.fe;
	var namespaces = _v0.fA;
	var snippets = _v0.gd;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {eG: charset, g6: declarations, fe: imports, fA: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var $rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {eG: $elm$core$Maybe$Nothing, fe: _List_Nil, fA: _List_Nil, gd: snippets};
};
var $rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return $elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString,
				$rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					$elm$core$List$singleton(
						$rtfeldman$elm_css$Css$Preprocess$stylesheet(
							$elm$core$List$singleton(
								A2(
									$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = $rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		$elm$virtual_dom$VirtualDom$property,
		'className',
		$elm$json$Json$Encode$string(classname));
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var $rtfeldman$elm_css$Html$Styled$styled = F4(
	function (fn, styles, attrs, children) {
		return A2(
			fn,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Internal$css(styles),
				attrs),
			children);
	});
var $rtfeldman$elm_css$Css$Global$table = $rtfeldman$elm_css$Css$Global$typeSelector('table');
var $rtfeldman$elm_css$Css$Global$td = $rtfeldman$elm_css$Css$Global$typeSelector('td');
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$Global$tfoot = $rtfeldman$elm_css$Css$Global$typeSelector('tfoot');
var $rtfeldman$elm_css$Css$Global$th = $rtfeldman$elm_css$Css$Global$typeSelector('th');
var $rtfeldman$elm_css$Css$Global$thead = $rtfeldman$elm_css$Css$Global$typeSelector('thead');
var $rtfeldman$elm_css$Css$batch = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles;
var $author$project$SE$UI$Utils$desktopWidth = 1024;
var $rtfeldman$elm_css$Css$Media$feature = F2(
	function (key, _v0) {
		var value = _v0.iY;
		return {
			e0: key,
			iY: $elm$core$Maybe$Just(value)
		};
	});
var $rtfeldman$elm_css$Css$Media$minWidth = function (value) {
	return A2($rtfeldman$elm_css$Css$Media$feature, 'min-width', value);
};
var $rtfeldman$elm_css$Css$Structure$OnlyQuery = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$only = $rtfeldman$elm_css$Css$Structure$OnlyQuery;
var $rtfeldman$elm_css$Css$Structure$Screen = 1;
var $rtfeldman$elm_css$Css$Media$screen = 1;
var $rtfeldman$elm_css$Css$Preprocess$WithMedia = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$withMedia = $rtfeldman$elm_css$Css$Preprocess$WithMedia;
var $author$project$SE$UI$Utils$desktop = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$Media$only,
			$rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Media$minWidth(
					$rtfeldman$elm_css$Css$px($author$project$SE$UI$Utils$desktopWidth))
				]))
		]));
var $author$project$SE$UI$Font$desktopTitleScale = 1.2;
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$SE$UI$Font$mobileTitleScale = 1.125;
var $author$project$SE$UI$Font$titleSizeHelper = F2(
	function (fn, exponent) {
		var newExponent = (exponent >= 1) ? (exponent - 1) : exponent;
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$fontSize(
					fn(
						A2($elm$core$Basics$pow, $author$project$SE$UI$Font$mobileTitleScale, newExponent))),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							fn(
								A2($elm$core$Basics$pow, $author$project$SE$UI$Font$desktopTitleScale, newExponent)))
						]))
				]));
	});
var $author$project$SE$UI$Font$titleSizeEm = $author$project$SE$UI$Font$titleSizeHelper($rtfeldman$elm_css$Css$em);
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $elm$core$Basics$pi = _Basics_pi;
var $rtfeldman$elm_css$Css$degreesToRadians = function (degrees) {
	return (degrees * 180) / $elm$core$Basics$pi;
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $rtfeldman$elm_css$Css$fmod = F2(
	function (f, n) {
		var integer = $elm$core$Basics$floor(f);
		return (A2($elm$core$Basics$modBy, n, integer) + f) - integer;
	});
var $rtfeldman$elm_css$Css$hslToRgb = F3(
	function (hueVal, saturationVal, lightness) {
		var normHue = hueVal / $elm$core$Basics$degrees(60);
		var chroma = (1 - $elm$core$Basics$abs((2 * lightness) - 1)) * saturationVal;
		var m = lightness - (chroma / 2);
		var x = chroma * (1 - $elm$core$Basics$abs(
			A2($rtfeldman$elm_css$Css$fmod, normHue, 2) - 1));
		var _v0 = (normHue < 0) ? _Utils_Tuple3(0, 0, 0) : ((normHue < 1) ? _Utils_Tuple3(chroma, x, 0) : ((normHue < 2) ? _Utils_Tuple3(x, chroma, 0) : ((normHue < 3) ? _Utils_Tuple3(0, chroma, x) : ((normHue < 4) ? _Utils_Tuple3(0, x, chroma) : ((normHue < 5) ? _Utils_Tuple3(x, 0, chroma) : ((normHue < 6) ? _Utils_Tuple3(chroma, 0, x) : _Utils_Tuple3(0, 0, 0)))))));
		var r = _v0.a;
		var g = _v0.b;
		var b = _v0.c;
		return _Utils_Tuple3(r + m, g + m, b + m);
	});
var $rtfeldman$elm_css$Css$hslaToRgba = F5(
	function (value, hueVal, saturationVal, lightness, hslAlpha) {
		var _v0 = A3(
			$rtfeldman$elm_css$Css$hslToRgb,
			$rtfeldman$elm_css$Css$degreesToRadians(hueVal),
			saturationVal,
			lightness);
		var red = _v0.a;
		var green = _v0.b;
		var blue = _v0.c;
		return {
			bD: hslAlpha,
			bH: $elm$core$Basics$floor(blue),
			at: 0,
			bP: $elm$core$Basics$floor(green),
			b3: $elm$core$Basics$floor(red),
			iY: value
		};
	});
var $rtfeldman$elm_css$Css$numericalPercentageToString = function (value) {
	return $elm$core$String$fromFloat(value * 100) + '%';
};
var $rtfeldman$elm_css$Css$hsla = F4(
	function (hueVal, saturationVal, lightnessVal, alpha) {
		var valuesList = _List_fromArray(
			[
				$elm$core$String$fromFloat(hueVal),
				$rtfeldman$elm_css$Css$numericalPercentageToString(saturationVal),
				$rtfeldman$elm_css$Css$numericalPercentageToString(lightnessVal),
				$elm$core$String$fromFloat(alpha)
			]);
		var value = A2($rtfeldman$elm_css$Css$cssFunction, 'hsla', valuesList);
		return A5($rtfeldman$elm_css$Css$hslaToRgba, value, hueVal, saturationVal, lightnessVal, alpha);
	});
var $author$project$SE$UI$Colors$toCss = function (_v0) {
	var hue = _v0.a;
	var saturation = _v0.b;
	var lightness = _v0.c;
	var alpha = _v0.d;
	return A4($rtfeldman$elm_css$Css$hsla, hue, saturation, lightness, alpha);
};
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $rtfeldman$elm_css$Css$Global$ul = $rtfeldman$elm_css$Css$Global$typeSelector('ul');
var $rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$whiteSpace = $rtfeldman$elm_css$Css$prop1('white-space');
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $rtfeldman$elm_css$Css$zero = {b: 0, bl: 0, aM: 0, bm: 0, bn: 0, aZ: 0, a_: 0, fD: 0, aO: 0, cP: 0, bz: '', cf: 0, iY: '0'};
var $author$project$SE$UI$Content$content = function (attrs) {
	return A3(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$block,
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'li + li',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$em(0.25))
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$each,
						_List_fromArray(
							[$rtfeldman$elm_css$Css$Global$p, $rtfeldman$elm_css$Css$Global$dl, $rtfeldman$elm_css$Css$Global$ol, $rtfeldman$elm_css$Css$Global$ul, $rtfeldman$elm_css$Css$Global$blockquote, $rtfeldman$elm_css$Css$Global$pre, $rtfeldman$elm_css$Css$Global$table]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not(:last-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$em(1))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$each,
						_List_fromArray(
							[$rtfeldman$elm_css$Css$Global$h1, $rtfeldman$elm_css$Css$Global$h2, $rtfeldman$elm_css$Css$Global$h3, $rtfeldman$elm_css$Css$Global$h4, $rtfeldman$elm_css$Css$Global$h5, $rtfeldman$elm_css$Css$Global$h6]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(600)),
								$rtfeldman$elm_css$Css$lineHeight(
								$rtfeldman$elm_css$Css$num(1.3))
							])),
						$rtfeldman$elm_css$Css$Global$h1(
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeEm(6),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$em(0.5)),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not(:first-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$em(1))
									]))
							])),
						$rtfeldman$elm_css$Css$Global$h2(
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeEm(5),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$em(0.5714)),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not(:first-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$em(1.1428))
									]))
							])),
						$rtfeldman$elm_css$Css$Global$h3(
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeEm(4),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$em(0.6666)),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not(:first-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$em(1.3333))
									]))
							])),
						$rtfeldman$elm_css$Css$Global$h4(
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeEm(3),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$em(0.8))
							])),
						$rtfeldman$elm_css$Css$Global$h5(
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeEm(2),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$em(0.8888))
							])),
						$rtfeldman$elm_css$Css$Global$h6(
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeEm(1),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$em(1))
							])),
						$rtfeldman$elm_css$Css$Global$blockquote(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$backgroundColor(
								$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lightest)),
								A3(
								$rtfeldman$elm_css$Css$borderLeft3,
								$rtfeldman$elm_css$Css$px(5),
								$rtfeldman$elm_css$Css$solid,
								$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$em(1.25),
								$rtfeldman$elm_css$Css$em(1.5))
							])),
						$rtfeldman$elm_css$Css$Global$ol(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$listStylePosition($rtfeldman$elm_css$Css$outside),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(2)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$em(1)),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not([type])',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$listStyleType($rtfeldman$elm_css$Css$decimal)
									]))
							])),
						$rtfeldman$elm_css$Css$Global$ul(
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Css$listStyle2, $rtfeldman$elm_css$Css$disc, $rtfeldman$elm_css$Css$outside),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(2)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$em(1)),
								$rtfeldman$elm_css$Css$Global$descendants(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$ul(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$listStyleType($rtfeldman$elm_css$Css$circle),
												$rtfeldman$elm_css$Css$marginTop(
												$rtfeldman$elm_css$Css$em(0.5)),
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$Global$ul(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$listStyleType($rtfeldman$elm_css$Css$square)
															]))
													]))
											]))
									]))
							])),
						$rtfeldman$elm_css$Css$Global$dd(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(2))
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'figure',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(2)),
								$rtfeldman$elm_css$Css$marginRight(
								$rtfeldman$elm_css$Css$em(2)),
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not(:first-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginTop(
										$rtfeldman$elm_css$Css$em(2))
									])),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'not(:last-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$em(2))
									])),
								$rtfeldman$elm_css$Css$Global$descendants(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$img(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock)
											])),
										A2(
										$rtfeldman$elm_css$Css$Global$typeSelector,
										'figcaption',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$italic)
											]))
									]))
							])),
						$rtfeldman$elm_css$Css$Global$pre(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$overflowX($rtfeldman$elm_css$Css$auto),
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$em(1.25),
								$rtfeldman$elm_css$Css$em(1.5)),
								$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$pre),
								A2($rtfeldman$elm_css$Css$property, 'word-wrap', 'normal')
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'sup, sub',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$pct(75))
							])),
						$rtfeldman$elm_css$Css$Global$table(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$Global$descendants(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$each,
										_List_fromArray(
											[$rtfeldman$elm_css$Css$Global$td, $rtfeldman$elm_css$Css$Global$th]),
										_List_fromArray(
											[
												A3(
												$rtfeldman$elm_css$Css$border3,
												$rtfeldman$elm_css$Css$px(1),
												$rtfeldman$elm_css$Css$solid,
												$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
												A3(
												$rtfeldman$elm_css$Css$borderWidth3,
												$rtfeldman$elm_css$Css$zero,
												$rtfeldman$elm_css$Css$zero,
												$rtfeldman$elm_css$Css$px(1)),
												A2(
												$rtfeldman$elm_css$Css$padding2,
												$rtfeldman$elm_css$Css$em(0.5),
												$rtfeldman$elm_css$Css$em(0.75)),
												$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
											])),
										$rtfeldman$elm_css$Css$Global$th(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$color(
												$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$black)),
												$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
											])),
										$rtfeldman$elm_css$Css$Global$thead(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$each,
														_List_fromArray(
															[$rtfeldman$elm_css$Css$Global$th, $rtfeldman$elm_css$Css$Global$td]),
														_List_fromArray(
															[
																A3(
																$rtfeldman$elm_css$Css$borderWidth3,
																$rtfeldman$elm_css$Css$zero,
																$rtfeldman$elm_css$Css$zero,
																$rtfeldman$elm_css$Css$px(2)),
																$rtfeldman$elm_css$Css$color(
																$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$black))
															]))
													]))
											])),
										$rtfeldman$elm_css$Css$Global$tfoot(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$each,
														_List_fromArray(
															[$rtfeldman$elm_css$Css$Global$th, $rtfeldman$elm_css$Css$Global$td]),
														_List_fromArray(
															[
																A3(
																$rtfeldman$elm_css$Css$borderWidth3,
																$rtfeldman$elm_css$Css$px(2),
																$rtfeldman$elm_css$Css$zero,
																$rtfeldman$elm_css$Css$zero),
																$rtfeldman$elm_css$Css$color(
																$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$black))
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Css$Global$selector,
										'tbody tr:last-child td',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$borderBottomWidth($rtfeldman$elm_css$Css$zero)
											])),
										A2(
										$rtfeldman$elm_css$Css$Global$selector,
										'tbody tr:last-child th',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$borderBottomWidth($rtfeldman$elm_css$Css$zero)
											]))
									]))
							]))
					]))
			]),
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('content', true)
					])),
			attrs));
};
var $dillonkearns$elm_markdown$Markdown$Parser$problemToString = function (problem) {
	switch (problem.$) {
		case 0:
			var string = problem.a;
			return 'Expecting ' + string;
		case 1:
			return 'Expecting int';
		case 2:
			return 'Expecting hex';
		case 3:
			return 'Expecting octal';
		case 4:
			return 'Expecting binary';
		case 5:
			return 'Expecting float';
		case 6:
			return 'Expecting number';
		case 7:
			return 'Expecting variable';
		case 8:
			var string = problem.a;
			return 'Expecting symbol ' + string;
		case 9:
			var string = problem.a;
			return 'Expecting keyword ' + string;
		case 10:
			return 'Expecting keyword end';
		case 11:
			return 'Unexpected char';
		case 12:
			var problemDescription = problem.a;
			return problemDescription;
		default:
			return 'Bad repeat';
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString = function (deadEnd) {
	return 'Problem at row ' + ($elm$core$String$fromInt(deadEnd.iu) + ('\n' + $dillonkearns$elm_markdown$Markdown$Parser$problemToString(deadEnd.ij)));
};
var $author$project$Content$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $dillonkearns$elm_markdown$Markdown$Block$BlockQuote = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Cdata = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CodeBlock = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CodeSpan = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CompletedTask = 2;
var $dillonkearns$elm_markdown$Markdown$Block$Emphasis = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Parser$EmptyBlock = {$: 0};
var $dillonkearns$elm_markdown$Markdown$Block$HardLineBreak = {$: 7};
var $dillonkearns$elm_markdown$Markdown$Block$Heading = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$Html = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlBlock = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlComment = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$HtmlElement = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$HtmlInline = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Image = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$IncompleteTask = 1;
var $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Link = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$ListItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$NoTask = 0;
var $dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$OrderedList = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$Paragraph = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Strong = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Table = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$Text = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ThematicBreak = {$: 8};
var $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak = {$: 7};
var $dillonkearns$elm_markdown$Markdown$Block$UnorderedList = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$UnparsedInlines = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Parser$addReference = F2(
	function (state, linkRef) {
		return {
			a$: A2($elm$core$List$cons, linkRef, state.a$),
			az: state.az
		};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine = {$: 10};
var $dillonkearns$elm_markdown$Helpers$isSpaceOrTab = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Parser$Token$newline = A2(
	$elm$parser$Parser$Advanced$Token,
	'\n',
	$elm$parser$Parser$Expecting('a newline'));
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $dillonkearns$elm_markdown$Markdown$Parser$blankLine = A2(
	$elm$parser$Parser$Advanced$map,
	function (_v0) {
		return $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine;
	},
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$backtrackable(
			$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isSpaceOrTab)),
		$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline)));
var $dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote = function (a) {
	return {$: 11, a: a};
};
var $dillonkearns$elm_markdown$Parser$Token$space = A2(
	$elm$parser$Parser$Advanced$Token,
	' ',
	$elm$parser$Parser$Expecting('a space'));
var $dillonkearns$elm_markdown$Markdown$Parser$blockQuoteStarts = _List_fromArray(
	[
		$elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			'>',
			$elm$parser$Parser$Expecting('>'))),
		A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$backtrackable(
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space)),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'>',
						$elm$parser$Parser$Expecting(' >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						' >',
						$elm$parser$Parser$Expecting('  >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'  >',
						$elm$parser$Parser$Expecting('   >')))
				])))
	]);
var $elm$parser$Parser$Advanced$chompUntilEndOr = function (str) {
	return function (s) {
		var _v0 = A5(_Parser_findSubString, str, s.h4, s.iu, s.eJ, s.iI);
		var newOffset = _v0.a;
		var newRow = _v0.b;
		var newCol = _v0.c;
		var adjustedOffset = (newOffset < 0) ? $elm$core$String$length(s.iI) : newOffset;
		return A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.h4, adjustedOffset) < 0,
			0,
			{eJ: newCol, g: s.g, j: s.j, h4: adjustedOffset, iu: newRow, iI: s.iI});
	};
};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.iI),
			s.h4) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $dillonkearns$elm_markdown$Helpers$endOfFile = $elm$parser$Parser$Advanced$end(
	$elm$parser$Parser$Expecting('end of input'));
var $dillonkearns$elm_markdown$Helpers$endOfLineOrFile = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline),
			$dillonkearns$elm_markdown$Helpers$endOfFile
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$blockQuote = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote),
			$elm$parser$Parser$Advanced$oneOf($dillonkearns$elm_markdown$Markdown$Parser$blockQuoteStarts)),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space),
					$elm$parser$Parser$Advanced$succeed(0)
				]))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$dillonkearns$elm_markdown$Helpers$endOfLineOrFile));
var $dillonkearns$elm_markdown$Markdown$RawBlock$Heading = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$Table = function (a) {
	return {$: 8, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Table$Table = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Table$TableDelimiterRow = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith = F3(
	function (joinWith, string1, string2) {
		var _v0 = _Utils_Tuple2(string1, string2);
		if (_v0.a === '') {
			return string2;
		} else {
			if (_v0.b === '') {
				return string1;
			} else {
				return _Utils_ap(
					string1,
					_Utils_ap(joinWith, string2));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll = F2(
	function (string1, string2) {
		return string1 + ('\n' + string2);
	});
var $dillonkearns$elm_markdown$Markdown$Table$TableHeader = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Parser$Extra$tokenHelp = function (_char) {
	return $elm$parser$Parser$Advanced$token(
		A2(
			$elm$parser$Parser$Advanced$Token,
			_char,
			$elm$parser$Parser$Expecting(_char)));
};
var $dillonkearns$elm_markdown$Markdown$TableParser$parseCellHelper = function (_v0) {
	var curr = _v0.a;
	var acc = _v0.b;
	var _return = A2(
		$elm$core$Maybe$withDefault,
		$elm$parser$Parser$Advanced$Done(acc),
		A2(
			$elm$core$Maybe$map,
			function (cell) {
				return $elm$parser$Parser$Advanced$Done(
					A2($elm$core$List$cons, cell, acc));
			},
			curr));
	var finishCell = A2(
		$elm$core$Maybe$withDefault,
		$elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple2($elm$core$Maybe$Nothing, acc)),
		A2(
			$elm$core$Maybe$map,
			function (cell) {
				return $elm$parser$Parser$Advanced$Loop(
					_Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						A2($elm$core$List$cons, cell, acc)));
			},
			curr));
	var addToCurrent = function (c) {
		return _Utils_ap(
			A2($elm$core$Maybe$withDefault, '', curr),
			c);
	};
	var continueCell = function (c) {
		return $elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple2(
				$elm$core$Maybe$Just(
					addToCurrent(c)),
				acc));
	};
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v1) {
					return _return;
				},
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|\n')),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v2) {
					return _return;
				},
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\n')),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v3) {
					return _return;
				},
				$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('end'))),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(
						continueCell('|'))),
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\\\\|')),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(
						continueCell('\\'))),
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\\\\')),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(
						continueCell('|'))),
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\\|')),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(finishCell)),
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|')),
				A2(
				$elm$parser$Parser$Advanced$mapChompedString,
				F2(
					function (_char, _v4) {
						return continueCell(_char);
					}),
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					$elm$core$Basics$always(true),
					$elm$parser$Parser$Problem('No character found')))
			]));
};
var $elm$core$String$trim = _String_trim;
var $dillonkearns$elm_markdown$Markdown$TableParser$parseCells = A2(
	$elm$parser$Parser$Advanced$map,
	A2(
		$elm$core$List$foldl,
		F2(
			function (cell, acc) {
				return A2(
					$elm$core$List$cons,
					$elm$core$String$trim(cell),
					acc);
			}),
		_List_Nil),
	A2(
		$elm$parser$Parser$Advanced$loop,
		_Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil),
		$dillonkearns$elm_markdown$Markdown$TableParser$parseCellHelper));
var $dillonkearns$elm_markdown$Markdown$TableParser$rowParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|'),
					$elm$parser$Parser$Advanced$succeed(0)
				]))),
	$dillonkearns$elm_markdown$Markdown$TableParser$parseCells);
var $dillonkearns$elm_markdown$Markdown$TableParser$parseHeader = F2(
	function (_v0, headersRow) {
		var columnAlignments = _v0.b;
		var headersWithAlignment = function (headers) {
			return A3(
				$elm$core$List$map2,
				F2(
					function (headerCell, alignment) {
						return {ci: alignment, cB: headerCell};
					}),
				headers,
				columnAlignments);
		};
		var combineHeaderAndDelimiter = function (headers) {
			return _Utils_eq(
				$elm$core$List$length(headers),
				$elm$core$List$length(columnAlignments)) ? $elm$core$Result$Ok(
				headersWithAlignment(headers)) : $elm$core$Result$Err(
				'Tables must have the same number of header columns (' + ($elm$core$String$fromInt(
					$elm$core$List$length(headers)) + (') as delimiter columns (' + ($elm$core$String$fromInt(
					$elm$core$List$length(columnAlignments)) + ')'))));
		};
		var _v1 = A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$TableParser$rowParser, headersRow);
		if (!_v1.$) {
			var headers = _v1.a;
			return combineHeaderAndDelimiter(headers);
		} else {
			return $elm$core$Result$Err('Unable to parse previous line as a table header');
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks = F2(
	function (state, newRawBlock) {
		return {
			a$: state.a$,
			az: function () {
				var _v0 = _Utils_Tuple2(newRawBlock, state.az);
				_v0$9:
				while (true) {
					if (_v0.b.b) {
						switch (_v0.b.a.$) {
							case 5:
								if (_v0.a.$ === 5) {
									var block1 = _v0.a.a;
									var _v1 = _v0.b;
									var block2 = _v1.a.a;
									var rest = _v1.b;
									return A2(
										$elm$core$List$cons,
										$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock(
											{
												bI: A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block2.bI, block1.bI),
												fr: $elm$core$Maybe$Nothing
											}),
										rest);
								} else {
									break _v0$9;
								}
							case 6:
								if (_v0.a.$ === 6) {
									var block1 = _v0.a.a;
									var _v2 = _v0.b;
									var block2 = _v2.a.a;
									var rest = _v2.b;
									return A2(
										$elm$core$List$cons,
										$dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock(
											A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block2, block1)),
										rest);
								} else {
									break _v0$9;
								}
							case 11:
								switch (_v0.a.$) {
									case 1:
										var body1 = _v0.a.a;
										var _v3 = _v0.b;
										var body2 = _v3.a.a;
										var rest = _v3.b;
										return A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote(
												A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', body2, body1)),
											rest);
									case 11:
										var body1 = _v0.a.a;
										var _v4 = _v0.b;
										var body2 = _v4.a.a;
										var rest = _v4.b;
										return A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote(
												A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, body2, body1)),
											rest);
									default:
										break _v0$9;
								}
							case 1:
								switch (_v0.a.$) {
									case 1:
										var body1 = _v0.a.a;
										var _v5 = _v0.b;
										var body2 = _v5.a.a;
										var rest = _v5.b;
										return A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(
												A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', body2, body1)),
											rest);
									case 12:
										if (!_v0.a.a) {
											var _v6 = _v0.a;
											var _v7 = _v6.a;
											var _v8 = _v0.b;
											var unparsedInlines = _v8.a.a;
											var rest = _v8.b;
											return A2(
												$elm$core$List$cons,
												A2($dillonkearns$elm_markdown$Markdown$RawBlock$Heading, 1, unparsedInlines),
												rest);
										} else {
											var _v9 = _v0.a;
											var _v10 = _v9.a;
											var _v11 = _v0.b;
											var unparsedInlines = _v11.a.a;
											var rest = _v11.b;
											return A2(
												$elm$core$List$cons,
												A2($dillonkearns$elm_markdown$Markdown$RawBlock$Heading, 2, unparsedInlines),
												rest);
										}
									case 9:
										var _v12 = _v0.a.a;
										var text = _v12.a;
										var alignments = _v12.b;
										var _v13 = _v0.b;
										var rawHeaders = _v13.a.a;
										var rest = _v13.b;
										var _v14 = A2(
											$dillonkearns$elm_markdown$Markdown$TableParser$parseHeader,
											A2($dillonkearns$elm_markdown$Markdown$Table$TableDelimiterRow, text, alignments),
											rawHeaders);
										if (!_v14.$) {
											var headers = _v14.a;
											return A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$Table(
													A2($dillonkearns$elm_markdown$Markdown$Table$Table, headers, _List_Nil)),
												rest);
										} else {
											return A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(
													A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', rawHeaders, text.fY)),
												rest);
										}
									default:
										break _v0$9;
								}
							case 8:
								if (_v0.a.$ === 8) {
									var updatedTable = _v0.a.a;
									var _v15 = _v0.b;
									var rest = _v15.b;
									return A2(
										$elm$core$List$cons,
										$dillonkearns$elm_markdown$Markdown$RawBlock$Table(updatedTable),
										rest);
								} else {
									break _v0$9;
								}
							default:
								break _v0$9;
						}
					} else {
						break _v0$9;
					}
				}
				return A2($elm$core$List$cons, newRawBlock, state.az);
			}()
		};
	});
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $dillonkearns$elm_markdown$HtmlParser$Cdata = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$Element = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$HtmlParser$Text = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$expectTagNameCharacter = $elm$parser$Parser$Expecting('at least 1 tag name character');
var $dillonkearns$elm_markdown$HtmlParser$tagNameCharacter = function (c) {
	switch (c) {
		case ' ':
			return false;
		case '\u000D':
			return false;
		case '\n':
			return false;
		case '\t':
			return false;
		case '/':
			return false;
		case '<':
			return false;
		case '>':
			return false;
		case '\"':
			return false;
		case '\'':
			return false;
		case '=':
			return false;
		default:
			return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$tagName = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (name, _v0) {
			return $elm$core$String$toLower(name);
		}),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2($elm$parser$Parser$Advanced$chompIf, $dillonkearns$elm_markdown$HtmlParser$tagNameCharacter, $dillonkearns$elm_markdown$HtmlParser$expectTagNameCharacter),
		$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$tagNameCharacter)));
var $dillonkearns$elm_markdown$HtmlParser$attributeName = $dillonkearns$elm_markdown$HtmlParser$tagName;
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$token(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $dillonkearns$elm_markdown$HtmlParser$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('quot', '\"')
		]));
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $dillonkearns$elm_markdown$HtmlParser$decodeEscape = function (s) {
	return A2($elm$core$String$startsWith, '#x', s) ? A2(
		$elm$core$Result$mapError,
		$elm$parser$Parser$Problem,
		A2(
			$elm$core$Result$map,
			$elm$core$Char$fromCode,
			$rtfeldman$elm_hex$Hex$fromString(
				A2($elm$core$String$dropLeft, 2, s)))) : (A2($elm$core$String$startsWith, '#', s) ? A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('Invalid escaped character: ' + s),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Char$fromCode,
			$elm$core$String$toInt(
				A2($elm$core$String$dropLeft, 1, s)))) : A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('No entity named \"&' + (s + ';\" found.')),
		A2($elm$core$Dict$get, s, $dillonkearns$elm_markdown$HtmlParser$entities)));
};
var $dillonkearns$elm_markdown$HtmlParser$escapedChar = function (end_) {
	var process = function (entityStr) {
		var _v0 = $dillonkearns$elm_markdown$HtmlParser$decodeEscape(entityStr);
		if (!_v0.$) {
			var c = _v0.a;
			return $elm$parser$Parser$Advanced$succeed(c);
		} else {
			var e = _v0.a;
			return $elm$parser$Parser$Advanced$problem(e);
		}
	};
	var isEntityChar = function (c) {
		return (!_Utils_eq(c, end_)) && (c !== ';');
	};
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$dillonkearns$elm_markdown$HtmlParser$symbol('&')),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$andThen,
				process,
				$elm$parser$Parser$Advanced$getChompedString(
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$chompIf,
							isEntityChar,
							$elm$parser$Parser$Expecting('an entity character')),
						$elm$parser$Parser$Advanced$chompWhile(isEntityChar)))),
			$dillonkearns$elm_markdown$HtmlParser$symbol(';')));
};
var $dillonkearns$elm_markdown$HtmlParser$textStringStep = F3(
	function (closingChar, predicate, accum) {
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (soFar) {
				return $elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$map,
							function (escaped) {
								return $elm$parser$Parser$Advanced$Loop(
									_Utils_ap(
										accum,
										_Utils_ap(
											soFar,
											$elm$core$String$fromChar(escaped))));
							},
							$dillonkearns$elm_markdown$HtmlParser$escapedChar(closingChar)),
							$elm$parser$Parser$Advanced$succeed(
							$elm$parser$Parser$Advanced$Done(
								_Utils_ap(accum, soFar)))
						]));
			},
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompWhile(predicate)));
	});
var $dillonkearns$elm_markdown$HtmlParser$textString = function (closingChar) {
	var predicate = function (c) {
		return (!_Utils_eq(c, closingChar)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$Advanced$loop,
		'',
		A2($dillonkearns$elm_markdown$HtmlParser$textStringStep, closingChar, predicate));
};
var $dillonkearns$elm_markdown$HtmlParser$attributeValue = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\"')),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$HtmlParser$textString('\"'),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\"'))),
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\'')),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$HtmlParser$textString('\''),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\'')))
		]));
var $dillonkearns$elm_markdown$HtmlParser$keepOldest = F2(
	function (_new, mValue) {
		if (!mValue.$) {
			var v = mValue.a;
			return $elm$core$Maybe$Just(v);
		} else {
			return $elm$core$Maybe$Just(_new);
		}
	});
var $dillonkearns$elm_markdown$HtmlParser$isWhitespace = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\u000D':
			return true;
		case '\n':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$whiteSpace = $elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$isWhitespace);
var $dillonkearns$elm_markdown$HtmlParser$attributesStep = function (attrs) {
	var process = F2(
		function (name, value) {
			return $elm$parser$Parser$Advanced$Loop(
				A3(
					$elm$core$Dict$update,
					$elm$core$String$toLower(name),
					$dillonkearns$elm_markdown$HtmlParser$keepOldest(value),
					attrs));
		});
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(process),
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributeName, $dillonkearns$elm_markdown$HtmlParser$whiteSpace),
							$dillonkearns$elm_markdown$HtmlParser$symbol('=')),
						$dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
				A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributeValue, $dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
				$elm$parser$Parser$Advanced$succeed(
				$elm$parser$Parser$Advanced$Done(attrs))
			]));
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $dillonkearns$elm_markdown$HtmlParser$attributes = A2(
	$elm$parser$Parser$Advanced$map,
	A2(
		$elm$core$Dict$foldl,
		F3(
			function (key, value, accum) {
				return A2(
					$elm$core$List$cons,
					{hT: key, iY: value},
					accum);
			}),
		_List_Nil),
	A2($elm$parser$Parser$Advanced$loop, $elm$core$Dict$empty, $dillonkearns$elm_markdown$HtmlParser$attributesStep));
var $dillonkearns$elm_markdown$HtmlParser$cdata = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
		$dillonkearns$elm_markdown$HtmlParser$symbol('<![CDATA[')),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr(']]>')),
		$dillonkearns$elm_markdown$HtmlParser$symbol(']]>')));
var $dillonkearns$elm_markdown$HtmlParser$childrenStep = F2(
	function (options, accum) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (f) {
				return f(accum);
			},
			$elm$parser$Parser$Advanced$oneOf(options));
	});
var $dillonkearns$elm_markdown$HtmlParser$fail = function (str) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(str));
};
var $dillonkearns$elm_markdown$HtmlParser$closingTag = function (startTagName) {
	var closingTagName = A2(
		$elm$parser$Parser$Advanced$andThen,
		function (endTagName) {
			return _Utils_eq(startTagName, endTagName) ? $elm$parser$Parser$Advanced$succeed(0) : $dillonkearns$elm_markdown$HtmlParser$fail('tag name mismatch: ' + (startTagName + (' and ' + endTagName)));
		},
		$dillonkearns$elm_markdown$HtmlParser$tagName);
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$HtmlParser$symbol('</'),
					$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
				closingTagName),
			$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
		$dillonkearns$elm_markdown$HtmlParser$symbol('>'));
};
var $dillonkearns$elm_markdown$HtmlParser$Comment = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $dillonkearns$elm_markdown$HtmlParser$comment = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Comment),
		$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$HtmlParser$toToken('<!--'))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('-->')),
		$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$HtmlParser$toToken('-->'))));
var $dillonkearns$elm_markdown$HtmlParser$Declaration = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $dillonkearns$elm_markdown$HtmlParser$expectUppercaseCharacter = $elm$parser$Parser$Expecting('at least 1 uppercase character');
var $dillonkearns$elm_markdown$HtmlParser$allUppercase = $elm$parser$Parser$Advanced$getChompedString(
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isUpper, $dillonkearns$elm_markdown$HtmlParser$expectUppercaseCharacter),
		$elm$parser$Parser$Advanced$chompWhile($elm$core$Char$isUpper)));
var $dillonkearns$elm_markdown$HtmlParser$oneOrMoreWhiteSpace = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$chompIf,
		$dillonkearns$elm_markdown$HtmlParser$isWhitespace,
		$elm$parser$Parser$Expecting('at least one whitespace')),
	$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$isWhitespace));
var $dillonkearns$elm_markdown$HtmlParser$docType = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Declaration),
			$dillonkearns$elm_markdown$HtmlParser$symbol('<!')),
		A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$allUppercase, $dillonkearns$elm_markdown$HtmlParser$oneOrMoreWhiteSpace)),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('>')),
		$dillonkearns$elm_markdown$HtmlParser$symbol('>')));
var $dillonkearns$elm_markdown$HtmlParser$ProcessingInstruction = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$processingInstruction = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$ProcessingInstruction),
		$dillonkearns$elm_markdown$HtmlParser$symbol('<?')),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('?>')),
		$dillonkearns$elm_markdown$HtmlParser$symbol('?>')));
var $dillonkearns$elm_markdown$HtmlParser$isNotTextNodeIgnoreChar = function (c) {
	switch (c) {
		case '<':
			return false;
		case '&':
			return false;
		default:
			return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$textNodeStringStepOptions = _List_fromArray(
	[
		A2(
		$elm$parser$Parser$Advanced$map,
		function (_v0) {
			return $elm$parser$Parser$Advanced$Loop(0);
		},
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$chompIf,
				$dillonkearns$elm_markdown$HtmlParser$isNotTextNodeIgnoreChar,
				$elm$parser$Parser$Expecting('is not & or <')),
			$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$isNotTextNodeIgnoreChar))),
		A2(
		$elm$parser$Parser$Advanced$map,
		function (_v1) {
			return $elm$parser$Parser$Advanced$Loop(0);
		},
		$dillonkearns$elm_markdown$HtmlParser$escapedChar('<')),
		$elm$parser$Parser$Advanced$succeed(
		$elm$parser$Parser$Advanced$Done(0))
	]);
var $dillonkearns$elm_markdown$HtmlParser$textNodeStringStep = function (_v0) {
	return $elm$parser$Parser$Advanced$oneOf($dillonkearns$elm_markdown$HtmlParser$textNodeStringStepOptions);
};
var $dillonkearns$elm_markdown$HtmlParser$textNodeString = $elm$parser$Parser$Advanced$getChompedString(
	A2($elm$parser$Parser$Advanced$loop, 0, $dillonkearns$elm_markdown$HtmlParser$textNodeStringStep));
var $dillonkearns$elm_markdown$HtmlParser$children = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$loop,
		_List_Nil,
		$dillonkearns$elm_markdown$HtmlParser$childrenStep(
			$dillonkearns$elm_markdown$HtmlParser$childrenStepOptions(startTagName)));
};
var $dillonkearns$elm_markdown$HtmlParser$childrenStepOptions = function (startTagName) {
	return _List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$map,
			F2(
				function (_v1, accum) {
					return $elm$parser$Parser$Advanced$Done(
						$elm$core$List$reverse(accum));
				}),
			$dillonkearns$elm_markdown$HtmlParser$closingTag(startTagName)),
			A2(
			$elm$parser$Parser$Advanced$andThen,
			function (text) {
				return $elm$core$String$isEmpty(text) ? A2(
					$elm$parser$Parser$Advanced$map,
					F2(
						function (_v2, accum) {
							return $elm$parser$Parser$Advanced$Done(
								$elm$core$List$reverse(accum));
						}),
					$dillonkearns$elm_markdown$HtmlParser$closingTag(startTagName)) : $elm$parser$Parser$Advanced$succeed(
					function (accum) {
						return $elm$parser$Parser$Advanced$Loop(
							A2(
								$elm$core$List$cons,
								$dillonkearns$elm_markdown$HtmlParser$Text(text),
								accum));
					});
			},
			$dillonkearns$elm_markdown$HtmlParser$textNodeString),
			A2(
			$elm$parser$Parser$Advanced$map,
			F2(
				function (_new, accum) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($elm$core$List$cons, _new, accum));
				}),
			$dillonkearns$elm_markdown$HtmlParser$cyclic$html())
		]);
};
var $dillonkearns$elm_markdown$HtmlParser$elementContinuation = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(
					$dillonkearns$elm_markdown$HtmlParser$Element(startTagName)),
				$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
			A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributes, $dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return _List_Nil;
					},
					$dillonkearns$elm_markdown$HtmlParser$symbol('/>')),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
						$dillonkearns$elm_markdown$HtmlParser$symbol('>')),
					$dillonkearns$elm_markdown$HtmlParser$children(startTagName))
				])));
};
function $dillonkearns$elm_markdown$HtmlParser$cyclic$html() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2($elm$parser$Parser$Advanced$map, $dillonkearns$elm_markdown$HtmlParser$Cdata, $dillonkearns$elm_markdown$HtmlParser$cdata),
				$dillonkearns$elm_markdown$HtmlParser$processingInstruction,
				$dillonkearns$elm_markdown$HtmlParser$comment,
				$dillonkearns$elm_markdown$HtmlParser$docType,
				$dillonkearns$elm_markdown$HtmlParser$cyclic$element()
			]));
}
function $dillonkearns$elm_markdown$HtmlParser$cyclic$element() {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$dillonkearns$elm_markdown$HtmlParser$symbol('<')),
		A2($elm$parser$Parser$Advanced$andThen, $dillonkearns$elm_markdown$HtmlParser$elementContinuation, $dillonkearns$elm_markdown$HtmlParser$tagName));
}
var $dillonkearns$elm_markdown$HtmlParser$html = $dillonkearns$elm_markdown$HtmlParser$cyclic$html();
$dillonkearns$elm_markdown$HtmlParser$cyclic$html = function () {
	return $dillonkearns$elm_markdown$HtmlParser$html;
};
var $dillonkearns$elm_markdown$HtmlParser$element = $dillonkearns$elm_markdown$HtmlParser$cyclic$element();
$dillonkearns$elm_markdown$HtmlParser$cyclic$element = function () {
	return $dillonkearns$elm_markdown$HtmlParser$element;
};
var $dillonkearns$elm_markdown$Parser$Token$tab = A2(
	$elm$parser$Parser$Advanced$Token,
	'\t',
	$elm$parser$Parser$Expecting('a tab'));
var $dillonkearns$elm_markdown$Markdown$Parser$exactlyFourSpaces = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$tab),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$backtrackable(
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space)),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'   ',
							$elm$parser$Parser$ExpectingSymbol('Indentation'))),
						$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							' \t',
							$elm$parser$Parser$ExpectingSymbol('Indentation'))),
						$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'  \t',
							$elm$parser$Parser$ExpectingSymbol('Indentation')))
					])))
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$indentedCodeBlock = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock),
		$dillonkearns$elm_markdown$Markdown$Parser$exactlyFourSpaces),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$dillonkearns$elm_markdown$Helpers$endOfLineOrFile));
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$innerParagraphParser = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (rawLine, _v0) {
			return $dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(rawLine);
		}),
	$elm$parser$Parser$Advanced$chompUntilEndOr('\n'));
var $dillonkearns$elm_markdown$Markdown$Parser$openBlockOrParagraphParser = A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$Markdown$Parser$innerParagraphParser, $dillonkearns$elm_markdown$Helpers$endOfLineOrFile);
var $dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Parser$Token$closingParen = A2(
	$elm$parser$Parser$Advanced$Token,
	')',
	$elm$parser$Parser$Expecting('a `)`'));
var $dillonkearns$elm_markdown$Parser$Token$dot = A2(
	$elm$parser$Parser$Advanced$Token,
	'.',
	$elm$parser$Parser$Expecting('a `.`'));
var $dillonkearns$elm_markdown$Markdown$OrderedList$endOrNewline = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline),
			$elm$parser$Parser$Advanced$end(
			$elm$parser$Parser$Expecting('end of input'))
		]));
var $dillonkearns$elm_markdown$Parser$Extra$oneOrMore = function (condition) {
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$chompIf,
			condition,
			$elm$parser$Parser$Problem('Expected one or more character')),
		$elm$parser$Parser$Advanced$chompWhile(condition));
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$itemBody = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpaceOrTab)),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
				$dillonkearns$elm_markdown$Markdown$OrderedList$endOrNewline)),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(''),
			$dillonkearns$elm_markdown$Markdown$OrderedList$endOrNewline)
		]));
var $dillonkearns$elm_markdown$Parser$Extra$positiveInteger = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($elm$core$Char$isDigit));
var $dillonkearns$elm_markdown$Markdown$OrderedList$singleItemParser = function (listMarker) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$Parser$Extra$positiveInteger,
					$elm$parser$Parser$Advanced$symbol(listMarker)))),
		$dillonkearns$elm_markdown$Markdown$OrderedList$itemBody);
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$statementsHelp = F2(
	function (itemParser, revStmts) {
		return $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (stmt) {
						return $elm$parser$Parser$Advanced$Loop(
							A2($elm$core$List$cons, stmt, revStmts));
					},
					itemParser),
					$elm$parser$Parser$Advanced$succeed(
					$elm$parser$Parser$Advanced$Done(
						$elm$core$List$reverse(revStmts)))
				]));
	});
var $dillonkearns$elm_markdown$Markdown$OrderedList$parseSubsequentItems = F3(
	function (startingIndex, listMarker, firstItem) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (items) {
				return _Utils_Tuple2(
					startingIndex,
					A2($elm$core$List$cons, firstItem, items));
			},
			A2(
				$elm$parser$Parser$Advanced$loop,
				_List_Nil,
				$dillonkearns$elm_markdown$Markdown$OrderedList$statementsHelp(
					$dillonkearns$elm_markdown$Markdown$OrderedList$singleItemParser(listMarker))));
	});
var $dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (parsed) {
		return (parsed <= 999999999) ? $elm$parser$Parser$Advanced$succeed(parsed) : $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Starting numbers must be nine digits or less.'));
	},
	$dillonkearns$elm_markdown$Parser$Extra$positiveInteger);
var $dillonkearns$elm_markdown$Markdown$OrderedList$validateStartsWith1 = function (parsed) {
	if (parsed === 1) {
		return $elm$parser$Parser$Advanced$succeed(parsed);
	} else {
		return $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Lists inside a paragraph or after a paragraph without a blank line must start with 1'));
	}
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$parser = function (previousWasBody) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		$elm$core$Basics$identity,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$OrderedList$parseSubsequentItems),
					$elm$parser$Parser$Advanced$backtrackable(
						previousWasBody ? A2($elm$parser$Parser$Advanced$andThen, $dillonkearns$elm_markdown$Markdown$OrderedList$validateStartsWith1, $dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits) : $dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits)),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$backtrackable(
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Parser$Token$dot),
									$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$dot)),
									A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Parser$Token$closingParen),
									$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$closingParen))
								]))),
					$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpaceOrTab))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
				$dillonkearns$elm_markdown$Markdown$OrderedList$endOrNewline)));
};
var $dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock = function (previousWasBody) {
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (_v0) {
			var startingIndex = _v0.a;
			var unparsedLines = _v0.b;
			return A2(
				$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
				startingIndex,
				A2($elm$core$List$map, $elm$core$Basics$identity, unparsedLines));
		},
		$dillonkearns$elm_markdown$Markdown$OrderedList$parser(previousWasBody));
};
var $dillonkearns$elm_markdown$Markdown$Inline$CodeInline = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Emphasis = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$HardLineBreak = {$: 1};
var $dillonkearns$elm_markdown$Markdown$Inline$HtmlInline = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Image = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$Link = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$Text = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$matchToInline = function (_v0) {
	var match = _v0;
	var _v1 = match.iT;
	switch (_v1.$) {
		case 0:
			return $dillonkearns$elm_markdown$Markdown$Inline$Text(match.s);
		case 1:
			return $dillonkearns$elm_markdown$Markdown$Inline$HardLineBreak;
		case 2:
			return $dillonkearns$elm_markdown$Markdown$Inline$CodeInline(match.s);
		case 3:
			var _v2 = _v1.a;
			var text = _v2.a;
			var url = _v2.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Link,
				url,
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Inline$Text(text)
					]));
		case 4:
			var _v3 = _v1.a;
			var url = _v3.a;
			var maybeTitle = _v3.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Link,
				url,
				maybeTitle,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.D));
		case 5:
			var _v4 = _v1.a;
			var url = _v4.a;
			var maybeTitle = _v4.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Image,
				url,
				maybeTitle,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.D));
		case 6:
			var model = _v1.a;
			return $dillonkearns$elm_markdown$Markdown$Inline$HtmlInline(model);
		default:
			var length = _v1.a;
			return A2(
				$dillonkearns$elm_markdown$Markdown$Inline$Emphasis,
				length,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.D));
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines = function (matches) {
	return A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$InlineParser$matchToInline, matches);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Match = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch = F2(
	function (parentMatch, childMatch) {
		return {i: childMatch.i - parentMatch.A, D: childMatch.D, o: childMatch.o - parentMatch.A, s: childMatch.s, P: childMatch.P - parentMatch.A, A: childMatch.A - parentMatch.A, iT: childMatch.iT};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$addChild = F2(
	function (parentMatch, childMatch) {
		return {
			i: parentMatch.i,
			D: A2(
				$elm$core$List$cons,
				A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch, parentMatch, childMatch),
				parentMatch.D),
			o: parentMatch.o,
			s: parentMatch.s,
			P: parentMatch.P,
			A: parentMatch.A,
			iT: parentMatch.iT
		};
	});
var $elm$core$List$sortBy = _List_sortBy;
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeChildren = function (_v4) {
	var match = _v4;
	return {
		i: match.i,
		D: $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches(match.D),
		o: match.o,
		s: match.s,
		P: match.P,
		A: match.A,
		iT: match.iT
	};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches = function (matches) {
	var _v2 = A2(
		$elm$core$List$sortBy,
		function (_v3) {
			var match = _v3;
			return match.o;
		},
		matches);
	if (!_v2.b) {
		return _List_Nil;
	} else {
		var first = _v2.a;
		var rest = _v2.b;
		return A3($dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatchesHelp, rest, first, _List_Nil);
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatchesHelp = F3(
	function (remaining, _v0, matchesTail) {
		organizeMatchesHelp:
		while (true) {
			var prevMatch = _v0;
			if (!remaining.b) {
				return A2(
					$elm$core$List$cons,
					$dillonkearns$elm_markdown$Markdown$InlineParser$organizeChildren(prevMatch),
					matchesTail);
			} else {
				var match = remaining.a;
				var rest = remaining.b;
				if (_Utils_cmp(prevMatch.i, match.o) < 1) {
					var $temp$remaining = rest,
						$temp$_v0 = match,
						$temp$matchesTail = A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$organizeChildren(prevMatch),
						matchesTail);
					remaining = $temp$remaining;
					_v0 = $temp$_v0;
					matchesTail = $temp$matchesTail;
					continue organizeMatchesHelp;
				} else {
					if ((_Utils_cmp(prevMatch.o, match.o) < 0) && (_Utils_cmp(prevMatch.i, match.i) > 0)) {
						var $temp$remaining = rest,
							$temp$_v0 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$addChild, prevMatch, match),
							$temp$matchesTail = matchesTail;
						remaining = $temp$remaining;
						_v0 = $temp$_v0;
						matchesTail = $temp$matchesTail;
						continue organizeMatchesHelp;
					} else {
						var $temp$remaining = rest,
							$temp$_v0 = prevMatch,
							$temp$matchesTail = matchesTail;
						remaining = $temp$remaining;
						_v0 = $temp$_v0;
						matchesTail = $temp$matchesTail;
						continue organizeMatchesHelp;
					}
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType = {$: 0};
var $dillonkearns$elm_markdown$Markdown$Helpers$containsAmpersand = function (string) {
	return A2($elm$core$String$contains, '&', string);
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {hA: index, bU: match, fD: number, eb: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{gU: false, hS: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $dillonkearns$elm_markdown$Markdown$Entity$decimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#([0-9]{1,8});'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $dillonkearns$elm_markdown$Markdown$Entity$isBadEndUnicode = function (_int) {
	var remain_ = A2($elm$core$Basics$modBy, 16, _int);
	var remain = A2($elm$core$Basics$modBy, 131070, _int);
	return (_int >= 131070) && ((((0 <= remain) && (remain <= 15)) || ((65536 <= remain) && (remain <= 65551))) && ((remain_ === 14) || (remain_ === 15)));
};
var $dillonkearns$elm_markdown$Markdown$Entity$isValidUnicode = function (_int) {
	return (_int === 9) || ((_int === 10) || ((_int === 13) || ((_int === 133) || (((32 <= _int) && (_int <= 126)) || (((160 <= _int) && (_int <= 55295)) || (((57344 <= _int) && (_int <= 64975)) || (((65008 <= _int) && (_int <= 65533)) || ((65536 <= _int) && (_int <= 1114109)))))))));
};
var $dillonkearns$elm_markdown$Markdown$Entity$validUnicode = function (_int) {
	return ($dillonkearns$elm_markdown$Markdown$Entity$isValidUnicode(_int) && (!$dillonkearns$elm_markdown$Markdown$Entity$isBadEndUnicode(_int))) ? $elm$core$String$fromChar(
		$elm$core$Char$fromCode(_int)) : $elm$core$String$fromChar(
		$elm$core$Char$fromCode(65533));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimal = function (match) {
	var _v0 = match.eb;
	if (_v0.b && (!_v0.a.$)) {
		var first = _v0.a.a;
		var _v1 = $elm$core$String$toInt(first);
		if (!_v1.$) {
			var v = _v1.a;
			return $dillonkearns$elm_markdown$Markdown$Entity$validUnicode(v);
		} else {
			return match.bU;
		}
	} else {
		return match.bU;
	}
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimals = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$decimalRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimal);
var $dillonkearns$elm_markdown$Markdown$Entity$entitiesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&([0-9a-zA-Z]+);'));
var $dillonkearns$elm_markdown$Markdown$Entity$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('quot', 34),
			_Utils_Tuple2('amp', 38),
			_Utils_Tuple2('apos', 39),
			_Utils_Tuple2('lt', 60),
			_Utils_Tuple2('gt', 62),
			_Utils_Tuple2('nbsp', 160),
			_Utils_Tuple2('iexcl', 161),
			_Utils_Tuple2('cent', 162),
			_Utils_Tuple2('pound', 163),
			_Utils_Tuple2('curren', 164),
			_Utils_Tuple2('yen', 165),
			_Utils_Tuple2('brvbar', 166),
			_Utils_Tuple2('sect', 167),
			_Utils_Tuple2('uml', 168),
			_Utils_Tuple2('copy', 169),
			_Utils_Tuple2('ordf', 170),
			_Utils_Tuple2('laquo', 171),
			_Utils_Tuple2('not', 172),
			_Utils_Tuple2('shy', 173),
			_Utils_Tuple2('reg', 174),
			_Utils_Tuple2('macr', 175),
			_Utils_Tuple2('deg', 176),
			_Utils_Tuple2('plusmn', 177),
			_Utils_Tuple2('sup2', 178),
			_Utils_Tuple2('sup3', 179),
			_Utils_Tuple2('acute', 180),
			_Utils_Tuple2('micro', 181),
			_Utils_Tuple2('para', 182),
			_Utils_Tuple2('middot', 183),
			_Utils_Tuple2('cedil', 184),
			_Utils_Tuple2('sup1', 185),
			_Utils_Tuple2('ordm', 186),
			_Utils_Tuple2('raquo', 187),
			_Utils_Tuple2('frac14', 188),
			_Utils_Tuple2('frac12', 189),
			_Utils_Tuple2('frac34', 190),
			_Utils_Tuple2('iquest', 191),
			_Utils_Tuple2('Agrave', 192),
			_Utils_Tuple2('Aacute', 193),
			_Utils_Tuple2('Acirc', 194),
			_Utils_Tuple2('Atilde', 195),
			_Utils_Tuple2('Auml', 196),
			_Utils_Tuple2('Aring', 197),
			_Utils_Tuple2('AElig', 198),
			_Utils_Tuple2('Ccedil', 199),
			_Utils_Tuple2('Egrave', 200),
			_Utils_Tuple2('Eacute', 201),
			_Utils_Tuple2('Ecirc', 202),
			_Utils_Tuple2('Euml', 203),
			_Utils_Tuple2('Igrave', 204),
			_Utils_Tuple2('Iacute', 205),
			_Utils_Tuple2('Icirc', 206),
			_Utils_Tuple2('Iuml', 207),
			_Utils_Tuple2('ETH', 208),
			_Utils_Tuple2('Ntilde', 209),
			_Utils_Tuple2('Ograve', 210),
			_Utils_Tuple2('Oacute', 211),
			_Utils_Tuple2('Ocirc', 212),
			_Utils_Tuple2('Otilde', 213),
			_Utils_Tuple2('Ouml', 214),
			_Utils_Tuple2('times', 215),
			_Utils_Tuple2('Oslash', 216),
			_Utils_Tuple2('Ugrave', 217),
			_Utils_Tuple2('Uacute', 218),
			_Utils_Tuple2('Ucirc', 219),
			_Utils_Tuple2('Uuml', 220),
			_Utils_Tuple2('Yacute', 221),
			_Utils_Tuple2('THORN', 222),
			_Utils_Tuple2('szlig', 223),
			_Utils_Tuple2('agrave', 224),
			_Utils_Tuple2('aacute', 225),
			_Utils_Tuple2('acirc', 226),
			_Utils_Tuple2('atilde', 227),
			_Utils_Tuple2('auml', 228),
			_Utils_Tuple2('aring', 229),
			_Utils_Tuple2('aelig', 230),
			_Utils_Tuple2('ccedil', 231),
			_Utils_Tuple2('egrave', 232),
			_Utils_Tuple2('eacute', 233),
			_Utils_Tuple2('ecirc', 234),
			_Utils_Tuple2('euml', 235),
			_Utils_Tuple2('igrave', 236),
			_Utils_Tuple2('iacute', 237),
			_Utils_Tuple2('icirc', 238),
			_Utils_Tuple2('iuml', 239),
			_Utils_Tuple2('eth', 240),
			_Utils_Tuple2('ntilde', 241),
			_Utils_Tuple2('ograve', 242),
			_Utils_Tuple2('oacute', 243),
			_Utils_Tuple2('ocirc', 244),
			_Utils_Tuple2('otilde', 245),
			_Utils_Tuple2('ouml', 246),
			_Utils_Tuple2('divide', 247),
			_Utils_Tuple2('oslash', 248),
			_Utils_Tuple2('ugrave', 249),
			_Utils_Tuple2('uacute', 250),
			_Utils_Tuple2('ucirc', 251),
			_Utils_Tuple2('uuml', 252),
			_Utils_Tuple2('yacute', 253),
			_Utils_Tuple2('thorn', 254),
			_Utils_Tuple2('yuml', 255),
			_Utils_Tuple2('OElig', 338),
			_Utils_Tuple2('oelig', 339),
			_Utils_Tuple2('Scaron', 352),
			_Utils_Tuple2('scaron', 353),
			_Utils_Tuple2('Yuml', 376),
			_Utils_Tuple2('fnof', 402),
			_Utils_Tuple2('circ', 710),
			_Utils_Tuple2('tilde', 732),
			_Utils_Tuple2('Alpha', 913),
			_Utils_Tuple2('Beta', 914),
			_Utils_Tuple2('Gamma', 915),
			_Utils_Tuple2('Delta', 916),
			_Utils_Tuple2('Epsilon', 917),
			_Utils_Tuple2('Zeta', 918),
			_Utils_Tuple2('Eta', 919),
			_Utils_Tuple2('Theta', 920),
			_Utils_Tuple2('Iota', 921),
			_Utils_Tuple2('Kappa', 922),
			_Utils_Tuple2('Lambda', 923),
			_Utils_Tuple2('Mu', 924),
			_Utils_Tuple2('Nu', 925),
			_Utils_Tuple2('Xi', 926),
			_Utils_Tuple2('Omicron', 927),
			_Utils_Tuple2('Pi', 928),
			_Utils_Tuple2('Rho', 929),
			_Utils_Tuple2('Sigma', 931),
			_Utils_Tuple2('Tau', 932),
			_Utils_Tuple2('Upsilon', 933),
			_Utils_Tuple2('Phi', 934),
			_Utils_Tuple2('Chi', 935),
			_Utils_Tuple2('Psi', 936),
			_Utils_Tuple2('Omega', 937),
			_Utils_Tuple2('alpha', 945),
			_Utils_Tuple2('beta', 946),
			_Utils_Tuple2('gamma', 947),
			_Utils_Tuple2('delta', 948),
			_Utils_Tuple2('epsilon', 949),
			_Utils_Tuple2('zeta', 950),
			_Utils_Tuple2('eta', 951),
			_Utils_Tuple2('theta', 952),
			_Utils_Tuple2('iota', 953),
			_Utils_Tuple2('kappa', 954),
			_Utils_Tuple2('lambda', 955),
			_Utils_Tuple2('mu', 956),
			_Utils_Tuple2('nu', 957),
			_Utils_Tuple2('xi', 958),
			_Utils_Tuple2('omicron', 959),
			_Utils_Tuple2('pi', 960),
			_Utils_Tuple2('rho', 961),
			_Utils_Tuple2('sigmaf', 962),
			_Utils_Tuple2('sigma', 963),
			_Utils_Tuple2('tau', 964),
			_Utils_Tuple2('upsilon', 965),
			_Utils_Tuple2('phi', 966),
			_Utils_Tuple2('chi', 967),
			_Utils_Tuple2('psi', 968),
			_Utils_Tuple2('omega', 969),
			_Utils_Tuple2('thetasym', 977),
			_Utils_Tuple2('upsih', 978),
			_Utils_Tuple2('piv', 982),
			_Utils_Tuple2('ensp', 8194),
			_Utils_Tuple2('emsp', 8195),
			_Utils_Tuple2('thinsp', 8201),
			_Utils_Tuple2('zwnj', 8204),
			_Utils_Tuple2('zwj', 8205),
			_Utils_Tuple2('lrm', 8206),
			_Utils_Tuple2('rlm', 8207),
			_Utils_Tuple2('ndash', 8211),
			_Utils_Tuple2('mdash', 8212),
			_Utils_Tuple2('lsquo', 8216),
			_Utils_Tuple2('rsquo', 8217),
			_Utils_Tuple2('sbquo', 8218),
			_Utils_Tuple2('ldquo', 8220),
			_Utils_Tuple2('rdquo', 8221),
			_Utils_Tuple2('bdquo', 8222),
			_Utils_Tuple2('dagger', 8224),
			_Utils_Tuple2('Dagger', 8225),
			_Utils_Tuple2('bull', 8226),
			_Utils_Tuple2('hellip', 8230),
			_Utils_Tuple2('permil', 8240),
			_Utils_Tuple2('prime', 8242),
			_Utils_Tuple2('Prime', 8243),
			_Utils_Tuple2('lsaquo', 8249),
			_Utils_Tuple2('rsaquo', 8250),
			_Utils_Tuple2('oline', 8254),
			_Utils_Tuple2('frasl', 8260),
			_Utils_Tuple2('euro', 8364),
			_Utils_Tuple2('image', 8465),
			_Utils_Tuple2('weierp', 8472),
			_Utils_Tuple2('real', 8476),
			_Utils_Tuple2('trade', 8482),
			_Utils_Tuple2('alefsym', 8501),
			_Utils_Tuple2('larr', 8592),
			_Utils_Tuple2('uarr', 8593),
			_Utils_Tuple2('rarr', 8594),
			_Utils_Tuple2('darr', 8595),
			_Utils_Tuple2('harr', 8596),
			_Utils_Tuple2('crarr', 8629),
			_Utils_Tuple2('lArr', 8656),
			_Utils_Tuple2('uArr', 8657),
			_Utils_Tuple2('rArr', 8658),
			_Utils_Tuple2('dArr', 8659),
			_Utils_Tuple2('hArr', 8660),
			_Utils_Tuple2('forall', 8704),
			_Utils_Tuple2('part', 8706),
			_Utils_Tuple2('exist', 8707),
			_Utils_Tuple2('empty', 8709),
			_Utils_Tuple2('nabla', 8711),
			_Utils_Tuple2('isin', 8712),
			_Utils_Tuple2('notin', 8713),
			_Utils_Tuple2('ni', 8715),
			_Utils_Tuple2('prod', 8719),
			_Utils_Tuple2('sum', 8721),
			_Utils_Tuple2('minus', 8722),
			_Utils_Tuple2('lowast', 8727),
			_Utils_Tuple2('radic', 8730),
			_Utils_Tuple2('prop', 8733),
			_Utils_Tuple2('infin', 8734),
			_Utils_Tuple2('ang', 8736),
			_Utils_Tuple2('and', 8743),
			_Utils_Tuple2('or', 8744),
			_Utils_Tuple2('cap', 8745),
			_Utils_Tuple2('cup', 8746),
			_Utils_Tuple2('int', 8747),
			_Utils_Tuple2('there4', 8756),
			_Utils_Tuple2('sim', 8764),
			_Utils_Tuple2('cong', 8773),
			_Utils_Tuple2('asymp', 8776),
			_Utils_Tuple2('ne', 8800),
			_Utils_Tuple2('equiv', 8801),
			_Utils_Tuple2('le', 8804),
			_Utils_Tuple2('ge', 8805),
			_Utils_Tuple2('sub', 8834),
			_Utils_Tuple2('sup', 8835),
			_Utils_Tuple2('nsub', 8836),
			_Utils_Tuple2('sube', 8838),
			_Utils_Tuple2('supe', 8839),
			_Utils_Tuple2('oplus', 8853),
			_Utils_Tuple2('otimes', 8855),
			_Utils_Tuple2('perp', 8869),
			_Utils_Tuple2('sdot', 8901),
			_Utils_Tuple2('lceil', 8968),
			_Utils_Tuple2('rceil', 8969),
			_Utils_Tuple2('lfloor', 8970),
			_Utils_Tuple2('rfloor', 8971),
			_Utils_Tuple2('lang', 9001),
			_Utils_Tuple2('rang', 9002),
			_Utils_Tuple2('loz', 9674),
			_Utils_Tuple2('spades', 9824),
			_Utils_Tuple2('clubs', 9827),
			_Utils_Tuple2('hearts', 9829),
			_Utils_Tuple2('diams', 9830)
		]));
var $dillonkearns$elm_markdown$Markdown$Entity$replaceEntity = function (match) {
	var _v0 = match.eb;
	if (_v0.b && (!_v0.a.$)) {
		var first = _v0.a.a;
		var _v1 = A2($elm$core$Dict$get, first, $dillonkearns$elm_markdown$Markdown$Entity$entities);
		if (!_v1.$) {
			var code = _v1.a;
			return $elm$core$String$fromChar(
				$elm$core$Char$fromCode(code));
		} else {
			return match.bU;
		}
	} else {
		return match.bU;
	}
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceEntities = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$entitiesRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceEntity);
var $dillonkearns$elm_markdown$Markdown$Helpers$escapableRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\+)([!\"#$%&\\\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-])'));
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$replaceEscapable = A2(
	$elm$regex$Regex$replace,
	$dillonkearns$elm_markdown$Markdown$Helpers$escapableRegex,
	function (regexMatch) {
		var _v0 = regexMatch.eb;
		if (((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) {
			var backslashes = _v0.a.a;
			var _v1 = _v0.b;
			var escapedStr = _v1.a.a;
			return _Utils_ap(
				A2(
					$elm$core$String$repeat,
					($elm$core$String$length(backslashes) / 2) | 0,
					'\\'),
				escapedStr);
		} else {
			return regexMatch.bU;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Entity$hexadecimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#[Xx]([0-9a-fA-F]{1,8});'));
var $dillonkearns$elm_markdown$Markdown$Entity$hexToInt = function (string) {
	var folder = F2(
		function (hexDigit, _int) {
			return ((_int * 16) + A2(
				$elm$core$Basics$modBy,
				39,
				$elm$core$Char$toCode(hexDigit))) - 9;
		});
	return A3(
		$elm$core$String$foldl,
		folder,
		0,
		$elm$core$String$toLower(string));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimal = function (match) {
	var _v0 = match.eb;
	if (_v0.b && (!_v0.a.$)) {
		var first = _v0.a.a;
		return $dillonkearns$elm_markdown$Markdown$Entity$validUnicode(
			$dillonkearns$elm_markdown$Markdown$Entity$hexToInt(first));
	} else {
		return match.bU;
	}
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimals = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$hexadecimalRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimal);
var $dillonkearns$elm_markdown$Markdown$Helpers$formatStr = function (str) {
	var withEscapes = $dillonkearns$elm_markdown$Markdown$Helpers$replaceEscapable(str);
	return $dillonkearns$elm_markdown$Markdown$Helpers$containsAmpersand(withEscapes) ? $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimals(
		$dillonkearns$elm_markdown$Markdown$Entity$replaceDecimals(
			$dillonkearns$elm_markdown$Markdown$Entity$replaceEntities(withEscapes))) : withEscapes;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch = function (text) {
	return {
		i: 0,
		D: _List_Nil,
		o: 0,
		s: $dillonkearns$elm_markdown$Markdown$Helpers$formatStr(text),
		P: 0,
		A: 0,
		iT: $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType
	};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatch = F3(
	function (rawText, _v2, parsedMatches) {
		var matchModel = _v2;
		var updtMatch = {
			i: matchModel.i,
			D: A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches, matchModel.s, _List_Nil, matchModel.D),
			o: matchModel.o,
			s: matchModel.s,
			P: matchModel.P,
			A: matchModel.A,
			iT: matchModel.iT
		};
		if (!parsedMatches.b) {
			var finalStr = A2($elm$core$String$dropLeft, matchModel.i, rawText);
			return $elm$core$String$isEmpty(finalStr) ? _List_fromArray(
				[updtMatch]) : _List_fromArray(
				[
					updtMatch,
					$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(finalStr)
				]);
		} else {
			var matchHead = parsedMatches.a;
			var matchesTail = parsedMatches.b;
			var _v4 = matchHead.iT;
			if (!_v4.$) {
				return A2($elm$core$List$cons, updtMatch, parsedMatches);
			} else {
				return _Utils_eq(matchModel.i, matchHead.o) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : ((_Utils_cmp(matchModel.i, matchHead.o) < 0) ? A2(
					$elm$core$List$cons,
					updtMatch,
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(
							A3($elm$core$String$slice, matchModel.i, matchHead.o, rawText)),
						parsedMatches)) : parsedMatches);
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches = F3(
	function (rawText, parsedMatches, matches) {
		parseTextMatches:
		while (true) {
			if (!matches.b) {
				if (!parsedMatches.b) {
					return $elm$core$String$isEmpty(rawText) ? _List_Nil : _List_fromArray(
						[
							$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(rawText)
						]);
				} else {
					var matchModel = parsedMatches.a;
					return (matchModel.o > 0) ? A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(
							A2($elm$core$String$left, matchModel.o, rawText)),
						parsedMatches) : parsedMatches;
				}
			} else {
				var match = matches.a;
				var matchesTail = matches.b;
				var $temp$rawText = rawText,
					$temp$parsedMatches = A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatch, rawText, match, parsedMatches),
					$temp$matches = matchesTail;
				rawText = $temp$rawText;
				parsedMatches = $temp$parsedMatches;
				matches = $temp$matches;
				continue parseTextMatches;
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\<)'));
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketOpen = {$: 4};
var $dillonkearns$elm_markdown$Markdown$Helpers$isEven = function (_int) {
	return !A2($elm$core$Basics$modBy, 2, _int);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken = function (regMatch) {
	var _v0 = regMatch.eb;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{hA: regMatch.hA + backslashesLength, b: 1, h: $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketOpen}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\>)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketClose = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Escaped = 0;
var $dillonkearns$elm_markdown$Markdown$InlineParser$NotEscaped = 1;
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken = function (regMatch) {
	var _v0 = regMatch.eb;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				hA: regMatch.hA + backslashesLength,
				b: 1,
				h: $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketClose(1) : $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketClose(0)
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^*])?(\\*+)([^*])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisToken = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isPunctuation = function (c) {
	switch (c) {
		case '!':
			return true;
		case '\"':
			return true;
		case '#':
			return true;
		case '%':
			return true;
		case '&':
			return true;
		case '\'':
			return true;
		case '(':
			return true;
		case ')':
			return true;
		case '*':
			return true;
		case ',':
			return true;
		case '-':
			return true;
		case '.':
			return true;
		case '/':
			return true;
		case ':':
			return true;
		case ';':
			return true;
		case '?':
			return true;
		case '@':
			return true;
		case '[':
			return true;
		case ']':
			return true;
		case '_':
			return true;
		case '{':
			return true;
		case '}':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$containPunctuation = A2(
	$elm$core$String$foldl,
	F2(
		function (c, accum) {
			return accum || $dillonkearns$elm_markdown$Markdown$InlineParser$isPunctuation(c);
		}),
	false);
var $dillonkearns$elm_markdown$Markdown$InlineParser$isWhitespace = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\u000C':
			return true;
		case '\n':
			return true;
		case '\u000D':
			return true;
		case '\t':
			return true;
		case '\u000B':
			return true;
		case '\u00A0':
			return true;
		case '\u2028':
			return true;
		case '\u2029':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$containSpace = A2(
	$elm$core$String$foldl,
	F2(
		function (c, accum) {
			return accum || $dillonkearns$elm_markdown$Markdown$InlineParser$isWhitespace(c);
		}),
	false);
var $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank = function (mstring) {
	if (!mstring.$) {
		var string = mstring.a;
		return ($elm$core$String$isEmpty(string) || $dillonkearns$elm_markdown$Markdown$InlineParser$containSpace(string)) ? 0 : ($dillonkearns$elm_markdown$Markdown$InlineParser$containPunctuation(string) ? 1 : 2);
	} else {
		return 0;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken = F3(
	function (_char, rawText, regMatch) {
		var _v0 = regMatch.eb;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) {
			var maybeBackslashes = _v0.a;
			var _v1 = _v0.b;
			var maybeLeftFringe = _v1.a;
			var _v2 = _v1.b;
			var delimiter = _v2.a.a;
			var _v3 = _v2.b;
			var maybeRightFringe = _v3.a;
			var rFringeRank = $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank(maybeRightFringe);
			var leftFringeLength = function () {
				if (!maybeLeftFringe.$) {
					var left = maybeLeftFringe.a;
					return $elm$core$String$length(left);
				} else {
					return 0;
				}
			}();
			var mLeftFringe = ((!(!regMatch.hA)) && (!leftFringeLength)) ? $elm$core$Maybe$Just(
				A3($elm$core$String$slice, regMatch.hA - 1, regMatch.hA, rawText)) : maybeLeftFringe;
			var backslashesLength = function () {
				if (!maybeBackslashes.$) {
					var backslashes = maybeBackslashes.a;
					return $elm$core$String$length(backslashes);
				} else {
					return 0;
				}
			}();
			var isEscaped = ((!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) && (!leftFringeLength)) || function () {
				if ((!mLeftFringe.$) && (mLeftFringe.a === '\\')) {
					return true;
				} else {
					return false;
				}
			}();
			var delimiterLength = isEscaped ? ($elm$core$String$length(delimiter) - 1) : $elm$core$String$length(delimiter);
			var lFringeRank = isEscaped ? 1 : $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank(mLeftFringe);
			if ((delimiterLength <= 0) || ((_char === '_') && ((lFringeRank === 2) && (rFringeRank === 2)))) {
				return $elm$core$Maybe$Nothing;
			} else {
				var index = ((regMatch.hA + backslashesLength) + leftFringeLength) + (isEscaped ? 1 : 0);
				return $elm$core$Maybe$Just(
					{
						hA: index,
						b: delimiterLength,
						h: A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisToken,
							_char,
							{cD: lFringeRank, cX: rFringeRank})
					});
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '*', str),
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\`+)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToCodeToken = function (regMatch) {
	var _v0 = regMatch.eb;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backtick = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				hA: regMatch.hA + backslashesLength,
				b: $elm$core$String$length(backtick),
				h: $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken(1) : $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken(0)
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findCodeTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToCodeToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$codeTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( {2,}))\\n'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken = {$: 9};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken = function (regMatch) {
	var _v0 = regMatch.eb;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return (!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) ? $elm$core$Maybe$Just(
					{hA: (regMatch.hA + backslashesLength) - 1, b: 2, h: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Nothing;
			} else {
				if (_v0.b.b && (!_v0.b.a.$)) {
					var _v1 = _v0.b;
					return $elm$core$Maybe$Just(
						{
							hA: regMatch.hA,
							b: $elm$core$String$length(regMatch.bU),
							h: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken = function (regMatch) {
	var _v0 = regMatch.eb;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
					{hA: regMatch.hA + backslashesLength, b: 1, h: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Just(
					{hA: (regMatch.hA + backslashesLength) - 1, b: 2, h: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken});
			} else {
				if (_v0.b.b) {
					var _v1 = _v0.b;
					var maybeSpaces = _v1.a;
					return $elm$core$Maybe$Just(
						{
							hA: regMatch.hA,
							b: $elm$core$String$length(regMatch.bU),
							h: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak = false;
var $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( *))\\n'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findHardBreakTokens = function (str) {
	return $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak ? A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex, str)) : A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\])'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$SquareBracketClose = {$: 3};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken = function (regMatch) {
	var _v0 = regMatch.eb;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{hA: regMatch.hA + backslashesLength, b: 1, h: $dillonkearns$elm_markdown$Markdown$InlineParser$SquareBracketClose}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\!)?(\\[)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$Active = 0;
var $dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken = {$: 2};
var $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken = function (regMatch) {
	var _v0 = regMatch.eb;
	if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var maybeImageOpen = _v1.a;
		var _v2 = _v1.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		var isEscaped = !$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength);
		var index = isEscaped ? ((regMatch.hA + backslashesLength) + 1) : (regMatch.hA + backslashesLength);
		if (isEscaped) {
			if (!maybeImageOpen.$) {
				return $elm$core$Maybe$Just(
					{
						hA: index,
						b: 1,
						h: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(0)
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			if (!maybeImageOpen.$) {
				return $elm$core$Maybe$Just(
					{hA: index, b: 2, h: $dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken});
			} else {
				return $elm$core$Maybe$Just(
					{
						hA: index,
						b: 1,
						h: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(0)
					});
			}
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^_])?(\\_+)([^_])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '_', str),
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex = F2(
	function (left, right) {
		if (left.b) {
			var lfirst = left.a;
			var lrest = left.b;
			if (right.b) {
				var rfirst = right.a;
				var rrest = right.b;
				return (_Utils_cmp(lfirst.hA, rfirst.hA) < 0) ? A2(
					$elm$core$List$cons,
					lfirst,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex, lrest, right)) : A2(
					$elm$core$List$cons,
					rfirst,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex, left, rrest));
			} else {
				return left;
			}
		} else {
			return right;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenize = function (rawText) {
	return A2(
		$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
		$dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens(rawText),
		A2(
			$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
			$dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens(rawText),
			A2(
				$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
				$dillonkearns$elm_markdown$Markdown$InlineParser$findHardBreakTokens(rawText),
				A2(
					$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
					$dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens(rawText),
					A2(
						$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
						$dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens(rawText),
						A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
							$dillonkearns$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens(rawText),
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
								$dillonkearns$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens(rawText),
								$dillonkearns$elm_markdown$Markdown$InlineParser$findCodeTokens(rawText))))))));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$CodeType = {$: 2};
var $dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisType = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Inactive = 1;
var $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType = function (a) {
	return {$: 3, a: a};
};
var $elm$regex$Regex$contains = _Regex_contains;
var $dillonkearns$elm_markdown$Markdown$InlineParser$decodeUrlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('%(?:3B|2C|2F|3F|3A|40|26|3D|2B|24|23|25)'));
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2(
		$elm$regex$Regex$replace,
		$dillonkearns$elm_markdown$Markdown$InlineParser$decodeUrlRegex,
		function (match) {
			return A2(
				$elm$core$Maybe$withDefault,
				match.bU,
				$elm$url$Url$percentDecode(match.bU));
		}));
var $dillonkearns$elm_markdown$Markdown$InlineParser$urlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z][A-Za-z0-9.+\\-]{1,31}:[^<>\\x00-\\x20]*)$'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$autolinkToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $dillonkearns$elm_markdown$Markdown$InlineParser$urlRegex, match.s) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				iT: $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.s,
						$dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(match.s)))
			})) : $elm$core$Result$Err(match);
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $dillonkearns$elm_markdown$Markdown$Helpers$insideSquareBracketRegex = '[^\\[\\]\\\\]*(?:\\\\.[^\\[\\]\\\\]*)*';
var $dillonkearns$elm_markdown$Markdown$InlineParser$refLabelRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\[\\s*(' + ($dillonkearns$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + ')\\s*\\]')));
var $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces = function (original) {
	return original;
};
var $dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel = A2($elm$core$Basics$composeR, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $elm$core$String$toLower);
var $dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle = F2(
	function (rawUrl, maybeTitle) {
		return _Utils_Tuple2(
			$dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(
				$dillonkearns$elm_markdown$Markdown$Helpers$formatStr(rawUrl)),
			A2($elm$core$Maybe$map, $dillonkearns$elm_markdown$Markdown$Helpers$formatStr, maybeTitle));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$refRegexToMatch = F3(
	function (matchModel, references, maybeRegexMatch) {
		var refLabel = function (str) {
			return $elm$core$String$isEmpty(str) ? matchModel.s : str;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				matchModel.s,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$andThen,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.eb;
							},
							$elm$core$List$head),
						maybeRegexMatch))));
		var _v0 = A2(
			$elm$core$Dict$get,
			$dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel(refLabel),
			references);
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v1 = _v0.a;
			var rawUrl = _v1.a;
			var maybeTitle = _v1.b;
			var type_ = function () {
				var _v3 = matchModel.iT;
				if (_v3.$ === 5) {
					return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType(
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle, rawUrl, maybeTitle));
				} else {
					return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType(
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle, rawUrl, maybeTitle));
				}
			}();
			var regexMatchLength = function () {
				if (!maybeRegexMatch.$) {
					var match = maybeRegexMatch.a.bU;
					return $elm$core$String$length(match);
				} else {
					return 0;
				}
			}();
			return $elm$core$Maybe$Just(
				_Utils_update(
					matchModel,
					{i: matchModel.i + regexMatchLength, iT: type_}));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineReferences = F3(
	function (remainText, _v0, references) {
		var tempMatch = _v0;
		var matches = A3($elm$regex$Regex$findAtMost, 1, $dillonkearns$elm_markdown$Markdown$InlineParser$refLabelRegex, remainText);
		return A3(
			$dillonkearns$elm_markdown$Markdown$InlineParser$refRegexToMatch,
			tempMatch,
			references,
			$elm$core$List$head(matches));
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$lineEndChars = '\\f\\v\\r\\n';
var $dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars = ' \\t\\f\\v\\r\\n';
var $dillonkearns$elm_markdown$Markdown$InlineParser$hrefRegex = '(?:<([^<>' + ($dillonkearns$elm_markdown$Markdown$Helpers$lineEndChars + (']*)>|([^' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + ('\\(\\)\\\\]*(?:\\\\.[^' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + '\\(\\)\\\\]*)*))')))));
var $dillonkearns$elm_markdown$Markdown$Helpers$titleRegex = '(?:[' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']+' + ('(?:\'([^\'\\\\]*(?:\\\\.[^\'\\\\]*)*)\'|' + ('\"([^\"\\\\]*(?:\\\\.[^\"\\\\]*)*)\"|' + '\\(([^\\)\\\\]*(?:\\\\.[^\\)\\\\]*)*)\\)))?'))));
var $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\(\\s*' + ($dillonkearns$elm_markdown$Markdown$InlineParser$hrefRegex + ($dillonkearns$elm_markdown$Markdown$Helpers$titleRegex + '\\s*\\)'))));
var $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust = function (maybes) {
	var process = F2(
		function (a, maybeFound) {
			if (!maybeFound.$) {
				var found = maybeFound.a;
				return $elm$core$Maybe$Just(found);
			} else {
				return a;
			}
		});
	return A3($elm$core$List$foldl, process, $elm$core$Maybe$Nothing, maybes);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch = F2(
	function (matchModel, regexMatch) {
		var _v0 = regexMatch.eb;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
			var maybeRawUrlAngleBrackets = _v0.a;
			var _v1 = _v0.b;
			var maybeRawUrlWithoutBrackets = _v1.a;
			var _v2 = _v1.b;
			var maybeTitleSingleQuotes = _v2.a;
			var _v3 = _v2.b;
			var maybeTitleDoubleQuotes = _v3.a;
			var _v4 = _v3.b;
			var maybeTitleParenthesis = _v4.a;
			var maybeTitle = $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis]));
			var toMatch = function (rawUrl) {
				return _Utils_update(
					matchModel,
					{
						i: matchModel.i + $elm$core$String$length(regexMatch.bU),
						iT: function () {
							var _v5 = matchModel.iT;
							if (_v5.$ === 5) {
								return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType;
							} else {
								return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType;
							}
						}()(
							A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle, rawUrl, maybeTitle))
					});
			};
			var maybeRawUrl = $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
			return $elm$core$Maybe$Just(
				toMatch(
					A2($elm$core$Maybe$withDefault, '', maybeRawUrl)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType = F3(
	function (remainText, _v0, refs) {
		var tempMatch = _v0;
		var _v1 = A3($elm$regex$Regex$findAtMost, 1, $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex, remainText);
		if (_v1.b) {
			var first = _v1.a;
			var _v2 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch, tempMatch, first);
			if (!_v2.$) {
				var match = _v2.a;
				return $elm$core$Maybe$Just(match);
			} else {
				return A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineReferences, remainText, tempMatch, refs);
			}
		} else {
			return A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineReferences, remainText, tempMatch, refs);
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping = F2(
	function (_v0, remainMatches) {
		var match = _v0;
		var overlappingMatches = $elm$core$List$filter(
			function (_v1) {
				var testMatch = _v1;
				return (_Utils_cmp(match.i, testMatch.o) > 0) && (_Utils_cmp(match.i, testMatch.i) < 0);
			});
		return ($elm$core$List$isEmpty(remainMatches) || $elm$core$List$isEmpty(
			overlappingMatches(remainMatches))) ? $elm$core$Maybe$Just(
			A2($elm$core$List$cons, match, remainMatches)) : $elm$core$Maybe$Nothing;
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)*)$'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $dillonkearns$elm_markdown$Markdown$InlineParser$emailRegex, match.s) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				iT: $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.s,
						'mailto:' + $dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(match.s)))
			})) : $elm$core$Result$Err(match);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findTokenHelp = F3(
	function (innerTokens, isToken, tokens) {
		findTokenHelp:
		while (true) {
			if (!tokens.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var nextToken = tokens.a;
				var remainingTokens = tokens.b;
				if (isToken(nextToken)) {
					return $elm$core$Maybe$Just(
						_Utils_Tuple3(
							nextToken,
							$elm$core$List$reverse(innerTokens),
							remainingTokens));
				} else {
					var $temp$innerTokens = A2($elm$core$List$cons, nextToken, innerTokens),
						$temp$isToken = isToken,
						$temp$tokens = remainingTokens;
					innerTokens = $temp$innerTokens;
					isToken = $temp$isToken;
					tokens = $temp$tokens;
					continue findTokenHelp;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$findToken = F2(
	function (isToken, tokens) {
		return A3($dillonkearns$elm_markdown$Markdown$InlineParser$findTokenHelp, _List_Nil, isToken, tokens);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$HtmlToken = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$NotOpening = 1;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.h4, s);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlToToken = F2(
	function (rawText, _v0) {
		var match = _v0;
		var consumedCharacters = A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (startOffset, htmlTag, endOffset) {
								return {fb: htmlTag, b: endOffset - startOffset};
							})),
					$elm$parser$Parser$Advanced$getOffset),
				$dillonkearns$elm_markdown$HtmlParser$html),
			$elm$parser$Parser$Advanced$getOffset);
		var parsed = A2(
			$elm$parser$Parser$Advanced$run,
			consumedCharacters,
			A2($elm$core$String$dropLeft, match.o, rawText));
		if (!parsed.$) {
			var htmlTag = parsed.a.fb;
			var length = parsed.a.b;
			var htmlToken = A2($dillonkearns$elm_markdown$Markdown$InlineParser$HtmlToken, 1, htmlTag);
			return $elm$core$Maybe$Just(
				{hA: match.o, b: length, h: htmlToken});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$ifError = F2(
	function (_function, result) {
		if (!result.$) {
			return result;
		} else {
			var err = result.a;
			return _function(err);
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isCloseToken = F2(
	function (htmlModel, token) {
		return false;
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isCodeTokenPair = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.h;
		if (!_v0.$) {
			if (!_v0.a) {
				var _v1 = _v0.a;
				return _Utils_eq(openToken.b - 1, closeToken.b);
			} else {
				var _v2 = _v0.a;
				return _Utils_eq(openToken.b, closeToken.b);
			}
		} else {
			return false;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken = function (token) {
	var _v0 = token.h;
	switch (_v0.$) {
		case 1:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.h;
		if (_v0.$ === 7) {
			var openChar = _v0.a;
			var open = _v0.b;
			var _v1 = closeToken.h;
			if (_v1.$ === 7) {
				var closeChar = _v1.a;
				var close = _v1.b;
				return _Utils_eq(openChar, closeChar) ? ((_Utils_eq(open.cD, open.cX) || _Utils_eq(close.cD, close.cX)) ? (!(!A2($elm$core$Basics$modBy, 3, closeToken.b + openToken.b))) : true) : false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakType = {$: 1};
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch = F2(
	function (token, type_) {
		return {i: token.hA + token.b, D: _List_Nil, o: token.hA, s: '', P: 0, A: 0, iT: type_};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM = F5(
	function (remaining, tokens, matches, refs, rawText) {
		lineBreakTTM:
		while (true) {
			if (!remaining.b) {
				return matches;
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v1 = token.h;
				if (_v1.$ === 9) {
					var $temp$remaining = tokensTail,
						$temp$tokens = tokens,
						$temp$matches = A2(
						$elm$core$List$cons,
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch, token, $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakType),
						matches),
						$temp$refs = refs,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					refs = $temp$refs;
					rawText = $temp$rawText;
					continue lineBreakTTM;
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$refs = refs,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					refs = $temp$refs;
					rawText = $temp$rawText;
					continue lineBreakTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens = F2(
	function (_v0, tokensTail) {
		var match = _v0;
		return A2(
			$elm$core$List$filter,
			function (token) {
				return _Utils_cmp(token.hA, match.i) > -1;
			},
			tokensTail);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketsToMatch = F6(
	function (closeToken, escaped, matches, references, rawText, _v39) {
		var openToken = _v39.a;
		var remainTokens = _v39.c;
		var result = A2(
			$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
			$dillonkearns$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch,
			$dillonkearns$elm_markdown$Markdown$InlineParser$autolinkToMatch(
				A7(
					$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
					references,
					rawText,
					function (s) {
						return s;
					},
					$dillonkearns$elm_markdown$Markdown$InlineParser$CodeType,
					openToken,
					closeToken,
					_List_Nil)));
		if (result.$ === 1) {
			var tempMatch = result.a;
			if (escaped === 1) {
				var _v42 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$htmlToToken, rawText, tempMatch);
				if (!_v42.$) {
					var newToken = _v42.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(
							A2($elm$core$List$cons, newToken, remainTokens),
							matches));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			var newMatch = result.a;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(
					remainTokens,
					A2($elm$core$List$cons, newMatch, matches)));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		codeAutolinkTypeHtmlTagTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v31 = token.h;
				switch (_v31.$) {
					case 0:
						var isEscaped = _v31.a;
						var _v32 = A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
							$dillonkearns$elm_markdown$Markdown$InlineParser$isCodeTokenPair(token),
							tokens);
						if (!_v32.$) {
							var code = _v32.a;
							var _v33 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$codeToMatch, token, matches, references, rawText, code);
							var newTokens = _v33.a;
							var newMatches = _v33.b;
							var $temp$remaining = tokensTail,
								$temp$tokens = newTokens,
								$temp$matches = newMatches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue codeAutolinkTypeHtmlTagTTM;
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = A2($elm$core$List$cons, token, tokens),
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue codeAutolinkTypeHtmlTagTTM;
						}
					case 5:
						var isEscaped = _v31.a;
						var isAngleBracketOpen = function (_v38) {
							var meaning = _v38.h;
							if (meaning.$ === 4) {
								return true;
							} else {
								return false;
							}
						};
						var _v34 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$findToken, isAngleBracketOpen, tokens);
						if (!_v34.$) {
							var found = _v34.a;
							var _v35 = A6($dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketsToMatch, token, isEscaped, matches, references, rawText, found);
							if (!_v35.$) {
								var _v36 = _v35.a;
								var newTokens = _v36.a;
								var newMatches = _v36.b;
								var $temp$remaining = tokensTail,
									$temp$tokens = A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeL, $elm$core$Basics$not, isAngleBracketOpen),
									newTokens),
									$temp$matches = newMatches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue codeAutolinkTypeHtmlTagTTM;
							} else {
								var $temp$remaining = tokensTail,
									$temp$tokens = A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeL, $elm$core$Basics$not, isAngleBracketOpen),
									tokens),
									$temp$matches = matches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue codeAutolinkTypeHtmlTagTTM;
							}
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = A2(
								$elm$core$List$filter,
								A2($elm$core$Basics$composeL, $elm$core$Basics$not, isAngleBracketOpen),
								tokens),
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue codeAutolinkTypeHtmlTagTTM;
						}
					default:
						var $temp$remaining = tokensTail,
							$temp$tokens = A2($elm$core$List$cons, token, tokens),
							$temp$matches = matches,
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue codeAutolinkTypeHtmlTagTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeToMatch = F5(
	function (closeToken, matches, references, rawText, _v27) {
		var openToken = _v27.a;
		var remainTokens = _v27.c;
		var updatedOpenToken = function () {
			var _v28 = openToken.h;
			if ((!_v28.$) && (!_v28.a)) {
				var _v29 = _v28.a;
				return _Utils_update(
					openToken,
					{hA: openToken.hA + 1, b: openToken.b - 1});
			} else {
				return openToken;
			}
		}();
		var match = A7($dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch, references, rawText, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $dillonkearns$elm_markdown$Markdown$InlineParser$CodeType, updatedOpenToken, closeToken, _List_Nil);
		return _Utils_Tuple2(
			remainTokens,
			A2($elm$core$List$cons, match, matches));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		emphasisTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v22 = token.h;
				if (_v22.$ === 7) {
					var _char = _v22.a;
					var leftFringeRank = _v22.b.cD;
					var rightFringeRank = _v22.b.cX;
					if (_Utils_eq(leftFringeRank, rightFringeRank)) {
						if ((!(!rightFringeRank)) && ((_char !== '_') || (rightFringeRank === 1))) {
							var _v23 = A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
								$dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
								tokens);
							if (!_v23.$) {
								var found = _v23.a;
								var _v24 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch, references, rawText, token, tokensTail, found);
								var newRemaining = _v24.a;
								var match = _v24.b;
								var newTokens = _v24.c;
								var $temp$remaining = newRemaining,
									$temp$tokens = newTokens,
									$temp$matches = A2($elm$core$List$cons, match, matches),
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							} else {
								var $temp$remaining = tokensTail,
									$temp$tokens = A2($elm$core$List$cons, token, tokens),
									$temp$matches = matches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							}
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = tokens,
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue emphasisTTM;
						}
					} else {
						if (_Utils_cmp(leftFringeRank, rightFringeRank) < 0) {
							var $temp$remaining = tokensTail,
								$temp$tokens = A2($elm$core$List$cons, token, tokens),
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue emphasisTTM;
						} else {
							var _v25 = A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
								$dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
								tokens);
							if (!_v25.$) {
								var found = _v25.a;
								var _v26 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch, references, rawText, token, tokensTail, found);
								var newRemaining = _v26.a;
								var match = _v26.b;
								var newTokens = _v26.c;
								var $temp$remaining = newRemaining,
									$temp$tokens = newTokens,
									$temp$matches = A2($elm$core$List$cons, match, matches),
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							} else {
								var $temp$remaining = tokensTail,
									$temp$tokens = tokens,
									$temp$matches = matches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							}
						}
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue emphasisTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch = F5(
	function (references, rawText, closeToken, tokensTail, _v20) {
		var openToken = _v20.a;
		var innerTokens = _v20.b;
		var remainTokens = _v20.c;
		var remainLength = openToken.b - closeToken.b;
		var updt = (!remainLength) ? {cp: closeToken, b_: openToken, cV: remainTokens, c9: tokensTail} : ((remainLength > 0) ? {
			cp: closeToken,
			b_: _Utils_update(
				openToken,
				{hA: openToken.hA + remainLength, b: closeToken.b}),
			cV: A2(
				$elm$core$List$cons,
				_Utils_update(
					openToken,
					{b: remainLength}),
				remainTokens),
			c9: tokensTail
		} : {
			cp: _Utils_update(
				closeToken,
				{b: openToken.b}),
			b_: openToken,
			cV: remainTokens,
			c9: A2(
				$elm$core$List$cons,
				_Utils_update(
					closeToken,
					{hA: closeToken.hA + openToken.b, b: -remainLength}),
				tokensTail)
		});
		var match = A7(
			$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
			references,
			rawText,
			function (s) {
				return s;
			},
			$dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisType(updt.b_.b),
			updt.b_,
			updt.cp,
			$elm$core$List$reverse(innerTokens));
		return _Utils_Tuple3(updt.c9, match, updt.cV);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		htmlElementTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v16 = token.h;
				if (_v16.$ === 6) {
					var isOpen = _v16.a;
					var htmlModel = _v16.b;
					if (isOpen === 1) {
						var $temp$remaining = tokensTail,
							$temp$tokens = tokens,
							$temp$matches = A2(
							$elm$core$List$cons,
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch,
								token,
								$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)),
							matches),
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue htmlElementTTM;
					} else {
						var _v18 = A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
							$dillonkearns$elm_markdown$Markdown$InlineParser$isCloseToken(htmlModel),
							tokensTail);
						if (_v18.$ === 1) {
							var $temp$remaining = tokensTail,
								$temp$tokens = tokens,
								$temp$matches = A2(
								$elm$core$List$cons,
								A2(
									$dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch,
									token,
									$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)),
								matches),
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue htmlElementTTM;
						} else {
							var _v19 = _v18.a;
							var closeToken = _v19.a;
							var innerTokens = _v19.b;
							var newTail = _v19.c;
							var newMatch = A7(
								$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
								references,
								rawText,
								function (s) {
									return s;
								},
								$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel),
								token,
								closeToken,
								innerTokens);
							var $temp$remaining = newTail,
								$temp$tokens = tokens,
								$temp$matches = A2($elm$core$List$cons, newMatch, matches),
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue htmlElementTTM;
						}
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue htmlElementTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		linkImageTypeTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v11 = token.h;
				if (_v11.$ === 3) {
					var _v12 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$findToken, $dillonkearns$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken, tokens);
					if (!_v12.$) {
						var found = _v12.a;
						var _v13 = A6($dillonkearns$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch, token, tokensTail, matches, references, rawText, found);
						if (!_v13.$) {
							var _v14 = _v13.a;
							var x = _v14.a;
							var newMatches = _v14.b;
							var newTokens = _v14.c;
							var $temp$remaining = x,
								$temp$tokens = newTokens,
								$temp$matches = newMatches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue linkImageTypeTTM;
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = tokens,
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue linkImageTypeTTM;
						}
					} else {
						var $temp$remaining = tokensTail,
							$temp$tokens = tokens,
							$temp$matches = matches,
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue linkImageTypeTTM;
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue linkImageTypeTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch = F6(
	function (closeToken, tokensTail, oldMatches, references, rawText, _v1) {
		var openToken = _v1.a;
		var innerTokens = _v1.b;
		var remainTokens = _v1.c;
		var removeOpenToken = _Utils_Tuple3(
			tokensTail,
			oldMatches,
			_Utils_ap(innerTokens, remainTokens));
		var remainText = A2($elm$core$String$dropLeft, closeToken.hA + 1, rawText);
		var inactivateLinkOpenToken = function (token) {
			var _v9 = token.h;
			if (_v9.$ === 1) {
				return _Utils_update(
					token,
					{
						h: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(1)
					});
			} else {
				return token;
			}
		};
		var findTempMatch = function (isLinkType) {
			return A7(
				$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
				references,
				rawText,
				function (s) {
					return s;
				},
				isLinkType ? $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)) : $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)),
				openToken,
				closeToken,
				$elm$core$List$reverse(innerTokens));
		};
		var _v2 = openToken.h;
		switch (_v2.$) {
			case 2:
				var tempMatch = findTempMatch(false);
				var _v3 = A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType, remainText, tempMatch, references);
				if (_v3.$ === 1) {
					return $elm$core$Maybe$Just(removeOpenToken);
				} else {
					var match = _v3.a;
					var _v4 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping, match, oldMatches);
					if (!_v4.$) {
						var matches = _v4.a;
						return $elm$core$Maybe$Just(
							_Utils_Tuple3(
								A2($dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens, match, tokensTail),
								matches,
								remainTokens));
					} else {
						return $elm$core$Maybe$Just(removeOpenToken);
					}
				}
			case 1:
				if (!_v2.a) {
					var _v5 = _v2.a;
					var tempMatch = findTempMatch(true);
					var _v6 = A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType, remainText, tempMatch, references);
					if (_v6.$ === 1) {
						return $elm$core$Maybe$Just(removeOpenToken);
					} else {
						var match = _v6.a;
						var _v7 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping, match, oldMatches);
						if (!_v7.$) {
							var matches = _v7.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple3(
									A2($dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens, match, tokensTail),
									matches,
									A2($elm$core$List$map, inactivateLinkOpenToken, remainTokens)));
						} else {
							return $elm$core$Maybe$Just(removeOpenToken);
						}
					}
				} else {
					var _v8 = _v2.a;
					return $elm$core$Maybe$Just(removeOpenToken);
				}
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch = F7(
	function (references, rawText, processText, type_, openToken, closeToken, innerTokens) {
		var textStart = openToken.hA + openToken.b;
		var textEnd = closeToken.hA;
		var text = processText(
			A3($elm$core$String$slice, textStart, textEnd, rawText));
		var start = openToken.hA;
		var end = closeToken.hA + closeToken.b;
		var match = {i: end, D: _List_Nil, o: start, s: text, P: textEnd, A: textStart, iT: type_};
		var matches = A2(
			$elm$core$List$map,
			function (_v0) {
				var matchModel = _v0;
				return A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch, match, matchModel);
			},
			A4($dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches, innerTokens, _List_Nil, references, rawText));
		return {i: end, D: matches, o: start, s: text, P: textEnd, A: textStart, iT: type_};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches = F4(
	function (tokens, matches, references, rawText) {
		return A5($dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM, tokens, _List_Nil, matches, references, rawText);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$parse = F2(
	function (refs, rawText_) {
		var rawText = $elm$core$String$trim(rawText_);
		var tokens = $dillonkearns$elm_markdown$Markdown$InlineParser$tokenize(rawText);
		return $dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(
			A3(
				$dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches,
				rawText,
				_List_Nil,
				$dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches(
					A4($dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches, tokens, _List_Nil, refs, rawText))));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$thisIsDefinitelyNotAnHtmlTag = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				' ',
				$elm$parser$Parser$Expecting(' '))),
			$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				'>',
				$elm$parser$Parser$Expecting('>'))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					$elm$core$Char$isAlpha,
					$elm$parser$Parser$Expecting('Alpha')),
				$elm$parser$Parser$Advanced$chompWhile(
					function (c) {
						return $elm$core$Char$isAlphaNum(c) || (c === '-');
					})),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							':',
							$elm$parser$Parser$Expecting(':'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'@',
							$elm$parser$Parser$Expecting('@'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\\',
							$elm$parser$Parser$Expecting('\\'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'+',
							$elm$parser$Parser$Expecting('+'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'.',
							$elm$parser$Parser$Expecting('.')))
					])))
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock = $elm$parser$Parser$Advanced$backtrackable(
	A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (rawLine, _v0) {
				return $dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(rawLine);
			}),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'<',
							$elm$parser$Parser$Expecting('<'))),
					$dillonkearns$elm_markdown$Markdown$Parser$thisIsDefinitelyNotAnHtmlTag),
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
			$dillonkearns$elm_markdown$Helpers$endOfLineOrFile)));
var $dillonkearns$elm_markdown$Markdown$CodeBlock$openingCodeFence = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$map,
			function (delimiter) {
				return _Utils_Tuple2('`', delimiter);
			},
			$elm$parser$Parser$Advanced$getChompedString(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'```',
							$elm$parser$Parser$ExpectingSymbol('```'))),
					$elm$parser$Parser$Advanced$chompWhile(
						function (c) {
							return c === '`';
						})))),
			A2(
			$elm$parser$Parser$Advanced$map,
			function (delimiter) {
				return _Utils_Tuple2('~', delimiter);
			},
			$elm$parser$Parser$Advanced$getChompedString(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'~~~',
							$elm$parser$Parser$ExpectingSymbol('~~~'))),
					$elm$parser$Parser$Advanced$chompWhile(
						function (c) {
							return c === '~';
						}))))
		]));
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (_v0) {
		var _char = _v0.a;
		var delimiter = _v0.b;
		return A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed(
					F2(
						function (language, body) {
							return {
								bI: body,
								fr: $elm$core$String$isEmpty(language) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(language)
							};
						})),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'\n',
								$elm$parser$Parser$Problem('Expecting newline')))),
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\n',
							$elm$parser$Parser$ExpectingSymbol('\n'))))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntilEndOr('\n' + delimiter)),
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\n' + delimiter,
							$elm$parser$Parser$ExpectingSymbol(delimiter)))),
				$elm$parser$Parser$Advanced$chompWhile(
					function (c) {
						return _Utils_eq(c, _char);
					})));
	},
	$dillonkearns$elm_markdown$Markdown$CodeBlock$openingCodeFence);
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parser = $dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp;
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$String$trimRight = _String_trimRight;
var $dillonkearns$elm_markdown$Markdown$Heading$dropTrailingHashes = function (headingString) {
	return A2($elm$core$String$endsWith, '#', headingString) ? $dillonkearns$elm_markdown$Markdown$Heading$dropTrailingHashes(
		$elm$core$String$trimRight(
			A2($elm$core$String$dropRight, 1, headingString))) : headingString;
};
var $dillonkearns$elm_markdown$Parser$Token$hash = A2(
	$elm$parser$Parser$Advanced$Token,
	'#',
	$elm$parser$Parser$Expecting('a `#`'));
var $dillonkearns$elm_markdown$Markdown$Heading$isHash = function (c) {
	if ('#' === c) {
		return true;
	} else {
		return false;
	}
};
var $elm$parser$Parser$Advanced$spaces = $elm$parser$Parser$Advanced$chompWhile(
	function (c) {
		return (c === ' ') || ((c === '\n') || (c === '\r'));
	});
var $elm$core$String$trimLeft = _String_trimLeft;
var $dillonkearns$elm_markdown$Markdown$Heading$parser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$Heading),
				A2(
					$elm$parser$Parser$Advanced$andThen,
					function (startingSpaces) {
						var startSpace = $elm$core$String$length(startingSpaces);
						return (startSpace >= 4) ? $elm$parser$Parser$Advanced$problem(
							$elm$parser$Parser$Expecting('heading with < 4 spaces in front')) : $elm$parser$Parser$Advanced$succeed(startSpace);
					},
					$elm$parser$Parser$Advanced$getChompedString($elm$parser$Parser$Advanced$spaces))),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$hash)),
		A2(
			$elm$parser$Parser$Advanced$andThen,
			function (additionalHashes) {
				var level = $elm$core$String$length(additionalHashes) + 1;
				return (level >= 7) ? $elm$parser$Parser$Advanced$problem(
					$elm$parser$Parser$Expecting('heading with < 7 #\'s')) : $elm$parser$Parser$Advanced$succeed(level);
			},
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Markdown$Heading$isHash)))),
	A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (headingText, _v0) {
				return $dillonkearns$elm_markdown$Markdown$Heading$dropTrailingHashes(
					$elm$core$String$trimLeft(headingText));
			}),
		$elm$parser$Parser$Advanced$chompUntilEndOr('\n')));
var $dillonkearns$elm_markdown$Parser$Token$greaterThan = A2(
	$elm$parser$Parser$Advanced$Token,
	'>',
	$elm$parser$Parser$Expecting('a `>`'));
var $elm$parser$Parser$Advanced$Located = F3(
	function (row, col, context) {
		return {eJ: col, g: context, iu: row};
	});
var $elm$parser$Parser$Advanced$changeContext = F2(
	function (newContext, s) {
		return {eJ: s.eJ, g: newContext, j: s.j, h4: s.h4, iu: s.iu, iI: s.iI};
	});
var $elm$parser$Parser$Advanced$inContext = F2(
	function (context, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(
				A2(
					$elm$parser$Parser$Advanced$changeContext,
					A2(
						$elm$core$List$cons,
						A3($elm$parser$Parser$Advanced$Located, s0.iu, s0.eJ, context),
						s0.g),
					s0));
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					a,
					A2($elm$parser$Parser$Advanced$changeContext, s0.g, s1));
			} else {
				var step = _v1;
				return step;
			}
		};
	});
var $dillonkearns$elm_markdown$Helpers$isGfmWhitespace = function (_char) {
	switch (_char) {
		case ' ':
			return true;
		case '\n':
			return true;
		case '\t':
			return true;
		case '\u000B':
			return true;
		case '\u000C':
			return true;
		case '\u000D':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Parser$Token$lessThan = A2(
	$elm$parser$Parser$Advanced$Token,
	'<',
	$elm$parser$Parser$Expecting('a `<`'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$destinationParser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'link destination',
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$url$Url$percentEncode),
					$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$lessThan)),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$greaterThan)),
					$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$greaterThan))),
				$elm$parser$Parser$Advanced$getChompedString(
				$dillonkearns$elm_markdown$Parser$Extra$oneOrMore(
					function (c) {
						return !$dillonkearns$elm_markdown$Helpers$isGfmWhitespace(c);
					}))
			])));
var $dillonkearns$elm_markdown$Parser$Token$closingSquareBracket = A2(
	$elm$parser$Parser$Advanced$Token,
	']',
	$elm$parser$Parser$Expecting('a `]`'));
var $dillonkearns$elm_markdown$Parser$Token$openingSquareBracket = A2(
	$elm$parser$Parser$Advanced$Token,
	'[',
	$elm$parser$Parser$Expecting('a `[`'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$labelParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel),
		$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$openingSquareBracket)),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$closingSquareBracket)),
		$elm$parser$Parser$Advanced$symbol(
			A2(
				$elm$parser$Parser$Advanced$Token,
				']:',
				$elm$parser$Parser$Expecting(']:')))));
var $dillonkearns$elm_markdown$Parser$Token$doubleQuote = A2(
	$elm$parser$Parser$Advanced$Token,
	'\"',
	$elm$parser$Parser$Expecting('a double quote'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$hasNoBlankLine = function (str) {
	return A2($elm$core$String$contains, '\n\n', str) ? $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Expecting('no blank line')) : $elm$parser$Parser$Advanced$succeed(str);
};
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline = A2(
	$elm$parser$Parser$Advanced$ignorer,
	$elm$parser$Parser$Advanced$chompWhile(
		function (c) {
			return (c !== '\n') && $dillonkearns$elm_markdown$Helpers$isGfmWhitespace(c);
		}),
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline),
				$elm$parser$Parser$Advanced$end(
				$elm$parser$Parser$Expecting('end of file'))
			])));
var $dillonkearns$elm_markdown$Helpers$requiredWhitespace = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$chompIf,
		$dillonkearns$elm_markdown$Helpers$isGfmWhitespace,
		$elm$parser$Parser$Expecting('gfm whitespace')),
	$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isGfmWhitespace));
var $dillonkearns$elm_markdown$Parser$Token$singleQuote = A2(
	$elm$parser$Parser$Advanced$Token,
	'\'',
	$elm$parser$Parser$Expecting('a single quote'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$titleParser = function () {
	var inSingleQuotes = A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Just),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$singleQuote)),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$andThen,
					$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$hasNoBlankLine,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$singleQuote))),
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$singleQuote)),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline));
	var inDoubleQuotes = A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Just),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$doubleQuote)),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$andThen,
					$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$hasNoBlankLine,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$doubleQuote))),
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$doubleQuote)),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline));
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'title',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$backtrackable(
					A2(
						$elm$parser$Parser$Advanced$keeper,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
							$dillonkearns$elm_markdown$Helpers$requiredWhitespace),
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									inDoubleQuotes,
									inSingleQuotes,
									$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing)
								])))),
					A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing),
					$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline)
				])));
}();
var $dillonkearns$elm_markdown$Helpers$spaceOrTab = A2(
	$elm$parser$Parser$Advanced$chompIf,
	$dillonkearns$elm_markdown$Helpers$isSpaceOrTab,
	$elm$parser$Parser$Expecting('space or tab'));
var $dillonkearns$elm_markdown$Helpers$upToThreeSpaces = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$Helpers$spaceOrTab,
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$dillonkearns$elm_markdown$Helpers$spaceOrTab,
							$elm$parser$Parser$Advanced$succeed(0)
						]))),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Helpers$spaceOrTab,
						$elm$parser$Parser$Advanced$succeed(0)
					]))),
			$elm$parser$Parser$Advanced$succeed(0)
		]));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$parser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'link reference definition',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (label, destination, title) {
								return _Utils_Tuple2(
									label,
									{eU: destination, gr: title});
							})),
					$dillonkearns$elm_markdown$Helpers$upToThreeSpaces),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$labelParser,
							$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isSpaceOrTab)),
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline),
									$elm$parser$Parser$Advanced$succeed(0)
								]))),
					$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isSpaceOrTab))),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$destinationParser),
		$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$titleParser));
var $dillonkearns$elm_markdown$ThematicBreak$ThematicBreak = 0;
var $dillonkearns$elm_markdown$ThematicBreak$isSpace = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$ThematicBreak$whitespace = $elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$ThematicBreak$isSpace);
var $dillonkearns$elm_markdown$ThematicBreak$withChar = function (tchar) {
	var token = $dillonkearns$elm_markdown$Parser$Extra$tokenHelp(
		$elm$core$String$fromChar(tchar));
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(0),
								token),
							$dillonkearns$elm_markdown$ThematicBreak$whitespace),
						token),
					$dillonkearns$elm_markdown$ThematicBreak$whitespace),
				token),
			$elm$parser$Parser$Advanced$chompWhile(
				function (c) {
					return _Utils_eq(c, tchar) || $dillonkearns$elm_markdown$ThematicBreak$isSpace(c);
				})),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\n'),
					$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('end'))
				])));
};
var $dillonkearns$elm_markdown$ThematicBreak$parseThematicBreak = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$dillonkearns$elm_markdown$ThematicBreak$withChar('-'),
			$dillonkearns$elm_markdown$ThematicBreak$withChar('*'),
			$dillonkearns$elm_markdown$ThematicBreak$withChar('_')
		]));
var $dillonkearns$elm_markdown$ThematicBreak$singleSpace = A2(
	$elm$parser$Parser$Advanced$chompIf,
	$dillonkearns$elm_markdown$ThematicBreak$isSpace,
	$elm$parser$Parser$Expecting('Space'));
var $dillonkearns$elm_markdown$ThematicBreak$parser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
						$dillonkearns$elm_markdown$ThematicBreak$singleSpace),
					$elm$parser$Parser$Advanced$oneOf(
						_List_fromArray(
							[
								$dillonkearns$elm_markdown$ThematicBreak$singleSpace,
								$elm$parser$Parser$Advanced$succeed(0)
							]))),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$dillonkearns$elm_markdown$ThematicBreak$singleSpace,
							$elm$parser$Parser$Advanced$succeed(0)
						]))),
			$dillonkearns$elm_markdown$ThematicBreak$parseThematicBreak),
			$dillonkearns$elm_markdown$ThematicBreak$parseThematicBreak
		]));
var $dillonkearns$elm_markdown$Markdown$RawBlock$LevelOne = 0;
var $dillonkearns$elm_markdown$Markdown$RawBlock$LevelTwo = 1;
var $dillonkearns$elm_markdown$Markdown$RawBlock$SetextLine = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Parser$Token$equals = A2(
	$elm$parser$Parser$Advanced$Token,
	'=',
	$elm$parser$Parser$Expecting('a `=`'));
var $dillonkearns$elm_markdown$Parser$Token$minus = A2(
	$elm$parser$Parser$Advanced$Token,
	'-',
	$elm$parser$Parser$Expecting('a `-`'));
var $dillonkearns$elm_markdown$Markdown$Parser$setextLineParser = function () {
	var setextLevel = F3(
		function (level, levelToken, levelChar) {
			return A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(level),
					$elm$parser$Parser$Advanced$token(levelToken)),
				$elm$parser$Parser$Advanced$chompWhile(
					$elm$core$Basics$eq(levelChar)));
		});
	return A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (raw, level) {
				return A2($dillonkearns$elm_markdown$Markdown$RawBlock$SetextLine, level, raw);
			}),
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$Helpers$upToThreeSpaces),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$oneOf(
						_List_fromArray(
							[
								A3(setextLevel, 0, $dillonkearns$elm_markdown$Parser$Token$equals, '='),
								A3(setextLevel, 1, $dillonkearns$elm_markdown$Parser$Token$minus, '-')
							])),
					$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isSpaceOrTab)),
				$dillonkearns$elm_markdown$Helpers$endOfLineOrFile)));
}();
var $dillonkearns$elm_markdown$Markdown$RawBlock$TableDelimiter = function (a) {
	return {$: 9, a: a};
};
var $dillonkearns$elm_markdown$Markdown$TableParser$chompSinglelineWhitespace = $elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Helpers$isSpaceOrTab);
var $dillonkearns$elm_markdown$Parser$Extra$maybeChomp = function (condition) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$chompIf,
				condition,
				$elm$parser$Parser$Problem('Character not found')),
				$elm$parser$Parser$Advanced$succeed(0)
			]));
};
var $dillonkearns$elm_markdown$Markdown$TableParser$requirePipeIfNotFirst = function (columns) {
	return $elm$core$List$isEmpty(columns) ? $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|'),
				$elm$parser$Parser$Advanced$succeed(0)
			])) : $dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|');
};
var $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowHelp = function (revDelimiterColumns) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return $elm$parser$Parser$Advanced$Done(revDelimiterColumns);
					},
					$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|\n'))),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v1) {
					return $elm$parser$Parser$Advanced$Done(revDelimiterColumns);
				},
				$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('\n')),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v2) {
					return $elm$parser$Parser$Advanced$Done(revDelimiterColumns);
				},
				$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('end'))),
				$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							$elm$parser$Parser$Advanced$Done(revDelimiterColumns)),
						$dillonkearns$elm_markdown$Parser$Extra$tokenHelp('|')),
					$elm$parser$Parser$Advanced$end(
						$elm$parser$Parser$Expecting('end')))),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							function (column) {
								return $elm$parser$Parser$Advanced$Loop(
									A2($elm$core$List$cons, column, revDelimiterColumns));
							}),
						$dillonkearns$elm_markdown$Markdown$TableParser$requirePipeIfNotFirst(revDelimiterColumns)),
					$dillonkearns$elm_markdown$Markdown$TableParser$chompSinglelineWhitespace),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed(0),
									$dillonkearns$elm_markdown$Parser$Extra$maybeChomp(
										function (c) {
											return c === ':';
										})),
								$dillonkearns$elm_markdown$Parser$Extra$oneOrMore(
									function (c) {
										return c === '-';
									})),
							$dillonkearns$elm_markdown$Parser$Extra$maybeChomp(
								function (c) {
									return c === ':';
								}))),
					$dillonkearns$elm_markdown$Markdown$TableParser$chompSinglelineWhitespace))
			]));
};
var $dillonkearns$elm_markdown$Markdown$Block$AlignCenter = 2;
var $dillonkearns$elm_markdown$Markdown$Block$AlignLeft = 0;
var $dillonkearns$elm_markdown$Markdown$Block$AlignRight = 1;
var $dillonkearns$elm_markdown$Markdown$TableParser$delimiterToAlignment = function (cell) {
	var _v0 = _Utils_Tuple2(
		A2($elm$core$String$startsWith, ':', cell),
		A2($elm$core$String$endsWith, ':', cell));
	if (_v0.a) {
		if (_v0.b) {
			return $elm$core$Maybe$Just(2);
		} else {
			return $elm$core$Maybe$Just(0);
		}
	} else {
		if (_v0.b) {
			return $elm$core$Maybe$Just(1);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowParser = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (delimiterRow) {
		var trimmed = delimiterRow.a.gx;
		var headers = delimiterRow.b;
		return $elm$core$List$isEmpty(headers) ? $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Expecting('Must have at least one column in delimiter row.')) : ((($elm$core$List$length(headers) === 1) && (!(A2($elm$core$String$startsWith, '|', trimmed) && A2($elm$core$String$endsWith, '|', trimmed)))) ? $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Tables with a single column must have pipes at the start and end of the delimiter row to avoid ambiguity.')) : $elm$parser$Parser$Advanced$succeed(delimiterRow));
	},
	A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (delimiterText, revDelimiterColumns) {
				return A2(
					$dillonkearns$elm_markdown$Markdown$Table$TableDelimiterRow,
					{
						fY: delimiterText,
						gx: $elm$core$String$trim(delimiterText)
					},
					A2(
						$elm$core$List$map,
						$dillonkearns$elm_markdown$Markdown$TableParser$delimiterToAlignment,
						$elm$core$List$reverse(revDelimiterColumns)));
			}),
		A2($elm$parser$Parser$Advanced$loop, _List_Nil, $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowHelp)));
var $dillonkearns$elm_markdown$Markdown$Parser$tableDelimiterInOpenParagraph = A2($elm$parser$Parser$Advanced$map, $dillonkearns$elm_markdown$Markdown$RawBlock$TableDelimiter, $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowParser);
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $dillonkearns$elm_markdown$Markdown$TableParser$standardizeRowLength = F2(
	function (expectedLength, row) {
		var rowLength = $elm$core$List$length(row);
		var _v0 = A2($elm$core$Basics$compare, expectedLength, rowLength);
		switch (_v0) {
			case 0:
				return A2($elm$core$List$take, expectedLength, row);
			case 1:
				return row;
			default:
				return _Utils_ap(
					row,
					A2($elm$core$List$repeat, expectedLength - rowLength, ''));
		}
	});
var $dillonkearns$elm_markdown$Markdown$TableParser$bodyRowParser = function (expectedRowLength) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (row) {
			return ($elm$core$List$isEmpty(row) || A2($elm$core$List$all, $elm$core$String$isEmpty, row)) ? $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Problem('A line must have at least one column')) : $elm$parser$Parser$Advanced$succeed(
				A2($dillonkearns$elm_markdown$Markdown$TableParser$standardizeRowLength, expectedRowLength, row));
		},
		$dillonkearns$elm_markdown$Markdown$TableParser$rowParser);
};
var $dillonkearns$elm_markdown$Markdown$Parser$tableRowIfTableStarted = function (_v0) {
	var headers = _v0.a;
	var body = _v0.b;
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (row) {
			return $dillonkearns$elm_markdown$Markdown$RawBlock$Table(
				A2(
					$dillonkearns$elm_markdown$Markdown$Table$Table,
					headers,
					_Utils_ap(
						body,
						_List_fromArray(
							[row]))));
		},
		$dillonkearns$elm_markdown$Markdown$TableParser$bodyRowParser(
			$elm$core$List$length(headers)));
};
var $dillonkearns$elm_markdown$Markdown$Block$H1 = 0;
var $dillonkearns$elm_markdown$Markdown$Block$H2 = 1;
var $dillonkearns$elm_markdown$Markdown$Block$H3 = 2;
var $dillonkearns$elm_markdown$Markdown$Block$H4 = 3;
var $dillonkearns$elm_markdown$Markdown$Block$H5 = 4;
var $dillonkearns$elm_markdown$Markdown$Block$H6 = 5;
var $dillonkearns$elm_markdown$Markdown$Parser$toHeading = function (level) {
	switch (level) {
		case 1:
			return $elm$core$Result$Ok(0);
		case 2:
			return $elm$core$Result$Ok(1);
		case 3:
			return $elm$core$Result$Ok(2);
		case 4:
			return $elm$core$Result$Ok(3);
		case 5:
			return $elm$core$Result$Ok(4);
		case 6:
			return $elm$core$Result$Ok(5);
		default:
			return $elm$core$Result$Err(
				$elm$parser$Parser$Expecting(
					'A heading with 1 to 6 #\'s, but found ' + $elm$core$String$fromInt(level)));
	}
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Parser$Token$asterisk = A2(
	$elm$parser$Parser$Advanced$Token,
	'*',
	$elm$parser$Parser$Expecting('a `*`'));
var $dillonkearns$elm_markdown$Parser$Token$plus = A2(
	$elm$parser$Parser$Advanced$Token,
	'+',
	$elm$parser$Parser$Expecting('a `+`'));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$listMarkerParser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Parser$Token$minus),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$minus)),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Parser$Token$plus),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$plus)),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Parser$Token$asterisk),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$asterisk))
		]));
var $dillonkearns$elm_markdown$Markdown$ListItem$PlainItem = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$ListItem$TaskItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$ListItem$Complete = 1;
var $dillonkearns$elm_markdown$Markdown$ListItem$Incomplete = 0;
var $dillonkearns$elm_markdown$Markdown$ListItem$taskItemParser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[x] ',
					$elm$parser$Parser$ExpectingSymbol('[x] ')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[X] ',
					$elm$parser$Parser$ExpectingSymbol('[X] ')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(0),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[ ] ',
					$elm$parser$Parser$ExpectingSymbol('[ ] '))))
		]));
var $dillonkearns$elm_markdown$Parser$Extra$zeroOrMore = function (condition) {
	return $elm$parser$Parser$Advanced$chompWhile(condition);
};
var $dillonkearns$elm_markdown$Markdown$ListItem$parser = A2(
	$elm$parser$Parser$Advanced$keeper,
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$ListItem$TaskItem),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$Markdown$ListItem$taskItemParser,
					$dillonkearns$elm_markdown$Parser$Extra$zeroOrMore($dillonkearns$elm_markdown$Helpers$isSpaceOrTab))),
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$ListItem$PlainItem)
			])),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$dillonkearns$elm_markdown$Helpers$endOfLineOrFile));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$itemBody = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$Advanced$backtrackable(
					$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpaceOrTab))),
			$dillonkearns$elm_markdown$Markdown$ListItem$parser),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$ListItem$PlainItem('')),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline))
		]));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$singleItemParser = function (listMarker) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$Advanced$backtrackable(
				$elm$parser$Parser$Advanced$symbol(listMarker))),
		$dillonkearns$elm_markdown$Markdown$UnorderedList$itemBody);
};
var $dillonkearns$elm_markdown$Markdown$UnorderedList$statementsHelp = F3(
	function (itemParser, firstItem, revStmts) {
		return $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (stmt) {
						return $elm$parser$Parser$Advanced$Loop(
							A2($elm$core$List$cons, stmt, revStmts));
					},
					itemParser),
					$elm$parser$Parser$Advanced$succeed(
					$elm$parser$Parser$Advanced$Done(
						A2(
							$elm$core$List$cons,
							firstItem,
							$elm$core$List$reverse(revStmts))))
				]));
	});
var $dillonkearns$elm_markdown$Markdown$UnorderedList$parser = function () {
	var parseSubsequentItems = F2(
		function (listMarker, firstItem) {
			return A2(
				$elm$parser$Parser$Advanced$loop,
				_List_Nil,
				A2(
					$dillonkearns$elm_markdown$Markdown$UnorderedList$statementsHelp,
					$dillonkearns$elm_markdown$Markdown$UnorderedList$singleItemParser(listMarker),
					firstItem));
		});
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		$elm$core$Basics$identity,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed(parseSubsequentItems),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$UnorderedList$listMarkerParser),
					$dillonkearns$elm_markdown$Parser$Extra$oneOrMore($dillonkearns$elm_markdown$Helpers$isSpaceOrTab))),
			$dillonkearns$elm_markdown$Markdown$ListItem$parser));
}();
var $dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock = function () {
	var parseListItem = function (unparsedListItem) {
		if (!unparsedListItem.$) {
			var completion = unparsedListItem.a;
			var body = unparsedListItem.b;
			return {
				bI: body,
				ei: $elm$core$Maybe$Just(
					function () {
						if (completion === 1) {
							return true;
						} else {
							return false;
						}
					}())
			};
		} else {
			var body = unparsedListItem.a;
			return {bI: body, ei: $elm$core$Maybe$Nothing};
		}
	};
	return A2(
		$elm$parser$Parser$Advanced$map,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map(parseListItem),
			$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock),
		$dillonkearns$elm_markdown$Markdown$UnorderedList$parser);
}();
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$childToBlocks = F2(
	function (node, blocks) {
		switch (node.$) {
			case 0:
				var tag = node.a;
				var attributes = node.b;
				var children = node.c;
				var _v28 = $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocks(children);
				if (!_v28.$) {
					var childrenAsBlocks = _v28.a;
					var block = $dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
						A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, childrenAsBlocks));
					return $elm$core$Result$Ok(
						A2($elm$core$List$cons, block, blocks));
				} else {
					var err = _v28.a;
					return $elm$core$Result$Err(err);
				}
			case 1:
				var innerText = node.a;
				var _v29 = $dillonkearns$elm_markdown$Markdown$Parser$parse(innerText);
				if (!_v29.$) {
					var value = _v29.a;
					return $elm$core$Result$Ok(
						_Utils_ap(
							$elm$core$List$reverse(value),
							blocks));
				} else {
					var error = _v29.a;
					return $elm$core$Result$Err(
						$elm$parser$Parser$Expecting(
							A2(
								$elm$core$String$join,
								'\n',
								A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
				}
			case 2:
				var string = node.a;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string)),
						blocks));
			case 3:
				var string = node.a;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Block$Cdata(string)),
						blocks));
			case 4:
				var string = node.a;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string)),
						blocks));
			default:
				var declarationType = node.a;
				var content = node.b;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content)),
						blocks));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper = F2(
	function (referencesDict, _v23) {
		var unparsedInlines = _v23;
		var mappedReferencesDict = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$mapSecond(
					function (_v24) {
						var destination = _v24.eU;
						var title = _v24.gr;
						return _Utils_Tuple2(destination, title);
					}),
				referencesDict));
		return A2(
			$elm$core$List$map,
			$dillonkearns$elm_markdown$Markdown$Parser$mapInline,
			A2($dillonkearns$elm_markdown$Markdown$InlineParser$parse, mappedReferencesDict, unparsedInlines));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$mapInline = function (inline) {
	switch (inline.$) {
		case 0:
			var string = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Text(string);
		case 1:
			return $dillonkearns$elm_markdown$Markdown$Block$HardLineBreak;
		case 2:
			var string = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$CodeSpan(string);
		case 3:
			var string = inline.a;
			var maybeString = inline.b;
			var inlines = inline.c;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$Link,
				string,
				maybeString,
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
		case 4:
			var string = inline.a;
			var maybeString = inline.b;
			var inlines = inline.c;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$Image,
				string,
				maybeString,
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
		case 5:
			var node = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlInline(
				$dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock(node));
		default:
			var level = inline.a;
			var inlines = inline.b;
			switch (level) {
				case 1:
					return $dillonkearns$elm_markdown$Markdown$Block$Emphasis(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
				case 2:
					return $dillonkearns$elm_markdown$Markdown$Block$Strong(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
				default:
					return $dillonkearns$elm_markdown$Markdown$Block$Strong(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
			}
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock = function (node) {
	switch (node.$) {
		case 1:
			var innerText = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlComment('TODO this never happens, but use types to drop this case.');
		case 0:
			var tag = node.a;
			var attributes = node.b;
			var children = node.c;
			var parseChild = function (child) {
				if (child.$ === 1) {
					var text = child.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$textNodeToBlocks(text);
				} else {
					return _List_fromArray(
						[
							$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock(child))
						]);
				}
			};
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$HtmlElement,
				tag,
				attributes,
				A2($elm$core$List$concatMap, parseChild, children));
		case 2:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string);
		case 3:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Cdata(string);
		case 4:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string);
		default:
			var declarationType = node.a;
			var content = node.b;
			return A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content);
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocks = function (children) {
	return A2($dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksHelp, children, _List_Nil);
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksHelp = F2(
	function (remaining, soFar) {
		nodesToBlocksHelp:
		while (true) {
			if (remaining.b) {
				var node = remaining.a;
				var rest = remaining.b;
				var _v18 = A2($dillonkearns$elm_markdown$Markdown$Parser$childToBlocks, node, soFar);
				if (!_v18.$) {
					var newSoFar = _v18.a;
					var $temp$remaining = rest,
						$temp$soFar = newSoFar;
					remaining = $temp$remaining;
					soFar = $temp$soFar;
					continue nodesToBlocksHelp;
				} else {
					var e = _v18.a;
					return $elm$core$Result$Err(e);
				}
			} else {
				return $elm$core$Result$Ok(
					$elm$core$List$reverse(soFar));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parse = function (input) {
	var _v14 = A2(
		$elm$parser$Parser$Advanced$run,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
			$dillonkearns$elm_markdown$Helpers$endOfFile),
		input);
	if (_v14.$ === 1) {
		var e = _v14.a;
		return $elm$core$Result$Err(e);
	} else {
		var v = _v14.a;
		var _v15 = $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(v);
		if (_v15.$ === 1) {
			var e = _v15.a;
			return A2(
				$elm$parser$Parser$Advanced$run,
				$elm$parser$Parser$Advanced$problem(e),
				'');
		} else {
			var blocks = _v15.a;
			var isNotEmptyParagraph = function (block) {
				if ((block.$ === 5) && (!block.a.b)) {
					return false;
				} else {
					return true;
				}
			};
			return $elm$core$Result$Ok(
				A2($elm$core$List$filter, isNotEmptyParagraph, blocks));
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines = function (state) {
	return A3($dillonkearns$elm_markdown$Markdown$Parser$parseAllInlinesHelp, state, state.az, _List_Nil);
};
var $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlinesHelp = F3(
	function (state, rawBlocks, parsedBlocks) {
		parseAllInlinesHelp:
		while (true) {
			if (rawBlocks.b) {
				var rawBlock = rawBlocks.a;
				var rest = rawBlocks.b;
				var _v13 = A2($dillonkearns$elm_markdown$Markdown$Parser$parseInlines, state.a$, rawBlock);
				switch (_v13.$) {
					case 1:
						var newParsedBlock = _v13.a;
						var $temp$state = state,
							$temp$rawBlocks = rest,
							$temp$parsedBlocks = A2($elm$core$List$cons, newParsedBlock, parsedBlocks);
						state = $temp$state;
						rawBlocks = $temp$rawBlocks;
						parsedBlocks = $temp$parsedBlocks;
						continue parseAllInlinesHelp;
					case 0:
						var $temp$state = state,
							$temp$rawBlocks = rest,
							$temp$parsedBlocks = parsedBlocks;
						state = $temp$state;
						rawBlocks = $temp$rawBlocks;
						parsedBlocks = $temp$parsedBlocks;
						continue parseAllInlinesHelp;
					default:
						var e = _v13.a;
						return $elm$core$Result$Err(e);
				}
			} else {
				return $elm$core$Result$Ok(parsedBlocks);
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseHeaderInlines = F2(
	function (linkReferences, header) {
		return A2(
			$elm$core$List$map,
			function (_v11) {
				var label = _v11.cB;
				var alignment = _v11.ci;
				return A3(
					$dillonkearns$elm_markdown$Markdown$Parser$parseRawInline,
					linkReferences,
					function (parsedHeaderLabel) {
						return {ci: alignment, cB: parsedHeaderLabel};
					},
					label);
			},
			header);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseInlines = F2(
	function (linkReferences, rawBlock) {
		switch (rawBlock.$) {
			case 0:
				var level = rawBlock.a;
				var unparsedInlines = rawBlock.b;
				var _v5 = $dillonkearns$elm_markdown$Markdown$Parser$toHeading(level);
				if (!_v5.$) {
					var parsedLevel = _v5.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
						A2(
							$dillonkearns$elm_markdown$Markdown$Block$Heading,
							parsedLevel,
							A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines)));
				} else {
					var e = _v5.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem(e);
				}
			case 1:
				var unparsedInlines = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$Paragraph(
						A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines)));
			case 2:
				var html = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(html));
			case 3:
				var unparsedItems = rawBlock.a;
				var parseItem = function (unparsed) {
					var task = function () {
						var _v6 = unparsed.ei;
						if (!_v6.$) {
							if (!_v6.a) {
								return 1;
							} else {
								return 2;
							}
						} else {
							return 0;
						}
					}();
					var parsedInlines = A3($dillonkearns$elm_markdown$Markdown$Parser$parseRawInline, linkReferences, $elm$core$Basics$identity, unparsed.bI);
					return A2($dillonkearns$elm_markdown$Markdown$Block$ListItem, task, parsedInlines);
				};
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$UnorderedList(
						A2($elm$core$List$map, parseItem, unparsedItems)));
			case 4:
				var startingIndex = rawBlock.a;
				var unparsedInlines = rawBlock.b;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					A2(
						$dillonkearns$elm_markdown$Markdown$Block$OrderedList,
						startingIndex,
						A2(
							$elm$core$List$map,
							A2($dillonkearns$elm_markdown$Markdown$Parser$parseRawInline, linkReferences, $elm$core$Basics$identity),
							unparsedInlines)));
			case 5:
				var codeBlock = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(codeBlock));
			case 7:
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock($dillonkearns$elm_markdown$Markdown$Block$ThematicBreak);
			case 10:
				return $dillonkearns$elm_markdown$Markdown$Parser$EmptyBlock;
			case 11:
				var rawBlocks = rawBlock.a;
				var _v7 = A2(
					$elm$parser$Parser$Advanced$run,
					$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
					rawBlocks);
				if (!_v7.$) {
					var value = _v7.a;
					var _v8 = $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(value);
					if (!_v8.$) {
						var parsedBlocks = _v8.a;
						return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
							$dillonkearns$elm_markdown$Markdown$Block$BlockQuote(parsedBlocks));
					} else {
						var e = _v8.a;
						return $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem(e);
					}
				} else {
					var error = _v7.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem(
						$elm$parser$Parser$Problem(
							$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(error)));
				}
			case 6:
				var codeBlockBody = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(
						{bI: codeBlockBody, fr: $elm$core$Maybe$Nothing}));
			case 8:
				var _v9 = rawBlock.a;
				var header = _v9.a;
				var rows = _v9.b;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					A2(
						$dillonkearns$elm_markdown$Markdown$Block$Table,
						A2($dillonkearns$elm_markdown$Markdown$Parser$parseHeaderInlines, linkReferences, header),
						A2($dillonkearns$elm_markdown$Markdown$Parser$parseRowInlines, linkReferences, rows)));
			case 9:
				var _v10 = rawBlock.a;
				var text = _v10.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$Paragraph(
						A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, text.fY)));
			default:
				var raw = rawBlock.b;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$Paragraph(
						A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, raw)));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseRawInline = F3(
	function (linkReferences, wrap, unparsedInlines) {
		return wrap(
			A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseRowInlines = F2(
	function (linkReferences, rows) {
		return A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$elm$core$List$map,
					function (column) {
						return A3($dillonkearns$elm_markdown$Markdown$Parser$parseRawInline, linkReferences, $elm$core$Basics$identity, column);
					},
					row);
			},
			rows);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$stepRawBlock = function (revStmts) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v2) {
					return $elm$parser$Parser$Advanced$Done(revStmts);
				},
				$dillonkearns$elm_markdown$Helpers$endOfFile),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (reference) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($dillonkearns$elm_markdown$Markdown$Parser$addReference, revStmts, reference));
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$parser)),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (block) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks, revStmts, block));
				},
				function () {
					var _v3 = revStmts.az;
					_v3$2:
					while (true) {
						if (_v3.b) {
							switch (_v3.a.$) {
								case 1:
									return $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser();
								case 8:
									var table = _v3.a.a;
									return $elm$parser$Parser$Advanced$oneOf(
										_List_fromArray(
											[
												$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser(),
												$dillonkearns$elm_markdown$Markdown$Parser$tableRowIfTableStarted(table)
											]));
								default:
									break _v3$2;
							}
						} else {
							break _v3$2;
						}
					}
					return $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser();
				}()),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (block) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks, revStmts, block));
				},
				$dillonkearns$elm_markdown$Markdown$Parser$openBlockOrParagraphParser)
			]));
};
var $dillonkearns$elm_markdown$Markdown$Parser$textNodeToBlocks = function (textNodeValue) {
	return A2(
		$elm$core$Result$withDefault,
		_List_Nil,
		$dillonkearns$elm_markdown$Markdown$Parser$parse(textNodeValue));
};
var $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode = function (xmlNode) {
	switch (xmlNode.$) {
		case 1:
			var innerText = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(innerText));
		case 0:
			var tag = xmlNode.a;
			var attributes = xmlNode.b;
			var children = xmlNode.c;
			var _v1 = $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocks(children);
			if (!_v1.$) {
				var parsedChildren = _v1.a;
				return $elm$parser$Parser$Advanced$succeed(
					$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
						A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, parsedChildren)));
			} else {
				var err = _v1.a;
				return $elm$parser$Parser$Advanced$problem(err);
			}
		case 2:
			var string = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					$dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string)));
		case 3:
			var string = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					$dillonkearns$elm_markdown$Markdown$Block$Cdata(string)));
		case 4:
			var string = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					$dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string)));
		default:
			var declarationType = xmlNode.a;
			var content = xmlNode.b;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content)));
	}
};
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$blankLine,
				$dillonkearns$elm_markdown$Markdown$Parser$blockQuote,
				A2(
				$elm$parser$Parser$Advanced$map,
				$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock,
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$CodeBlock$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$indentedCodeBlock,
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v26) {
					return $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak;
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$ThematicBreak$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock(false),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Heading$parser),
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser()
			]));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$blankLine,
				$dillonkearns$elm_markdown$Markdown$Parser$blockQuote,
				A2(
				$elm$parser$Parser$Advanced$map,
				$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock,
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$CodeBlock$parser)),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Parser$setextLineParser),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v25) {
					return $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak;
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$ThematicBreak$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock(true),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Heading$parser),
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser(),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Parser$tableDelimiterInOpenParagraph)
			]));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser() {
	return A2($elm$parser$Parser$Advanced$andThen, $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode, $dillonkearns$elm_markdown$HtmlParser$html);
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser() {
	return A2(
		$elm$parser$Parser$Advanced$loop,
		{a$: _List_Nil, az: _List_Nil},
		$dillonkearns$elm_markdown$Markdown$Parser$stepRawBlock);
}
var $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockNotAfterOpenBlockOrParagraphParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockNotAfterOpenBlockOrParagraphParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockAfterOpenBlockOrParagraphParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockAfterOpenBlockOrParagraphParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$htmlParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$htmlParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$rawBlockParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$rawBlockParser;
};
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$combineResults = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Block$foldl = F3(
	function (_function, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var block = list.a;
				var remainingBlocks = list.b;
				switch (block.$) {
					case 0:
						var html = block.a;
						if (!html.$) {
							var children = html.c;
							var $temp$function = _function,
								$temp$acc = A2(_function, block, acc),
								$temp$list = _Utils_ap(children, remainingBlocks);
							_function = $temp$function;
							acc = $temp$acc;
							list = $temp$list;
							continue foldl;
						} else {
							var $temp$function = _function,
								$temp$acc = A2(_function, block, acc),
								$temp$list = remainingBlocks;
							_function = $temp$function;
							acc = $temp$acc;
							list = $temp$list;
							continue foldl;
						}
					case 1:
						var listItems = block.a;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 2:
						var _int = block.a;
						var lists = block.b;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 3:
						var blocks = block.a;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = _Utils_ap(blocks, remainingBlocks);
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 4:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 5:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 6:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 7:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					default:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText = function (block) {
	switch (block.$) {
		case 5:
			var inlines = block.a;
			return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
		case 0:
			var html = block.a;
			if (!html.$) {
				var blocks = html.c;
				return A3(
					$dillonkearns$elm_markdown$Markdown$Block$foldl,
					F2(
						function (nestedBlock, soFar) {
							return _Utils_ap(
								soFar,
								$dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText(nestedBlock));
						}),
					'',
					blocks);
			} else {
				return '';
			}
		case 1:
			var items = block.a;
			return A2(
				$elm$core$String$join,
				'\n',
				A2(
					$elm$core$List$map,
					function (_v4) {
						var task = _v4.a;
						var inlines = _v4.b;
						return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
					},
					items));
		case 2:
			var _int = block.a;
			var items = block.b;
			return A2(
				$elm$core$String$join,
				'\n',
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineText, items));
		case 3:
			var blocks = block.a;
			return A2(
				$elm$core$String$join,
				'\n',
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText, blocks));
		case 4:
			var headingLevel = block.a;
			var inlines = block.b;
			return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
		case 6:
			var header = block.a;
			var rows = block.b;
			return A2(
				$elm$core$String$join,
				'\n',
				$elm$core$List$concat(
					_List_fromArray(
						[
							A2(
							$elm$core$List$map,
							$dillonkearns$elm_markdown$Markdown$Block$extractInlineText,
							A2(
								$elm$core$List$map,
								function ($) {
									return $.cB;
								},
								header)),
							$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								$elm$core$List$map($dillonkearns$elm_markdown$Markdown$Block$extractInlineText),
								rows))
						])));
		case 7:
			var body = block.a.bI;
			return body;
		default:
			return '';
	}
};
var $dillonkearns$elm_markdown$Markdown$Block$extractInlineText = function (inlines) {
	return A3($elm$core$List$foldl, $dillonkearns$elm_markdown$Markdown$Block$extractTextHelp, '', inlines);
};
var $dillonkearns$elm_markdown$Markdown$Block$extractTextHelp = F2(
	function (inline, text) {
		switch (inline.$) {
			case 6:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 7:
				return text + ' ';
			case 5:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 1:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 2:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 0:
				var html = inline.a;
				if (!html.$) {
					var blocks = html.c;
					return A3(
						$dillonkearns$elm_markdown$Markdown$Block$foldl,
						F2(
							function (block, soFar) {
								return _Utils_ap(
									soFar,
									$dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText(block));
							}),
						text,
						blocks);
				} else {
					return text;
				}
			case 4:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			default:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHtml = F5(
	function (tagName, attributes, children, _v0, renderedChildren) {
		var htmlRenderer = _v0;
		return A2(
			$elm$core$Result$andThen,
			function (okChildren) {
				return A2(
					$elm$core$Result$map,
					function (myRenderer) {
						return myRenderer(okChildren);
					},
					A3(htmlRenderer, tagName, attributes, children));
			},
			$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(renderedChildren));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$foldThing = F3(
	function (renderer, topLevelInline, soFar) {
		var _v7 = A2($dillonkearns$elm_markdown$Markdown$Renderer$renderSingleInline, renderer, topLevelInline);
		if (!_v7.$) {
			var inline = _v7.a;
			return A2($elm$core$List$cons, inline, soFar);
		} else {
			return soFar;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHelper = F2(
	function (renderer, blocks) {
		return A2(
			$elm$core$List$filterMap,
			$dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle(renderer),
			blocks);
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle = function (renderer) {
	return function (block) {
		switch (block.$) {
			case 4:
				var level = block.a;
				var content = block.b;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						function (children) {
							return renderer.dE(
								{
									eH: children,
									ft: level,
									$9: $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(content)
								});
						},
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content)));
			case 5:
				var content = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.dX,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content)));
			case 0:
				var html = block.a;
				if (!html.$) {
					var tag = html.a;
					var attributes = html.b;
					var children = html.c;
					return $elm$core$Maybe$Just(
						A4($dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode, renderer, tag, attributes, children));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 1:
				var items = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.en,
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2(
								$elm$core$List$map,
								function (_v4) {
									var task = _v4.a;
									var children = _v4.b;
									return A2(
										$elm$core$Result$map,
										function (renderedBody) {
											return A2($dillonkearns$elm_markdown$Markdown$Block$ListItem, task, renderedBody);
										},
										A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, children));
								},
								items))));
			case 2:
				var startingIndex = block.a;
				var items = block.b;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.dU(startingIndex),
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2(
								$elm$core$List$map,
								$dillonkearns$elm_markdown$Markdown$Renderer$renderStyled(renderer),
								items))));
			case 7:
				var codeBlock = block.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.ds(codeBlock)));
			case 8:
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(renderer.ek));
			case 3:
				var nestedBlocks = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.$7,
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, nestedBlocks))));
			default:
				var header = block.a;
				var rows = block.b;
				var renderedHeaderCells = $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
					A2(
						$elm$core$List$map,
						function (_v6) {
							var label = _v6.cB;
							var alignment = _v6.ci;
							return A2(
								$elm$core$Result$map,
								$elm$core$Tuple$pair(alignment),
								A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, label));
						},
						header));
				var renderedHeader = A2(
					$elm$core$Result$map,
					function (listListView) {
						return renderer.eg(
							$elm$core$List$singleton(
								renderer.c7(
									A2(
										$elm$core$List$map,
										function (_v5) {
											var maybeAlignment = _v5.a;
											var item = _v5.b;
											return A2(renderer.eh, maybeAlignment, item);
										},
										listListView))));
					},
					renderedHeaderCells);
				var renderedBody = function (r) {
					return $elm$core$List$isEmpty(r) ? _List_Nil : _List_fromArray(
						[
							renderer.ee(r)
						]);
				};
				var alignmentForColumn = function (columnIndex) {
					return A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.ci;
						},
						$elm$core$List$head(
							A2($elm$core$List$drop, columnIndex, header)));
				};
				var renderRow = function (cells) {
					return A2(
						$elm$core$Result$map,
						renderer.c7,
						A2(
							$elm$core$Result$map,
							$elm$core$List$indexedMap(
								F2(
									function (index, cell) {
										return A2(
											renderer.ef,
											alignmentForColumn(index),
											cell);
									})),
							$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
								A2(
									$elm$core$List$map,
									$dillonkearns$elm_markdown$Markdown$Renderer$renderStyled(renderer),
									cells))));
				};
				var renderedRows = $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
					A2($elm$core$List$map, renderRow, rows));
				return $elm$core$Maybe$Just(
					A3(
						$elm$core$Result$map2,
						F2(
							function (h, r) {
								return renderer.ed(
									A2(
										$elm$core$List$cons,
										h,
										renderedBody(r)));
							}),
						renderedHeader,
						renderedRows));
		}
	};
};
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode = F4(
	function (renderer, tag, attributes, children) {
		return A5(
			$dillonkearns$elm_markdown$Markdown$Renderer$renderHtml,
			tag,
			attributes,
			children,
			renderer.fa,
			A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, children));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderSingleInline = F2(
	function (renderer, inline) {
		switch (inline.$) {
			case 4:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.ea,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 3:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.dx,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 2:
				var src = inline.a;
				var title = inline.b;
				var children = inline.c;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.dG(
							{
								gM: $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(children),
								iI: src,
								gr: title
							})));
			case 6:
				var string = inline.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.s(string)));
			case 5:
				var string = inline.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.dt(string)));
			case 1:
				var destination = inline.a;
				var title = inline.b;
				var inlines = inline.c;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$andThen,
						function (children) {
							return $elm$core$Result$Ok(
								A2(
									renderer.hM,
									{eU: destination, gr: title},
									children));
						},
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, inlines)));
			case 7:
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(renderer.dD));
			default:
				var html = inline.a;
				if (!html.$) {
					var tag = html.a;
					var attributes = html.b;
					var children = html.c;
					return $elm$core$Maybe$Just(
						A4($dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode, renderer, tag, attributes, children));
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderStyled = F2(
	function (renderer, styledStrings) {
		return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
			A3(
				$elm$core$List$foldr,
				$dillonkearns$elm_markdown$Markdown$Renderer$foldThing(renderer),
				_List_Nil,
				styledStrings));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$render = F2(
	function (renderer, ast) {
		return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
			A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, ast));
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$align = $elm$html$Html$Attributes$stringProperty('align');
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$blockquote = _VirtualDom_node('blockquote');
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$code = _VirtualDom_node('code');
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$em = _VirtualDom_node('em');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $dillonkearns$elm_markdown$Markdown$HtmlRenderer$HtmlRenderer = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Html$resultOr = F2(
	function (ra, rb) {
		if (ra.$ === 1) {
			var singleError = ra.a;
			if (!rb.$) {
				var okValue = rb.a;
				return $elm$core$Result$Ok(okValue);
			} else {
				var errorsSoFar = rb.a;
				return $elm$core$Result$Err(
					A2($elm$core$List$cons, singleError, errorsSoFar));
			}
		} else {
			var okValue = ra.a;
			return $elm$core$Result$Ok(okValue);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Html$attributesToString = function (attributes) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var name = _v0.hT;
				var value = _v0.iY;
				return name + ('=\"' + (value + '\"'));
			},
			attributes));
};
var $dillonkearns$elm_markdown$Markdown$Html$tagToString = F2(
	function (tagName, attributes) {
		return $elm$core$List$isEmpty(attributes) ? ('<' + (tagName + '>')) : ('<' + (tagName + (' ' + ($dillonkearns$elm_markdown$Markdown$Html$attributesToString(attributes) + '>'))));
	});
var $dillonkearns$elm_markdown$Markdown$Html$oneOf = function (decoders) {
	var unwrappedDecoders = A2(
		$elm$core$List$map,
		function (_v1) {
			var rawDecoder = _v1;
			return rawDecoder;
		},
		decoders);
	return function (rawDecoder) {
		return F3(
			function (tagName, attributes, innerBlocks) {
				return A2(
					$elm$core$Result$mapError,
					function (errors) {
						if (!errors.b) {
							return 'Ran into a oneOf with no possibilities!';
						} else {
							if (!errors.b.b) {
								var singleError = errors.a;
								return 'Problem with the given value:\n\n' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\n' + (singleError + '\n')));
							} else {
								return 'oneOf failed parsing this value:\n    ' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\nParsing failed in the following 2 ways:\n\n\n' + (A2(
									$elm$core$String$join,
									'\n\n',
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (index, error) {
												return '(' + ($elm$core$String$fromInt(index + 1) + (') ' + error));
											}),
										errors)) + '\n')));
							}
						}
					},
					A3(rawDecoder, tagName, attributes, innerBlocks));
			});
	}(
		A3(
			$elm$core$List$foldl,
			F2(
				function (decoder, soFar) {
					return F3(
						function (tagName, attributes, children) {
							return A2(
								$dillonkearns$elm_markdown$Markdown$Html$resultOr,
								A3(decoder, tagName, attributes, children),
								A3(soFar, tagName, attributes, children));
						});
				}),
			F3(
				function (tagName, attributes, children) {
					return $elm$core$Result$Err(_List_Nil);
				}),
			unwrappedDecoders));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$start = function (n) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'start',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer = {
	$7: $elm$html$Html$blockquote(_List_Nil),
	ds: function (_v0) {
		var body = _v0.bI;
		var language = _v0.fr;
		var classes = function () {
			if (!language.$) {
				var actualLanguage = language.a;
				return _List_fromArray(
					[
						$elm$html$Html$Attributes$class('language-' + actualLanguage)
					]);
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$pre,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$code,
					classes,
					_List_fromArray(
						[
							$elm$html$Html$text(body)
						]))
				]));
	},
	dt: function (content) {
		return A2(
			$elm$html$Html$code,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(content)
				]));
	},
	dx: function (children) {
		return A2($elm$html$Html$em, _List_Nil, children);
	},
	dD: A2($elm$html$Html$br, _List_Nil, _List_Nil),
	dE: function (_v2) {
		var level = _v2.ft;
		var children = _v2.eH;
		switch (level) {
			case 0:
				return A2($elm$html$Html$h1, _List_Nil, children);
			case 1:
				return A2($elm$html$Html$h2, _List_Nil, children);
			case 2:
				return A2($elm$html$Html$h3, _List_Nil, children);
			case 3:
				return A2($elm$html$Html$h4, _List_Nil, children);
			case 4:
				return A2($elm$html$Html$h5, _List_Nil, children);
			default:
				return A2($elm$html$Html$h6, _List_Nil, children);
		}
	},
	fa: $dillonkearns$elm_markdown$Markdown$Html$oneOf(_List_Nil),
	dG: function (imageInfo) {
		var _v4 = imageInfo.gr;
		if (!_v4.$) {
			var title = _v4.a;
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageInfo.iI),
						$elm$html$Html$Attributes$alt(imageInfo.gM),
						$elm$html$Html$Attributes$title(title)
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageInfo.iI),
						$elm$html$Html$Attributes$alt(imageInfo.gM)
					]),
				_List_Nil);
		}
	},
	hM: F2(
		function (link, content) {
			var _v5 = link.gr;
			if (!_v5.$) {
				var title = _v5.a;
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(link.eU),
							$elm$html$Html$Attributes$title(title)
						]),
					content);
			} else {
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(link.eU)
						]),
					content);
			}
		}),
	dU: F2(
		function (startingIndex, items) {
			return A2(
				$elm$html$Html$ol,
				function () {
					if (startingIndex === 1) {
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$start(startingIndex)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				A2(
					$elm$core$List$map,
					function (itemBlocks) {
						return A2($elm$html$Html$li, _List_Nil, itemBlocks);
					},
					items));
		}),
	dX: $elm$html$Html$p(_List_Nil),
	ea: function (children) {
		return A2($elm$html$Html$strong, _List_Nil, children);
	},
	ed: $elm$html$Html$table(_List_Nil),
	ee: $elm$html$Html$tbody(_List_Nil),
	ef: function (maybeAlignment) {
		var attrs = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$align,
					A2(
						$elm$core$Maybe$map,
						function (alignment) {
							switch (alignment) {
								case 0:
									return 'left';
								case 2:
									return 'center';
								default:
									return 'right';
							}
						},
						maybeAlignment))));
		return $elm$html$Html$td(attrs);
	},
	eg: $elm$html$Html$thead(_List_Nil),
	eh: function (maybeAlignment) {
		var attrs = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$align,
					A2(
						$elm$core$Maybe$map,
						function (alignment) {
							switch (alignment) {
								case 0:
									return 'left';
								case 2:
									return 'center';
								default:
									return 'right';
							}
						},
						maybeAlignment))));
		return $elm$html$Html$th(attrs);
	},
	c7: $elm$html$Html$tr(_List_Nil),
	s: $elm$html$Html$text,
	ek: A2($elm$html$Html$hr, _List_Nil, _List_Nil),
	en: function (items) {
		return A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (item) {
					var task = item.a;
					var children = item.b;
					var checkbox = function () {
						switch (task) {
							case 0:
								return $elm$html$Html$text('');
							case 1:
								return A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$checked(false),
											$elm$html$Html$Attributes$type_('checkbox')
										]),
									_List_Nil);
							default:
								return A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$checked(true),
											$elm$html$Html$Attributes$type_('checkbox')
										]),
									_List_Nil);
						}
					}();
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						A2($elm$core$List$cons, checkbox, children));
				},
				items));
	}
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Content$renderer = _Utils_update(
	$dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer,
	{
		hM: F2(
			function (link, content) {
				var _v0 = link.gr;
				if (!_v0.$) {
					var title = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(link.eU),
								$elm$html$Html$Attributes$title(title),
								$elm$html$Html$Attributes$target('_blank')
							]),
						content);
				} else {
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(link.eU),
								$elm$html$Html$Attributes$target('_blank')
							]),
						content);
				}
			})
	});
var $author$project$Content$markdownDecoder = function (content) {
	return function (res) {
		if (!res.$) {
			var v = res.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			var e = res.a;
			return $elm$json$Json$Decode$fail(e);
		}
	}(
		A2(
			$elm$core$Result$map,
			$author$project$SE$UI$Content$content(_List_Nil),
			A2(
				$elm$core$Result$map,
				$elm$core$List$map($rtfeldman$elm_css$Html$Styled$fromUnstyled),
				A2(
					$elm$core$Result$andThen,
					$dillonkearns$elm_markdown$Markdown$Renderer$render($author$project$Content$renderer),
					A2(
						$elm$core$Result$mapError,
						$author$project$Content$deadEndsToString,
						$dillonkearns$elm_markdown$Markdown$Parser$parse(content))))));
};
var $author$project$Content$decoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Content$Content,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Content$markdownDecoder,
		A2($elm$json$Json$Decode$field, 'body', $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'files',
		$elm$json$Json$Decode$list($author$project$File$decoder)));
var $elm$url$Url$Builder$Absolute = {$: 0};
var $elm$url$Url$Builder$rootToPrePath = function (root) {
	switch (root.$) {
		case 0:
			return '/';
		case 1:
			return '';
		default:
			var prePath = root.a;
			return prePath + '/';
	}
};
var $elm$url$Url$Builder$custom = F4(
	function (root, pathSegments, parameters, maybeFragment) {
		var fragmentless = _Utils_ap(
			$elm$url$Url$Builder$rootToPrePath(root),
			_Utils_ap(
				A2($elm$core$String$join, '/', pathSegments),
				$elm$url$Url$Builder$toQuery(parameters)));
		if (maybeFragment.$ === 1) {
			return fragmentless;
		} else {
			var fragment = maybeFragment.a;
			return fragmentless + ('#' + fragment);
		}
	});
var $author$project$QueryParams$empty = $elm$core$Dict$empty;
var $author$project$Sticker$labels = function (sticker) {
	switch (sticker) {
		case 0:
			return _Utils_Tuple2('Nyhet', 'Nyheter');
		case 1:
			return _Utils_Tuple2('Fynd', 'Fynd');
		default:
			return _Utils_Tuple2('Kampanj', 'Kampanjer');
	}
};
var $author$project$Sticker$plural = A2($elm$core$Basics$composeR, $author$project$Sticker$labels, $elm$core$Tuple$second);
var $author$project$Route$toQueryParameters = function (queryParams) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var key = _v0.a;
			var values = _v0.b;
			return A2(
				$elm$url$Url$Builder$string,
				key,
				A2($elm$core$String$join, ',', values));
		},
		$elm$core$Dict$toList(queryParams));
};
var $author$project$Slug$toString = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$Route$toString = function (page) {
	var _v0 = function () {
		switch (page.$) {
			case 0:
				return _Utils_Tuple2(_List_Nil, $author$project$QueryParams$empty);
			case 1:
				return _Utils_Tuple2(
					_List_fromArray(
						['rma']),
					$author$project$QueryParams$empty);
			case 2:
				return _Utils_Tuple2(
					_List_fromArray(
						['deliveryterms']),
					$author$project$QueryParams$empty);
			case 3:
				return _Utils_Tuple2(
					_List_fromArray(
						['service']),
					$author$project$QueryParams$empty);
			case 6:
				var queryParams_ = page.a;
				var mainSlug = page.b;
				var _v2 = page.c;
				var id = _v2.a;
				var slug = _v2.b;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							mainSlug,
							slug + ('-' + $elm$core$String$fromInt(
							$author$project$Id$toInt(id)))
						]),
					queryParams_);
			case 7:
				var queryParams_ = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						['search']),
					queryParams_);
			case 9:
				var queryParams_ = page.a;
				var slug = page.b;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'brands',
							$author$project$Slug$toString(slug)
						]),
					queryParams_);
			case 10:
				var slug = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'brands',
							$author$project$Slug$toString(slug),
							'service'
						]),
					$author$project$QueryParams$empty);
			case 8:
				return _Utils_Tuple2(
					_List_fromArray(
						['brands']),
					$author$project$QueryParams$empty);
			case 5:
				var str = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[str]),
					$author$project$QueryParams$empty);
			case 4:
				var queryParams_ = page.a;
				var str = page.b;
				var sticker = page.c;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							str,
							$elm$core$String$toLower(
							$author$project$Sticker$plural(sticker))
						]),
					queryParams_);
			case 11:
				var queryParams_ = page.a;
				var _v3 = page.b;
				var id = _v3.a;
				var slug = _v3.b;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$author$project$Slug$toString(slug) + ('-' + ($elm$core$String$fromInt(
							$author$project$Id$toInt(id)) + '.html'))
						]),
					queryParams_);
			case 12:
				return _Utils_Tuple2(
					_List_fromArray(
						['checkout']),
					$author$project$QueryParams$empty);
			case 13:
				var queryParams_ = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						['orders']),
					queryParams_);
			case 14:
				var id = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'orders',
							$elm$core$String$fromInt(
							$author$project$Id$toInt(id))
						]),
					$author$project$QueryParams$empty);
			case 15:
				var id = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'orders',
							$elm$core$String$fromInt(
							$author$project$Id$toInt(id)),
							'thank-you'
						]),
					$author$project$QueryParams$empty);
			case 24:
				var queryParams_ = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						['invoices']),
					queryParams_);
			case 16:
				return _Utils_Tuple2(
					_List_fromArray(
						['login']),
					$author$project$QueryParams$empty);
			case 18:
				var queryParams_ = page.a;
				var token = page.b;
				return _Utils_Tuple2(
					_List_fromArray(
						['password', 'reset', token]),
					queryParams_);
			case 17:
				return _Utils_Tuple2(
					_List_fromArray(
						['password', 'reset']),
					$author$project$QueryParams$empty);
			case 19:
				return _Utils_Tuple2(
					_List_fromArray(
						['account']),
					$author$project$QueryParams$empty);
			case 20:
				return _Utils_Tuple2(
					_List_fromArray(
						['account', 'profile']),
					$author$project$QueryParams$empty);
			case 21:
				return _Utils_Tuple2(
					_List_fromArray(
						['account', 'settings']),
					$author$project$QueryParams$empty);
			case 22:
				return _Utils_Tuple2(
					_List_fromArray(
						['account', 'password']),
					$author$project$QueryParams$empty);
			case 23:
				return _Utils_Tuple2(
					_List_fromArray(
						['account', 'admin']),
					$author$project$QueryParams$empty);
			case 25:
				return _Utils_Tuple2(
					_List_fromArray(
						['bids']),
					$author$project$QueryParams$empty);
			default:
				var id = page.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'bids',
							$elm$core$String$fromInt(
							$author$project$Id$toInt(id))
						]),
					$author$project$QueryParams$empty);
		}
	}();
	var paths = _v0.a;
	var queryParams = _v0.b;
	return A4(
		$elm$url$Url$Builder$custom,
		$elm$url$Url$Builder$Absolute,
		paths,
		$author$project$Route$toQueryParameters(queryParams),
		$elm$core$Maybe$Nothing);
};
var $author$project$Content$get = F2(
	function (msg, route) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$content(
				$author$project$Route$toString(route)),
			$author$project$Content$decoder,
			msg);
	});
var $author$project$Page$Content$init = F2(
	function (session, route) {
		return _Utils_Tuple2(
			{eN: $author$project$Status$Loading, d4: route, K: session},
			A2($author$project$Content$get, $author$project$Page$Content$CompletedLoadContent, route));
	});
var $author$project$Page$Home$CompletedLoadTime = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Home$DisplayOrders = 0;
var $author$project$Page$Home$CompletedLoadCategories = function (a) {
	return {$: 5, a: a};
};
var $author$project$Category$Full = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Category$descriptionAndImageDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Category$Full,
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'short_description', $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed('')
			])),
	A2($elm$json$Json$Decode$field, 'image', $author$project$Image$decoder));
var $author$project$Category$fullDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Category$descriptionAndImageDecoder,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Category$internalsDecoder,
		$elm$json$Json$Decode$succeed($author$project$Category$Category)));
var $author$project$Api$Endpoint$rootCategories = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['categories']),
	_List_fromArray(
		[
			A2($elm$url$Url$Builder$string, 'only_root', 'true'),
			A2($elm$url$Url$Builder$string, 'fields', 'short_description,image')
		]));
var $author$project$Category$fetchRoots = function (msg) {
	return A3(
		$author$project$Api$get,
		$author$project$Api$Endpoint$rootCategories,
		$elm$json$Json$Decode$list($author$project$Category$fullDecoder),
		msg);
};
var $author$project$Page$Home$loadCategories = $author$project$Category$fetchRoots($author$project$Page$Home$CompletedLoadCategories);
var $author$project$Page$Home$CompletedLoadNewsArticles = function (a) {
	return {$: 6, a: a};
};
var $author$project$NewsArticle$NewsArticle = F5(
	function (title, url, published_at, tag, image_url) {
		return {hy: image_url, il: published_at, iO: tag, gr: title, cg: url};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$NewsArticle$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$NewsArticle$NewsArticle,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'published_at', $author$project$Helpers$posixDecoder),
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'image_url', $elm$json$Json$Decode$string));
var $author$project$Api$Endpoint$news = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['news']),
	_List_Nil);
var $author$project$NewsArticle$list = A2(
	$author$project$Api$get,
	$author$project$Api$Endpoint$news,
	$elm$json$Json$Decode$list($author$project$NewsArticle$decoder));
var $author$project$Page$Home$loadNews = $author$project$NewsArticle$list($author$project$Page$Home$CompletedLoadNewsArticles);
var $author$project$Page$Home$CompletedLoadOrders = function (a) {
	return {$: 2, a: a};
};
var $author$project$Document$Scope$My = 0;
var $author$project$Document$defaultQueryParams = {dW: 1, dY: 50, d0: '', c$: 0};
var $author$project$Order$Collection = F3(
	function (data, lastPage, currentPage) {
		return {g1: currentPage, g2: data, hJ: lastPage};
	});
var $author$project$Order$Preview = 0;
var $author$project$Order$IsPending = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Order$Internals = F8(
	function (id, yourPurchaseOrderNumber, status, goodsLabel, createdAt, updatedAt, yourRef, deliveryAddress) {
		return {g$: createdAt, g8: deliveryAddress, hq: goodsLabel, hx: id, gh: status, iW: updatedAt, i0: yourPurchaseOrderNumber, i1: yourRef};
	});
var $author$project$Address$Custom = function (a) {
	return {$: 1, a: a};
};
var $author$project$Address$customDecoder = A2($elm$json$Json$Decode$map, $author$project$Address$Custom, $author$project$Address$metadataDecoder);
var $author$project$Address$deliveryDecoderWithCustom = A2(
	$author$project$Address$typeDecoder,
	'delivery',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[$author$project$Address$registeredDecoder, $author$project$Address$customDecoder])));
var $elm$json$Json$Decode$map8 = _Json_map8;
var $author$project$Order$Backorder = 4;
var $author$project$Order$Cancelled = 3;
var $author$project$Order$Closed = 7;
var $author$project$Order$Dispatched = 2;
var $author$project$Order$Internal = 5;
var $author$project$Order$Pending = 0;
var $author$project$Order$Registered = 1;
var $author$project$Order$statusDecoderHelper = function (str) {
	switch (str) {
		case 'pending':
			return $elm$json$Json$Decode$succeed(0);
		case 'registered':
			return $elm$json$Json$Decode$succeed(1);
		case 'cancelled':
			return $elm$json$Json$Decode$succeed(3);
		case 'dispatched':
			return $elm$json$Json$Decode$succeed(2);
		case 'backorder':
			return $elm$json$Json$Decode$succeed(4);
		case 'internal':
			return $elm$json$Json$Decode$succeed(5);
		case 'closed':
			return $elm$json$Json$Decode$succeed(7);
		default:
			return $elm$json$Json$Decode$fail('Status \'' + (str + '\' is not supported.'));
	}
};
var $author$project$Order$statusDecoder = A2($elm$json$Json$Decode$andThen, $author$project$Order$statusDecoderHelper, $elm$json$Json$Decode$string);
var $author$project$Order$StrRef = function (a) {
	return {$: 0, a: a};
};
var $author$project$Order$User = F3(
	function (id, name, email) {
		return {be: email, hx: id, hT: name};
	});
var $author$project$Order$UserRef = function (a) {
	return {$: 1, a: a};
};
var $author$project$Order$yourRefDecoderHelper = A2(
	$elm$json$Json$Decode$map,
	$author$project$Order$UserRef,
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Order$User,
		A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string)));
var $author$project$Order$yourRefDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$Order$StrRef, $elm$json$Json$Decode$string),
			$author$project$Order$yourRefDecoderHelper
		]));
var $author$project$Order$internalsDecoder = A9(
	$elm$json$Json$Decode$map8,
	$author$project$Order$Internals,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'your_purchase_order_number', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'status', $author$project$Order$statusDecoder),
	A2($elm$json$Json$Decode$field, 'goods_label', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'created_at', $author$project$Helpers$posixDecoder),
	A2($elm$json$Json$Decode$field, 'updated_at', $author$project$Helpers$posixDecoder),
	A2($elm$json$Json$Decode$field, 'your_ref', $author$project$Order$yourRefDecoder),
	A2($elm$json$Json$Decode$field, 'delivery_address', $author$project$Address$deliveryDecoderWithCustom));
var $author$project$Order$pendingDecoder = function (scopeDecoder) {
	return A3($elm$json$Json$Decode$map2, $author$project$Order$IsPending, $author$project$Order$internalsDecoder, scopeDecoder);
};
var $author$project$Order$IsSynced = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Order$syncedDecoder = F2(
	function (scopeDecoder, externalId_) {
		return A3(
			$elm$json$Json$Decode$map2,
			$author$project$Order$IsSynced(externalId_),
			$author$project$Order$internalsDecoder,
			scopeDecoder);
	});
var $author$project$Order$decoderHelper = F2(
	function (scopeDecoder, maybeExternalId) {
		if (maybeExternalId.$ === 1) {
			return $author$project$Order$pendingDecoder(scopeDecoder);
		} else {
			var externalId_ = maybeExternalId.a;
			return A2($author$project$Order$syncedDecoder, scopeDecoder, externalId_);
		}
	});
var $author$project$Order$previewDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Order$decoderHelper(
		$elm$json$Json$Decode$succeed(0)),
	A2(
		$elm$json$Json$Decode$field,
		'external_id',
		$elm$json$Json$Decode$maybe($author$project$ExternalId$decoder)));
var $author$project$Order$collectionDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Order$Collection,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		$elm$json$Json$Decode$list($author$project$Order$previewDecoder)),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['meta', 'last_page']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['meta', 'current_page']),
		$elm$json$Json$Decode$int));
var $author$project$Api$Endpoint$orders = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['orders']),
		params);
};
var $author$project$Document$Scope$toString = function (scope) {
	if (!scope) {
		return 'my';
	} else {
		return 'all';
	}
};
var $author$project$Document$toQueryParameters = function (params) {
	return _List_fromArray(
		[
			A2($elm$url$Url$Builder$string, 'q', params.d0),
			A2(
			$elm$url$Url$Builder$int,
			'page',
			A2($elm$core$Basics$max, 1, params.dW)),
			A2(
			$elm$url$Url$Builder$int,
			'perPage',
			A3($elm$core$Basics$clamp, 1, 50, params.dY)),
			A2(
			$elm$url$Url$Builder$string,
			'scope',
			$author$project$Document$Scope$toString(params.c$))
		]);
};
var $author$project$Order$list = F2(
	function (msg, params) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$orders(
				$author$project$Document$toQueryParameters(params)),
			$author$project$Order$collectionDecoder,
			msg);
	});
var $author$project$Page$Home$loadOrders = A2(
	$author$project$Order$list,
	$author$project$Page$Home$CompletedLoadOrders,
	_Utils_update(
		$author$project$Document$defaultQueryParams,
		{dY: 5}));
var $author$project$Page$Home$CompletedLoadProducts = function (a) {
	return {$: 4, a: a};
};
var $author$project$OrderBy$Recent = {$: 0};
var $author$project$Filter$Selections = $elm$core$Basics$identity;
var $author$project$Filter$emptySelections = $elm$core$Dict$empty;
var $author$project$Api$Endpoint$products = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['products']),
		params);
};
var $author$project$Product$Match = function (a) {
	return {$: 0, a: a};
};
var $author$project$Product$MatchRec = F5(
	function (products, filters, spelling, categories, total) {
		return {gV: categories, J: filters, ik: products, gg: spelling, F: total};
	});
var $author$project$Product$Basic = function (a) {
	return {$: 1, a: a};
};
var $author$project$Product$guestDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Product$internalsDecoder,
	$elm$json$Json$Decode$succeed($author$project$Product$Basic));
var $author$project$Product$decoder = function (maybeUserId) {
	if (!maybeUserId.$) {
		var userId = maybeUserId.a;
		return $author$project$Product$fullDecoder(userId);
	} else {
		return $author$project$Product$guestDecoder;
	}
};
var $author$project$Category$filterResultsDecoderHelper = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'doc_count', $elm$json$Json$Decode$int));
var $author$project$Category$filterResultsDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	$elm$json$Json$Decode$list($author$project$Category$filterResultsDecoderHelper));
var $author$project$Helpers$isJust = function (a) {
	if (a.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Filter$Collapsed = 0;
var $author$project$Filter$Open = 1;
var $author$project$Filter$defaultState = F3(
	function (isLoggedIn, length, index) {
		return function (threshold) {
			return (((_Utils_cmp(length, threshold) < 1) && (!index)) || ((_Utils_cmp(length, threshold + 1) > -1) && (index === 1))) ? 1 : 0;
		}(
			isLoggedIn ? 3 : 2);
	});
var $author$project$Filter$Num = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Filter$NumberFilter = $elm$core$Basics$identity;
var $author$project$Filter$NumRec = F6(
	function (name, slug, min, max, total_min, total_max) {
		return {fw: max, fy: min, hT: name, gb: slug, da: total_max, db: total_min};
	});
var $author$project$Filter$numRecDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'total_max',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total_min',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'max',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'min',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'slug',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Filter$NumRec)))))));
var $author$project$Filter$numberDecoder = function (defaultState_) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Filter$Num(defaultState_),
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Filter$numRecDecoder));
};
var $author$project$Filter$Str = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Filter$StringFilter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Filter$Option = F3(
	function (name, slug, doc_count) {
		return {bc: doc_count, hT: name, gb: slug};
	});
var $author$project$Filter$optionDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Filter$Option,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'doc_count', $elm$json$Json$Decode$int));
var $author$project$Filter$StrRec = F4(
	function (name, slug, prefix, suffix) {
		return {hT: name, dZ: prefix, gb: slug, ec: suffix};
	});
var $author$project$Filter$strRecDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'suffix',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'prefix',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'slug',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Filter$StrRec)))));
var $author$project$Filter$stringDecoder = function (defaultState_) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Filter$Str(defaultState_),
		A3(
			$elm$json$Json$Decode$map2,
			$author$project$Filter$StringFilter,
			$author$project$Filter$strRecDecoder,
			A2(
				$elm$json$Json$Decode$field,
				'values',
				$elm$json$Json$Decode$list($author$project$Filter$optionDecoder))));
};
var $author$project$Filter$decoder = F3(
	function (isLoggedIn, length, index) {
		return function (a) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$author$project$Filter$stringDecoder(a),
						$author$project$Filter$numberDecoder(a)
					]));
		}(
			A3($author$project$Filter$defaultState, isLoggedIn, length, index));
	});
var $elm_community$json_extra$Json$Decode$Extra$combine = A2(
	$elm$core$List$foldr,
	$elm$json$Json$Decode$map2($elm$core$List$cons),
	$elm$json$Json$Decode$succeed(_List_Nil));
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm_community$json_extra$Json$Decode$Extra$sequence = function (decoders) {
	return $elm_community$json_extra$Json$Decode$Extra$combine(
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, dec) {
					return A2($elm$json$Json$Decode$index, idx, dec);
				}),
			decoders));
};
var $elm_community$json_extra$Json$Decode$Extra$indexedList = function (indexedDecoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (values) {
			return $elm_community$json_extra$Json$Decode$Extra$sequence(
				A2(
					$elm$core$List$map,
					indexedDecoder,
					A2(
						$elm$core$List$range,
						0,
						$elm$core$List$length(values) - 1)));
		},
		$elm$json$Json$Decode$list($elm$json$Json$Decode$value));
};
var $author$project$Filter$listDecoder = function (isLoggedIn) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (values) {
			return $elm_community$json_extra$Json$Decode$Extra$indexedList(
				A2(
					$author$project$Filter$decoder,
					isLoggedIn,
					$elm$core$List$length(values)));
		},
		$elm$json$Json$Decode$list($elm$json$Json$Decode$value));
};
var $author$project$Product$Suggestion = function (a) {
	return {$: 1, a: a};
};
var $author$project$Product$spellingSuggestionSuggestionDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Product$Suggestion,
	A2(
		$elm$json$Json$Decode$field,
		'options',
		A2(
			$elm$json$Json$Decode$index,
			0,
			A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string))));
var $author$project$Product$Term = function (a) {
	return {$: 0, a: a};
};
var $author$project$Product$spellingSuggestionTermDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Product$Term,
	A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string));
var $author$project$Product$spellingDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Product$spellingSuggestionSuggestionDecoder, $author$project$Product$spellingSuggestionTermDecoder]));
var $author$project$Product$matchRecDecoder = function (maybeUserId) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'categories',
			$author$project$Category$filterResultsDecoder,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'spelling',
				$elm$json$Json$Decode$list($author$project$Product$spellingDecoder),
				_List_Nil,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'filters',
					$author$project$Filter$listDecoder(
						$author$project$Helpers$isJust(maybeUserId)),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'products',
						$elm$json$Json$Decode$list(
							$author$project$Product$decoder(maybeUserId)),
						$elm$json$Json$Decode$succeed($author$project$Product$MatchRec))))));
};
var $author$project$Product$matchDecoder = function (maybeUserId) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Product$Match,
		$author$project$Product$matchRecDecoder(maybeUserId));
};
var $author$project$Product$NoMatch = function (a) {
	return {$: 1, a: a};
};
var $author$project$Product$NoMatchRec = F2(
	function (spelling, total) {
		return {gg: spelling, F: total};
	});
var $author$project$Product$noMatchRecDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'total',
	$elm$json$Json$Decode$int,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'spelling',
		$elm$json$Json$Decode$list($author$project$Product$spellingDecoder),
		_List_Nil,
		$elm$json$Json$Decode$succeed($author$project$Product$NoMatchRec)));
var $author$project$Product$noMatchDecoder = A2($elm$json$Json$Decode$map, $author$project$Product$NoMatch, $author$project$Product$noMatchRecDecoder);
var $author$project$Product$searchResultDecoder = function (maybeUserId) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$author$project$Product$matchDecoder(maybeUserId),
				$author$project$Product$noMatchDecoder
			]));
};
var $author$project$Helpers$booleanToString = function (b) {
	return b ? 'true' : 'false';
};
var $author$project$Filter$selectionsToQueryParametersHelper = function (selection) {
	switch (selection.$) {
		case 0:
			var set = selection.a;
			return A2(
				$elm$core$String$join,
				',',
				$elm$core$Set$toList(set));
		case 1:
			var _v1 = selection.a;
			var min = _v1.a;
			var max = _v1.b;
			return $elm$core$String$fromFloat(min) + ('-' + $elm$core$String$fromFloat(max));
		default:
			var id = selection.a;
			return $elm$core$String$fromInt(
				$author$project$Id$toInt(id));
	}
};
var $author$project$Filter$selectionsToQueryParameters = function (_v0) {
	var dict = _v0;
	return A2(
		$elm$core$List$map,
		function (_v1) {
			var key = _v1.a;
			var s = _v1.b;
			return A2(
				$elm$url$Url$Builder$string,
				key,
				$author$project$Filter$selectionsToQueryParametersHelper(s));
		},
		$elm$core$Dict$toList(dict));
};
var $author$project$OrderBy$sortToString = function (sort) {
	if (!sort) {
		return 'asc';
	} else {
		return 'desc';
	}
};
var $author$project$OrderBy$toString = function (a) {
	switch (a.$) {
		case 0:
			return 'recent';
		case 1:
			var sort = a.a;
			return 'product_code:' + $author$project$OrderBy$sortToString(sort);
		case 2:
			var sort = a.a;
			return 'name:' + $author$project$OrderBy$sortToString(sort);
		case 3:
			var sort = a.a;
			return 'base_price:' + $author$project$OrderBy$sortToString(sort);
		default:
			return 'relevance';
	}
};
var $author$project$Product$toQueryParameters = function (params) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'orderBy',
				$author$project$OrderBy$toString(params.ia)),
				A2(
				$elm$url$Url$Builder$string,
				'offset',
				$elm$core$String$fromInt(
					A2($elm$core$Basics$max, 0, params.h4))),
				A2(
				$elm$url$Url$Builder$string,
				'limit',
				$elm$core$String$fromInt(
					A2($elm$core$Basics$max, 1, params.hL))),
				A2(
				$elm$url$Url$Builder$string,
				'aggregations',
				$author$project$Helpers$booleanToString(params.gJ)),
				A2($elm$url$Url$Builder$string, 'q', params.d0)
			]),
		$author$project$Filter$selectionsToQueryParameters(params.iD));
};
var $author$project$Product$list = F3(
	function (msg, maybeUserId, params) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$products(
				$author$project$Product$toQueryParameters(params)),
			$author$project$Product$searchResultDecoder(maybeUserId),
			msg);
	});
var $author$project$Page$Home$loadProducts = function (maybeUserId) {
	return A3(
		$author$project$Product$list,
		$author$project$Page$Home$CompletedLoadProducts,
		maybeUserId,
		{gJ: false, hL: 5, h4: 0, ia: $author$project$OrderBy$Recent, d0: '', iD: $author$project$Filter$emptySelections});
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Metadata$encode = function (metadata) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(metadata.eT)),
				_Utils_Tuple2(
				'image',
				$elm$json$Json$Encode$string(metadata.dG))
			]));
};
var $author$project$Metadata$updateMeta = _Platform_outgoingPort('updateMeta', $elm$core$Basics$identity);
var $author$project$Metadata$store = A2($elm$core$Basics$composeL, $author$project$Metadata$updateMeta, $author$project$Metadata$encode);
var $author$project$Page$Home$init = function (session) {
	var _v0 = function () {
		var _v1 = $author$project$Session$user(session);
		if (_v1.$ === 1) {
			return {dr: _List_Nil, dN: $elm$core$Maybe$Nothing, aP: $author$project$Status$Loading};
		} else {
			var user = _v1.a;
			return {
				dr: _List_fromArray(
					[$author$project$Page$Home$loadOrders]),
				dN: $elm$core$Maybe$Just(user.hx),
				aP: $author$project$Status$Loading
			};
		}
	}();
	var orders = _v0.aP;
	var cmds = _v0.dr;
	var maybeUserId = _v0.dN;
	return _Utils_Tuple2(
		{
			cu: 0,
			hE: $author$project$Status$Loading,
			hF: $elm$core$Dict$empty,
			cF: _List_Nil,
			cK: _List_Nil,
			aP: orders,
			K: session,
			el: $author$project$Session$time(session)
		},
		$elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$cons,
				$author$project$Metadata$store(
					{eT: 'Special-Elektronik AB är en ledande distributör inom AV-teknik, belysning, smarta hem, och kabel-TV/fiberoptik. Vi erbjuder teknik från de främsta leverantörerna i världen och vår ambition är att alltid hålla en hög teknisk kompetensnivå.', dG: 'https://specialelektronik.se/assets/images/layout/se-logo-ny.png'}),
				A2(
					$elm$core$List$cons,
					A2($elm$core$Task$perform, $author$project$Page$Home$CompletedLoadTime, $elm$time$Time$now),
					A2(
						$elm$core$List$cons,
						$author$project$Page$Home$loadProducts(maybeUserId),
						A2(
							$elm$core$List$cons,
							$author$project$Page$Home$loadCategories,
							A2($elm$core$List$cons, $author$project$Page$Home$loadNews, cmds)))))));
};
var $author$project$Page$Invoices$CompletedLoadNow = function (a) {
	return {$: 4, a: a};
};
var $author$project$Document$Scope$All = 1;
var $author$project$Document$Scope$fromString = function (str) {
	switch (str) {
		case 'my':
			return $elm$core$Maybe$Just(0);
		case 'all':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$QueryParams$get = F3(
	function (parser, key, queryParams) {
		return A2(
			$elm$core$Maybe$andThen,
			parser,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$List$head,
				A2($elm$core$Dict$get, key, queryParams)));
	});
var $author$project$Document$Scope$fromQueryParams = F2(
	function (_default, queryParams_) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3($author$project$QueryParams$get, $author$project$Document$Scope$fromString, 'scope', queryParams_));
	});
var $author$project$QueryParams$getInt = $author$project$QueryParams$get($elm$core$String$toInt);
var $author$project$Page$Invoices$CompletedLoadInvoices = function (a) {
	return {$: 3, a: a};
};
var $author$project$Invoice$Collection = F3(
	function (data, lastPage, currentPage) {
		return {g1: currentPage, g2: data, hJ: lastPage};
	});
var $author$project$Invoice$Invoice = $elm$core$Basics$identity;
var $author$project$Invoice$Internals = function (id) {
	return function (externalId) {
		return function (type_) {
			return function (name) {
				return function (isPaid) {
					return function (totalAmount) {
						return function (invoicedAt) {
							return function (dueAt) {
								return function (order) {
									return function (url) {
										return function (updatedAt) {
											return {eW: dueAt, hh: externalId, hx: id, hD: invoicedAt, fl: isPaid, hT: name, h9: order, iS: totalAmount, iT: type_, iW: updatedAt, cg: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$InvoiceType$CreditInvoice = 1;
var $author$project$InvoiceType$Invoice = 0;
var $author$project$InvoiceType$fromCode = function (str) {
	switch (str) {
		case 'F':
			return $elm$core$Maybe$Just(0);
		case 'K':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$InvoiceType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		A2(
			$elm$core$Basics$composeR,
			$author$project$InvoiceType$fromCode,
			$elm$core$Maybe$map($elm$json$Json$Decode$succeed)),
		$elm$core$Maybe$withDefault(
			$elm$json$Json$Decode$fail('invoice type code not supported.'))),
	$elm$json$Json$Decode$string);
var $author$project$Invoice$internalsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updated_at',
	$author$project$Helpers$posixDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'url',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'order',
			$elm$json$Json$Decode$maybe($author$project$Order$previewDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'due_at',
				$elm$json$Json$Decode$maybe($author$project$Helpers$posixDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'invoiced_at',
					$author$project$Helpers$posixDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'total_amount',
						$elm$json$Json$Decode$float,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'is_paid',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'name',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'type_code',
									$author$project$InvoiceType$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'external_id',
										$author$project$ExternalId$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$author$project$Id$decoder,
											$elm$json$Json$Decode$succeed($author$project$Invoice$Internals))))))))))));
var $author$project$Invoice$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Invoice$internalsDecoder);
var $author$project$Invoice$collectionDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Invoice$Collection,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		$elm$json$Json$Decode$list($author$project$Invoice$decoder)),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['meta', 'last_page']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['meta', 'current_page']),
		$elm$json$Json$Decode$int));
var $author$project$Api$Endpoint$invoices = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['invoices']),
		params);
};
var $author$project$Invoice$list = F2(
	function (msg, params) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$invoices(
				$author$project$Document$toQueryParameters(params)),
			$author$project$Invoice$collectionDecoder,
			msg);
	});
var $author$project$Page$Invoices$loadInvoices = $author$project$Invoice$list($author$project$Page$Invoices$CompletedLoadInvoices);
var $author$project$Page$Invoices$init = F2(
	function (session, queryParams) {
		var q = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				$elm$core$String$join(', '),
				A2($elm$core$Dict$get, 'q', queryParams)));
		var page = A2(
			$elm$core$Maybe$withDefault,
			1,
			A2($author$project$QueryParams$getInt, 'page', queryParams));
		var defaultScope = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.c0;
					},
					function ($) {
						return $.ha;
					}),
				$author$project$Session$user(session)));
		var params = _Utils_update(
			$author$project$Document$defaultQueryParams,
			{
				dW: page,
				d0: q,
				c$: A2($author$project$Document$Scope$fromQueryParams, defaultScope, queryParams)
			});
		var newModel = {
			R: $author$project$Status$Loading,
			E: params,
			K: session,
			el: $author$project$Session$time(session)
		};
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Page$Invoices$CompletedLoadNow, $elm$time$Time$now),
						$author$project$Page$Invoices$loadInvoices(newModel.E)
					])));
	});
var $author$project$Route$Home = {$: 0};
var $author$project$Page$Login$NotValidated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$emptyInputs = {be: '', an: '', b4: false};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Route$load = function (route) {
	return $elm$browser$Browser$Navigation$load(
		$author$project$Route$toString(route));
};
var $author$project$Page$Login$init = F2(
	function (session, route) {
		var cmd = function () {
			var _v0 = $author$project$Session$user(session);
			if (_v0.$ === 1) {
				return $elm$core$Platform$Cmd$none;
			} else {
				return $author$project$Route$load($author$project$Route$Home);
			}
		}();
		return _Utils_Tuple2(
			{
				fx: '',
				b5: route,
				K: session,
				gh: $author$project$Page$Login$NotValidated($author$project$Page$Login$emptyInputs)
			},
			cmd);
	});
var $author$project$Page$MainCategory$CompletedLoadCategory = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Endpoint$categoryFullTree = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'categories',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id))
			]),
		_List_fromArray(
			[
				A2($elm$url$Url$Builder$string, 'format', 'tree'),
				A2($elm$url$Url$Builder$string, 'fields', 'description,image')
			]));
};
function $author$project$Category$cyclic$treeFullDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'children',
		$elm$json$Json$Decode$list(
			$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $author$project$Category$cyclic$treeFullDecoder();
				})),
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			$author$project$Category$fullDecoder,
			$elm$json$Json$Decode$succeed($zwilias$elm_rosetree$Tree$tree)));
}
var $author$project$Category$treeFullDecoder = $author$project$Category$cyclic$treeFullDecoder();
$author$project$Category$cyclic$treeFullDecoder = function () {
	return $author$project$Category$treeFullDecoder;
};
var $author$project$Category$fetchTreeFull = F2(
	function (msg, catId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$categoryFullTree(catId),
			$author$project$Category$treeFullDecoder,
			msg);
	});
var $author$project$Page$MainCategory$init = F2(
	function (session, previewTree) {
		return _Utils_Tuple2(
			{K: session, by: $author$project$Status$Loading},
			A2(
				$author$project$Category$fetchTreeFull,
				$author$project$Page$MainCategory$CompletedLoadCategory,
				$author$project$Category$id(
					$zwilias$elm_rosetree$Tree$label(previewTree))));
	});
var $author$project$Page$NotFound$init = function (session) {
	return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
};
var $author$project$Page$Order$CompletedLoadOrder = function (a) {
	return {$: 2, a: a};
};
var $author$project$Order$Full = $elm$core$Basics$identity;
var $author$project$Order$Body = F6(
	function (salesPerson, termsOfDelivery, modeOfTransport, rows, packages, invoices) {
		return {hE: invoices, hR: modeOfTransport, ib: packages, cY: rows, iz: salesPerson, iQ: termsOfDelivery};
	});
var $author$project$OrderRow$Regular = function (a) {
	return {$: 1, a: a};
};
var $author$project$OrderRow$Internals = $elm$core$Basics$identity;
var $author$project$OrderRow$InternalsRecord = function (productId) {
	return function (productSlug) {
		return function (productImage) {
			return function (externalId) {
				return function (name) {
					return function (orderedQuantity) {
						return function (deliveredQuantity) {
							return function (backorderedQuantity) {
								return function (basePrice) {
									return function (price) {
										return function (chemicalTax) {
											return function (vatAmount) {
												return function (unit) {
													return function (serialNumber) {
														return function (deliveryDate) {
															return function (source) {
																return {ew: backorderedQuantity, ex: basePrice, dq: chemicalTax, eR: deliveredQuantity, eS: deliveryDate, hh: externalId, hT: name, fG: orderedQuantity, cR: price, cT: productId, fS: productImage, fT: productSlug, f8: serialNumber, ge: source, em: unit, gB: vatAmount};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$OrderRow$NoSource = {$: 0};
var $author$project$OrderRow$BidSource = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$OrderRow$bidRowDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$OrderRow$BidSource,
	A2($elm$json$Json$Decode$field, 'bid_id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'bid_external_id', $author$project$ExternalId$decoder));
var $author$project$OrderRow$sourceDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$author$project$OrderRow$bidRowDecoder,
			$elm$json$Json$Decode$succeed($author$project$OrderRow$NoSource)
		]));
var $author$project$OrderRow$internalsRecordDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$OrderRow$sourceDecoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'delivery_date',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Helpers$posixDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'serial_number',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'unit',
				$author$project$Unit$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'vat_amount',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'chemical_tax',
						$elm$json$Json$Decode$float,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'price',
							$elm$json$Json$Decode$float,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'base_price',
								$elm$json$Json$Decode$float,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'backordered_quantity',
									$elm$json$Json$Decode$float,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'delivered_quantity',
										$elm$json$Json$Decode$float,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'ordered_quantity',
											$elm$json$Json$Decode$float,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'name',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'product_code',
													$author$project$ExternalId$decoder,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'product_image',
														$author$project$Image$decoder,
														$elm$core$Maybe$Nothing,
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'product_slug',
															A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Slug$decoder),
															$elm$core$Maybe$Nothing,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'product_id',
																A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Id$decoder),
																$elm$core$Maybe$Nothing,
																$elm$json$Json$Decode$succeed($author$project$OrderRow$InternalsRecord)))))))))))))))));
var $author$project$OrderRow$internalsDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$OrderRow$internalsRecordDecoder);
var $author$project$OrderRow$regularDecoder = A2($elm$json$Json$Decode$map, $author$project$OrderRow$Regular, $author$project$OrderRow$internalsDecoder);
var $author$project$OrderRow$Text = function (a) {
	return {$: 0, a: a};
};
var $author$project$OrderRow$textDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$OrderRow$Text,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $author$project$OrderRow$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$OrderRow$regularDecoder, $author$project$OrderRow$textDecoder]));
var $author$project$Package$Package = $elm$core$Basics$identity;
var $author$project$Package$Internals = F2(
	function (number, forwarder) {
		return {cx: forwarder, fD: number};
	});
var $author$project$Package$Forwarder = F3(
	function (name, short_name, tracking_url) {
		return {hT: name, f9: short_name, gs: tracking_url};
	});
var $author$project$Package$forwarderDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Package$Forwarder,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'short_name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'tracking_url', $elm$json$Json$Decode$string));
var $author$project$Package$internalsDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Package$Internals,
	A2($elm$json$Json$Decode$field, 'number', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'forwarder', $author$project$Package$forwarderDecoder)));
var $author$project$Package$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Package$internalsDecoder);
var $author$project$Order$invoiceDecoder = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (id, name, url) {
			return {hx: id, hT: name, cg: url};
		}),
	A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string));
var $author$project$Order$bodyDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'invoices',
	$elm$json$Json$Decode$list($author$project$Order$invoiceDecoder),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'packages',
		$elm$json$Json$Decode$list($author$project$Package$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'rows',
			$elm$json$Json$Decode$list($author$project$OrderRow$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'mode_of_transport',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'terms_of_delivery',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'sales_person',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Order$Body)))))));
var $author$project$Order$fullDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Order$decoderHelper(
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Order$bodyDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'external_id',
		$elm$json$Json$Decode$maybe($author$project$ExternalId$decoder)));
var $author$project$Api$Endpoint$order = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'orders',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id))
			]),
		_List_Nil);
};
var $author$project$Order$fetch = F3(
	function (msg, _v0, orderId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$order(orderId),
			$author$project$Order$fullDecoder,
			msg);
	});
var $author$project$Page$Order$init = F2(
	function (session, id) {
		return _Utils_Tuple2(
			{h9: $author$project$Status$Loading, K: session},
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (userId) {
						return A3($author$project$Order$fetch, $author$project$Page$Order$CompletedLoadOrder, userId, id);
					},
					$author$project$Session$userId(session))));
	});
var $author$project$Page$Orders$CompletedLoadOrders = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Orders$loadOrders = $author$project$Order$list($author$project$Page$Orders$CompletedLoadOrders);
var $author$project$Page$Orders$init = F2(
	function (session, queryParams) {
		var q = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				$elm$core$String$join(', '),
				A2($elm$core$Dict$get, 'q', queryParams)));
		var page = A2(
			$elm$core$Maybe$withDefault,
			1,
			A2($author$project$QueryParams$getInt, 'page', queryParams));
		var defaultScope = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.c0;
					},
					function ($) {
						return $.ha;
					}),
				$author$project$Session$user(session)));
		var params = _Utils_update(
			$author$project$Document$defaultQueryParams,
			{
				dW: page,
				d0: q,
				c$: A2($author$project$Document$Scope$fromQueryParams, defaultScope, queryParams)
			});
		var newModel = {R: $author$project$Status$Loading, E: params, K: session};
		return _Utils_Tuple2(
			newModel,
			$author$project$Page$Orders$loadOrders(params));
	});
var $author$project$Page$Product$CompletedLoadBargains = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Product$CompletedLoadBids = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Product$CompletedLoadProduct = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Product$CompletedLoadRelated = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Product$CompletedLoadVariants = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Product$None = 0;
var $author$project$QueryParams$getFloat = $author$project$QueryParams$get($elm$core$String$toFloat);
var $author$project$Page$Product$amountFromQueryParams = function (queryParams) {
	return A2(
		$elm$core$Maybe$withDefault,
		1,
		A2(
			$elm$core$Maybe$map,
			function (amount_) {
				return (amount_ > 0) ? amount_ : 1;
			},
			A2($author$project$QueryParams$getFloat, 'amount', queryParams)));
};
var $author$project$Api$Endpoint$product = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'products',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id))
			]),
		_List_Nil);
};
var $author$project$Product$fetch = F3(
	function (msg, maybeUserId, productId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$product(productId),
			$author$project$Product$decoder(maybeUserId),
			msg);
	});
var $author$project$Product$BargainData = F3(
	function (id, slug, price) {
		return {hx: id, cR: price, gb: slug};
	});
var $author$project$Product$bargainDataDecoder = function (_v0) {
	return A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Price$dataDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'slug',
			$author$project$Slug$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$Id$decoder,
				$elm$json$Json$Decode$succeed($author$project$Product$BargainData))));
};
var $author$project$Api$Endpoint$productBargains = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'products',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id)),
				'bargains'
			]),
		_List_Nil);
};
var $author$project$Product$fetchBargains = F3(
	function (msg, userId, productId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productBargains(productId),
			$elm$json$Json$Decode$list(
				$author$project$Product$bargainDataDecoder(userId)),
			msg);
	});
var $author$project$BidRow$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$BidRow$BidRow,
	$author$project$BidRow$internalsDecoder,
	$elm$json$Json$Decode$succeed(0));
var $author$project$Bid$fullDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	A2(
		$elm$json$Json$Decode$field,
		'rows',
		$elm$json$Json$Decode$list($author$project$BidRow$decoder)));
var $author$project$Api$Endpoint$productBids = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'products',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id)),
				'bids'
			]),
		_List_Nil);
};
var $author$project$Bid$fetchFromProduct = F3(
	function (msg, userId, productId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productBids(productId),
			$elm$json$Json$Decode$list(
				$author$project$Bid$decoder($author$project$Bid$fullDecoder)),
			msg);
	});
var $author$project$Api$Endpoint$productRelated = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'products',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id)),
				'related'
			]),
		_List_Nil);
};
var $author$project$Product$fetchRelated = F3(
	function (msg, maybeUserId, productId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productRelated(productId),
			$elm$json$Json$Decode$list(
				$author$project$Product$decoder(maybeUserId)),
			msg);
	});
var $author$project$Variant$VariantTerms = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Variant$VariantTermData = F3(
	function (label, id, slug) {
		return {hx: id, cB: label, gb: slug};
	});
var $author$project$Variant$termDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Variant$VariantTermData,
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'slug', $author$project$Slug$decoder));
var $author$project$Variant$VariantData = F3(
	function (label, prefix, suffix) {
		return {cB: label, dZ: prefix, ec: suffix};
	});
var $author$project$Variant$variantDataDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Variant$VariantData,
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'prefix', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'suffix', $elm$json$Json$Decode$string));
var $author$project$Variant$decoder = A2(
	$elm$json$Json$Decode$field,
	'variants',
	$elm$json$Json$Decode$list(
		A3(
			$elm$json$Json$Decode$map2,
			$author$project$Variant$VariantTerms,
			$author$project$Variant$variantDataDecoder,
			A2(
				$elm$json$Json$Decode$field,
				'terms',
				$elm$json$Json$Decode$list($author$project$Variant$termDecoder)))));
var $author$project$Api$Endpoint$productVariants = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'products',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id)),
				'variants'
			]),
		_List_Nil);
};
var $author$project$Variant$list = F2(
	function (msg, productId) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productVariants(productId),
			$author$project$Variant$decoder,
			msg);
	});
var $author$project$Page$Product$init = F3(
	function (session, queryParams, _v0) {
		var id = _v0.a;
		var slug = _v0.b;
		return _Utils_Tuple2(
			{
				aj: 0,
				cj: $author$project$Page$Product$amountFromQueryParams(queryParams),
				aE: $author$project$Status$Loading,
				aG: _List_Nil,
				aV: $author$project$Status$Loading,
				hx: id,
				cH: 0,
				b2: $author$project$Status$Loading,
				a2: $author$project$Status$Loading,
				K: session,
				gb: slug,
				aA: $author$project$Status$Loading
			},
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					A3(
						$author$project$Product$fetch,
						$author$project$Page$Product$CompletedLoadProduct,
						$author$project$Session$userId(session),
						id),
					A2(
						$elm$core$List$cons,
						A2($author$project$Variant$list, $author$project$Page$Product$CompletedLoadVariants, id),
						A2(
							$elm$core$List$cons,
							A3(
								$author$project$Product$fetchRelated,
								$author$project$Page$Product$CompletedLoadRelated,
								$author$project$Session$userId(session),
								id),
							function () {
								var _v1 = $author$project$Session$userId(session);
								if (!_v1.$) {
									var userId = _v1.a;
									return _List_fromArray(
										[
											A3($author$project$Product$fetchBargains, $author$project$Page$Product$CompletedLoadBargains, userId, id),
											A3($author$project$Bid$fetchFromProduct, $author$project$Page$Product$CompletedLoadBids, userId, id)
										]);
								} else {
									return _List_Nil;
								}
							}())))));
	});
var $author$project$Page$RequestNewPassword$NotValidated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$RequestNewPassword$emptyInputs = {be: ''};
var $author$project$Page$RequestNewPassword$init = function (session) {
	var cmd = function () {
		var _v0 = $author$project$Session$user(session);
		if (_v0.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			return $author$project$Route$load($author$project$Route$Home);
		}
	}();
	return _Utils_Tuple2(
		{
			fx: '',
			K: session,
			gh: $author$project$Page$RequestNewPassword$NotValidated($author$project$Page$RequestNewPassword$emptyInputs)
		},
		cmd);
};
var $author$project$Page$ResetPassword$NotValidated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ResetPassword$emptyInputs = {be: '', an: '', ao: ''};
var $author$project$Page$ResetPassword$init = F3(
	function (session, queryParams, token) {
		var email = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$List$head,
				A2($elm$core$Dict$get, 'email', queryParams)));
		var cmd = function () {
			var _v0 = $author$project$Session$user(session);
			if (_v0.$ === 1) {
				return $elm$core$Platform$Cmd$none;
			} else {
				return $author$project$Route$load($author$project$Route$Home);
			}
		}();
		return _Utils_Tuple2(
			{
				fx: '',
				K: session,
				gh: $author$project$Page$ResetPassword$NotValidated(
					_Utils_update(
						$author$project$Page$ResetPassword$emptyInputs,
						{be: email})),
				cb: token
			},
			cmd);
	});
var $author$project$Page$Service$CompletedLoadServices = function (a) {
	return {$: 2, a: a};
};
var $author$project$Service$Service = F2(
	function (title, slug) {
		return {gb: slug, gr: title};
	});
var $author$project$Service$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Service$Service,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string));
var $author$project$Api$Endpoint$service = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['service']),
	_List_Nil);
var $author$project$Service$fetch = function (msg) {
	return A3(
		$author$project$Api$get,
		$author$project$Api$Endpoint$service,
		$elm$json$Json$Decode$list($author$project$Service$decoder),
		msg);
};
var $author$project$Page$Service$init = function (session) {
	return _Utils_Tuple2(
		{K: session, gh: $author$project$Status$Loading},
		$author$project$Service$fetch($author$project$Page$Service$CompletedLoadServices));
};
var $author$project$Page$ThankYou$init = F2(
	function (session, id) {
		return _Utils_Tuple2(
			{cN: id, K: session},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Products$BrandMode = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Products$CompletedLoadBrand = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Products$Empty = {$: 0};
var $author$project$Page$Products$defaultLimit = 60;
var $author$project$Category$emptyFilterResults = $elm$core$Dict$empty;
var $author$project$Api$Endpoint$brand = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'brands',
				$author$project$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Brand$fetch = F2(
	function (msg, s) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$brand(s),
			$author$project$Brand$decoder,
			msg);
	});
var $author$project$Filter$CatSelection = function (a) {
	return {$: 2, a: a};
};
var $author$project$Filter$StrSelection = function (a) {
	return {$: 0, a: a};
};
var $author$project$Filter$StringSelection = $elm$core$Basics$identity;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Id$fromInt = $elm$core$Basics$identity;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $elm$parser$Parser$ExpectingFloat = {$: 5};
var $elm$parser$Parser$Advanced$float = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				ey: $elm$core$Result$Err(invalid),
				e$: expecting,
				e2: $elm$core$Result$Ok($elm$core$Basics$identity),
				e8: $elm$core$Result$Err(invalid),
				fi: $elm$core$Result$Ok($elm$core$Basics$toFloat),
				hC: invalid,
				fE: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$float = A2($elm$parser$Parser$Advanced$float, $elm$parser$Parser$ExpectingFloat, $elm$parser$Parser$ExpectingFloat);
var $author$project$Filter$NumSelection = function (a) {
	return {$: 1, a: a};
};
var $author$project$Filter$NumberSelection = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Filter$numParserHelper = function (_v0) {
	var min = _v0.a;
	var max = _v0.b;
	return $elm$parser$Parser$succeed(
		$author$project$Filter$NumSelection(
			A2($author$project$Filter$NumberSelection, min, max)));
};
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $author$project$Filter$numParser = A2(
	$elm$parser$Parser$andThen,
	$author$project$Filter$numParserHelper,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$float,
				$elm$parser$Parser$symbol('-'))),
		$elm$parser$Parser$float));
var $author$project$Filter$fromRawDict = function (queryParams) {
	var ignoreParameters = _List_fromArray(
		['orderBy', 'sort', 'offset', 'limit', 'q']);
	return A2(
		$elm$core$Dict$map,
		F2(
			function (key, values) {
				var _v0 = _Utils_Tuple2(key, values);
				if (!_v0.b.b) {
					return $author$project$Filter$StrSelection($elm$core$Set$empty);
				} else {
					if (!_v0.b.b.b) {
						if (_v0.a === 'categories') {
							var _v1 = _v0.b;
							var stringCatId = _v1.a;
							var _v2 = $elm$core$String$toInt(stringCatId);
							if (!_v2.$) {
								var catId = _v2.a;
								return $author$project$Filter$CatSelection(
									$author$project$Id$fromInt(catId));
							} else {
								return $author$project$Filter$StrSelection($elm$core$Set$empty);
							}
						} else {
							var _v3 = _v0.b;
							var singleValue = _v3.a;
							var _v4 = A2($elm$parser$Parser$run, $author$project$Filter$numParser, singleValue);
							if (!_v4.$) {
								var sel = _v4.a;
								return sel;
							} else {
								return $author$project$Filter$StrSelection(
									$elm$core$Set$fromList(values));
							}
						}
					} else {
						return $author$project$Filter$StrSelection(
							$elm$core$Set$fromList(values));
					}
				}
			}),
		A2(
			$elm$core$Dict$filter,
			F2(
				function (key, values) {
					return (!A2($elm$core$List$member, key, ignoreParameters)) && (!$elm$core$List$isEmpty(values));
				}),
			queryParams));
};
var $author$project$Page$Products$CompletedLoadProducts = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Page$Products$searchQuery = function (sts) {
	if (!sts.$) {
		var str = sts.a;
		return str;
	} else {
		var str = sts.a;
		return str;
	}
};
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $elm$core$Set$singleton = function (key) {
	return A2($elm$core$Dict$singleton, key, 0);
};
var $author$project$Filter$setBrand = F2(
	function (slug_, _v0) {
		var dict = _v0;
		return A3(
			$elm$core$Dict$update,
			'brand',
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(
					$author$project$Filter$StrSelection(
						$elm$core$Set$singleton(slug_)))),
			dict);
	});
var $author$project$Filter$setCategory = F2(
	function (id, _v0) {
		var dict = _v0;
		return A3(
			$elm$core$Dict$update,
			'categories',
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(
					$author$project$Filter$CatSelection(id))),
			dict);
	});
var $author$project$Sticker$toFilterValue = function (sticker) {
	switch (sticker) {
		case 0:
			return 'new';
		case 1:
			return 'bargain';
		default:
			return 'campaign';
	}
};
var $author$project$Filter$setSticker = F2(
	function (sticker, _v0) {
		var dict = _v0;
		var slug_ = $author$project$Sticker$toFilterValue(sticker);
		return A3(
			$elm$core$Dict$update,
			'stickers',
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(
					$author$project$Filter$StrSelection(
						$elm$core$Set$singleton(slug_)))),
			dict);
	});
var $author$project$Page$Products$loadProducts = F3(
	function (_v0, aggregations, model) {
		var limit = _v0.hL;
		var offset = _v0.h4;
		var _v1 = function () {
			var _v2 = model.f;
			switch (_v2.$) {
				case 0:
					var cat = _v2.a;
					return _Utils_Tuple2(
						'',
						A2(
							$author$project$Filter$setCategory,
							$author$project$Category$id(
								$zwilias$elm_rosetree$Tree$Zipper$label(cat)),
							model.iD));
				case 3:
					var sticker = _v2.b;
					return _Utils_Tuple2(
						'',
						A2($author$project$Filter$setSticker, sticker, model.iD));
				case 2:
					var _v3 = _v2.a;
					var slug = _v3.a;
					return _Utils_Tuple2(
						'',
						A2(
							$author$project$Filter$setBrand,
							$author$project$Slug$toString(slug),
							model.iD));
				default:
					var q_ = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Products$searchQuery(q_),
						model.iD);
			}
		}();
		var q = _v1.a;
		var selections = _v1.b;
		return A3(
			$author$project$Product$list,
			A2($author$project$Page$Products$CompletedLoadProducts, offset, aggregations),
			$author$project$Session$userId(model.K),
			{gJ: aggregations, hL: limit, h4: offset, ia: model.ia, d0: q, iD: selections});
	});
var $author$project$Page$Products$offsetFromQueryParams = F2(
	function (_default, queryParams) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2($author$project$QueryParams$getInt, 'offset', queryParams));
	});
var $author$project$Session$orderBy = function (session) {
	if (!session.$) {
		var user_ = session.b;
		return user_.c0.ia;
	} else {
		var ui = session.b;
		return ui.ia;
	}
};
var $author$project$OrderBy$Asc = 0;
var $author$project$OrderBy$BasePrice = function (a) {
	return {$: 3, a: a};
};
var $author$project$OrderBy$Desc = 1;
var $author$project$OrderBy$Name = function (a) {
	return {$: 2, a: a};
};
var $author$project$OrderBy$ProductCode = function (a) {
	return {$: 1, a: a};
};
var $author$project$OrderBy$Relevance = {$: 4};
var $author$project$OrderBy$fromString = function (s) {
	switch (s) {
		case 'recent':
			return $elm$core$Maybe$Just($author$project$OrderBy$Recent);
		case 'product_code:asc':
			return $elm$core$Maybe$Just(
				$author$project$OrderBy$ProductCode(0));
		case 'product_code:desc':
			return $elm$core$Maybe$Just(
				$author$project$OrderBy$ProductCode(1));
		case 'name:asc':
			return $elm$core$Maybe$Just(
				$author$project$OrderBy$Name(0));
		case 'name:desc':
			return $elm$core$Maybe$Just(
				$author$project$OrderBy$Name(1));
		case 'base_price:asc':
			return $elm$core$Maybe$Just(
				$author$project$OrderBy$BasePrice(0));
		case 'base_price:desc':
			return $elm$core$Maybe$Just(
				$author$project$OrderBy$BasePrice(1));
		case 'relevance':
			return $elm$core$Maybe$Just($author$project$OrderBy$Relevance);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Products$orderByFromQueryParams = F2(
	function (_default, queryParams) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3($author$project$QueryParams$get, $author$project$OrderBy$fromString, 'orderBy', queryParams));
	});
var $author$project$Page$Products$View$Loading = {$: 0};
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Page$Products$setLoading = F2(
	function (_v0, items) {
		var offset = _v0.h4;
		var limit = _v0.hL;
		return A2(
			$elm$core$Dict$union,
			items,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (a) {
						return _Utils_Tuple2(a, $author$project$Page$Products$View$Loading);
					},
					A2($elm$core$List$range, offset, (offset + limit) - 1))));
	});
var $author$project$Page$Products$initBrandMode = F3(
	function (session, queryParams, slug) {
		var offset = A2($author$project$Page$Products$offsetFromQueryParams, 0, queryParams);
		var limitWithOffset = $author$project$Page$Products$defaultLimit + offset;
		var newModel = {
			gV: $author$project$Category$emptyFilterResults,
			eT: $author$project$Page$Products$Empty,
			aH: $elm$core$Maybe$Nothing,
			aI: false,
			J: _List_Nil,
			hF: A2(
				$author$project$Page$Products$setLoading,
				{hL: limitWithOffset, h4: 0},
				$elm$core$Dict$empty),
			hL: $author$project$Page$Products$defaultLimit,
			f: $author$project$Page$Products$BrandMode(
				_Utils_Tuple2(slug, $elm$core$Maybe$Nothing)),
			h4: 0,
			ia: A2(
				$author$project$Page$Products$orderByFromQueryParams,
				$author$project$Session$orderBy(session),
				queryParams),
			iD: A2(
				$author$project$Filter$setBrand,
				$author$project$Slug$toString(slug),
				$author$project$Filter$fromRawDict(queryParams)),
			K: session,
			aq: _List_Nil,
			F: $author$project$Page$Products$defaultLimit
		};
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($author$project$Brand$fetch, $author$project$Page$Products$CompletedLoadBrand, slug),
						A3(
						$author$project$Page$Products$loadProducts,
						{hL: limitWithOffset, h4: 0},
						true,
						newModel)
					])));
	});
var $author$project$Page$Products$CategoryMode = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Products$CompletedLoadCategory = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Endpoint$category = F2(
	function (id, fields) {
		var queryParams = function () {
			if (!fields.b) {
				return _List_Nil;
			} else {
				var fields_ = fields;
				return _List_fromArray(
					[
						A2(
						$elm$url$Url$Builder$string,
						'fields',
						A2($elm$core$String$join, ',', fields_))
					]);
			}
		}();
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'categories',
					$elm$core$String$fromInt(
					$author$project$Id$toInt(id))
				]),
			queryParams);
	});
var $author$project$Category$fetch = F3(
	function (msg, catId, fields) {
		return A3(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$category, catId, fields),
			$author$project$Category$fullDecoder,
			msg);
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $author$project$Category$productCount = function (_v0) {
	var internals = _v0.a;
	return internals.cS;
};
var $author$project$Page$Products$initCategoryMode = F3(
	function (session, queryParams, categoryZipper) {
		var offset = A2($author$project$Page$Products$offsetFromQueryParams, 0, queryParams);
		var category = $zwilias$elm_rosetree$Tree$Zipper$label(categoryZipper);
		var total = $author$project$Category$productCount(category);
		var limitWithOffset = A2($elm$core$Basics$min, total, $author$project$Page$Products$defaultLimit + offset);
		var newModel = {
			gV: $author$project$Category$emptyFilterResults,
			eT: $author$project$Page$Products$Empty,
			aH: $elm$core$Maybe$Nothing,
			aI: false,
			J: _List_Nil,
			hF: A2(
				$author$project$Page$Products$setLoading,
				{hL: limitWithOffset, h4: 0},
				$elm$core$Dict$empty),
			hL: $author$project$Page$Products$defaultLimit,
			f: $author$project$Page$Products$CategoryMode(categoryZipper),
			h4: offset,
			ia: A2(
				$author$project$Page$Products$orderByFromQueryParams,
				$author$project$Session$orderBy(session),
				queryParams),
			iD: A2(
				$author$project$Filter$setCategory,
				$author$project$Category$id(category),
				$author$project$Filter$fromRawDict(queryParams)),
			K: session,
			aq: _List_Nil,
			F: total
		};
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Category$fetch,
						$author$project$Page$Products$CompletedLoadCategory,
						$author$project$Category$id(category),
						_List_fromArray(
							['image'])),
						A3(
						$author$project$Page$Products$loadProducts,
						{hL: limitWithOffset, h4: 0},
						true,
						newModel)
					])));
	});
var $author$project$Page$Product$initReload = F4(
	function (model, session, queryParams, _v0) {
		var id = _v0.a;
		var slug = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					aj: 0,
					cj: $author$project$Page$Product$amountFromQueryParams(queryParams),
					aG: _List_Nil,
					hx: id,
					cH: 0,
					K: session,
					gb: slug
				}),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					A3(
						$author$project$Product$fetch,
						$author$project$Page$Product$CompletedLoadProduct,
						$author$project$Session$userId(session),
						id),
					A2(
						$elm$core$List$cons,
						A2($author$project$Variant$list, $author$project$Page$Product$CompletedLoadVariants, id),
						A2(
							$elm$core$List$cons,
							A3(
								$author$project$Product$fetchRelated,
								$author$project$Page$Product$CompletedLoadRelated,
								$author$project$Session$userId(session),
								id),
							function () {
								var _v1 = $author$project$Session$userId(session);
								if (!_v1.$) {
									var userId = _v1.a;
									return _List_fromArray(
										[
											A3($author$project$Product$fetchBargains, $author$project$Page$Product$CompletedLoadBargains, userId, id),
											A3($author$project$Bid$fetchFromProduct, $author$project$Page$Product$CompletedLoadBids, userId, id)
										]);
								} else {
									return _List_Nil;
								}
							}())))));
	});
var $author$project$Page$Products$SearchMode = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Products$Searching = function (a) {
	return {$: 0, a: a};
};
var $author$project$Session$setQuery = function (q) {
	return $author$project$Session$mapUI(
		function (ui) {
			return _Utils_update(
				ui,
				{
					dk: $specialelektronik$elm_autocomplete$Autocomplete$init(q)
				});
		});
};
var $author$project$Page$Products$initSearchMode = F2(
	function (session, queryParams) {
		var q = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				$elm$core$String$join(', '),
				A2($elm$core$Dict$get, 'q', queryParams)));
		var offset = A2($author$project$Page$Products$offsetFromQueryParams, 0, queryParams);
		var limitWithOffset = $author$project$Page$Products$defaultLimit + offset;
		var newModel = {
			gV: $author$project$Category$emptyFilterResults,
			eT: $author$project$Page$Products$Empty,
			aH: $elm$core$Maybe$Nothing,
			aI: false,
			J: _List_Nil,
			hF: A2(
				$author$project$Page$Products$setLoading,
				{hL: limitWithOffset, h4: 0},
				$elm$core$Dict$empty),
			hL: $author$project$Page$Products$defaultLimit,
			f: $author$project$Page$Products$SearchMode(
				$author$project$Page$Products$Searching(q)),
			h4: 0,
			ia: A2($author$project$Page$Products$orderByFromQueryParams, $author$project$OrderBy$Relevance, queryParams),
			iD: $author$project$Filter$fromRawDict(queryParams),
			K: A2($author$project$Session$setQuery, q, session),
			aq: _List_Nil,
			F: $author$project$Page$Products$defaultLimit
		};
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Page$Products$loadProducts,
						{hL: limitWithOffset, h4: 0},
						true,
						newModel)
					])));
	});
var $author$project$Page$Products$StickerMode = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Filter$category = function (_v0) {
	var dict = _v0;
	return A2(
		$elm$core$Maybe$andThen,
		function (sel) {
			if (sel.$ === 2) {
				var id = sel.a;
				return $elm$core$Maybe$Just(id);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		},
		A2($elm$core$Dict$get, 'categories', dict));
};
var $author$project$Page$Products$initStickerMode = F4(
	function (session, queryParams, categoryZipper, sticker) {
		var selections = function (sels) {
			var _v0 = $author$project$Filter$category(sels);
			if (!_v0.$) {
				return sels;
			} else {
				return A2(
					$author$project$Filter$setCategory,
					$author$project$Category$id(
						$zwilias$elm_rosetree$Tree$Zipper$label(categoryZipper)),
					sels);
			}
		}(
			A2(
				$author$project$Filter$setSticker,
				sticker,
				$author$project$Filter$fromRawDict(queryParams)));
		var offset = A2($author$project$Page$Products$offsetFromQueryParams, 0, queryParams);
		var limitWithOffset = $author$project$Page$Products$defaultLimit + offset;
		var newModel = {
			gV: $author$project$Category$emptyFilterResults,
			eT: $author$project$Page$Products$Empty,
			aH: $elm$core$Maybe$Nothing,
			aI: false,
			J: _List_Nil,
			hF: A2(
				$author$project$Page$Products$setLoading,
				{hL: limitWithOffset, h4: 0},
				$elm$core$Dict$empty),
			hL: $author$project$Page$Products$defaultLimit,
			f: A2($author$project$Page$Products$StickerMode, categoryZipper, sticker),
			h4: offset,
			ia: A2(
				$author$project$Page$Products$orderByFromQueryParams,
				$author$project$Session$orderBy(session),
				queryParams),
			iD: selections,
			K: session,
			aq: _List_Nil,
			F: $author$project$Page$Products$defaultLimit
		};
		return _Utils_Tuple2(
			newModel,
			A3(
				$author$project$Page$Products$loadProducts,
				{hL: limitWithOffset, h4: 0},
				true,
				newModel));
	});
var $author$project$Page$Products$isSameBrand = F2(
	function (newSlug, model) {
		var _v0 = model.f;
		if (_v0.$ === 2) {
			var _v1 = _v0.a;
			var oldSlug = _v1.a;
			return _Utils_eq(
				$author$project$Slug$toString(newSlug),
				$author$project$Slug$toString(oldSlug));
		} else {
			return false;
		}
	});
var $author$project$Page$Products$isSameCategory = F2(
	function (zipper, model) {
		var _v0 = model.f;
		if (!_v0.$) {
			var cat = _v0.a;
			return _Utils_eq(
				$author$project$Category$id(
					$zwilias$elm_rosetree$Tree$Zipper$label(cat)),
				$author$project$Category$id(
					$zwilias$elm_rosetree$Tree$Zipper$label(zipper)));
		} else {
			return false;
		}
	});
var $author$project$Page$Products$isSameSearch = F2(
	function (newQuery, model) {
		var _v0 = model.f;
		if (_v0.$ === 1) {
			var oldQuery = _v0.a;
			return _Utils_eq(
				newQuery,
				$author$project$Page$Products$searchQuery(oldQuery));
		} else {
			return false;
		}
	});
var $author$project$Page$Products$isSameSticker = F3(
	function (zipper, newSticker, model) {
		var _v0 = model.f;
		if (_v0.$ === 3) {
			var cat = _v0.a;
			var oldSticker = _v0.b;
			return _Utils_eq(
				$author$project$Category$id(
					$zwilias$elm_rosetree$Tree$Zipper$label(cat)),
				$author$project$Category$id(
					$zwilias$elm_rosetree$Tree$Zipper$label(zipper))) && _Utils_eq(newSticker, oldSticker);
		} else {
			return false;
		}
	});
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Category$slug = function (_v0) {
	var internals = _v0.a;
	return internals.gb;
};
var $author$project$Route$Account = {$: 19};
var $author$project$Route$AccountAdministration = {$: 23};
var $author$project$Route$AccountPassword = {$: 22};
var $author$project$Route$AccountProfile = {$: 20};
var $author$project$Route$AccountSettings = {$: 21};
var $author$project$Route$Bids = {$: 25};
var $author$project$Route$Brands = {$: 8};
var $author$project$Route$Checkout = {$: 12};
var $author$project$Route$Login = {$: 16};
var $author$project$Route$RequestNewPassword = {$: 17};
var $author$project$Route$Service = {$: 3};
var $author$project$Route$Bid = function (a) {
	return {$: 26, a: a};
};
var $author$project$Bid$id = function (_v0) {
	var internals = _v0.a;
	return internals.hx;
};
var $author$project$Page$Bid$toRoute = function (model) {
	var _v0 = model.aF;
	if (_v0.$ === 2) {
		var bid = _v0.a;
		return $author$project$Route$Bid(
			$author$project$Bid$id(bid));
	} else {
		return $author$project$Route$Home;
	}
};
var $author$project$Page$Content$toRoute = function (_v0) {
	var route = _v0.d4;
	return route;
};
var $author$project$Route$Invoices = function (a) {
	return {$: 24, a: a};
};
var $author$project$Document$toRouteQueryParameters = function (params) {
	var q = $elm$core$String$trim(params.d0);
	var page = A2($elm$core$Basics$max, 1, params.dW);
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var k = _v0.a;
				var v = _v0.b;
				return (v !== '') ? $elm$core$Maybe$Just(
					_Utils_Tuple2(
						k,
						_List_fromArray(
							[v]))) : $elm$core$Maybe$Nothing;
			},
			_List_fromArray(
				[
					_Utils_Tuple2('q', q),
					_Utils_Tuple2(
					'page',
					(page === 1) ? '' : $elm$core$String$fromInt(page)),
					_Utils_Tuple2(
					'scope',
					(q !== '') ? '' : $author$project$Document$Scope$toString(params.c$))
				])));
};
var $author$project$Page$Invoices$toRoute = function (model) {
	return $author$project$Route$Invoices(
		$author$project$Document$toRouteQueryParameters(model.E));
};
var $author$project$Route$MainCategory = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$MainCategory$toRoute = function (model) {
	var _v0 = model.by;
	if (_v0.$ === 2) {
		var a = _v0.a;
		return $author$project$Route$MainCategory(
			$author$project$Category$slug(
				$zwilias$elm_rosetree$Tree$label(a)));
	} else {
		return $author$project$Route$Home;
	}
};
var $author$project$Route$Order = function (a) {
	return {$: 14, a: a};
};
var $author$project$Order$internals = function (order) {
	if (!order.$) {
		var internals_ = order.a;
		return internals_;
	} else {
		var internals_ = order.b;
		return internals_;
	}
};
var $author$project$Page$Order$toRoute = function (model) {
	var _v0 = model.h9;
	if (_v0.$ === 2) {
		var order = _v0.a;
		return $author$project$Route$Order(
			$author$project$Order$internals(order).hx);
	} else {
		return $author$project$Route$Home;
	}
};
var $author$project$Route$Orders = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Orders$toRoute = function (model) {
	return $author$project$Route$Orders(
		$author$project$Document$toRouteQueryParameters(model.E));
};
var $author$project$Route$Product = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Page$Product$toRoute = function (model) {
	var queryParams = (model.cj > 1) ? A2(
		$elm$core$Dict$singleton,
		'amount',
		_List_fromArray(
			[
				$elm$core$String$fromFloat(model.cj)
			])) : $author$project$QueryParams$empty;
	return A2(
		$author$project$Route$Product,
		queryParams,
		_Utils_Tuple2(model.hx, model.gb));
};
var $author$project$Route$Search = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Brand = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$Products$toBrandRoute = F2(
	function (queryParams, slug) {
		return A2(
			$author$project$Route$Brand,
			A3(
				$elm$core$Dict$update,
				'brand',
				$elm$core$Basics$always($elm$core$Maybe$Nothing),
				queryParams),
			slug);
	});
var $author$project$Route$Category = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $zwilias$elm_rosetree$Tree$Zipper$toTree = A2($elm$core$Basics$composeL, $zwilias$elm_rosetree$Tree$Zipper$tree, $zwilias$elm_rosetree$Tree$Zipper$root);
var $author$project$Page$Products$toCategoryRoute = F2(
	function (queryParams, category) {
		var mainSlug = $author$project$Category$slug(
			$zwilias$elm_rosetree$Tree$label(
				$zwilias$elm_rosetree$Tree$Zipper$toTree(category)));
		var label = $zwilias$elm_rosetree$Tree$Zipper$label(category);
		var slug = $author$project$Category$slug(label);
		var id = $author$project$Category$id(label);
		return A3(
			$author$project$Route$Category,
			A3(
				$elm$core$Dict$update,
				'categories',
				$elm$core$Basics$always($elm$core$Maybe$Nothing),
				queryParams),
			mainSlug,
			_Utils_Tuple2(id, slug));
	});
var $author$project$Page$Products$hasQueryParam = function (params) {
	return !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(''),
			params));
};
var $author$project$Filter$toRawDict = function (_v0) {
	var dict = _v0;
	return A2(
		$elm$core$Dict$map,
		F2(
			function (_v1, sel) {
				switch (sel.$) {
					case 0:
						var set = sel.a;
						return $elm$core$Set$toList(set);
					case 1:
						var _v3 = sel.a;
						var min = _v3.a;
						var max = _v3.b;
						return _List_fromArray(
							[
								$elm$core$String$fromFloat(min) + ('-' + $elm$core$String$fromFloat(max))
							]);
					default:
						var id = sel.a;
						return _List_fromArray(
							[
								$elm$core$String$fromInt(
								$author$project$Id$toInt(id))
							]);
				}
			}),
		dict);
};
var $author$project$Page$Products$toQueryParameters = function (params) {
	return A2(
		$elm$core$Dict$filter,
		F2(
			function (_v0, v) {
				return $author$project$Page$Products$hasQueryParam(v);
			}),
		A2(
			$elm$core$Dict$union,
			$elm$core$Dict$fromList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'orderBy',
						_List_fromArray(
							[
								$author$project$OrderBy$toString(params.ia)
							])),
						_Utils_Tuple2(
						'q',
						_List_fromArray(
							[params.d0])),
						_Utils_Tuple2(
						'offset',
						_List_fromArray(
							[
								$elm$core$String$fromInt(params.h4)
							]))
					])),
			$author$project$Filter$toRawDict(params.iD)));
};
var $author$project$Route$Sticker = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Products$toStickerRoute = F3(
	function (queryParams, mainCategory, sticker) {
		return A3(
			$author$project$Route$Sticker,
			A3(
				$elm$core$Dict$update,
				'stickers',
				$elm$core$Basics$always($elm$core$Maybe$Nothing),
				queryParams),
			$author$project$Category$slug(
				$zwilias$elm_rosetree$Tree$Zipper$label(mainCategory)),
			sticker);
	});
var $author$project$Page$Products$toRoute = function (model) {
	var queryParams = function (q) {
		return $author$project$Page$Products$toQueryParameters(
			{h4: model.h4, ia: model.ia, d0: q, iD: model.iD});
	};
	var _v0 = model.f;
	switch (_v0.$) {
		case 0:
			var cat = _v0.a;
			return A2(
				$author$project$Page$Products$toCategoryRoute,
				queryParams(''),
				cat);
		case 2:
			var _v1 = _v0.a;
			var slug = _v1.a;
			return A2(
				$author$project$Page$Products$toBrandRoute,
				queryParams(''),
				slug);
		case 3:
			var cat = _v0.a;
			var sticker = _v0.b;
			return A3(
				$author$project$Page$Products$toStickerRoute,
				queryParams(''),
				cat,
				sticker);
		default:
			var q = _v0.a;
			return $author$project$Route$Search(
				queryParams(
					$author$project$Page$Products$searchQuery(q)));
	}
};
var $author$project$Route$ThankYou = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$ThankYou$toRoute = function (_v0) {
	var orderId = _v0.cN;
	return $author$project$Route$ThankYou(orderId);
};
var $author$project$Main$toRoute = function (page) {
	switch (page.$) {
		case 0:
			return $author$project$Route$Home;
		case 1:
			return $author$project$Route$Home;
		case 2:
			return $author$project$Route$Home;
		case 3:
			return $author$project$Route$Home;
		case 21:
			var subModel = page.a;
			return $author$project$Page$Content$toRoute(subModel);
		case 11:
			return $author$project$Route$Login;
		case 12:
			return $author$project$Route$RequestNewPassword;
		case 13:
			return $author$project$Route$Home;
		case 16:
			return $author$project$Route$Account;
		case 17:
			return $author$project$Route$AccountProfile;
		case 18:
			return $author$project$Route$AccountSettings;
		case 19:
			return $author$project$Route$AccountPassword;
		case 20:
			return $author$project$Route$AccountAdministration;
		case 5:
			var subModel = page.a;
			return $author$project$Page$Products$toRoute(subModel);
		case 4:
			var subModel = page.a;
			return $author$project$Page$MainCategory$toRoute(subModel);
		case 6:
			var subModel = page.a;
			return $author$project$Page$Product$toRoute(subModel);
		case 7:
			return $author$project$Route$Checkout;
		case 8:
			var subModel = page.a;
			return $author$project$Page$Orders$toRoute(subModel);
		case 9:
			var subModel = page.a;
			return $author$project$Page$Order$toRoute(subModel);
		case 23:
			return $author$project$Route$Bids;
		case 24:
			var subModel = page.a;
			return $author$project$Page$Bid$toRoute(subModel);
		case 14:
			var subModel = page.a;
			return $author$project$Page$ThankYou$toRoute(subModel);
		case 10:
			var subModel = page.a;
			return $author$project$Page$Invoices$toRoute(subModel);
		case 15:
			return $author$project$Route$Brands;
		default:
			return $author$project$Route$Service;
	}
};
var $author$project$OpeningHours$Closed = {$: 1};
var $author$project$Layout$Gallery = 0;
var $author$project$SearchHistory$SearchHistory = $elm$core$Basics$identity;
var $author$project$SearchHistory$empty = _List_Nil;
var $author$project$CsrfToken$CsrfToken = $elm$core$Basics$identity;
var $author$project$CsrfToken$init = '';
var $author$project$Newsletter$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Newsletter$init = $author$project$Newsletter$Loaded(
	{
		be: '',
		a0: _List_fromArray(
			[
				_Utils_Tuple3(false, 'av', 'Jag vill ha nyheter om AV'),
				_Utils_Tuple3(false, 'catv', 'Jag vill ha nyheter om CATV')
			]),
		cg: ''
	});
var $author$project$SE$UI$Snackbar$init = {ae: $elm$core$Dict$empty, de: 0};
var $author$project$Session$defaultUI = {
	dk: $specialelektronik$elm_autocomplete$Autocomplete$init(''),
	gV: _List_Nil,
	eO: _List_Nil,
	g0: $author$project$CsrfToken$init,
	hd: _List_Nil,
	hK: 0,
	bX: $author$project$SE$UI$Navbar$V2$defaultModel,
	fB: $author$project$Newsletter$init,
	h8: $author$project$OpeningHours$Closed,
	ia: $author$project$OrderBy$Recent,
	a3: $author$project$SearchHistory$empty,
	iG: $author$project$SE$UI$Snackbar$init,
	el: $elm$time$Time$millisToPosix(1613028675),
	gG: A2($elm$time$Time$customZone, 60, _List_Nil)
};
var $author$project$Session$empty = function (key) {
	return A2($author$project$Session$Guest, key, $author$project$Session$defaultUI);
};
var $author$project$Page$Account$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Account$Administration$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Account$Password$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Account$Profile$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Account$Settings$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Bid$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Bids$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Brands$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Checkout$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Content$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Home$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Invoices$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Login$toSession = function (model) {
	return model.K;
};
var $author$project$Page$MainCategory$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Order$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Orders$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Product$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Products$toSession = function (model) {
	return model.K;
};
var $author$project$Page$RequestNewPassword$toSession = function (model) {
	return model.K;
};
var $author$project$Page$ResetPassword$toSession = function (model) {
	return model.K;
};
var $author$project$Page$Service$toSession = function (model) {
	return model.K;
};
var $author$project$Page$ThankYou$toSession = function (model) {
	return model.K;
};
var $author$project$Main$toSession = function (page) {
	switch (page.$) {
		case 0:
			var navKey = page.a;
			return $author$project$Session$empty(navKey);
		case 1:
			var session = page.a;
			return session;
		case 2:
			var session = page.a;
			return session;
		case 3:
			var subModel = page.a;
			return $author$project$Page$Home$toSession(subModel);
		case 21:
			var subModel = page.a;
			return $author$project$Page$Content$toSession(subModel);
		case 11:
			var subModel = page.a;
			return $author$project$Page$Login$toSession(subModel);
		case 12:
			var subModel = page.a;
			return $author$project$Page$RequestNewPassword$toSession(subModel);
		case 13:
			var subModel = page.a;
			return $author$project$Page$ResetPassword$toSession(subModel);
		case 16:
			var subModel = page.a;
			return $author$project$Page$Account$toSession(subModel);
		case 17:
			var subModel = page.a;
			return $author$project$Page$Account$Profile$toSession(subModel);
		case 18:
			var subModel = page.a;
			return $author$project$Page$Account$Settings$toSession(subModel);
		case 19:
			var subModel = page.a;
			return $author$project$Page$Account$Password$toSession(subModel);
		case 20:
			var subModel = page.a;
			return $author$project$Page$Account$Administration$toSession(subModel);
		case 5:
			var subModel = page.a;
			return $author$project$Page$Products$toSession(subModel);
		case 4:
			var subModel = page.a;
			return $author$project$Page$MainCategory$toSession(subModel);
		case 6:
			var subModel = page.a;
			return $author$project$Page$Product$toSession(subModel);
		case 7:
			var subModel = page.a;
			return $author$project$Page$Checkout$toSession(subModel);
		case 8:
			var subModel = page.a;
			return $author$project$Page$Orders$toSession(subModel);
		case 9:
			var subModel = page.a;
			return $author$project$Page$Order$toSession(subModel);
		case 23:
			var subModel = page.a;
			return $author$project$Page$Bids$toSession(subModel);
		case 24:
			var subModel = page.a;
			return $author$project$Page$Bid$toSession(subModel);
		case 14:
			var subModel = page.a;
			return $author$project$Page$ThankYou$toSession(subModel);
		case 10:
			var subModel = page.a;
			return $author$project$Page$Invoices$toSession(subModel);
		case 15:
			var subModel = page.a;
			return $author$project$Page$Brands$toSession(subModel);
		default:
			var subModel = page.a;
			return $author$project$Page$Service$toSession(subModel);
	}
};
var $author$project$Page$Products$Searched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Products$updateQueryParams = F2(
	function (queryParams, model) {
		var rawSelections = $author$project$Filter$fromRawDict(queryParams);
		var newOffset = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				A2(
					$elm$core$Maybe$andThen,
					$elm$core$List$head,
					A2($elm$core$Dict$get, 'offset', queryParams))));
		var aggregrations = (!_Utils_eq(newOffset, model.h4)) ? false : true;
		var _v0 = function () {
			var _v1 = model.f;
			switch (_v1.$) {
				case 0:
					return _Utils_Tuple2(model.f, rawSelections);
				case 2:
					return _Utils_Tuple2(model.f, rawSelections);
				case 3:
					var categoryZipper = _v1.a;
					var sticker = _v1.b;
					return _Utils_Tuple2(
						model.f,
						function (sels) {
							var _v2 = $author$project$Filter$category(sels);
							if (!_v2.$) {
								return sels;
							} else {
								return A2(
									$author$project$Filter$setCategory,
									$author$project$Category$id(
										$zwilias$elm_rosetree$Tree$Zipper$label(categoryZipper)),
									sels);
							}
						}(
							A2($author$project$Filter$setSticker, sticker, rawSelections)));
				default:
					var oldQuery = _v1.a;
					return _Utils_Tuple2(
						$author$project$Page$Products$SearchMode(
							$author$project$Page$Products$Searched(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Page$Products$searchQuery(oldQuery),
									A2(
										$elm$core$Maybe$map,
										$elm$core$String$join(','),
										A2($elm$core$Dict$get, 'q', queryParams))))),
						rawSelections);
			}
		}();
		var newMode = _v0.a;
		var newSelections = _v0.b;
		var newModel = _Utils_update(
			model,
			{f: newMode, h4: newOffset, iD: newSelections});
		return _Utils_Tuple2(
			newModel,
			A3(
				$author$project$Page$Products$loadProducts,
				{hL: newModel.hL, h4: newOffset},
				aggregrations,
				newModel));
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$updateWith = F4(
	function (toModel, toMsg, cmds, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					A2($elm$core$Platform$Cmd$map, toMsg, subCmd),
					cmds)));
	});
var $author$project$Main$changeRouteTo = F2(
	function (maybeRoute, model) {
		var session = A2(
			$author$project$Session$setQuery,
			'',
			$author$project$Session$closeNavbar(
				$author$project$Main$toSession(model)));
		var cmds = _List_fromArray(
			[
				A2(
				$elm$core$Task$perform,
				function (_v33) {
					return $author$project$Main$Ignored;
				},
				A2($elm$browser$Browser$Dom$setViewport, 0, 0))
			]);
		if (maybeRoute.$ === 1) {
			return A4(
				$author$project$Main$updateWith,
				$author$project$Main$NotFound,
				$author$project$Main$GotNotFoundMsg,
				cmds,
				$author$project$Page$NotFound$init(session));
		} else {
			switch (maybeRoute.a.$) {
				case 16:
					var _v1 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Login,
						$author$project$Main$GotLoginMsg,
						cmds,
						A2(
							$author$project$Page$Login$init,
							session,
							$author$project$Main$toRoute(model)));
				case 1:
					var _v2 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Content,
						$author$project$Main$GotContentMsg,
						cmds,
						A2($author$project$Page$Content$init, session, $author$project$Route$RMA));
				case 2:
					var _v3 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Content,
						$author$project$Main$GotContentMsg,
						cmds,
						A2($author$project$Page$Content$init, session, $author$project$Route$DeliveryTerms));
				case 17:
					var _v4 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$RequestNewPassword,
						$author$project$Main$GotRequestNewPasswordMsg,
						cmds,
						$author$project$Page$RequestNewPassword$init(session));
				case 18:
					var _v5 = maybeRoute.a;
					var queryParams = _v5.a;
					var token = _v5.b;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ResetPassword,
						$author$project$Main$GotResetPasswordMsg,
						cmds,
						A3($author$project$Page$ResetPassword$init, session, queryParams, token));
				case 19:
					var _v6 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Account,
						$author$project$Main$GotAccountMsg,
						cmds,
						$author$project$Page$Account$init(session));
				case 20:
					var _v7 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$AccountProfile,
						$author$project$Main$GotAccountProfileMsg,
						cmds,
						$author$project$Page$Account$Profile$init(session));
				case 21:
					var _v8 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$AccountSettings,
						$author$project$Main$GotAccountSettingsMsg,
						cmds,
						$author$project$Page$Account$Settings$init(session));
				case 22:
					var _v9 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$AccountPassword,
						$author$project$Main$GotAccountPasswordMsg,
						cmds,
						$author$project$Page$Account$Password$init(session));
				case 23:
					var _v10 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$AccountAdministration,
						$author$project$Main$GotAccountAdministrationMsg,
						cmds,
						$author$project$Page$Account$Administration$init(session));
				case 0:
					var _v11 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Home,
						$author$project$Main$GotHomeMsg,
						cmds,
						$author$project$Page$Home$init(session));
				case 6:
					var _v12 = maybeRoute.a;
					var queryParams = _v12.a;
					var mainCategorySlug = _v12.b;
					var _v13 = _v12.c;
					var id = _v13.a;
					var maybeMainCategory = A2(
						$author$project$Helpers$find,
						function (tree) {
							return _Utils_eq(
								$author$project$Category$slug(
									$zwilias$elm_rosetree$Tree$label(tree)),
								mainCategorySlug);
						},
						$author$project$Session$categories(session));
					var maybeCategory = A2(
						$elm$core$Maybe$andThen,
						$zwilias$elm_rosetree$Tree$Zipper$findFromRoot(
							function (cat) {
								return _Utils_eq(
									$author$project$Category$id(cat),
									id);
							}),
						A2($elm$core$Maybe$map, $zwilias$elm_rosetree$Tree$Zipper$fromTree, maybeMainCategory));
					if (!maybeCategory.$) {
						var cat = maybeCategory.a;
						var maybeCategoryModel = $author$project$Main$getProductsModel(model);
						var isSamePage = A2(
							$elm$core$Maybe$withDefault,
							false,
							A2(
								$elm$core$Maybe$map,
								$author$project$Page$Products$isSameCategory(cat),
								maybeCategoryModel));
						var _v15 = _Utils_Tuple2(isSamePage, maybeCategoryModel);
						if (_v15.a && (!_v15.b.$)) {
							var subModel = _v15.b.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Products,
								$author$project$Main$GotProductsMsg,
								_List_Nil,
								A2($author$project$Page$Products$updateQueryParams, queryParams, subModel));
						} else {
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Products,
								$author$project$Main$GotProductsMsg,
								cmds,
								A3($author$project$Page$Products$initCategoryMode, session, queryParams, cat));
						}
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					}
				case 5:
					var slug = maybeRoute.a.a;
					var maybeMainCategory = A2(
						$author$project$Helpers$find,
						function (tree) {
							return _Utils_eq(
								$author$project$Category$slug(
									$zwilias$elm_rosetree$Tree$label(tree)),
								slug);
						},
						$author$project$Session$categories(session));
					if (!maybeMainCategory.$) {
						var cat = maybeMainCategory.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$MainCategory,
							$author$project$Main$GotMainCategoryMsg,
							cmds,
							A2($author$project$Page$MainCategory$init, session, cat));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					}
				case 4:
					var _v17 = maybeRoute.a;
					var queryParams = _v17.a;
					var mainCategorySlug = _v17.b;
					var sticker = _v17.c;
					var maybeMainCategory = A2(
						$elm$core$Maybe$map,
						$zwilias$elm_rosetree$Tree$Zipper$fromTree,
						A2(
							$author$project$Helpers$find,
							function (tree) {
								return _Utils_eq(
									$author$project$Category$slug(
										$zwilias$elm_rosetree$Tree$label(tree)),
									mainCategorySlug);
							},
							$author$project$Session$categories(session)));
					if (!maybeMainCategory.$) {
						var cat = maybeMainCategory.a;
						var maybeCategoryModel = $author$project$Main$getProductsModel(model);
						var isSamePage = A2(
							$elm$core$Maybe$withDefault,
							false,
							A2(
								$elm$core$Maybe$map,
								A2($author$project$Page$Products$isSameSticker, cat, sticker),
								maybeCategoryModel));
						var _v19 = _Utils_Tuple2(isSamePage, maybeCategoryModel);
						if (_v19.a && (!_v19.b.$)) {
							var subModel = _v19.b.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Products,
								$author$project$Main$GotProductsMsg,
								_List_Nil,
								A2($author$project$Page$Products$updateQueryParams, queryParams, subModel));
						} else {
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Products,
								$author$project$Main$GotProductsMsg,
								cmds,
								A4($author$project$Page$Products$initStickerMode, session, queryParams, cat, sticker));
						}
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					}
				case 11:
					var _v20 = maybeRoute.a;
					var queryParams = _v20.a;
					var id = _v20.b;
					var _v21 = $author$project$Main$getProductModel(model);
					if (!_v21.$) {
						var subModel = _v21.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Product,
							$author$project$Main$GotProductMsg,
							cmds,
							A4($author$project$Page$Product$initReload, subModel, session, queryParams, id));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Product,
							$author$project$Main$GotProductMsg,
							cmds,
							A3($author$project$Page$Product$init, session, queryParams, id));
					}
				case 12:
					var _v22 = maybeRoute.a;
					var _v23 = $author$project$Session$user(session);
					if (_v23.$ === 1) {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Checkout,
							$author$project$Main$GotCheckoutMsg,
							cmds,
							$author$project$Page$Checkout$init(session));
					}
				case 13:
					var queryParams = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Orders,
						$author$project$Main$GotOrdersMsg,
						cmds,
						A2($author$project$Page$Orders$init, session, queryParams));
				case 14:
					var id = maybeRoute.a.a;
					var _v24 = $author$project$Session$user(session);
					if (_v24.$ === 1) {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Order,
							$author$project$Main$GotOrderMsg,
							cmds,
							A2($author$project$Page$Order$init, session, id));
					}
				case 25:
					var _v25 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Bids,
						$author$project$Main$GotBidsMsg,
						cmds,
						$author$project$Page$Bids$init(session));
				case 26:
					var id = maybeRoute.a.a;
					var _v26 = $author$project$Session$user(session);
					if (_v26.$ === 1) {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Bid,
							$author$project$Main$GotBidMsg,
							cmds,
							A2($author$project$Page$Bid$init, session, id));
					}
				case 15:
					var id = maybeRoute.a.a;
					var _v27 = $author$project$Session$user(session);
					if (_v27.$ === 1) {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							cmds,
							$author$project$Page$NotFound$init(session));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ThankYou,
							$author$project$Main$GotThankYouMsg,
							cmds,
							A2($author$project$Page$ThankYou$init, session, id));
					}
				case 24:
					var queryParams = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Invoices,
						$author$project$Main$GotInvoicesMsg,
						cmds,
						A2($author$project$Page$Invoices$init, session, queryParams));
				case 7:
					var queryParams = maybeRoute.a.a;
					var maybeSearchModel = $author$project$Main$getProductsModel(model);
					var isSamePage = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$Products$isSameSearch(
								A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$andThen,
										$elm$core$List$head,
										A2($elm$core$Dict$get, 'q', queryParams)))),
							maybeSearchModel));
					var _v28 = _Utils_Tuple2(isSamePage, maybeSearchModel);
					if (_v28.a && (!_v28.b.$)) {
						var subModel = _v28.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Products,
							$author$project$Main$GotProductsMsg,
							_List_Nil,
							A2($author$project$Page$Products$updateQueryParams, queryParams, subModel));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Products,
							$author$project$Main$GotProductsMsg,
							_List_Nil,
							A2($author$project$Page$Products$initSearchMode, session, queryParams));
					}
				case 9:
					var _v29 = maybeRoute.a;
					var queryParams = _v29.a;
					var slug = _v29.b;
					var maybeBrandModel = $author$project$Main$getProductsModel(model);
					var isSamePage = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$Products$isSameBrand(slug),
							maybeBrandModel));
					var _v30 = _Utils_Tuple2(isSamePage, maybeBrandModel);
					if (_v30.a && (!_v30.b.$)) {
						var subModel = _v30.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Products,
							$author$project$Main$GotProductsMsg,
							_List_Nil,
							A2($author$project$Page$Products$updateQueryParams, queryParams, subModel));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Products,
							$author$project$Main$GotProductsMsg,
							_List_Nil,
							A3($author$project$Page$Products$initBrandMode, session, queryParams, slug));
					}
				case 8:
					var _v31 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Brands,
						$author$project$Main$GotBrandsMsg,
						cmds,
						$author$project$Page$Brands$init(session));
				case 3:
					var _v32 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Service,
						$author$project$Main$GotServiceMsg,
						cmds,
						$author$project$Page$Service$init(session));
				default:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Content,
						$author$project$Main$GotContentMsg,
						cmds,
						A2(
							$author$project$Page$Content$init,
							session,
							$author$project$Route$BrandService(slug)));
			}
		}
	});
var $author$project$Rollbar$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Rollbar$encode = function (event) {
	switch (event.$) {
		case 0:
			var url = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'message',
						$elm$json$Json$Encode$string('Elm error: Bad url')),
						_Utils_Tuple2(
						'payload',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(url))
								])))
					]));
		case 1:
			var statusCode = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'message',
						$elm$json$Json$Encode$string(
							'Elm error: Bad status' + $elm$core$String$fromInt(statusCode))),
						_Utils_Tuple2('payload', $elm$json$Json$Encode$null)
					]));
		case 2:
			var body = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'message',
						$elm$json$Json$Encode$string('Elm error: Bad body')),
						_Utils_Tuple2(
						'payload',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'body',
									$elm$json$Json$Encode$string(body))
								])))
					]));
		default:
			var message = event.a.fx;
			var description = event.a.eT;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'message',
						$elm$json$Json$Encode$string('Elm error: ' + message)),
						_Utils_Tuple2(
						'payload',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'description',
									$elm$json$Json$Encode$string(description))
								])))
					]));
	}
};
var $author$project$Ports$trackError = _Platform_outgoingPort('trackError', $elm$core$Basics$identity);
var $author$project$Rollbar$customError = A2(
	$elm$core$Basics$composeR,
	$author$project$Rollbar$Custom,
	A2($elm$core$Basics$composeR, $author$project$Rollbar$encode, $author$project$Ports$trackError));
var $author$project$Session$decodeError = function (error) {
	return _Utils_Tuple2(
		$author$project$Api$ValidationError(
			{hd: $elm$core$Dict$empty, fx: 'Något gick fel. Felet har rapporterats och undersöks inom kort. Testa att uppdatera sidan för att se om det löser problemet. Kontakta annars partnerzon@specialelektronik.se'}),
		$author$project$Rollbar$customError(
			{
				eT: $elm$json$Json$Decode$errorToString(error),
				fx: 'Session decode error'
			}));
};
var $author$project$Session$Storage = F4(
	function (user, searchHistory, layout, orderBy) {
		return {hK: layout, ia: orderBy, a3: searchHistory, gy: user};
	});
var $author$project$Layout$List = 1;
var $author$project$Layout$Table = 2;
var $author$project$Layout$fromString = function (string) {
	switch (string) {
		case 'gallery':
			return $elm$core$Maybe$Just(0);
		case 'list':
			return $elm$core$Maybe$Just(1);
		case 'table':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Layout$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		var _v0 = $author$project$Layout$fromString(s);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$json$Json$Decode$succeed(a);
		} else {
			return $elm$json$Json$Decode$fail('Unsupported layout');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$OrderBy$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		var _v0 = $author$project$OrderBy$fromString(s);
		if (!_v0.$) {
			var orderBy = _v0.a;
			return $elm$json$Json$Decode$succeed(orderBy);
		} else {
			return $elm$json$Json$Decode$fail(s + ' is not a supported OrderBy value');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$SearchHistory$decoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$User$User = F7(
	function (id, firstName, lastName, email, cart, settings, role) {
		return {gT: cart, be: email, ho: firstName, hx: id, hI: lastName, it: role, c0: settings};
	});
var $author$project$Cart$Cart = $elm$core$Basics$identity;
var $author$project$Cart$Row = $elm$core$Basics$identity;
var $author$project$Cart$Internals = function (id) {
	return function (productId) {
		return function (unit) {
			return function (name) {
				return function (productExternalId) {
					return function (slug) {
						return function (price) {
							return function (chemicalTax) {
								return function (amount) {
									return function (brand) {
										return function (image) {
											return function (categories) {
												return function (source) {
													return function (status) {
														return function (error) {
															return {cj: amount, dp: brand, gV: categories, dq: chemicalTax, dy: error, hx: id, dG: image, hT: name, cR: price, d$: productExternalId, cT: productId, gb: slug, ge: source, gh: status, em: unit};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Cart$NoSource = {$: 0};
var $author$project$Cart$BidRowSource = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Cart$bidRowDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Cart$BidRowSource,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'bid_id', $author$project$Id$decoder),
	A2($elm$json$Json$Decode$field, 'bid_external_id', $author$project$ExternalId$decoder));
var $author$project$Cart$sourceDecoderHelper = function (type_) {
	if (type_ === 'bid_row') {
		return $author$project$Cart$bidRowDecoder;
	} else {
		return $elm$json$Json$Decode$fail(type_ + ' is not a supported source');
	}
};
var $author$project$Cart$sourceDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Cart$sourceDecoderHelper,
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Cart$internalsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'error',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$author$project$ProductStatus$decoder,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'source',
			$author$project$Cart$sourceDecoder,
			$author$project$Cart$NoSource,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'categories',
				$elm$json$Json$Decode$list($author$project$Category$previewDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'image',
					$author$project$Image$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'brand',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'amount',
							$elm$json$Json$Decode$float,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'chemical_tax',
								$elm$json$Json$Decode$float,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'price',
									$elm$json$Json$Decode$float,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'slug',
										$author$project$Slug$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'product_code',
											$author$project$ExternalId$decoder,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'name',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'unit',
													$author$project$Unit$decoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'product_id',
														$author$project$Id$decoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'id',
															$author$project$Id$decoder,
															$elm$json$Json$Decode$succeed($author$project$Cart$Internals))))))))))))))));
var $author$project$Cart$rowDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Cart$internalsDecoder,
	$elm$json$Json$Decode$succeed($elm$core$Basics$identity));
var $author$project$Cart$rowsDecoder = A2(
	$elm$json$Json$Decode$field,
	'rows',
	$elm$json$Json$Decode$list($author$project$Cart$rowDecoder));
var $author$project$Cart$decoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Cart$rowsDecoder,
	$elm$json$Json$Decode$succeed($elm$core$Basics$identity));
var $author$project$User$Settings$Settings = F5(
	function (vat, layout, orderBy, showDiscount, documentScope) {
		return {ha: documentScope, hK: layout, ia: orderBy, ga: showDiscount, gA: vat};
	});
var $author$project$Document$Scope$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $author$project$Document$Scope$fromString(str);
		if (!_v0.$) {
			var scope = _v0.a;
			return $elm$json$Json$Decode$succeed(scope);
		} else {
			return $elm$json$Json$Decode$fail(str + ' is not a supported scope');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$VAT$Excl = 1;
var $author$project$VAT$Incl = 0;
var $author$project$VAT$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'incl':
				return $elm$json$Json$Decode$succeed(0);
			case 'excl':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail(s + ' is not a supported vat setting');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$User$Settings$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'documentScope',
	$author$project$Document$Scope$decoder,
	0,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'showDiscount',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'orderBy',
			$author$project$OrderBy$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'layout',
				$author$project$Layout$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'vat',
					$author$project$VAT$decoder,
					$elm$json$Json$Decode$succeed($author$project$User$Settings$Settings))))));
var $author$project$Cart$empty = _List_Nil;
var $author$project$User$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'role',
	$author$project$Role$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'settings',
		$author$project$User$Settings$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'cart',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$author$project$Cart$decoder,
						$elm$json$Json$Decode$null($author$project$Cart$empty)
					])),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'email',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'last_name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'first_name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$Id$decoder,
							$elm$json$Json$Decode$succeed($author$project$User$User))))))));
var $author$project$Session$storageDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'orderBy',
	$author$project$OrderBy$decoder,
	$author$project$OrderBy$Recent,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'layout',
		$author$project$Layout$decoder,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'search_history',
			$author$project$SearchHistory$decoder,
			$author$project$SearchHistory$empty,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'user',
				$elm$json$Json$Decode$nullable($author$project$User$decoder),
				$elm$json$Json$Decode$succeed($author$project$Session$Storage)))));
var $author$project$Session$decodeStorage = function (value) {
	return A2($elm$json$Json$Decode$decodeValue, $author$project$Session$storageDecoder, value);
};
var $author$project$Session$UI = function (navbar) {
	return function (autocomplete) {
		return function (searchHistory) {
			return function (layout) {
				return function (orderBy) {
					return function (categories) {
						return function (csrfToken) {
							return function (openingHours) {
								return function (zone) {
									return function (time) {
										return function (errors) {
											return function (snackbar) {
												return function (newsletter) {
													return function (cookieHosts) {
														return {dk: autocomplete, gV: categories, eO: cookieHosts, g0: csrfToken, hd: errors, hK: layout, bX: navbar, fB: newsletter, h8: openingHours, ia: orderBy, a3: searchHistory, iG: snackbar, el: time, gG: zone};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$CsrfToken$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$OpeningHours$Open = function (a) {
	return {$: 0, a: a};
};
var $author$project$OpeningHours$decoder = A2(
	$elm$json$Json$Decode$map,
	function (s) {
		return (s === '') ? $author$project$OpeningHours$Closed : $author$project$OpeningHours$Open(s);
	},
	$elm$json$Json$Decode$string);
function $author$project$Category$cyclic$treeDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'children',
		$elm$json$Json$Decode$list(
			$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $author$project$Category$cyclic$treeDecoder();
				})),
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			$author$project$Category$previewDecoder,
			$elm$json$Json$Decode$succeed($zwilias$elm_rosetree$Tree$tree)));
}
var $author$project$Category$treeDecoder = $author$project$Category$cyclic$treeDecoder();
$author$project$Category$cyclic$treeDecoder = function () {
	return $author$project$Category$treeDecoder;
};
var $author$project$Session$uiDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	_List_Nil,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		$author$project$Newsletter$init,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			$author$project$SE$UI$Snackbar$init,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				_List_Nil,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'time',
					A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'timezoneOffset',
						A2(
							$elm$json$Json$Decode$map,
							function (a) {
								return A2($elm$time$Time$customZone, a, _List_Nil);
							},
							$elm$json$Json$Decode$int),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'openingHours',
							$author$project$OpeningHours$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'csrfToken',
								$author$project$CsrfToken$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'categories',
									$elm$json$Json$Decode$list($author$project$Category$treeDecoder),
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'orderBy',
										$author$project$OrderBy$decoder,
										$author$project$OrderBy$Recent,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'layout',
											$author$project$Layout$decoder,
											0,
											$elm$json$Json$Decode$succeed(
												A3(
													$author$project$Session$UI,
													$author$project$SE$UI$Navbar$V2$defaultModel,
													$specialelektronik$elm_autocomplete$Autocomplete$init(''),
													$author$project$SearchHistory$empty)))))))))))));
var $author$project$Session$decodeUI = function (value) {
	return A2(
		$elm$core$Result$withDefault,
		$author$project$Session$defaultUI,
		A2($elm$json$Json$Decode$decodeValue, $author$project$Session$uiDecoder, value));
};
var $author$project$Session$fromStorage = F3(
	function (key, ui, storage) {
		var _v0 = storage.gy;
		if (!_v0.$) {
			var userValue = _v0.a;
			return A3(
				$author$project$Session$LoggedIn,
				key,
				userValue,
				_Utils_update(
					ui,
					{a3: storage.a3}));
		} else {
			return A2(
				$author$project$Session$Guest,
				key,
				_Utils_update(
					ui,
					{hK: storage.hK}));
		}
	});
var $author$project$QueryParams$fromUrl = function (url) {
	var toTuples = function (str) {
		var _v2 = A2($elm$core$String$split, '=', str);
		if (_v2.b) {
			if (_v2.a === 'q') {
				var value = _v2.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'q',
						A2($elm$core$String$join, '=', value))
					]);
			} else {
				var key = _v2.a;
				var value = _v2.b;
				return A2(
					$elm$core$List$map,
					$elm$core$Tuple$pair(key),
					A2(
						$elm$core$String$split,
						',',
						A2($elm$core$String$join, '=', value)));
			}
		} else {
			return _List_Nil;
		}
	};
	var addParam = F2(
		function (value, maybeValues) {
			if (!maybeValues.$) {
				var values = maybeValues.a;
				return $elm$core$Maybe$Just(
					A2($elm$core$List$cons, value, values));
			} else {
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[value]));
			}
		});
	var toDict = function (parameters) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, dict) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(
						$elm$core$Dict$update,
						k,
						addParam(v),
						dict);
				}),
			$elm$core$Dict$empty,
			parameters);
	};
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Dict$empty,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$String$split('&'),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$concatMap(toTuples),
					toDict)),
			A2($elm$core$Maybe$andThen, $elm$url$Url$percentDecode, url.im)));
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aY: frag, E: params, aT: unvisited, iY: value, a6: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aT;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.iY);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.iY);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.fI),
					$elm$url$Url$Parser$prepareQuery(url.im),
					url.e3,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$ResetPassword = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.a6;
		var unvisited = _v0.aT;
		var params = _v0.E;
		var frag = _v0.aY;
		var value = _v0.iY;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.a6;
			var unvisited = _v1.aT;
			var params = _v1.E;
			var frag = _v1.aY;
			var value = _v1.iY;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.a6;
			var unvisited = _v0.aT;
			var params = _v0.E;
			var frag = _v0.aY;
			var value = _v0.iY;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $author$project$Slug$fromString = $elm$core$Basics$identity;
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $author$project$Id$productUrlParser = A2(
	$elm$url$Url$Parser$custom,
	'PRODUCTID',
	function (segment) {
		var _v0 = A2($elm$core$String$right, 5, segment);
		if (_v0 === '.html') {
			return function (revList) {
				if (!revList.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var head = revList.a;
					var tail = revList.b;
					var _v2 = $elm$core$String$toInt(head);
					if (_v2.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var id_ = _v2.a;
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(
								id_,
								$author$project$Slug$fromString(
									A2(
										$elm$core$String$join,
										'-',
										$elm$core$List$reverse(tail)))));
					}
				}
			}(
				$elm$core$List$reverse(
					A2(
						$elm$core$String$split,
						'-',
						A2($elm$core$String$dropRight, 5, segment))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.a6;
		var unvisited = _v0.aT;
		var params = _v0.E;
		var frag = _v0.aY;
		var value = _v0.iY;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Category$urlParser = A2(
	$elm$url$Url$Parser$custom,
	'CATEGORY',
	function (segment) {
		var parts = $elm$core$List$reverse(
			A2($elm$core$String$split, '-', segment));
		var maybeSlug = A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$String$join('-'),
				$elm$core$List$reverse),
			$elm$core$List$tail(parts));
		var maybeId = A2(
			$elm$core$Maybe$map,
			$author$project$Id$fromInt,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				$elm$core$List$head(parts)));
		return A3($elm$core$Maybe$map2, $elm$core$Tuple$pair, maybeId, maybeSlug);
	});
var $author$project$Id$urlParser = A2(
	$elm$url$Url$Parser$custom,
	'ID',
	function (str) {
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$identity,
			$elm$core$String$toInt(str));
	});
var $author$project$Slug$urlParser = A2(
	$elm$url$Url$Parser$custom,
	'SLUG',
	function (str) {
		return $elm$core$Maybe$Just(str);
	});
var $author$project$Sticker$fromSlug = function (str) {
	switch (str) {
		case 'nyheter':
			return $elm$core$Maybe$Just(0);
		case 'kampanjer':
			return $elm$core$Maybe$Just(2);
		case 'fynd':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Sticker$urlParser = A2($elm$url$Url$Parser$custom, 'STICKER', $author$project$Sticker$fromSlug);
var $author$project$Route$parser = function (queryParams) {
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Login,
				$elm$url$Url$Parser$s('login')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$RMA,
				$elm$url$Url$Parser$s('rma')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$DeliveryTerms,
				$elm$url$Url$Parser$s('deliveryterms')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Service,
				$elm$url$Url$Parser$s('service')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$ResetPassword(queryParams),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('password'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('reset'),
						$elm$url$Url$Parser$string))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$RequestNewPassword,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('password'),
					$elm$url$Url$Parser$s('reset'))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Account,
				$elm$url$Url$Parser$s('account')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$AccountProfile,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('account'),
					$elm$url$Url$Parser$s('profile'))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$AccountSettings,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('account'),
					$elm$url$Url$Parser$s('settings'))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$AccountPassword,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('account'),
					$elm$url$Url$Parser$s('password'))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$AccountAdministration,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('account'),
					$elm$url$Url$Parser$s('admin'))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Bids,
				$elm$url$Url$Parser$s('bids')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Bid,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('bids'),
					$author$project$Id$urlParser)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Checkout,
				$elm$url$Url$Parser$s('checkout')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Orders(queryParams),
				$elm$url$Url$Parser$s('orders')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Order,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('orders'),
					$author$project$Id$urlParser)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$ThankYou,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('orders'),
					A2(
						$elm$url$Url$Parser$slash,
						$author$project$Id$urlParser,
						$elm$url$Url$Parser$s('thank-you')))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Invoices(queryParams),
				$elm$url$Url$Parser$s('invoices')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Brands,
				$elm$url$Url$Parser$s('brands')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$BrandService,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('brands'),
					A2(
						$elm$url$Url$Parser$slash,
						$author$project$Slug$urlParser,
						$elm$url$Url$Parser$s('service')))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Brand(queryParams),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('brands'),
					$author$project$Slug$urlParser)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Sticker(queryParams),
				A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $author$project$Sticker$urlParser)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Category(queryParams),
				A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $author$project$Category$urlParser)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Search(queryParams),
				$elm$url$Url$Parser$s('search')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Product(queryParams),
				$author$project$Id$productUrlParser),
				A2($elm$url$Url$Parser$map, $author$project$Route$MainCategory, $elm$url$Url$Parser$string)
			]));
};
var $author$project$Route$fromUrl = function (url) {
	return A2(
		$elm$url$Url$Parser$parse,
		$author$project$Route$parser(
			$author$project$QueryParams$fromUrl(url)),
		url);
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Main$init = F3(
	function (flags, url, navKey) {
		var _v0 = $author$project$Session$decodeStorage(flags);
		if (!_v0.$) {
			var storage = _v0.a;
			return A2(
				$author$project$Main$changeRouteTo,
				$author$project$Route$fromUrl(url),
				$author$project$Main$Redirect(
					A3(
						$author$project$Session$fromStorage,
						navKey,
						$author$project$Session$decodeUI(flags),
						storage)));
		} else {
			var decodeError = _v0.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Main$DecodeError(navKey),
				$author$project$Session$decodeError(decodeError));
		}
	});
var $author$project$Main$GotSession = function (a) {
	return {$: 26, a: a};
};
var $author$project$Session$onSessionChange = _Platform_incomingPort('onSessionChange', $elm$json$Json$Decode$value);
var $author$project$Session$changes = F3(
	function (toMsg, key, ui) {
		return $author$project$Session$onSessionChange(
			function (value) {
				return toMsg(
					A2(
						$elm$core$Result$map,
						A2($author$project$Session$fromStorage, key, ui),
						$author$project$Session$decodeStorage(value)));
			});
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Session$navKey = function (session) {
	if (!session.$) {
		var key = session.a;
		return key;
	} else {
		var key = session.a;
		return key;
	}
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Page$Account$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Account$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Session$ChangedVisibility = function (a) {
	return {$: 19, a: a};
};
var $author$project$Session$ToggledShowDiscount = {$: 17};
var $author$project$Cookie$activeGroupsDecoder = A2(
	$elm$json$Json$Decode$map,
	function (str) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
			A2($elm$core$String$split, ',', str));
	},
	$elm$json$Json$Decode$string);
var $author$project$Cookie$Group = F3(
	function (name, id, hosts) {
		return {e9: hosts, hx: id, hT: name};
	});
var $author$project$Cookie$groupHostDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (id, name) {
			return {hx: id, hT: name};
		}),
	A2($elm$json$Json$Decode$field, 'HostId', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'DisplayName', $elm$json$Json$Decode$string));
var $author$project$Cookie$groupDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Cookie$Group,
	A2($elm$json$Json$Decode$field, 'GroupName', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'CustomGroupId', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'Hosts',
		$elm$json$Json$Decode$list($author$project$Cookie$groupHostDecoder)));
var $author$project$Cookie$Host = $elm$core$Basics$identity;
var $author$project$Cookie$groupToHost = F3(
	function (groupName, isActive, _v0) {
		var id = _v0.hx;
		var name = _v0.hT;
		return {dC: groupName, hx: id, dH: isActive, hT: name};
	});
var $author$project$Cookie$groupToHosts = F2(
	function (activeGroupIds, group) {
		return A2(
			$elm$core$List$map,
			A2(
				$author$project$Cookie$groupToHost,
				group.hT,
				A2($elm$core$List$member, group.hx, activeGroupIds)),
			group.e9);
	});
var $author$project$Cookie$toHosts = F2(
	function (allGroups, activeGroupIds) {
		return A2(
			$elm$core$List$concatMap,
			$author$project$Cookie$groupToHosts(activeGroupIds),
			allGroups);
	});
var $author$project$Cookie$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Cookie$toHosts,
	A2(
		$elm$json$Json$Decode$field,
		'allGroups',
		$elm$json$Json$Decode$list($author$project$Cookie$groupDecoder)),
	A2($elm$json$Json$Decode$field, 'activeGroupIdsString', $author$project$Cookie$activeGroupsDecoder));
var $author$project$Session$decodeCookieHosts = function (value) {
	return A2($elm$json$Json$Decode$decodeValue, $author$project$Cookie$decoder, value);
};
var $author$project$Session$onCookieConsentChange = _Platform_incomingPort('onCookieConsentChange', $elm$json$Json$Decode$value);
var $author$project$Session$cookieChanges = F2(
	function (toMsg, session) {
		return $author$project$Session$onCookieConsentChange(
			function (value) {
				return toMsg(
					A2(
						$elm$core$Result$map,
						function (okHosts) {
							return A2(
								$author$project$Session$mapUI,
								function (ui) {
									return _Utils_update(
										ui,
										{eO: okHosts});
								},
								session);
						},
						$author$project$Session$decodeCookieHosts(value)));
			});
	});
var $author$project$Keyboard$ArrowLeft = {$: 1};
var $author$project$Keyboard$ArrowRight = {$: 2};
var $author$project$Keyboard$Character = function (a) {
	return {$: 0, a: a};
};
var $author$project$Keyboard$Esc = {$: 3};
var $author$project$Keyboard$keyDecoderHelper = function (s) {
	switch (s) {
		case 'ArrowLeft':
			return $elm$json$Json$Decode$succeed($author$project$Keyboard$ArrowLeft);
		case 'ArrowRight':
			return $elm$json$Json$Decode$succeed($author$project$Keyboard$ArrowRight);
		case 'Escape':
			return $elm$json$Json$Decode$succeed($author$project$Keyboard$Esc);
		default:
			var other = s;
			var _v1 = $elm$core$String$uncons(other);
			if ((!_v1.$) && (_v1.a.b === '')) {
				var _v2 = _v1.a;
				var _char = _v2.a;
				return $elm$json$Json$Decode$succeed(
					$author$project$Keyboard$Character(_char));
			} else {
				return $elm$json$Json$Decode$fail('key not supported');
			}
	}
};
var $author$project$Keyboard$keyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Keyboard$keyDecoderHelper,
	A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {fK: pids, gm: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {e_: event, fp: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.fK,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.fp;
		var event = _v0.e_;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.gm);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onKeyUp = A2($elm$browser$Browser$Events$on, 0, 'keyup');
var $elm$browser$Browser$Events$Hidden = 1;
var $elm$browser$Browser$Events$Visible = 0;
var $elm$browser$Browser$Events$withHidden = F2(
	function (func, isHidden) {
		return func(
			isHidden ? 1 : 0);
	});
var $elm$browser$Browser$Events$onVisibilityChange = function (func) {
	var info = _Browser_visibilityInfo(0);
	return A3(
		$elm$browser$Browser$Events$on,
		0,
		info.gW,
		A2(
			$elm$json$Json$Decode$map,
			$elm$browser$Browser$Events$withHidden(func),
			A2(
				$elm$json$Json$Decode$field,
				'target',
				A2($elm$json$Json$Decode$field, info.ht, $elm$json$Json$Decode$bool))));
};
var $author$project$Session$subscriptions = F2(
	function (_v0, session) {
		var gotSession = _v0.hr;
		var gotSessionMsg = _v0.hs;
		return $elm$core$Platform$Sub$batch(
			A2(
				$elm$core$List$cons,
				A2($author$project$Session$cookieChanges, gotSession, session),
				function () {
					if (!session.$) {
						var navKey_ = session.a;
						var ui = session.c;
						return _List_fromArray(
							[
								A3($author$project$Session$changes, gotSession, navKey_, ui),
								$elm$browser$Browser$Events$onVisibilityChange(
								A2($elm$core$Basics$composeR, $author$project$Session$ChangedVisibility, gotSessionMsg)),
								$elm$browser$Browser$Events$onKeyUp(
								A2(
									$elm$json$Json$Decode$andThen,
									function (key) {
										if ((!key.$) && ('§' === key.a)) {
											return $elm$json$Json$Decode$succeed(
												gotSessionMsg($author$project$Session$ToggledShowDiscount));
										} else {
											return $elm$json$Json$Decode$fail('');
										}
									},
									$author$project$Keyboard$keyDecoder))
							]);
					} else {
						var navKey_ = session.a;
						var ui = session.b;
						return _List_fromArray(
							[
								A3($author$project$Session$changes, gotSession, navKey_, ui)
							]);
					}
				}()));
	});
var $author$project$Page$Account$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Account$GotSession, hs: $author$project$Page$Account$GotSessionMsg},
		model.K);
};
var $author$project$Page$Account$Administration$GotSession = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Account$Administration$GotSessionMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Account$Administration$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Account$Administration$GotSession, hs: $author$project$Page$Account$Administration$GotSessionMsg},
		model.K);
};
var $author$project$Page$Account$Password$GotSession = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Account$Password$GotSessionMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Account$Password$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Account$Password$GotSession, hs: $author$project$Page$Account$Password$GotSessionMsg},
		model.K);
};
var $author$project$Page$Account$Profile$GotSession = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Account$Profile$GotSessionMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Account$Profile$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Account$Profile$GotSession, hs: $author$project$Page$Account$Profile$GotSessionMsg},
		model.K);
};
var $author$project$Page$Account$Settings$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Account$Settings$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Account$Settings$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Account$Settings$GotSession, hs: $author$project$Page$Account$Settings$GotSessionMsg},
		model.K);
};
var $author$project$Page$Bid$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Bid$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Bid$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Bid$GotSession, hs: $author$project$Page$Bid$GotSessionMsg},
		model.K);
};
var $author$project$Page$Bids$GotSession = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Bids$GotSessionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Bids$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Bids$GotSession, hs: $author$project$Page$Bids$GotSessionMsg},
		model.K);
};
var $author$project$Page$Brands$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Brands$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Brands$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Brands$GotSession, hs: $author$project$Page$Brands$GotSessionMsg},
		model.K);
};
var $author$project$Page$Checkout$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Checkout$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Checkout$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Checkout$GotSession, hs: $author$project$Page$Checkout$GotSessionMsg},
		model.K);
};
var $author$project$Page$Content$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Content$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Content$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Content$GotSession, hs: $author$project$Page$Content$GotSessionMsg},
		model.K);
};
var $author$project$Page$Home$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Home$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Home$GotSession, hs: $author$project$Page$Home$GotSessionMsg},
		model.K);
};
var $author$project$Page$Invoices$GotSession = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Invoices$GotSessionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Invoices$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Invoices$GotSession, hs: $author$project$Page$Invoices$GotSessionMsg},
		model.K);
};
var $author$project$Page$Login$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Login$GotSession, hs: $author$project$Page$Login$GotSessionMsg},
		model.K);
};
var $author$project$Page$MainCategory$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$MainCategory$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$MainCategory$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$MainCategory$GotSession, hs: $author$project$Page$MainCategory$GotSessionMsg},
		model.K);
};
var $author$project$Page$NotFound$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$NotFound$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$NotFound$subscriptions = function (session) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$NotFound$GotSession, hs: $author$project$Page$NotFound$GotSessionMsg},
		session);
};
var $author$project$Page$Order$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Order$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Order$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Order$GotSession, hs: $author$project$Page$Order$GotSessionMsg},
		model.K);
};
var $author$project$Page$Orders$GotSession = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Orders$GotSessionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Orders$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Orders$GotSession, hs: $author$project$Page$Orders$GotSessionMsg},
		model.K);
};
var $author$project$Page$Product$GotSession = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Product$GotSessionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Product$ChangedModal = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Product$ChangedActiveEmbedIndex = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Product$NoOp = {$: 0};
var $author$project$Page$Product$arrowSub = $elm$browser$Browser$Events$onKeyUp(
	A2(
		$elm$json$Json$Decode$map,
		function (key) {
			switch (key.$) {
				case 1:
					return $author$project$Page$Product$ChangedActiveEmbedIndex(-1);
				case 2:
					return $author$project$Page$Product$ChangedActiveEmbedIndex(1);
				default:
					return $author$project$Page$Product$NoOp;
			}
		},
		$author$project$Keyboard$keyDecoder));
var $author$project$Page$Product$escSub = function (msg) {
	return $elm$browser$Browser$Events$onKeyUp(
		A2(
			$elm$json$Json$Decode$map,
			function (key) {
				if (key.$ === 3) {
					return msg;
				} else {
					return $author$project$Page$Product$NoOp;
				}
			},
			$author$project$Keyboard$keyDecoder));
};
var $author$project$Page$Product$modalSubscriptions = function (modal) {
	switch (modal) {
		case 1:
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						$author$project$Page$Product$escSub(
						$author$project$Page$Product$ChangedModal(0)),
						$author$project$Page$Product$arrowSub
					]));
		case 2:
			return $author$project$Page$Product$escSub(
				$author$project$Page$Product$ChangedModal(0));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Page$Product$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$author$project$Session$subscriptions,
				{hr: $author$project$Page$Product$GotSession, hs: $author$project$Page$Product$GotSessionMsg},
				model.K),
				$author$project$Page$Product$modalSubscriptions(model.cH)
			]));
};
var $author$project$Page$Products$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Products$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Products$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Products$GotSession, hs: $author$project$Page$Products$GotSessionMsg},
		model.K);
};
var $author$project$Page$RequestNewPassword$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$RequestNewPassword$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$RequestNewPassword$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$RequestNewPassword$GotSession, hs: $author$project$Page$RequestNewPassword$GotSessionMsg},
		model.K);
};
var $author$project$Page$ResetPassword$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ResetPassword$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ResetPassword$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$ResetPassword$GotSession, hs: $author$project$Page$ResetPassword$GotSessionMsg},
		model.K);
};
var $author$project$Page$Service$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Service$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Service$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$Service$GotSession, hs: $author$project$Page$Service$GotSessionMsg},
		model.K);
};
var $author$project$Page$ThankYou$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ThankYou$GotSessionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ThankYou$subscriptions = function (model) {
	return A2(
		$author$project$Session$subscriptions,
		{hr: $author$project$Page$ThankYou$GotSession, hs: $author$project$Page$ThankYou$GotSessionMsg},
		model.K);
};
var $author$project$Main$subscriptions = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Platform$Sub$none;
		case 2:
			var session = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotNotFoundMsg,
				$author$project$Page$NotFound$subscriptions(session));
		case 1:
			return A3(
				$author$project$Session$changes,
				$author$project$Main$GotSession,
				$author$project$Session$navKey(
					$author$project$Main$toSession(model)),
				$author$project$Session$userInterface(
					$author$project$Main$toSession(model)));
		case 3:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotHomeMsg,
				$author$project$Page$Home$subscriptions(subModel));
		case 21:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotContentMsg,
				$author$project$Page$Content$subscriptions(subModel));
		case 22:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotServiceMsg,
				$author$project$Page$Service$subscriptions(subModel));
		case 11:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotLoginMsg,
				$author$project$Page$Login$subscriptions(subModel));
		case 12:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotRequestNewPasswordMsg,
				$author$project$Page$RequestNewPassword$subscriptions(subModel));
		case 13:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotResetPasswordMsg,
				$author$project$Page$ResetPassword$subscriptions(subModel));
		case 5:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotProductsMsg,
				$author$project$Page$Products$subscriptions(subModel));
		case 4:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotMainCategoryMsg,
				$author$project$Page$MainCategory$subscriptions(subModel));
		case 6:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotProductMsg,
				$author$project$Page$Product$subscriptions(subModel));
		case 7:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotCheckoutMsg,
				$author$project$Page$Checkout$subscriptions(subModel));
		case 8:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotOrdersMsg,
				$author$project$Page$Orders$subscriptions(subModel));
		case 9:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotOrderMsg,
				$author$project$Page$Order$subscriptions(subModel));
		case 23:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotBidsMsg,
				$author$project$Page$Bids$subscriptions(subModel));
		case 24:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotBidMsg,
				$author$project$Page$Bid$subscriptions(subModel));
		case 14:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotThankYouMsg,
				$author$project$Page$ThankYou$subscriptions(subModel));
		case 10:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotInvoicesMsg,
				$author$project$Page$Invoices$subscriptions(subModel));
		case 15:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotBrandsMsg,
				$author$project$Page$Brands$subscriptions(subModel));
		case 16:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotAccountMsg,
				$author$project$Page$Account$subscriptions(subModel));
		case 17:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotAccountProfileMsg,
				$author$project$Page$Account$Profile$subscriptions(subModel));
		case 18:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotAccountSettingsMsg,
				$author$project$Page$Account$Settings$subscriptions(subModel));
		case 19:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotAccountPasswordMsg,
				$author$project$Page$Account$Password$subscriptions(subModel));
		default:
			var subModel = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotAccountAdministrationMsg,
				$author$project$Page$Account$Administration$subscriptions(subModel));
	}
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var newStyles = _v0.b;
		var classname = _v0.c;
		return $elm$core$List$isEmpty(newStyles) ? styles : A3($elm$core$Dict$insert, classname, newStyles, styles);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _v1 = properties.a;
				var styles = _v1.b;
				var classname = _v1.c;
				var rest = properties.b;
				if ($elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = $elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _v0 = A2($rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, $elm$core$Maybe$Nothing, properties);
	if (_v0.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var _v1 = _v0.a;
		var classname = _v1.a;
		var styles = _v1.b;
		return A2($elm$core$Dict$singleton, classname, styles);
	}
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_v0) {
	var classname = _v0.a;
	var styles = _v0.b;
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		$elm$core$List$singleton(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					$elm$core$Dict$toList(dict)))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		$elm$core$List$singleton(
			$elm$virtual_dom$VirtualDom$text(
				$rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $author$project$Main$toUnstyled = function (_v0) {
	var title = _v0.gr;
	var body = _v0.bI;
	return {
		bI: A2($elm$core$List$map, $rtfeldman$elm_css$Html$Styled$toUnstyled, body),
		gr: title
	};
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$toString(route));
	});
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.fV;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.e3,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.im,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.fL,
					_Utils_ap(http, url.hv)),
				url.fI)));
};
var $author$project$Status$Failed = function (a) {
	return {$: 3, a: a};
};
var $author$project$Status$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Session$addError = function (error) {
	return $author$project$Session$mapUI(
		function (ui) {
			return _Utils_update(
				ui,
				{
					hd: A2($elm$core$List$cons, error, ui.hd)
				});
		});
};
var $author$project$Session$addDecodeError = F2(
	function (error, session) {
		return A2(
			$elm$core$Tuple$mapFirst,
			function (apiError) {
				return A2($author$project$Session$addError, apiError, session);
			},
			$author$project$Session$decodeError(error));
	});
var $author$project$Rollbar$BadBody = function (a) {
	return {$: 2, a: a};
};
var $author$project$Rollbar$BadStatus = function (a) {
	return {$: 1, a: a};
};
var $author$project$Rollbar$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Rollbar$toEvent = function (apiError_) {
	switch (apiError_.$) {
		case 0:
			var url = apiError_.a;
			return $elm$core$Maybe$Just(
				$author$project$Rollbar$BadUrl(url));
		case 3:
			switch (apiError_.a) {
				case 404:
					return $elm$core$Maybe$Nothing;
				case 419:
					return $elm$core$Maybe$Nothing;
				default:
					var statusCode = apiError_.a;
					return $elm$core$Maybe$Just(
						$author$project$Rollbar$BadStatus(statusCode));
			}
		case 4:
			var body = apiError_.a;
			return $elm$core$Maybe$Just(
				$author$project$Rollbar$BadBody(body));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Rollbar$apiError = A2(
	$elm$core$Basics$composeR,
	$author$project$Rollbar$toEvent,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map($author$project$Rollbar$encode),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($author$project$Ports$trackError),
			$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none))));
var $author$project$SE$UI$Colors$Buy = 11;
var $author$project$Analytics$Checkout = {$: 10};
var $author$project$Session$CompletedClearedCart = function (a) {
	return {$: 10, a: a};
};
var $author$project$Session$CompletedLoadCart = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Session$CompletedUpdatedSettings = function (a) {
	return {$: 18, a: a};
};
var $author$project$Session$GotNewsletterMsg = function (a) {
	return {$: 23, a: a};
};
var $author$project$Api$Endpoint$cart = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['cart']),
	_List_Nil);
var $author$project$Helpers$dataDecoder = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'data', decoder);
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Cart$encodeAddPayloadSource = function (source_) {
	if (!source_.$) {
		return _List_Nil;
	} else {
		var bidRowId = source_.a;
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'source_type',
				$elm$json$Json$Encode$string('bid_rows')),
				_Utils_Tuple2(
				'source_id',
				$elm$json$Json$Encode$int(
					$author$project$Id$toInt(bidRowId)))
			]);
	}
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Cart$encodeAddPayload = F3(
	function (productId_, source_, amount_) {
		return $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'product_id',
						$elm$json$Json$Encode$int(
							$author$project$Id$toInt(productId_))),
						_Utils_Tuple2(
						'amount',
						$elm$json$Json$Encode$float(amount_))
					]),
				$author$project$Cart$encodeAddPayloadSource(source_)));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Api$post = F4(
	function (url, body, decoder, msg) {
		return $author$project$Api$Endpoint$request(
			{
				bI: $elm$http$Http$jsonBody(body),
				bN: A2($author$project$Api$expectJson, msg, decoder),
				bQ: _List_Nil,
				bW: 'POST',
				b7: $elm$core$Maybe$Nothing,
				cc: $elm$core$Maybe$Nothing,
				cg: url
			});
	});
var $author$project$Cart$add = F4(
	function (msg, productId_, source_, amount_) {
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$cart,
			A3($author$project$Cart$encodeAddPayload, productId_, source_, amount_),
			$author$project$Helpers$dataDecoder($author$project$Cart$decoder),
			msg);
	});
var $author$project$SE$UI$Snackbar$PassedDuration = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$SE$UI$Snackbar$add = F3(
	function (config, model, snackbar) {
		var internals = snackbar;
		var newUid = model.de + 1;
		var cmds = (!internals.bM) ? $elm$core$Platform$Cmd$none : A2(
			$elm$core$Platform$Cmd$map,
			config.gu,
			A2(
				$elm$core$Task$perform,
				function (_v0) {
					return $author$project$SE$UI$Snackbar$PassedDuration(newUid);
				},
				$elm$core$Process$sleep(internals.bM)));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					ae: A3($elm$core$Dict$insert, newUid, snackbar, model.ae),
					de: newUid
				}),
			cmds);
	});
var $author$project$Cart$amount = function (_v0) {
	var internals = _v0;
	return internals.cj;
};
var $author$project$Session$autocomplete = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$userInterface,
	function ($) {
		return $.dk;
	});
var $author$project$Session$AutocompleteMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Session$ChoseSuggestion = function (a) {
	return {$: 2, a: a};
};
var $author$project$Session$CompletedLoadSuggestions = function (a) {
	return {$: 0, a: a};
};
var $author$project$Session$FocusedSearchbar = {$: 3};
var $author$project$Session$SubmittedQuery = function (a) {
	return {$: 1, a: a};
};
var $author$project$SearchBar$Brand = F4(
	function (id, name, slug, image) {
		return {hx: id, dG: image, hT: name, gb: slug};
	});
var $author$project$SearchBar$BrandSuggestion = function (a) {
	return {$: 3, a: a};
};
var $author$project$SearchBar$brandDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$SearchBar$BrandSuggestion,
	A5(
		$elm$json$Json$Decode$map4,
		$author$project$SearchBar$Brand,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'slug', $author$project$Slug$decoder),
		A2($elm$json$Json$Decode$field, 'logo', $author$project$Image$decoder)));
var $author$project$SearchBar$Category = F5(
	function (id, name, slug, image, ancestors) {
		return {di: ancestors, hx: id, dG: image, hT: name, gb: slug};
	});
var $author$project$SearchBar$CategorySuggestion = function (a) {
	return {$: 2, a: a};
};
var $author$project$SearchBar$Ancestor = F3(
	function (id, name, slug) {
		return {hx: id, hT: name, gb: slug};
	});
var $author$project$SearchBar$ancestorDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$SearchBar$Ancestor,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string));
var $author$project$SearchBar$categoryDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$SearchBar$CategorySuggestion,
	A6(
		$elm$json$Json$Decode$map5,
		$author$project$SearchBar$Category,
		A2($elm$json$Json$Decode$field, 'id', $author$project$Id$decoder),
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'image', $author$project$Image$decoder),
		A2(
			$elm$json$Json$Decode$field,
			'ancestors',
			$elm$json$Json$Decode$list($author$project$SearchBar$ancestorDecoder))));
var $author$project$SearchBar$Product = F5(
	function (id, product_code, name, slug, image) {
		return {hx: id, dG: image, hT: name, fU: product_code, gb: slug};
	});
var $author$project$SearchBar$ProductSuggestion = function (a) {
	return {$: 1, a: a};
};
var $author$project$SearchBar$productDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$SearchBar$ProductSuggestion,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'image',
		$author$project$Image$decoder,
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'slug',
			$author$project$Slug$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'product_code',
					$author$project$ExternalId$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$author$project$Id$decoder,
						$elm$json$Json$Decode$succeed($author$project$SearchBar$Product)))))));
var $author$project$SearchBar$decoder = A2(
	$elm$json$Json$Decode$field,
	'suggestions',
	$elm$json$Json$Decode$list(
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[$author$project$SearchBar$productDecoder, $author$project$SearchBar$categoryDecoder, $author$project$SearchBar$brandDecoder]))));
var $author$project$Api$Endpoint$productsSuggest = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['products', 'suggest']),
		params);
};
var $author$project$SearchBar$list = F2(
	function (msg, q) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productsSuggest(
				_List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'q', q)
					])),
			$author$project$SearchBar$decoder,
			msg);
	});
var $author$project$Session$autocompleteConfig = {
	gX: $author$project$Session$ChoseSuggestion,
	hl: $author$project$SearchBar$list($author$project$Session$CompletedLoadSuggestions),
	hp: $author$project$Session$FocusedSearchbar,
	iK: $author$project$Session$SubmittedQuery,
	gu: $author$project$Session$AutocompleteMsg
};
var $author$project$SE$UI$Colors$base = A4($author$project$SE$UI$Colors$Hsla, 210, 0.03, 0.57, 1);
var $author$project$SE$UI$Icon$V2$Icon = $elm$core$Basics$identity;
var $author$project$SE$UI$Icon$V2$Internals = F4(
	function (size, containerSize, transforms, viewBoxAndPaths) {
		return {cq: containerSize, d6: size, dc: transforms, ep: viewBoxAndPaths};
	});
var $author$project$SE$UI$Control$Regular = 0;
var $author$project$SE$UI$Icon$V2$initInternals = A3($author$project$SE$UI$Icon$V2$Internals, 0, 0, _List_Nil);
var $author$project$SE$UI$Icon$V2$cart = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		576,
		512,
		_List_fromArray(
			['M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z'])));
var $author$project$SE$UI$Colors$color = A2($elm$core$Basics$composeR, $author$project$SE$UI$Colors$toCss, $rtfeldman$elm_css$Css$color);
var $author$project$SE$UI$Snackbar$Entered = 0;
var $author$project$SE$UI$Snackbar$Snackbar = $elm$core$Basics$identity;
var $author$project$SE$UI$Snackbar$defaultDuration = 3500;
var $author$project$SE$UI$Snackbar$create = function (_v0) {
	var label = _v0.cB;
	var message = _v0.fx;
	return {bM: $author$project$SE$UI$Snackbar$defaultDuration, cz: $elm$core$Maybe$Nothing, cB: label, fx: message, c2: 0};
};
var $author$project$Api$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$author$project$Api$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$Api$delete = F2(
	function (url, msg) {
		return $author$project$Api$Endpoint$request(
			{
				bI: $elm$http$Http$emptyBody,
				bN: $author$project$Api$expectWhatever(msg),
				bQ: _List_Nil,
				bW: 'DELETE',
				b7: $elm$core$Maybe$Nothing,
				cc: $elm$core$Maybe$Nothing,
				cg: url
			});
	});
var $author$project$Cart$delete = function (msg) {
	return A2($author$project$Api$delete, $author$project$Api$Endpoint$cart, msg);
};
var $author$project$Id$equals = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return _Utils_eq(a, b);
	});
var $author$project$Cart$findRow = F2(
	function (productId_, _v0) {
		var rows_ = _v0;
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var internals_ = _v1;
					return A2($author$project$Id$equals, productId_, internals_.cT);
				},
				rows_));
	});
var $author$project$Session$CompletedHeartbeat = function (a) {
	return {$: 20, a: a};
};
var $author$project$Api$head = F2(
	function (url, msg) {
		return $author$project$Api$Endpoint$request(
			{
				bI: $elm$http$Http$emptyBody,
				bN: $author$project$Api$expectWhatever(msg),
				bQ: _List_Nil,
				bW: 'HEAD',
				b7: $elm$core$Maybe$Nothing,
				cc: $elm$core$Maybe$Nothing,
				cg: url
			});
	});
var $author$project$Api$Endpoint$heartbeat = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['heartbeat']),
	_List_Nil);
var $author$project$Session$heartbeat = A2($author$project$Api$head, $author$project$Api$Endpoint$heartbeat, $author$project$Session$CompletedHeartbeat);
var $author$project$SearchHistory$insert = F2(
	function (str, _v0) {
		var lst = _v0;
		return A2(
			$elm$core$List$take,
			5,
			A2(
				$elm$core$List$cons,
				str,
				A2(
					$elm$core$List$filter,
					$elm$core$Basics$neq(str),
					lst)));
	});
var $author$project$Session$navbar = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$userInterface,
	function ($) {
		return $.bX;
	});
var $author$project$SE$UI$Colors$primary = A4($author$project$SE$UI$Colors$Hsla, 138, 1, 0.31, 1);
var $author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$toString(route));
	});
var $author$project$Helpers$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $specialelektronik$elm_autocomplete$Autocomplete$setSuggestions = F2(
	function (suggestions, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{iM: suggestions});
	});
var $author$project$Session$GotSnackbarMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$Session$snackbarConfig = {gu: $author$project$Session$GotSnackbarMsg};
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Image$sizeEncoder = function (size) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(size.cg)),
				_Utils_Tuple2(
				'resolution',
				$elm$json$Json$Encode$int(size.d3)),
				_Utils_Tuple2(
				'width',
				$elm$json$Json$Encode$int(size.gE)),
				_Utils_Tuple2(
				'height',
				$elm$json$Json$Encode$int(size.e7))
			]));
};
var $author$project$Image$encode = function (_v0) {
	var internals = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(internals.hx)),
				_Utils_Tuple2(
				'order',
				$elm$json$Json$Encode$int(internals.h9)),
				_Utils_Tuple2(
				'sizes',
				A3(
					$elm$json$Json$Encode$dict,
					$elm$core$Basics$identity,
					$elm$json$Json$Encode$list($author$project$Image$sizeEncoder),
					internals.c1))
			]));
};
var $author$project$ProductStatus$toString = function (status) {
	switch (status) {
		case 0:
			return 'ok';
		case 1:
			return 'outbound';
		default:
			return 'locked';
	}
};
var $author$project$ProductStatus$encode = A2($elm$core$Basics$composeR, $author$project$ProductStatus$toString, $elm$json$Json$Encode$string);
var $author$project$Slug$encode = A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Slug$toString);
var $author$project$Unit$toKey = function (unit) {
	switch (unit) {
		case 0:
			return 'piece';
		case 1:
			return 'kilogram';
		case 2:
			return 'hour';
		case 3:
			return 'meter';
		case 4:
			return 'kilometer';
		case 5:
			return 'square_meter';
		case 6:
			return 'hundred_package';
		case 7:
			return 'centimeter';
		case 8:
			return 'five_hundred_meter';
		case 9:
			return 'pair';
		case 10:
			return 'package';
		case 11:
			return 'minute';
		case 12:
			return 'month';
		case 13:
			return 'year';
		case 14:
			return 'kit';
		default:
			return 'room';
	}
};
var $author$project$Unit$encode = function (unit) {
	return $elm$json$Json$Encode$string(
		$author$project$Unit$toKey(unit));
};
var $author$project$Category$encodePreview = function (_v0) {
	var internals = _v0.a;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(
					$author$project$Id$toInt(internals.hx))),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(internals.hT)),
				_Utils_Tuple2(
				'slug',
				$elm$json$Json$Encode$string(internals.gb)),
				_Utils_Tuple2(
				'product_count',
				$elm$json$Json$Encode$int(internals.cS))
			]));
};
var $author$project$ExternalId$toString = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$Cart$encodeSource = function (source_) {
	if (!source_.$) {
		return $elm$json$Json$Encode$null;
	} else {
		var bidRowId = source_.a;
		var bidId = source_.b;
		var bidExternalId = source_.c;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('bid_row')),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(
						$author$project$Id$toInt(bidRowId))),
					_Utils_Tuple2(
					'bid_id',
					$elm$json$Json$Encode$int(
						$author$project$Id$toInt(bidId))),
					_Utils_Tuple2(
					'bid_external_id',
					$elm$json$Json$Encode$string(
						$author$project$ExternalId$toString(bidExternalId)))
				]));
	}
};
var $author$project$Cart$encodeRow = function (_v0) {
	var internals = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(
					$author$project$Id$toInt(internals.hx))),
				_Utils_Tuple2(
				'product_id',
				$elm$json$Json$Encode$int(
					$author$project$Id$toInt(internals.cT))),
				_Utils_Tuple2(
				'unit',
				$author$project$Unit$encode(internals.em)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(internals.hT)),
				_Utils_Tuple2(
				'product_code',
				$elm$json$Json$Encode$string(
					$author$project$ExternalId$toString(internals.d$))),
				_Utils_Tuple2(
				'slug',
				$author$project$Slug$encode(internals.gb)),
				_Utils_Tuple2(
				'price',
				$elm$json$Json$Encode$float(internals.cR)),
				_Utils_Tuple2(
				'amount',
				$elm$json$Json$Encode$float(internals.cj)),
				_Utils_Tuple2(
				'chemical_tax',
				$elm$json$Json$Encode$float(internals.dq)),
				_Utils_Tuple2(
				'brand',
				$elm$json$Json$Encode$string(internals.dp)),
				_Utils_Tuple2(
				'categories',
				A2($elm$json$Json$Encode$list, $author$project$Category$encodePreview, internals.gV)),
				_Utils_Tuple2(
				'source',
				$author$project$Cart$encodeSource(internals.ge)),
				_Utils_Tuple2(
				'image',
				function () {
					var _v1 = internals.dG;
					if (!_v1.$) {
						var image_ = _v1.a;
						return $author$project$Image$encode(image_);
					} else {
						return $elm$json$Json$Encode$null;
					}
				}()),
				_Utils_Tuple2(
				'status',
				$author$project$ProductStatus$encode(internals.gh)),
				_Utils_Tuple2(
				'error',
				$elm$json$Json$Encode$string(internals.dy))
			]));
};
var $author$project$Cart$encode = function (_v0) {
	var rs = _v0;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rows',
				A2($elm$json$Json$Encode$list, $author$project$Cart$encodeRow, rs))
			]));
};
var $author$project$Role$toString = function (role) {
	if (!role) {
		return 'regular';
	} else {
		return 'admin';
	}
};
var $author$project$Role$encode = A2($elm$core$Basics$composeR, $author$project$Role$toString, $elm$json$Json$Encode$string);
var $author$project$Document$Scope$encode = A2($elm$core$Basics$composeR, $author$project$Document$Scope$toString, $elm$json$Json$Encode$string);
var $author$project$Layout$toString = function (layout) {
	switch (layout) {
		case 0:
			return 'gallery';
		case 1:
			return 'list';
		default:
			return 'table';
	}
};
var $author$project$Layout$encode = A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Layout$toString);
var $author$project$OrderBy$encode = A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$OrderBy$toString);
var $author$project$VAT$toString = function (vat) {
	if (vat === 1) {
		return 'excl';
	} else {
		return 'incl';
	}
};
var $author$project$VAT$encode = function (vat) {
	return $elm$json$Json$Encode$string(
		$author$project$VAT$toString(vat));
};
var $author$project$User$Settings$encode = function (settings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'vat',
				$author$project$VAT$encode(settings.gA)),
				_Utils_Tuple2(
				'layout',
				$author$project$Layout$encode(settings.hK)),
				_Utils_Tuple2(
				'orderBy',
				$author$project$OrderBy$encode(settings.ia)),
				_Utils_Tuple2(
				'showDiscount',
				$elm$json$Json$Encode$bool(settings.ga)),
				_Utils_Tuple2(
				'documentScope',
				$author$project$Document$Scope$encode(settings.ha))
			]));
};
var $author$project$User$encode = function (user) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(
					$author$project$Id$toInt(user.hx))),
				_Utils_Tuple2(
				'first_name',
				$elm$json$Json$Encode$string(user.ho)),
				_Utils_Tuple2(
				'last_name',
				$elm$json$Json$Encode$string(user.hI)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(user.be)),
				_Utils_Tuple2(
				'cart',
				$author$project$Cart$encode(user.gT)),
				_Utils_Tuple2(
				'settings',
				$author$project$User$Settings$encode(user.c0)),
				_Utils_Tuple2(
				'role',
				$author$project$Role$encode(user.it))
			]));
};
var $author$project$Session$storeSession = _Platform_outgoingPort('storeSession', $elm$core$Basics$identity);
var $author$project$Session$storeCart = F2(
	function (session, cart) {
		if (!session.$) {
			var u = session.b;
			return $author$project$Session$storeSession(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'user',
							$author$project$User$encode(
								_Utils_update(
									u,
									{gT: cart})))
						])));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$SearchHistory$encode = function (_v0) {
	var lst = _v0;
	return A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, lst);
};
var $author$project$Session$storeHistory = function (sh) {
	return $author$project$Session$storeSession(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'search_history',
					$author$project$SearchHistory$encode(sh))
				])));
};
var $author$project$Session$storeLayout = function (layout_) {
	return $author$project$Session$storeSession(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'layout',
					$author$project$Layout$encode(layout_))
				])));
};
var $author$project$Session$storeOrderBy = function (orderBy_) {
	return $author$project$Session$storeSession(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'orderBy',
					$author$project$OrderBy$encode(orderBy_))
				])));
};
var $author$project$Session$storeSettings = F2(
	function (session, settings) {
		if (!session.$) {
			var u = session.b;
			return $author$project$Session$storeSession(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'user',
							$author$project$User$encode(
								_Utils_update(
									u,
									{c0: settings})))
						])));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $author$project$SearchBar$HistorySuggestion = function (a) {
	return {$: 0, a: a};
};
var $author$project$SearchBar$toHistorySuggestion = $author$project$SearchBar$HistorySuggestion;
var $author$project$SearchHistory$toList = function (_v0) {
	var lst = _v0;
	return lst;
};
var $author$project$SearchBar$categorytoRoute = function (cat) {
	var _v0 = $elm$core$List$head(cat.di);
	if (_v0.$ === 1) {
		return $author$project$Route$MainCategory(cat.gb);
	} else {
		var mainCat = _v0.a;
		return A3(
			$author$project$Route$Category,
			$author$project$QueryParams$empty,
			mainCat.gb,
			_Utils_Tuple2(cat.hx, cat.gb));
	}
};
var $author$project$SearchBar$toRoute = function (suggestion) {
	switch (suggestion.$) {
		case 0:
			var q = suggestion.a;
			return $author$project$Route$Search(
				A2(
					$elm$core$Dict$singleton,
					'q',
					_List_fromArray(
						[q])));
		case 1:
			var p = suggestion.a;
			return A2(
				$author$project$Route$Product,
				$author$project$QueryParams$empty,
				_Utils_Tuple2(p.hx, p.gb));
		case 2:
			var c = suggestion.a;
			return $author$project$SearchBar$categorytoRoute(c);
		default:
			var brand = suggestion.a;
			return A2($author$project$Route$Brand, $author$project$QueryParams$empty, brand.gb);
	}
};
var $author$project$Analytics$AddToCart = function (a) {
	return {$: 0, a: a};
};
var $author$project$Analytics$Ecommerce = function (a) {
	return {$: 2, a: a};
};
var $author$project$Category$name = function (_v0) {
	var internals = _v0.a;
	return internals.hT;
};
var $author$project$Analytics$encodeAddToCartItemCategories = function (categories) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (cat, acc) {
				var _v0 = $elm$core$List$length(acc);
				if (!_v0) {
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'item_category',
							$elm$json$Json$Encode$string(
								$author$project$Category$name(cat)))
						]);
				} else {
					var length = _v0;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'item_category' + $elm$core$String$fromInt(length),
							$elm$json$Json$Encode$string(
								$author$project$Category$name(cat))),
						acc);
				}
			}),
		_List_Nil,
		A2($elm$core$List$take, 6, categories));
};
var $author$project$Brand$slug = function (_v0) {
	var internals = _v0;
	return internals.gb;
};
var $author$project$Analytics$encodeAddToCartSource = function (source) {
	var name = function (a) {
		return _Utils_Tuple2(
			'item_list_name',
			$elm$json$Json$Encode$string(a));
	};
	var id = function (a) {
		return _Utils_Tuple2(
			'item_list_id',
			$elm$json$Json$Encode$string(a));
	};
	switch (source.$) {
		case 12:
			return _List_Nil;
		case 0:
			return _List_Nil;
		case 1:
			var index = source.a;
			return _List_fromArray(
				[
					name('Search Results'),
					id('search_results'),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 2:
			var id_ = source.a;
			var index = source.b;
			return _List_fromArray(
				[
					name('Related products'),
					id(
					'related_products_' + $elm$core$String$fromInt(
						$author$project$Id$toInt(id_))),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 3:
			var index = source.a;
			return _List_fromArray(
				[
					name('Home page'),
					id('home_page'),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 4:
			var id_ = source.a;
			var index = source.b;
			return _List_fromArray(
				[
					name('Category'),
					id(
					'category_' + $elm$core$String$fromInt(
						$author$project$Id$toInt(id_))),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 5:
			var id_ = source.a;
			var index = source.b;
			return _List_fromArray(
				[
					name('News'),
					id(
					'news_' + $elm$core$String$fromInt(
						$author$project$Id$toInt(id_))),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 6:
			var id_ = source.a;
			var index = source.b;
			return _List_fromArray(
				[
					name('Campaigns'),
					id(
					'campaigns_' + $elm$core$String$fromInt(
						$author$project$Id$toInt(id_))),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 7:
			var id_ = source.a;
			var index = source.b;
			return _List_fromArray(
				[
					name('Bargains'),
					id(
					'bargains_' + $elm$core$String$fromInt(
						$author$project$Id$toInt(id_))),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 8:
			var brand = source.a;
			var index = source.b;
			return _List_fromArray(
				[
					name('Brand'),
					id(
					'brand_' + $author$project$Slug$toString(
						$author$project$Brand$slug(brand))),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(index))
				]);
		case 9:
			return _List_fromArray(
				[
					name('Quick add'),
					id('quick_add')
				]);
		case 10:
			return _List_fromArray(
				[
					name('Checkout'),
					id('checkout')
				]);
		default:
			return _List_fromArray(
				[
					name('BID Page'),
					id('bid_page')
				]);
	}
};
var $author$project$Analytics$encodeCartItem = function (item) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'item_name',
				$elm$json$Json$Encode$string(item.b2.hT)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(
						$author$project$Id$toInt(item.b2.hx))),
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(
						'item_external_id',
						$elm$json$Json$Encode$string(
							$author$project$ExternalId$toString(item.b2.hh))),
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'quantity',
							$elm$json$Json$Encode$float(item.fW)),
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'currency',
								$elm$json$Json$Encode$string('SEK')),
							$elm$core$List$concat(
								_List_fromArray(
									[
										$author$project$Analytics$encodeAddToCartItemCategories(item.b2.gV),
										$author$project$Analytics$encodeAddToCartSource(item.ge)
									]))))))));
};
var $author$project$Analytics$ecommerceEventToPayload = function (event) {
	if (!event.$) {
		var payload = event.a;
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'ecommerce',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'items',
							A2(
								$elm$json$Json$Encode$list,
								$author$project$Analytics$encodeCartItem,
								_List_fromArray(
									[payload])))
						])))
			]);
	} else {
		var payload = event.a;
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'ecommerce',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'items',
							A2(
								$elm$json$Json$Encode$list,
								$author$project$Analytics$encodeCartItem,
								_List_fromArray(
									[payload])))
						])))
			]);
	}
};
var $author$project$Analytics$eventToPayload = function (event) {
	switch (event.$) {
		case 0:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'method',
					$elm$json$Json$Encode$string('password'))
				]);
		case 1:
			var query = event.a.im;
			var hits = event.a.hu;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'search_term',
					$elm$json$Json$Encode$string(query)),
					_Utils_Tuple2(
					'search_hits',
					$elm$json$Json$Encode$int(hits))
				]);
		default:
			var ecEvent = event.a;
			return A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					'reset',
					$elm$json$Json$Encode$bool(true)),
				$author$project$Analytics$ecommerceEventToPayload(ecEvent));
	}
};
var $author$project$Analytics$eventToString = function (event) {
	switch (event.$) {
		case 0:
			return 'login';
		case 1:
			return 'search';
		default:
			if (!event.a.$) {
				return 'add_to_cart';
			} else {
				return 'remove_from_cart';
			}
	}
};
var $author$project$Analytics$analyticsPayload = function (event) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'event',
				$elm$json$Json$Encode$string(
					$author$project$Analytics$eventToString(event))),
			$author$project$Analytics$eventToPayload(event)));
};
var $author$project$Ports$trackAnalytics = _Platform_outgoingPort('trackAnalytics', $elm$core$Basics$identity);
var $author$project$Analytics$trackAddToCart = A2(
	$elm$core$Basics$composeR,
	$author$project$Analytics$AddToCart,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Analytics$Ecommerce,
		A2($elm$core$Basics$composeR, $author$project$Analytics$analyticsPayload, $author$project$Ports$trackAnalytics)));
var $author$project$Analytics$RemoveFromCart = function (a) {
	return {$: 1, a: a};
};
var $author$project$Analytics$trackRemoveFromCart = A2(
	$elm$core$Basics$composeR,
	$author$project$Analytics$RemoveFromCart,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Analytics$Ecommerce,
		A2($elm$core$Basics$composeR, $author$project$Analytics$analyticsPayload, $author$project$Ports$trackAnalytics)));
var $specialelektronik$elm_autocomplete$Autocomplete$DebounceMsg = function (a) {
	return {$: 4, a: a};
};
var $jinjor$elm_debounce$Debounce$Later = function (a) {
	return {$: 2, a: a};
};
var $jinjor$elm_debounce$Debounce$later = $jinjor$elm_debounce$Debounce$Later;
var $specialelektronik$elm_autocomplete$Autocomplete$debounceConfig = {
	gj: $jinjor$elm_debounce$Debounce$later(200),
	gu: $specialelektronik$elm_autocomplete$Autocomplete$DebounceMsg
};
var $specialelektronik$elm_autocomplete$Autocomplete$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $specialelektronik$elm_autocomplete$Autocomplete$ignoreUpdate = function (internals) {
	return {gY: $elm$core$Platform$Cmd$none, dL: $elm$core$Maybe$Nothing, hX: internals};
};
var $specialelektronik$elm_autocomplete$Autocomplete$LoadSuggestions = function (a) {
	return {$: 2, a: a};
};
var $specialelektronik$elm_autocomplete$Autocomplete$load = function (q) {
	return A2(
		$elm$core$Task$perform,
		$specialelektronik$elm_autocomplete$Autocomplete$LoadSuggestions,
		$elm$core$Task$succeed(q));
};
var $jinjor$elm_debounce$Debounce$Flush = function (a) {
	return {$: 1, a: a};
};
var $jinjor$elm_debounce$Debounce$SendIfLengthNotChangedFrom = function (a) {
	return {$: 2, a: a};
};
var $jinjor$elm_debounce$Debounce$delayCmd = F2(
	function (delay, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(delay));
	});
var $jinjor$elm_debounce$Debounce$length = function (_v0) {
	var input = _v0.av;
	return $elm$core$List$length(input);
};
var $jinjor$elm_debounce$Debounce$push = F3(
	function (config, a, _v0) {
		var d = _v0;
		var newDebounce = _Utils_update(
			d,
			{
				av: A2($elm$core$List$cons, a, d.av)
			});
		var selfCmd = function () {
			var _v1 = config.gj;
			switch (_v1.$) {
				case 0:
					var offset = _v1.a;
					return d.bT ? $elm$core$Platform$Cmd$none : A2(
						$jinjor$elm_debounce$Debounce$delayCmd,
						offset,
						$jinjor$elm_debounce$Debounce$Flush($elm$core$Maybe$Nothing));
				case 1:
					var offset = _v1.a;
					var delay = _v1.b;
					return d.bT ? $elm$core$Platform$Cmd$none : A2(
						$jinjor$elm_debounce$Debounce$delayCmd,
						offset,
						$jinjor$elm_debounce$Debounce$Flush(
							$elm$core$Maybe$Just(delay)));
				default:
					var delay = _v1.a;
					return A2(
						$jinjor$elm_debounce$Debounce$delayCmd,
						delay,
						$jinjor$elm_debounce$Debounce$SendIfLengthNotChangedFrom(
							$jinjor$elm_debounce$Debounce$length(newDebounce)));
			}
		}();
		return _Utils_Tuple2(
			newDebounce,
			A2($elm$core$Platform$Cmd$map, config.gu, selfCmd));
	});
var $jinjor$elm_debounce$Debounce$takeLast = F3(
	function (send, head, tail) {
		return _Utils_Tuple2(
			_List_Nil,
			send(head));
	});
var $jinjor$elm_debounce$Debounce$update = F4(
	function (config, send, msg, _v0) {
		var d = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(d, $elm$core$Platform$Cmd$none);
			case 1:
				var tryAgainAfter = msg.a;
				var _v2 = d.av;
				if (_v2.b) {
					var head = _v2.a;
					var tail = _v2.b;
					var selfCmd = function () {
						if (!tryAgainAfter.$) {
							var delay = tryAgainAfter.a;
							return A2(
								$jinjor$elm_debounce$Debounce$delayCmd,
								delay,
								$jinjor$elm_debounce$Debounce$Flush(
									$elm$core$Maybe$Just(delay)));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					var _v3 = A2(send, head, tail);
					var input = _v3.a;
					var sendCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							d,
							{av: input, bT: true}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									sendCmd,
									A2($elm$core$Platform$Cmd$map, config.gu, selfCmd)
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							d,
							{bT: false}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var lastInputLength = msg.a;
				var _v5 = _Utils_Tuple2(
					_Utils_cmp(
						$elm$core$List$length(d.av),
						lastInputLength) < 1,
					d.av);
				if (_v5.a && _v5.b.b) {
					var _v6 = _v5.b;
					var head = _v6.a;
					var tail = _v6.b;
					var _v7 = A2(send, head, tail);
					var input = _v7.a;
					var cmd = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							d,
							{av: input}),
						cmd);
				} else {
					return _Utils_Tuple2(d, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $specialelektronik$elm_autocomplete$Autocomplete$update = F3(
	function (config, msg, _v0) {
		var internals = _v0;
		switch (msg.$) {
			case 0:
				var q = msg.a;
				if ($elm$core$String$length(q) < 3) {
					return {
						gY: $elm$core$Platform$Cmd$none,
						dL: $elm$core$Maybe$Nothing,
						hX: _Utils_update(
							internals,
							{hA: $elm$core$Maybe$Nothing, im: q, iM: _List_Nil})
					};
				} else {
					var _v2 = A3($jinjor$elm_debounce$Debounce$push, $specialelektronik$elm_autocomplete$Autocomplete$debounceConfig, q, internals.bb);
					var debounce = _v2.a;
					var cmd = _v2.b;
					return {
						gY: A2($elm$core$Platform$Cmd$map, config.gu, cmd),
						dL: $elm$core$Maybe$Nothing,
						hX: _Utils_update(
							internals,
							{bb: debounce, im: q})
					};
				}
			case 1:
				return {
					gY: $elm$core$Platform$Cmd$none,
					dL: $elm$core$Maybe$Just(config.hp),
					hX: internals
				};
			case 4:
				var subMsg = msg.a;
				var _v3 = A4(
					$jinjor$elm_debounce$Debounce$update,
					$specialelektronik$elm_autocomplete$Autocomplete$debounceConfig,
					$jinjor$elm_debounce$Debounce$takeLast($specialelektronik$elm_autocomplete$Autocomplete$load),
					subMsg,
					internals.bb);
				var debounce = _v3.a;
				var cmd = _v3.b;
				return {
					gY: A2($elm$core$Platform$Cmd$map, config.gu, cmd),
					dL: $elm$core$Maybe$Nothing,
					hX: _Utils_update(
						internals,
						{bb: debounce})
				};
			case 2:
				var q = msg.a;
				return {
					gY: config.hl(q),
					dL: $elm$core$Maybe$Nothing,
					hX: internals
				};
			case 3:
				var _v4 = function () {
					var _v5 = _Utils_Tuple2(internals.hA, internals.im);
					if (_v5.a.$ === 1) {
						if (_v5.b === '') {
							var _v6 = _v5.a;
							return _Utils_Tuple2($elm$core$Maybe$Nothing, '');
						} else {
							var _v7 = _v5.a;
							var q = _v5.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									config.iK(q)),
								q);
						}
					} else {
						var i = _v5.a.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$Maybe$map,
								config.gX,
								A2($specialelektronik$elm_autocomplete$Autocomplete$getAt, i, internals.iM)),
							'');
					}
				}();
				var newMsg = _v4.a;
				var newQuery = _v4.b;
				return {
					gY: $elm$core$Platform$Cmd$none,
					dL: newMsg,
					hX: _Utils_update(
						internals,
						{hA: $elm$core$Maybe$Nothing, im: newQuery})
				};
			case 5:
				var upOrDown = msg.a;
				var _v8 = internals.iM;
				if (!_v8.b) {
					return $specialelektronik$elm_autocomplete$Autocomplete$ignoreUpdate(internals);
				} else {
					var newIndex = function () {
						var _v9 = _Utils_Tuple2(upOrDown, internals.hA);
						if (_v9.a === 1) {
							if (_v9.b.$ === 1) {
								var _v10 = _v9.a;
								var _v11 = _v9.b;
								return $elm$core$Maybe$Just(0);
							} else {
								var _v14 = _v9.a;
								var i = _v9.b.a;
								return $elm$core$Maybe$Just(
									A2(
										$elm$core$Basics$modBy,
										$elm$core$List$length(internals.iM),
										i + 1));
							}
						} else {
							if (_v9.b.$ === 1) {
								var _v12 = _v9.a;
								var _v13 = _v9.b;
								return $elm$core$Maybe$Just(
									A2(
										$elm$core$Basics$modBy,
										$elm$core$List$length(internals.iM),
										-1));
							} else {
								if (!_v9.b.a) {
									var _v15 = _v9.a;
									return $elm$core$Maybe$Nothing;
								} else {
									var _v16 = _v9.a;
									var i = _v9.b.a;
									return $elm$core$Maybe$Just(
										A2(
											$elm$core$Basics$modBy,
											$elm$core$List$length(internals.iM),
											i - 1));
								}
							}
						}
					}();
					return {
						gY: $elm$core$Platform$Cmd$none,
						dL: $elm$core$Maybe$Nothing,
						hX: _Utils_update(
							internals,
							{hA: newIndex})
					};
				}
			default:
				return $specialelektronik$elm_autocomplete$Autocomplete$ignoreUpdate(internals);
		}
	});
var $author$project$Api$put = F4(
	function (url, body, decoder, msg) {
		return $author$project$Api$Endpoint$request(
			{
				bI: $elm$http$Http$jsonBody(body),
				bN: A2($author$project$Api$expectJson, msg, decoder),
				bQ: _List_Nil,
				bW: 'PUT',
				b7: $elm$core$Maybe$Nothing,
				cc: $elm$core$Maybe$Nothing,
				cg: url
			});
	});
var $author$project$Cart$update = F4(
	function (msg, productId_, source_, amount_) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$cart,
			A3($author$project$Cart$encodeAddPayload, productId_, source_, amount_),
			$author$project$Helpers$dataDecoder($author$project$Cart$decoder),
			msg);
	});
var $author$project$Newsletter$Completed = function (a) {
	return {$: 3, a: a};
};
var $author$project$Newsletter$Failed = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Newsletter$mapForm = F2(
	function (fn, model) {
		switch (model.$) {
			case 0:
				var form = model.a;
				return $author$project$Newsletter$Loaded(
					fn(form));
			case 2:
				var form = model.a;
				var errorMsg = model.b;
				return A2(
					$author$project$Newsletter$Failed,
					fn(form),
					errorMsg);
			default:
				return model;
		}
	});
var $author$project$Newsletter$CompletedForm = function (a) {
	return {$: 4, a: a};
};
var $author$project$Newsletter$Sending = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Endpoint$emailSubscribe = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['email', 'subscribe']),
	_List_Nil);
var $author$project$Newsletter$encodeForm = function (form) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(form.be)),
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(form.cg)),
				_Utils_Tuple2(
				'lists',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$string,
					A2(
						$elm$core$List$filterMap,
						function (_v0) {
							var c = _v0.a;
							var k = _v0.b;
							return c ? $elm$core$Maybe$Just(k) : $elm$core$Maybe$Nothing;
						},
						form.a0)))
			]));
};
var $author$project$Newsletter$send = function (form) {
	return _Utils_Tuple2(
		$author$project$Newsletter$Sending(form),
		A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$emailSubscribe,
			$author$project$Newsletter$encodeForm(form),
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$author$project$Newsletter$CompletedForm));
};
var $author$project$Newsletter$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(model, msg);
		_v0$2:
		while (true) {
			_v0$3:
			while (true) {
				switch (_v0.b.$) {
					case 4:
						switch (_v0.a.$) {
							case 1:
								if (!_v0.b.a.$) {
									var message = _v0.b.a.a;
									return _Utils_Tuple2(
										$author$project$Newsletter$Completed(message),
										$elm$core$Platform$Cmd$none);
								} else {
									var form = _v0.a.a;
									var error = _v0.b.a.a;
									return _Utils_Tuple2(
										A2($author$project$Newsletter$Failed, form, error),
										$author$project$Rollbar$apiError(error));
								}
							case 3:
								break _v0$3;
							case 0:
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							default:
								var _v1 = _v0.a;
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					case 3:
						switch (_v0.a.$) {
							case 1:
								break _v0$2;
							case 3:
								break _v0$3;
							case 0:
								var form = _v0.a.a;
								var _v2 = _v0.b;
								return $author$project$Newsletter$send(form);
							default:
								var _v3 = _v0.a;
								var form = _v3.a;
								var _v4 = _v0.b;
								return $author$project$Newsletter$send(form);
						}
					case 0:
						switch (_v0.a.$) {
							case 1:
								break _v0$2;
							case 3:
								break _v0$3;
							default:
								var email = _v0.b.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Newsletter$mapForm,
										function (form) {
											return _Utils_update(
												form,
												{be: email});
										},
										model),
									$elm$core$Platform$Cmd$none);
						}
					case 1:
						switch (_v0.a.$) {
							case 1:
								break _v0$2;
							case 3:
								break _v0$3;
							default:
								var url = _v0.b.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Newsletter$mapForm,
										function (form) {
											return _Utils_update(
												form,
												{cg: url});
										},
										model),
									$elm$core$Platform$Cmd$none);
						}
					default:
						switch (_v0.a.$) {
							case 1:
								break _v0$2;
							case 3:
								break _v0$3;
							default:
								var key = _v0.b.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Newsletter$mapForm,
										function (form) {
											return _Utils_update(
												form,
												{
													a0: A2(
														$elm$core$List$map,
														function (_v5) {
															var checked = _v5.a;
															var k = _v5.b;
															var t = _v5.c;
															return _Utils_eq(k, key) ? _Utils_Tuple3(!checked, k, t) : _Utils_Tuple3(checked, k, t);
														},
														form.a0)
												});
										},
										model),
									$elm$core$Platform$Cmd$none);
						}
				}
			}
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$SE$UI$Navbar$V2$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_update(
					model,
					{aw: !model.aw});
			case 1:
				return _Utils_update(
					model,
					{ai: ''});
			default:
				var id = msg.a;
				var newId = _Utils_eq(model.ai, id) ? '' : id;
				return _Utils_update(
					model,
					{ai: newId});
		}
	});
var $author$project$SE$UI$Snackbar$Leaving = 1;
var $author$project$SE$UI$Snackbar$PassedLeavingDuration = function (a) {
	return {$: 1, a: a};
};
var $author$project$SE$UI$Snackbar$leavingDuration = 1000;
var $author$project$SE$UI$Snackbar$mapState = F2(
	function (state, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{c2: state});
	});
var $author$project$SE$UI$Snackbar$updateHelper = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ae: A3(
								$elm$core$Dict$update,
								index,
								$elm$core$Maybe$map(
									$author$project$SE$UI$Snackbar$mapState(1)),
								model.ae)
						}),
					A2(
						$elm$core$Task$perform,
						function (_v1) {
							return $author$project$SE$UI$Snackbar$PassedLeavingDuration(index);
						},
						$elm$core$Process$sleep($author$project$SE$UI$Snackbar$leavingDuration)));
			case 1:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ae: A2($elm$core$Dict$remove, index, model.ae)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ae: A2($elm$core$Dict$remove, index, model.ae)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$SE$UI$Snackbar$update = F3(
	function (config, msg, model) {
		var _v0 = A2($author$project$SE$UI$Snackbar$updateHelper, msg, model);
		var newModel = _v0.a;
		var cmds = _v0.b;
		return _Utils_Tuple2(
			newModel,
			A2($elm$core$Platform$Cmd$map, config.gu, cmds));
	});
var $author$project$Api$Endpoint$meSettings = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['me', 'settings']),
	_List_Nil);
var $author$project$User$Settings$updateDocumentScope = F2(
	function (msg, scope) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$meSettings,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'documentScope',
						$author$project$Document$Scope$encode(scope))
					])),
			$author$project$User$Settings$decoder,
			msg);
	});
var $author$project$User$Settings$updateLayout = F2(
	function (msg, layout) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$meSettings,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'layout',
						$author$project$Layout$encode(layout))
					])),
			$author$project$User$Settings$decoder,
			msg);
	});
var $author$project$User$Settings$updateOrderBy = F2(
	function (msg, orderBy) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$meSettings,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'orderBy',
						$author$project$OrderBy$encode(orderBy))
					])),
			$author$project$User$Settings$decoder,
			msg);
	});
var $author$project$User$Settings$updateShowDiscount = F2(
	function (msg, showDiscount) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$meSettings,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'showDiscount',
						$elm$json$Json$Encode$bool(showDiscount))
					])),
			$author$project$User$Settings$decoder,
			msg);
	});
var $author$project$User$Settings$updateVat = F2(
	function (msg, vat) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$meSettings,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'vat',
						$author$project$VAT$encode(vat))
					])),
			$author$project$User$Settings$decoder,
			msg);
	});
var $author$project$SE$UI$Snackbar$withIcon = F3(
	function (icon, color, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{
				cz: $elm$core$Maybe$Just(
					_Utils_Tuple2(icon, color))
			});
	});
var $author$project$Session$update = F2(
	function (msg, session) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					if (!msg.a.$) {
						var suggestions = msg.a.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Session$mapUI,
								function (ui) {
									return _Utils_update(
										ui,
										{
											dk: A2(
												$specialelektronik$elm_autocomplete$Autocomplete$setSuggestions,
												suggestions,
												$author$project$Session$autocomplete(session))
										});
								},
								session),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							A2($author$project$Session$addError, error, session),
							$author$project$Rollbar$apiError(error));
					}
				case 1:
					var q = msg.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$mapUI,
							function (ui) {
								return _Utils_update(
									ui,
									{
										dk: A2(
											$specialelektronik$elm_autocomplete$Autocomplete$setSuggestions,
											_List_Nil,
											$author$project$Session$autocomplete(session))
									});
							},
							session),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Route$pushUrl,
									$author$project$Session$navKey(session),
									$author$project$Route$Search(
										A2(
											$elm$core$Dict$singleton,
											'q',
											_List_fromArray(
												[q])))),
									$author$project$Session$storeHistory(
									A2(
										$author$project$SearchHistory$insert,
										q,
										$author$project$Session$userInterface(session).a3))
								])));
				case 3:
					var suggestions = A2(
						$elm$core$List$map,
						$author$project$SearchBar$toHistorySuggestion,
						$author$project$SearchHistory$toList(
							$author$project$Session$userInterface(session).a3));
					return _Utils_Tuple2(
						A2(
							$author$project$Session$mapUI,
							function (ui) {
								return _Utils_update(
									ui,
									{
										dk: A2(
											$specialelektronik$elm_autocomplete$Autocomplete$setSuggestions,
											suggestions,
											$author$project$Session$autocomplete(session))
									});
							},
							session),
						$elm$core$Platform$Cmd$none);
				case 4:
					return _Utils_Tuple2(
						A2(
							$author$project$Session$mapUI,
							function (ui) {
								return _Utils_update(
									ui,
									{
										dk: A2(
											$specialelektronik$elm_autocomplete$Autocomplete$setSuggestions,
											_List_Nil,
											$author$project$Session$autocomplete(session))
									});
							},
							session),
						$elm$core$Platform$Cmd$none);
				case 2:
					var suggestion = msg.a;
					return _Utils_Tuple2(
						session,
						A2(
							$author$project$Route$pushUrl,
							$author$project$Session$navKey(session),
							$author$project$SearchBar$toRoute(suggestion)));
				case 5:
					var minimal = msg.a;
					var source = msg.b;
					var amount = msg.c;
					var analyticsSource = msg.d;
					return _Utils_Tuple2(
						session,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A4(
									$author$project$Cart$add,
									$author$project$Session$CompletedLoadCart(
										{
											cB: 'En produkt har lagts i varukorgen',
											fx: A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_Nil,
												_List_fromArray(
													[
														A4(
														$rtfeldman$elm_css$Html$Styled$styled,
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
															]),
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(
																$author$project$ExternalId$toString(minimal.hh))
															])),
														A4(
														$rtfeldman$elm_css$Html$Styled$styled,
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
															]),
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(' - ' + minimal.hT)
															]))
													]))
										}),
									minimal.hx,
									source,
									amount),
									$author$project$Analytics$trackAddToCart(
									{b2: minimal, fW: amount, ge: analyticsSource})
								])));
				case 7:
					if (!msg.b.$) {
						var snackbarMsg = msg.a;
						var cart = msg.b.a;
						var _v1 = A3(
							$author$project$SE$UI$Snackbar$add,
							$author$project$Session$snackbarConfig,
							$author$project$Session$userInterface(session).iG,
							A3(
								$author$project$SE$UI$Snackbar$withIcon,
								$author$project$SE$UI$Icon$V2$cart,
								11,
								$author$project$SE$UI$Snackbar$create(snackbarMsg)));
						var newSnackbar = _v1.a;
						var cmds = _v1.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Session$mapUI,
								function (ui) {
									return _Utils_update(
										ui,
										{iG: newSnackbar});
								},
								session),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($author$project$Session$storeCart, session, cart),
										cmds
									])));
					} else {
						var error = msg.b.a;
						return _Utils_Tuple2(
							A2($author$project$Session$addError, error, session),
							$author$project$Rollbar$apiError(error));
					}
				case 8:
					if (!msg.a.$) {
						var cart = msg.a.a;
						return _Utils_Tuple2(
							session,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($author$project$Session$storeCart, session, cart)
									])));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							A2($author$project$Session$addError, error, session),
							$author$project$Rollbar$apiError(error));
					}
				case 9:
					return _Utils_Tuple2(
						session,
						$author$project$Cart$delete($author$project$Session$CompletedClearedCart));
				case 10:
					if (!msg.a.$) {
						return _Utils_Tuple2(
							session,
							A2($author$project$Session$storeCart, session, $author$project$Cart$empty));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							A2($author$project$Session$addError, error, session),
							$author$project$Rollbar$apiError(error));
					}
				case 6:
					var minimal = msg.a;
					var source = msg.b;
					var amountString = msg.c;
					var _v2 = $elm$core$String$toFloat(amountString);
					if (_v2.$ === 1) {
						return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
					} else {
						var amount = _v2.a;
						var currentAmount = A2(
							$elm$core$Maybe$withDefault,
							0,
							A2(
								$elm$core$Maybe$map,
								$author$project$Cart$amount,
								A2(
									$elm$core$Maybe$andThen,
									$author$project$Cart$findRow(minimal.hx),
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.gT;
										},
										$author$project$Session$user(session)))));
						var newAmount = amount - currentAmount;
						var trackCmd = (newAmount > 0.1) ? $author$project$Analytics$trackAddToCart(
							{b2: minimal, fW: newAmount, ge: $author$project$Analytics$Checkout}) : $author$project$Analytics$trackRemoveFromCart(
							{
								b2: minimal,
								fW: $elm$core$Basics$abs(newAmount),
								ge: $author$project$Analytics$Checkout
							});
						return (amount < 0.1) ? _Utils_Tuple2(
							session,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A4(
										$author$project$Cart$update,
										$author$project$Session$CompletedLoadCart(
											{
												cB: $author$project$ExternalId$toString(minimal.hh) + ' har tagits bort from varukorgen',
												fx: $rtfeldman$elm_css$Html$Styled$text(minimal.hT)
											}),
										minimal.hx,
										source,
										0),
										trackCmd
									]))) : _Utils_Tuple2(
							session,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A4(
										$author$project$Cart$update,
										$author$project$Session$CompletedLoadCart(
											{
												cB: 'Antalet ' + ($author$project$ExternalId$toString(minimal.hh) + (' har uppdaterats till ' + $elm$core$String$fromFloat(amount))),
												fx: $rtfeldman$elm_css$Html$Styled$text(minimal.hT)
											}),
										minimal.hx,
										source,
										amount),
										trackCmd
									])));
					}
				case 11:
					var subMsg = msg.a;
					var _v3 = A3(
						$specialelektronik$elm_autocomplete$Autocomplete$update,
						$author$project$Session$autocompleteConfig,
						subMsg,
						$author$project$Session$autocomplete(session));
					var newAutocomplete = _v3.hX;
					var maybeMsg = _v3.dL;
					var cmd = _v3.gY;
					var newSession = A2(
						$author$project$Session$mapUI,
						function (ui) {
							return _Utils_update(
								ui,
								{dk: newAutocomplete});
						},
						session);
					if (maybeMsg.$ === 1) {
						return _Utils_Tuple2(newSession, cmd);
					} else {
						var msg_ = maybeMsg.a;
						var $temp$msg = msg_,
							$temp$session = newSession;
						msg = $temp$msg;
						session = $temp$session;
						continue update;
					}
				case 12:
					var subMsg = msg.a;
					var newSession = A2(
						$author$project$Session$mapUI,
						function (ui) {
							return _Utils_update(
								ui,
								{
									bX: A2(
										$author$project$SE$UI$Navbar$V2$update,
										subMsg,
										$author$project$Session$navbar(session))
								});
						},
						session);
					return _Utils_Tuple2(newSession, $elm$core$Platform$Cmd$none);
				case 13:
					var vat = msg.a;
					return _Utils_Tuple2(
						session,
						A2($author$project$User$Settings$updateVat, $author$project$Session$CompletedUpdatedSettings, vat));
				case 14:
					var layout_ = msg.a;
					if (!session.$) {
						return _Utils_Tuple2(
							session,
							A2($author$project$User$Settings$updateLayout, $author$project$Session$CompletedUpdatedSettings, layout_));
					} else {
						var key = session.a;
						var ui = session.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Session$Guest,
								key,
								_Utils_update(
									ui,
									{hK: layout_})),
							$author$project$Session$storeLayout(layout_));
					}
				case 15:
					var orderByString = msg.a;
					var _v6 = $author$project$OrderBy$fromString(orderByString);
					if (_v6.$ === 1) {
						return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
					} else {
						var orderBy_ = _v6.a;
						if (!session.$) {
							return _Utils_Tuple2(
								session,
								A2($author$project$User$Settings$updateOrderBy, $author$project$Session$CompletedUpdatedSettings, orderBy_));
						} else {
							var key = session.a;
							var ui = session.b;
							return _Utils_Tuple2(
								A2(
									$author$project$Session$Guest,
									key,
									_Utils_update(
										ui,
										{ia: orderBy_})),
								$author$project$Session$storeOrderBy(orderBy_));
						}
					}
				case 16:
					var scope = msg.a;
					if (!session.$) {
						return _Utils_Tuple2(
							session,
							A2($author$project$User$Settings$updateDocumentScope, $author$project$Session$CompletedUpdatedSettings, scope));
					} else {
						var key = session.a;
						var ui = session.b;
						return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
					}
				case 17:
					if (!session.$) {
						var user_ = session.b;
						return _Utils_Tuple2(
							session,
							A2($author$project$User$Settings$updateShowDiscount, $author$project$Session$CompletedUpdatedSettings, !user_.c0.ga));
					} else {
						return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
					}
				case 18:
					if (!msg.a.$) {
						var settings = msg.a.a;
						return _Utils_Tuple2(
							session,
							A2($author$project$Session$storeSettings, session, settings));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							A2($author$project$Session$addError, error, session),
							$author$project$Rollbar$apiError(error));
					}
				case 19:
					if (!msg.a) {
						var _v10 = msg.a;
						return _Utils_Tuple2(session, $author$project$Session$heartbeat);
					} else {
						return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
					}
				case 20:
					if (!msg.a.$) {
						return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
					} else {
						if ((msg.a.a.$ === 3) && (msg.a.a.a === 401)) {
							if (!session.$) {
								return _Utils_Tuple2(
									session,
									$author$project$Route$load($author$project$Route$Login));
							} else {
								return _Utils_Tuple2(session, $elm$core$Platform$Cmd$none);
							}
						} else {
							var error = msg.a.a;
							if (!session.$) {
								return _Utils_Tuple2(
									session,
									$author$project$Route$load($author$project$Route$Login));
							} else {
								return _Utils_Tuple2(
									session,
									$author$project$Rollbar$apiError(error));
							}
						}
					}
				case 21:
					var indexToRemove = msg.a;
					var newErrors = A2(
						$author$project$Helpers$removeAt,
						indexToRemove,
						$author$project$Session$userInterface(session).hd);
					return _Utils_Tuple2(
						A2(
							$author$project$Session$mapUI,
							function (ui) {
								return _Utils_update(
									ui,
									{hd: newErrors});
							},
							session),
						$elm$core$Platform$Cmd$none);
				case 22:
					var subMsg = msg.a;
					var _v13 = A3(
						$author$project$SE$UI$Snackbar$update,
						$author$project$Session$snackbarConfig,
						subMsg,
						$author$project$Session$userInterface(session).iG);
					var newSnackbar = _v13.a;
					var cmds = _v13.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$mapUI,
							function (ui) {
								return _Utils_update(
									ui,
									{iG: newSnackbar});
							},
							session),
						cmds);
				default:
					var subMsg = msg.a;
					var _v14 = A2(
						$author$project$Newsletter$update,
						subMsg,
						$author$project$Session$userInterface(session).fB);
					var newNewsletterModel = _v14.a;
					var cmds = _v14.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$mapUI,
							function (ui) {
								return _Utils_update(
									ui,
									{fB: newNewsletterModel});
							},
							session),
						A2($elm$core$Platform$Cmd$map, $author$project$Session$GotNewsletterMsg, cmds));
			}
		}
	});
var $author$project$Page$Account$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Account$GotSessionMsg, cmds));
			case 2:
				if (!msg.a.$) {
					var company = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bL: $author$project$Status$Loaded(company)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bL: $author$project$Status$Failed(error),
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			default:
				if (!msg.a.$) {
					var administrators = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bB: $author$project$Status$Loaded(administrators)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bB: $author$project$Status$Failed(error),
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
		}
	});
var $author$project$Page$Account$Administration$CompletedAddUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Account$Administration$CompletedChangeRole = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Account$Administration$CompletedDeleteUser = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Account$Administration$Sending = function (a) {
	return {$: 1, a: a};
};
var $author$project$User$add = F2(
	function (msg, email) {
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$users,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(email))
					])),
			$author$project$User$minimalDecoder,
			msg);
	});
var $author$project$Api$Endpoint$user = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'users',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id))
			]),
		_List_Nil);
};
var $author$project$User$delete = F2(
	function (msg, userId) {
		return A2(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$user(userId),
			msg);
	});
var $author$project$Role$toLabel = function (role) {
	if (!role) {
		return 'Medarbetare';
	} else {
		return 'Administratör';
	}
};
var $author$project$Api$Endpoint$userRole = function (id) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'users',
				$elm$core$String$fromInt(
				$author$project$Id$toInt(id)),
				'role'
			]),
		_List_Nil);
};
var $author$project$User$updateRole = F3(
	function (msg, userId, newRole) {
		return A4(
			$author$project$Api$put,
			$author$project$Api$Endpoint$userRole(userId),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'role',
						$author$project$Role$encode(newRole))
					])),
			$author$project$User$minimalDecoder,
			msg);
	});
var $author$project$Status$Reloading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Status$map = F2(
	function (fn, status) {
		switch (status.$) {
			case 1:
				var value = status.a;
				return $author$project$Status$Reloading(
					fn(value));
			case 2:
				var value = status.a;
				return $author$project$Status$Loaded(
					fn(value));
			case 0:
				return $author$project$Status$Loading;
			default:
				var error = status.a;
				return $author$project$Status$Failed(error);
		}
	});
var $author$project$Page$Account$Administration$updateStatus = function (user) {
	return $author$project$Status$map(
		$elm$core$List$map(
			function (u) {
				return _Utils_eq(u.hx, user.hx) ? user : u;
			}));
};
var $author$project$Page$Account$Administration$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var newEmail = msg.a;
				var _v1 = model.U;
				if (!_v1.$) {
					var oldErrorMsg = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								U: A2($author$project$Page$Account$Administration$Editing, newEmail, oldErrorMsg)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var _v2 = model.U;
				if (!_v2.$) {
					var email = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								U: $author$project$Page$Account$Administration$Sending(email)
							}),
						A2($author$project$User$add, $author$project$Page$Account$Administration$CompletedAddUser, email));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!msg.a.$) {
					var user = msg.a.a;
					var _v3 = model.ar;
					if (_v3.$ === 2) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fx: user.be + ' har lagts till!',
									U: A2($author$project$Page$Account$Administration$Editing, '', '')
								}),
							$author$project$User$list($author$project$Page$Account$Administration$CompletedLoadUsers));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					if (msg.a.a.$ === 5) {
						var problem = msg.a.a.a;
						var _v4 = model.U;
						if (!_v4.$) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							var currentEmail = _v4.a;
							var errorMessage = A2(
								$elm$core$Maybe$withDefault,
								problem.fx,
								A2(
									$elm$core$Maybe$andThen,
									$elm$core$List$head,
									A2($elm$core$Dict$get, 'email', problem.hd)));
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										U: A2($author$project$Page$Account$Administration$Editing, currentEmail, errorMessage),
										K: A2(
											$author$project$Session$addError,
											$author$project$Api$ValidationError(problem),
											model.K)
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						var error = msg.a.a;
						var _v5 = model.U;
						if (!_v5.$) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							var currentEmail = _v5.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										U: A2($author$project$Page$Account$Administration$Editing, currentEmail, 'Något blev fel när den nya användare skulle skapas.'),
										K: A2($author$project$Session$addError, error, model.K)
									}),
								$author$project$Rollbar$apiError(error));
						}
					}
				}
			case 1:
				if (!msg.a.$) {
					var users = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $author$project$Status$Loaded(users)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 4:
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aW: $elm$core$Maybe$Just(user)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aW: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var user = msg.a;
				var _v6 = model.ar;
				if (_v6.$ === 2) {
					return _Utils_Tuple2(
						model,
						A2($author$project$User$delete, $author$project$Page$Account$Administration$CompletedDeleteUser, user.hx));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (!msg.a.$) {
					var _v7 = model.ar;
					if (_v7.$ === 2) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aW: $elm$core$Maybe$Nothing, fx: 'Användaren har tagits bort.'}),
							$author$project$User$list($author$project$Page$Account$Administration$CompletedLoadUsers));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aW: $elm$core$Maybe$Nothing,
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 8:
				var user = msg.a;
				var newRole = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$User$updateRole, $author$project$Page$Account$Administration$CompletedChangeRole, user.hx, newRole));
			case 9:
				if (!msg.a.$) {
					var user = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fx: user.ho + (' ' + (user.hI + (' har ändrats till ' + $author$project$Role$toLabel(user.it)))),
								ar: A2($author$project$Page$Account$Administration$updateStatus, user, model.ar)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bo: ''}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var string = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bo: _Utils_eq(string, model.bo) ? '' : string
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			default:
				var subMsg = msg.a;
				var _v8 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v8.a;
				var cmds = _v8.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Account$Administration$GotSessionMsg, cmds));
		}
	});
var $author$project$Page$Account$Password$Invalid = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Page$Account$Password$extractError = F2(
	function (problem, key) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Dict$get, key, problem.hd));
	});
var $author$project$Page$Account$Password$apiProblemToErrors = function (problem) {
	return {
		S: A2($author$project$Page$Account$Password$extractError, problem, 'current_password'),
		N: A2($author$project$Page$Account$Password$extractError, problem, 'new_password'),
		ak: _List_Nil
	};
};
var $author$project$Page$Account$Password$CompletedSavedPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Account$Password$Sending = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Account$Password$noErrors = {S: _List_Nil, N: _List_Nil, ak: _List_Nil};
var $author$project$User$encodePassword = function (rec) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_password',
				$elm$json$Json$Encode$string(rec.S)),
				_Utils_Tuple2(
				'new_password',
				$elm$json$Json$Encode$string(rec.N))
			]));
};
var $author$project$Api$Endpoint$mePassword = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['me', 'password']),
	_List_Nil);
var $author$project$User$postPassword = F2(
	function (msg, rec) {
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$mePassword,
			$author$project$User$encodePassword(rec),
			$elm$json$Json$Decode$succeed(0),
			msg);
	});
var $author$project$Form$isMin = F2(
	function (min, v) {
		return (_Utils_cmp(
			$elm$core$String$length(v),
			min) > -1) ? '' : ('Minst ' + ($elm$core$String$fromInt(min) + ' tecken.'));
	});
var $author$project$Form$isRequired = function (v) {
	return (!$elm$core$String$isEmpty(v)) ? '' : 'Vänligen fyll i detta fält.';
};
var $author$project$Form$isSame = F3(
	function (label, first, second) {
		return _Utils_eq(first, second) ? '' : ('Fältet måste vara samma som ' + label);
	});
var $author$project$Page$Account$Password$validateString = F2(
	function (value, rules) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			A2(
				$elm$core$List$map,
				function (rule) {
					return rule(value);
				},
				rules));
	});
var $author$project$Page$Account$Password$validate = function (inputs) {
	var newErrors = {
		S: A2(
			$author$project$Page$Account$Password$validateString,
			inputs.S,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					$author$project$Form$isMin(8)
				])),
		N: A2(
			$author$project$Page$Account$Password$validateString,
			inputs.N,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					$author$project$Form$isMin(8)
				])),
		ak: A2(
			$author$project$Page$Account$Password$validateString,
			inputs.ak,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					A2($author$project$Form$isSame, 'lösenord', inputs.N)
				]))
	};
	return _Utils_eq(newErrors, $author$project$Page$Account$Password$noErrors) ? _Utils_Tuple2('', $author$project$Page$Account$Password$noErrors) : _Utils_Tuple2('Något blev fel med formuläret.', newErrors);
};
var $author$project$Page$Account$Password$send = function (inputs) {
	var _v0 = $author$project$Page$Account$Password$validate(inputs);
	var errorMsg = _v0.a;
	var newErrors = _v0.b;
	return _Utils_eq(newErrors, $author$project$Page$Account$Password$noErrors) ? _Utils_Tuple2(
		$author$project$Page$Account$Password$Sending(inputs),
		A2(
			$author$project$User$postPassword,
			$author$project$Page$Account$Password$CompletedSavedPassword,
			{S: inputs.S, N: inputs.N})) : _Utils_Tuple2(
		A3($author$project$Page$Account$Password$Invalid, inputs, newErrors, errorMsg),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Account$Password$Valid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Account$Password$updateForm = F2(
	function (msg, status) {
		var _v0 = _Utils_Tuple2(msg, status);
		switch (_v0.b.$) {
			case 3:
				var inputs = _v0.b.a;
				return $author$project$Page$Account$Password$Sending(inputs);
			case 0:
				var _v1 = _v0.a;
				var updateFn = _v1.a;
				var value = _v1.b;
				var inputs = _v0.b.a;
				return $author$project$Page$Account$Password$NotValidated(
					A2(updateFn, value, inputs));
			case 1:
				var _v2 = _v0.a;
				var updateFn = _v2.a;
				var value = _v2.b;
				var _v3 = _v0.b;
				var inputs = _v3.a;
				var newValues = A2(updateFn, value, inputs);
				var _v4 = $author$project$Page$Account$Password$validate(newValues);
				var errorMsg = _v4.a;
				var newErrors = _v4.b;
				var newStatus = $elm$core$String$isEmpty(errorMsg) ? $author$project$Page$Account$Password$Valid(newValues) : A3($author$project$Page$Account$Password$Invalid, newValues, newErrors, errorMsg);
				return newStatus;
			default:
				var _v5 = _v0.a;
				var updateFn = _v5.a;
				var value = _v5.b;
				var inputs = _v0.b.a;
				var newValues = A2(updateFn, value, inputs);
				var _v6 = $author$project$Page$Account$Password$validate(newValues);
				var errorMsg = _v6.a;
				var newErrors = _v6.b;
				var newStatus = $elm$core$String$isEmpty(errorMsg) ? $author$project$Page$Account$Password$Valid(newValues) : A3($author$project$Page$Account$Password$Invalid, newValues, newErrors, errorMsg);
				return newStatus;
		}
	});
var $author$project$Page$Account$Password$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gh: A2($author$project$Page$Account$Password$updateForm, formMsg, model.gh)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var newStatus = function () {
						var _v1 = model.gh;
						if (_v1.$ === 3) {
							return $author$project$Page$Account$Password$NotValidated($author$project$Page$Account$Password$emptyInputs);
						} else {
							var other = _v1;
							return other;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fx: 'Tack! Ditt lösenord är uppdaterat.', gh: newStatus}),
						$elm$core$Platform$Cmd$none);
				} else {
					if (msg.a.a.$ === 5) {
						var problem = msg.a.a.a;
						var newStatus = function () {
							var _v2 = model.gh;
							if (_v2.$ === 3) {
								var inputs = _v2.a;
								return A3(
									$author$project$Page$Account$Password$Invalid,
									inputs,
									$author$project$Page$Account$Password$apiProblemToErrors(problem),
									problem.fx);
							} else {
								var other = _v2;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{gh: newStatus}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2($author$project$Session$addError, error, model.K)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
			case 1:
				var _v3 = model.gh;
				switch (_v3.$) {
					case 0:
						var inputs = _v3.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							$author$project$Page$Account$Password$send(inputs));
					case 1:
						var inputs = _v3.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							$author$project$Page$Account$Password$send(inputs));
					case 2:
						var inputs = _v3.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							$author$project$Page$Account$Password$send(inputs));
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			default:
				var subMsg = msg.a;
				var _v4 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v4.a;
				var cmds = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Account$Password$GotSessionMsg, cmds));
		}
	});
var $author$project$Page$Account$Profile$CompletedLoadProfile = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Account$Profile$Editing = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Page$Account$Profile$FailedLoading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Account$Profile$extractError = F2(
	function (problem, key) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Dict$get, key, problem.hd));
	});
var $author$project$Page$Account$Profile$apiProblemToErrors = function (problem) {
	return {
		Z: A2($author$project$Page$Account$Profile$extractError, problem, 'city'),
		au: A2($author$project$Page$Account$Profile$extractError, problem, 'country_code'),
		_: A2($author$project$Page$Account$Profile$extractError, problem, 'first_name'),
		ab: A2($author$project$Page$Account$Profile$extractError, problem, 'last_name'),
		ac: A2($author$project$Page$Account$Profile$extractError, problem, 'mobile'),
		ad: A2($author$project$Page$Account$Profile$extractError, problem, 'phone'),
		af: A2($author$project$Page$Account$Profile$extractError, problem, 'street_address'),
		ah: A2($author$project$Page$Account$Profile$extractError, problem, 'zip')
	};
};
var $author$project$NewsletterList$externalId = function (_v0) {
	var internals = _v0;
	return internals.hh;
};
var $author$project$User$Newsletters$hasDoubleOptIn = function (_v0) {
	var internals = _v0;
	return internals.e6;
};
var $author$project$NewsletterList$isSubscribed = function (_v0) {
	var internals = _v0;
	return internals.fm;
};
var $author$project$User$Newsletters$lists = function (_v0) {
	var internals = _v0;
	return internals.a0;
};
var $author$project$Status$loading = function (status) {
	switch (status.$) {
		case 1:
			var a = status.a;
			return $author$project$Status$Reloading(a);
		case 2:
			var a = status.a;
			return $author$project$Status$Reloading(a);
		default:
			return $author$project$Status$Loading;
	}
};
var $author$project$User$Profile$Profile = function (id) {
	return function (first_name) {
		return function (last_name) {
			return function (email) {
				return function (phone) {
					return function (mobile) {
						return function (street_address) {
							return function (zip) {
								return function (city) {
									return function (country) {
										return {Z: city, g_: country, be: email, _: first_name, hx: id, ab: last_name, ac: mobile, ad: phone, af: street_address, ah: zip};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$User$Profile$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'country',
	$author$project$Country$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'city',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'zip',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'street_address',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'mobile',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'phone',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'email',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'last_name',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'first_name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$author$project$Id$decoder,
										$elm$json$Json$Decode$succeed($author$project$User$Profile$Profile)))))))))));
var $author$project$Api$Endpoint$profile = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['me', 'profile']),
	_List_Nil);
var $author$project$User$Profile$my = function (msg) {
	return A3($author$project$Api$get, $author$project$Api$Endpoint$profile, $author$project$User$Profile$decoder, msg);
};
var $author$project$Page$Account$Profile$noErrors = {Z: _List_Nil, au: _List_Nil, _: _List_Nil, ab: _List_Nil, ac: _List_Nil, ad: _List_Nil, af: _List_Nil, ah: _List_Nil};
var $author$project$User$Newsletters$post = F4(
	function (msg, userId, externalId, subscribe) {
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$meNewsletters,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'external_id',
						$elm$json$Json$Encode$int(externalId)),
						_Utils_Tuple2(
						'subscribe',
						$elm$json$Json$Encode$bool(subscribe))
					])),
			$author$project$User$Newsletters$decoder,
			msg);
	});
var $author$project$Country$code = function (_v0) {
	var internals = _v0;
	return internals.eI;
};
var $author$project$Page$Account$Profile$profileToInputs = function (profile) {
	return {
		Z: profile.Z,
		au: $author$project$Country$codeToString(
			$author$project$Country$code(profile.g_)),
		_: profile._,
		ab: profile.ab,
		ac: profile.ac,
		ad: profile.ad,
		af: profile.af,
		ah: profile.ah
	};
};
var $author$project$Page$Account$Profile$CompletedSavedProfile = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Account$Profile$Sending = function (a) {
	return {$: 3, a: a};
};
var $author$project$User$Profile$encode = F2(
	function (userId, rec) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(
						$author$project$Id$toInt(userId))),
					_Utils_Tuple2(
					'first_name',
					$elm$json$Json$Encode$string(rec._)),
					_Utils_Tuple2(
					'last_name',
					$elm$json$Json$Encode$string(rec.ab)),
					_Utils_Tuple2(
					'phone',
					$elm$json$Json$Encode$string(rec.ad)),
					_Utils_Tuple2(
					'mobile',
					$elm$json$Json$Encode$string(rec.ac)),
					_Utils_Tuple2(
					'street_address',
					$elm$json$Json$Encode$string(rec.af)),
					_Utils_Tuple2(
					'zip',
					$elm$json$Json$Encode$string(rec.ah)),
					_Utils_Tuple2(
					'city',
					$elm$json$Json$Encode$string(rec.Z)),
					_Utils_Tuple2(
					'country_code',
					$elm$json$Json$Encode$string(rec.au))
				]));
	});
var $author$project$User$Profile$post = F3(
	function (msg, userId, values) {
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$profile,
			A2($author$project$User$Profile$encode, userId, values),
			$elm$json$Json$Decode$succeed(0),
			msg);
	});
var $author$project$Form$isMax = F2(
	function (max, v) {
		return (_Utils_cmp(
			$elm$core$String$length(v),
			max) < 1) ? '' : ('Max ' + ($elm$core$String$fromInt(max) + ' tecken.'));
	});
var $author$project$Page$Account$Profile$validateString = F2(
	function (value, rules) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			A2(
				$elm$core$List$map,
				function (rule) {
					return rule(value);
				},
				rules));
	});
var $author$project$Page$Account$Profile$validate = function (inputs) {
	var newErrors = {
		Z: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs.Z,
			_List_fromArray(
				[
					$author$project$Form$isMax(60)
				])),
		au: _List_Nil,
		_: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs._,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					$author$project$Form$isMax(20)
				])),
		ab: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs.ab,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					$author$project$Form$isMax(20)
				])),
		ac: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs.ac,
			_List_fromArray(
				[
					$author$project$Form$isMax(25)
				])),
		ad: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs.ad,
			_List_fromArray(
				[
					$author$project$Form$isMax(25)
				])),
		af: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs.af,
			_List_fromArray(
				[
					$author$project$Form$isMax(80)
				])),
		ah: A2(
			$author$project$Page$Account$Profile$validateString,
			inputs.ah,
			_List_fromArray(
				[
					$author$project$Form$isMax(20)
				]))
	};
	return _Utils_eq(newErrors, $author$project$Page$Account$Profile$noErrors) ? _Utils_Tuple2('', $author$project$Page$Account$Profile$noErrors) : _Utils_Tuple2('Något blev fel med formuläret.', newErrors);
};
var $author$project$Page$Account$Profile$send = F2(
	function (userId, inputs) {
		var _v0 = $author$project$Page$Account$Profile$validate(inputs);
		var errorMsg = _v0.a;
		var newErrors = _v0.b;
		return _Utils_eq(newErrors, $author$project$Page$Account$Profile$noErrors) ? _Utils_Tuple2(
			$author$project$Page$Account$Profile$Sending(inputs),
			A3($author$project$User$Profile$post, $author$project$Page$Account$Profile$CompletedSavedProfile, userId, inputs)) : _Utils_Tuple2(
			A3($author$project$Page$Account$Profile$Editing, inputs, newErrors, errorMsg),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Status$toMaybe = function (status) {
	switch (status.$) {
		case 1:
			var a = status.a;
			return $elm$core$Maybe$Just(a);
		case 2:
			var a = status.a;
			return $elm$core$Maybe$Just(a);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Account$Profile$updateForm = F2(
	function (msg, status) {
		if (status.$ === 2) {
			var inputs = status.a;
			var updateFn = msg.a;
			var value = msg.b;
			var newValues = A2(updateFn, value, inputs);
			var _v2 = $author$project$Page$Account$Profile$validate(newValues);
			var errorMsg = _v2.a;
			var newErrors = _v2.b;
			return A3($author$project$Page$Account$Profile$Editing, newValues, newErrors, errorMsg);
		} else {
			var other = status;
			return other;
		}
	});
var $author$project$Api$Endpoint$meNewslettersWhitelist = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['me', 'newsletters', 'whitelist']),
	_List_Nil);
var $author$project$User$Newsletters$whitelist = function (msg) {
	return A4(
		$author$project$Api$post,
		$author$project$Api$Endpoint$meNewslettersWhitelist,
		$elm$json$Json$Encode$object(_List_Nil),
		$author$project$User$Newsletters$decoder,
		msg);
};
var $author$project$Page$Account$Profile$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							C: A2($author$project$Page$Account$Profile$updateForm, formMsg, model.C)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				if (!msg.a.$) {
					var countries = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cr: countries}),
						$author$project$User$Profile$my($author$project$Page$Account$Profile$CompletedLoadProfile));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: $author$project$Page$Account$Profile$FailedLoading('Det gick inte att ladda in länder.'),
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 5:
				if (!msg.b.$) {
					var message = msg.a;
					var newsletters = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								al: $author$project$Status$Loaded(newsletters),
								cL: message
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								al: $author$project$Status$Failed('Det gick inte att hämta dina prenumerationer.'),
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 3:
				if (!msg.a.$) {
					var profile = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: A3(
									$author$project$Page$Account$Profile$Editing,
									$author$project$Page$Account$Profile$profileToInputs(profile),
									$author$project$Page$Account$Profile$noErrors,
									'')
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: $author$project$Page$Account$Profile$FailedLoading('Det gick inte att ladda profilen.'),
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 2:
				if (!msg.a.$) {
					var newStatus = function () {
						var _v1 = model.C;
						if (_v1.$ === 3) {
							var inputs = _v1.a;
							return A3($author$project$Page$Account$Profile$Editing, inputs, $author$project$Page$Account$Profile$noErrors, '');
						} else {
							var other = _v1;
							return other;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{C: newStatus, fx: 'Tack! Din profil är sparad.'}),
						$elm$core$Platform$Cmd$none);
				} else {
					if (msg.a.a.$ === 5) {
						var problem = msg.a.a.a;
						var newStatus = function () {
							var _v2 = model.C;
							if (_v2.$ === 3) {
								var inputs = _v2.a;
								return A3(
									$author$project$Page$Account$Profile$Editing,
									inputs,
									$author$project$Page$Account$Profile$apiProblemToErrors(problem),
									problem.fx);
							} else {
								var other = _v2;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{C: newStatus}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						var newStatus = function () {
							var _v3 = model.C;
							if (_v3.$ === 3) {
								var inputs = _v3.a;
								return A3($author$project$Page$Account$Profile$Editing, inputs, $author$project$Page$Account$Profile$noErrors, '');
							} else {
								var other = _v3;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									C: newStatus,
									K: A2($author$project$Session$addError, error, model.K)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
			case 1:
				var _v4 = _Utils_Tuple2(
					$author$project$Session$userId(model.K),
					model.C);
				if ((!_v4.a.$) && (_v4.b.$ === 2)) {
					var userId = _v4.a.a;
					var _v5 = _v4.b;
					var inputs = _v5.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (status) {
							return _Utils_update(
								model,
								{C: status});
						},
						A2($author$project$Page$Account$Profile$send, userId, inputs));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var externalId = msg.a;
				var _v6 = _Utils_Tuple2(
					$author$project$Session$userId(model.K),
					$author$project$Status$toMaybe(model.al));
				if ((!_v6.a.$) && (!_v6.b.$)) {
					var userId = _v6.a.a;
					var newsletters = _v6.b.a;
					var message = (!$author$project$User$Newsletters$hasDoubleOptIn(newsletters)) ? 'Din prenumeration börjar gälla först när du bekräftat den via email.' : '';
					var maybeSubscribed = A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $author$project$NewsletterList$isSubscribed, $elm$core$Basics$not),
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								A2(
									$elm$core$Basics$composeR,
									$author$project$NewsletterList$externalId,
									$elm$core$Basics$eq(externalId)),
								$author$project$User$Newsletters$lists(newsletters))));
					if (!maybeSubscribed.$) {
						var subscribed = maybeSubscribed.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									al: $author$project$Status$loading(model.al)
								}),
							A4(
								$author$project$User$Newsletters$post,
								$author$project$Page$Account$Profile$CompletedLoadNewsletters(message),
								userId,
								externalId,
								subscribed));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							al: $author$project$Status$loading(model.al)
						}),
					$author$project$User$Newsletters$whitelist(
						$author$project$Page$Account$Profile$CompletedLoadNewsletters('')));
			case 8:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			default:
				var subMsg = msg.a;
				var _v8 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v8.a;
				var cmds = _v8.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Account$Profile$GotSessionMsg, cmds));
		}
	});
var $author$project$Page$Account$Settings$update = F2(
	function (msg, model) {
		if (!msg.$) {
			if (!msg.a.$) {
				var session = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: session}),
					$elm$core$Platform$Cmd$none);
			} else {
				var decodeError = msg.a.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (session) {
						return _Utils_update(
							model,
							{K: session});
					},
					A2($author$project$Session$addDecodeError, decodeError, model.K));
			}
		} else {
			var subMsg = msg.a;
			var _v1 = A2($author$project$Session$update, subMsg, model.K);
			var newSession = _v1.a;
			var cmds = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{K: newSession}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Account$Settings$GotSessionMsg, cmds));
		}
	});
var $author$project$BidRow$id = function (_v0) {
	var internals = _v0.a;
	return internals.hx;
};
var $author$project$BidRow$orderableQuantity = function (_v0) {
	var internals = _v0.a;
	return internals.fW - internals.eo;
};
var $author$project$Amount$update = F2(
	function (_v0, amounts) {
		var id = _v0.a;
		var newAmount = _v0.b;
		return A2(
			$elm$core$List$cons,
			_Utils_Tuple2(id, newAmount),
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var id_ = _v1.a;
					return !A2($author$project$Id$equals, id, id_);
				},
				amounts));
	});
var $author$project$Page$Bid$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Bid$GotSessionMsg, cmds));
			case 2:
				if (!msg.a.$) {
					var bid = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aF: $author$project$Status$Loaded(bid)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 404)) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2(
										$author$project$Session$addError,
										$author$project$Api$BadBody('Bid finns inte'),
										model.K)
								}),
							A2(
								$author$project$Route$pushUrl,
								$author$project$Session$navKey(model.K),
								$author$project$Route$Bids));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aF: $author$project$Status$Failed(error)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
			default:
				var row = msg.a;
				var amountString = msg.b;
				var _v2 = $elm$core$String$toFloat(amountString);
				if (_v2.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var amount = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bE: A2(
									$author$project$Amount$update,
									_Utils_Tuple2(
										$author$project$BidRow$id(row),
										A3(
											$elm$core$Basics$clamp,
											1,
											$author$project$BidRow$orderableQuantity(row),
											amount)),
									model.bE)
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Bids$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 2:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Bids$GotSessionMsg, cmds));
			case 3:
				if (!msg.a.$) {
					var collection = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$Loaded(collection)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 5:
				if (msg.a === '') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$loading(model.R),
								d0: ''
							}),
						$author$project$Page$Bids$loadBids($author$project$Bid$defaultQueryParams));
				} else {
					var q = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d0: q}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: $author$project$Status$loading(model.R)
						}),
					$author$project$Page$Bids$loadBids(
						_Utils_update(
							$author$project$Bid$defaultQueryParams,
							{d0: model.d0})));
			case 7:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: $author$project$Status$loading(model.R)
						}),
					$author$project$Page$Bids$loadBids(
						_Utils_update(
							$author$project$Bid$defaultQueryParams,
							{dW: page, d0: model.d0})));
			case 4:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{el: time}),
					$elm$core$Platform$Cmd$none);
			default:
				var modal = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cH: modal}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Brands$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Brands$GotSessionMsg, cmds));
			default:
				if (!msg.a.$) {
					var brands = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bK: $author$project$Status$Loaded(brands)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bK: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
		}
	});
var $author$project$Session$AddedToCart = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$Checkout$ClickedPickupDeliveryAddress = {$: 6};
var $author$project$Page$Checkout$ClickedUnselectDeliveryAddress = {$: 3};
var $author$project$Page$Checkout$CompletedLookupProduct = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Checkout$GotQuickAddMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Checkout$NoOp = {$: 2};
var $author$project$Analytics$QuickAdd = {$: 9};
var $author$project$Page$Checkout$SelectedSafeDeliveryAddress = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Checkout$Valid = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$Page$Checkout$AutocompleteMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Checkout$ChoseQuickAddProduct = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Checkout$CompletedLoadQuickAddProducts = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Checkout$SubmittedQuickAddProduct = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Endpoint$productsAutocomplete = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['products', 'autocomplete']),
		params);
};
var $author$project$Product$autocomplete = F3(
	function (msg, userId, query) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productsAutocomplete(
				_List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'q', query)
					])),
			$elm$json$Json$Decode$list(
				$author$project$Product$fullDecoder(userId)),
			msg);
	});
var $author$project$Page$Checkout$autocompleteConfig = function (userId) {
	return {
		gX: A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotQuickAddMsg, $author$project$Page$Checkout$ChoseQuickAddProduct),
		hl: A2(
			$author$project$Product$autocomplete,
			A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotQuickAddMsg, $author$project$Page$Checkout$CompletedLoadQuickAddProducts),
			userId),
		hp: $author$project$Page$Checkout$NoOp,
		iK: A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotQuickAddMsg, $author$project$Page$Checkout$SubmittedQuickAddProduct),
		gu: A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotQuickAddMsg, $author$project$Page$Checkout$AutocompleteMsg)
	};
};
var $author$project$Product$internals = function (product) {
	if (!product.$) {
		var ints = product.a;
		return ints;
	} else {
		var ints = product.a;
		return ints;
	}
};
var $author$project$Product$externalId = A2(
	$elm$core$Basics$composeR,
	$author$project$Product$internals,
	function ($) {
		return $.hh;
	});
var $author$project$Page$Checkout$findAddressId = F2(
	function (statusCompany, stringId) {
		var maybeId = $elm$core$String$toInt(stringId);
		var maybeAddresses = function () {
			if (statusCompany.$ === 2) {
				var company = statusCompany.a;
				return $elm$core$Maybe$Just(company.g9);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var _v0 = _Utils_Tuple2(maybeAddresses, maybeId);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var addresses = _v0.a.a;
			var currentId = _v0.b.a;
			return A3(
				$elm$core$List$foldl,
				F2(
					function (adr, carry) {
						if (!adr.$) {
							var id = adr.a;
							return _Utils_eq(
								currentId,
								$author$project$Id$toInt(id)) ? $elm$core$Maybe$Just(id) : carry;
						} else {
							return carry;
						}
					}),
				$elm$core$Maybe$Nothing,
				addresses);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Product$Minimal = F5(
	function (id, externalId, name, brand, categories) {
		return {dp: brand, gV: categories, hh: externalId, hx: id, hT: name};
	});
var $author$project$Product$minimalDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'categories',
	$elm$json$Json$Decode$list($author$project$Category$previewDecoder),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'brand',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'product_code',
				$author$project$ExternalId$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$author$project$Id$decoder,
					$elm$json$Json$Decode$succeed($author$project$Product$Minimal))))));
var $author$project$Api$Endpoint$productLookup = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['products', 'lookup']),
		params);
};
var $author$project$Product$lookup = F2(
	function (msg, externalId_) {
		return A3(
			$author$project$Api$get,
			$author$project$Api$Endpoint$productLookup(
				_List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'product_code', externalId_)
					])),
			$author$project$Product$minimalDecoder,
			msg);
	});
var $author$project$Session$CompletedReloadCart = function (a) {
	return {$: 8, a: a};
};
var $author$project$Cart$get = A2(
	$author$project$Api$get,
	$author$project$Api$Endpoint$cart,
	$author$project$Helpers$dataDecoder($author$project$Cart$decoder));
var $author$project$Session$reloadCart = $author$project$Cart$get($author$project$Session$CompletedReloadCart);
var $author$project$Page$Checkout$CompletedSendOrder = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Checkout$Sending = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Checkout$encodeDeliveryAddress = function (_v0) {
	var delivery_address = _v0.I;
	var row1 = _v0.iv;
	var row2 = _v0.iw;
	var row3 = _v0.ix;
	var reference = _v0.ip;
	switch (delivery_address.$) {
		case 0:
			return _List_Nil;
		case 1:
			var id = delivery_address.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'address_id',
					$elm$json$Json$Encode$int(
						$author$project$Id$toInt(id)))
				]);
		case 3:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'row1',
					$elm$json$Json$Encode$string(row1.iY)),
					_Utils_Tuple2(
					'row2',
					$elm$json$Json$Encode$string(row2.iY)),
					_Utils_Tuple2(
					'row3',
					$elm$json$Json$Encode$string(row3.iY)),
					_Utils_Tuple2(
					'reference',
					$elm$json$Json$Encode$string(reference.iY))
				]);
		default:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'pickup',
					$elm$json$Json$Encode$bool(true))
				]);
	}
};
var $author$project$Page$Checkout$encodeFormData = function (formData) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'your_purchase_order_number',
					$elm$json$Json$Encode$string(formData.ag.iY)),
					_Utils_Tuple2(
					'goods_label',
					$elm$json$Json$Encode$string(formData.aa.iY)),
					_Utils_Tuple2(
					'message',
					$elm$json$Json$Encode$string(formData.fx.iY))
				]),
			$author$project$Page$Checkout$encodeDeliveryAddress(formData)));
};
var $author$project$Order$post = F2(
	function (msg, values) {
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$orders(_List_Nil),
			values,
			$author$project$Order$previewDecoder,
			msg);
	});
var $author$project$Page$Checkout$sendCmd = F2(
	function (msg, formData) {
		return A2(
			$author$project$Order$post,
			msg,
			$author$project$Page$Checkout$encodeFormData(formData));
	});
var $author$project$Page$Checkout$Invalid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Checkout$isResultOk = function (r) {
	if (!r.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Checkout$hasFormErrors = function (formData) {
	return !A3(
		$elm$core$List$foldl,
		A2($elm$core$Basics$composeR, $author$project$Page$Checkout$isResultOk, $elm$core$Basics$and),
		true,
		_List_fromArray(
			[
				formData.ag.hd,
				formData.aa.hd,
				formData.fx.hd,
				formData.iv.hd,
				formData.iw.hd,
				formData.ix.hd,
				formData.ip.hd,
				_Utils_eq(formData.I, $author$project$Page$Checkout$NotSelected) ? $elm$core$Result$Err(
				_List_fromArray(
					[''])) : $elm$core$Result$Ok(0)
			]));
};
var $author$project$Page$Checkout$trimField = function (field) {
	return _Utils_update(
		field,
		{
			iY: $elm$core$String$trim(field.iY)
		});
};
var $author$project$Page$Checkout$trimDeliveryAddress = function (formData) {
	var _v0 = formData.I;
	if (_v0.$ === 3) {
		return _Utils_update(
			formData,
			{
				ip: $author$project$Page$Checkout$trimField(formData.ip),
				iv: $author$project$Page$Checkout$trimField(formData.iv),
				iw: $author$project$Page$Checkout$trimField(formData.iw),
				ix: $author$project$Page$Checkout$trimField(formData.ix)
			});
	} else {
		return formData;
	}
};
var $author$project$Page$Checkout$trim = function (formData) {
	var newDeliveryAddress = $author$project$Page$Checkout$trimDeliveryAddress(formData);
	return _Utils_update(
		newDeliveryAddress,
		{
			aa: $author$project$Page$Checkout$trimField(formData.aa),
			fx: $author$project$Page$Checkout$trimField(formData.fx),
			ag: $author$project$Page$Checkout$trimField(formData.ag)
		});
};
var $author$project$Helpers$combineErrorHelper = F2(
	function (res, carry) {
		var _v0 = _Utils_Tuple2(res, carry);
		if (!_v0.a.$) {
			return carry;
		} else {
			if (!_v0.b.$) {
				var error = _v0.a.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[error]));
			} else {
				var error = _v0.a.a;
				var errors = _v0.b.a;
				return $elm$core$Result$Err(
					A2($elm$core$List$cons, error, errors));
			}
		}
	});
var $author$project$Helpers$combineErrors = F2(
	function (okValue, rs) {
		if (!rs.b) {
			return $elm$core$Result$Ok(okValue);
		} else {
			if (!rs.a.$) {
				var v = rs.a.a;
				return A3(
					$elm$core$List$foldl,
					$author$project$Helpers$combineErrorHelper,
					$elm$core$Result$Ok(v),
					rs);
			} else {
				var error = rs.a.a;
				var tail = rs.b;
				return A3(
					$elm$core$List$foldl,
					$author$project$Helpers$combineErrorHelper,
					$elm$core$Result$Err(
						_List_fromArray(
							[error])),
					tail);
			}
		}
	});
var $author$project$Page$Checkout$validateField = function (field) {
	return _Utils_update(
		field,
		{
			hd: A2(
				$author$project$Helpers$combineErrors,
				0,
				A2(
					$elm$core$List$map,
					function (v) {
						return v(field.iY);
					},
					field.gz))
		});
};
var $author$project$Page$Checkout$validateDeliveryAddress = function (formData) {
	var _v0 = formData.I;
	if (_v0.$ === 3) {
		return _Utils_update(
			formData,
			{
				ip: $author$project$Page$Checkout$validateField(formData.ip),
				iv: $author$project$Page$Checkout$validateField(formData.iv),
				iw: $author$project$Page$Checkout$validateField(formData.iw),
				ix: $author$project$Page$Checkout$validateField(formData.ix)
			});
	} else {
		return formData;
	}
};
var $author$project$Page$Checkout$validate = function (formData) {
	var newDeliveryAddress = $author$project$Page$Checkout$validateDeliveryAddress(formData);
	var newData = _Utils_update(
		newDeliveryAddress,
		{
			aa: $author$project$Page$Checkout$validateField(formData.aa),
			fx: $author$project$Page$Checkout$validateField(formData.fx),
			ag: $author$project$Page$Checkout$validateField(formData.ag)
		});
	var _v0 = $author$project$Page$Checkout$hasFormErrors(newData);
	if (_v0) {
		return $author$project$Page$Checkout$Invalid(newData);
	} else {
		return $author$project$Page$Checkout$Valid(
			$author$project$Page$Checkout$trim(newData));
	}
};
var $author$project$Page$Checkout$send = function (form) {
	var validatedForm = function () {
		switch (form.$) {
			case 1:
				var data = form.a;
				return $author$project$Page$Checkout$Valid(data);
			case 3:
				var data = form.a;
				return $author$project$Page$Checkout$Valid(data);
			case 0:
				var data = form.a;
				return $author$project$Page$Checkout$validate(data);
			default:
				var data = form.a;
				return $author$project$Page$Checkout$validate(data);
		}
	}();
	if (validatedForm.$ === 1) {
		var data = validatedForm.a;
		return _Utils_Tuple2(
			$author$project$Page$Checkout$Sending(data),
			A2($author$project$Page$Checkout$sendCmd, $author$project$Page$Checkout$CompletedSendOrder, data));
	} else {
		return _Utils_Tuple2(validatedForm, $elm$core$Platform$Cmd$none);
	}
};
var $specialelektronik$elm_autocomplete$Autocomplete$toView = function (_v0) {
	var internals = _v0;
	return {hA: internals.hA, im: internals.im, iM: internals.iM};
};
var $author$project$Page$Checkout$Custom = {$: 3};
var $author$project$Page$Checkout$Pickup = {$: 2};
var $author$project$Page$Checkout$Selected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Checkout$mapFieldError = F2(
	function (field, res) {
		return _Utils_update(
			field,
			{hd: res});
	});
var $author$project$Page$Checkout$mapFieldValue = F2(
	function (field, value) {
		return _Utils_update(
			field,
			{iY: value});
	});
var $author$project$Page$Checkout$updateFormData = F2(
	function (msg, formdata) {
		switch (msg.$) {
			case 0:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						ag: A2($author$project$Page$Checkout$mapFieldValue, formdata.ag, str)
					});
			case 1:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						aa: A2($author$project$Page$Checkout$mapFieldValue, formdata.aa, str)
					});
			case 2:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						fx: A2($author$project$Page$Checkout$mapFieldValue, formdata.fx, str)
					});
			case 4:
				var id = msg.a;
				return _Utils_update(
					formdata,
					{
						I: $author$project$Page$Checkout$Selected(id),
						ip: A2(
							$author$project$Page$Checkout$mapFieldError,
							formdata.ip,
							$elm$core$Result$Ok(0)),
						iv: A2(
							$author$project$Page$Checkout$mapFieldError,
							formdata.iv,
							$elm$core$Result$Ok(0)),
						iw: A2(
							$author$project$Page$Checkout$mapFieldError,
							formdata.iw,
							$elm$core$Result$Ok(0)),
						ix: A2(
							$author$project$Page$Checkout$mapFieldError,
							formdata.ix,
							$elm$core$Result$Ok(0))
					});
			case 3:
				return _Utils_update(
					formdata,
					{I: $author$project$Page$Checkout$NotSelected});
			case 5:
				return _Utils_update(
					formdata,
					{I: $author$project$Page$Checkout$Custom});
			case 6:
				return _Utils_update(
					formdata,
					{I: $author$project$Page$Checkout$Pickup});
			case 7:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						iv: A2($author$project$Page$Checkout$mapFieldValue, formdata.iv, str)
					});
			case 8:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						iw: A2($author$project$Page$Checkout$mapFieldValue, formdata.iw, str)
					});
			case 9:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						ix: A2($author$project$Page$Checkout$mapFieldValue, formdata.ix, str)
					});
			case 10:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						ip: A2($author$project$Page$Checkout$mapFieldValue, formdata.ip, str)
					});
			default:
				var str = msg.a;
				return _Utils_update(
					formdata,
					{
						g_: A2($author$project$Page$Checkout$mapFieldValue, formdata.g_, str)
					});
		}
	});
var $author$project$Page$Checkout$updateForm = F2(
	function (msg, formdata) {
		switch (formdata.$) {
			case 0:
				var data = formdata.a;
				return $author$project$Page$Checkout$NotValidated(
					A2($author$project$Page$Checkout$updateFormData, msg, data));
			case 1:
				var data = formdata.a;
				return $author$project$Page$Checkout$validate(
					A2($author$project$Page$Checkout$updateFormData, msg, data));
			case 2:
				var data = formdata.a;
				return $author$project$Page$Checkout$validate(
					A2($author$project$Page$Checkout$updateFormData, msg, data));
			default:
				var data = formdata.a;
				return $author$project$Page$Checkout$Sending(data);
		}
	});
var $author$project$Page$Checkout$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v10 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v10.a;
				var cmds = _v10.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Checkout$GotSessionMsg, cmds));
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				if (!msg.a.$) {
					var company = msg.a.a;
					var newForm = company.ic ? A2($author$project$Page$Checkout$updateForm, $author$project$Page$Checkout$ClickedPickupDeliveryAddress, model.C) : model.C;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bL: $author$project$Status$Loaded(company),
								C: newForm
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bL: $author$project$Status$Failed(error),
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 4:
				var stringId = msg.a;
				var maybeSelectedId = A2($author$project$Page$Checkout$findAddressId, model.bL, stringId);
				if (maybeSelectedId.$ === 1) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: A2($author$project$Page$Checkout$updateForm, $author$project$Page$Checkout$ClickedUnselectDeliveryAddress, model.C)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var selectedId = maybeSelectedId.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: A2(
									$author$project$Page$Checkout$updateForm,
									$author$project$Page$Checkout$SelectedSafeDeliveryAddress(selectedId),
									model.C)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							C: A2($author$project$Page$Checkout$updateForm, formMsg, model.C)
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var quickAddMsg = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						A2($author$project$Page$Checkout$updateQuickAdd, quickAddMsg, model),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.hx;
							},
							$author$project$Session$user(model.K))));
			case 6:
				return A2(
					$elm$core$Tuple$mapFirst,
					function (form) {
						return _Utils_update(
							model,
							{C: form});
					},
					$author$project$Page$Checkout$send(model.C));
			case 7:
				if (!msg.a.$) {
					var order = msg.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Route$load(
							$author$project$Route$ThankYou(
								$author$project$Order$internals(order).hx)));
				} else {
					var error = msg.a.a;
					var newForm = function () {
						var _v12 = model.C;
						if (_v12.$ === 3) {
							var data = _v12.a;
							return $author$project$Page$Checkout$Valid(data);
						} else {
							var other = _v12;
							return other;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: newForm,
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Page$Checkout$GotSessionMsg, $author$project$Session$reloadCart),
									$author$project$Rollbar$apiError(error)
								])));
				}
			default:
				if (!msg.a.$) {
					var countries = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cr: $author$project$Status$Loaded(countries)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
		}
	});
var $author$project$Page$Checkout$updateQuickAdd = F3(
	function (msg, model, userId) {
		switch (msg.$) {
			case 2:
				var amount = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aR: A2(
								$elm$core$Maybe$withDefault,
								model.aR,
								$elm$core$String$toFloat(amount))
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				if (!msg.a.$) {
					var products = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								V: A2($specialelektronik$elm_autocomplete$Autocomplete$setSuggestions, products, model.V)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 1:
				var product = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							V: $specialelektronik$elm_autocomplete$Autocomplete$init(
								$author$project$ExternalId$toString(
									$author$project$Product$externalId(product)))
						}),
					A2(
						$elm$core$Task$attempt,
						function (_v1) {
							return $author$project$Page$Checkout$NoOp;
						},
						$elm$browser$Browser$Dom$focus('quick-add-amount')));
			case 6:
				var subMsg = msg.a;
				var _v2 = A3(
					$specialelektronik$elm_autocomplete$Autocomplete$update,
					$author$project$Page$Checkout$autocompleteConfig(userId),
					subMsg,
					model.V);
				var newAutocomplete = _v2.hX;
				var maybeMsg = _v2.dL;
				var cmd = _v2.gY;
				var newModel = _Utils_update(
					model,
					{V: newAutocomplete});
				if (maybeMsg.$ === 1) {
					return _Utils_Tuple2(newModel, cmd);
				} else {
					var msg_ = maybeMsg.a;
					return A2($author$project$Page$Checkout$update, msg_, newModel);
				}
			case 5:
				if (!msg.a.$) {
					var minimal = msg.a.a;
					var _v4 = A2(
						$author$project$Session$update,
						A4($author$project$Session$AddedToCart, minimal, $author$project$Cart$NoSource, model.aR, $author$project$Analytics$QuickAdd),
						model.K);
					var newSession = _v4.a;
					var sessionCmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: 1,
								V: $specialelektronik$elm_autocomplete$Autocomplete$init(''),
								br: '',
								K: newSession
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Page$Checkout$GotSessionMsg, sessionCmd),
									A2(
									$elm$core$Task$attempt,
									function (_v6) {
										return $author$project$Page$Checkout$NoOp;
									},
									A2(
										$elm$core$Task$andThen,
										function (_v5) {
											return $elm$browser$Browser$Dom$focus('quick-add-product-code');
										},
										$elm$core$Process$sleep(200)))
								])));
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 404)) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{br: 'Produkten finns inte, har du stavat rätt?'}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2($author$project$Session$addError, error, model.K)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
			case 3:
				var _v7 = $specialelektronik$elm_autocomplete$Autocomplete$toView(model.V);
				var query = _v7.im;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Product$lookup,
						A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotQuickAddMsg, $author$project$Page$Checkout$CompletedLookupProduct),
						query));
			default:
				var externalId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							V: $specialelektronik$elm_autocomplete$Autocomplete$init(externalId)
						}),
					A2(
						$elm$core$Task$attempt,
						function (_v8) {
							return $author$project$Page$Checkout$NoOp;
						},
						$elm$browser$Browser$Dom$focus('quick-add-amount')));
		}
	});
var $author$project$Page$Content$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Content$GotSessionMsg, cmds));
			default:
				if (!msg.a.$) {
					var content = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eN: $author$project$Status$Loaded(content)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eN: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
		}
	});
var $author$project$Page$Products$View$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Products$View$defaultItem = function (product) {
	return {gH: 0, cj: 1, b2: product};
};
var $author$project$Status$isLoaded = function (status) {
	if (status.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Home$CompletedLoadInvoices = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Home$loadInvoices = A2(
	$author$project$Invoice$list,
	$author$project$Page$Home$CompletedLoadInvoices,
	_Utils_update(
		$author$project$Document$defaultQueryParams,
		{dY: 5}));
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$GotSessionMsg, cmds));
			case 2:
				if (!msg.a.$) {
					var orders = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aP: $author$project$Status$Loaded(orders.g2)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aP: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 3:
				if (!msg.a.$) {
					var invoices = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hE: $author$project$Status$Loaded(invoices.g2)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hE: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 4:
				if (!msg.a.$) {
					if (!msg.a.a.$) {
						var products = msg.a.a.a.ik;
						var newItems = $elm$core$Dict$fromList(
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, product) {
										return _Utils_Tuple2(
											i,
											$author$project$Page$Products$View$Loaded(
												$author$project$Page$Products$View$defaultItem(product)));
									}),
								products));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{hF: newItems}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 7:
				var itemIndex = msg.a;
				var imageIndex = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hF: A3(
								$elm$core$Dict$update,
								itemIndex,
								$elm$core$Maybe$map(
									function (status) {
										if (status.$ === 1) {
											var item = status.a;
											return $author$project$Page$Products$View$Loaded(
												_Utils_update(
													item,
													{gH: imageIndex}));
										} else {
											var other = status;
											return other;
										}
									}),
								model.hF)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				if (!msg.a.$) {
					var categories = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cF: categories}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 6:
				if (!msg.a.$) {
					var articles = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cK: articles}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 8:
				var docType = msg.a;
				var cmd = function () {
					var _v3 = _Utils_Tuple2(
						docType,
						!$author$project$Status$isLoaded(model.hE));
					if ((_v3.a === 1) && _v3.b) {
						var _v4 = _v3.a;
						return $author$project$Page$Home$loadInvoices;
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cu: docType}),
					cmd);
			default:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{el: time}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Invoices$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 2:
				if (msg.a.$ === 16) {
					var subMsg = msg.a;
					var scope = subMsg.a;
					var oldParams = model.E;
					var newParams = _Utils_update(
						oldParams,
						{dW: 1, c$: scope});
					var _v1 = A2($author$project$Session$update, subMsg, model.K);
					var newSession = _v1.a;
					var cmds = _v1.b;
					var newModel = _Utils_update(
						model,
						{
							R: $author$project$Status$loading(model.R),
							E: newParams,
							K: newSession
						});
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Route$replaceUrl,
									$author$project$Session$navKey(newModel.K),
									$author$project$Page$Invoices$toRoute(newModel)),
									A2($elm$core$Platform$Cmd$map, $author$project$Page$Invoices$GotSessionMsg, cmds)
								])));
				} else {
					var subMsg = msg.a;
					var _v2 = A2($author$project$Session$update, subMsg, model.K);
					var newSession = _v2.a;
					var cmds = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: newSession}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Invoices$GotSessionMsg, cmds));
				}
			case 3:
				if (!msg.a.$) {
					var collection = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$Loaded(collection)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 5:
				var q = msg.a;
				var _v3 = $elm$core$String$trim(q);
				if (_v3 === '') {
					var oldParams = model.E;
					var newParams = _Utils_update(
						oldParams,
						{dW: 1, d0: ''});
					var newModel = _Utils_update(
						model,
						{E: newParams});
					return _Utils_Tuple2(
						newModel,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(newModel.K),
							$author$project$Page$Invoices$toRoute(newModel)));
				} else {
					var trimmedQ = _v3;
					var oldParams = model.E;
					var newParams = _Utils_update(
						oldParams,
						{d0: trimmedQ});
					var newModel = _Utils_update(
						model,
						{E: newParams});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var oldParams = model.E;
				var newParams = _Utils_update(
					oldParams,
					{dW: 1});
				var newModel = _Utils_update(
					model,
					{
						R: $author$project$Status$loading(model.R),
						E: newParams
					});
				return _Utils_Tuple2(
					newModel,
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(newModel.K),
						$author$project$Page$Invoices$toRoute(newModel)));
			case 7:
				var page = msg.a;
				var oldParams = model.E;
				var newParams = _Utils_update(
					oldParams,
					{dW: page});
				var newModel = _Utils_update(
					model,
					{
						R: $author$project$Status$loading(model.R),
						E: newParams
					});
				return _Utils_Tuple2(
					newModel,
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(newModel.K),
						$author$project$Page$Invoices$toRoute(newModel)));
			default:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{el: time}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Login$Invalid = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Page$Login$extractError = F2(
	function (problem, key) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Dict$get, key, problem.hd));
	});
var $author$project$Page$Login$apiProblemToErrors = function (problem) {
	var extract = $author$project$Page$Login$extractError(problem);
	return {
		be: extract('email'),
		an: extract('password')
	};
};
var $author$project$Page$Login$noErrors = {be: _List_Nil, an: _List_Nil};
var $author$project$Page$Login$CompletedLogin = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Login$Sending = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Endpoint$nonJsonUrl = F2(
	function (paths, queryParams) {
		return A2($elm$url$Url$Builder$absolute, paths, queryParams);
	});
var $author$project$Api$Endpoint$login = A2(
	$author$project$Api$Endpoint$nonJsonUrl,
	_List_fromArray(
		['login']),
	_List_Nil);
var $author$project$User$login = F3(
	function (msg, returnTo, _v0) {
		var email = _v0.be;
		var password = _v0.an;
		var rememberMe = _v0.b4;
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$login,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(email)),
						_Utils_Tuple2(
						'password',
						$elm$json$Json$Encode$string(password)),
						_Utils_Tuple2(
						'remember_me',
						$elm$json$Json$Encode$bool(rememberMe)),
						_Utils_Tuple2(
						'returnTo',
						$elm$json$Json$Encode$string(
							$author$project$Route$toString(returnTo)))
					])),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['returnTo']),
				$elm$json$Json$Decode$string),
			msg);
	});
var $author$project$Analytics$Login = {$: 0};
var $author$project$Analytics$trackLogin = $author$project$Ports$trackAnalytics(
	$author$project$Analytics$analyticsPayload($author$project$Analytics$Login));
var $author$project$Page$Login$validateString = F2(
	function (value, rules) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			A2(
				$elm$core$List$map,
				function (rule) {
					return rule(value);
				},
				rules));
	});
var $author$project$Page$Login$validate = function (inputs) {
	var newErrors = {
		be: A2(
			$author$project$Page$Login$validateString,
			inputs.be,
			_List_fromArray(
				[$author$project$Form$isRequired])),
		an: A2(
			$author$project$Page$Login$validateString,
			inputs.an,
			_List_fromArray(
				[$author$project$Form$isRequired]))
	};
	return _Utils_eq(newErrors, $author$project$Page$Login$noErrors) ? _Utils_Tuple2('', $author$project$Page$Login$noErrors) : _Utils_Tuple2('Något blev fel med formuläret.', newErrors);
};
var $author$project$Page$Login$send = F2(
	function (returnTo, inputs) {
		var _v0 = $author$project$Page$Login$validate(inputs);
		var errorMsg = _v0.a;
		var newErrors = _v0.b;
		return _Utils_eq(newErrors, $author$project$Page$Login$noErrors) ? _Utils_Tuple2(
			$author$project$Page$Login$Sending(inputs),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Analytics$trackLogin,
						A3($author$project$User$login, $author$project$Page$Login$CompletedLogin, returnTo, inputs)
					]))) : _Utils_Tuple2(
			A3($author$project$Page$Login$Invalid, inputs, newErrors, errorMsg),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Login$Valid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$updateFormInput = F3(
	function (inputs, updateFn, value) {
		var newValues = A2(updateFn, value, inputs);
		var _v0 = $author$project$Page$Login$validate(newValues);
		var errorMsg = _v0.a;
		var newErrors = _v0.b;
		var newStatus = $elm$core$String$isEmpty(errorMsg) ? $author$project$Page$Login$Valid(newValues) : A3($author$project$Page$Login$Invalid, newValues, newErrors, errorMsg);
		return newStatus;
	});
var $author$project$Page$Login$updateForm = F2(
	function (msg, status) {
		var _v0 = _Utils_Tuple2(msg, status);
		switch (_v0.b.$) {
			case 3:
				var inputs = _v0.b.a;
				return $author$project$Page$Login$Sending(inputs);
			case 0:
				if (!_v0.a.$) {
					var _v1 = _v0.a;
					var updateFn = _v1.a;
					var value = _v1.b;
					var inputs = _v0.b.a;
					return $author$project$Page$Login$NotValidated(
						A2(updateFn, value, inputs));
				} else {
					var updateFn = _v0.a.a;
					var inputs = _v0.b.a;
					return $author$project$Page$Login$NotValidated(
						updateFn(inputs));
				}
			case 1:
				if (!_v0.a.$) {
					var _v2 = _v0.a;
					var updateFn = _v2.a;
					var value = _v2.b;
					var _v3 = _v0.b;
					var inputs = _v3.a;
					return A3($author$project$Page$Login$updateFormInput, inputs, updateFn, value);
				} else {
					var updateFn = _v0.a.a;
					var _v5 = _v0.b;
					var inputs = _v5.a;
					return A3(
						$author$project$Page$Login$updateFormInput,
						inputs,
						$elm$core$Basics$always(updateFn),
						0);
				}
			default:
				if (!_v0.a.$) {
					var _v4 = _v0.a;
					var updateFn = _v4.a;
					var value = _v4.b;
					var inputs = _v0.b.a;
					return A3($author$project$Page$Login$updateFormInput, inputs, updateFn, value);
				} else {
					var updateFn = _v0.a.a;
					var inputs = _v0.b.a;
					return A3(
						$author$project$Page$Login$updateFormInput,
						inputs,
						$elm$core$Basics$always(updateFn),
						0);
				}
		}
	});
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Login$GotSessionMsg, cmds));
			case 2:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gh: A2($author$project$Page$Login$updateForm, formMsg, model.gh)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var _v2 = model.gh;
				switch (_v2.$) {
					case 0:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							A2($author$project$Page$Login$send, model.b5, inputs));
					case 1:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							A2($author$project$Page$Login$send, model.b5, inputs));
					case 2:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							A2($author$project$Page$Login$send, model.b5, inputs));
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					var redirect = msg.a.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(redirect));
				} else {
					if (msg.a.a.$ === 5) {
						var problem = msg.a.a.a;
						var newStatus = function () {
							var _v3 = model.gh;
							if (_v3.$ === 3) {
								var inputs = _v3.a;
								return A3(
									$author$project$Page$Login$Invalid,
									inputs,
									$author$project$Page$Login$apiProblemToErrors(problem),
									problem.fx);
							} else {
								var other = _v3;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{gh: newStatus}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						var newStatus = function () {
							var _v4 = model.gh;
							if (_v4.$ === 3) {
								var inputs = _v4.a;
								return A3($author$project$Page$Login$Invalid, inputs, $author$project$Page$Login$noErrors, '');
							} else {
								var other = _v4;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2($author$project$Session$addError, error, model.K),
									gh: newStatus
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
		}
	});
var $author$project$Page$MainCategory$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$MainCategory$GotSessionMsg, cmds));
			default:
				if (!msg.a.$) {
					var tree = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								by: $author$project$Status$Loaded(tree)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								by: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
		}
	});
var $author$project$Page$NotFound$update = F2(
	function (msg, session) {
		if (!msg.$) {
			if (!msg.a.$) {
				var newSession = msg.a.a;
				return _Utils_Tuple2(newSession, $elm$core$Platform$Cmd$none);
			} else {
				var decodeError = msg.a.a;
				return A2($author$project$Session$addDecodeError, decodeError, session);
			}
		} else {
			var subMsg = msg.a;
			var _v1 = A2($author$project$Session$update, subMsg, session);
			var newSession = _v1.a;
			var cmds = _v1.b;
			return _Utils_Tuple2(
				newSession,
				A2($elm$core$Platform$Cmd$map, $author$project$Page$NotFound$GotSessionMsg, cmds));
		}
	});
var $author$project$Page$Order$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Order$GotSessionMsg, cmds));
			default:
				if (!msg.a.$) {
					var order = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h9: $author$project$Status$Loaded(order)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 404)) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2(
										$author$project$Session$addError,
										$author$project$Api$BadBody('Order finns inte'),
										model.K)
								}),
							A2(
								$author$project$Route$pushUrl,
								$author$project$Session$navKey(model.K),
								$author$project$Route$Orders($author$project$QueryParams$empty)));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									h9: $author$project$Status$Failed(error)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
		}
	});
var $author$project$Page$Orders$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 2:
				if (msg.a.$ === 16) {
					var subMsg = msg.a;
					var scope = subMsg.a;
					var oldParams = model.E;
					var newParams = _Utils_update(
						oldParams,
						{dW: 1, c$: scope});
					var _v1 = A2($author$project$Session$update, subMsg, model.K);
					var newSession = _v1.a;
					var cmds = _v1.b;
					var newModel = _Utils_update(
						model,
						{
							R: $author$project$Status$loading(model.R),
							E: newParams,
							K: newSession
						});
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Route$replaceUrl,
									$author$project$Session$navKey(newModel.K),
									$author$project$Page$Orders$toRoute(newModel)),
									A2($elm$core$Platform$Cmd$map, $author$project$Page$Orders$GotSessionMsg, cmds)
								])));
				} else {
					var subMsg = msg.a;
					var _v2 = A2($author$project$Session$update, subMsg, model.K);
					var newSession = _v2.a;
					var cmds = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: newSession}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Orders$GotSessionMsg, cmds));
				}
			case 3:
				if (!msg.a.$) {
					var collection = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$Loaded(collection)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								R: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 4:
				var q = msg.a;
				var _v3 = $elm$core$String$trim(q);
				if (_v3 === '') {
					var oldParams = model.E;
					var newParams = _Utils_update(
						oldParams,
						{dW: 1, d0: ''});
					var newModel = _Utils_update(
						model,
						{E: newParams});
					return _Utils_Tuple2(
						newModel,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(newModel.K),
							$author$project$Page$Orders$toRoute(newModel)));
				} else {
					var trimmedQ = _v3;
					var oldParams = model.E;
					var newParams = _Utils_update(
						oldParams,
						{d0: trimmedQ});
					var newModel = _Utils_update(
						model,
						{E: newParams});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var oldParams = model.E;
				var newParams = _Utils_update(
					oldParams,
					{dW: 1});
				var newModel = _Utils_update(
					model,
					{
						R: $author$project$Status$loading(model.R),
						E: newParams
					});
				return _Utils_Tuple2(
					newModel,
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(newModel.K),
						$author$project$Page$Orders$toRoute(newModel)));
			default:
				var page = msg.a;
				var oldParams = model.E;
				var newParams = _Utils_update(
					oldParams,
					{dW: page});
				var newModel = _Utils_update(
					model,
					{
						R: $author$project$Status$loading(model.R),
						E: newParams
					});
				return _Utils_Tuple2(
					newModel,
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(newModel.K),
						$author$project$Page$Orders$toRoute(newModel)));
		}
	});
var $author$project$Product$deserializeRoute = function (str) {
	var _v0 = A2($elm$core$String$split, '|', str);
	if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
		var idString = _v0.a;
		var _v1 = _v0.b;
		var slug_ = _v1.a;
		return A2(
			$elm$core$Maybe$map,
			function (id_) {
				return _Utils_Tuple2(
					$author$project$Id$fromInt(id_),
					$author$project$Slug$fromString(slug_));
			},
			$elm$core$String$toInt(idString));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Product$metadata = A2(
	$elm$core$Basics$composeR,
	$author$project$Product$internals,
	function ($) {
		return $.bV;
	});
var $author$project$Page$Product$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 2:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Product$GotSessionMsg, cmds));
			case 3:
				var amountStr = msg.a;
				var _v2 = $elm$core$String$toFloat(amountStr);
				if (_v2.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var amount = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cj: amount}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var row = msg.a;
				var amountString = msg.b;
				var _v3 = $elm$core$String$toFloat(amountString);
				if (_v3.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var amount = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aG: A2(
									$author$project$Amount$update,
									_Utils_Tuple2(
										$author$project$BidRow$id(row),
										A3(
											$elm$core$Basics$clamp,
											1,
											$author$project$BidRow$orderableQuantity(row),
											amount)),
									model.aG)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var product = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b2: $author$project$Status$Loaded(product)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 404)) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									b2: $author$project$Status$Failed(0)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									b2: $author$project$Status$Failed(0),
									K: A2($author$project$Session$addError, error, model.K)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
			case 6:
				if (!msg.a.$) {
					var bargains = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aE: $author$project$Status$Loaded(bargains)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 404)) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aE: $author$project$Status$Failed(0)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aE: $author$project$Status$Failed(0)
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
			case 7:
				if (!msg.a.$) {
					var related = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a2: $author$project$Status$Loaded(related)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a2: $author$project$Status$Loaded(_List_Nil)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 8:
				if (!msg.a.$) {
					var variants = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aA: $author$project$Status$Loaded(variants)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aA: $author$project$Status$Loaded(_List_Nil)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 9:
				if (!msg.a.$) {
					var bids = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aV: $author$project$Status$Loaded(bids)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aV: $author$project$Status$Loaded(_List_Nil)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 10:
				var modal = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cH: modal}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var aei = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aj: aei}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var i = msg.a;
				var embedsLength = function () {
					var _v4 = model.b2;
					if (_v4.$ === 2) {
						var product = _v4.a;
						return $elm$core$List$length(
							$author$project$Product$metadata(product).eY);
					} else {
						return 0;
					}
				}();
				var newIndex = (!embedsLength) ? model.aj : A2($elm$core$Basics$modBy, embedsLength, model.aj + (1 * i));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aj: newIndex}),
					$elm$core$Platform$Cmd$none);
			default:
				var idAndSlugString = msg.a;
				var _v5 = $author$project$Product$deserializeRoute(idAndSlugString);
				if (_v5.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var idAndSlug = _v5.a;
					var queryParams = A2(
						$elm$core$Dict$singleton,
						'amount',
						_List_fromArray(
							[
								$elm$core$String$fromFloat(model.cj)
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aA: $author$project$Status$loading(model.aA)
							}),
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.K),
							A2($author$project$Route$Product, queryParams, idAndSlug)));
				}
		}
	});
var $author$project$Page$Products$Expanded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Session$UpdatedOrderBy = function (a) {
	return {$: 15, a: a};
};
var $author$project$Filter$mapState = F2(
	function (filter, newState) {
		if (!filter.$) {
			var a = filter.b;
			return A2($author$project$Filter$Str, newState, a);
		} else {
			var a = filter.b;
			return A2($author$project$Filter$Num, newState, a);
		}
	});
var $author$project$Filter$slug = function (filter) {
	if (!filter.$) {
		var _v1 = filter.b;
		var rec = _v1.a;
		return rec.gb;
	} else {
		var rec = filter.b;
		return rec.gb;
	}
};
var $author$project$Filter$changeState = F3(
	function (filters, newState, filter) {
		return A2(
			$elm$core$List$map,
			function (f) {
				return (!_Utils_eq(
					$author$project$Filter$slug(f),
					$author$project$Filter$slug(filter))) ? f : A2($author$project$Filter$mapState, f, newState);
			},
			filters);
	});
var $author$project$Brand$description = function (_v0) {
	var internals = _v0;
	return internals.eT;
};
var $author$project$Category$description = function (_v0) {
	var _v1 = _v0.b;
	var desc = _v1.a;
	return desc;
};
var $author$project$Brand$embeds = function (_v0) {
	var internals = _v0;
	return internals.eY;
};
var $author$project$Category$image = function (_v0) {
	var _v1 = _v0.b;
	var img = _v1.b;
	return img;
};
var $author$project$Brand$images = function (_v0) {
	var internals = _v0;
	return internals.fd;
};
var $author$project$Filter$findState = F2(
	function (haystack, needle) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (straw, carry) {
					var _v0 = _Utils_Tuple2(straw, needle);
					_v0$2:
					while (true) {
						if (!_v0.a.$) {
							if (!_v0.b.$) {
								var _v1 = _v0.a;
								var strawState = _v1.a;
								var _v2 = _v1.b;
								var strawRec = _v2.a;
								var _v3 = _v0.b;
								var _v4 = _v3.b;
								var needleRec = _v4.a;
								return _Utils_eq(strawRec.gb, needleRec.gb) ? strawState : carry;
							} else {
								break _v0$2;
							}
						} else {
							if (_v0.b.$ === 1) {
								var _v5 = _v0.a;
								var strawState = _v5.a;
								var strawRec = _v5.b;
								var _v6 = _v0.b;
								var needleRec = _v6.b;
								return _Utils_eq(strawRec.gb, needleRec.gb) ? strawState : carry;
							} else {
								break _v0$2;
							}
						}
					}
					return carry;
				}),
			0,
			haystack);
	});
var $author$project$Filter$merge = F2(
	function (oldFilters, newFilters) {
		return A2(
			$elm$core$List$map,
			function (f) {
				return A2(
					$author$project$Filter$mapState,
					f,
					A2($author$project$Filter$findState, oldFilters, f));
			},
			newFilters);
	});
var $author$project$Analytics$Search = function (a) {
	return {$: 1, a: a};
};
var $author$project$Analytics$trackSearch = A2(
	$elm$core$Basics$composeR,
	$author$project$Analytics$Search,
	A2($elm$core$Basics$composeR, $author$project$Analytics$analyticsPayload, $author$project$Ports$trackAnalytics));
var $author$project$Page$Products$Collapsed = function (a) {
	return {$: 1, a: a};
};
var $author$project$MD$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $author$project$MD$renderer = _Utils_update(
	$dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer,
	{
		hM: F2(
			function (link, content) {
				var target = (A2($elm$core$String$startsWith, 'http', link.eU) && ((!A2($elm$core$String$startsWith, 'https://partnerzon.specialelektronik.se', link.eU)) || (!A2($elm$core$String$startsWith, 'https://www.specialelektronik.se', link.eU)))) ? '_blank' : '_self';
				var _v0 = link.gr;
				if (!_v0.$) {
					var title = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(link.eU),
								$elm$html$Html$Attributes$title(title),
								$elm$html$Html$Attributes$target(target)
							]),
						content);
				} else {
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(link.eU),
								$elm$html$Html$Attributes$target(target)
							]),
						content);
				}
			})
	});
var $author$project$MD$parse = function (content) {
	return (content === '') ? $elm$core$Result$Ok(
		$rtfeldman$elm_css$Html$Styled$text('')) : A2(
		$elm$core$Result$map,
		$author$project$SE$UI$Content$content(_List_Nil),
		A2(
			$elm$core$Result$map,
			$elm$core$List$map($rtfeldman$elm_css$Html$Styled$fromUnstyled),
			A2(
				$elm$core$Result$andThen,
				$dillonkearns$elm_markdown$Markdown$Renderer$render($author$project$MD$renderer),
				A2(
					$elm$core$Result$mapError,
					$author$project$MD$deadEndsToString,
					$dillonkearns$elm_markdown$Markdown$Parser$parse(content)))));
};
var $author$project$MD$fromString = function (str) {
	var _v0 = $author$project$MD$parse(str);
	if (!_v0.$) {
		var html = _v0.a;
		return html;
	} else {
		var e = _v0.a;
		return $rtfeldman$elm_css$Html$Styled$text('FEL: ' + e);
	}
};
var $author$project$Page$Products$updateDescription = function (str) {
	return (str === '') ? $author$project$Page$Products$Empty : (($elm$core$String$length(str) <= 300) ? $author$project$Page$Products$Expanded(
		$author$project$MD$fromString(str)) : $author$project$Page$Products$Collapsed(
		$author$project$MD$fromString(str)));
};
var $author$project$Filter$clearFilter = F2(
	function (_v0, filter) {
		var dict = _v0;
		return A2(
			$elm$core$Dict$remove,
			$author$project$Filter$slug(filter),
			dict);
	});
var $author$project$Filter$clearSelections = function (_v0) {
	var dict = _v0;
	return A2(
		$elm$core$Dict$filter,
		F2(
			function (slug_, _v1) {
				return slug_ === 'categories';
			}),
		dict);
};
var $author$project$Filter$removeCategory = function (_v0) {
	var dict = _v0;
	return A3(
		$elm$core$Dict$update,
		'categories',
		$elm$core$Basics$always($elm$core$Maybe$Nothing),
		dict);
};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Filter$toggleSelectionHelper = F2(
	function (value, maybeSelection) {
		if ((!maybeSelection.$) && (!maybeSelection.a.$)) {
			var set = maybeSelection.a.a;
			return A2($elm$core$Set$member, value, set) ? $elm$core$Maybe$Just(
				$author$project$Filter$StrSelection(
					A2($elm$core$Set$remove, value, set))) : $elm$core$Maybe$Just(
				$author$project$Filter$StrSelection(
					A2($elm$core$Set$insert, value, set)));
		} else {
			return $elm$core$Maybe$Just(
				$author$project$Filter$StrSelection(
					$elm$core$Set$singleton(value)));
		}
	});
var $author$project$Filter$toggleSelection = F3(
	function (_v0, o, _v1) {
		var rec = _v0.a;
		var dict = _v1;
		return A3(
			$elm$core$Dict$update,
			rec.gb,
			$author$project$Filter$toggleSelectionHelper(o),
			dict);
	});
var $author$project$Filter$updateMaxHelper = F3(
	function (filterMin, newMax, maybeSelection) {
		if ((!maybeSelection.$) && (maybeSelection.a.$ === 1)) {
			var _v1 = maybeSelection.a.a;
			var currentMin = _v1.a;
			return $elm$core$Maybe$Just(
				$author$project$Filter$NumSelection(
					A2($author$project$Filter$NumberSelection, currentMin, newMax)));
		} else {
			return $elm$core$Maybe$Just(
				$author$project$Filter$NumSelection(
					A2($author$project$Filter$NumberSelection, filterMin, newMax)));
		}
	});
var $author$project$Filter$updateMax = F3(
	function (_v0, _v1, newMax) {
		var dict = _v0;
		var rec = _v1;
		var total_min = rec.db;
		var total_max = rec.da;
		return A3(
			$elm$core$Dict$update,
			rec.gb,
			A2(
				$author$project$Filter$updateMaxHelper,
				total_min,
				A3($elm$core$Basics$clamp, total_min, total_max, newMax)),
			dict);
	});
var $author$project$Filter$updateMinHelper = F3(
	function (filterMax, newMin, maybeSelection) {
		if ((!maybeSelection.$) && (maybeSelection.a.$ === 1)) {
			var _v1 = maybeSelection.a.a;
			var currentMax = _v1.b;
			return $elm$core$Maybe$Just(
				$author$project$Filter$NumSelection(
					A2($author$project$Filter$NumberSelection, newMin, currentMax)));
		} else {
			return $elm$core$Maybe$Just(
				$author$project$Filter$NumSelection(
					A2($author$project$Filter$NumberSelection, newMin, filterMax)));
		}
	});
var $author$project$Filter$updateMin = F3(
	function (_v0, _v1, newMin) {
		var dict = _v0;
		var rec = _v1;
		var total_min = rec.db;
		var total_max = rec.da;
		return A3(
			$elm$core$Dict$update,
			rec.gb,
			A2(
				$author$project$Filter$updateMinHelper,
				total_max,
				A3($elm$core$Basics$clamp, total_min, total_max, newMin)),
			dict);
	});
var $author$project$Page$Products$updateFilter = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var strFilter = msg.a;
				var value = msg.b;
				return _Utils_update(
					model,
					{
						iD: A3($author$project$Filter$toggleSelection, strFilter, value, model.iD)
					});
			case 1:
				var id = msg.a;
				return _Utils_update(
					model,
					{
						iD: A2($author$project$Filter$setCategory, id, model.iD)
					});
			case 2:
				return _Utils_update(
					model,
					{
						iD: $author$project$Filter$removeCategory(model.iD)
					});
			case 3:
				var numFilter = msg.a;
				var value = msg.b;
				var _v1 = $elm$core$String$toFloat(value);
				if (!_v1.$) {
					var floatValue = _v1.a;
					return _Utils_update(
						model,
						{
							iD: A3($author$project$Filter$updateMin, model.iD, numFilter, floatValue)
						});
				} else {
					return model;
				}
			case 4:
				var numFilter = msg.a;
				var value = msg.b;
				var _v2 = $elm$core$String$toFloat(value);
				if (!_v2.$) {
					var floatValue = _v2.a;
					return _Utils_update(
						model,
						{
							iD: A3($author$project$Filter$updateMax, model.iD, numFilter, floatValue)
						});
				} else {
					return model;
				}
			case 5:
				var filter = msg.a;
				return _Utils_update(
					model,
					{
						iD: A2($author$project$Filter$clearFilter, model.iD, filter)
					});
			default:
				return _Utils_update(
					model,
					{
						iD: $author$project$Filter$clearSelections(model.iD)
					});
		}
	});
var $author$project$Page$Products$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				if (msg.a.$ === 15) {
					var orderByString = msg.a.a;
					var _v1 = A2(
						$author$project$Session$update,
						$author$project$Session$UpdatedOrderBy(orderByString),
						model.K);
					var newSession = _v1.a;
					var cmds = _v1.b;
					var newModel = _Utils_update(
						model,
						{
							h4: 0,
							ia: A2(
								$elm$core$Maybe$withDefault,
								model.ia,
								$author$project$OrderBy$fromString(orderByString)),
							K: newSession
						});
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Page$Products$GotSessionMsg, cmds),
									A2(
									$author$project$Route$pushUrl,
									$author$project$Session$navKey(newModel.K),
									$author$project$Page$Products$toRoute(newModel))
								])));
				} else {
					var subMsg = msg.a;
					var _v2 = A2($author$project$Session$update, subMsg, model.K);
					var newSession = _v2.a;
					var cmds = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: newSession}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Products$GotSessionMsg, cmds));
				}
			case 2:
				if (!msg.a.$) {
					var cat = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eT: $author$project$Page$Products$updateDescription(
									$author$project$Category$description(cat)),
								aH: A2(
									$elm$core$Maybe$map,
									$author$project$Embed$IsImage,
									$author$project$Category$image(cat))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 3:
				if (!msg.a.$) {
					var brand = msg.a.a;
					var embed = function () {
						var _v3 = $elm$core$List$head(
							$author$project$Brand$embeds(brand));
						if (_v3.$ === 1) {
							return A2(
								$elm$core$Maybe$map,
								$author$project$Embed$IsImage,
								$elm$core$List$head(
									$author$project$Brand$images(brand)));
						} else {
							var other = _v3;
							return other;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eT: $author$project$Page$Products$updateDescription(
									$author$project$Brand$description(brand)),
								aH: embed,
								f: $author$project$Page$Products$BrandMode(
									_Utils_Tuple2(
										$author$project$Brand$slug(brand),
										$elm$core$Maybe$Just(brand)))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 4:
				var newOffset = $elm$core$List$length(
					$elm$core$Dict$keys(model.hF));
				var calcLimit = A2($elm$core$Basics$min, model.F - newOffset, $author$project$Page$Products$defaultLimit);
				var newModel = _Utils_update(
					model,
					{
						hF: A2(
							$author$project$Page$Products$setLoading,
							{hL: calcLimit, h4: newOffset},
							model.hF)
					});
				return _Utils_Tuple2(
					newModel,
					A2(
						$author$project$Route$pushUrl,
						$author$project$Session$navKey(newModel.K),
						$author$project$Page$Products$toRoute(
							_Utils_update(
								newModel,
								{h4: newOffset}))));
			case 5:
				if (!msg.c.$) {
					if (!msg.c.a.$) {
						var offset = msg.a;
						var hasAggregations = msg.b;
						var products = msg.c.a.a.ik;
						var categories = msg.c.a.a.gV;
						var filters = msg.c.a.a.J;
						var total = msg.c.a.a.F;
						var spelling = msg.c.a.a.gg;
						var newItems = $elm$core$Dict$fromList(
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, product) {
										return _Utils_Tuple2(
											i + offset,
											$author$project$Page$Products$View$Loaded(
												$author$project$Page$Products$View$defaultItem(product)));
									}),
								products));
						var updatedItems = hasAggregations ? newItems : A2($elm$core$Dict$union, newItems, model.hF);
						var newFilters = function () {
							var _v6 = _Utils_Tuple2(hasAggregations, model.J);
							if (_v6.a) {
								if (!_v6.b.b) {
									return filters;
								} else {
									return A2($author$project$Filter$merge, model.J, filters);
								}
							} else {
								return model.J;
							}
						}();
						var newCategories = hasAggregations ? categories : model.gV;
						var _v4 = function () {
							var _v5 = model.f;
							if ((_v5.$ === 1) && (!_v5.a.$)) {
								var q = _v5.a.a;
								return _Utils_Tuple2(
									$author$project$Page$Products$SearchMode(
										$author$project$Page$Products$Searched(q)),
									$author$project$Analytics$trackSearch(
										{hu: total, im: q}));
							} else {
								var other = _v5;
								return _Utils_Tuple2(other, $elm$core$Platform$Cmd$none);
							}
						}();
						var newMode = _v4.a;
						var newCmd = _v4.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{gV: newCategories, J: newFilters, hF: updatedItems, f: newMode, aq: spelling, F: total}),
							newCmd);
					} else {
						var spelling = msg.c.a.a.gg;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{gV: $elm$core$Dict$empty, hF: $elm$core$Dict$empty, hL: $author$project$Page$Products$defaultLimit, aq: spelling, F: 0}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var error = msg.c.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								K: A2($author$project$Session$addError, error, model.K)
							}),
						$author$project$Rollbar$apiError(error));
				}
			case 6:
				var itemIndex = msg.a;
				var imageIndex = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hF: A3(
								$elm$core$Dict$update,
								itemIndex,
								$elm$core$Maybe$map(
									function (status) {
										if (status.$ === 1) {
											var item = status.a;
											return $author$project$Page$Products$View$Loaded(
												_Utils_update(
													item,
													{gH: imageIndex}));
										} else {
											var other = status;
											return other;
										}
									}),
								model.hF)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var filterMsg = msg.a;
				var newModel = A2(
					$author$project$Page$Products$updateFilter,
					filterMsg,
					_Utils_update(
						model,
						{h4: 0}));
				return _Utils_Tuple2(
					newModel,
					A2(
						$author$project$Route$pushUrl,
						$author$project$Session$navKey(newModel.K),
						$author$project$Page$Products$toRoute(newModel)));
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: !model.aI}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var newState = msg.a;
				var filter = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							J: A3($author$project$Filter$changeState, model.J, newState, filter)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var newDescription = function () {
					var _v8 = model.eT;
					if (_v8.$ === 1) {
						var html = _v8.a;
						return $author$project$Page$Products$Expanded(html);
					} else {
						var other = _v8;
						return other;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eT: newDescription}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$RequestNewPassword$Invalid = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Page$RequestNewPassword$extractError = F2(
	function (problem, key) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Dict$get, key, problem.hd));
	});
var $author$project$Page$RequestNewPassword$apiProblemToErrors = function (problem) {
	var extract = $author$project$Page$RequestNewPassword$extractError(problem);
	return {
		be: extract('email')
	};
};
var $author$project$Page$RequestNewPassword$noErrors = {be: _List_Nil};
var $author$project$Page$RequestNewPassword$CompletedRequestNewPassword = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$RequestNewPassword$Sending = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Endpoint$passwordEmail = A2(
	$author$project$Api$Endpoint$nonJsonUrl,
	_List_fromArray(
		['password', 'email']),
	_List_Nil);
var $author$project$User$requestPasswordReset = F2(
	function (msg, _v0) {
		var email = _v0.be;
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$passwordEmail,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(email))
					])),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['status']),
				$elm$json$Json$Decode$string),
			msg);
	});
var $author$project$Page$RequestNewPassword$validateString = F2(
	function (value, rules) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			A2(
				$elm$core$List$map,
				function (rule) {
					return rule(value);
				},
				rules));
	});
var $author$project$Page$RequestNewPassword$validate = function (inputs) {
	var newErrors = {
		be: A2(
			$author$project$Page$RequestNewPassword$validateString,
			inputs.be,
			_List_fromArray(
				[$author$project$Form$isRequired]))
	};
	return _Utils_eq(newErrors, $author$project$Page$RequestNewPassword$noErrors) ? _Utils_Tuple2('', $author$project$Page$RequestNewPassword$noErrors) : _Utils_Tuple2('Något blev fel med formuläret.', newErrors);
};
var $author$project$Page$RequestNewPassword$send = function (inputs) {
	var _v0 = $author$project$Page$RequestNewPassword$validate(inputs);
	var errorMsg = _v0.a;
	var newErrors = _v0.b;
	return _Utils_eq(newErrors, $author$project$Page$RequestNewPassword$noErrors) ? _Utils_Tuple2(
		$author$project$Page$RequestNewPassword$Sending(inputs),
		A2($author$project$User$requestPasswordReset, $author$project$Page$RequestNewPassword$CompletedRequestNewPassword, inputs)) : _Utils_Tuple2(
		A3($author$project$Page$RequestNewPassword$Invalid, inputs, newErrors, errorMsg),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$RequestNewPassword$Valid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$RequestNewPassword$updateFormInput = F3(
	function (inputs, updateFn, value) {
		var newValues = A2(updateFn, value, inputs);
		var _v0 = $author$project$Page$RequestNewPassword$validate(newValues);
		var errorMsg = _v0.a;
		var newErrors = _v0.b;
		var newStatus = $elm$core$String$isEmpty(errorMsg) ? $author$project$Page$RequestNewPassword$Valid(newValues) : A3($author$project$Page$RequestNewPassword$Invalid, newValues, newErrors, errorMsg);
		return newStatus;
	});
var $author$project$Page$RequestNewPassword$updateForm = F2(
	function (msg, status) {
		var _v0 = _Utils_Tuple2(msg, status);
		switch (_v0.b.$) {
			case 3:
				var inputs = _v0.b.a;
				return $author$project$Page$RequestNewPassword$Sending(inputs);
			case 0:
				var _v1 = _v0.a;
				var updateFn = _v1.a;
				var value = _v1.b;
				var inputs = _v0.b.a;
				return $author$project$Page$RequestNewPassword$NotValidated(
					A2(updateFn, value, inputs));
			case 1:
				var _v2 = _v0.a;
				var updateFn = _v2.a;
				var value = _v2.b;
				var _v3 = _v0.b;
				var inputs = _v3.a;
				return A3($author$project$Page$RequestNewPassword$updateFormInput, inputs, updateFn, value);
			default:
				var _v4 = _v0.a;
				var updateFn = _v4.a;
				var value = _v4.b;
				var inputs = _v0.b.a;
				return A3($author$project$Page$RequestNewPassword$updateFormInput, inputs, updateFn, value);
		}
	});
var $author$project$Page$RequestNewPassword$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$RequestNewPassword$GotSessionMsg, cmds));
			case 2:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gh: A2($author$project$Page$RequestNewPassword$updateForm, formMsg, model.gh)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var _v2 = model.gh;
				switch (_v2.$) {
					case 0:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							$author$project$Page$RequestNewPassword$send(inputs));
					case 1:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							$author$project$Page$RequestNewPassword$send(inputs));
					case 2:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							$author$project$Page$RequestNewPassword$send(inputs));
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					var message = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fx: message,
								gh: $author$project$Page$RequestNewPassword$NotValidated($author$project$Page$RequestNewPassword$emptyInputs)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					if (msg.a.a.$ === 5) {
						var problem = msg.a.a.a;
						var newStatus = function () {
							var _v3 = model.gh;
							if (_v3.$ === 3) {
								var inputs = _v3.a;
								return A3(
									$author$project$Page$RequestNewPassword$Invalid,
									inputs,
									$author$project$Page$RequestNewPassword$apiProblemToErrors(problem),
									problem.fx);
							} else {
								var other = _v3;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{gh: newStatus}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						var newStatus = function () {
							var _v4 = model.gh;
							if (_v4.$ === 3) {
								var inputs = _v4.a;
								return A3($author$project$Page$RequestNewPassword$Invalid, inputs, $author$project$Page$RequestNewPassword$noErrors, '');
							} else {
								var other = _v4;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2($author$project$Session$addError, error, model.K),
									gh: newStatus
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
		}
	});
var $author$project$Page$ResetPassword$Invalid = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Page$ResetPassword$extractError = F2(
	function (problem, key) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Dict$get, key, problem.hd));
	});
var $author$project$Page$ResetPassword$apiProblemToErrors = function (problem) {
	var extract = $author$project$Page$ResetPassword$extractError(problem);
	return {
		be: extract('email'),
		an: extract('password'),
		ao: extract('password_confirmation')
	};
};
var $author$project$Page$ResetPassword$noErrors = {be: _List_Nil, an: _List_Nil, ao: _List_Nil};
var $author$project$Page$ResetPassword$CompletedResetPassword = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ResetPassword$Sending = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Endpoint$resetPassword = A2(
	$author$project$Api$Endpoint$nonJsonUrl,
	_List_fromArray(
		['password', 'reset']),
	_List_Nil);
var $author$project$User$resetPassword = F3(
	function (msg, token, _v0) {
		var email = _v0.be;
		var password = _v0.an;
		var password_confirmation = _v0.ao;
		return A4(
			$author$project$Api$post,
			$author$project$Api$Endpoint$resetPassword,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'token',
						$elm$json$Json$Encode$string(token)),
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(email)),
						_Utils_Tuple2(
						'password',
						$elm$json$Json$Encode$string(password)),
						_Utils_Tuple2(
						'password_confirmation',
						$elm$json$Json$Encode$string(password_confirmation))
					])),
			$elm$json$Json$Decode$succeed(0),
			msg);
	});
var $author$project$Page$ResetPassword$validateString = F2(
	function (value, rules) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			A2(
				$elm$core$List$map,
				function (rule) {
					return rule(value);
				},
				rules));
	});
var $author$project$Page$ResetPassword$validate = function (inputs) {
	var newErrors = {
		be: A2(
			$author$project$Page$ResetPassword$validateString,
			inputs.be,
			_List_fromArray(
				[$author$project$Form$isRequired])),
		an: A2(
			$author$project$Page$ResetPassword$validateString,
			inputs.an,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					$author$project$Form$isMin(8)
				])),
		ao: A2(
			$author$project$Page$ResetPassword$validateString,
			inputs.ao,
			_List_fromArray(
				[
					$author$project$Form$isRequired,
					A2($author$project$Form$isSame, 'lösenord', inputs.an)
				]))
	};
	return _Utils_eq(newErrors, $author$project$Page$ResetPassword$noErrors) ? _Utils_Tuple2('', $author$project$Page$ResetPassword$noErrors) : _Utils_Tuple2('Något blev fel med formuläret.', newErrors);
};
var $author$project$Page$ResetPassword$send = F2(
	function (token, inputs) {
		var _v0 = $author$project$Page$ResetPassword$validate(inputs);
		var errorMsg = _v0.a;
		var newErrors = _v0.b;
		return _Utils_eq(newErrors, $author$project$Page$ResetPassword$noErrors) ? _Utils_Tuple2(
			$author$project$Page$ResetPassword$Sending(inputs),
			A3($author$project$User$resetPassword, $author$project$Page$ResetPassword$CompletedResetPassword, token, inputs)) : _Utils_Tuple2(
			A3($author$project$Page$ResetPassword$Invalid, inputs, newErrors, errorMsg),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$ResetPassword$Valid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ResetPassword$updateFormInput = F3(
	function (inputs, updateFn, value) {
		var newValues = A2(updateFn, value, inputs);
		var _v0 = $author$project$Page$ResetPassword$validate(newValues);
		var errorMsg = _v0.a;
		var newErrors = _v0.b;
		var newStatus = $elm$core$String$isEmpty(errorMsg) ? $author$project$Page$ResetPassword$Valid(newValues) : A3($author$project$Page$ResetPassword$Invalid, newValues, newErrors, errorMsg);
		return newStatus;
	});
var $author$project$Page$ResetPassword$updateForm = F2(
	function (msg, status) {
		var _v0 = _Utils_Tuple2(msg, status);
		switch (_v0.b.$) {
			case 3:
				var inputs = _v0.b.a;
				return $author$project$Page$ResetPassword$Sending(inputs);
			case 0:
				var _v1 = _v0.a;
				var updateFn = _v1.a;
				var value = _v1.b;
				var inputs = _v0.b.a;
				return $author$project$Page$ResetPassword$NotValidated(
					A2(updateFn, value, inputs));
			case 1:
				var _v2 = _v0.a;
				var updateFn = _v2.a;
				var value = _v2.b;
				var _v3 = _v0.b;
				var inputs = _v3.a;
				return A3($author$project$Page$ResetPassword$updateFormInput, inputs, updateFn, value);
			default:
				var _v4 = _v0.a;
				var updateFn = _v4.a;
				var value = _v4.b;
				var inputs = _v0.b.a;
				return A3($author$project$Page$ResetPassword$updateFormInput, inputs, updateFn, value);
		}
	});
var $author$project$Page$ResetPassword$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$ResetPassword$GotSessionMsg, cmds));
			case 2:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gh: A2($author$project$Page$ResetPassword$updateForm, formMsg, model.gh)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var _v2 = model.gh;
				switch (_v2.$) {
					case 0:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							A2($author$project$Page$ResetPassword$send, model.cb, inputs));
					case 1:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							A2($author$project$Page$ResetPassword$send, model.cb, inputs));
					case 2:
						var inputs = _v2.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									model,
									{gh: status});
							},
							A2($author$project$Page$ResetPassword$send, model.cb, inputs));
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						$author$project$Route$load($author$project$Route$Home));
				} else {
					if (msg.a.a.$ === 5) {
						var problem = msg.a.a.a;
						var newStatus = function () {
							var _v3 = model.gh;
							if (_v3.$ === 3) {
								var inputs = _v3.a;
								return A3(
									$author$project$Page$ResetPassword$Invalid,
									inputs,
									$author$project$Page$ResetPassword$apiProblemToErrors(problem),
									problem.fx);
							} else {
								var other = _v3;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{gh: newStatus}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						var newStatus = function () {
							var _v4 = model.gh;
							if (_v4.$ === 3) {
								var inputs = _v4.a;
								return A3($author$project$Page$ResetPassword$Invalid, inputs, $author$project$Page$ResetPassword$noErrors, '');
							} else {
								var other = _v4;
								return other;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									K: A2($author$project$Session$addError, error, model.K),
									gh: newStatus
								}),
							$author$project$Rollbar$apiError(error));
					}
				}
		}
	});
var $author$project$Page$Service$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var session = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{K: session}),
						$elm$core$Platform$Cmd$none);
				} else {
					var decodeError = msg.a.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (session) {
							return _Utils_update(
								model,
								{K: session});
						},
						A2($author$project$Session$addDecodeError, decodeError, model.K));
				}
			case 1:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Session$update, subMsg, model.K);
				var newSession = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: newSession}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Service$GotSessionMsg, cmds));
			default:
				if (!msg.a.$) {
					var services = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gh: $author$project$Status$Loaded(services)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gh: $author$project$Status$Failed(error)
							}),
						$author$project$Rollbar$apiError(error));
				}
		}
	});
var $author$project$Page$ThankYou$update = F2(
	function (msg, model) {
		if (!msg.$) {
			if (!msg.a.$) {
				var session = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: session}),
					$elm$core$Platform$Cmd$none);
			} else {
				var decodeError = msg.a.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (session) {
						return _Utils_update(
							model,
							{K: session});
					},
					A2($author$project$Session$addDecodeError, decodeError, model.K));
			}
		} else {
			var subMsg = msg.a;
			var _v1 = A2($author$project$Session$update, subMsg, model.K);
			var newSession = _v1.a;
			var cmds = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{K: newSession}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$ThankYou$GotSessionMsg, cmds));
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$27:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 2:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								$author$project$Session$navKey(
									$author$project$Main$toSession(model)),
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 1:
					var url = _v0.a.a;
					return A2(
						$author$project$Main$changeRouteTo,
						$author$project$Route$fromUrl(url),
						model);
				case 3:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Home,
							$author$project$Main$GotHomeMsg,
							_List_Nil,
							A2($author$project$Page$Home$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 4:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Products,
							$author$project$Main$GotProductsMsg,
							_List_Nil,
							A2($author$project$Page$Products$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 5:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$MainCategory,
							$author$project$Main$GotMainCategoryMsg,
							_List_Nil,
							A2($author$project$Page$MainCategory$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 6:
					if (_v0.b.$ === 6) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Product,
							$author$project$Main$GotProductMsg,
							_List_Nil,
							A2($author$project$Page$Product$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 13:
					if (_v0.b.$ === 11) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Login,
							$author$project$Main$GotLoginMsg,
							_List_Nil,
							A2($author$project$Page$Login$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 14:
					if (_v0.b.$ === 12) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$RequestNewPassword,
							$author$project$Main$GotRequestNewPasswordMsg,
							_List_Nil,
							A2($author$project$Page$RequestNewPassword$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 15:
					if (_v0.b.$ === 13) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ResetPassword,
							$author$project$Main$GotResetPasswordMsg,
							_List_Nil,
							A2($author$project$Page$ResetPassword$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 7:
					if (_v0.b.$ === 7) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Checkout,
							$author$project$Main$GotCheckoutMsg,
							_List_Nil,
							A2($author$project$Page$Checkout$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 8:
					if (_v0.b.$ === 8) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Orders,
							$author$project$Main$GotOrdersMsg,
							_List_Nil,
							A2($author$project$Page$Orders$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 9:
					if (_v0.b.$ === 9) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Order,
							$author$project$Main$GotOrderMsg,
							_List_Nil,
							A2($author$project$Page$Order$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 10:
					if (_v0.b.$ === 23) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Bids,
							$author$project$Main$GotBidsMsg,
							_List_Nil,
							A2($author$project$Page$Bids$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 11:
					if (_v0.b.$ === 24) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Bid,
							$author$project$Main$GotBidMsg,
							_List_Nil,
							A2($author$project$Page$Bid$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 16:
					if (_v0.b.$ === 14) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ThankYou,
							$author$project$Main$GotThankYouMsg,
							_List_Nil,
							A2($author$project$Page$ThankYou$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 12:
					if (_v0.b.$ === 10) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Invoices,
							$author$project$Main$GotInvoicesMsg,
							_List_Nil,
							A2($author$project$Page$Invoices$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 17:
					if (_v0.b.$ === 15) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Brands,
							$author$project$Main$GotBrandsMsg,
							_List_Nil,
							A2($author$project$Page$Brands$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 26:
					if (_v0.b.$ === 1) {
						var session = _v0.a.a;
						if (!session.$) {
							var session_ = session.a;
							return _Utils_Tuple2(
								$author$project$Main$Redirect(session_),
								A2(
									$author$project$Route$replaceUrl,
									$author$project$Session$navKey(session_),
									$author$project$Route$Home));
						} else {
							var decodeError = session.a;
							var _v4 = $author$project$Session$decodeError(decodeError);
							var apiError = _v4.a;
							var cmd = _v4.b;
							return _Utils_Tuple2(
								A2(
									$author$project$Main$DecodeError,
									$author$project$Session$navKey(
										$author$project$Main$toSession(model)),
									apiError),
								cmd);
						}
					} else {
						break _v0$27;
					}
				case 18:
					if (_v0.b.$ === 16) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Account,
							$author$project$Main$GotAccountMsg,
							_List_Nil,
							A2($author$project$Page$Account$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 19:
					if (_v0.b.$ === 17) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$AccountProfile,
							$author$project$Main$GotAccountProfileMsg,
							_List_Nil,
							A2($author$project$Page$Account$Profile$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 20:
					if (_v0.b.$ === 18) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$AccountSettings,
							$author$project$Main$GotAccountSettingsMsg,
							_List_Nil,
							A2($author$project$Page$Account$Settings$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 21:
					if (_v0.b.$ === 19) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$AccountPassword,
							$author$project$Main$GotAccountPasswordMsg,
							_List_Nil,
							A2($author$project$Page$Account$Password$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 22:
					if (_v0.b.$ === 20) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$AccountAdministration,
							$author$project$Main$GotAccountAdministrationMsg,
							_List_Nil,
							A2($author$project$Page$Account$Administration$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 23:
					if (_v0.b.$ === 21) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Content,
							$author$project$Main$GotContentMsg,
							_List_Nil,
							A2($author$project$Page$Content$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				case 24:
					if (_v0.b.$ === 22) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Service,
							$author$project$Main$GotServiceMsg,
							_List_Nil,
							A2($author$project$Page$Service$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
				default:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$NotFound,
							$author$project$Main$GotNotFoundMsg,
							_List_Nil,
							A2($author$project$Page$NotFound$update, subMsg, subModel));
					} else {
						break _v0$27;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _v0) {
		var prop = _v0.a;
		var styles = _v0.b;
		var classname = _v0.c;
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			styles,
			classname);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var key = _v1.a;
							var child = _v1.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var key = _v2.a;
							var child = _v2.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2($elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var $rtfeldman$elm_css$Html$Styled$map = $rtfeldman$elm_css$VirtualDom$Styled$map;
var $author$project$Breadcrumb$Account = {$: 10};
var $author$project$Page$Account = 0;
var $rtfeldman$elm_css$Css$calcExpressionToString = function (expression) {
	if (!expression) {
		return '+';
	} else {
		return '-';
	}
};
var $rtfeldman$elm_css$Css$calc = F3(
	function (firstExpr, expression, secondExpr) {
		var withoutCalcStr = function (l) {
			return A2($elm$core$String$startsWith, 'calc(', l.iY) ? A2($elm$core$String$dropLeft, 4, l.iY) : l.iY;
		};
		var calcs = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					withoutCalcStr(firstExpr),
					$rtfeldman$elm_css$Css$calcExpressionToString(expression),
					withoutCalcStr(secondExpr)
				]));
		var value = A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'calc',
			_List_fromArray(
				[calcs]));
		return {eD: 0, bf: 0, L: 0, b: 0, bl: 0, aM: 0, bm: 0, bn: 0, aZ: 0, a_: 0, ax: 0, bw: 0, iY: value};
	});
var $rtfeldman$elm_css$Css$margin2 = $rtfeldman$elm_css$Css$prop2('margin');
var $rtfeldman$elm_css$Css$maxWidth = $rtfeldman$elm_css$Css$prop1('max-width');
var $rtfeldman$elm_css$Css$Subtraction = 1;
var $rtfeldman$elm_css$Css$minus = 1;
var $rtfeldman$elm_css$Css$none = {a7: 0, eA: 0, Q: 0, a: 0, u: 0, hw: 0, fg: 0, dJ: 0, bn: 0, aZ: 0, ax: 0, e: 0, c: 0, dR: 0, cP: 0, id: 0, ap: 0, cW: 0, iA: 0, bv: 0, a5: 0, Y: 0, gu: 0, iV: 0, iY: 'none'};
var $author$project$SE$UI$Container$modifier = function (m) {
	if (!m) {
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$maxWidth($rtfeldman$elm_css$Css$none),
					$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$auto)
				]));
	} else {
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$maxWidth(
					$rtfeldman$elm_css$Css$px(1208))
				]));
	}
};
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $rtfeldman$elm_css$Css$relative = {b1: 0, iY: 'relative'};
var $author$project$SE$UI$Container$container = function (modifiers) {
	var modStyle = $rtfeldman$elm_css$Css$batch(
		A2($elm$core$List$map, $author$project$SE$UI$Container$modifier, modifiers));
	return A3(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$maxWidth(
				A3(
					$rtfeldman$elm_css$Css$calc,
					$rtfeldman$elm_css$Css$px(1920),
					$rtfeldman$elm_css$Css$minus,
					$rtfeldman$elm_css$Css$rem(2 * 2.66666667))),
				modStyle
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('container', true)
					]))
			]));
};
var $author$project$SE$UI$Card$Card = $elm$core$Basics$identity;
var $author$project$SE$UI$Card$content = function (kids) {
	return {cm: false, eN: kids, c4: '', gr: ''};
};
var $rtfeldman$elm_css$Css$Structure$AdjacentSibling = 0;
var $rtfeldman$elm_css$Css$Global$adjacentSiblings = $rtfeldman$elm_css$Css$Preprocess$NestSnippet(0);
var $rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $author$project$SE$UI$Colors$backgroundColor = A2($elm$core$Basics$composeR, $author$project$SE$UI$Colors$toCss, $rtfeldman$elm_css$Css$backgroundColor);
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $rtfeldman$elm_css$Css$Structure$Child = 2;
var $rtfeldman$elm_css$Css$Global$children = $rtfeldman$elm_css$Css$Preprocess$NestSnippet(2);
var $rtfeldman$elm_css$Css$row = {dB: 0, bO: 0, iY: 'row'};
var $rtfeldman$elm_css$Css$column = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{iY: 'column'});
var $author$project$SE$UI$Colors$darker = A4($author$project$SE$UI$Colors$Hsla, 210, 0.07, 0.17, 1);
var $rtfeldman$elm_css$Css$displayFlex = A2($rtfeldman$elm_css$Css$property, 'display', 'flex');
var $rtfeldman$elm_css$Css$flexDirection = $rtfeldman$elm_css$Css$prop1('flex-direction');
var $author$project$SE$UI$Colors$bargain = A4($author$project$SE$UI$Colors$Hsla, 330, 1, 0.71, 1);
var $author$project$SE$UI$Colors$mapAlpha = F2(
	function (f, _v0) {
		var hue = _v0.a;
		var saturation = _v0.b;
		var lightness = _v0.c;
		var alpha = _v0.d;
		return A4(
			$author$project$SE$UI$Colors$Hsla,
			hue,
			saturation,
			lightness,
			f(alpha));
	});
var $author$project$SE$UI$Colors$white = A4($author$project$SE$UI$Colors$Hsla, 0, 0, 1, 1);
var $author$project$SE$UI$Colors$invert = function (hsla) {
	var lightness = hsla.c;
	return _Utils_eq(hsla, $author$project$SE$UI$Colors$bargain) ? $author$project$SE$UI$Colors$white : ((lightness < 0.65) ? $author$project$SE$UI$Colors$white : A2(
		$author$project$SE$UI$Colors$mapAlpha,
		$elm$core$Basics$mul(0.7),
		$author$project$SE$UI$Colors$black));
};
var $rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$minHeight = $rtfeldman$elm_css$Css$prop1('min-height');
var $rtfeldman$elm_css$Css$minWidth = $rtfeldman$elm_css$Css$prop1('min-width');
var $author$project$SE$UI$Utils$radius = $rtfeldman$elm_css$Css$px(2);
var $rtfeldman$elm_css$Html$Styled$strong = $rtfeldman$elm_css$Html$Styled$node('strong');
var $rtfeldman$elm_css$Css$textTransform = $rtfeldman$elm_css$Css$prop1('text-transform');
var $rtfeldman$elm_css$Css$uppercase = {a5: 0, iY: 'uppercase'};
var $author$project$Page$Account$View$avatar = function (_v0) {
	var firstName = _v0.ho;
	var lastName = _v0.hI;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darker),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$invert($author$project$SE$UI$Colors$darker)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				$rtfeldman$elm_css$Css$minWidth(
				$rtfeldman$elm_css$Css$px(60)),
				$rtfeldman$elm_css$Css$minHeight(
				$rtfeldman$elm_css$Css$px(60)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$lineHeight(
				$rtfeldman$elm_css$Css$int(1)),
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'strong',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$px(32)),
								$rtfeldman$elm_css$Css$Global$adjacentSiblings(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$typeSelector,
										'strong',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$fontSize(
												$rtfeldman$elm_css$Css$px(16)),
												$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase)
											]))
									]))
							]))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$strong,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						_Utils_ap(
							A2($elm$core$String$left, 1, firstName),
							A2($elm$core$String$left, 1, lastName)))
					]))
			]));
};
var $rtfeldman$elm_css$Html$Styled$a = $rtfeldman$elm_css$Html$Styled$node('a');
var $rtfeldman$elm_css$Html$Styled$br = $rtfeldman$elm_css$Html$Styled$node('br');
var $author$project$SE$UI$Icon$V2$envelope = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z'])));
var $rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var $rtfeldman$elm_css$Html$Styled$p = $rtfeldman$elm_css$Html$Styled$node('p');
var $rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$attribute, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$attribute = $rtfeldman$elm_css$VirtualDom$Styled$attribute;
var $rtfeldman$elm_css$Css$currentColor = {at: 0, iY: 'currentColor'};
var $rtfeldman$elm_css$Css$fill = $rtfeldman$elm_css$Css$prop1('fill');
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$inlineFlex = {u: 0, iY: 'inline-flex'};
var $rtfeldman$elm_css$Css$animationDuration = function (arg) {
	return A2($rtfeldman$elm_css$Css$prop1, 'animation-duration', arg);
};
var $rtfeldman$elm_css$Css$Preprocess$WithKeyframes = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$animationName = function (arg) {
	return ((arg.iY === 'none') || ((arg.iY === 'inherit') || ((arg.iY === 'unset') || (arg.iY === 'initial')))) ? A2($rtfeldman$elm_css$Css$prop1, 'animation-name', arg) : $rtfeldman$elm_css$Css$Preprocess$WithKeyframes(arg.iY);
};
var $rtfeldman$elm_css$Css$angleConverter = F2(
	function (suffix, angleVal) {
		return {
			gO: 0,
			as: 0,
			iY: _Utils_ap(
				$elm$core$String$fromFloat(angleVal),
				suffix)
		};
	});
var $rtfeldman$elm_css$Css$deg = $rtfeldman$elm_css$Css$angleConverter('deg');
var $rtfeldman$elm_css$Css$Internal$printKeyframeSelector = function (_v0) {
	var percentage = _v0.a;
	var properties = _v0.b;
	var propertiesStr = A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$map,
			function (_v1) {
				var prop = _v1;
				return prop + ';';
			},
			properties));
	var percentageStr = $elm$core$String$fromInt(percentage) + '%';
	return percentageStr + (' {' + (propertiesStr + '}'));
};
var $rtfeldman$elm_css$Css$Internal$compileKeyframes = function (tuples) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Internal$printKeyframeSelector, tuples));
};
var $rtfeldman$elm_css$Css$Animations$keyframes = function (tuples) {
	return $elm$core$List$isEmpty(tuples) ? {dJ: 0, dR: 0, iY: 'none'} : {
		dJ: 0,
		dR: 0,
		iY: $rtfeldman$elm_css$Css$Internal$compileKeyframes(tuples)
	};
};
var $rtfeldman$elm_css$Css$rotate = function (_v0) {
	var value = _v0.iY;
	return {
		gu: 0,
		iY: A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'rotate',
			_List_fromArray(
				[value]))
	};
};
var $rtfeldman$elm_css$Css$scaleX = function (x) {
	return {
		gu: 0,
		iY: A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'scaleX',
			_List_fromArray(
				[
					$elm$core$String$fromFloat(x)
				]))
	};
};
var $rtfeldman$elm_css$Css$scaleY = function (y) {
	return {
		gu: 0,
		iY: A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'scaleY',
			_List_fromArray(
				[
					$elm$core$String$fromFloat(y)
				]))
	};
};
var $rtfeldman$elm_css$Css$sec = function (amount) {
	return {
		bM: 0,
		iY: $elm$core$String$fromFloat(amount) + 's'
	};
};
var $rtfeldman$elm_css$Css$valuesOrNone = function (list) {
	return $elm$core$List$isEmpty(list) ? {iY: 'none'} : {
		iY: A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				function ($) {
					return $.iY;
				},
				list))
	};
};
var $rtfeldman$elm_css$Css$transforms = A2(
	$elm$core$Basics$composeL,
	$rtfeldman$elm_css$Css$prop1('transform'),
	$rtfeldman$elm_css$Css$valuesOrNone);
var $rtfeldman$elm_css$Css$transform = function (only) {
	return $rtfeldman$elm_css$Css$transforms(
		_List_fromArray(
			[only]));
};
var $rtfeldman$elm_css$Css$Internal$Property = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Animations$transform = function (values) {
	return $elm$core$List$isEmpty(values) ? 'transform:none' : ('transform:' + A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$map,
			function ($) {
				return $.iY;
			},
			values)));
};
var $author$project$SE$UI$Icon$V2$transformToStyle = function (transform) {
	switch (transform.$) {
		case 0:
			var deg = transform.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$transform(
					$rtfeldman$elm_css$Css$rotate(
						$rtfeldman$elm_css$Css$deg(deg)))
				]);
		case 1:
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$transform(
					$rtfeldman$elm_css$Css$scaleX(-1))
				]);
		case 2:
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$transform(
					$rtfeldman$elm_css$Css$scaleY(-1))
				]);
		default:
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$animationDuration(
					$rtfeldman$elm_css$Css$sec(1)),
					A2($rtfeldman$elm_css$Css$property, 'animation-iteration-count', 'infinite'),
					A2($rtfeldman$elm_css$Css$property, 'animation-timing-function', 'linear'),
					$rtfeldman$elm_css$Css$animationName(
					$rtfeldman$elm_css$Css$Animations$keyframes(
						_List_fromArray(
							[
								_Utils_Tuple2(
								0,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Animations$transform(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$rotate(
												$rtfeldman$elm_css$Css$deg(0))
											]))
									])),
								_Utils_Tuple2(
								100,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Animations$transform(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$rotate(
												$rtfeldman$elm_css$Css$deg(360))
											]))
									]))
							])))
				]);
	}
};
var $rtfeldman$elm_css$Css$Global$withClass = function (str) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$ClassSelector(str));
};
var $author$project$SE$UI$Icon$V2$containerStyles = function (transforms) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$rem(1.5)),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$rem(1.5)),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'is-small',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(1)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$rem(1))
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'is-medium',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(2)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$rem(2))
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'is-large',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(3)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$rem(3))
				])),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$typeSelector,
					'svg',
					A2(
						$elm$core$List$cons,
						$rtfeldman$elm_css$Css$fill($rtfeldman$elm_css$Css$currentColor),
						A2($elm$core$List$concatMap, $author$project$SE$UI$Icon$V2$transformToStyle, transforms)))
				]))
		]);
};
var $rtfeldman$elm_css$Svg$Styled$Attributes$height = $rtfeldman$elm_css$VirtualDom$Styled$attribute('height');
var $author$project$SE$UI$Icon$V2$iconSize = function (size) {
	switch (size) {
		case 0:
			return '16';
		case 1:
			return '12';
		case 2:
			return '24';
		default:
			return '36';
	}
};
var $rtfeldman$elm_css$Svg$Styled$Attributes$d = $rtfeldman$elm_css$VirtualDom$Styled$attribute('d');
var $rtfeldman$elm_css$VirtualDom$Styled$nodeNS = $rtfeldman$elm_css$VirtualDom$Styled$NodeNS;
var $rtfeldman$elm_css$Svg$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$nodeNS('http://www.w3.org/2000/svg');
var $rtfeldman$elm_css$Svg$Styled$path = $rtfeldman$elm_css$Svg$Styled$node('path');
var $author$project$SE$UI$Icon$V2$pathToSvg = function (str) {
	return A2(
		$rtfeldman$elm_css$Svg$Styled$path,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Svg$Styled$Attributes$d(str)
			]),
		_List_Nil);
};
var $author$project$SE$UI$Icon$V2$sizeToClass = function (size) {
	switch (size) {
		case 0:
			return '';
		case 1:
			return 'is-small';
		case 2:
			return 'is-medium';
		default:
			return 'is-large';
	}
};
var $rtfeldman$elm_css$Svg$Styled$svg = $rtfeldman$elm_css$Svg$Styled$node('svg');
var $rtfeldman$elm_css$Svg$Styled$Attributes$viewBox = $rtfeldman$elm_css$VirtualDom$Styled$attribute('viewBox');
var $author$project$SE$UI$Icon$V2$viewBoxtoHtml = function (_v0) {
	var w = _v0.a;
	var h = _v0.b;
	return $rtfeldman$elm_css$Svg$Styled$Attributes$viewBox(
		'0 0 ' + ($elm$core$String$fromInt(w) + (' ' + $elm$core$String$fromInt(h))));
};
var $rtfeldman$elm_css$Svg$Styled$Attributes$width = $rtfeldman$elm_css$VirtualDom$Styled$attribute('width');
var $author$project$SE$UI$Icon$V2$toHtml = function (_v0) {
	var internals = _v0;
	var s = $author$project$SE$UI$Icon$V2$iconSize(internals.d6);
	var _v1 = internals.ep;
	var w = _v1.a;
	var h = _v1.b;
	var paths = _v1.c;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$span,
		$author$project$SE$UI$Icon$V2$containerStyles(internals.dc),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('icon', true),
						_Utils_Tuple2(
						$author$project$SE$UI$Icon$V2$sizeToClass(internals.cq),
						true)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Svg$Styled$svg,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-hidden', 'true'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$width(s),
						$rtfeldman$elm_css$Svg$Styled$Attributes$height(s),
						$author$project$SE$UI$Icon$V2$viewBoxtoHtml(
						_Utils_Tuple2(w, h))
					]),
				A2($elm$core$List$map, $author$project$SE$UI$Icon$V2$pathToSvg, paths))
			]));
};
var $author$project$Page$Account$View$content = function (user) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$marginLeft(
				$rtfeldman$elm_css$Css$rem(1))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$strong,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(user.ho + (' ' + user.hI))
							])),
						A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
						$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$envelope),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:' + user.be)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(user.be)
							]))
					]))
			]));
};
var $author$project$Page$Account$View$profile = function (user) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[$rtfeldman$elm_css$Css$displayFlex]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$Account$View$avatar(user),
				$author$project$Page$Account$View$content(user)
			]));
};
var $rtfeldman$elm_css$Html$Styled$section = $rtfeldman$elm_css$Html$Styled$node('section');
var $author$project$SE$UI$Section$section = A2(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$section,
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$rem(1),
			$rtfeldman$elm_css$Css$rem(1)),
			$author$project$SE$UI$Utils$desktop(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(2.66666667),
					$rtfeldman$elm_css$Css$rem(2.66666667))
				]))
		]));
var $rtfeldman$elm_css$Html$Styled$h1 = $rtfeldman$elm_css$Html$Styled$node('h1');
var $author$project$SE$UI$Title$internalTitle = F3(
	function (tag, style, content) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			tag,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$SE$UI$Utils$block,
						A2($rtfeldman$elm_css$Css$property, 'word-break', 'break-word'),
						$rtfeldman$elm_css$Css$lineHeight(
						$rtfeldman$elm_css$Css$num(1.3)),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600))
					]),
				style),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(content)
				]));
	});
var $author$project$SE$UI$Title$title1 = A2(
	$author$project$SE$UI$Title$internalTitle,
	$rtfeldman$elm_css$Html$Styled$h1,
	_List_fromArray(
		[
			$author$project$SE$UI$Font$titleSizeEm(6)
		]));
var $author$project$Breadcrumb$Link = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Breadcrumb$MainSite = {$: 0};
var $author$project$Breadcrumb$categoryLinksHelper = F4(
	function (routeHelper, mainCategorySlug, acc, maybeZipper) {
		categoryLinksHelper:
		while (true) {
			if (maybeZipper.$ === 1) {
				return acc;
			} else {
				var zipper = maybeZipper.a;
				var cat = $zwilias$elm_rosetree$Tree$Zipper$label(zipper);
				var id = $author$project$Category$id(cat);
				var name = $author$project$Category$name(cat);
				var slug = $author$project$Category$slug(cat);
				var route = _Utils_eq(slug, mainCategorySlug) ? $author$project$Route$MainCategory(mainCategorySlug) : A2(
					routeHelper,
					mainCategorySlug,
					_Utils_Tuple2(id, slug));
				var $temp$routeHelper = routeHelper,
					$temp$mainCategorySlug = mainCategorySlug,
					$temp$acc = A2(
					$elm$core$List$cons,
					A2($author$project$Breadcrumb$Link, name, route),
					acc),
					$temp$maybeZipper = $zwilias$elm_rosetree$Tree$Zipper$parent(zipper);
				routeHelper = $temp$routeHelper;
				mainCategorySlug = $temp$mainCategorySlug;
				acc = $temp$acc;
				maybeZipper = $temp$maybeZipper;
				continue categoryLinksHelper;
			}
		}
	});
var $author$project$Breadcrumb$categoryLinks = function (zipper) {
	var routeHelper = $author$project$Route$Category($elm$core$Dict$empty);
	var mainCategorySlug = $author$project$Category$slug(
		$zwilias$elm_rosetree$Tree$Zipper$label(
			$zwilias$elm_rosetree$Tree$Zipper$root(zipper)));
	return A4(
		$author$project$Breadcrumb$categoryLinksHelper,
		routeHelper,
		mainCategorySlug,
		_List_Nil,
		$elm$core$Maybe$Just(zipper));
};
var $author$project$Order$displayId = function (order) {
	if (!order.$) {
		var internals_ = order.a;
		return 'W' + $elm$core$String$fromInt(
			$author$project$Id$toInt(internals_.hx));
	} else {
		var externalId_ = order.a;
		return $author$project$ExternalId$toString(externalId_);
	}
};
var $author$project$Bid$externalId = function (_v0) {
	var internals = _v0.a;
	return internals.hh;
};
var $author$project$Breadcrumb$homePage = A2($author$project$Breadcrumb$Link, 'Partnerzon', $author$project$Route$Home);
var $rtfeldman$elm_css$Css$Global$class = F2(
	function (str, styles) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
			styles,
			$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Structure$ClassSelector(str)
					])));
	});
var $rtfeldman$elm_css$Css$hover = $rtfeldman$elm_css$Css$pseudoClass('hover');
var $author$project$SE$UI$Colors$mapLightness = F2(
	function (f, _v0) {
		var hue = _v0.a;
		var saturation = _v0.b;
		var lightness = _v0.c;
		var alpha = _v0.d;
		return A4(
			$author$project$SE$UI$Colors$Hsla,
			hue,
			saturation,
			f(lightness),
			alpha);
	});
var $author$project$SE$UI$Colors$hover = $author$project$SE$UI$Colors$mapLightness(
	$elm$core$Basics$mul(0.9));
var $author$project$SE$UI$Colors$link = A4($author$project$SE$UI$Colors$Hsla, 214, 0.78, 0.47, 1);
var $author$project$Breadcrumb$aStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
			])),
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'svg',
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'transform', 'rotate(90deg)')
					]))
			])),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'icon',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
							]))
					]))
			]))
	]);
var $author$project$SE$UI$Icon$V2$angleDown = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		320,
		512,
		_List_fromArray(
			['M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'])));
var $author$project$Route$href = function (targetRoute) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$href(
		$author$project$Route$toString(targetRoute));
};
var $rtfeldman$elm_css$Html$Styled$li = $rtfeldman$elm_css$Html$Styled$node('li');
var $rtfeldman$elm_css$Css$Structure$PseudoElement = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$WithPseudoElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$pseudoElement = function (element) {
	return $rtfeldman$elm_css$Css$Preprocess$WithPseudoElement(element);
};
var $rtfeldman$elm_css$Css$before = $rtfeldman$elm_css$Css$pseudoElement('before');
var $rtfeldman$elm_css$Css$firstChild = $rtfeldman$elm_css$Css$pseudoClass('first-child');
var $rtfeldman$elm_css$Css$initial = {es: 0, B: 0, bG: 0, eu: 0, a7: 0, ev: 0, a8: 0, aD: 0, Q: 0, cn: 0, at: 0, a: 0, u: 0, bf: 0, dB: 0, bO: 0, cw: 0, aX: 0, L: 0, bg: 0, q: 0, aL: 0, cA: 0, fo: 0, dJ: 0, b: 0, bl: 0, aM: 0, bm: 0, bn: 0, aZ: 0, a_: 0, ax: 0, cE: 0, e: 0, c: 0, dR: 0, fD: 0, aO: 0, cP: 0, bp: 0, ap: 0, c6: 0, bv: 0, a4: 0, bw: 0, bx: 0, a5: 0, Y: 0, bz: '', cf: 0, iY: 'initial', ch: 0, aU: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{iY: 'inherit'});
var $rtfeldman$elm_css$Css$lastChild = $rtfeldman$elm_css$Css$pseudoClass('last-child');
var $author$project$SE$UI$Colors$light = A4($author$project$SE$UI$Colors$Hsla, 220, 0.03, 0.79, 1);
var $author$project$Helpers$nbr = '\u00A0';
var $rtfeldman$elm_css$Css$paddingLeft = $rtfeldman$elm_css$Css$prop1('padding-left');
var $rtfeldman$elm_css$Css$pointerEvents = $rtfeldman$elm_css$Css$prop1('pointer-events');
var $author$project$SE$UI$Colors$darkest = A4($author$project$SE$UI$Colors$Hsla, 210, 0.07, 0.06, 1);
var $author$project$SE$UI$Colors$text = $author$project$SE$UI$Colors$darkest;
var $author$project$Breadcrumb$liStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$firstChild(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'a',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$paddingLeft($rtfeldman$elm_css$Css$zero)
							]))
					]))
			])),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$adjacentSiblings(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'li',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$before(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$light),
										A2($rtfeldman$elm_css$Css$property, 'content', '\"' + ($author$project$Helpers$nbr + ('/' + ($author$project$Helpers$nbr + '\"'))))
									]))
							]))
					]))
			])),
		$rtfeldman$elm_css$Css$lastChild(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'a',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none),
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
							]))
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$pseudoClass,
		'not(:nth-last-of-type(2))',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
			])),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$pseudoClass,
				'not(:nth-last-of-type(2))',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inherit)
					]))
			]))
	]);
var $author$project$Constants$siteUrl = 'https://specialelektronik.se';
var $author$project$Breadcrumb$linkToHtml = F3(
	function (count, index, link) {
		var icon = _Utils_eq(count - 2, index) ? $author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$angleDown) : $rtfeldman$elm_css$Html$Styled$text('');
		var _v0 = function () {
			if (link.$ === 1) {
				var label_ = link.a;
				var targetRoute_ = link.b;
				return _Utils_Tuple2(
					label_,
					$author$project$Route$href(targetRoute_));
			} else {
				return _Utils_Tuple2(
					'Hem',
					$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Constants$siteUrl));
			}
		}();
		var label = _v0.a;
		var href = _v0.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$li,
			$author$project$Breadcrumb$liStyles,
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
					A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/ListItem'),
					A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemprop', 'itemListElement')
				]),
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$a,
					$author$project$Breadcrumb$aStyles,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemprop', 'item'),
							href
						]),
					_List_fromArray(
						[
							icon,
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemprop', 'name')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]))
						])),
					A3(
					$rtfeldman$elm_css$Html$Styled$node,
					'meta',
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemprop', 'position'),
							A2(
							$rtfeldman$elm_css$Html$Styled$Attributes$attribute,
							'content',
							$elm$core$String$fromInt(index + 1))
						]),
					_List_Nil)
				]));
	});
var $rtfeldman$elm_css$Html$Styled$nav = $rtfeldman$elm_css$Html$Styled$node('nav');
var $author$project$SE$UI$Font$desktopBodyScale = 2 / 18;
var $author$project$SE$UI$Font$mobileBodyScale = 2 / 16;
var $author$project$SE$UI$Font$bodySizeHelper = F2(
	function (fn, factor) {
		var newFactor = (factor >= 1) ? (factor - 1) : factor;
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$fontSize(
					fn(1 + ($author$project$SE$UI$Font$mobileBodyScale * newFactor))),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							fn(1 + ($author$project$SE$UI$Font$desktopBodyScale * newFactor)))
						]))
				]));
	});
var $author$project$SE$UI$Font$bodySizeRem = $author$project$SE$UI$Font$bodySizeHelper($rtfeldman$elm_css$Css$rem);
var $rtfeldman$elm_css$Css$noWrap = {bO: 0, cw: 0, iY: 'nowrap', aU: 0};
var $author$project$SE$UI$Utils$unselectable = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A2($rtfeldman$elm_css$Css$property, '-webkit-touch-callout', 'none'),
			A2($rtfeldman$elm_css$Css$property, '-webkit-user-select', 'none'),
			A2($rtfeldman$elm_css$Css$property, '-moz-user-select', 'none'),
			A2($rtfeldman$elm_css$Css$property, '-ms-user-select', 'none'),
			A2($rtfeldman$elm_css$Css$property, 'user-select', 'none')
		]));
var $author$project$Breadcrumb$navStyles = _List_fromArray(
	[
		$author$project$SE$UI$Utils$block,
		$author$project$SE$UI$Utils$unselectable,
		$author$project$SE$UI$Font$bodySizeRem(-2),
		$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap)
	]);
var $rtfeldman$elm_css$Html$Styled$ol = $rtfeldman$elm_css$Html$Styled$node('ol');
var $rtfeldman$elm_css$Css$flexStart = $rtfeldman$elm_css$Css$prop1('flex-start');
var $rtfeldman$elm_css$Css$flexWrap = $rtfeldman$elm_css$Css$prop1('flex-wrap');
var $rtfeldman$elm_css$Css$wrap = {bO: 0, cw: 0, iY: 'wrap'};
var $author$project$Breadcrumb$olStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart)
	]);
var $author$project$Breadcrumb$internalToHtml = function (links) {
	return $elm$core$List$isEmpty(links) ? $rtfeldman$elm_css$Html$Styled$text('') : A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$nav,
		$author$project$Breadcrumb$navStyles,
		_List_Nil,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$ol,
				$author$project$Breadcrumb$olStyles,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/BreadcrumbList')
					]),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Breadcrumb$linkToHtml(
						$elm$core$List$length(links)),
					links))
			]));
};
var $author$project$Brand$name = function (_v0) {
	var internals = _v0;
	return internals.hT;
};
var $author$project$Product$id = A2(
	$elm$core$Basics$composeR,
	$author$project$Product$internals,
	function ($) {
		return $.hx;
	});
var $author$project$Breadcrumb$productLinkHelper = F2(
	function (mainCatSlug, cat) {
		return A2(
			$author$project$Breadcrumb$Link,
			$author$project$Category$name(cat),
			A3(
				$author$project$Route$Category,
				$author$project$QueryParams$empty,
				mainCatSlug,
				_Utils_Tuple2(
					$author$project$Category$id(cat),
					$author$project$Category$slug(cat))));
	});
var $author$project$Product$slug = A2(
	$elm$core$Basics$composeR,
	$author$project$Product$internals,
	function ($) {
		return $.gb;
	});
var $author$project$Breadcrumb$productLinks = function (product) {
	var slug = $author$project$Product$slug(product);
	var metadata = $author$project$Product$metadata(product);
	var id = $author$project$Product$id(product);
	var link = A2(
		$author$project$Breadcrumb$Link,
		metadata.hT,
		A2(
			$author$project$Route$Product,
			$author$project$QueryParams$empty,
			_Utils_Tuple2(id, slug)));
	var _v0 = metadata.gV;
	if (!_v0.b) {
		return _List_fromArray(
			[link]);
	} else {
		var mainCat = _v0.a;
		var cats = _v0.b;
		return A2(
			$elm$core$List$cons,
			A2(
				$author$project$Breadcrumb$Link,
				$author$project$Category$name(mainCat),
				$author$project$Route$MainCategory(
					$author$project$Category$slug(mainCat))),
			_Utils_ap(
				A2(
					$elm$core$List$map,
					$author$project$Breadcrumb$productLinkHelper(
						$author$project$Category$slug(mainCat)),
					cats),
				_List_fromArray(
					[link])));
	}
};
var $author$project$Breadcrumb$toHtml = function (bc) {
	return $author$project$Breadcrumb$internalToHtml(
		function () {
			switch (bc.$) {
				case 0:
					return _List_fromArray(
						[$author$project$Breadcrumb$MainSite, $author$project$Breadcrumb$homePage]);
				case 1:
					var cat = bc.a;
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2(
							$author$project$Breadcrumb$Link,
							$author$project$Category$name(cat),
							$author$project$Route$MainCategory(
								$author$project$Category$slug(cat)))
						]);
				case 2:
					var z = bc.a;
					return A2(
						$elm$core$List$cons,
						$author$project$Breadcrumb$MainSite,
						A2(
							$elm$core$List$cons,
							$author$project$Breadcrumb$homePage,
							$author$project$Breadcrumb$categoryLinks(z)));
				case 3:
					var z = bc.a;
					var sticker = bc.b;
					var mainSlug = $author$project$Category$slug(
						$zwilias$elm_rosetree$Tree$Zipper$label(z));
					return A2(
						$elm$core$List$cons,
						$author$project$Breadcrumb$MainSite,
						A2(
							$elm$core$List$cons,
							$author$project$Breadcrumb$homePage,
							_Utils_ap(
								$author$project$Breadcrumb$categoryLinks(z),
								_List_fromArray(
									[
										A2(
										$author$project$Breadcrumb$Link,
										$author$project$Sticker$plural(sticker),
										A3($author$project$Route$Sticker, $author$project$QueryParams$empty, mainSlug, sticker))
									]))));
				case 4:
					var brand_ = bc.a;
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Varumärken', $author$project$Route$Brands),
							A2(
							$author$project$Breadcrumb$Link,
							$author$project$Brand$name(brand_),
							A2(
								$author$project$Route$Brand,
								$author$project$QueryParams$empty,
								$author$project$Brand$slug(brand_)))
						]);
				case 5:
					var order = bc.a;
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2(
							$author$project$Breadcrumb$Link,
							'Ordrar',
							$author$project$Route$Orders($author$project$QueryParams$empty)),
							A2(
							$author$project$Breadcrumb$Link,
							$author$project$Order$displayId(order),
							$author$project$Route$Order(
								$author$project$Order$internals(order).hx))
						]);
				case 6:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2(
							$author$project$Breadcrumb$Link,
							'Ordrar',
							$author$project$Route$Orders($author$project$QueryParams$empty))
						]);
				case 7:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2(
							$author$project$Breadcrumb$Link,
							'Fakturor',
							$author$project$Route$Invoices($author$project$QueryParams$empty))
						]);
				case 8:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'BID', $author$project$Route$Bids)
						]);
				case 9:
					var bid = bc.a;
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'BID', $author$project$Route$Bids),
							A2(
							$author$project$Breadcrumb$Link,
							$author$project$ExternalId$toString(
								$author$project$Bid$externalId(bid)),
							$author$project$Route$Bid(
								$author$project$Bid$id(bid)))
						]);
				case 10:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Konto', $author$project$Route$Account)
						]);
				case 11:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Konto', $author$project$Route$Account),
							A2($author$project$Breadcrumb$Link, 'Profil', $author$project$Route$AccountProfile)
						]);
				case 12:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Konto', $author$project$Route$Account),
							A2($author$project$Breadcrumb$Link, 'Generella inställningar', $author$project$Route$AccountSettings)
						]);
				case 13:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Konto', $author$project$Route$Account),
							A2($author$project$Breadcrumb$Link, 'Lösenord', $author$project$Route$AccountPassword)
						]);
				case 14:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Konto', $author$project$Route$Account),
							A2($author$project$Breadcrumb$Link, 'Administration', $author$project$Route$AccountAdministration)
						]);
				case 15:
					var product = bc.a;
					return A2(
						$elm$core$List$cons,
						$author$project$Breadcrumb$MainSite,
						A2(
							$elm$core$List$cons,
							$author$project$Breadcrumb$homePage,
							$author$project$Breadcrumb$productLinks(product)));
				case 16:
					var query = bc.a;
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2(
							$author$project$Breadcrumb$Link,
							'Sök: ' + query,
							$author$project$Route$Search(
								$elm$core$Dict$fromList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'q',
											_List_fromArray(
												[query]))
										]))))
						]);
				default:
					return _List_fromArray(
						[
							$author$project$Breadcrumb$MainSite,
							$author$project$Breadcrumb$homePage,
							A2($author$project$Breadcrumb$Link, 'Laddar...', $author$project$Route$Home)
						]);
			}
		}());
};
var $rtfeldman$elm_css$Css$borderColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-color', c.iY);
};
var $author$project$SE$UI$Colors$borderColor = A2($elm$core$Basics$composeR, $author$project$SE$UI$Colors$toCss, $rtfeldman$elm_css$Css$borderColor);
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Css$borderWidth = $rtfeldman$elm_css$Css$prop1('border-width');
var $rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.iY, argB.iY, argC.iY, argD.iY])));
	});
var $rtfeldman$elm_css$Css$boxShadow4 = $rtfeldman$elm_css$Css$prop4('box-shadow');
var $author$project$SE$UI$Card$cardStyles = function (hasBoxShadow) {
	return _List_fromArray(
		[
			$author$project$SE$UI$Utils$block,
			$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
			$rtfeldman$elm_css$Css$maxWidth(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
			$rtfeldman$elm_css$Css$borderWidth(
			$rtfeldman$elm_css$Css$px(1)),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
			hasBoxShadow ? A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(4),
			$rtfeldman$elm_css$Css$px(10),
			$author$project$SE$UI$Colors$toCss(
				A2(
					$author$project$SE$UI$Colors$mapAlpha,
					$elm$core$Basics$always(0.15),
					$author$project$SE$UI$Colors$black))) : $rtfeldman$elm_css$Css$batch(_List_Nil)
		]);
};
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $author$project$SE$UI$Card$contentStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$padding(
		$rtfeldman$elm_css$Css$rem(0.88888888))
	]);
var $rtfeldman$elm_css$Css$baseline = $rtfeldman$elm_css$Css$prop1('baseline');
var $rtfeldman$elm_css$Css$borderBottomStyle = $rtfeldman$elm_css$Css$prop1('border-bottom-style');
var $rtfeldman$elm_css$Css$makeImportant = function (str) {
	return A2(
		$elm$core$String$endsWith,
		' !important',
		$elm$core$String$toLower(str)) ? str : (str + ' !important');
};
var $rtfeldman$elm_css$Css$Preprocess$mapAllLastProperty = F2(
	function (update, styles) {
		if (!styles.b) {
			return styles;
		} else {
			if (!styles.b.b) {
				var only = styles.a;
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$Preprocess$mapLastProperty, update, only)
					]);
			} else {
				var first = styles.a;
				var rest = styles.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Preprocess$mapAllLastProperty, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$mapLastProperty = F2(
	function (update, style) {
		switch (style.$) {
			case 0:
				var property = style.a;
				return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(
					update(property));
			case 1:
				var selector = style.a;
				var styles = style.b;
				return A2(
					$rtfeldman$elm_css$Css$Preprocess$ExtendSelector,
					selector,
					A2($rtfeldman$elm_css$Css$Preprocess$mapAllLastProperty, update, styles));
			case 2:
				return style;
			case 3:
				return style;
			case 4:
				return style;
			case 5:
				return style;
			default:
				var otherStyles = style.a;
				return $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(
					A2(
						$rtfeldman$elm_css$Css$Structure$mapLast,
						$rtfeldman$elm_css$Css$Preprocess$mapLastProperty(update),
						otherStyles));
		}
	});
var $rtfeldman$elm_css$Css$important = $rtfeldman$elm_css$Css$Preprocess$mapLastProperty($rtfeldman$elm_css$Css$makeImportant);
var $author$project$SE$UI$Colors$lightBlue = A4($author$project$SE$UI$Colors$Hsla, 220, 0.27, 0.98, 1);
var $rtfeldman$elm_css$Css$margin = $rtfeldman$elm_css$Css$prop1('margin');
var $author$project$SE$UI$Card$headerStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightBlue),
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$baseline),
		$rtfeldman$elm_css$Css$borderBottomWidth(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$borderBottomStyle($rtfeldman$elm_css$Css$solid),
		$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
		$rtfeldman$elm_css$Css$padding(
		$rtfeldman$elm_css$Css$rem(0.55555555)),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
			])),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'h5',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero))
					]))
			]))
	]);
var $author$project$SE$UI$Font$bodySizeEm = $author$project$SE$UI$Font$bodySizeHelper($rtfeldman$elm_css$Css$em);
var $author$project$SE$UI$Card$subTitleToHtml = function (subTitle) {
	return $elm$core$String$isEmpty(subTitle) ? $rtfeldman$elm_css$Html$Styled$text('') : A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero),
				$author$project$SE$UI$Font$bodySizeEm(-2),
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$rem(0.88888888))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(subTitle)
			]));
};
var $rtfeldman$elm_css$Html$Styled$h5 = $rtfeldman$elm_css$Html$Styled$node('h5');
var $author$project$SE$UI$Title$title5 = A2(
	$author$project$SE$UI$Title$internalTitle,
	$rtfeldman$elm_css$Html$Styled$h5,
	_List_fromArray(
		[
			$author$project$SE$UI$Font$titleSizeEm(2)
		]));
var $author$project$SE$UI$Card$titleToHtml = function (_v0) {
	var title = _v0.gr;
	var subTitle = _v0.c4;
	return $elm$core$String$isEmpty(title) ? $rtfeldman$elm_css$Html$Styled$text('') : A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		$author$project$SE$UI$Card$headerStyles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('card-header', true)
					]))
			]),
		_List_fromArray(
			[
				$author$project$SE$UI$Title$title5(title),
				$author$project$SE$UI$Card$subTitleToHtml(subTitle)
			]));
};
var $author$project$SE$UI$Card$toHtml = function (_v0) {
	var internals = _v0;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		$author$project$SE$UI$Card$cardStyles(internals.cm),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('card', true)
					]))
			]),
		_List_fromArray(
			[
				$author$project$SE$UI$Card$titleToHtml(internals),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				$author$project$SE$UI$Card$contentStyles,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('card-content', true)
							]))
					]),
				internals.eN)
			]));
};
var $author$project$Page$AccountPassword = 2;
var $author$project$Page$AccountProfile = 3;
var $author$project$Page$AccountSettings = 4;
var $author$project$SE$UI$Tabs$V2$Tabs = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SE$UI$Alignment$Left = 0;
var $author$project$SE$UI$Tabs$V2$Unstyled = 0;
var $author$project$SE$UI$Tabs$V2$defaultInternals = {ci: 0, cy: false, d6: 0, c3: 0};
var $author$project$SE$UI$Tabs$V2$create = function (linkOrButtons) {
	return A2($author$project$SE$UI$Tabs$V2$Tabs, $author$project$SE$UI$Tabs$V2$defaultInternals, linkOrButtons);
};
var $author$project$SE$UI$Tabs$V2$Boxed = 1;
var $author$project$SE$UI$Tabs$V2$withStyle = F2(
	function (style, _v0) {
		var internals = _v0.a;
		var links = _v0.b;
		return A2(
			$author$project$SE$UI$Tabs$V2$Tabs,
			_Utils_update(
				internals,
				{c3: style}),
			links);
	});
var $author$project$SE$UI$Tabs$V2$isBoxed = $author$project$SE$UI$Tabs$V2$withStyle(1);
var $author$project$SE$UI$Tabs$V2$Link = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$SE$UI$Tabs$V2$link = $author$project$SE$UI$Tabs$V2$Link;
var $author$project$SE$UI$Tabs$V2$alignmentToClass = function (alignment) {
	switch (alignment) {
		case 0:
			return '';
		case 1:
			return 'is-centered';
		default:
			return 'is-right';
	}
};
var $author$project$SE$UI$Tabs$V2$sizeToClass = function (size) {
	switch (size) {
		case 0:
			return '';
		case 1:
			return 'is-small';
		case 2:
			return 'is-medium';
		default:
			return 'is-large';
	}
};
var $author$project$SE$UI$Tabs$V2$styleToClass = function (style) {
	switch (style) {
		case 0:
			return '';
		case 2:
			return 'is-toggle';
		default:
			return 'is-boxed';
	}
};
var $author$project$SE$UI$Tabs$V2$internalsToClasses = function (_v0) {
	var size = _v0.d6;
	var style = _v0.c3;
	var alignment = _v0.ci;
	var fullwidth = _v0.cy;
	return A2(
		$elm$core$List$map,
		function (a) {
			return _Utils_Tuple2(a, true);
		},
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$author$project$SE$UI$Tabs$V2$sizeToClass(size),
					$author$project$SE$UI$Tabs$V2$styleToClass(style),
					$author$project$SE$UI$Tabs$V2$alignmentToClass(alignment),
					fullwidth ? 'is-fullwidth' : ''
				])));
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$SE$UI$Tabs$V2$linkOrButtonToHtml = function (linkOrButton) {
	var _v0 = function () {
		if (!linkOrButton.$) {
			var isActive_ = linkOrButton.a;
			var url_ = linkOrButton.b;
			var html_ = linkOrButton.c;
			return {
				dj: _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(url_),
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('link', true)
							]))
					]),
				dw: $rtfeldman$elm_css$Html$Styled$a,
				dH: isActive_,
				dK: html_
			};
		} else {
			var isActive_ = linkOrButton.a;
			var onClickMsg_ = linkOrButton.b;
			var html_ = linkOrButton.c;
			return {
				dj: _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick(onClickMsg_),
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('link', true)
							]))
					]),
				dw: $rtfeldman$elm_css$Html$Styled$span,
				dH: isActive_,
				dK: html_
			};
		}
	}();
	var el = _v0.dw;
	var attrs = _v0.dj;
	var kids = _v0.dK;
	var isActive = _v0.dH;
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('is-active', isActive)
					]))
			]),
		_List_fromArray(
			[
				A2(el, attrs, kids)
			]));
};
var $rtfeldman$elm_css$Css$flexEnd = $rtfeldman$elm_css$Css$prop1('flex-end');
var $rtfeldman$elm_css$Css$flexGrow = $rtfeldman$elm_css$Css$prop1('flex-grow');
var $rtfeldman$elm_css$Css$flexShrink = $rtfeldman$elm_css$Css$prop1('flex-shrink');
var $rtfeldman$elm_css$Css$hidden = {Q: 0, bp: 0, iY: 'hidden', ch: 0};
var $rtfeldman$elm_css$Css$overflow = $rtfeldman$elm_css$Css$prop1('overflow');
var $author$project$SE$UI$Utils$overflowTouch = A2($rtfeldman$elm_css$Css$property, '-webkit-overflow-scrolling', 'touch');
var $rtfeldman$elm_css$Css$spaceBetween = $rtfeldman$elm_css$Css$prop1('space-between');
var $rtfeldman$elm_css$Css$stretch = $rtfeldman$elm_css$Css$prop1('stretch');
var $rtfeldman$elm_css$Css$borderBottomColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-bottom-color', c.iY);
};
var $rtfeldman$elm_css$Css$borderRadius4 = $rtfeldman$elm_css$Css$prop4('border-radius');
var $rtfeldman$elm_css$Css$transparent = {at: 0, iY: 'transparent'};
var $author$project$SE$UI$Tabs$V2$ulBoxedStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'link',
				_List_fromArray(
					[
						A3(
						$rtfeldman$elm_css$Css$border3,
						$rtfeldman$elm_css$Css$px(1),
						$rtfeldman$elm_css$Css$solid,
						$rtfeldman$elm_css$Css$transparent),
						A4($rtfeldman$elm_css$Css$borderRadius4, $author$project$SE$UI$Utils$radius, $author$project$SE$UI$Utils$radius, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$borderBottomColor(
						$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
						$rtfeldman$elm_css$Css$hover(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor(
								$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white)),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$borderBottomColor(
									$author$project$SE$UI$Colors$toCss(
										$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'li',
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$withClass,
						'is-active',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$class,
										'link',
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
												$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
												$rtfeldman$elm_css$Css$fontWeight(
												$rtfeldman$elm_css$Css$int(600)),
												$rtfeldman$elm_css$Css$important(
												$rtfeldman$elm_css$Css$borderBottomColor(
													$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$white)))
											]))
									]))
							]))
					]))
			]))
	]);
var $author$project$SE$UI$Tabs$V2$linkActiveStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$borderBottomColor(
		$author$project$SE$UI$Colors$toCss(
			$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link)
	]);
var $author$project$SE$UI$Tabs$V2$liActiveStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Global$class, 'link', $author$project$SE$UI$Tabs$V2$linkActiveStyles)
			]))
	]);
var $rtfeldman$elm_css$Css$block = {u: 0, iY: 'block'};
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$pointer = {a: 0, iY: 'pointer'};
var $author$project$SE$UI$Tabs$V2$linkStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$borderBottomColor(
		$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
		$rtfeldman$elm_css$Css$borderBottomStyle($rtfeldman$elm_css$Css$solid),
		$rtfeldman$elm_css$Css$borderBottomWidth(
		$rtfeldman$elm_css$Css$px(1)),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$marginBottom(
		$rtfeldman$elm_css$Css$px(-1)),
		A2(
		$rtfeldman$elm_css$Css$padding2,
		$rtfeldman$elm_css$Css$em(0.5),
		$rtfeldman$elm_css$Css$em(1)),
		$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$text)),
				$rtfeldman$elm_css$Css$borderBottomColor(
				$author$project$SE$UI$Colors$toCss(
					$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$text)))
			]))
	]);
var $author$project$SE$UI$Tabs$V2$liStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Global$class, 'link', $author$project$SE$UI$Tabs$V2$linkStyles)
			]))
	]);
var $author$project$SE$UI$Tabs$V2$ulStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$borderBottomColor(
		$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
		$rtfeldman$elm_css$Css$borderBottomStyle($rtfeldman$elm_css$Css$solid),
		$rtfeldman$elm_css$Css$borderBottomWidth(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$flexGrow(
		$rtfeldman$elm_css$Css$int(1)),
		$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'li',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$batch($author$project$SE$UI$Tabs$V2$liStyles),
						A2($rtfeldman$elm_css$Css$Global$withClass, 'is-active', $author$project$SE$UI$Tabs$V2$liActiveStyles)
					]))
			]))
	]);
var $author$project$SE$UI$Colors$active = $author$project$SE$UI$Colors$mapLightness(
	$elm$core$Basics$mul(0.8));
var $rtfeldman$elm_css$Css$borderBottom = $rtfeldman$elm_css$Css$prop1('border-bottom');
var $rtfeldman$elm_css$Css$borderBottomLeftRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-left-radius');
var $rtfeldman$elm_css$Css$borderBottomRightRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-right-radius');
var $rtfeldman$elm_css$Css$borderTopLeftRadius = $rtfeldman$elm_css$Css$prop1('border-top-left-radius');
var $rtfeldman$elm_css$Css$borderTopRightRadius = $rtfeldman$elm_css$Css$prop1('border-top-right-radius');
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $author$project$SE$UI$Tabs$V2$ulToggledStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$borderBottom($rtfeldman$elm_css$Css$zero),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'li',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$adjacentSiblings(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$typeSelector,
								'li',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$px(-1))
									]))
							])),
						$rtfeldman$elm_css$Css$firstChild(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$class,
										'link',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$borderTopLeftRadius($author$project$SE$UI$Utils$radius),
												$rtfeldman$elm_css$Css$borderBottomLeftRadius($author$project$SE$UI$Utils$radius)
											]))
									]))
							])),
						$rtfeldman$elm_css$Css$lastChild(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$class,
										'link',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$borderTopRightRadius($author$project$SE$UI$Utils$radius),
												$rtfeldman$elm_css$Css$borderBottomRightRadius($author$project$SE$UI$Utils$radius)
											]))
									]))
							])),
						$rtfeldman$elm_css$Css$Global$children(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$class,
								'link',
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
										$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
										$rtfeldman$elm_css$Css$borderWidth(
										$rtfeldman$elm_css$Css$px(1)),
										$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero)),
										$rtfeldman$elm_css$Css$hover(
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$backgroundColor(
												$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white)),
												$author$project$SE$UI$Colors$borderColor(
												$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border)),
												$rtfeldman$elm_css$Css$zIndex(
												$rtfeldman$elm_css$Css$int(2))
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$withClass,
						'is-active',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$class,
										'link',
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$pseudoClass,
												'not(:hover)',
												_List_fromArray(
													[
														$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$link),
														$author$project$SE$UI$Colors$borderColor(
														$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$link)),
														$author$project$SE$UI$Colors$color(
														$author$project$SE$UI$Colors$invert($author$project$SE$UI$Colors$link)),
														$rtfeldman$elm_css$Css$zIndex(
														$rtfeldman$elm_css$Css$int(1))
													]))
											]))
									]))
							]))
					]))
			]))
	]);
var $author$project$SE$UI$Tabs$V2$navStyle = _List_fromArray(
	[
		$author$project$SE$UI$Utils$block,
		$author$project$SE$UI$Utils$unselectable,
		$author$project$SE$UI$Utils$overflowTouch,
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$stretch),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$fontSize(
		$rtfeldman$elm_css$Css$rem(1)),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
		$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
		$rtfeldman$elm_css$Css$overflowX($rtfeldman$elm_css$Css$auto),
		$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Global$typeSelector, 'ul', $author$project$SE$UI$Tabs$V2$ulStyles)
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-small',
		_List_fromArray(
			[
				$author$project$SE$UI$Font$bodySizeRem(-2)
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-medium',
		_List_fromArray(
			[
				$author$project$SE$UI$Font$bodySizeRem(3)
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-large',
		_List_fromArray(
			[
				$author$project$SE$UI$Font$bodySizeRem(5)
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-toggle',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$Global$typeSelector, 'ul', $author$project$SE$UI$Tabs$V2$ulToggledStyles)
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-boxed',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$Global$typeSelector, 'ul', $author$project$SE$UI$Tabs$V2$ulBoxedStyles)
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-centered',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'ul',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
							]))
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-right',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'ul',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd)
							]))
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-fullwidth',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'li',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexGrow(
								$rtfeldman$elm_css$Css$int(1)),
								$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero)
							]))
					]))
			]))
	]);
var $rtfeldman$elm_css$Html$Styled$ul = $rtfeldman$elm_css$Html$Styled$node('ul');
var $author$project$SE$UI$Tabs$V2$toHtml = function (_v0) {
	var internals = _v0.a;
	var linkOrButtons = _v0.b;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$nav,
		$author$project$SE$UI$Tabs$V2$navStyle,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('tabs', true),
					$author$project$SE$UI$Tabs$V2$internalsToClasses(internals)))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_Nil,
				A2($elm$core$List$map, $author$project$SE$UI$Tabs$V2$linkOrButtonToHtml, linkOrButtons))
			]));
};
var $author$project$Page$viewAccountMenu = F2(
	function (page, _v0) {
		return $author$project$SE$UI$Tabs$V2$toHtml(
			$author$project$SE$UI$Tabs$V2$isBoxed(
				$author$project$SE$UI$Tabs$V2$create(
					_List_fromArray(
						[
							A3(
							$author$project$SE$UI$Tabs$V2$link,
							page === 3,
							$author$project$Route$toString($author$project$Route$AccountProfile),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Min profil')
								])),
							A3(
							$author$project$SE$UI$Tabs$V2$link,
							page === 4,
							$author$project$Route$toString($author$project$Route$AccountSettings),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Inställningar')
								])),
							A3(
							$author$project$SE$UI$Tabs$V2$link,
							page === 2,
							$author$project$Route$toString($author$project$Route$AccountPassword),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Lösenord')
								]))
						]))));
	});
var $rtfeldman$elm_css$Html$Styled$hr = $rtfeldman$elm_css$Html$Styled$node('hr');
var $author$project$Page$Account$viewAdministrator = function (user) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$Account$View$profile(user)
			]));
};
var $author$project$SE$UI$Card$withBoxShadow = function (_v0) {
	var internals = _v0;
	return _Utils_update(
		internals,
		{cm: true});
};
var $author$project$SE$UI$Card$withTitle = F2(
	function (title, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{gr: title});
	});
var $author$project$Page$Account$viewAdministrators = function (administrators) {
	var defaultDesc = _List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('En administratör kan lägga till, radera samt byta roll på användare i Partnerzonen.')
		]);
	var _v0 = function () {
		var _v1 = $elm$core$List$length(administrators);
		switch (_v1) {
			case 0:
				return _Utils_Tuple2(
					'Ingen administratör registrerad',
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:partnerzon@specialelektronik.se?subject=L%C3%A4gga%20till%20administrat%C3%B6r%20p%C3%A5%20Partnerzonen')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Kontakta oss')
								])),
							$rtfeldman$elm_css$Html$Styled$text(' för att registrera en administör som kan hantera era användare på Partnerzonen. ')
						]));
			case 1:
				return _Utils_Tuple2('Administratör', defaultDesc);
			default:
				return _Utils_Tuple2('Administratörer', defaultDesc);
		}
	}();
	var title = _v0.a;
	var desc = _v0.b;
	return $author$project$SE$UI$Card$toHtml(
		$author$project$SE$UI$Card$withBoxShadow(
			A2(
				$author$project$SE$UI$Card$withTitle,
				title,
				$author$project$SE$UI$Card$content(
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, desc),
							A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_Nil,
							A2($elm$core$List$map, $author$project$Page$Account$viewAdministrator, administrators))
						])))));
};
var $rtfeldman$elm_css$Css$absolute = {b1: 0, iY: 'absolute'};
var $rtfeldman$elm_css$Css$after = $rtfeldman$elm_css$Css$pseudoElement('after');
var $rtfeldman$elm_css$Css$borderBox = {dl: 0, cn: 0, iY: 'border-box'};
var $rtfeldman$elm_css$Css$boxSizing = $rtfeldman$elm_css$Css$prop1('box-sizing');
var $rtfeldman$elm_css$Css$borderRightColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-right-color', c.iY);
};
var $rtfeldman$elm_css$Css$borderTopColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-top-color', c.iY);
};
var $rtfeldman$elm_css$Css$ms = function (amount) {
	return {
		bM: 0,
		iY: $elm$core$String$fromFloat(amount) + 'ms'
	};
};
var $author$project$SE$UI$Utils$spinAround = $rtfeldman$elm_css$Css$Animations$keyframes(
	_List_fromArray(
		[
			_Utils_Tuple2(
			0,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Animations$transform(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$rotate(
							$rtfeldman$elm_css$Css$deg(0))
						]))
				])),
			_Utils_Tuple2(
			100,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Animations$transform(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$rotate(
							$rtfeldman$elm_css$Css$deg(359))
						]))
				]))
		]));
var $author$project$SE$UI$Utils$loader = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$animationName($author$project$SE$UI$Utils$spinAround),
			$rtfeldman$elm_css$Css$animationDuration(
			$rtfeldman$elm_css$Css$ms(500)),
			A2($rtfeldman$elm_css$Css$property, 'animation-iteration-count', 'infinite'),
			A2($rtfeldman$elm_css$Css$property, 'animation-timing-function', 'linear'),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$solid,
			$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$light)),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$pct(50)),
			$rtfeldman$elm_css$Css$borderRightColor($rtfeldman$elm_css$Css$transparent),
			$rtfeldman$elm_css$Css$borderTopColor($rtfeldman$elm_css$Css$transparent),
			A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$em(1)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$em(1))
		]));
var $rtfeldman$elm_css$Css$right = $rtfeldman$elm_css$Css$prop1('right');
var $author$project$SE$UI$Form$internalControl = F2(
	function (isExpanded, loading) {
		var loadingStyle = loading ? _List_fromArray(
			[
				$rtfeldman$elm_css$Css$after(
				_List_fromArray(
					[
						$author$project$SE$UI$Utils$loader,
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute)),
						$rtfeldman$elm_css$Css$right(
						$rtfeldman$elm_css$Css$em(0.625)),
						$rtfeldman$elm_css$Css$top(
						$rtfeldman$elm_css$Css$em(0.625)),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(4))
					]))
			]) : _List_Nil;
		return A3(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$boxSizing($rtfeldman$elm_css$Css$borderBox),
						A2($rtfeldman$elm_css$Css$property, 'clear', 'both'),
						$author$project$SE$UI$Font$bodySizeRem(1),
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left),
						isExpanded ? $rtfeldman$elm_css$Css$flexGrow(
						$rtfeldman$elm_css$Css$int(1)) : $rtfeldman$elm_css$Css$batch(_List_Nil)
					]),
				loadingStyle),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('control')
				]));
	});
var $author$project$SE$UI$Form$control = $author$project$SE$UI$Form$internalControl(false);
var $author$project$SE$UI$Form$fieldModifier = function (mod) {
	return $rtfeldman$elm_css$Css$batch(
		function () {
			if (!mod) {
				return _List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$class,
								'control',
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'not(:last-child)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginRight(
												$rtfeldman$elm_css$Css$px(-1))
											])),
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'not(:first-child):not(:last-child)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$each,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$Global$typeSelector('button'),
																$rtfeldman$elm_css$Css$Global$typeSelector('input'),
																$rtfeldman$elm_css$Css$Global$typeSelector('select')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$borderRadius($rtfeldman$elm_css$Css$zero)
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'first-child:not(:only-child)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$each,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$Global$typeSelector('button'),
																$rtfeldman$elm_css$Css$Global$typeSelector('input'),
																$rtfeldman$elm_css$Css$Global$typeSelector('select')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$borderBottomRightRadius($rtfeldman$elm_css$Css$zero),
																$rtfeldman$elm_css$Css$borderTopRightRadius($rtfeldman$elm_css$Css$zero)
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'last-child:not(:only-child)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$each,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$Global$typeSelector('button'),
																$rtfeldman$elm_css$Css$Global$typeSelector('input'),
																$rtfeldman$elm_css$Css$Global$typeSelector('select')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$borderBottomLeftRadius($rtfeldman$elm_css$Css$zero),
																$rtfeldman$elm_css$Css$borderTopLeftRadius($rtfeldman$elm_css$Css$zero)
															]))
													]))
											]))
									]))
							]))
					]);
			} else {
				return _List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
						$rtfeldman$elm_css$Css$Global$children(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$class,
								'control',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'not(:last-child)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero),
												$rtfeldman$elm_css$Css$marginRight(
												$rtfeldman$elm_css$Css$rem(0.75))
											]))
									]))
							]))
					]);
			}
		}());
};
var $author$project$SE$UI$Form$field = function (mods) {
	return A3(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$pseudoClass,
				'not(:last-child)',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$rem(0.75))
					])),
				$rtfeldman$elm_css$Css$batch(
				A2($elm$core$List$map, $author$project$SE$UI$Form$fieldModifier, mods))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('field')
			]));
};
var $author$project$SE$UI$Colors$danger = A4($author$project$SE$UI$Colors$Hsla, 0, 0.56, 0.55, 1);
var $rtfeldman$elm_css$Html$Styled$label = $rtfeldman$elm_css$Html$Styled$node('label');
var $author$project$SE$UI$Form$labelHelper = F2(
	function (required, s) {
		var star = required ? A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$color(
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$danger))
				]),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(' *')
				])) : $rtfeldman$elm_css$Html$Styled$text('');
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$label,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$color(
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$text)),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
					$author$project$SE$UI$Font$bodySizeRem(-2),
					$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
					$rtfeldman$elm_css$Css$fontWeight(
					$rtfeldman$elm_css$Css$int(600)),
					A2(
					$rtfeldman$elm_css$Css$pseudoClass,
					'not(:last-child)',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$em(0.5))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(s),
					star
				]));
	});
var $author$project$SE$UI$Form$label = $author$project$SE$UI$Form$labelHelper(false);
var $author$project$Status$withDefault = F2(
	function (_default, status) {
		switch (status.$) {
			case 1:
				var value = status.a;
				return value;
			case 2:
				var value = status.a;
				return value;
			default:
				return _default;
		}
	});
var $author$project$Page$Account$viewCompany = function (status) {
	var _v0 = A2(
		$author$project$Status$withDefault,
		_Utils_Tuple2('laddar...', 'Laddar...'),
		A2(
			$author$project$Status$map,
			function (company) {
				return _Utils_Tuple2(company.hi, company.hT);
			},
			status));
	var content = _v0.a;
	var title = _v0.b;
	return $author$project$SE$UI$Card$toHtml(
		$author$project$SE$UI$Card$withBoxShadow(
			A2(
				$author$project$SE$UI$Card$withTitle,
				title,
				$author$project$SE$UI$Card$content(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$SE$UI$Form$label('Kundnummer'),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(content)
										]))
								]))
						])))));
};
var $author$project$Page$viewGuestAccount = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('This should never have happened'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('As a guest, you should never end up on this page. Please contact us so that we can make sure others won\'t be stuck at this dead end as well.')
									]))
							]))
					]))
			])),
	gr: 'Wow, how did you end up here?'
};
var $author$project$Page$AccountAdministration = 1;
var $author$project$Page$Bids = 7;
var $author$project$Page$Invoices = 6;
var $author$project$Page$Orders = 5;
var $author$project$Page$viewLoggedInMenu = F2(
	function (page, user) {
		return $author$project$SE$UI$Tabs$V2$toHtml(
			$author$project$SE$UI$Tabs$V2$create(
				A2(
					$elm$core$List$cons,
					A3(
						$author$project$SE$UI$Tabs$V2$link,
						!page,
						$author$project$Route$toString($author$project$Route$Account),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Mitt konto')
							])),
					A2(
						$elm$core$List$cons,
						A3(
							$author$project$SE$UI$Tabs$V2$link,
							page === 5,
							$author$project$Route$toString(
								$author$project$Route$Orders($author$project$QueryParams$empty)),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Ordrar')
								])),
						A2(
							$elm$core$List$cons,
							A3(
								$author$project$SE$UI$Tabs$V2$link,
								page === 6,
								$author$project$Route$toString(
									$author$project$Route$Invoices($author$project$QueryParams$empty)),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Fakturor')
									])),
							A2(
								$elm$core$List$cons,
								A3(
									$author$project$SE$UI$Tabs$V2$link,
									page === 7,
									$author$project$Route$toString($author$project$Route$Bids),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('BID')
										])),
								(user.it === 1) ? _List_fromArray(
									[
										A3(
										$author$project$SE$UI$Tabs$V2$link,
										page === 1,
										$author$project$Route$toString($author$project$Route$AccountAdministration),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Användare')
											]))
									]) : _List_Nil))))));
	});
var $author$project$Page$Account$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Account),
								A2($author$project$Page$viewLoggedInMenu, 0, user),
								$author$project$SE$UI$Title$title1('Mitt konto'),
								A2($author$project$Page$viewAccountMenu, 0, user),
								$author$project$SE$UI$Card$toHtml(
								$author$project$SE$UI$Card$withBoxShadow(
									$author$project$SE$UI$Card$content(
										_List_fromArray(
											[
												$author$project$Page$Account$View$profile(user)
											])))),
								$author$project$Page$Account$viewCompany(model.bL),
								A2(
								$author$project$Status$withDefault,
								$rtfeldman$elm_css$Html$Styled$text(''),
								A2($author$project$Status$map, $author$project$Page$Account$viewAdministrators, model.bB))
							]))
					])),
			gr: 'Mitt konto'
		};
	}
};
var $author$project$Breadcrumb$AccountAdministration = {$: 14};
var $author$project$SE$UI$Columns$Desktop = 3;
var $author$project$SE$UI$Columns$OneThird = 5;
var $author$project$SE$UI$Columns$Column = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SE$UI$Columns$column = F2(
	function (sizes, html) {
		return A2($author$project$SE$UI$Columns$Column, sizes, html);
	});
var $author$project$SE$UI$Columns$Normal = 2;
var $author$project$SE$UI$Columns$columnGapHelper = function (gap) {
	switch (gap) {
		case 0:
			return 0;
		case 1:
			return 0.5;
		case 2:
			return 0.75;
		default:
			return 1;
	}
};
var $author$project$SE$UI$Columns$columnGap = function (gap) {
	return $rtfeldman$elm_css$Css$rem(
		$author$project$SE$UI$Columns$columnGapHelper(gap));
};
var $author$project$SE$UI$Columns$negativeColumnGap = function (gap) {
	return $rtfeldman$elm_css$Css$rem(
		$author$project$SE$UI$Columns$columnGapHelper(gap) * (-1));
};
var $author$project$SE$UI$Columns$columnsStyles = function (gap) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$marginLeft(
			$author$project$SE$UI$Columns$negativeColumnGap(gap)),
			$rtfeldman$elm_css$Css$marginRight(
			$author$project$SE$UI$Columns$negativeColumnGap(gap)),
			$rtfeldman$elm_css$Css$marginTop(
			$author$project$SE$UI$Columns$negativeColumnGap(gap)),
			A2(
			$rtfeldman$elm_css$Css$pseudoClass,
			'not(:last-child)',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginBottom(
					A3(
						$rtfeldman$elm_css$Css$calc,
						$rtfeldman$elm_css$Css$rem(1.5),
						$rtfeldman$elm_css$Css$minus,
						$author$project$SE$UI$Columns$columnGap(gap)))
				])),
			A2(
			$rtfeldman$elm_css$Css$pseudoClass,
			':last-child',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginBottom(
					$author$project$SE$UI$Columns$negativeColumnGap(gap))
				])),
			$author$project$SE$UI$Utils$desktop(
			_List_fromArray(
				[$rtfeldman$elm_css$Css$displayFlex])),
			$rtfeldman$elm_css$Css$Global$children(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$typeSelector,
					'div',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$padding(
							$author$project$SE$UI$Columns$columnGap(gap))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'is-multiline',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap)
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'is-mobile',
			_List_fromArray(
				[$rtfeldman$elm_css$Css$displayFlex]))
		]);
};
var $author$project$SE$UI$Columns$Mobile = 1;
var $author$project$SE$UI$Columns$isMobileColumn = function (_v0) {
	var sizes = _v0.a;
	return A2(
		$elm$core$List$member,
		1,
		A2($elm$core$List$map, $elm$core$Tuple$first, sizes));
};
var $author$project$SE$UI$Columns$isMobileColumns = function (cols) {
	return A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2($elm$core$List$map, $author$project$SE$UI$Columns$isMobileColumn, cols));
};
var $author$project$SE$UI$Utils$fullhdWidth = 1408;
var $author$project$SE$UI$Utils$fullhd = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$Media$only,
			$rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Media$minWidth(
					$rtfeldman$elm_css$Css$px($author$project$SE$UI$Utils$fullhdWidth))
				]))
		]));
var $rtfeldman$elm_css$Css$Media$maxWidth = function (value) {
	return A2($rtfeldman$elm_css$Css$Media$feature, 'max-width', value);
};
var $author$project$SE$UI$Utils$tabletWidth = 769;
var $author$project$SE$UI$Utils$mobile = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$Media$only,
			$rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Media$maxWidth(
					$rtfeldman$elm_css$Css$px($author$project$SE$UI$Utils$tabletWidth - 1))
				]))
		]));
var $author$project$SE$UI$Utils$tablet = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$Media$only,
			$rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Media$minWidth(
					$rtfeldman$elm_css$Css$px($author$project$SE$UI$Utils$tabletWidth))
				]))
		]));
var $rtfeldman$elm_css$Css$flex = $rtfeldman$elm_css$Css$prop1('flex');
var $author$project$SE$UI$Columns$translateWidth = function (width) {
	switch (width) {
		case 0:
			return $rtfeldman$elm_css$Css$batch(_List_Nil);
		case 1:
			return $rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none);
		case 2:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100 / 6))
					]));
		case 3:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(20))
					]));
		case 4:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(25))
					]));
		case 5:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100 / 3))
					]));
		case 6:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(40))
					]));
		case 7:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(50))
					]));
		case 8:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(60))
					]));
		case 9:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100 / 1.5))
					]));
		case 10:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(75))
					]));
		case 11:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(80))
					]));
		case 12:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct((100 / 6) * 5))
					]));
		default:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flex($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100))
					]));
	}
};
var $author$project$SE$UI$Utils$widescreenWidth = 1216;
var $author$project$SE$UI$Utils$widescreen = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$Media$only,
			$rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Media$minWidth(
					$rtfeldman$elm_css$Css$px($author$project$SE$UI$Utils$widescreenWidth))
				]))
		]));
var $author$project$SE$UI$Columns$columnSize = function (_v0) {
	var device = _v0.a;
	var width = _v0.b;
	switch (device) {
		case 0:
			return $author$project$SE$UI$Columns$translateWidth(width);
		case 1:
			return $author$project$SE$UI$Utils$mobile(
				_List_fromArray(
					[
						$author$project$SE$UI$Columns$translateWidth(width)
					]));
		case 2:
			return $author$project$SE$UI$Utils$tablet(
				_List_fromArray(
					[
						$author$project$SE$UI$Columns$translateWidth(width)
					]));
		case 3:
			return $author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						$author$project$SE$UI$Columns$translateWidth(width)
					]));
		case 4:
			return $author$project$SE$UI$Utils$widescreen(
				_List_fromArray(
					[
						$author$project$SE$UI$Columns$translateWidth(width)
					]));
		default:
			return $author$project$SE$UI$Utils$fullhd(
				_List_fromArray(
					[
						$author$project$SE$UI$Columns$translateWidth(width)
					]));
	}
};
var $rtfeldman$elm_css$Css$flex3 = $rtfeldman$elm_css$Css$prop3('flex');
var $author$project$SE$UI$Columns$columnStyles = function (sizes) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
			A3(
			$rtfeldman$elm_css$Css$flex3,
			$rtfeldman$elm_css$Css$int(1),
			$rtfeldman$elm_css$Css$int(1),
			$rtfeldman$elm_css$Css$int(0)),
			$rtfeldman$elm_css$Css$batch(
			A2($elm$core$List$map, $author$project$SE$UI$Columns$columnSize, sizes))
		]);
};
var $author$project$SE$UI$Columns$toHtml = function (_v0) {
	var sizes = _v0.a;
	var html = _v0.b;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		$author$project$SE$UI$Columns$columnStyles(sizes),
		_List_Nil,
		html);
};
var $author$project$SE$UI$Columns$internalColumns = F3(
	function (gap, isMultiline, cols) {
		var isMobile = $author$project$SE$UI$Columns$isMobileColumns(cols);
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Columns$columnsStyles(gap),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('columns', true),
							_Utils_Tuple2('is-multiline', isMultiline),
							_Utils_Tuple2('is-mobile', isMobile)
						]))
				]),
			A2($elm$core$List$map, $author$project$SE$UI$Columns$toHtml, cols));
	});
var $author$project$SE$UI$Columns$columns = A2($author$project$SE$UI$Columns$internalColumns, 2, false);
var $author$project$SE$UI$Columns$defaultColumn = function (html) {
	return A2($author$project$SE$UI$Columns$Column, _List_Nil, html);
};
var $author$project$Role$hasAdminRights = function (role) {
	return role === 1;
};
var $author$project$SE$UI$Form$Attached = 0;
var $author$project$SE$UI$Buttons$Color = function (a) {
	return {$: 0, a: a};
};
var $author$project$SE$UI$Form$Input$Danger = {$: 1};
var $author$project$Page$Account$Administration$GotEmailInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$SE$UI$Form$Grouped = 1;
var $author$project$SE$UI$Buttons$Loading = {$: 4};
var $author$project$SE$UI$Form$Input$OnInput = 0;
var $author$project$SE$UI$Colors$Primary = 9;
var $author$project$Page$Account$Administration$SubmittedAddUser = {$: 2};
var $rtfeldman$elm_css$Html$Styled$button = $rtfeldman$elm_css$Html$Styled$node('button');
var $rtfeldman$elm_css$Css$active = $rtfeldman$elm_css$Css$pseudoClass('active');
var $rtfeldman$elm_css$Css$Transitions$BackgroundColor = 1;
var $rtfeldman$elm_css$Css$Transitions$Transition = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Transitions$durationTransition = F2(
	function (animation, duration) {
		return {ck: animation, cs: $elm$core$Maybe$Nothing, bM: duration, c8: $elm$core$Maybe$Nothing};
	});
var $rtfeldman$elm_css$Css$Transitions$backgroundColor = $rtfeldman$elm_css$Css$Transitions$durationTransition(1);
var $rtfeldman$elm_css$Css$boxShadow = $rtfeldman$elm_css$Css$prop1('box-shadow');
var $rtfeldman$elm_css$Css$Transitions$BoxShadow = 25;
var $rtfeldman$elm_css$Css$Transitions$boxShadow = $rtfeldman$elm_css$Css$Transitions$durationTransition(25);
var $author$project$SE$UI$Colors$background = $author$project$SE$UI$Colors$lightest;
var $author$project$SE$UI$Utils$centerEm = F2(
	function (width, height) {
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
					$rtfeldman$elm_css$Css$left(
					A3(
						$rtfeldman$elm_css$Css$calc,
						$rtfeldman$elm_css$Css$pct(50),
						$rtfeldman$elm_css$Css$minus,
						$rtfeldman$elm_css$Css$em(width / 2))),
					$rtfeldman$elm_css$Css$top(
					(!(!height)) ? A3(
						$rtfeldman$elm_css$Css$calc,
						$rtfeldman$elm_css$Css$pct(50),
						$rtfeldman$elm_css$Css$minus,
						$rtfeldman$elm_css$Css$em(height / 2)) : A3(
						$rtfeldman$elm_css$Css$calc,
						$rtfeldman$elm_css$Css$pct(50),
						$rtfeldman$elm_css$Css$minus,
						$rtfeldman$elm_css$Css$em(width / 2)))
				]));
	});
var $rtfeldman$elm_css$Css$disabled = $rtfeldman$elm_css$Css$pseudoClass('disabled');
var $rtfeldman$elm_css$Css$focus = $rtfeldman$elm_css$Css$pseudoClass('focus');
var $author$project$SE$UI$Colors$buy = A4($author$project$SE$UI$Colors$Hsla, 41, 0.7, 0.52, 1);
var $author$project$SE$UI$Colors$dark = A4($author$project$SE$UI$Colors$Hsla, 213, 0.06, 0.29, 1);
var $author$project$SE$UI$Colors$darkGreen = A4($author$project$SE$UI$Colors$Hsla, 188, 0.72, 0.14, 1);
var $author$project$SE$UI$Colors$toHsla = function (color_) {
	switch (color_) {
		case 0:
			return $author$project$SE$UI$Colors$white;
		case 1:
			return $author$project$SE$UI$Colors$lightest;
		case 2:
			return $author$project$SE$UI$Colors$lighter;
		case 3:
			return $author$project$SE$UI$Colors$light;
		case 4:
			return $author$project$SE$UI$Colors$base;
		case 5:
			return $author$project$SE$UI$Colors$dark;
		case 6:
			return $author$project$SE$UI$Colors$darker;
		case 7:
			return $author$project$SE$UI$Colors$darkest;
		case 8:
			return $author$project$SE$UI$Colors$black;
		case 9:
			return $author$project$SE$UI$Colors$primary;
		case 10:
			return $author$project$SE$UI$Colors$link;
		case 11:
			return $author$project$SE$UI$Colors$buy;
		case 12:
			return $author$project$SE$UI$Colors$danger;
		case 13:
			return $author$project$SE$UI$Colors$bargain;
		case 14:
			return $author$project$SE$UI$Colors$darkGreen;
		default:
			return $author$project$SE$UI$Colors$lightBlue;
	}
};
var $author$project$SE$UI$Buttons$buttonModifier = function (modifier) {
	switch (modifier.$) {
		case 0:
			var color = modifier.a;
			var hsla = $author$project$SE$UI$Colors$toHsla(color);
			var hover = $author$project$SE$UI$Colors$hover(hsla);
			var active = $author$project$SE$UI$Colors$active(hsla);
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600)),
						$author$project$SE$UI$Colors$color(
						$author$project$SE$UI$Colors$invert(hsla)),
						$author$project$SE$UI$Colors$backgroundColor(hsla),
						$rtfeldman$elm_css$Css$borderColor($rtfeldman$elm_css$Css$transparent),
						$rtfeldman$elm_css$Css$hover(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color(
								$author$project$SE$UI$Colors$invert(hover)),
								$author$project$SE$UI$Colors$backgroundColor(hover),
								$rtfeldman$elm_css$Css$borderColor($rtfeldman$elm_css$Css$transparent)
							])),
						$rtfeldman$elm_css$Css$active(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color(
								$author$project$SE$UI$Colors$invert(active)),
								$author$project$SE$UI$Colors$backgroundColor(active)
							])),
						$rtfeldman$elm_css$Css$disabled(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$author$project$SE$UI$Colors$backgroundColor(hsla))
							]))
					]));
		case 1:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
						$rtfeldman$elm_css$Css$borderColor($rtfeldman$elm_css$Css$transparent),
						$rtfeldman$elm_css$Css$hover(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor(
								$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$background)),
								$rtfeldman$elm_css$Css$borderColor($rtfeldman$elm_css$Css$transparent)
							])),
						$rtfeldman$elm_css$Css$focus(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor(
								$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$background))
							])),
						$rtfeldman$elm_css$Css$active(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor(
								$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$background))
							]))
					]));
		case 2:
			return $rtfeldman$elm_css$Css$batch(_List_Nil);
		case 3:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100))
					]));
		default:
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$color($rtfeldman$elm_css$Css$transparent)),
						$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$after(
						_List_fromArray(
							[
								$author$project$SE$UI$Utils$loader,
								A2($author$project$SE$UI$Utils$centerEm, 1, 1)
							]))
					]));
	}
};
var $author$project$SE$UI$Buttons$buttonModifiers = F2(
	function (callback, mods) {
		return $rtfeldman$elm_css$Css$batch(
			A2($elm$core$List$map, callback, mods));
	});
var $author$project$SE$UI$Buttons$buttonShadow = $rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none);
var $author$project$SE$UI$Buttons$buttonShadowHover = $rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none);
var $author$project$SE$UI$Control$controlLineHeight = $rtfeldman$elm_css$Css$em(1.5);
var $author$project$SE$UI$Utils$smallRadius = $rtfeldman$elm_css$Css$px(1);
var $author$project$SE$UI$Control$modifier = function (size) {
	return $rtfeldman$elm_css$Css$batch(
		function () {
			switch (size) {
				case 0:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$px(16)),
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius)
						]);
				case 1:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$px(12)),
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$smallRadius)
						]);
				case 2:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$px(20)),
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius)
						]);
				default:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$px(24)),
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius)
						]);
			}
		}());
};
var $rtfeldman$elm_css$Css$notAllowed = {a: 0, iY: 'not-allowed'};
var $rtfeldman$elm_css$Css$outline = $rtfeldman$elm_css$Css$prop1('outline');
var $author$project$SE$UI$Control$controlStyle = function (size) {
	return $rtfeldman$elm_css$Css$batch(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$property, '-moz-appearance', 'none'),
				A2($rtfeldman$elm_css$Css$property, '-webkit-appearance', 'none'),
				A2($rtfeldman$elm_css$Css$property, 'appearance', 'none'),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				A3(
				$rtfeldman$elm_css$Css$border3,
				$rtfeldman$elm_css$Css$px(1),
				$rtfeldman$elm_css$Css$solid,
				$rtfeldman$elm_css$Css$transparent),
				$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none),
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
				$rtfeldman$elm_css$Css$lineHeight($author$project$SE$UI$Control$controlLineHeight),
				A2($rtfeldman$elm_css$Css$property, 'padding', 'calc(0.5em - 1px) calc(0.75em - 1px)'),
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'padding', 'calc(0.75em - 1px)')
					])),
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
				$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
				$rtfeldman$elm_css$Css$focus(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none)
					])),
				$rtfeldman$elm_css$Css$active(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none)
					])),
				$rtfeldman$elm_css$Css$disabled(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$notAllowed)
					])),
				$author$project$SE$UI$Control$modifier(size)
			]));
};
var $author$project$SE$UI$Buttons$extractControlSize = function (mods) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (m, init) {
				if (m.$ === 2) {
					var s = m.a;
					return s;
				} else {
					return init;
				}
			}),
		0,
		mods);
};
var $rtfeldman$elm_css$Css$opacity = $rtfeldman$elm_css$Css$prop1('opacity');
var $rtfeldman$elm_css$Css$Transitions$propToString = function (prop) {
	switch (prop) {
		case 0:
			return 'background';
		case 1:
			return 'background-color';
		case 2:
			return 'background-position';
		case 3:
			return 'background-size';
		case 4:
			return 'border';
		case 5:
			return 'border-bottom';
		case 6:
			return 'border-bottom-color';
		case 7:
			return 'border-bottom-left-radius';
		case 8:
			return 'border-bottom-right-radius';
		case 9:
			return 'border-bottom-width';
		case 10:
			return 'border-color';
		case 11:
			return 'border-left';
		case 12:
			return 'border-left-color';
		case 13:
			return 'border-left-width';
		case 14:
			return 'border-radius';
		case 15:
			return 'border-right';
		case 16:
			return 'border-right-color';
		case 17:
			return 'border-right-width';
		case 18:
			return 'border-top';
		case 19:
			return 'border-top-color';
		case 20:
			return 'border-top-left-radius';
		case 21:
			return 'border-top-right-radius';
		case 22:
			return 'border-top-width';
		case 23:
			return 'border-width';
		case 24:
			return 'bottom';
		case 25:
			return 'box-shadow';
		case 26:
			return 'caret-color';
		case 27:
			return 'clip';
		case 28:
			return 'clip-path';
		case 29:
			return 'color';
		case 30:
			return 'column-count';
		case 31:
			return 'column-gap';
		case 32:
			return 'column-rule';
		case 33:
			return 'column-rule-color';
		case 34:
			return 'column-rule-width';
		case 35:
			return 'column-width';
		case 36:
			return 'columns';
		case 37:
			return 'filter';
		case 38:
			return 'flex';
		case 39:
			return 'flex-basis';
		case 40:
			return 'flex-grow';
		case 41:
			return 'flex-shrink';
		case 42:
			return 'font';
		case 43:
			return 'font-size';
		case 44:
			return 'font-size-adjust';
		case 45:
			return 'font-stretch';
		case 46:
			return 'font-variation-settings';
		case 47:
			return 'font-weight';
		case 48:
			return 'grid-column-gap';
		case 49:
			return 'grid-gap';
		case 50:
			return 'grid-row-gap';
		case 51:
			return 'height';
		case 52:
			return 'left';
		case 53:
			return 'letter-spacing';
		case 54:
			return 'line-height';
		case 55:
			return 'margin';
		case 56:
			return 'margin-bottom';
		case 57:
			return 'margin-left';
		case 58:
			return 'margin-right';
		case 59:
			return 'margin-top';
		case 60:
			return 'mask';
		case 61:
			return 'mask-position';
		case 62:
			return 'mask-size';
		case 63:
			return 'max-height';
		case 64:
			return 'max-width';
		case 65:
			return 'min-height';
		case 66:
			return 'min-width';
		case 67:
			return 'object-position';
		case 68:
			return 'offset';
		case 69:
			return 'offset-anchor';
		case 70:
			return 'offset-distance';
		case 71:
			return 'offset-path';
		case 72:
			return 'offset-rotate';
		case 73:
			return 'opacity';
		case 74:
			return 'order';
		case 75:
			return 'outline';
		case 76:
			return 'outline-color';
		case 77:
			return 'outline-offset';
		case 78:
			return 'outline-width';
		case 79:
			return 'padding';
		case 80:
			return 'padding-bottom';
		case 81:
			return 'padding-left';
		case 82:
			return 'padding-right';
		case 83:
			return 'padding-top';
		case 84:
			return 'right';
		case 85:
			return 'tab-size';
		case 86:
			return 'text-indent';
		case 87:
			return 'text-shadow';
		case 88:
			return 'top';
		case 89:
			return 'transform';
		case 90:
			return 'transform-origin';
		case 91:
			return 'vertical-align';
		case 92:
			return 'visibility';
		case 93:
			return 'width';
		case 94:
			return 'word-spacing';
		default:
			return 'z-index';
	}
};
var $rtfeldman$elm_css$Css$Transitions$timeToString = function (time) {
	return $elm$core$String$fromFloat(time) + 'ms';
};
var $rtfeldman$elm_css$Css$Transitions$timingFunctionToString = function (tf) {
	switch (tf.$) {
		case 0:
			return 'ease';
		case 1:
			return 'linear';
		case 2:
			return 'ease-in';
		case 3:
			return 'ease-out';
		case 4:
			return 'ease-in-out';
		case 5:
			return 'step-start';
		case 6:
			return 'step-end';
		default:
			var _float = tf.a;
			var float2 = tf.b;
			var float3 = tf.c;
			var float4 = tf.d;
			return 'cubic-bezier(' + ($elm$core$String$fromFloat(_float) + (' , ' + ($elm$core$String$fromFloat(float2) + (' , ' + ($elm$core$String$fromFloat(float3) + (' , ' + ($elm$core$String$fromFloat(float4) + ')')))))));
	}
};
var $rtfeldman$elm_css$Css$Transitions$transition = function (options) {
	var v = A3(
		$elm$core$String$slice,
		0,
		-1,
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, s) {
					var animation = _v0.ck;
					var duration = _v0.bM;
					var delay = _v0.cs;
					var timing = _v0.c8;
					return s + (A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Transitions$propToString(animation),
								$rtfeldman$elm_css$Css$Transitions$timeToString(duration),
								A2(
								$elm$core$Maybe$withDefault,
								'',
								A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Transitions$timeToString, delay)),
								A2(
								$elm$core$Maybe$withDefault,
								'',
								A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Transitions$timingFunctionToString, timing))
							])) + ',');
				}),
			'',
			options));
	return A2($rtfeldman$elm_css$Css$property, 'transition', v);
};
var $author$project$SE$UI$Buttons$buttonStyles = F2(
	function (mods, hasIcon) {
		var size = $author$project$SE$UI$Buttons$extractControlSize(mods);
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$property, 'padding', 'calc(0.5em - 1px) calc(1.5em - 1px)'),
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'padding', 'calc(0.75em - 1px) calc(1.5em - 1px)')
					])),
				$author$project$SE$UI$Control$controlStyle(size),
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
				$rtfeldman$elm_css$Css$borderWidth(
				$rtfeldman$elm_css$Css$px(1)),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
				$author$project$SE$UI$Buttons$buttonShadow,
				$rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$borderColor(
						$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border)),
						$author$project$SE$UI$Buttons$buttonShadowHover,
						$rtfeldman$elm_css$Css$Transitions$transition(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Transitions$backgroundColor(250),
								$rtfeldman$elm_css$Css$Transitions$boxShadow(250)
							])),
						A2(
						$rtfeldman$elm_css$Css$pseudoClass,
						'not(disabled)',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$zIndex(
								$rtfeldman$elm_css$Css$int(2))
							]))
					])),
				$rtfeldman$elm_css$Css$active(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$borderColor(
						$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$border))
					])),
				A2($author$project$SE$UI$Buttons$buttonModifiers, $author$project$SE$UI$Buttons$buttonModifier, mods),
				$rtfeldman$elm_css$Css$disabled(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
						$rtfeldman$elm_css$Css$borderColor($rtfeldman$elm_css$Css$transparent),
						$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$opacity(
						$rtfeldman$elm_css$Css$num(0.5))
					])),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$selector,
						'.icon',
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'first-child:not(:last-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginRight(
										$rtfeldman$elm_css$Css$em(0.1875))
									])),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'last-child:not(:first-child)',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginRight(
										A3(
											$rtfeldman$elm_css$Css$calc,
											$rtfeldman$elm_css$Css$em(-0.375),
											$rtfeldman$elm_css$Css$minus,
											$rtfeldman$elm_css$Css$px(1))),
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$em(0.1875))
									])),
								A2(
								$rtfeldman$elm_css$Css$pseudoClass,
								'first-child:last-child',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginRight(
										A3(
											$rtfeldman$elm_css$Css$calc,
											$rtfeldman$elm_css$Css$em(-0.375),
											$rtfeldman$elm_css$Css$minus,
											$rtfeldman$elm_css$Css$px(1)))
									]))
							]))
					])),
				hasIcon ? $rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$paddingLeft(
					$rtfeldman$elm_css$Css$em(3.25))) : $rtfeldman$elm_css$Css$batch(_List_Nil)
			]);
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$disabled = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('disabled');
var $author$project$SE$UI$Buttons$button = F3(
	function (mods, onPress, html) {
		var eventAttribs = function () {
			if (onPress.$ === 1) {
				return _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true)
					]);
			} else {
				var msg = onPress.a;
				return _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick(msg)
					]);
			}
		}();
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$button,
			A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
			eventAttribs,
			html);
	});
var $author$project$SE$UI$Form$Input$Email = function (a) {
	return {$: 7, a: a};
};
var $author$project$SE$UI$Form$Input$OnChange = 1;
var $author$project$SE$UI$Form$Input$email = F2(
	function (msg, value) {
		return $author$project$SE$UI$Form$Input$Email(
			{ct: false, k: _List_Nil, cJ: msg, hT: '', r: '', m: false, n: false, G: 1, iY: value});
	});
var $author$project$SE$UI$Form$expandedControl = $author$project$SE$UI$Form$internalControl(true);
var $rtfeldman$elm_css$Html$Styled$form = $rtfeldman$elm_css$Html$Styled$node('form');
var $rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onSubmit = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$SE$UI$Form$Input$Loading = {$: 3};
var $rtfeldman$elm_css$Css$backgroundImage = $rtfeldman$elm_css$Css$prop1('background-image');
var $rtfeldman$elm_css$Css$backgroundOrigin = $rtfeldman$elm_css$Css$prop1('background-origin');
var $rtfeldman$elm_css$Css$backgroundPosition = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'backgroundPosition',
		'background-position',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$backgroundRepeat = $rtfeldman$elm_css$Css$prop1('background-repeat');
var $rtfeldman$elm_css$Css$backgroundSize2 = $rtfeldman$elm_css$Css$prop2('background-size');
var $author$project$SE$UI$Form$Input$boolAttribute = F3(
	function (attrFn, val, attrs) {
		return A2(
			$elm$core$List$cons,
			attrFn(val),
			attrs);
	});
var $author$project$SE$UI$Form$Input$buttonColor = function (mods) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (m, carry) {
				switch (m.$) {
					case 0:
						return $author$project$SE$UI$Colors$primary;
					case 1:
						return $author$project$SE$UI$Colors$danger;
					default:
						return carry;
				}
			}),
		$author$project$SE$UI$Colors$link,
		mods);
};
var $author$project$SE$UI$Form$Input$checkboxLabelToHtml = function (label) {
	if (!label.$) {
		var str = label.a;
		return $rtfeldman$elm_css$Html$Styled$text(str);
	} else {
		var kids = label.b;
		return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, kids);
	}
};
var $rtfeldman$elm_css$Css$checked = $rtfeldman$elm_css$Css$pseudoClass('checked');
var $rtfeldman$elm_css$Html$Styled$Attributes$checked = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('checked');
var $author$project$SE$UI$Icon$V2$circle = 'data:image/svg+xml,%3csvg viewBox=\'0 0 16 16\' fill=\'white\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3ccircle cx=\'8\' cy=\'8\' r=\'3\'/%3e%3c/svg%3e';
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $author$project$SE$UI$Form$Input$extractControlSize = function (mods) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (m, init) {
				if (m.$ === 2) {
					var s = m.a;
					return s;
				} else {
					return init;
				}
			}),
		0,
		mods);
};
var $author$project$SE$UI$Form$Input$inputModifierStyle = function (modifier) {
	var style = F2(
		function (color, prop) {
			return $rtfeldman$elm_css$Css$batch(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$borderColor(color),
						$rtfeldman$elm_css$Css$focus(
						_List_fromArray(
							[
								prop,
								$author$project$SE$UI$Colors$borderColor(
								$author$project$SE$UI$Colors$active(color))
							])),
						$rtfeldman$elm_css$Css$active(
						_List_fromArray(
							[
								prop,
								$author$project$SE$UI$Colors$borderColor(
								$author$project$SE$UI$Colors$active(color))
							])),
						$rtfeldman$elm_css$Css$hover(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$borderColor(
								$author$project$SE$UI$Colors$hover(color))
							]))
					]));
		});
	switch (modifier.$) {
		case 0:
			return A2(
				style,
				$author$project$SE$UI$Colors$primary,
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 0 0 0.125em rgba(53, 157, 55, 0.25)'));
		case 1:
			return A2(
				style,
				$author$project$SE$UI$Colors$danger,
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 0 0 0.125em rgba(255,56,96, 0.25)'));
		case 2:
			return $rtfeldman$elm_css$Css$batch(_List_Nil);
		case 3:
			return $rtfeldman$elm_css$Css$batch(_List_Nil);
		default:
			return $rtfeldman$elm_css$Css$batch(_List_Nil);
	}
};
var $author$project$SE$UI$Form$Input$placeholder = function (c) {
	return $rtfeldman$elm_css$Css$batch(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$pseudoElement, 'placeholder', c),
				A2($rtfeldman$elm_css$Css$pseudoElement, '-webkit-input-placeholder', c),
				A2($rtfeldman$elm_css$Css$pseudoElement, '-ms-input-placeholder', c)
			]));
};
var $author$project$SE$UI$Form$Input$inputStyle = function (mods) {
	var size = $author$project$SE$UI$Form$Input$extractControlSize(mods);
	return _Utils_ap(
		_List_fromArray(
			[
				$author$project$SE$UI$Control$controlStyle(size),
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$darker),
				$rtfeldman$elm_css$Css$maxWidth(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', 'inset 0px 1px 2px rgba(0, 0, 0, 0.05)'),
				$author$project$SE$UI$Form$Input$placeholder(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
					])),
				$rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$borderColor(
						$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border))
					])),
				$rtfeldman$elm_css$Css$focus(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$link),
						A2($rtfeldman$elm_css$Css$property, 'box-shadow', 'inset 0px 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 0.125em rgba(50,115,220, 0.25)')
					])),
				$rtfeldman$elm_css$Css$active(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$link),
						A2($rtfeldman$elm_css$Css$property, 'box-shadow', 'inset 0px 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 0.125em rgba(50,115,220, 0.25)')
					]))
			]),
		A2($elm$core$List$map, $author$project$SE$UI$Form$Input$inputModifierStyle, mods));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$name = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('name');
var $rtfeldman$elm_css$Css$noRepeat = {a8: 0, aD: 0, iY: 'no-repeat'};
var $author$project$SE$UI$Form$Input$noneEmptyAttribute = F3(
	function (attrFn, val, attrs) {
		return (val !== '') ? A2(
			$elm$core$List$cons,
			attrFn(val),
			attrs) : attrs;
	});
var $author$project$SE$UI$Utils$onChange = function (handler) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'change',
		A2(
			$elm$json$Json$Decode$map,
			handler,
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'value']),
				$elm$json$Json$Decode$string)));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$readonly = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('readOnly');
var $rtfeldman$elm_css$Html$Styled$Attributes$required = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('required');
var $author$project$SE$UI$Icon$V2$tick = 'data:image/svg+xml,%3csvg viewBox=\'0 0 16 16\' fill=\'white\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath d=\'M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z\'/%3e%3c/svg%3e';
var $rtfeldman$elm_css$Html$Styled$Attributes$type_ = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('type');
var $rtfeldman$elm_css$Css$url = function (urlValue) {
	return {a7: 0, iY: 'url(' + (urlValue + ')')};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $author$project$SE$UI$Form$Input$buttonToHtml = F2(
	function (buttonType, rec) {
		var labelStyles = function () {
			var _v2 = rec.cB;
			if (!_v2.$) {
				return _List_fromArray(
					[
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]);
			} else {
				var styles = _v2.a;
				return styles;
			}
		}();
		var isLoading = A2($elm$core$List$member, $author$project$SE$UI$Form$Input$Loading, rec.k);
		var loadingStyles = isLoading ? _List_fromArray(
			[
				$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none),
				$rtfeldman$elm_css$Css$after(
				_List_fromArray(
					[
						$author$project$SE$UI$Utils$loader,
						A2($author$project$SE$UI$Utils$centerEm, 1, 1)
					])),
				$rtfeldman$elm_css$Css$checked(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundImage($rtfeldman$elm_css$Css$none)
					]))
			]) : _List_Nil;
		var isDisabled = isLoading || rec.ct;
		var cursor = isDisabled ? $rtfeldman$elm_css$Css$notAllowed : $rtfeldman$elm_css$Css$pointer;
		var _v0 = function () {
			if (!buttonType) {
				return {
					dn: $author$project$SE$UI$Icon$V2$tick,
					d1: $rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$em(0.25)),
					iT: 'checkbox'
				};
			} else {
				return {
					dn: $author$project$SE$UI$Icon$V2$circle,
					d1: $rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(100)),
					iT: 'radio'
				};
			}
		}();
		var type_ = _v0.iT;
		var bg = _v0.dn;
		var radius = _v0.d1;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$label,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
						A2($rtfeldman$elm_css$Css$property, 'gap', '0.5em'),
						$rtfeldman$elm_css$Css$cursor(cursor),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$rem(0.5)),
						$rtfeldman$elm_css$Css$firstChild(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$zero)
							]))
					]),
				labelStyles),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$input,
					_Utils_ap(
						$author$project$SE$UI$Form$Input$inputStyle(rec.k),
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, '-webkit-print-color-adjust', 'exact'),
									A2($rtfeldman$elm_css$Css$property, 'color-adjust', 'exact'),
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
									$rtfeldman$elm_css$Css$backgroundOrigin($rtfeldman$elm_css$Css$borderBox),
									A2($rtfeldman$elm_css$Css$property, '-webkit-user-select', 'none'),
									A2($rtfeldman$elm_css$Css$property, '-moz-user-select', 'none'),
									A2($rtfeldman$elm_css$Css$property, '-ms-user-select', 'none'),
									A2($rtfeldman$elm_css$Css$property, 'user-select', 'none'),
									$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$em(1)),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(1)),
									$rtfeldman$elm_css$Css$cursor(cursor),
									$rtfeldman$elm_css$Css$color(
									$author$project$SE$UI$Colors$toCss(
										$author$project$SE$UI$Form$Input$buttonColor(rec.k))),
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
									radius,
									$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$checked(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$backgroundImage(
											$rtfeldman$elm_css$Css$url('\"' + (bg + '\"'))),
											$rtfeldman$elm_css$Css$borderColor($rtfeldman$elm_css$Css$transparent),
											$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$currentColor),
											A2(
											$rtfeldman$elm_css$Css$backgroundSize2,
											$rtfeldman$elm_css$Css$pct(100),
											$rtfeldman$elm_css$Css$pct(100)),
											$rtfeldman$elm_css$Css$backgroundPosition($rtfeldman$elm_css$Css$center),
											$rtfeldman$elm_css$Css$backgroundRepeat($rtfeldman$elm_css$Css$noRepeat)
										])),
									$rtfeldman$elm_css$Css$disabled(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$opacity(
											$rtfeldman$elm_css$Css$num(0.7))
										]))
								]),
							loadingStyles)),
					A3(
						$author$project$SE$UI$Form$Input$noneEmptyAttribute,
						$rtfeldman$elm_css$Html$Styled$Attributes$value,
						rec.iY,
						A3(
							$author$project$SE$UI$Form$Input$noneEmptyAttribute,
							$rtfeldman$elm_css$Html$Styled$Attributes$name,
							rec.hT,
							A3(
								$author$project$SE$UI$Form$Input$boolAttribute,
								$rtfeldman$elm_css$Html$Styled$Attributes$readonly,
								rec.m,
								A3(
									$author$project$SE$UI$Form$Input$boolAttribute,
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled,
									isDisabled,
									A3(
										$author$project$SE$UI$Form$Input$boolAttribute,
										$rtfeldman$elm_css$Html$Styled$Attributes$required,
										rec.n,
										A3(
											$author$project$SE$UI$Form$Input$boolAttribute,
											$rtfeldman$elm_css$Html$Styled$Attributes$checked,
											rec.co,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_(type_),
													$author$project$SE$UI$Utils$onChange(
													$elm$core$Basics$always(rec.cJ))
												]))))))),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$elm$core$Basics$never,
					$author$project$SE$UI$Form$Input$checkboxLabelToHtml(rec.cB))
				]));
	});
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $author$project$SE$UI$Form$Input$onBlurWithTargetValue = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'blur',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue));
};
var $rtfeldman$elm_css$Html$Styled$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onInput = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue)));
};
var $author$project$SE$UI$Form$Input$triggerToAttribute = function (trigger) {
	switch (trigger) {
		case 0:
			return $rtfeldman$elm_css$Html$Styled$Events$onInput;
		case 1:
			return $author$project$SE$UI$Utils$onChange;
		default:
			return $author$project$SE$UI$Form$Input$onBlurWithTargetValue;
	}
};
var $author$project$SE$UI$Form$Input$initAttributes = function (_v0) {
	var trigger = _v0.G;
	var msg = _v0.cJ;
	var value = _v0.iY;
	var required = _v0.n;
	var disabled = _v0.ct;
	var readonly = _v0.m;
	var name = _v0.hT;
	return A3(
		$author$project$SE$UI$Form$Input$noneEmptyAttribute,
		$rtfeldman$elm_css$Html$Styled$Attributes$name,
		name,
		A3(
			$author$project$SE$UI$Form$Input$boolAttribute,
			$rtfeldman$elm_css$Html$Styled$Attributes$readonly,
			readonly,
			A3(
				$author$project$SE$UI$Form$Input$boolAttribute,
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled,
				disabled,
				A3(
					$author$project$SE$UI$Form$Input$boolAttribute,
					$rtfeldman$elm_css$Html$Styled$Attributes$required,
					required,
					_List_fromArray(
						[
							A2($author$project$SE$UI$Form$Input$triggerToAttribute, trigger, msg),
							$rtfeldman$elm_css$Html$Styled$Attributes$value(value)
						])))));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$max = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('max');
var $rtfeldman$elm_css$Html$Styled$Attributes$min = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('min');
var $rtfeldman$elm_css$Html$Styled$Attributes$pattern = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('pattern');
var $rtfeldman$elm_css$Html$Styled$Attributes$placeholder = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('placeholder');
var $author$project$SE$UI$Form$Input$dateToHtml = function (rec) {
	var attrs = A3(
		$author$project$SE$UI$Form$Input$noneEmptyAttribute,
		$rtfeldman$elm_css$Html$Styled$Attributes$placeholder,
		rec.r,
		A3(
			$author$project$SE$UI$Form$Input$noneEmptyAttribute,
			$rtfeldman$elm_css$Html$Styled$Attributes$max,
			rec.fw,
			A3(
				$author$project$SE$UI$Form$Input$noneEmptyAttribute,
				$rtfeldman$elm_css$Html$Styled$Attributes$min,
				rec.fy,
				_Utils_ap(
					$author$project$SE$UI$Form$Input$initAttributes(rec),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$pattern('(((2000|2400|2800|(19|2[0-9](0[48]|[2468][048]|[13579][26])))-02-29)|(((19|2[0-9])[0-9]{2})-02-(0[1-9]|1[0-9]|2[0-8]))|(((19|2[0-9])[0-9]{2})-(0[13578]|10|12)-(0[1-9]|[12][0-9]|3[01]))|(((19|2[0-9])[0-9]{2})-(0[469]|11)-(0[1-9]|[12][0-9]|30)))'),
							$rtfeldman$elm_css$Html$Styled$Attributes$type_('date')
						])))));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$input,
		$author$project$SE$UI$Form$Input$inputStyle(rec.k),
		attrs,
		_List_Nil);
};
var $author$project$SE$UI$Form$Input$generalToHtml = F2(
	function (type_, rec) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$input,
			$author$project$SE$UI$Form$Input$inputStyle(rec.k),
			A3(
				$author$project$SE$UI$Form$Input$noneEmptyAttribute,
				$rtfeldman$elm_css$Html$Styled$Attributes$placeholder,
				rec.r,
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Html$Styled$Attributes$type_(type_),
					$author$project$SE$UI$Form$Input$initAttributes(rec))),
			_List_Nil);
	});
var $author$project$SE$UI$Form$Input$maybeAttribute = F3(
	function (attrFn, maybeAttr, attrs) {
		if (!maybeAttr.$) {
			var val = maybeAttr.a;
			return A2(
				$elm$core$List$cons,
				attrFn(val),
				attrs);
		} else {
			return attrs;
		}
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$step = function (n) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'step', n);
};
var $author$project$SE$UI$Form$Input$numberToHtml = function (rec) {
	var attrs = A3(
		$author$project$SE$UI$Form$Input$noneEmptyAttribute,
		$rtfeldman$elm_css$Html$Styled$Attributes$placeholder,
		rec.r,
		A3(
			$author$project$SE$UI$Form$Input$maybeAttribute,
			A2($elm$core$Basics$composeR, $elm$core$String$fromFloat, $rtfeldman$elm_css$Html$Styled$Attributes$step),
			rec.d7,
			A3(
				$author$project$SE$UI$Form$Input$maybeAttribute,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					A2($elm$core$Basics$composeR, $elm$core$String$fromFloat, $rtfeldman$elm_css$Html$Styled$Attributes$max)),
				rec.cU,
				A3(
					$author$project$SE$UI$Form$Input$maybeAttribute,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Tuple$first,
						A2($elm$core$Basics$composeR, $elm$core$String$fromFloat, $rtfeldman$elm_css$Html$Styled$Attributes$min)),
					rec.cU,
					A2(
						$elm$core$List$cons,
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('number'),
						$author$project$SE$UI$Form$Input$initAttributes(rec))))));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$input,
		$author$project$SE$UI$Form$Input$inputStyle(rec.k),
		attrs,
		_List_Nil);
};
var $author$project$SE$UI$Form$Input$passwordAutocompleteToString = function (a) {
	if (!a) {
		return 'current-password';
	} else {
		return 'new-password';
	}
};
var $author$project$SE$UI$Form$Input$passwordToHtml = function (rec) {
	var attrs = A3(
		$author$project$SE$UI$Form$Input$noneEmptyAttribute,
		$rtfeldman$elm_css$Html$Styled$Attributes$placeholder,
		rec.r,
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$type_('password'),
			A2(
				$elm$core$List$cons,
				A2(
					$rtfeldman$elm_css$Html$Styled$Attributes$attribute,
					'autocomplete',
					$author$project$SE$UI$Form$Input$passwordAutocompleteToString(rec.dk)),
				$author$project$SE$UI$Form$Input$initAttributes(rec))));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$input,
		$author$project$SE$UI$Form$Input$inputStyle(rec.k),
		attrs,
		_List_Nil);
};
var $rtfeldman$elm_css$Html$Styled$option = $rtfeldman$elm_css$Html$Styled$node('option');
var $rtfeldman$elm_css$Html$Styled$Attributes$selected = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('selected');
var $author$project$SE$UI$Form$Input$option = F2(
	function (val, o) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$option,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$value(o.iY),
					$rtfeldman$elm_css$Html$Styled$Attributes$selected(
					_Utils_eq(o.iY, val))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(o.cB)
				]));
	});
var $rtfeldman$elm_css$Html$Styled$select = $rtfeldman$elm_css$Html$Styled$node('select');
var $author$project$SE$UI$Icon$V2$pathtoDataUri = function (path) {
	return '%3cpath d=\'' + (path + '\'/%3e');
};
var $author$project$SE$UI$Icon$V2$toDataUri = function (_v0) {
	var internals = _v0;
	var _v1 = internals.ep;
	var w = _v1.a;
	var h = _v1.b;
	var paths = _v1.c;
	return 'data:image/svg+xml,%3Csvg viewBox=\'0 0 ' + ($elm$core$String$fromInt(w) + (' ' + ($elm$core$String$fromInt(h) + ('\' fill=\'currentColor\' xmlns=\'http://www.w3.org/2000/svg\'%3E' + (A2(
		$elm$core$String$join,
		'',
		A2($elm$core$List$map, $author$project$SE$UI$Icon$V2$pathtoDataUri, paths)) + '%3C/svg%3E')))));
};
var $author$project$SE$UI$Form$Input$selectStyle = function (mods) {
	return _Utils_ap(
		$author$project$SE$UI$Form$Input$inputStyle(mods),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$px(16)),
				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
				$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none),
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
				A2(
				$rtfeldman$elm_css$Css$property,
				'background-image',
				'url(\"' + ($author$project$SE$UI$Icon$V2$toDataUri($author$project$SE$UI$Icon$V2$angleDown) + '\"), linear-gradient(to bottom, hsla(0, 0%, 96%, 1) 0%,hsla(0, 0%, 96%, 1) 100%)')),
				A2($rtfeldman$elm_css$Css$property, 'background-repeat', 'no-repeat, repeat'),
				A2($rtfeldman$elm_css$Css$property, 'background-position', 'right 0.75em top 50%, 0, 0'),
				A2($rtfeldman$elm_css$Css$property, 'background-size', '0.75em auto, 100%'),
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', 'none'),
				A2(
				$rtfeldman$elm_css$Css$pseudoElement,
				'ms-expand',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
					])),
				$rtfeldman$elm_css$Css$important(
				A2($rtfeldman$elm_css$Css$property, 'padding-right', 'calc(2.25em - 1px)'))
			]));
};
var $author$project$SE$UI$Form$Input$selectToHtml = function (rec) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$select,
		$author$project$SE$UI$Form$Input$selectStyle(rec.k),
		A3(
			$author$project$SE$UI$Form$Input$noneEmptyAttribute,
			$rtfeldman$elm_css$Html$Styled$Attributes$name,
			rec.hT,
			A3(
				$author$project$SE$UI$Form$Input$boolAttribute,
				$rtfeldman$elm_css$Html$Styled$Attributes$readonly,
				rec.m,
				A3(
					$author$project$SE$UI$Form$Input$boolAttribute,
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled,
					rec.ct,
					A3(
						$author$project$SE$UI$Form$Input$boolAttribute,
						$rtfeldman$elm_css$Html$Styled$Attributes$required,
						rec.n,
						_List_fromArray(
							[
								$author$project$SE$UI$Utils$onChange(rec.cJ)
							]))))),
		A2(
			$elm$core$List$cons,
			A2(
				$author$project$SE$UI$Form$Input$option,
				rec.iY,
				{cB: rec.r, iY: ''}),
			A2(
				$elm$core$List$map,
				$author$project$SE$UI$Form$Input$option(rec.iY),
				rec.dT)));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$rows = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $rtfeldman$elm_css$Html$Styled$textarea = $rtfeldman$elm_css$Html$Styled$node('textarea');
var $rtfeldman$elm_css$Css$maxHeight = $rtfeldman$elm_css$Css$prop1('max-height');
var $rtfeldman$elm_css$Css$resize = $rtfeldman$elm_css$Css$prop1('resize');
var $rtfeldman$elm_css$Css$vertical = {cW: 0, iY: 'vertical'};
var $rtfeldman$elm_css$Css$Structure$AttributeSelector = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$Css$Global$withAttribute = function (attribute) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$AttributeSelector(attribute));
};
var $author$project$SE$UI$Form$Input$textareaStyle = function (mods) {
	return _Utils_ap(
		$author$project$SE$UI$Form$Input$inputStyle(mods),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$rtfeldman$elm_css$Css$maxWidth(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$minWidth(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$em(0.625)),
				$rtfeldman$elm_css$Css$resize($rtfeldman$elm_css$Css$vertical),
				A2(
				$rtfeldman$elm_css$Css$pseudoClass,
				'not([rows])',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$maxHeight(
						$rtfeldman$elm_css$Css$px(600)),
						$rtfeldman$elm_css$Css$minHeight(
						$rtfeldman$elm_css$Css$px(120))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$withAttribute,
				'rows',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$initial)
					]))
			]));
};
var $author$project$SE$UI$Form$Input$textareaToHtml = function (rec) {
	var attrs = A3(
		$author$project$SE$UI$Form$Input$noneEmptyAttribute,
		$rtfeldman$elm_css$Html$Styled$Attributes$placeholder,
		rec.r,
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$rows(rec.cY),
			$author$project$SE$UI$Form$Input$initAttributes(rec)));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$textarea,
		$author$project$SE$UI$Form$Input$textareaStyle(rec.k),
		attrs,
		_List_Nil);
};
var $author$project$SE$UI$Form$Input$toHtml = function (input_) {
	switch (input_.$) {
		case 0:
			var rec = input_.a;
			return A2($author$project$SE$UI$Form$Input$generalToHtml, 'text', rec);
		case 1:
			var rec = input_.a;
			return $author$project$SE$UI$Form$Input$numberToHtml(rec);
		case 2:
			var rec = input_.a;
			return $author$project$SE$UI$Form$Input$textareaToHtml(rec);
		case 3:
			var rec = input_.a;
			return $author$project$SE$UI$Form$Input$dateToHtml(rec);
		case 7:
			var rec = input_.a;
			return A2($author$project$SE$UI$Form$Input$generalToHtml, 'email', rec);
		case 8:
			var rec = input_.a;
			return A2($author$project$SE$UI$Form$Input$generalToHtml, 'tel', rec);
		case 5:
			var rec = input_.a;
			return $author$project$SE$UI$Form$Input$selectToHtml(rec);
		case 4:
			var rec = input_.a;
			return $author$project$SE$UI$Form$Input$passwordToHtml(rec);
		default:
			var type_ = input_.a;
			var rec = input_.b;
			return A2($author$project$SE$UI$Form$Input$buttonToHtml, type_, rec);
	}
};
var $author$project$Page$Account$Administration$viewAddUserError = function (error) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$rem(0.75)),
				$rtfeldman$elm_css$Css$marginTop(
				$rtfeldman$elm_css$Css$rem(0.25)),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(error)
			]));
};
var $author$project$Helpers$viewIf = F2(
	function (predicate, html) {
		return predicate ? html : $rtfeldman$elm_css$Html$Styled$text('');
	});
var $author$project$SE$UI$Form$Input$Button = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$SE$UI$Form$Input$Date = function (a) {
	return {$: 3, a: a};
};
var $author$project$SE$UI$Form$Input$Number = function (a) {
	return {$: 1, a: a};
};
var $author$project$SE$UI$Form$Input$Password = function (a) {
	return {$: 4, a: a};
};
var $author$project$SE$UI$Form$Input$Select = function (a) {
	return {$: 5, a: a};
};
var $author$project$SE$UI$Form$Input$Tel = function (a) {
	return {$: 8, a: a};
};
var $author$project$SE$UI$Form$Input$Text = function (a) {
	return {$: 0, a: a};
};
var $author$project$SE$UI$Form$Input$Textarea = function (a) {
	return {$: 2, a: a};
};
var $author$project$SE$UI$Form$Input$withModifier = F2(
	function (mod, input) {
		var newRec = function (rec) {
			return _Utils_update(
				rec,
				{
					k: A2($elm$core$List$cons, mod, rec.k)
				});
		};
		switch (input.$) {
			case 0:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Text(
					newRec(rec));
			case 1:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Number(
					newRec(rec));
			case 2:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Textarea(
					newRec(rec));
			case 3:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Date(
					newRec(rec));
			case 4:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Password(
					newRec(rec));
			case 5:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Select(
					newRec(rec));
			case 7:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Email(
					newRec(rec));
			case 8:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Tel(
					newRec(rec));
			default:
				var type_ = input.a;
				var rec = input.b;
				return A2(
					$author$project$SE$UI$Form$Input$Button,
					type_,
					newRec(rec));
		}
	});
var $author$project$SE$UI$Form$Input$withPlaceholder = F2(
	function (placeholder_, input) {
		switch (input.$) {
			case 0:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Text(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 1:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Number(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 2:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Textarea(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 3:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Date(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 4:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Password(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 5:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Select(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 7:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Email(
					_Utils_update(
						rec,
						{r: placeholder_}));
			case 8:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Tel(
					_Utils_update(
						rec,
						{r: placeholder_}));
			default:
				return input;
		}
	});
var $author$project$SE$UI$Form$Input$withRequired = function (input) {
	switch (input.$) {
		case 0:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Text(
				_Utils_update(
					rec,
					{n: true}));
		case 1:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Number(
				_Utils_update(
					rec,
					{n: true}));
		case 2:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Textarea(
				_Utils_update(
					rec,
					{n: true}));
		case 3:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Date(
				_Utils_update(
					rec,
					{n: true}));
		case 4:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Password(
				_Utils_update(
					rec,
					{n: true}));
		case 5:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Select(
				_Utils_update(
					rec,
					{n: true}));
		case 7:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Email(
				_Utils_update(
					rec,
					{n: true}));
		case 8:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Tel(
				_Utils_update(
					rec,
					{n: true}));
		default:
			var type_ = input.a;
			var rec = input.b;
			return A2(
				$author$project$SE$UI$Form$Input$Button,
				type_,
				_Utils_update(
					rec,
					{n: true}));
	}
};
var $author$project$SE$UI$Form$Input$withTrigger = F2(
	function (trigger, input) {
		switch (input.$) {
			case 0:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Text(
					_Utils_update(
						rec,
						{G: trigger}));
			case 1:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Number(
					_Utils_update(
						rec,
						{G: trigger}));
			case 2:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Textarea(
					_Utils_update(
						rec,
						{G: trigger}));
			case 3:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Date(
					_Utils_update(
						rec,
						{G: trigger}));
			case 4:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Password(
					_Utils_update(
						rec,
						{G: trigger}));
			case 7:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Email(
					_Utils_update(
						rec,
						{G: trigger}));
			case 8:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Tel(
					_Utils_update(
						rec,
						{G: trigger}));
			case 6:
				return input;
			default:
				return input;
		}
	});
var $author$project$Page$Account$Administration$viewAddUser = function (form) {
	var _v0 = function () {
		if (!form.$) {
			var email_ = form.a;
			var errorMsg_ = form.b;
			return {
				be: email_,
				dz: errorMsg_,
				dI: false,
				dL: $elm$core$Maybe$Just($author$project$Page$Account$Administration$SubmittedAddUser)
			};
		} else {
			var email_ = form.a;
			return {be: email_, dz: '', dI: true, dL: $elm$core$Maybe$Nothing};
		}
	}();
	var isLoading = _v0.dI;
	var maybeMsg = _v0.dL;
	var email = _v0.be;
	var errorMsg = _v0.dz;
	var buttonMods = isLoading ? _List_fromArray(
		[$author$project$SE$UI$Buttons$Loading]) : _List_Nil;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[$author$project$SE$UI$Utils$block]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$form,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Page$Account$Administration$SubmittedAddUser)
					]),
				_List_fromArray(
					[
						$author$project$SE$UI$Form$label('Skapa konto åt anställd'),
						A2(
						$author$project$SE$UI$Form$field,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Form$expandedControl,
								isLoading,
								_List_fromArray(
									[
										$author$project$SE$UI$Form$Input$toHtml(
										A2(
											$author$project$SE$UI$Form$Input$withTrigger,
											0,
											((errorMsg === '') ? $elm$core$Basics$identity : $author$project$SE$UI$Form$Input$withModifier($author$project$SE$UI$Form$Input$Danger))(
												$author$project$SE$UI$Form$Input$withRequired(
													A2(
														$author$project$SE$UI$Form$Input$withPlaceholder,
														'E-mail',
														A2($author$project$SE$UI$Form$Input$email, $author$project$Page$Account$Administration$GotEmailInput, email))))))
									])),
								A2(
								$author$project$SE$UI$Form$control,
								false,
								_List_fromArray(
									[
										A3(
										$author$project$SE$UI$Buttons$button,
										A2(
											$elm$core$List$cons,
											$author$project$SE$UI$Buttons$Color(9),
											buttonMods),
										maybeMsg,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Skapa konto')
											]))
									]))
							])),
						A2(
						$author$project$Helpers$viewIf,
						!$elm$core$String$isEmpty(errorMsg),
						$author$project$Page$Account$Administration$viewAddUserError(errorMsg))
					]))
			]));
};
var $author$project$SE$UI$Buttons$Centered = 1;
var $author$project$Page$Account$Administration$ClickedConfirmRemoveUser = function (a) {
	return {$: 6, a: a};
};
var $author$project$SE$UI$Colors$Danger = 12;
var $author$project$Page$Account$Administration$DismissedModal = {$: 5};
var $author$project$SE$UI$Buttons$Text = {$: 1};
var $author$project$SE$UI$Buttons$buttonsModifier = function (mod) {
	return $rtfeldman$elm_css$Css$batch(
		function () {
			switch (mod) {
				case 0:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$typeSelector,
									'*',
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$pseudoClass,
											'not(:first-child)',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$borderBottomLeftRadius($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$borderTopLeftRadius($rtfeldman$elm_css$Css$zero)
												])),
											A2(
											$rtfeldman$elm_css$Css$pseudoClass,
											'not(:last-child)',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$borderBottomRightRadius($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$borderTopRightRadius($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$marginRight(
														$rtfeldman$elm_css$Css$px(-1)))
												])),
											A2(
											$rtfeldman$elm_css$Css$pseudoClass,
											':last-child',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$marginRight($rtfeldman$elm_css$Css$zero))
												]))
										]))
								]))
						]);
				case 1:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
						]);
				default:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd)
						]);
			}
		}());
};
var $author$project$SE$UI$Buttons$buttonsStyles = function (mods) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
			$rtfeldman$elm_css$Css$Global$children(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$typeSelector,
					'*',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(0.5)),
							A2(
							$rtfeldman$elm_css$Css$pseudoClass,
							'not(:last-child)',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginRight(
									$rtfeldman$elm_css$Css$rem(0.5))
								]))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Css$pseudoClass,
			'last-child',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$rem(-0.5))
				])),
			A2(
			$rtfeldman$elm_css$Css$pseudoClass,
			'not(:last-child)',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$rem(1))
				])),
			$rtfeldman$elm_css$Css$batch(
			A2($elm$core$List$map, $author$project$SE$UI$Buttons$buttonsModifier, mods))
		]);
};
var $author$project$SE$UI$Buttons$buttons = F2(
	function (mods, btns) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Buttons$buttonsStyles(mods),
			_List_Nil,
			btns);
	});
var $rtfeldman$elm_css$Css$fixed = {bG: 0, b1: 0, c6: 0, iY: 'fixed'};
var $author$project$SE$UI$Modal$closeStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$backgroundImage($rtfeldman$elm_css$Css$none),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px(40)),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
		$rtfeldman$elm_css$Css$right(
		$rtfeldman$elm_css$Css$px(20)),
		$rtfeldman$elm_css$Css$top(
		$rtfeldman$elm_css$Css$px(20)),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$px(40)),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
			])),
		$rtfeldman$elm_css$Css$focus(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
			])),
		$rtfeldman$elm_css$Css$active(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(
				$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$white))
			])),
		$rtfeldman$elm_css$Css$before(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$black)
			])),
		$rtfeldman$elm_css$Css$after(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$black)
			]))
	]);
var $author$project$SE$UI$Delete$Large = 3;
var $rtfeldman$elm_css$Css$Transitions$TransformOrigin = 90;
var $rtfeldman$elm_css$Css$Transitions$transformOrigin = $rtfeldman$elm_css$Css$Transitions$durationTransition(90);
var $rtfeldman$elm_css$Css$translateX = function (_v0) {
	var value = _v0.iY;
	return {
		gu: 0,
		iY: A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'translateX',
			_List_fromArray(
				[value]))
	};
};
var $rtfeldman$elm_css$Css$translateY = function (_v0) {
	var value = _v0.iY;
	return {
		gu: 0,
		iY: A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'translateY',
			_List_fromArray(
				[value]))
	};
};
var $author$project$SE$UI$Delete$afterAndbefore = _List_fromArray(
	[
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
		$rtfeldman$elm_css$Css$left(
		$rtfeldman$elm_css$Css$pct(50)),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
		$rtfeldman$elm_css$Css$top(
		$rtfeldman$elm_css$Css$pct(50)),
		$rtfeldman$elm_css$Css$transforms(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$translateX(
				$rtfeldman$elm_css$Css$pct(-50)),
				$rtfeldman$elm_css$Css$translateY(
				$rtfeldman$elm_css$Css$pct(-50)),
				$rtfeldman$elm_css$Css$rotate(
				$rtfeldman$elm_css$Css$deg(45))
			])),
		$rtfeldman$elm_css$Css$Transitions$transition(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
			]))
	]);
var $rtfeldman$elm_css$Css$border = $rtfeldman$elm_css$Css$prop1('border');
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			bD: alpha,
			bH: b,
			at: 0,
			bP: g,
			b3: r,
			iY: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $author$project$SE$UI$Delete$sizeToPx = function (size) {
	return $rtfeldman$elm_css$Css$px(
		function () {
			switch (size) {
				case 0:
					return 16;
				case 1:
					return 20;
				case 2:
					return 28;
				default:
					return 40;
			}
		}());
};
var $author$project$SE$UI$Delete$deleteStyles = function (size) {
	var pxSize = $author$project$SE$UI$Delete$sizeToPx(size);
	return _List_fromArray(
		[
			$author$project$SE$UI$Utils$unselectable,
			A2($rtfeldman$elm_css$Css$property, '-moz-appearance', 'none'),
			A2($rtfeldman$elm_css$Css$property, '-webkit-appearance', 'none'),
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 34, 41, 47, 0.2)),
			$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$initial),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$pct(50)),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A2($rtfeldman$elm_css$Css$property, 'pointer-events', 'auto'),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$px(0)),
			$rtfeldman$elm_css$Css$height(pxSize),
			$rtfeldman$elm_css$Css$maxHeight(pxSize),
			$rtfeldman$elm_css$Css$maxWidth(pxSize),
			$rtfeldman$elm_css$Css$minHeight(pxSize),
			$rtfeldman$elm_css$Css$minWidth(pxSize),
			$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
			$rtfeldman$elm_css$Css$width(pxSize),
			$rtfeldman$elm_css$Css$after(
			_Utils_ap(
				$author$project$SE$UI$Delete$afterAndbefore,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px(2))
					]))),
			$rtfeldman$elm_css$Css$before(
			_Utils_ap(
				$author$project$SE$UI$Delete$afterAndbefore,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(2)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(50))
					]))),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					A4($rtfeldman$elm_css$Css$rgba, 34, 41, 47, 0.3))
				])),
			$rtfeldman$elm_css$Css$focus(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					A4($rtfeldman$elm_css$Css$rgba, 34, 41, 47, 0.3))
				])),
			$rtfeldman$elm_css$Css$active(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					A4($rtfeldman$elm_css$Css$rgba, 34, 41, 47, 0.4))
				]))
		]);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $author$project$SE$UI$Utils$visuallyHiddenStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$important(
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute)),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
		A2($rtfeldman$elm_css$Css$property, 'clip', 'rect(1px, 1px, 1px, 1px)'),
		$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap)
	]);
var $author$project$SE$UI$Utils$visuallyHidden = F3(
	function (fn, attrs, children) {
		return A2(
			fn,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$SE$UI$Utils$visuallyHiddenStyles),
				attrs),
			children);
	});
var $author$project$SE$UI$Delete$helper = F3(
	function (size, styles, msg) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$button,
			_Utils_ap(
				$author$project$SE$UI$Delete$deleteStyles(size),
				styles),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(msg),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('delete', true)
						]))
				]),
			_List_fromArray(
				[
					A3(
					$author$project$SE$UI$Utils$visuallyHidden,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Stäng')
						]))
				]));
	});
var $author$project$SE$UI$Delete$large = $author$project$SE$UI$Delete$helper(3);
var $rtfeldman$elm_css$Css$bottom = $rtfeldman$elm_css$Css$prop1('bottom');
var $author$project$SE$UI$Modal$overlay = function (offset) {
	return $rtfeldman$elm_css$Css$batch(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$bottom(
				$rtfeldman$elm_css$Css$px(offset)),
				$rtfeldman$elm_css$Css$left(
				$rtfeldman$elm_css$Css$px(offset)),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$right(
				$rtfeldman$elm_css$Css$px(offset)),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$px(offset))
			]));
};
var $author$project$SE$UI$Modal$modalBackgroundStyles = _List_fromArray(
	[
		$author$project$SE$UI$Modal$overlay(0),
		$rtfeldman$elm_css$Css$backgroundColor(
		A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.7))
	]);
var $rtfeldman$elm_css$Css$VhUnits = 0;
var $rtfeldman$elm_css$Css$vh = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'vh');
var $author$project$SE$UI$Modal$modalContentStyles = _List_fromArray(
	[
		A2(
		$rtfeldman$elm_css$Css$margin2,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$px(20)),
		$rtfeldman$elm_css$Css$maxHeight(
		A3(
			$rtfeldman$elm_css$Css$calc,
			$rtfeldman$elm_css$Css$vh(100),
			$rtfeldman$elm_css$Css$minus,
			$rtfeldman$elm_css$Css$px(160))),
		$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$auto),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		$author$project$SE$UI$Utils$tablet(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto),
				$rtfeldman$elm_css$Css$maxHeight(
				A3(
					$rtfeldman$elm_css$Css$calc,
					$rtfeldman$elm_css$Css$vh(100),
					$rtfeldman$elm_css$Css$minus,
					$rtfeldman$elm_css$Css$px(40))),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$px(640))
			]))
	]);
var $author$project$SE$UI$Modal$modalStyles = _List_fromArray(
	[
		$author$project$SE$UI$Modal$overlay(0),
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
		$rtfeldman$elm_css$Css$zIndex(
		$rtfeldman$elm_css$Css$int(40))
	]);
var $author$project$SE$UI$Modal$modal = F2(
	function (closeMsg, c) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Modal$modalStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal', true)
						]))
				]),
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Modal$modalBackgroundStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(closeMsg),
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-background', true)
								]))
						]),
					_List_Nil),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Modal$modalContentStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-content', true)
								]))
						]),
					c),
					A2($author$project$SE$UI$Delete$large, $author$project$SE$UI$Modal$closeStyles, closeMsg)
				]));
	});
var $author$project$Page$Account$Administration$viewConfirmModal = function (maybeUser) {
	if (maybeUser.$ === 1) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var user = maybeUser.a;
		return A2(
			$author$project$SE$UI$Modal$modal,
			$author$project$Page$Account$Administration$DismissedModal,
			_List_fromArray(
				[
					$author$project$SE$UI$Card$toHtml(
					$author$project$SE$UI$Card$content(
						_List_fromArray(
							[
								$author$project$SE$UI$Title$title5('Vill du verkligen ta bort ' + (user.ho + (' ' + (user.hI + '?')))),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[$author$project$SE$UI$Utils$block]),
								_List_Nil,
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('OBS!')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' Användaren och kontakten hos oss kommer att inaktiveras. Personen kan inte längre beställa produkter från Special-Elektronik för ert företag, varken via webb, email eller telefon.')
									])),
								A2(
								$author$project$SE$UI$Buttons$buttons,
								_List_fromArray(
									[1]),
								_List_fromArray(
									[
										A3(
										$author$project$SE$UI$Buttons$button,
										_List_fromArray(
											[
												$author$project$SE$UI$Buttons$Color(12)
											]),
										$elm$core$Maybe$Just(
											$author$project$Page$Account$Administration$ClickedConfirmRemoveUser(user)),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Ja, ta verkligen bort denna användare')
											])),
										A3(
										$author$project$SE$UI$Buttons$button,
										_List_fromArray(
											[$author$project$SE$UI$Buttons$Text]),
										$elm$core$Maybe$Just($author$project$Page$Account$Administration$DismissedModal),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Nej, avbryt')
											]))
									]))
							])))
				]));
	}
};
var $rtfeldman$elm_css$Html$Styled$h3 = $rtfeldman$elm_css$Html$Styled$node('h3');
var $rtfeldman$elm_css$Css$Global$span = $rtfeldman$elm_css$Css$Global$typeSelector('span');
var $author$project$Page$Account$Administration$viewHelp = A2(
	$author$project$SE$UI$Content$content,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$author$project$SE$UI$Font$bodySizeEm(-1),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$span(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(400)),
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
								])),
							$rtfeldman$elm_css$Css$Global$h5(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero)
								]))
						]))
				]))
		]),
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$h3,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Rollbeskrivning och behörigheter')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$h5,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Medarbetare')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$span,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Du som har den här rollen kan')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('se priser och beställa')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('se dina ordrar')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('se kollegors ordrar')
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$h5,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Administratör')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$span,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Du som har den här rollen kan göra allt som en Medarbetare och dessutom')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('lägga till och ta bort kollegor')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('byta roller på kollegor')
						]))
				]))
		]));
var $rtfeldman$elm_css$Css$Global$a = $rtfeldman$elm_css$Css$Global$typeSelector('a');
var $rtfeldman$elm_css$Css$Global$code = $rtfeldman$elm_css$Css$Global$typeSelector('code');
var $author$project$SE$UI$Delete$Regular = 1;
var $author$project$SE$UI$Delete$regular = $author$project$SE$UI$Delete$helper(1);
var $author$project$SE$UI$Notification$delete = function (msg) {
	return A2(
		$author$project$SE$UI$Delete$regular,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$right(
				$rtfeldman$elm_css$Css$rem(0.5)),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$rem(0.5))
			]),
		msg);
};
var $rtfeldman$elm_css$Css$padding4 = $rtfeldman$elm_css$Css$prop4('padding');
var $author$project$SE$UI$Notification$padding = A4(
	$rtfeldman$elm_css$Css$padding4,
	$rtfeldman$elm_css$Css$rem(1.25),
	$rtfeldman$elm_css$Css$rem(2.5),
	$rtfeldman$elm_css$Css$rem(1.25),
	$rtfeldman$elm_css$Css$rem(1.5));
var $rtfeldman$elm_css$Css$Global$strong = $rtfeldman$elm_css$Css$Global$typeSelector('strong');
var $rtfeldman$elm_css$Css$textDecoration = $rtfeldman$elm_css$Css$prop1('text-decoration');
var $rtfeldman$elm_css$Css$underline = {bv: 0, iY: 'underline'};
var $author$project$SE$UI$Notification$internalNotification = F3(
	function (color, maybeMsg, content) {
		var button = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2($elm$core$Maybe$map, $author$project$SE$UI$Notification$delete, maybeMsg));
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Utils$block,
					$author$project$SE$UI$Colors$backgroundColor(color),
					$author$project$SE$UI$Colors$color(
					$author$project$SE$UI$Colors$invert(color)),
					$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
					$author$project$SE$UI$Notification$padding,
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$strong(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($rtfeldman$elm_css$Css$currentColor)
								])),
							$rtfeldman$elm_css$Css$Global$a(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($rtfeldman$elm_css$Css$currentColor),
									$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$underline),
									$rtfeldman$elm_css$Css$hover(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color(
											$author$project$SE$UI$Colors$hover(
												$author$project$SE$UI$Colors$invert(color)))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$each,
							_List_fromArray(
								[$rtfeldman$elm_css$Css$Global$code, $rtfeldman$elm_css$Css$Global$pre]),
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white)
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$selector,
							'pre code',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent)
								]))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('notification', true)
						]))
				]),
			A2($elm$core$List$cons, button, content));
	});
var $author$project$SE$UI$Notification$primary = $author$project$SE$UI$Notification$internalNotification($author$project$SE$UI$Colors$primary);
var $author$project$Page$viewMessage = function (message) {
	return $elm$core$String$isEmpty(message) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
		$author$project$SE$UI$Notification$primary,
		$elm$core$Maybe$Nothing,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(message)
			]));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$colspan = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$SE$UI$Notification$danger = $author$project$SE$UI$Notification$internalNotification($author$project$SE$UI$Colors$danger);
var $author$project$Api$errorToHtml = function (error) {
	switch (error.$) {
		case 0:
			var url = error.a;
			return $rtfeldman$elm_css$Html$Styled$text('Något blev fel, är detta rätt adress? (' + (url + ')'));
		case 1:
			return $rtfeldman$elm_css$Html$Styled$text('Fel: Det tog för lång tid att hämta informationen.');
		case 2:
			return $rtfeldman$elm_css$Html$Styled$text('Fel: Kan inte nå servern, är du offline?');
		case 3:
			if (error.a === 419) {
				return $rtfeldman$elm_css$Html$Styled$text('Fel: Sessionen har löpt ut. Ladda om sidan och försök igen.');
			} else {
				var statusCode = error.a;
				return $rtfeldman$elm_css$Html$Styled$text(
					'Fel status (' + ($elm$core$String$fromInt(statusCode) + ') meddelande från servern.'));
			}
		case 4:
			var body = error.a;
			return $rtfeldman$elm_css$Html$Styled$text('Felaktigt svar från från servern. (' + (body + ')'));
		case 5:
			var problem = error.a;
			return $rtfeldman$elm_css$Html$Styled$text(problem.fx);
		default:
			return A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('För att kunna göra detta måste du vara '),
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href('/login')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('inloggad')
							])),
						$rtfeldman$elm_css$Html$Styled$text('.')
					]));
	}
};
var $rtfeldman$elm_css$Html$Styled$td = $rtfeldman$elm_css$Html$Styled$node('td');
var $rtfeldman$elm_css$Html$Styled$tr = $rtfeldman$elm_css$Html$Styled$node('tr');
var $author$project$SE$UI$Table$V2$Fullwidth = 0;
var $author$project$SE$UI$Table$V2$Hoverable = 1;
var $author$project$SE$UI$Table$V2$Body = function (a) {
	return {$: 0, a: a};
};
var $author$project$SE$UI$Table$V2$Table = $elm$core$Basics$identity;
var $author$project$SE$UI$Table$V2$body = F2(
	function (attrs, kids) {
		return {
			bI: $author$project$SE$UI$Table$V2$Body(
				_Utils_Tuple2(attrs, kids)),
			bi: _List_Nil,
			bk: _List_Nil,
			cI: _List_Nil
		};
	});
var $rtfeldman$elm_css$Css$middle = $rtfeldman$elm_css$Css$prop1('middle');
var $rtfeldman$elm_css$Html$Styled$th = $rtfeldman$elm_css$Html$Styled$node('th');
var $author$project$SE$UI$Table$V2$bodyAttributes = function (body_) {
	if (!body_.$) {
		var _v1 = body_.a;
		var attrs = _v1.a;
		return attrs;
	} else {
		var _v2 = body_.a;
		var attrs = _v2.a;
		return attrs;
	}
};
var $rtfeldman$elm_css$VirtualDom$Styled$keyedNode = $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode;
var $rtfeldman$elm_css$Html$Styled$Keyed$node = $rtfeldman$elm_css$VirtualDom$Styled$keyedNode;
var $rtfeldman$elm_css$Html$Styled$tbody = $rtfeldman$elm_css$Html$Styled$node('tbody');
var $author$project$SE$UI$Table$V2$bodyToHtml = function (body_) {
	if (!body_.$) {
		var _v1 = body_.a;
		var kids = _v1.b;
		return A2($rtfeldman$elm_css$Html$Styled$tbody, _List_Nil, kids);
	} else {
		var _v2 = body_.a;
		var kids = _v2.b;
		return A3($rtfeldman$elm_css$Html$Styled$Keyed$node, 'tbody', _List_Nil, kids);
	}
};
var $rtfeldman$elm_css$Html$Styled$table = $rtfeldman$elm_css$Html$Styled$node('table');
var $rtfeldman$elm_css$Css$borderLeftWidth = $rtfeldman$elm_css$Css$prop1('border-left-width');
var $rtfeldman$elm_css$Css$borderRightWidth = $rtfeldman$elm_css$Css$prop1('border-right-width');
var $rtfeldman$elm_css$Css$borderWidth2 = $rtfeldman$elm_css$Css$prop2('border-width');
var $author$project$SE$UI$Table$V2$tableModifierStyle = function (mod) {
	if (!mod) {
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(100))
				]));
	} else {
		return $rtfeldman$elm_css$Css$batch(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$selector,
							'tbody tr:not([selected]):hover',
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lighter)
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$selector,
							'tbody tr:not([selected]):hover:nth-child(even)',
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$light)
								]))
						]))
				]));
	}
};
var $author$project$SE$UI$Table$V2$tableModifierStyles = function (mods) {
	return A2($elm$core$List$map, $author$project$SE$UI$Table$V2$tableModifierStyle, mods);
};
var $author$project$SE$UI$Table$V2$tableStyles = function (mods) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$batch(
			$author$project$SE$UI$Table$V2$tableModifierStyles(mods)),
			$author$project$SE$UI$Font$bodySizeRem(-2),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$each,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$typeSelector('td'),
							$rtfeldman$elm_css$Css$Global$typeSelector('th')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$padding2,
							$rtfeldman$elm_css$Css$rem(0.7),
							$rtfeldman$elm_css$Css$px(16)),
							$author$project$SE$UI$Utils$desktop(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$padding2,
									$rtfeldman$elm_css$Css$rem(0.5),
									$rtfeldman$elm_css$Css$px(16))
								])),
							A3(
							$rtfeldman$elm_css$Css$border3,
							$rtfeldman$elm_css$Css$px(10),
							$rtfeldman$elm_css$Css$solid,
							$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
							A2(
							$rtfeldman$elm_css$Css$borderWidth2,
							$rtfeldman$elm_css$Css$px(1),
							$rtfeldman$elm_css$Css$zero),
							A2(
							$rtfeldman$elm_css$Css$pseudoClass,
							'first-child',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$borderLeftWidth(
									$rtfeldman$elm_css$Css$px(1))
								])),
							A2(
							$rtfeldman$elm_css$Css$pseudoClass,
							'last-child',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$borderRightWidth(
									$rtfeldman$elm_css$Css$px(1))
								])),
							$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'tbody tr:not([selected]):nth-child(even)',
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background)
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'thead th',
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background)
						]))
				]))
		]);
};
var $author$project$SE$UI$Table$V2$helper = F3(
	function (fn, attrs, kids) {
		return $elm$core$List$isEmpty(kids) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(fn, attrs, kids);
	});
var $rtfeldman$elm_css$Html$Styled$tfoot = $rtfeldman$elm_css$Html$Styled$node('tfoot');
var $author$project$SE$UI$Table$V2$tfoot = $author$project$SE$UI$Table$V2$helper($rtfeldman$elm_css$Html$Styled$tfoot);
var $rtfeldman$elm_css$Html$Styled$thead = $rtfeldman$elm_css$Html$Styled$node('thead');
var $author$project$SE$UI$Table$V2$thead = $author$project$SE$UI$Table$V2$helper($rtfeldman$elm_css$Html$Styled$thead);
var $rtfeldman$elm_css$Css$overflowY = $rtfeldman$elm_css$Css$prop1('overflow-y');
var $author$project$SE$UI$Table$V2$wrapper = function (kids) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$block,
				$author$project$SE$UI$Utils$overflowTouch,
				$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$auto),
				$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$hidden),
				$rtfeldman$elm_css$Css$maxWidth(
				$rtfeldman$elm_css$Css$pct(100))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-container')
			]),
		_List_fromArray(
			[kids]));
};
var $author$project$SE$UI$Table$V2$toHtml = function (_v0) {
	var internals = _v0;
	return $author$project$SE$UI$Table$V2$wrapper(
		A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$table,
			$author$project$SE$UI$Table$V2$tableStyles(internals.cI),
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table'),
				$author$project$SE$UI$Table$V2$bodyAttributes(internals.bI)),
			_List_fromArray(
				[
					$elm$core$List$isEmpty(internals.bk) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
					$author$project$SE$UI$Table$V2$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$tr, _List_Nil, internals.bk)
						])),
					$author$project$SE$UI$Table$V2$bodyToHtml(internals.bI),
					$elm$core$List$isEmpty(internals.bi) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
					$author$project$SE$UI$Table$V2$tfoot,
					_List_Nil,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$tr, _List_Nil, internals.bi)
						]))
				])));
};
var $author$project$SE$UI$Table$V2$withHead = F2(
	function (rows, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{bk: rows});
	});
var $author$project$SE$UI$Table$V2$withModifiers = F2(
	function (mods, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{cI: mods});
	});
var $author$project$Page$Account$Administration$viewTableHelper = function (rows) {
	return $author$project$SE$UI$Table$V2$toHtml(
		A2(
			$author$project$SE$UI$Table$V2$withModifiers,
			_List_fromArray(
				[1, 0]),
			A2(
				$author$project$SE$UI$Table$V2$withHead,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Användare')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Sågs senast')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Roll')
							]))
					]),
				A2(
					$author$project$SE$UI$Table$V2$body,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$children(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Global$selector,
											'tbody td',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
												]))
										]))
								]))
						]),
					rows))));
};
var $author$project$Page$Account$Administration$viewFailed = function (error) {
	return $author$project$Page$Account$Administration$viewTableHelper(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
							]),
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Notification$danger,
								$elm$core$Maybe$Nothing,
								_List_fromArray(
									[
										$author$project$Api$errorToHtml(error)
									]))
							]))
					]))
			]));
};
var $author$project$Page$Account$Administration$viewLoading = $author$project$Page$Account$Administration$viewTableHelper(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('laddar...')
						]))
				]))
		]));
var $author$project$Page$Account$Administration$viewStatus = F2(
	function (loadedFn, status) {
		switch (status.$) {
			case 0:
				return $author$project$Page$Account$Administration$viewLoading;
			case 3:
				var error = status.a;
				return $author$project$Page$Account$Administration$viewFailed(error);
			case 1:
				var a = status.a;
				return loadedFn(a);
			default:
				var a = status.a;
				return loadedFn(a);
		}
	});
var $author$project$Page$Account$Administration$ClickedChangeRole = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$Account$Administration$ClickedRemoveUser = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Account$Administration$ClosedDropdown = {$: 10};
var $author$project$SE$UI$Icon$V2$FlipY = {$: 2};
var $author$project$Page$Account$Administration$ToggledDropdown = function (a) {
	return {$: 11, a: a};
};
var $author$project$Role$all = _List_fromArray(
	[0, 1]);
var $author$project$SE$UI$Dropdown$Button = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$SE$UI$Dropdown$button = $author$project$SE$UI$Dropdown$Button;
var $author$project$SE$UI$Dropdown$buttonToHtml = function (btn) {
	if (!btn.$) {
		var modifiers = btn.a;
		var onPress = btn.b;
		var html = btn.c;
		return A3($author$project$SE$UI$Buttons$button, modifiers, onPress, html);
	} else {
		var html = btn.a;
		return html;
	}
};
var $author$project$SE$UI$Dropdown$dropdownContentShadow = A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 2px 3px rgba(34, 41, 47, 0.1), 0 0 0 1px rgba(34, 41, 47, 0.1)');
var $rtfeldman$elm_css$Css$paddingBottom = $rtfeldman$elm_css$Css$prop1('padding-bottom');
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $author$project$SE$UI$Dropdown$contentStyles = _List_fromArray(
	[
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
		$author$project$SE$UI$Dropdown$dropdownContentShadow,
		$rtfeldman$elm_css$Css$paddingBottom(
		$rtfeldman$elm_css$Css$rem(0.5)),
		$rtfeldman$elm_css$Css$paddingTop(
		$rtfeldman$elm_css$Css$rem(0.5))
	]);
var $author$project$SE$UI$Dropdown$dropdownStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
		$rtfeldman$elm_css$Css$focus(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none)
			]))
	]);
var $author$project$SE$UI$Dropdown$hrStyles = _List_fromArray(
	[
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$border),
		$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px(1)),
		A2(
		$rtfeldman$elm_css$Css$margin2,
		$rtfeldman$elm_css$Css$rem(0.5),
		$rtfeldman$elm_css$Css$zero)
	]);
var $author$project$SE$UI$Dropdown$itemStyles = _List_fromArray(
	[
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
		$rtfeldman$elm_css$Css$fontSize(
		$rtfeldman$elm_css$Css$rem(0.875)),
		$rtfeldman$elm_css$Css$lineHeight(
		$rtfeldman$elm_css$Css$num(1.5)),
		A2(
		$rtfeldman$elm_css$Css$padding2,
		$rtfeldman$elm_css$Css$rem(0.375),
		$rtfeldman$elm_css$Css$rem(1)),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
	]);
var $rtfeldman$elm_css$Css$paddingRight = $rtfeldman$elm_css$Css$prop1('padding-right');
var $author$project$SE$UI$Dropdown$linkStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$paddingRight(
		$rtfeldman$elm_css$Css$rem(3)),
		$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left),
		$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$black)
			]))
	]);
var $author$project$SE$UI$Dropdown$itemToHtml = function (item) {
	switch (item.$) {
		case 0:
			var url = item.a;
			var html = item.b;
			return A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$a,
				_Utils_ap($author$project$SE$UI$Dropdown$itemStyles, $author$project$SE$UI$Dropdown$linkStyles),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(url)
					]),
				html);
		case 1:
			var msg = item.a;
			var html = item.b;
			return A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_Utils_ap($author$project$SE$UI$Dropdown$itemStyles, $author$project$SE$UI$Dropdown$linkStyles),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick(msg)
					]),
				html);
		case 2:
			var html = item.a;
			return A4($rtfeldman$elm_css$Html$Styled$styled, $rtfeldman$elm_css$Html$Styled$div, $author$project$SE$UI$Dropdown$itemStyles, _List_Nil, html);
		default:
			return A4($rtfeldman$elm_css$Html$Styled$styled, $rtfeldman$elm_css$Html$Styled$hr, $author$project$SE$UI$Dropdown$hrStyles, _List_Nil, _List_Nil);
	}
};
var $author$project$SE$UI$Dropdown$dropdownContentOffset = $rtfeldman$elm_css$Css$px(4);
var $author$project$SE$UI$Dropdown$menuStyles = function (isOpen) {
	var displayStyle = isOpen ? $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block) : $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none);
	return _List_fromArray(
		[
			displayStyle,
			$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$minWidth(
			$rtfeldman$elm_css$Css$rem(12)),
			$rtfeldman$elm_css$Css$paddingTop($author$project$SE$UI$Dropdown$dropdownContentOffset),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(20))
		]);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$id = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var $author$project$SE$UI$OuterClick$invertDecoder = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (maybe) {
			return _Utils_eq(maybe, $elm$core$Maybe$Nothing) ? $elm$json$Json$Decode$succeed(0) : $elm$json$Json$Decode$fail('');
		},
		$elm$json$Json$Decode$maybe(decoder));
};
var $author$project$SE$UI$OuterClick$succeedIfBloodlineHasId = function (targetId) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (id) {
			return _Utils_eq(id, targetId) ? $elm$json$Json$Decode$succeed(0) : A2(
				$elm$json$Json$Decode$field,
				'parentNode',
				$author$project$SE$UI$OuterClick$succeedIfBloodlineHasId(targetId));
		},
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string));
};
var $author$project$SE$UI$OuterClick$succeedIfClickIsOutsideOfId = function (targetId) {
	return $author$project$SE$UI$OuterClick$invertDecoder(
		A2(
			$elm$json$Json$Decode$field,
			'relatedTarget',
			$author$project$SE$UI$OuterClick$succeedIfBloodlineHasId(targetId)));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$tabindex = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$SE$UI$OuterClick$withId = F2(
	function (id, onClickOutsideMsg) {
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$tabindex(0),
				$rtfeldman$elm_css$Html$Styled$Attributes$id(id),
				A2(
				$rtfeldman$elm_css$Html$Styled$Events$on,
				'focusout',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Basics$always(onClickOutsideMsg),
					$author$project$SE$UI$OuterClick$succeedIfClickIsOutsideOfId(id)))
			]);
	});
var $author$project$SE$UI$Dropdown$dropdown = F5(
	function (id, closeMsg, isOpen, btn, items) {
		var outerClickAttributes = A2($author$project$SE$UI$OuterClick$withId, id, closeMsg);
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Dropdown$dropdownStyles,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('dropdown', true)
						])),
				outerClickAttributes),
			_List_fromArray(
				[
					$author$project$SE$UI$Dropdown$buttonToHtml(btn),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Dropdown$menuStyles(isOpen),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('dropdown-menu', true)
								]))
						]),
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							$author$project$SE$UI$Dropdown$contentStyles,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('dropdown-content', true)
										]))
								]),
							A2($elm$core$List$map, $author$project$SE$UI$Dropdown$itemToHtml, items))
						]))
				]));
	});
var $author$project$SE$UI$Dropdown$MsgLink = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$SE$UI$Dropdown$msgLink = $author$project$SE$UI$Dropdown$MsgLink;
var $author$project$Helpers$monthToNumber = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.o, posixMinutes) < 0) {
					return posixMinutes + era.h4;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		eP: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		fz: month,
		gF: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).eP;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fz;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gF;
	});
var $author$project$Helpers$viewDate = F2(
	function (time, timezone) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, timezone, time)) + ('-' + (A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(
				$author$project$Helpers$monthToNumber(
					A2($elm$time$Time$toMonth, timezone, time)))) + ('-' + A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(
				A2($elm$time$Time$toDay, timezone, time))))));
	});
var $author$project$Page$Account$Administration$viewLastLoggedIn = F2(
	function (user, timezone) {
		var verifiedText = function () {
			var _v1 = user.hc;
			if (_v1.$ === 1) {
				return A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger),
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(0.9))
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Har inte bekräftat sitt konto.')
						]));
			} else {
				return $rtfeldman$elm_css$Html$Styled$text('');
			}
		}();
		var loggedInText = function () {
			var _v0 = user.hH;
			if (_v0.$ === 1) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$strong,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Aldrig')
						]));
			} else {
				var lastLoggedIn = _v0.a;
				return $rtfeldman$elm_css$Html$Styled$text(
					A2($author$project$Helpers$viewDate, lastLoggedIn, timezone));
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					loggedInText,
					A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
					verifiedText
				]));
	});
var $author$project$Page$Account$Administration$viewRole = function (role) {
	var label = $author$project$Role$toLabel(role);
	if (role === 1) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$strong,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(label)
				]));
	} else {
		return $rtfeldman$elm_css$Html$Styled$text(label);
	}
};
var $author$project$SE$UI$Icon$V2$withTransform = F2(
	function (transform, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{
				dc: A2($elm$core$List$cons, transform, internals.dc)
			});
	});
var $author$project$Page$Account$Administration$viewUserRow = F4(
	function (openDropdownId, currentUserId, timezone, user) {
		var userIdString = $elm$core$String$fromInt(
			$author$project$Id$toInt(user.hx));
		var otherRoles = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Basics$eq(user.it),
				$elm$core$Basics$not),
			$author$project$Role$all);
		var isDropdownOpen = _Utils_eq(openDropdownId, userIdString);
		var isCurrentUser = A2($author$project$Id$equals, currentUserId, user.hx);
		var icon = isDropdownOpen ? A2($author$project$SE$UI$Icon$V2$withTransform, $author$project$SE$UI$Icon$V2$FlipY, $author$project$SE$UI$Icon$V2$angleDown) : $author$project$SE$UI$Icon$V2$angleDown;
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(user.ho + (' ' + user.hI))
								])),
							A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:' + user.be)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(user.be)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Page$Account$Administration$viewLastLoggedIn, user, timezone)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(
							isCurrentUser ? 2 : 1)
						]),
					_List_fromArray(
						[
							$author$project$Page$Account$Administration$viewRole(user.it)
						])),
					A2(
					$author$project$Helpers$viewIf,
					!isCurrentUser,
					A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$SE$UI$Dropdown$dropdown,
								userIdString,
								$author$project$Page$Account$Administration$ClosedDropdown,
								isDropdownOpen,
								A3(
									$author$project$SE$UI$Dropdown$button,
									_List_Nil,
									$elm$core$Maybe$Just(
										$author$project$Page$Account$Administration$ToggledDropdown(userIdString)),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Åtgärder'),
											$author$project$SE$UI$Icon$V2$toHtml(icon)
										])),
								_Utils_ap(
									A2(
										$elm$core$List$map,
										function (role) {
											return A2(
												$author$project$SE$UI$Dropdown$msgLink,
												A2($author$project$Page$Account$Administration$ClickedChangeRole, user, role),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														'Gör till ' + $author$project$Role$toLabel(role))
													]));
										},
										otherRoles),
									_List_fromArray(
										[
											A2(
											$author$project$SE$UI$Dropdown$msgLink,
											$author$project$Page$Account$Administration$ClickedRemoveUser(user),
											_List_fromArray(
												[
													A4(
													$rtfeldman$elm_css$Html$Styled$styled,
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger),
															$rtfeldman$elm_css$Css$fontWeight(
															$rtfeldman$elm_css$Css$int(600))
														]),
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('🗑️ Radera konto')
														]))
												]))
										])))
							])))
				]));
	});
var $author$project$Page$Account$Administration$viewUsers = F4(
	function (openDropdownId, currentUserId, timezone, users) {
		return $author$project$Page$Account$Administration$viewTableHelper(
			A2(
				$elm$core$List$map,
				A3($author$project$Page$Account$Administration$viewUserRow, openDropdownId, currentUserId, timezone),
				users));
	});
var $author$project$Session$zone = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$userInterface,
	function ($) {
		return $.gG;
	});
var $author$project$Page$Account$Administration$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return (!$author$project$Role$hasAdminRights(user.it)) ? $author$project$Page$viewGuestAccount : {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$AccountAdministration),
								A2($author$project$Page$viewLoggedInMenu, 1, user),
								$author$project$SE$UI$Title$title1('Administrera användare'),
								$author$project$Page$viewMessage(model.fx),
								$author$project$SE$UI$Columns$columns(
								_List_fromArray(
									[
										$author$project$SE$UI$Columns$defaultColumn(
										_List_fromArray(
											[
												$author$project$SE$UI$Card$toHtml(
												$author$project$SE$UI$Card$content(
													_List_fromArray(
														[
															$author$project$Page$Account$Administration$viewAddUser(model.U),
															A2(
															$author$project$Page$Account$Administration$viewStatus,
															A3(
																$author$project$Page$Account$Administration$viewUsers,
																model.bo,
																user.hx,
																$author$project$Session$zone(model.K)),
															model.ar)
														])))
											])),
										A2(
										$author$project$SE$UI$Columns$column,
										_List_fromArray(
											[
												_Utils_Tuple2(3, 5)
											]),
										_List_fromArray(
											[
												$author$project$SE$UI$Card$toHtml(
												$author$project$SE$UI$Card$content(
													_List_fromArray(
														[$author$project$Page$Account$Administration$viewHelp])))
											]))
									]))
							])),
						$author$project$Page$Account$Administration$viewConfirmModal(model.aW)
					])),
			gr: 'Administrera användare'
		};
	}
};
var $author$project$Breadcrumb$AccountPassword = {$: 13};
var $rtfeldman$elm_css$Css$border2 = $rtfeldman$elm_css$Css$prop2('border');
var $rtfeldman$elm_css$Css$borderTopWidth = $rtfeldman$elm_css$Css$prop1('border-top-width');
var $author$project$Page$viewNonDismissableErrorNotification = function (errorMsg) {
	return (errorMsg !== '') ? A2(
		$author$project$SE$UI$Notification$danger,
		$elm$core$Maybe$Nothing,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(errorMsg)
					]))
			])) : $rtfeldman$elm_css$Html$Styled$text('');
};
var $author$project$Page$Account$Password$GotFormMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Account$Password$GotInput = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SE$UI$Form$Input$Current = 0;
var $author$project$SE$UI$Form$Input$password = F2(
	function (msg, value) {
		return $author$project$SE$UI$Form$Input$Password(
			{dk: 0, ct: false, k: _List_Nil, cJ: msg, hT: '', r: '', m: false, n: false, G: 1, iY: value});
	});
var $author$project$Page$Account$Password$viewFieldError = function (error) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$rem(0.75)),
				$rtfeldman$elm_css$Css$marginTop(
				$rtfeldman$elm_css$Css$rem(0.25)),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(error)
			]));
};
var $author$project$Page$Account$Password$viewFieldErrors = function (errors) {
	if (!errors.b) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Page$Account$Password$viewFieldError, errors));
	}
};
var $author$project$Page$Account$Password$viewField = F3(
	function (label, errors, content) {
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Form$label(label),
					A2(
					$author$project$SE$UI$Form$control,
					false,
					_List_fromArray(
						[content])),
					$author$project$Page$Account$Password$viewFieldErrors(errors)
				]));
	});
var $author$project$SE$UI$Form$Input$withModifiers = F2(
	function (mods, input) {
		var newRec = function (rec) {
			return _Utils_update(
				rec,
				{
					k: _Utils_ap(mods, rec.k)
				});
		};
		switch (input.$) {
			case 0:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Text(
					newRec(rec));
			case 1:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Number(
					newRec(rec));
			case 2:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Textarea(
					newRec(rec));
			case 3:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Date(
					newRec(rec));
			case 4:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Password(
					newRec(rec));
			case 5:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Select(
					newRec(rec));
			case 7:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Email(
					newRec(rec));
			case 8:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Tel(
					newRec(rec));
			default:
				var type_ = input.a;
				var rec = input.b;
				return A2(
					$author$project$SE$UI$Form$Input$Button,
					type_,
					newRec(rec));
		}
	});
var $author$project$Page$Account$Password$viewPasswordField = F4(
	function (label, value, errors, updateFn) {
		var mods = $elm$core$List$isEmpty(errors) ? _List_Nil : _List_fromArray(
			[$author$project$SE$UI$Form$Input$Danger]);
		return A3(
			$author$project$Page$Account$Password$viewField,
			label,
			errors,
			$author$project$SE$UI$Form$Input$toHtml(
				A2(
					$author$project$SE$UI$Form$Input$withModifiers,
					mods,
					$author$project$SE$UI$Form$Input$withRequired(
						A2(
							$author$project$SE$UI$Form$Input$withTrigger,
							0,
							A2(
								$author$project$SE$UI$Form$Input$password,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Account$Password$GotFormMsg,
									$author$project$Page$Account$Password$GotInput(updateFn)),
								value))))));
	});
var $author$project$Page$Account$Password$ClickedSave = {$: 1};
var $author$project$Page$Account$Password$viewSubmitButton = F2(
	function (isSending, errors) {
		var _v0 = _Utils_eq(errors, $author$project$Page$Account$Password$noErrors) ? (isSending ? {
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9),
					$author$project$SE$UI$Buttons$Loading
				]),
			cJ: $elm$core$Maybe$Just($author$project$Page$Account$Password$ClickedSave),
			dd: 'Spara'
		} : {
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			cJ: $elm$core$Maybe$Just($author$project$Page$Account$Password$ClickedSave),
			dd: 'Spara'
		}) : {
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(12)
				]),
			cJ: $elm$core$Maybe$Nothing,
			dd: 'Rätta felen innan du sparar din profil'
		};
		var msg = _v0.cJ;
		var txt = _v0.dd;
		var mods = _v0.cI;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Form$control,
					false,
					_List_fromArray(
						[
							A3(
							$author$project$SE$UI$Buttons$button,
							mods,
							msg,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(txt)
								]))
						]))
				]));
	});
var $author$project$Page$Account$Password$viewForm = F5(
	function (isSending, message, inputs, errors, errorMsg) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$rem(-1.5)),
					$rtfeldman$elm_css$Css$padding(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
					A2(
					$rtfeldman$elm_css$Css$border2,
					$rtfeldman$elm_css$Css$px(1),
					$rtfeldman$elm_css$Css$solid),
					$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
					$rtfeldman$elm_css$Css$borderTopWidth($rtfeldman$elm_css$Css$zero)
				]),
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Page$viewMessage(message),
					$author$project$Page$viewNonDismissableErrorNotification(errorMsg),
					A4(
					$author$project$Page$Account$Password$viewPasswordField,
					'Nuvarande lösenord',
					inputs.S,
					errors.S,
					F2(
						function (value, inputs_) {
							return _Utils_update(
								inputs_,
								{S: value});
						})),
					A4(
					$author$project$Page$Account$Password$viewPasswordField,
					'Nytt lösenord',
					inputs.N,
					errors.N,
					F2(
						function (value, inputs_) {
							return _Utils_update(
								inputs_,
								{N: value});
						})),
					A4(
					$author$project$Page$Account$Password$viewPasswordField,
					'Upprepa nya lösenordet',
					inputs.ak,
					errors.ak,
					F2(
						function (value, inputs_) {
							return _Utils_update(
								inputs_,
								{ak: value});
						})),
					A2($author$project$Page$Account$Password$viewSubmitButton, isSending, errors)
				]));
	});
var $author$project$Page$Account$Password$viewStatus = F2(
	function (message, status) {
		switch (status.$) {
			case 0:
				var inputs = status.a;
				return A5($author$project$Page$Account$Password$viewForm, false, message, inputs, $author$project$Page$Account$Password$noErrors, '');
			case 1:
				var inputs = status.a;
				var errors = status.b;
				var errorMsg = status.c;
				return A5($author$project$Page$Account$Password$viewForm, false, message, inputs, errors, errorMsg);
			case 2:
				var inputs = status.a;
				return A5($author$project$Page$Account$Password$viewForm, false, message, inputs, $author$project$Page$Account$Password$noErrors, '');
			default:
				var inputs = status.a;
				return A5($author$project$Page$Account$Password$viewForm, true, message, inputs, $author$project$Page$Account$Password$noErrors, '');
		}
	});
var $author$project$Page$Account$Password$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$AccountPassword),
								A2($author$project$Page$viewLoggedInMenu, 0, user),
								$author$project$SE$UI$Title$title1('Byt lösenord'),
								A2($author$project$Page$viewAccountMenu, 2, user),
								A2($author$project$Page$Account$Password$viewStatus, model.fx, model.gh)
							]))
					])),
			gr: 'Byt lösenord'
		};
	}
};
var $author$project$Breadcrumb$AccountProfile = {$: 11};
var $author$project$SE$UI$Columns$All = 0;
var $author$project$SE$UI$Columns$Full = 13;
var $author$project$Country$name = function (_v0) {
	var internals = _v0;
	return internals.hT;
};
var $author$project$Page$Account$Profile$countriesToOptions = $elm$core$List$map(
	function (c) {
		return {
			cB: $author$project$Country$name(c),
			iY: $author$project$Country$codeToString(
				$author$project$Country$code(c))
		};
	});
var $author$project$SE$UI$Columns$multilineColumns = A2($author$project$SE$UI$Columns$internalColumns, 2, true);
var $author$project$Page$Account$Profile$GotFormMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Account$Profile$GotInput = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SE$UI$Form$Input$select = F3(
	function (msg, options, value) {
		return $author$project$SE$UI$Form$Input$Select(
			{ct: false, k: _List_Nil, cJ: msg, hT: '', dT: options, r: '', m: false, n: false, iY: value});
	});
var $author$project$Page$Account$Profile$viewFieldError = function (error) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$rem(0.75)),
				$rtfeldman$elm_css$Css$marginTop(
				$rtfeldman$elm_css$Css$rem(0.25)),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(error)
			]));
};
var $author$project$Page$Account$Profile$viewFieldErrors = function (errors) {
	if (!errors.b) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Page$Account$Profile$viewFieldError, errors));
	}
};
var $author$project$Page$Account$Profile$viewField = F3(
	function (label, errors, content) {
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Form$label(label),
					A2(
					$author$project$SE$UI$Form$control,
					false,
					_List_fromArray(
						[content])),
					$author$project$Page$Account$Profile$viewFieldErrors(errors)
				]));
	});
var $author$project$Page$Account$Profile$viewSelectField = F5(
	function (label, options, value, errors, updateFn) {
		return A3(
			$author$project$Page$Account$Profile$viewField,
			label,
			errors,
			$author$project$SE$UI$Form$Input$toHtml(
				A3(
					$author$project$SE$UI$Form$Input$select,
					A2(
						$elm$core$Basics$composeL,
						$author$project$Page$Account$Profile$GotFormMsg,
						$author$project$Page$Account$Profile$GotInput(updateFn)),
					options,
					value)));
	});
var $author$project$Page$Account$Profile$ClickedSave = {$: 1};
var $author$project$Page$Account$Profile$viewSubmitButton = F2(
	function (isSending, errors) {
		var _v0 = _Utils_eq(errors, $author$project$Page$Account$Profile$noErrors) ? (isSending ? {
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9),
					$author$project$SE$UI$Buttons$Loading
				]),
			cJ: $elm$core$Maybe$Just($author$project$Page$Account$Profile$ClickedSave),
			dd: 'Spara'
		} : {
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			cJ: $elm$core$Maybe$Just($author$project$Page$Account$Profile$ClickedSave),
			dd: 'Spara'
		}) : {
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(12)
				]),
			cJ: $elm$core$Maybe$Nothing,
			dd: 'Rätta felen innan du sparar din profil'
		};
		var msg = _v0.cJ;
		var txt = _v0.dd;
		var mods = _v0.cI;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Form$control,
					false,
					_List_fromArray(
						[
							A3(
							$author$project$SE$UI$Buttons$button,
							mods,
							msg,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(txt)
								]))
						]))
				]));
	});
var $author$project$SE$UI$Form$Input$text = F2(
	function (msg, value) {
		return $author$project$SE$UI$Form$Input$Text(
			{ct: false, k: _List_Nil, cJ: msg, hT: '', r: '', m: false, n: false, G: 1, iY: value});
	});
var $author$project$Page$Account$Profile$viewTextField = F4(
	function (label, value, errors, updateFn) {
		return A3(
			$author$project$Page$Account$Profile$viewField,
			label,
			errors,
			$author$project$SE$UI$Form$Input$toHtml(
				A2(
					$author$project$SE$UI$Form$Input$text,
					A2(
						$elm$core$Basics$composeL,
						$author$project$Page$Account$Profile$GotFormMsg,
						$author$project$Page$Account$Profile$GotInput(updateFn)),
					value)));
	});
var $author$project$Page$Account$Profile$viewForm = F5(
	function (isSending, countries, inputs, errors, errorMsg) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Page$viewNonDismissableErrorNotification(errorMsg),
					$author$project$SE$UI$Title$title5('Kontaktuppgifter'),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					$author$project$SE$UI$Columns$columns(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Columns$column,
							_List_fromArray(
								[
									_Utils_Tuple2(3, 5)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Här kan du ändra dina kontaktuppgifter. Uppgifterna används för att vi enkelt ska kunna kontakta dig om behovet uppstår.')
										]))
								])),
							$author$project$SE$UI$Columns$defaultColumn(
							_List_fromArray(
								[
									$author$project$SE$UI$Columns$columns(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Förnamn',
													inputs._,
													errors._,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{_: value});
														}))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Efternamn',
													inputs.ab,
													errors.ab,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{ab: value});
														}))
												]))
										])),
									$author$project$SE$UI$Columns$columns(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Telefon',
													inputs.ad,
													errors.ad,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{ad: value});
														}))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Mobil',
													inputs.ac,
													errors.ac,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{ac: value});
														}))
												]))
										]))
								]))
						])),
					$author$project$SE$UI$Title$title5('Besöksadress'),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					$author$project$SE$UI$Columns$columns(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Columns$column,
							_List_fromArray(
								[
									_Utils_Tuple2(3, 5)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Här kan du ändra din besöksadress. Uppgifterna används för att vi enkelt ska kunna kontakta dig om behovet uppstår.')
										]))
								])),
							$author$project$SE$UI$Columns$defaultColumn(
							_List_fromArray(
								[
									$author$project$SE$UI$Columns$multilineColumns(
									_List_fromArray(
										[
											A2(
											$author$project$SE$UI$Columns$column,
											_List_fromArray(
												[
													_Utils_Tuple2(0, 13)
												]),
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Adress',
													inputs.af,
													errors.af,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{af: value});
														}))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Postnummer',
													inputs.ah,
													errors.ah,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{ah: value});
														}))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A4(
													$author$project$Page$Account$Profile$viewTextField,
													'Stad',
													inputs.Z,
													errors.Z,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{Z: value});
														}))
												])),
											A2(
											$author$project$SE$UI$Columns$column,
											_List_fromArray(
												[
													_Utils_Tuple2(0, 13)
												]),
											_List_fromArray(
												[
													A5(
													$author$project$Page$Account$Profile$viewSelectField,
													'Land',
													$author$project$Page$Account$Profile$countriesToOptions(countries),
													inputs.au,
													errors.au,
													F2(
														function (value, inputs_) {
															return _Utils_update(
																inputs_,
																{au: value});
														}))
												]))
										])),
									A2($author$project$Page$Account$Profile$viewSubmitButton, isSending, errors)
								]))
						]))
				]));
	});
var $author$project$Page$Account$Profile$viewLoadingForm = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Title$title5('Kontaktuppgifter'),
			A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
			$rtfeldman$elm_css$Html$Styled$text('Hämtar kontaktuppgifter...')
		]));
var $author$project$Page$Account$Profile$viewFormStatus = F2(
	function (countries, status) {
		switch (status.$) {
			case 0:
				return $author$project$Page$Account$Profile$viewLoadingForm;
			case 1:
				var errorMsg = status.a;
				return A2(
					$author$project$SE$UI$Notification$danger,
					$elm$core$Maybe$Nothing,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(errorMsg)
						]));
			case 2:
				var inputs = status.a;
				var errors = status.b;
				var errorMsg = status.c;
				return A5($author$project$Page$Account$Profile$viewForm, false, countries, inputs, errors, errorMsg);
			default:
				var inputs = status.a;
				return A5($author$project$Page$Account$Profile$viewForm, true, countries, inputs, $author$project$Page$Account$Profile$noErrors, '');
		}
	});
var $author$project$Page$Account$Profile$viewLoadingNewsletters = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Title$title5('Prenumerationer'),
			A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
			$rtfeldman$elm_css$Html$Styled$text('Hämtar prenumerationer...')
		]));
var $author$project$User$Newsletters$isBlacklisted = function (_v0) {
	var internals = _v0;
	return internals.fk;
};
var $author$project$Page$Account$Profile$ClickedWhitelist = {$: 7};
var $author$project$SE$UI$Colors$White = 0;
var $author$project$Page$Account$Profile$viewBlacklisted = function (isLoading) {
	var mods = A2(
		$elm$core$List$cons,
		$author$project$SE$UI$Buttons$Color(0),
		isLoading ? _List_fromArray(
			[$author$project$SE$UI$Buttons$Loading]) : _List_Nil);
	return A2(
		$author$project$SE$UI$Notification$danger,
		$elm$core$Maybe$Nothing,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$rem(0.75))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Du har valt att avsäga dig utskick från oss. Är det OK att vi gör det i framtiden?')
					])),
				A3(
				$author$project$SE$UI$Buttons$button,
				mods,
				$elm$core$Maybe$Just($author$project$Page$Account$Profile$ClickedWhitelist),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Ja, det är OK')
					]))
			]));
};
var $author$project$Page$Account$Profile$ToggledNewsletterList = function (a) {
	return {$: 6, a: a};
};
var $author$project$SE$UI$Form$Input$Checkbox = 0;
var $author$project$SE$UI$Form$Input$Simple = function (a) {
	return {$: 0, a: a};
};
var $author$project$SE$UI$Form$Input$checkbox = F3(
	function (msg, label, checked) {
		return A2(
			$author$project$SE$UI$Form$Input$Button,
			0,
			{
				co: checked,
				ct: false,
				cB: $author$project$SE$UI$Form$Input$Simple(label),
				k: _List_Nil,
				cJ: msg,
				hT: '',
				m: false,
				n: false,
				iY: ''
			});
	});
var $author$project$NewsletterList$description = function (_v0) {
	var internals = _v0;
	return internals.eT;
};
var $author$project$NewsletterList$name = function (_v0) {
	var internals = _v0;
	return internals.hT;
};
var $rtfeldman$elm_css$Html$Styled$small = $rtfeldman$elm_css$Html$Styled$node('small');
var $author$project$SE$UI$Form$Input$Custom = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$SE$UI$Form$Input$withCustomLabel = F3(
	function (styles, kids, input) {
		if (input.$ === 6) {
			var type_ = input.a;
			var rec = input.b;
			return A2(
				$author$project$SE$UI$Form$Input$Button,
				type_,
				_Utils_update(
					rec,
					{
						cB: A2($author$project$SE$UI$Form$Input$Custom, styles, kids)
					}));
		} else {
			return input;
		}
	});
var $author$project$Page$Account$Profile$viewNewsletter = F2(
	function (isLoading, newsletterList) {
		var _v0 = _Utils_Tuple3(
			$author$project$NewsletterList$externalId(newsletterList),
			$author$project$NewsletterList$name(newsletterList),
			$author$project$NewsletterList$description(newsletterList));
		var id = _v0.a;
		var name = _v0.b;
		var description = _v0.c;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Form$Input$toHtml(
					(isLoading ? $author$project$SE$UI$Form$Input$withModifier($author$project$SE$UI$Form$Input$Loading) : $elm$core$Basics$identity)(
						A3(
							$author$project$SE$UI$Form$Input$withCustomLabel,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(name)
										])),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$small,
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
										]),
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(description)
										]))
								]),
							A3(
								$author$project$SE$UI$Form$Input$checkbox,
								$author$project$Page$Account$Profile$ToggledNewsletterList(id),
								name,
								$author$project$NewsletterList$isSubscribed(newsletterList)))))
				]));
	});
var $author$project$Page$Account$Profile$viewNewsletters = F3(
	function (message, isLoading, newsletters) {
		var isBlacklisted = $author$project$User$Newsletters$isBlacklisted(newsletters);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Title$title5('Prenumerationer'),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					$author$project$Page$viewMessage(message),
					$author$project$SE$UI$Columns$columns(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Columns$column,
							_List_fromArray(
								[
									_Utils_Tuple2(3, 5)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Håll dig uppdaterad genom att prenumerera på våra utskick.')
										]))
								])),
							$author$project$SE$UI$Columns$defaultColumn(
							_List_fromArray(
								[
									isBlacklisted ? $author$project$Page$Account$Profile$viewBlacklisted(isLoading) : A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									A2(
										$elm$core$List$map,
										$author$project$Page$Account$Profile$viewNewsletter(isLoading),
										$author$project$User$Newsletters$lists(newsletters)))
								]))
						]))
				]));
	});
var $author$project$Page$Account$Profile$viewNewslettersStatus = F2(
	function (message, status) {
		switch (status.$) {
			case 0:
				return $author$project$Page$Account$Profile$viewLoadingNewsletters;
			case 1:
				var newsletters = status.a;
				return A3($author$project$Page$Account$Profile$viewNewsletters, message, true, newsletters);
			case 2:
				var newsletters = status.a;
				return A3($author$project$Page$Account$Profile$viewNewsletters, message, false, newsletters);
			default:
				var errorMsg = status.a;
				return A2(
					$author$project$SE$UI$Notification$danger,
					$elm$core$Maybe$Nothing,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(errorMsg)
						]));
		}
	});
var $author$project$Page$Account$Profile$viewTabBox = A3(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$rem(-1.5)),
			$rtfeldman$elm_css$Css$padding(
			$rtfeldman$elm_css$Css$rem(1.5)),
			$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
			A2(
			$rtfeldman$elm_css$Css$border2,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid),
			$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
			$rtfeldman$elm_css$Css$borderTopWidth($rtfeldman$elm_css$Css$zero)
		]),
	_List_Nil);
var $author$project$Page$Account$Profile$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$AccountProfile),
								A2($author$project$Page$viewLoggedInMenu, 0, user),
								$author$project$SE$UI$Title$title1('Min profil'),
								A2($author$project$Page$viewAccountMenu, 3, user),
								$author$project$Page$Account$Profile$viewTabBox(
								_List_fromArray(
									[
										$author$project$Page$viewMessage(model.fx),
										A2($author$project$Page$Account$Profile$viewFormStatus, model.cr, model.C),
										A2($author$project$Page$Account$Profile$viewNewslettersStatus, model.cL, model.al)
									]))
							]))
					])),
			gr: 'Min profil'
		};
	}
};
var $author$project$Breadcrumb$AccountSettings = {$: 12};
var $author$project$Session$UpdatedLayout = function (a) {
	return {$: 14, a: a};
};
var $author$project$Layout$all = _List_fromArray(
	[0, 1, 2]);
var $author$project$Layout$toLabel = function (layout) {
	switch (layout) {
		case 0:
			return 'Galleri';
		case 1:
			return 'Lista';
		default:
			return 'Tabell';
	}
};
var $author$project$SE$UI$Form$Input$Radio = 1;
var $author$project$SE$UI$Form$Input$radio = F3(
	function (msg, label, checked) {
		return A2(
			$author$project$SE$UI$Form$Input$Button,
			1,
			{
				co: checked,
				ct: false,
				cB: $author$project$SE$UI$Form$Input$Simple(label),
				k: _List_Nil,
				cJ: msg,
				hT: '',
				m: false,
				n: false,
				iY: ''
			});
	});
var $author$project$Page$Account$Settings$viewRadio = F4(
	function (toMsg, toLabel, currentValue, value) {
		return A2(
			$author$project$SE$UI$Form$control,
			false,
			_List_fromArray(
				[
					$author$project$SE$UI$Form$Input$toHtml(
					A3(
						$author$project$SE$UI$Form$Input$radio,
						toMsg(value),
						toLabel(value),
						_Utils_eq(currentValue, value)))
				]));
	});
var $author$project$Page$Account$Settings$viewLayoutField = function (currentLayout) {
	return A2(
		$author$project$SE$UI$Form$field,
		_List_Nil,
		A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Form$label('Layout i produktlista'),
			A2(
				$elm$core$List$map,
				A3(
					$author$project$Page$Account$Settings$viewRadio,
					A2($elm$core$Basics$composeL, $author$project$Page$Account$Settings$GotSessionMsg, $author$project$Session$UpdatedLayout),
					$author$project$Layout$toLabel,
					currentLayout),
				$author$project$Layout$all)));
};
var $author$project$OrderBy$all = _List_fromArray(
	[
		$author$project$OrderBy$Recent,
		$author$project$OrderBy$ProductCode(0),
		$author$project$OrderBy$ProductCode(1),
		$author$project$OrderBy$Name(0),
		$author$project$OrderBy$Name(1),
		$author$project$OrderBy$BasePrice(0),
		$author$project$OrderBy$BasePrice(1),
		$author$project$OrderBy$Relevance
	]);
var $author$project$OrderBy$toSwedishString = function (a) {
	switch (a.$) {
		case 0:
			return 'Senaste';
		case 1:
			if (!a.a) {
				var _v1 = a.a;
				return 'Artikelkod A-Ö';
			} else {
				var _v2 = a.a;
				return 'Artikelkod Ö-A';
			}
		case 2:
			if (!a.a) {
				var _v3 = a.a;
				return 'Namn A-Ö';
			} else {
				var _v4 = a.a;
				return 'Namn Ö-A';
			}
		case 3:
			if (!a.a) {
				var _v5 = a.a;
				return 'Lägsta pris';
			} else {
				var _v6 = a.a;
				return 'Högsta pris';
			}
		default:
			return 'Relevans';
	}
};
var $author$project$Page$Account$Settings$viewOrderByField = function (currentOrderBy) {
	return A2(
		$author$project$SE$UI$Form$field,
		_List_Nil,
		A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Form$label('Förvald sorteringsordning i produktlista'),
			A2(
				$elm$core$List$map,
				A3(
					$author$project$Page$Account$Settings$viewRadio,
					A2(
						$elm$core$Basics$composeR,
						$author$project$OrderBy$toString,
						A2($elm$core$Basics$composeR, $author$project$Session$UpdatedOrderBy, $author$project$Page$Account$Settings$GotSessionMsg)),
					$author$project$OrderBy$toSwedishString,
					currentOrderBy),
				$author$project$OrderBy$all)));
};
var $author$project$SE$UI$Columns$Narrow = 1;
var $rtfeldman$elm_css$Css$prop5 = F6(
	function (key, argA, argB, argC, argD, argE) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.iY, argB.iY, argC.iY, argD.iY, argE.iY])));
	});
var $rtfeldman$elm_css$Css$boxShadow5 = $rtfeldman$elm_css$Css$prop5('box-shadow');
var $author$project$SE$UI$Font$codeFamily = 'monospace';
var $rtfeldman$elm_css$Css$stringsToValue = function (list) {
	return $elm$core$List$isEmpty(list) ? {iY: 'none'} : {
		iY: A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				function (s) {
					return s;
				},
				list))
	};
};
var $rtfeldman$elm_css$Css$fontFamilies = A2(
	$elm$core$Basics$composeL,
	$rtfeldman$elm_css$Css$prop1('font-family'),
	$rtfeldman$elm_css$Css$stringsToValue);
var $rtfeldman$elm_css$Css$inset = {Q: 0, iY: 'inset'};
var $rtfeldman$elm_css$Html$Styled$kbd = $rtfeldman$elm_css$Html$Styled$node('kbd');
var $author$project$Page$Account$Settings$fakePriceData = A2($elm$json$Json$Decode$decodeString, $author$project$Price$dataDecoder, '{\"price\" : 80,\"base_price\" : 100,\"vat_rate\" : 0.25,\"chemical_tax\" : 0, \"unit\": \"st\"}');
var $author$project$SE$UI$Font$titleSizeRem = $author$project$SE$UI$Font$titleSizeHelper($rtfeldman$elm_css$Css$rem);
var $rtfeldman$elm_css$Css$unset = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{iY: 'unset'});
var $author$project$Price$priceTagStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'net-price',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$each,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$class('vat-label'),
						$rtfeldman$elm_css$Css$Global$class('list-price-and-discount')
					]),
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'list-price-and-discount',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'show-discount',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'list-price-and-discount',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$unset)
							]))
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-small',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'net-price',
						_List_fromArray(
							[
								$author$project$SE$UI$Font$bodySizeRem(-1)
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$each,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$class('vat-label'),
								$rtfeldman$elm_css$Css$Global$class('list-price-and-discount')
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Font$bodySizeRem(-3),
								$rtfeldman$elm_css$Css$lineHeight(
								$rtfeldman$elm_css$Css$num(1.2))
							]))
					]))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-large',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'net-price',
						_List_fromArray(
							[
								$author$project$SE$UI$Font$titleSizeRem(6)
							]))
					]))
			]))
	]);
var $rtfeldman$elm_css$Html$Styled$Attributes$title = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('title');
var $cuducos$elm_format_number$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {g4: decimals, fj: integers, fH: original, dZ: prefix, ec: suffix};
	});
var $cuducos$elm_format_number$Parser$Negative = 2;
var $cuducos$elm_format_number$Parser$Positive = 0;
var $cuducos$elm_format_number$Parser$Zero = 1;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $cuducos$elm_format_number$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.fj,
				$elm$core$List$singleton(formatted.g4))));
	return onlyZeros ? 1 : ((formatted.fH < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $cuducos$elm_format_number$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$Parser$parse = F2(
	function (locale, original) {
		var parts = A2(
			$elm$core$String$split,
			'.',
			A2($myrho$elm_round$Round$round, locale.g4, original));
		var integers = $cuducos$elm_format_number$Parser$splitThousands(
			A2(
				$elm$core$String$filter,
				$elm$core$Char$isDigit,
				A2(
					$elm$core$Maybe$withDefault,
					'0',
					$elm$core$List$head(parts))));
		var decimals = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$List$drop, 1, parts)));
		var partial = A5($cuducos$elm_format_number$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{dZ: locale.hV, ec: locale.hW});
			case 0:
				return _Utils_update(
					partial,
					{dZ: locale.ie, ec: locale.$8});
			default:
				return _Utils_update(
					partial,
					{dZ: locale.i2, ec: locale.i3});
		}
	});
var $cuducos$elm_format_number$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.g3, decimals);
	});
var $cuducos$elm_format_number$Stringfy$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$Stringfy$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$Stringfy$humanizeDecimals = F3(
	function (locale, strategy, decimals) {
		if ((decimals === '') || _Utils_eq(
			A2($elm$core$String$repeat, locale.g4, '0'),
			decimals)) {
			return '';
		} else {
			if (!strategy) {
				return _Utils_ap(locale.g3, decimals);
			} else {
				return A2(
					$cuducos$elm_format_number$Stringfy$formatDecimals,
					locale,
					$cuducos$elm_format_number$Stringfy$removeZeros(decimals));
			}
		}
	});
var $cuducos$elm_format_number$Stringfy$stringfy = F3(
	function (locale, strategy, formatted) {
		var stringfyDecimals = function () {
			if (!strategy.$) {
				var strategy_ = strategy.a;
				return A2($cuducos$elm_format_number$Stringfy$humanizeDecimals, locale, strategy_);
			} else {
				return $cuducos$elm_format_number$Stringfy$formatDecimals(locale);
			}
		}();
		var integers = A2($elm$core$String$join, locale.iR, formatted.fj);
		var decimals = stringfyDecimals(formatted.g4);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.dZ, integers, decimals, formatted.ec]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A3(
			$cuducos$elm_format_number$Stringfy$stringfy,
			locale,
			$elm$core$Maybe$Nothing,
			A2($cuducos$elm_format_number$Parser$parse, locale, number_));
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Locale = F9(
	function (decimals, thousandSeparator, decimalSeparator, negativePrefix, negativeSuffix, positivePrefix, positiveSuffix, zeroPrefix, zeroSuffix) {
		return {g3: decimalSeparator, g4: decimals, hV: negativePrefix, hW: negativeSuffix, ie: positivePrefix, $8: positiveSuffix, iR: thousandSeparator, i2: zeroPrefix, i3: zeroSuffix};
	});
var $author$project$Helpers$swedishLocale = A9($cuducos$elm_format_number$FormatNumber$Locales$Locale, 2, '\u202F', ',', '−', '', '', '', '', '');
var $author$project$Helpers$swedishLocaleNoDecimals = A9($cuducos$elm_format_number$FormatNumber$Locales$Locale, 0, '\u202F', ',', '−', '', '', '', '', '');
var $author$project$Helpers$format = function (amount) {
	return (!amount) ? A2($cuducos$elm_format_number$FormatNumber$format, $author$project$Helpers$swedishLocaleNoDecimals, amount) : (($elm$core$Basics$abs(amount) < 10) ? A2($cuducos$elm_format_number$FormatNumber$format, $author$project$Helpers$swedishLocale, amount) : A2($cuducos$elm_format_number$FormatNumber$format, $author$project$Helpers$swedishLocaleNoDecimals, amount));
};
var $author$project$Helpers$formatToMoney = function (amount) {
	return $author$project$Helpers$format(amount) + ($author$project$Helpers$nbr + 'kr');
};
var $author$project$Price$toChemicalTaxString = function (_v0) {
	var chemicalTax = _v0.dq;
	return (!chemicalTax) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		$author$project$Helpers$formatToMoney(chemicalTax));
};
var $author$project$VAT$toLabel = function (vat) {
	if (vat === 1) {
		return 'Exkl.' + ($author$project$Helpers$nbr + 'moms');
	} else {
		return 'Inkl.' + ($author$project$Helpers$nbr + 'moms');
	}
};
var $author$project$VAT$amount = F3(
	function (vat, _v0, amount_) {
		var rate = _v0;
		if (vat === 1) {
			return amount_;
		} else {
			return amount_ + (amount_ * rate);
		}
	});
var $author$project$Unit$isPiece = function (unit) {
	if (!unit) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Unit$grammaticalNumberToSwedishString = function (unit) {
	switch (unit) {
		case 0:
			return _Utils_Tuple2('st', 'st');
		case 1:
			return _Utils_Tuple2('kg', 'kg');
		case 3:
			return _Utils_Tuple2('m', 'm');
		case 4:
			return _Utils_Tuple2('km', 'km');
		case 5:
			return _Utils_Tuple2('m²', 'm²');
		case 6:
			return _Utils_Tuple2('100-pack', '100-pack');
		case 7:
			return _Utils_Tuple2('cm', 'cm');
		case 8:
			return _Utils_Tuple2('500 m', '500 m');
		case 9:
			return _Utils_Tuple2('par', 'par');
		case 10:
			return _Utils_Tuple2('förp', 'förp');
		case 14:
			return _Utils_Tuple2('kit', 'kit');
		case 2:
			return _Utils_Tuple2('timme', 'timmar');
		case 11:
			return _Utils_Tuple2('minut', 'minuter');
		case 12:
			return _Utils_Tuple2('månad', 'månader');
		case 13:
			return _Utils_Tuple2('år', 'år');
		default:
			return _Utils_Tuple2('rum', 'rum');
	}
};
var $author$project$Unit$toSwedishString = function (amount) {
	return (((amount >= 0) && (amount < 1)) || (((amount <= 0) && (_Utils_cmp(amount, -1) > 0)) || ((amount > 1) || (_Utils_cmp(amount, -1) < 0)))) ? A2($elm$core$Basics$composeR, $author$project$Unit$grammaticalNumberToSwedishString, $elm$core$Tuple$second) : A2($elm$core$Basics$composeR, $author$project$Unit$grammaticalNumberToSwedishString, $elm$core$Tuple$first);
};
var $author$project$Price$toString = F3(
	function (vat, showDiscount, _v0) {
		var r = _v0;
		var vatRate = r.gC;
		var net = r.dO;
		var base = r.dm;
		return function (str) {
			return $author$project$Unit$isPiece(r.em) ? str : (str + ('/' + A2($author$project$Unit$toSwedishString, 1, r.em)));
		}(
			$author$project$Helpers$formatToMoney(
				A3(
					$author$project$VAT$amount,
					vat,
					vatRate,
					showDiscount ? net : base)));
	});
var $author$project$Sticker$color = function (sticker) {
	switch (sticker) {
		case 0:
			return $author$project$SE$UI$Colors$primary;
		case 1:
			return $author$project$SE$UI$Colors$bargain;
		default:
			return $author$project$SE$UI$Colors$black;
	}
};
var $author$project$Price$toDiscountRateString = function (_v0) {
	var net = _v0.dO;
	var base = _v0.dm;
	return $author$project$Helpers$format((1 - (net / base)) * 100) + '%';
};
var $author$project$Price$viewDiscount = F2(
	function (maybeSticker, data) {
		if (maybeSticker.$ === 1) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						' (' + ($author$project$Price$toDiscountRateString(data) + ') '))
					]));
		} else {
			var sticker = maybeSticker.a;
			return A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$backgroundColor(
						$author$project$Sticker$color(sticker)),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600)),
						$author$project$SE$UI$Colors$color(
						$author$project$SE$UI$Colors$invert(
							$author$project$Sticker$color(sticker))),
						A2(
						$rtfeldman$elm_css$Css$padding2,
						$rtfeldman$elm_css$Css$px(2),
						$rtfeldman$elm_css$Css$px(4)),
						$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$px(2))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Price$toDiscountRateString(data))
					]));
		}
	});
var $author$project$Price$viewSmallTag = F4(
	function (maybeSticker, vat, showDiscount, data) {
		var chemicalTax = A2(
			$elm$core$Maybe$withDefault,
			'',
			$author$project$Price$toChemicalTaxString(data));
		var hasChemicalTax = !$elm$core$String$isEmpty(chemicalTax);
		var chemicalTaxMarker = hasChemicalTax ? '*' : '';
		var attribs = hasChemicalTax ? _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$title('Kemikalieskatt tillkommer med ' + chemicalTax)
			]) : _List_Nil;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Price$priceTagStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('price', true),
							_Utils_Tuple2('is-small', true),
							_Utils_Tuple2('show-discount', showDiscount)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							A2(
								$elm$core$List$cons,
								$rtfeldman$elm_css$Html$Styled$Attributes$class('net-price'),
								attribs),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A3($author$project$Price$toString, vat, showDiscount, data)),
									$rtfeldman$elm_css$Html$Styled$text(chemicalTaxMarker)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('vat-label')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									' ' + $author$project$VAT$toLabel(vat))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('list-price-and-discount')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							'Listpris ' + A3($author$project$Price$toString, vat, false, data)),
							A2($author$project$Price$viewDiscount, maybeSticker, data)
						]))
				]));
	});
var $author$project$Page$Account$Settings$viewPriceExample = F2(
	function (vat, showDiscount) {
		return A2(
			$elm$core$Result$withDefault,
			$rtfeldman$elm_css$Html$Styled$text('Exemplet kan inte visas.'),
			A2(
				$elm$core$Result$map,
				A3($author$project$Price$viewSmallTag, $elm$core$Maybe$Nothing, vat, showDiscount),
				$author$project$Page$Account$Settings$fakePriceData));
	});
var $author$project$Page$Account$Settings$viewShowDiscountField = function (settings) {
	return A2(
		$author$project$SE$UI$Form$field,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Columns$columns(
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Columns$column,
						_List_fromArray(
							[
								_Utils_Tuple2(0, 1)
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Form$label('Visa priser med rabatt'),
								A2(
								$author$project$SE$UI$Form$control,
								false,
								_List_fromArray(
									[
										$author$project$SE$UI$Form$Input$toHtml(
										A3(
											$author$project$SE$UI$Form$Input$checkbox,
											$author$project$Page$Account$Settings$GotSessionMsg($author$project$Session$ToggledShowDiscount),
											'Visa rabatt',
											settings.ga))
									])),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[$author$project$SE$UI$Utils$block]),
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('När du döljer rabatten visas enbart listpriset.')
									])),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[$author$project$SE$UI$Utils$block]),
								_List_Nil,
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('TIPS! ')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' Du kan trycka '),
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$kbd,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
												A2(
												$rtfeldman$elm_css$Css$padding2,
												$rtfeldman$elm_css$Css$px(4),
												$rtfeldman$elm_css$Css$px(8)),
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark),
												$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
												$author$project$SE$UI$Font$bodySizeRem(2),
												$rtfeldman$elm_css$Css$fontFamilies(
												_List_fromArray(
													[$author$project$SE$UI$Font$codeFamily])),
												$rtfeldman$elm_css$Css$lineHeight(
												$rtfeldman$elm_css$Css$int(1)),
												$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
												A3(
												$rtfeldman$elm_css$Css$border3,
												$rtfeldman$elm_css$Css$px(1),
												$rtfeldman$elm_css$Css$solid,
												$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
												$rtfeldman$elm_css$Css$borderRadius(
												$rtfeldman$elm_css$Css$px(4)),
												A5(
												$rtfeldman$elm_css$Css$boxShadow5,
												$rtfeldman$elm_css$Css$inset,
												$rtfeldman$elm_css$Css$zero,
												$rtfeldman$elm_css$Css$px(-2),
												$rtfeldman$elm_css$Css$zero,
												$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border))
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('§')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' för att snabbt växla mellan att visa och dölja rabatter.')
									]))
							])),
						A2(
						$author$project$SE$UI$Columns$column,
						_List_fromArray(
							[
								_Utils_Tuple2(0, 1)
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Form$label('Exempel'),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
										$rtfeldman$elm_css$Css$padding(
										$rtfeldman$elm_css$Css$rem(0.75))
									]),
								_List_Nil,
								_List_fromArray(
									[
										A2($author$project$Page$Account$Settings$viewPriceExample, settings.gA, settings.ga)
									]))
							]))
					]))
			]));
};
var $author$project$Session$UpdatedVAT = function (a) {
	return {$: 13, a: a};
};
var $author$project$VAT$all = _List_fromArray(
	[1, 0]);
var $author$project$Page$Account$Settings$viewVATField = function (currentVAT) {
	return A2(
		$author$project$SE$UI$Form$field,
		_List_Nil,
		A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Form$label('Visa priser'),
			A2(
				$elm$core$List$map,
				A3(
					$author$project$Page$Account$Settings$viewRadio,
					A2($elm$core$Basics$composeL, $author$project$Page$Account$Settings$GotSessionMsg, $author$project$Session$UpdatedVAT),
					$author$project$VAT$toLabel,
					currentVAT),
				$author$project$VAT$all)));
};
var $author$project$Page$Account$Settings$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$AccountSettings),
								A2($author$project$Page$viewLoggedInMenu, 0, user),
								$author$project$SE$UI$Title$title1('Generella inställningar'),
								A2($author$project$Page$viewAccountMenu, 4, user),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginTop(
										$rtfeldman$elm_css$Css$rem(-1.5)),
										$rtfeldman$elm_css$Css$padding(
										$rtfeldman$elm_css$Css$rem(1.5)),
										$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
										A2(
										$rtfeldman$elm_css$Css$border2,
										$rtfeldman$elm_css$Css$px(1),
										$rtfeldman$elm_css$Css$solid),
										$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
										$rtfeldman$elm_css$Css$borderTopWidth($rtfeldman$elm_css$Css$zero)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Page$Account$Settings$viewVATField(user.c0.gA),
										$author$project$Page$Account$Settings$viewLayoutField(user.c0.hK),
										$author$project$Page$Account$Settings$viewOrderByField(user.c0.ia),
										$author$project$Page$Account$Settings$viewShowDiscountField(user.c0)
									]))
							]))
					])),
			gr: 'Generella inställningar'
		};
	}
};
var $author$project$Bid$name = function (_v0) {
	var internals = _v0.a;
	return internals.hT;
};
var $author$project$Page$Bid$bidToTitleHelper = function (bid) {
	return $author$project$ExternalId$toString(
		$author$project$Bid$externalId(bid)) + (' - ' + $author$project$Bid$name(bid));
};
var $author$project$Page$Bid$bidToTitle = function (bid) {
	switch (bid.$) {
		case 0:
			return 'Laddar...';
		case 3:
			return 'Ett fel uppstod...';
		case 2:
			var bid_ = bid.a;
			return $author$project$Page$Bid$bidToTitleHelper(bid_);
		default:
			var bid_ = bid.a;
			return $author$project$Page$Bid$bidToTitleHelper(bid_);
	}
};
var $author$project$Status$view = F3(
	function (errorFn, loadedFn, status) {
		switch (status.$) {
			case 0:
				return $rtfeldman$elm_css$Html$Styled$text('laddar...');
			case 1:
				var value = status.a;
				return loadedFn(value);
			case 2:
				var value = status.a;
				return loadedFn(value);
			default:
				var error = status.a;
				return errorFn(error);
		}
	});
var $author$project$SE$UI$Colors$Link = 10;
var $author$project$Amount$find = F2(
	function (amounts, id) {
		return A2(
			$elm$core$Maybe$withDefault,
			1,
			$elm$core$List$head(
				A2(
					$elm$core$List$filterMap,
					function (_v0) {
						var id_ = _v0.a;
						var amount = _v0.b;
						return A2($author$project$Id$equals, id, id_) ? $elm$core$Maybe$Just(amount) : $elm$core$Maybe$Nothing;
					},
					amounts)));
	});
var $author$project$Bid$noOrdersAfter = function (_v0) {
	var internals = _v0.a;
	return internals.dQ;
};
var $rtfeldman$elm_css$Css$Addition = 0;
var $rtfeldman$elm_css$Css$plus = 0;
var $author$project$Bid$rows = function (_v0) {
	var rows_ = _v0.b;
	return rows_;
};
var $author$project$Bid$Active = 1;
var $author$project$Bid$Disabled = 3;
var $author$project$Bid$Expired = 2;
var $author$project$Bid$Forthcoming = 0;
var $author$project$Bid$status = F2(
	function (now, _v0) {
		var internals = _v0.a;
		return (!internals.gh) ? 3 : ((_Utils_cmp(
			$elm$time$Time$posixToMillis(internals.df),
			$elm$time$Time$posixToMillis(now)) > 0) ? 0 : (((_Utils_cmp(
			$elm$time$Time$posixToMillis(internals.df),
			$elm$time$Time$posixToMillis(now)) < 1) && (_Utils_cmp(
			$elm$time$Time$posixToMillis(internals.dQ),
			$elm$time$Time$posixToMillis(now)) > -1)) ? 1 : 2));
	});
var $rtfeldman$elm_css$Html$Styled$h6 = $rtfeldman$elm_css$Html$Styled$node('h6');
var $author$project$SE$UI$Title$title6 = A2(
	$author$project$SE$UI$Title$internalTitle,
	$rtfeldman$elm_css$Html$Styled$h6,
	_List_fromArray(
		[
			$author$project$SE$UI$Font$titleSizeEm(1)
		]));
var $author$project$Bid$validFrom = function (_v0) {
	var internals = _v0.a;
	return internals.df;
};
var $author$project$BidRow$priceData = function (_v0) {
	var internals = _v0.a;
	return internals.fP;
};
var $author$project$Product$prices = function (product) {
	if (!product.$) {
		var p = product.b;
		return $elm$core$Maybe$Just(p);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$BidRow$product = function (_v0) {
	var product_ = _v0.b;
	return product_;
};
var $author$project$BidRow$quantity = function (_v0) {
	var internals = _v0.a;
	return internals.fW;
};
var $author$project$Price$unit = function (_v0) {
	var rec = _v0;
	return rec.em;
};
var $author$project$Analytics$BidPage = {$: 11};
var $author$project$Page$Bid$GotAmount = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Product$minimal = A2(
	$elm$core$Basics$composeR,
	$author$project$Product$internals,
	function (i) {
		return A5(
			$author$project$Product$Minimal,
			i.hx,
			i.hh,
			i.bV.hT,
			$author$project$Brand$name(i.bV.dp),
			i.bV.gV);
	});
var $author$project$SE$UI$Form$Input$number = F2(
	function (msg, value) {
		return $author$project$SE$UI$Form$Input$Number(
			{ct: false, k: _List_Nil, cJ: msg, hT: '', r: '', cU: $elm$core$Maybe$Nothing, m: false, n: false, d7: $elm$core$Maybe$Nothing, G: 1, iY: value});
	});
var $author$project$SE$UI$Buttons$staticButtonStyles = function (mods) {
	return _Utils_ap(
		A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$backgroundColor(
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lightest))),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$color(
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$dark))),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none))
			]));
};
var $author$project$SE$UI$Buttons$staticButton = F2(
	function (mods, label) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			$author$project$SE$UI$Buttons$staticButtonStyles(mods),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(label)
				]));
	});
var $author$project$SE$UI$Form$Input$withRange = F2(
	function (range, input) {
		if (input.$ === 1) {
			var rec = input.a;
			var _v1 = _Utils_Tuple2(
				A2($elm$core$Basics$min, range.a, range.b),
				A2($elm$core$Basics$max, range.a, range.b));
			var min = _v1.a;
			var max = _v1.b;
			return $author$project$SE$UI$Form$Input$Number(
				_Utils_update(
					rec,
					{
						cU: $elm$core$Maybe$Just(
							_Utils_Tuple2(min, max))
					}));
		} else {
			return input;
		}
	});
var $author$project$SE$UI$Form$Input$withStep = F2(
	function (step, input) {
		if (input.$ === 1) {
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Number(
				_Utils_update(
					rec,
					{
						d7: $elm$core$Maybe$Just(step)
					}));
		} else {
			return input;
		}
	});
var $author$project$Page$Bid$viewAddToCart = F5(
	function (now, amount, unit, bid, row) {
		var _v0 = _Utils_Tuple2(
			A2($author$project$Bid$status, now, bid),
			$author$project$BidRow$orderableQuantity(row) > 0);
		if ((_v0.a === 1) && _v0.b) {
			var _v1 = _v0.a;
			var minimal = $author$project$Product$minimal(
				$author$project$BidRow$product(row));
			var id = $author$project$BidRow$id(row);
			var source = A3(
				$author$project$Cart$BidRowSource,
				id,
				$author$project$Bid$id(bid),
				$author$project$Bid$externalId(bid));
			return A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						A2($rtfeldman$elm_css$Css$property, 'gap', '0.75rem'),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Form$field,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Form$control,
								false,
								_List_fromArray(
									[
										$author$project$SE$UI$Form$Input$toHtml(
										A2(
											$author$project$SE$UI$Form$Input$withStep,
											1,
											A2(
												$author$project$SE$UI$Form$Input$withPlaceholder,
												'Ange antal',
												A2(
													$author$project$SE$UI$Form$Input$withRange,
													_Utils_Tuple2(
														1,
														$author$project$BidRow$orderableQuantity(row)),
													A2(
														$author$project$SE$UI$Form$Input$number,
														$author$project$Page$Bid$GotAmount(row),
														$elm$core$String$fromFloat(amount))))))
									])),
								A2(
								$author$project$SE$UI$Form$control,
								false,
								_List_fromArray(
									[
										A2(
										$author$project$SE$UI$Buttons$staticButton,
										_List_Nil,
										A2($author$project$Unit$toSwedishString, amount, unit))
									]))
							])),
						A2(
						$author$project$SE$UI$Form$field,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Form$control,
								false,
								_List_fromArray(
									[
										A3(
										$author$project$SE$UI$Buttons$button,
										_List_fromArray(
											[
												$author$project$SE$UI$Buttons$Color(11)
											]),
										$elm$core$Maybe$Just(
											$author$project$Page$Bid$GotSessionMsg(
												A4($author$project$Session$AddedToCart, minimal, source, amount, $author$project$Analytics$BidPage))),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Lägg i varukorg')
											]))
									]))
							]))
					]));
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	});
var $author$project$Page$Products$View$viewBrand = function (brand) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
				$author$project$SE$UI$Font$bodySizeRem(-3),
				$rtfeldman$elm_css$Css$lineHeight(
				$rtfeldman$elm_css$Css$num(1.2))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				$author$project$Brand$name(brand))
			]));
};
var $author$project$Page$Products$View$viewExternalId = function (code) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary),
				$author$project$SE$UI$Font$bodySizeRem(-2)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				$author$project$ExternalId$toString(code))
			]));
};
var $author$project$Helpers$viewMaybe = F2(
	function (fn, maybeValue) {
		return A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2($elm$core$Maybe$map, fn, maybeValue));
	});
var $author$project$SE$UI$Image$V2$Image = $elm$core$Basics$identity;
var $author$project$SE$UI$Image$V2$create = function (_v0) {
	var width = _v0.gE;
	var height = _v0.e7;
	return {bF: $elm$core$Maybe$Nothing, cl: true, e7: height, cC: true, gE: width};
};
var $author$project$SE$UI$Image$V2$figureStyles = function (_v0) {
	var width = _v0.gE;
	var height = _v0.e7;
	var aspectRatio = _v0.bF;
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$img(
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Css$property, 'content-visibility', 'auto'),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock)
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$class,
					'icon',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$px(width)),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(height)),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$lighter),
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$selector,
									'svg',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$pct(80)),
											$rtfeldman$elm_css$Css$height(
											$rtfeldman$elm_css$Css$pct(80))
										]))
								]))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'has-aspect-ratio',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$each,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$img,
									$rtfeldman$elm_css$Css$Global$class('icon')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$pct(100)),
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$pct(100)),
									$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute)
								])),
							$rtfeldman$elm_css$Css$Global$img(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, 'object-fit', 'contain')
								]))
						]))
				])),
			$rtfeldman$elm_css$Css$batch(
			function () {
				if (aspectRatio.$ === 1) {
					return _List_Nil;
				} else {
					var _v2 = aspectRatio.a;
					var widthRatio = _v2.a;
					var heightRatio = _v2.b;
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$maxWidth(
							$rtfeldman$elm_css$Css$pct(100)),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
							$rtfeldman$elm_css$Css$paddingBottom(
							$rtfeldman$elm_css$Css$pct((heightRatio / widthRatio) * 100))
						]);
				}
			}())
		]);
};
var $author$project$SE$UI$Image$V2$figure = function (internals) {
	var hasAspectRatio = function () {
		var _v0 = internals.bF;
		if (_v0.$ === 1) {
			return false;
		} else {
			return true;
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$node,
		'figure',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('has-aspect-ratio', hasAspectRatio)
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				$author$project$SE$UI$Image$V2$figureStyles(internals))
			]));
};
var $author$project$SE$UI$Icon$V2$images = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		576,
		512,
		_List_fromArray(
			['M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z'])));
var $author$project$SE$UI$Image$V2$icon = $author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$images);
var $author$project$SE$UI$Image$V2$toMissingHtml = function (_v0) {
	var internals = _v0;
	return A2(
		$author$project$SE$UI$Image$V2$figure,
		internals,
		_List_fromArray(
			[$author$project$SE$UI$Image$V2$icon]));
};
var $author$project$Image$Thumbnail = 0;
var $author$project$Image$sizeKeyIndex = function (key) {
	switch (key) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Image$Base = 1;
var $author$project$Image$Large = 2;
var $author$project$Image$Original = 3;
var $author$project$Image$stringToSizeKey = function (str) {
	switch (str) {
		case 'thumb':
			return $elm$core$Maybe$Just(0);
		case 'base':
			return $elm$core$Maybe$Just(1);
		case 'large':
			return $elm$core$Maybe$Just(2);
		case 'original':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Image$sizeKeySorter = F2(
	function (_v0, _v1) {
		var a = _v0.a;
		var b = _v1.a;
		var bIndex = A2(
			$elm$core$Maybe$withDefault,
			99,
			A2(
				$elm$core$Maybe$map,
				$author$project$Image$sizeKeyIndex,
				$author$project$Image$stringToSizeKey(b)));
		var aIndex = A2(
			$elm$core$Maybe$withDefault,
			99,
			A2(
				$elm$core$Maybe$map,
				$author$project$Image$sizeKeyIndex,
				$author$project$Image$stringToSizeKey(a)));
		return A2($elm$core$Basics$compare, aIndex, bIndex);
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Image$findSize = F2(
	function (key, sizes) {
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$second,
			$elm$core$List$head(
				A2(
					$elm$core$List$sortWith,
					$author$project$Image$sizeKeySorter,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (k, _v0) {
									var currentIndex = A2(
										$elm$core$Maybe$withDefault,
										-1,
										A2(
											$elm$core$Maybe$map,
											$author$project$Image$sizeKeyIndex,
											$author$project$Image$stringToSizeKey(k)));
									return _Utils_cmp(
										$author$project$Image$sizeKeyIndex(key),
										currentIndex) < 1;
								}),
							sizes)))));
	});
var $author$project$Helpers$groupWhile = F2(
	function (isSameGroup, items) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					if (!acc.b) {
						return _List_fromArray(
							[
								_Utils_Tuple2(x, _List_Nil)
							]);
					} else {
						var _v1 = acc.a;
						var y = _v1.a;
						var restOfGroup = _v1.b;
						var groups = acc.b;
						return A2(isSameGroup, x, y) ? A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								x,
								A2($elm$core$List$cons, y, restOfGroup)),
							groups) : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(x, _List_Nil),
							acc);
					}
				}),
			_List_Nil,
			items);
	});
var $author$project$Image$groupByExtension = function (sizes) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var a = _v0.a;
			var tail = _v0.b;
			return A2($elm$core$List$cons, a, tail);
		},
		A2(
			$author$project$Helpers$groupWhile,
			F2(
				function (a, b) {
					return _Utils_eq(
						A2($elm$core$String$right, 4, a.cg),
						A2($elm$core$String$right, 4, b.cg));
				}),
			sizes));
};
var $author$project$SE$UI$Image$V2$Source = $elm$core$Basics$identity;
var $author$project$SE$UI$Image$V2$source = $elm$core$Basics$identity;
var $author$project$SE$UI$Image$V2$Srcset = F2(
	function (url, resolution) {
		return {d3: resolution, cg: url};
	});
var $author$project$SE$UI$Image$V2$srcset = $author$project$SE$UI$Image$V2$Srcset;
var $rtfeldman$elm_css$Html$Styled$Attributes$alt = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('alt');
var $author$project$SE$UI$Image$V2$boolAttribute = F2(
	function (attr, bool) {
		return bool ? attr : $rtfeldman$elm_css$Html$Styled$Attributes$class('');
	});
var $author$project$SE$UI$Image$V2$asyncAttribute = $author$project$SE$UI$Image$V2$boolAttribute(
	A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'decoding', 'async'));
var $rtfeldman$elm_css$Html$Styled$Attributes$height = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $rtfeldman$elm_css$Html$Styled$img = $rtfeldman$elm_css$Html$Styled$node('img');
var $author$project$SE$UI$Image$V2$lazyAttribute = $author$project$SE$UI$Image$V2$boolAttribute(
	A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'loading', 'lazy'));
var $rtfeldman$elm_css$Html$Styled$Attributes$src = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'src', url);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$width = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$SE$UI$Image$V2$img = F2(
	function (_v0, _v1) {
		var alt = _v0.gM;
		var src = _v0.iI;
		var width = _v1.gE;
		var height = _v1.e7;
		var async = _v1.cl;
		var lazy = _v1.cC;
		return A2(
			$rtfeldman$elm_css$Html$Styled$img,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$alt(alt),
					$rtfeldman$elm_css$Html$Styled$Attributes$src(src),
					$rtfeldman$elm_css$Html$Styled$Attributes$width(width),
					$rtfeldman$elm_css$Html$Styled$Attributes$height(height),
					$author$project$SE$UI$Image$V2$asyncAttribute(async),
					$author$project$SE$UI$Image$V2$lazyAttribute(lazy)
				]),
			_List_Nil);
	});
var $author$project$SE$UI$Image$V2$extractExtension = function (str) {
	var _v0 = A2($elm$core$String$right, 4, str);
	switch (_v0) {
		case 'webp':
			return 'webp';
		case '.jpg':
			return 'jpg';
		case 'jpeg':
			return 'jpg';
		case '.gif':
			return 'gif';
		case '.png':
			return 'png';
		case 'avif':
			return 'avif';
		default:
			return '';
	}
};
var $author$project$SE$UI$Image$V2$sortSourcesHelper = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		var _v2 = _Utils_Tuple2(a, b);
		if (!_v2.a.b) {
			if (!_v2.b.b) {
				return 1;
			} else {
				return 1;
			}
		} else {
			if (!_v2.b.b) {
				return 1;
			} else {
				var _v3 = _v2.a;
				var aHead = _v3.a;
				var _v4 = _v2.b;
				var bHead = _v4.a;
				var _v5 = _Utils_Tuple2(
					$author$project$SE$UI$Image$V2$extractExtension(aHead.cg),
					$author$project$SE$UI$Image$V2$extractExtension(bHead.cg));
				_v5$1:
				while (true) {
					_v5$2:
					while (true) {
						switch (_v5.a) {
							case 'avif':
								switch (_v5.b) {
									case 'avif':
										return 1;
									case 'webp':
										break _v5$1;
									default:
										break _v5$1;
								}
							case 'webp':
								switch (_v5.b) {
									case 'avif':
										break _v5$2;
									case 'webp':
										return 1;
									default:
										return 0;
								}
							default:
								switch (_v5.b) {
									case 'avif':
										break _v5$2;
									case 'webp':
										return 2;
									default:
										return 1;
								}
						}
					}
					return 2;
				}
				return 0;
			}
		}
	});
var $author$project$SE$UI$Image$V2$sortSources = $elm$core$List$sortWith($author$project$SE$UI$Image$V2$sortSourcesHelper);
var $author$project$SE$UI$Image$V2$Avif = 4;
var $author$project$SE$UI$Image$V2$Gif = 2;
var $author$project$SE$UI$Image$V2$Jpeg = 1;
var $author$project$SE$UI$Image$V2$Png = 3;
var $author$project$SE$UI$Image$V2$WebP = 0;
var $author$project$SE$UI$Image$V2$extensionToMimeType = function (ext) {
	var _v0 = $elm$core$String$toLower(ext);
	switch (_v0) {
		case 'webp':
			return $elm$core$Maybe$Just(0);
		case 'jpg':
			return $elm$core$Maybe$Just(1);
		case 'jpeg':
			return $elm$core$Maybe$Just(1);
		case 'gif':
			return $elm$core$Maybe$Just(2);
		case 'png':
			return $elm$core$Maybe$Just(3);
		case 'avif':
			return $elm$core$Maybe$Just(4);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$SE$UI$Image$V2$mimeTypeToAttribute = function (mimeType) {
	var ext = function () {
		switch (mimeType) {
			case 0:
				return 'webp';
			case 1:
				return 'jpeg';
			case 2:
				return 'gif';
			case 3:
				return 'png';
			default:
				return 'avif';
		}
	}();
	return $rtfeldman$elm_css$Html$Styled$Attributes$type_('image/' + ext);
};
var $rtfeldman$elm_css$Html$Styled$source = $rtfeldman$elm_css$Html$Styled$node('source');
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$SE$UI$Image$V2$srcsetsToString = function (lst) {
	return A3(
		$elm$core$List$foldl,
		$elm$core$Basics$append,
		'',
		A2(
			$elm$core$List$intersperse,
			', ',
			$elm$core$List$reverse(
				A2(
					$elm$core$List$map,
					function (s) {
						return s.cg + (' ' + ($elm$core$String$fromInt(s.d3) + 'x'));
					},
					lst))));
};
var $author$project$SE$UI$Image$V2$sourceToHtml = function (_v0) {
	var srcsets = _v0;
	if (!srcsets.b) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var head = srcsets.a;
		var attrs = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					$author$project$SE$UI$Image$V2$mimeTypeToAttribute,
					$author$project$SE$UI$Image$V2$extensionToMimeType(
						$author$project$SE$UI$Image$V2$extractExtension(head.cg)))));
		return A2(
			$rtfeldman$elm_css$Html$Styled$source,
			A2(
				$elm$core$List$cons,
				A2(
					$rtfeldman$elm_css$Html$Styled$Attributes$attribute,
					'srcset',
					$author$project$SE$UI$Image$V2$srcsetsToString(srcsets)),
				attrs),
			_List_Nil);
	}
};
var $author$project$SE$UI$Image$V2$toHtml = F3(
	function (alt, sources, _v0) {
		var internals = _v0;
		return A2(
			$author$project$SE$UI$Image$V2$figure,
			internals,
			$elm$core$List$singleton(
				function () {
					if (!sources.b) {
						return $author$project$SE$UI$Image$V2$icon;
					} else {
						var sortedSources = $author$project$SE$UI$Image$V2$sortSources(sources);
						var fallbackImg = A2(
							$elm$core$Maybe$map,
							function (srcset_) {
								return A2(
									$author$project$SE$UI$Image$V2$img,
									{gM: alt, iI: srcset_.cg},
									internals);
							},
							A2(
								$elm$core$Maybe$andThen,
								function (_v2) {
									var srcsets = _v2;
									return $elm$core$List$head(srcsets);
								},
								$elm$core$List$head(
									$elm$core$List$reverse(sortedSources))));
						var sourceEls = _Utils_ap(
							A2($elm$core$List$map, $author$project$SE$UI$Image$V2$sourceToHtml, sortedSources),
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2($elm$core$Maybe$map, $elm$core$List$singleton, fallbackImg)));
						return A3($rtfeldman$elm_css$Html$Styled$node, 'picture', _List_Nil, sourceEls);
					}
				}()));
	});
var $author$project$Image$viewSizeHelper = F4(
	function (imageConfig, key, alt, _v0) {
		var sizes = _v0.c1;
		var _v1 = A2($author$project$Image$findSize, key, sizes);
		if (_v1.$ === 1) {
			return $author$project$SE$UI$Image$V2$toMissingHtml(imageConfig);
		} else {
			var sizes_ = _v1.a;
			return A3(
				$author$project$SE$UI$Image$V2$toHtml,
				alt,
				A2(
					$elm$core$List$map,
					function (size) {
						return $author$project$SE$UI$Image$V2$source(
							A2(
								$elm$core$List$map,
								function (b) {
									return A2($author$project$SE$UI$Image$V2$srcset, b.cg, b.d3);
								},
								size));
					},
					$author$project$Image$groupByExtension(sizes_)),
				imageConfig);
		}
	});
var $author$project$SE$UI$Image$V2$withAspectRatio = F2(
	function (ratio, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{
				bF: $elm$core$Maybe$Just(ratio)
			});
	});
var $author$project$Image$viewThumbnail = function (widthAndHeight) {
	return A2(
		$author$project$Image$viewSizeHelper,
		A2(
			$author$project$SE$UI$Image$V2$withAspectRatio,
			_Utils_Tuple2(1, 1),
			$author$project$SE$UI$Image$V2$create(
				{e7: widthAndHeight, gE: widthAndHeight})),
		0);
};
var $author$project$Image$viewMaybeThumbnail = F3(
	function (widthAndHeight, alt, maybeImage) {
		if (maybeImage.$ === 1) {
			return $author$project$SE$UI$Image$V2$toMissingHtml(
				A2(
					$author$project$SE$UI$Image$V2$withAspectRatio,
					_Utils_Tuple2(1, 1),
					$author$project$SE$UI$Image$V2$create(
						{e7: widthAndHeight, gE: widthAndHeight})));
		} else {
			var image = maybeImage.a;
			return A3($author$project$Image$viewThumbnail, widthAndHeight, alt, image);
		}
	});
var $author$project$Page$Products$View$viewName = function (product) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_Nil,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600)),
						$author$project$SE$UI$Font$bodySizeRem(-2),
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
					]),
				_List_fromArray(
					[
						$author$project$Route$href(
						A2(
							$author$project$Route$Product,
							$author$project$QueryParams$empty,
							_Utils_Tuple2(
								$author$project$Product$id(product),
								$author$project$Product$slug(product))))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Product$metadata(product).hT)
					]))
			]));
};
var $author$project$Page$Bid$viewQuantity = F2(
	function (quantity, unit) {
		return $rtfeldman$elm_css$Html$Styled$text(
			_Utils_ap(
				$elm$core$String$fromFloat(quantity),
				_Utils_ap(
					$author$project$Helpers$nbr,
					A2($author$project$Unit$toSwedishString, quantity, unit))));
	});
var $author$project$SE$UI$Control$Small = 1;
var $author$project$SE$UI$Icon$V2$bargain = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		576,
		512,
		_List_fromArray(
			['M560 224h-29.5c-8.8-20-21.6-37.7-37.4-52.5L512 96h-32c-29.4 0-55.4 13.5-73 34.3-7.6-1.1-15.1-2.3-23-2.3H256c-77.4 0-141.9 55-156.8 128H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h40c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-48h128v48c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-80.7c11.8-8.9 22.3-19.4 31.3-31.3H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-128 64c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zM256 96h128c5.4 0 10.7.4 15.9.8 0-.3.1-.5.1-.8 0-53-43-96-96-96s-96 43-96 96c0 2.1.5 4.1.6 6.2 15.2-3.9 31-6.2 47.4-6.2z'])));
var $author$project$SE$UI$Icon$V2$campaign = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z'])));
var $author$project$SE$UI$Icon$V2$star = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		576,
		512,
		_List_fromArray(
			['M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z'])));
var $author$project$Sticker$icon = function (sticker) {
	switch (sticker) {
		case 0:
			return $author$project$SE$UI$Icon$V2$star;
		case 1:
			return $author$project$SE$UI$Icon$V2$bargain;
		default:
			return $author$project$SE$UI$Icon$V2$campaign;
	}
};
var $rtfeldman$elm_css$Css$letterSpacing = $rtfeldman$elm_css$Css$prop1('letter-spacing');
var $author$project$Sticker$singular = A2($elm$core$Basics$composeR, $author$project$Sticker$labels, $elm$core$Tuple$first);
var $author$project$SE$UI$Icon$V2$withSize = F2(
	function (size, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{cq: size, d6: size});
	});
var $author$project$Sticker$view = function (sticker) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(
				$author$project$Sticker$color(sticker)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$invert(
					$author$project$Sticker$color(sticker))),
				$author$project$SE$UI$Font$bodySizeRem(-4),
				$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
				$rtfeldman$elm_css$Css$fontWeight(
				$rtfeldman$elm_css$Css$int(600)),
				$rtfeldman$elm_css$Css$letterSpacing(
				$rtfeldman$elm_css$Css$px(0.5)),
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$px(4)),
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(
				A2(
					$author$project$SE$UI$Icon$V2$withSize,
					1,
					$author$project$Sticker$icon(sticker))),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Sticker$singular(sticker))
					]))
			]));
};
var $author$project$Page$Products$View$viewStickersContainer = F2(
	function (isAbsolute, maybeSticker) {
		return A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				A3(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							A2($rtfeldman$elm_css$Css$property, 'gap', '0.25rem'),
							$rtfeldman$elm_css$Css$batch(
							isAbsolute ? _List_fromArray(
								[
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
									$rtfeldman$elm_css$Css$top(
									$rtfeldman$elm_css$Css$px(4)),
									$rtfeldman$elm_css$Css$left(
									$rtfeldman$elm_css$Css$px(4))
								]) : _List_Nil)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('stickers-container')
						])),
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$singleton,
					A2($elm$core$Maybe$map, $author$project$Sticker$view, maybeSticker))));
	});
var $author$project$Page$Bid$viewBidRow = F5(
	function (_v0, user, amountLookupFn, bid, row) {
		var now = _v0.a;
		var zone = _v0.b;
		var unit = $author$project$Price$unit(
			$author$project$BidRow$priceData(row));
		var product = $author$project$BidRow$product(row);
		var productExternalId = $author$project$Product$externalId(product);
		var productMetadata = $author$project$Product$metadata(product);
		var maybePriceData = A2(
			$elm$core$Maybe$andThen,
			function (p) {
				if (p.$ === 1) {
					var data = p.a;
					return $elm$core$Maybe$Just(data);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			$author$project$Product$prices(product));
		var amount = amountLookupFn(
			$author$project$BidRow$id(row));
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$width(80)
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Image$viewMaybeThumbnail,
							80,
							$author$project$ExternalId$toString(productExternalId),
							$elm$core$List$head(productMetadata.fd))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$class,
									'stickers-container',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$px(4))
										]))
								]))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Page$Products$View$viewStickersContainer, false, productMetadata.d8),
							$author$project$Page$Products$View$viewBrand(productMetadata.dp),
							$author$project$Page$Products$View$viewName(product),
							$author$project$Page$Products$View$viewExternalId(productExternalId)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Page$Bid$viewQuantity,
							$author$project$BidRow$quantity(row),
							unit)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Page$Bid$viewQuantity,
							$author$project$BidRow$orderableQuantity(row),
							unit)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Helpers$viewMaybe,
							A3($author$project$Price$viewSmallTag, productMetadata.d8, user.c0.gA, user.c0.ga),
							maybePriceData)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$author$project$Price$viewSmallTag,
							productMetadata.d8,
							user.c0.gA,
							user.c0.ga,
							$author$project$BidRow$priceData(row))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right),
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$class,
									'field',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd)
										]))
								]))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A5($author$project$Page$Bid$viewAddToCart, now, amount, unit, bid, row)
						]))
				]));
	});
var $author$project$Page$Bid$viewEmptyRows = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$author$project$SE$UI$Utils$block,
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
		]),
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Title$title1('Inga produkter'),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Vi kan inte hitta några produkter för denna BID. Kontakta '),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:order@specialelektronik.se')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('order@specialelektronik.se')
						])),
					$rtfeldman$elm_css$Html$Styled$text(' om du har några frågor.')
				]))
		]));
var $author$project$SE$UI$Tag$Color = function (a) {
	return {$: 0, a: a};
};
var $author$project$SE$UI$Tag$Normal = {$: 1};
var $author$project$SE$UI$Colors$Lighter = 2;
var $author$project$Bid$statusColorLabel = function (status_) {
	switch (status_) {
		case 2:
			return _Utils_Tuple2(12, 'UTGÅNGEN');
		case 1:
			return _Utils_Tuple2(9, 'AKTIV');
		case 0:
			return _Utils_Tuple2(2, 'KOMMANDE');
		default:
			return _Utils_Tuple2(12, 'AVSLUTAD');
	}
};
var $author$project$SE$UI$Tag$tagModifier = function (m) {
	return $rtfeldman$elm_css$Css$batch(
		function () {
			switch (m.$) {
				case 0:
					var color = m.a;
					return _List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor(
							$author$project$SE$UI$Colors$toHsla(color)),
							$author$project$SE$UI$Colors$color(
							$author$project$SE$UI$Colors$invert(
								$author$project$SE$UI$Colors$toHsla(color)))
						]);
				case 1:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(0.75))
						]);
				case 2:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(1))
						]);
				default:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(1.25))
						]);
			}
		}());
};
var $author$project$SE$UI$Tag$tagModifiers = function (mods) {
	return $rtfeldman$elm_css$Css$batch(
		A2($elm$core$List$map, $author$project$SE$UI$Tag$tagModifier, mods));
};
var $author$project$SE$UI$Tag$tagStyle = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
			$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
			$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$rem(0.75)),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$em(2)),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1.5)),
			$rtfeldman$elm_css$Css$paddingLeft(
			$rtfeldman$elm_css$Css$em(0.75)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$em(0.75)),
			$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap)
		]));
var $author$project$SE$UI$Tag$tag = F2(
	function (mods, t) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$author$project$SE$UI$Tag$tagStyle,
					$author$project$SE$UI$Tag$tagModifiers(mods)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('tag')
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(t)
				]));
	});
var $author$project$Bid$viewStatusTag = function (status_) {
	var _v0 = $author$project$Bid$statusColorLabel(status_);
	var color = _v0.a;
	var label = _v0.b;
	return A2(
		$author$project$SE$UI$Tag$tag,
		_List_fromArray(
			[
				$author$project$SE$UI$Tag$Color(color),
				$author$project$SE$UI$Tag$Normal
			]),
		label);
};
var $author$project$SE$UI$Icon$V2$lightbulb = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		352,
		512,
		_List_fromArray(
			['M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z'])));
var $author$project$Page$Bid$viewTips = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$author$project$SE$UI$Utils$block,
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
		]),
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$lightbulb),
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
					$author$project$SE$UI$Font$bodySizeRem(-1)
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$strong,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('OBS!')
						])),
					$rtfeldman$elm_css$Html$Styled$text(' Om en produkt saknas kan det bero på att den utgått eller aldrig publicerats på Partnerzonen.')
				]))
		]));
var $author$project$Page$Bid$viewBid = F4(
	function (_v0, user, amounts, bid) {
		var now = _v0.a;
		var zone = _v0.b;
		var rows = $author$project$Bid$rows(bid);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Card$toHtml(
					$author$project$SE$UI$Card$withBoxShadow(
						$author$project$SE$UI$Card$content(
							_List_fromArray(
								[
									$author$project$SE$UI$Columns$columns(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$p,
													_List_Nil,
													_List_fromArray(
														[
															$author$project$Bid$viewStatusTag(
															A2($author$project$Bid$status, now, bid))
														]))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													$author$project$SE$UI$Title$title6('Startdatum'),
													$rtfeldman$elm_css$Html$Styled$text(
													A2(
														$author$project$Helpers$viewDate,
														$author$project$Bid$validFrom(bid),
														zone))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													$author$project$SE$UI$Title$title6('Sista beställningsdatum'),
													$rtfeldman$elm_css$Html$Styled$text(
													A2(
														$author$project$Helpers$viewDate,
														$author$project$Bid$noOrdersAfter(bid),
														zone))
												])),
											A2(
											$author$project$SE$UI$Columns$column,
											_List_fromArray(
												[
													_Utils_Tuple2(0, 1)
												]),
											_List_fromArray(
												[
													A4(
													$rtfeldman$elm_css$Html$Styled$styled,
													$rtfeldman$elm_css$Html$Styled$a,
													A2(
														$author$project$SE$UI$Buttons$buttonStyles,
														_List_fromArray(
															[
																$author$project$SE$UI$Buttons$Color(10)
															]),
														false),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(
															'mailto:order@specialelektronik.se?subject=F%C3%B6rl%C3%A4ng%20eller%20ut%C3%B6ka%20BID%3A%20' + ($author$project$ExternalId$toString(
																$author$project$Bid$externalId(bid)) + '&body=Hej!%0A%0AJag%20vill%20f%C3%B6rl%C3%A4nga%20denna%20BID%20till%3A%20%5BANGE%20DATUM%5D%0A%0AEller%0A%0AJag%20vill%20ut%C3%B6ka%20antalet%20av%20%5BANGE%20ARTIKELKOD%2FARTIKELNUMMER%5D%20till%20%5BANGE%20ANTAL%5D%20st.%0A'))
														]),
													_List_fromArray(
														[
															$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$envelope),
															A2(
															$rtfeldman$elm_css$Html$Styled$span,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Förläng eller utöka BID')
																]))
														]))
												]))
										]))
								])))),
					$elm$core$List$isEmpty(rows) ? $author$project$Page$Bid$viewEmptyRows : $author$project$SE$UI$Card$toHtml(
					$author$project$SE$UI$Card$withBoxShadow(
						$author$project$SE$UI$Card$content(
							_List_fromArray(
								[
									$author$project$SE$UI$Table$V2$toHtml(
									A2(
										$author$project$SE$UI$Table$V2$withHead,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Produkt')
													])),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
													]),
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Totalt antal')
													])),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
													]),
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Antal kvar')
													])),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
													]),
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Normalt pris')
													])),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
													]),
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('BID-pris')
													])),
												A2($rtfeldman$elm_css$Html$Styled$th, _List_Nil, _List_Nil)
											]),
										A2(
											$author$project$SE$UI$Table$V2$withModifiers,
											_List_fromArray(
												[0]),
											A2(
												$author$project$SE$UI$Table$V2$body,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$margin(
																$rtfeldman$elm_css$Css$px(-17)),
																$rtfeldman$elm_css$Css$width(
																A3(
																	$rtfeldman$elm_css$Css$calc,
																	$rtfeldman$elm_css$Css$pct(100),
																	$rtfeldman$elm_css$Css$plus,
																	$rtfeldman$elm_css$Css$px(34))),
																$rtfeldman$elm_css$Css$borderRadius(
																$rtfeldman$elm_css$Css$px(2)),
																$rtfeldman$elm_css$Css$Global$children(
																_List_fromArray(
																	[
																		A2(
																		$rtfeldman$elm_css$Css$Global$selector,
																		'tbody td',
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
																			]))
																	]))
															]))
													]),
												A2(
													$elm$core$List$map,
													A4(
														$author$project$Page$Bid$viewBidRow,
														_Utils_Tuple2(now, zone),
														user,
														$author$project$Amount$find(amounts),
														bid),
													rows)))))
								])))),
					$author$project$Page$Bid$viewTips
				]));
	});
var $author$project$Breadcrumb$Bid = function (a) {
	return {$: 9, a: a};
};
var $author$project$Breadcrumb$Loading = {$: 17};
var $author$project$Page$Bid$viewBreadcrumbs = function (status) {
	switch (status.$) {
		case 0:
			return $author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Loading);
		case 3:
			return A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil);
		case 2:
			var order = status.a;
			return $author$project$Breadcrumb$toHtml(
				$author$project$Breadcrumb$Bid(order));
		default:
			var order = status.a;
			return $author$project$Breadcrumb$toHtml(
				$author$project$Breadcrumb$Bid(order));
	}
};
var $author$project$Page$Bid$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		var title = $author$project$Page$Bid$bidToTitle(model.aF);
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Page$Bid$viewBreadcrumbs(model.aF),
								A2($author$project$Page$viewLoggedInMenu, 7, user),
								$author$project$SE$UI$Title$title1(title),
								A3(
								$author$project$Status$view,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Api$errorToHtml,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
								A3(
									$author$project$Page$Bid$viewBid,
									_Utils_Tuple2(
										model.el,
										$author$project$Session$zone(model.K)),
									user,
									model.bE),
								model.aF)
							]))
					])),
			gr: title
		};
	}
};
var $author$project$Breadcrumb$Bids = {$: 8};
var $author$project$Page$Bids$GotPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Status$isLoading = function (status) {
	switch (status.$) {
		case 0:
			return true;
		case 1:
			return true;
		default:
			return false;
	}
};
var $author$project$SE$UI$Pagination$V2$Pagination = $elm$core$Basics$identity;
var $author$project$SE$UI$Pagination$V2$create = function (_v0) {
	var currentPage = _v0.g1;
	var lastPage = _v0.hJ;
	var nextPageLabel = _v0.hY;
	var previousPageLabel = _v0.ig;
	var msg = _v0.cJ;
	return {ci: 0, g1: currentPage, hJ: lastPage, cJ: msg, hY: nextPageLabel, ig: previousPageLabel, d6: 0};
};
var $author$project$Page$Bids$View$emptyTable = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
		]),
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Title$title1('Inga bid'),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Vi kan inte hitta några bid. Om du sökt på ett bidnummer, kontrollera att du skrivit rätt.')
				]))
		]));
var $author$project$SE$UI$Alignment$Centered = 1;
var $author$project$SE$UI$Pagination$V2$withAlignment = F2(
	function (alignment, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{ci: alignment});
	});
var $author$project$SE$UI$Pagination$V2$isCentered = $author$project$SE$UI$Pagination$V2$withAlignment(1);
var $author$project$Page$Bids$View$row = F2(
	function (_v0, bid) {
		var zone = _v0.a;
		var now = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Bid$viewStatusTag(
							A2($author$project$Bid$status, now, bid))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$author$project$Route$href(
									$author$project$Route$Bid(
										$author$project$Bid$id(bid)))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$ExternalId$toString(
										$author$project$Bid$externalId(bid)))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$author$project$Route$href(
									$author$project$Route$Bid(
										$author$project$Bid$id(bid)))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Bid$name(bid))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2(
								$author$project$Helpers$viewDate,
								$author$project$Bid$validFrom(bid),
								zone))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2(
								$author$project$Helpers$viewDate,
								$author$project$Bid$noOrdersAfter(bid),
								zone))
						]))
				]));
	});
var $author$project$Page$Bids$View$tableHelper = F2(
	function (zoneAndNow, bids) {
		return A2(
			$author$project$SE$UI$Table$V2$withHead,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Status')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('BIDnummer')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Namn')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Startdatum')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Sista beställningsdatum')
						]))
				]),
			A2(
				$author$project$SE$UI$Table$V2$withModifiers,
				_List_fromArray(
					[1, 0]),
				A2(
					$author$project$SE$UI$Table$V2$body,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$descendants(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Global$class,
											'tag',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontWeight(
													$rtfeldman$elm_css$Css$int(600)),
													$author$project$SE$UI$Font$bodySizeRem(-3)
												]))
										]))
								]))
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$Bids$View$row(zoneAndNow),
						bids))));
	});
var $author$project$SE$UI$Pagination$V2$alignmentToClass = function (alignment) {
	return _Utils_Tuple2(
		function () {
			switch (alignment) {
				case 0:
					return 'is-left';
				case 1:
					return 'is-centered';
				default:
					return 'is-right';
			}
		}(),
		true);
};
var $rtfeldman$elm_css$Css$default = {a: 0, iY: 'default'};
var $author$project$SE$UI$Pagination$V2$itemAndEllipsisStyles = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$author$project$SE$UI$Control$controlStyle(0),
			$author$project$SE$UI$Utils$unselectable,
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$em(1)),
			$rtfeldman$elm_css$Css$paddingLeft(
			$rtfeldman$elm_css$Css$em(0.5)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$em(0.5)),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$margin(
			$rtfeldman$elm_css$Css$rem(0.25)),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$minWidth(
			$rtfeldman$elm_css$Css$em(3))
		]));
var $author$project$SE$UI$Pagination$V2$itemStyles = _List_fromArray(
	[
		$author$project$SE$UI$Pagination$V2$itemAndEllipsisStyles,
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
		$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border)),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$text))
			])),
		$rtfeldman$elm_css$Css$focus(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border)),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$text))
			])),
		$rtfeldman$elm_css$Css$active(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor(
				$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$border)),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$text))
			])),
		$rtfeldman$elm_css$Css$disabled(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lighter),
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$lighter),
				$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
				$rtfeldman$elm_css$Css$opacity(
				$rtfeldman$elm_css$Css$num(0.5))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-current',
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$link),
				$rtfeldman$elm_css$Css$important(
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$link)),
				$rtfeldman$elm_css$Css$important(
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white)),
				$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none),
				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$default)
			]))
	]);
var $author$project$SE$UI$Pagination$V2$listItem = F3(
	function (message, isCurrent, page) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$button,
					$author$project$SE$UI$Pagination$V2$itemStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							message(page)),
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('pagination-link', true),
									_Utils_Tuple2('is-current', isCurrent)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(page))
						]))
				]));
	});
var $author$project$SE$UI$Pagination$V2$all = F3(
	function (message, lastPage, currentPage) {
		return A2(
			$elm$core$List$map,
			function (p) {
				return A3(
					$author$project$SE$UI$Pagination$V2$listItem,
					message,
					_Utils_eq(p, currentPage),
					p);
			},
			A2($elm$core$List$range, 1, lastPage));
	});
var $author$project$SE$UI$Pagination$V2$ellipsisStyle = _List_fromArray(
	[
		$author$project$SE$UI$Pagination$V2$itemAndEllipsisStyles,
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$light),
		$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none),
		$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$unset)
	]);
var $author$project$SE$UI$Pagination$V2$listEllipsis = A2(
	$rtfeldman$elm_css$Html$Styled$li,
	_List_Nil,
	_List_fromArray(
		[
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			$author$project$SE$UI$Pagination$V2$ellipsisStyle,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('…')
				]))
		]));
var $author$project$SE$UI$Pagination$V2$clipped = F3(
	function (message, lastPage, currentPage) {
		return _List_fromArray(
			[
				A3($author$project$SE$UI$Pagination$V2$listItem, message, currentPage === 1, 1),
				(currentPage > 3) ? $author$project$SE$UI$Pagination$V2$listEllipsis : $rtfeldman$elm_css$Html$Styled$text(''),
				(currentPage > 2) ? A3($author$project$SE$UI$Pagination$V2$listItem, message, false, currentPage - 1) : $rtfeldman$elm_css$Html$Styled$text(''),
				((currentPage !== 1) && (!_Utils_eq(currentPage, lastPage))) ? A3($author$project$SE$UI$Pagination$V2$listItem, message, true, currentPage) : $rtfeldman$elm_css$Html$Styled$text(''),
				(_Utils_cmp(currentPage, lastPage - 1) < 0) ? A3($author$project$SE$UI$Pagination$V2$listItem, message, false, currentPage + 1) : $rtfeldman$elm_css$Html$Styled$text(''),
				(_Utils_cmp(currentPage, lastPage - 2) < 0) ? $author$project$SE$UI$Pagination$V2$listEllipsis : $rtfeldman$elm_css$Html$Styled$text(''),
				A3(
				$author$project$SE$UI$Pagination$V2$listItem,
				message,
				_Utils_eq(currentPage, lastPage),
				lastPage)
			]);
	});
var $author$project$SE$UI$Pagination$V2$navAndListStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
	]);
var $author$project$SE$UI$Pagination$V2$listStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$batch($author$project$SE$UI$Pagination$V2$navAndListStyles),
		$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
		$author$project$SE$UI$Utils$tablet(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexGrow(
				$rtfeldman$elm_css$Css$int(1)),
				$rtfeldman$elm_css$Css$flexShrink(
				$rtfeldman$elm_css$Css$int(1))
			]))
	]);
var $author$project$SE$UI$Pagination$V2$list = F3(
	function (message, lastPage, currentPage) {
		var itemList = (lastPage > 7) ? $author$project$SE$UI$Pagination$V2$clipped : $author$project$SE$UI$Pagination$V2$all;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$ul,
			$author$project$SE$UI$Pagination$V2$listStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('pagination-list', true)
						]))
				]),
			A3(itemList, message, lastPage, currentPage));
	});
var $rtfeldman$elm_css$Css$order = $rtfeldman$elm_css$Css$prop1('order');
var $author$project$SE$UI$Pagination$V2$centerNavStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-list',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(2))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-previous',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(1))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-next',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(3))
					]))
			]))
	]);
var $author$project$SE$UI$Pagination$V2$leftNavStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-list',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(1))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-previous',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(2))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-next',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(3))
					]))
			]))
	]);
var $rtfeldman$elm_css$Css$Global$li = $rtfeldman$elm_css$Css$Global$typeSelector('li');
var $author$project$SE$UI$Pagination$V2$rightNavStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-list',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(3))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-previous',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(1))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'pagination-next',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$int(2))
					]))
			]))
	]);
var $author$project$SE$UI$Pagination$V2$navStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$batch($author$project$SE$UI$Pagination$V2$navAndListStyles),
		$rtfeldman$elm_css$Css$margin(
		$rtfeldman$elm_css$Css$rem(-0.25)),
		$author$project$SE$UI$Utils$mobile(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$each,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$class('pagination-previous'),
								$rtfeldman$elm_css$Css$Global$class('pagination-next')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexGrow(
								$rtfeldman$elm_css$Css$int(1)),
								$rtfeldman$elm_css$Css$flexShrink(
								$rtfeldman$elm_css$Css$int(1))
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'pagination-list',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$li(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$flexGrow(
												$rtfeldman$elm_css$Css$int(1)),
												$rtfeldman$elm_css$Css$flexShrink(
												$rtfeldman$elm_css$Css$int(1))
											]))
									]))
							]))
					]))
			])),
		$author$project$SE$UI$Utils$tablet(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
				A2($rtfeldman$elm_css$Css$Global$withClass, 'is-left', $author$project$SE$UI$Pagination$V2$leftNavStyles),
				A2($rtfeldman$elm_css$Css$Global$withClass, 'is-centered', $author$project$SE$UI$Pagination$V2$centerNavStyles),
				A2($rtfeldman$elm_css$Css$Global$withClass, 'is-right', $author$project$SE$UI$Pagination$V2$rightNavStyles)
			])),
		$rtfeldman$elm_css$Css$fontSize(
		$rtfeldman$elm_css$Css$px(16)),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-small',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$px(12))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-medium',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$px(20))
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-large',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$px(24))
			]))
	]);
var $author$project$SE$UI$Pagination$V2$next = F4(
	function (msg, label, currentPage, lastPage) {
		var isDisabled = _Utils_eq(currentPage, lastPage);
		var attrs = isDisabled ? _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(isDisabled)
			]) : _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Events$onClick(
				msg(currentPage + 1))
			]);
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$button,
			$author$project$SE$UI$Pagination$V2$itemStyles,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('pagination-next', true)
						])),
				attrs),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(label)
				]));
	});
var $author$project$SE$UI$Pagination$V2$previous = F3(
	function (msg, label, currentPage) {
		var isDisabled = currentPage === 1;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$button,
			$author$project$SE$UI$Pagination$V2$itemStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					msg(currentPage - 1)),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('pagination-previous', true)
						])),
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(isDisabled)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(label)
				]));
	});
var $author$project$SE$UI$Pagination$V2$sizeToClass = function (size) {
	return _Utils_Tuple2(
		function () {
			switch (size) {
				case 0:
					return '';
				case 1:
					return 'is-small';
				case 2:
					return 'is-medium';
				default:
					return 'is-large';
			}
		}(),
		true);
};
var $author$project$SE$UI$Pagination$V2$toHtml = function (_v0) {
	var internals = _v0;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$nav,
		$author$project$SE$UI$Pagination$V2$navStyles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('pagination', true),
						$author$project$SE$UI$Pagination$V2$alignmentToClass(internals.ci),
						$author$project$SE$UI$Pagination$V2$sizeToClass(internals.d6)
					])),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'role', 'navigation'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-label', 'pagination')
			]),
		_List_fromArray(
			[
				A3($author$project$SE$UI$Pagination$V2$previous, internals.cJ, internals.ig, internals.g1),
				A4($author$project$SE$UI$Pagination$V2$next, internals.cJ, internals.hY, internals.g1, internals.hJ),
				A3($author$project$SE$UI$Pagination$V2$list, internals.cJ, internals.hJ, internals.g1)
			]));
};
var $author$project$SE$UI$Table$V2$withFoot = F2(
	function (rows, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{bi: rows});
	});
var $author$project$Page$Bids$View$table = F3(
	function (goToPageMsg, zoneAndNow, bids) {
		if ($elm$core$List$isEmpty(bids.g2)) {
			return $author$project$Page$Bids$View$emptyTable;
		} else {
			var footerFn = (bids.hJ === 1) ? $elm$core$Basics$identity : $author$project$SE$UI$Table$V2$withFoot(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(8)
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Pagination$V2$toHtml(
								$author$project$SE$UI$Pagination$V2$isCentered(
									$author$project$SE$UI$Pagination$V2$create(
										{g1: bids.g1, hJ: bids.hJ, cJ: goToPageMsg, hY: 'Nästa ⯈', ig: '⯇ Föregående'})))
							]))
					]));
			return $author$project$SE$UI$Table$V2$toHtml(
				footerFn(
					A2($author$project$Page$Bids$View$tableHelper, zoneAndNow, bids.g2)));
		}
	});
var $rtfeldman$elm_css$Css$prop6 = F7(
	function (key, argA, argB, argC, argD, argE, argF) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.iY, argB.iY, argC.iY, argD.iY, argE.iY, argF.iY])));
	});
var $rtfeldman$elm_css$Css$boxShadow6 = $rtfeldman$elm_css$Css$prop6('box-shadow');
var $author$project$Contactbox$viewPhoneEmailHelper = function (styles) {
	return A3(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'a',
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark),
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$class,
										'icon',
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
											]))
									]))
							]))
					])),
			styles),
		_List_Nil);
};
var $author$project$Contactbox$viewEmail = F2(
	function (styles, _v0) {
		var recipient = _v0.fZ;
		var subject = _v0.c5;
		var body = _v0.bI;
		var root = 'mailto:' + (recipient + '@specialelektronik.se');
		var params = $elm$url$Url$Builder$toQuery(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var k = _v1.a;
					var v = _v1.b;
					return A2($elm$url$Url$Builder$string, k, v);
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Tuple$second,
						A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not)),
					_List_fromArray(
						[
							_Utils_Tuple2('subject', subject),
							_Utils_Tuple2('body', body)
						]))));
		return A2(
			$author$project$Contactbox$viewPhoneEmailHelper,
			styles,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href(
							_Utils_ap(root, params))
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$envelope),
							$rtfeldman$elm_css$Html$Styled$text(recipient + '@specialelektronik.se')
						]))
				]));
	});
var $author$project$SE$UI$Icon$V2$phone = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'])));
var $author$project$Contactbox$viewPhone = F2(
	function (styles, suffix) {
		return A2(
			$author$project$Contactbox$viewPhoneEmailHelper,
			styles,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href('tel:+465444420' + suffix)
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$phone),
							$rtfeldman$elm_css$Html$Styled$text('054 444 20' + suffix)
						]))
				]));
	});
var $author$project$Contactbox$view = function (config) {
	var imageHtml = function () {
		var _v0 = config.fc;
		if (!_v0.$) {
			var imagePath = _v0.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$height(110),
						$rtfeldman$elm_css$Html$Styled$Attributes$width(110),
						$rtfeldman$elm_css$Html$Styled$Attributes$src(imagePath)
					]),
				_List_Nil);
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				A3(
				$rtfeldman$elm_css$Css$border3,
				$rtfeldman$elm_css$Css$px(1),
				$rtfeldman$elm_css$Css$solid,
				$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$rem(1)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
				$rtfeldman$elm_css$Css$borderBottom($rtfeldman$elm_css$Css$zero),
				A6(
				$rtfeldman$elm_css$Css$boxShadow6,
				$rtfeldman$elm_css$Css$inset,
				$rtfeldman$elm_css$Css$zero,
				$rtfeldman$elm_css$Css$px(-3),
				$rtfeldman$elm_css$Css$zero,
				$rtfeldman$elm_css$Css$zero,
				$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$primary)),
				$rtfeldman$elm_css$Css$maxWidth(
				$rtfeldman$elm_css$Css$px(500))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Title$title5(config.gr),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
								A3(
								$rtfeldman$elm_css$Css$flex3,
								$rtfeldman$elm_css$Css$zero,
								$rtfeldman$elm_css$Css$zero,
								$rtfeldman$elm_css$Css$px(80)),
								$rtfeldman$elm_css$Css$marginRight(
								$rtfeldman$elm_css$Css$rem(1))
							]),
						_List_Nil,
						_List_fromArray(
							[imageHtml])),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexGrow(
								$rtfeldman$elm_css$Css$int(1))
							]),
						_List_Nil,
						_Utils_ap(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(config.eM)
										])),
									A2($author$project$Contactbox$viewPhone, _List_Nil, config.fJ)
								]),
							A2(
								$elm$core$List$map,
								function (recipientPrefix) {
									return A2(
										$author$project$Contactbox$viewEmail,
										_List_Nil,
										{bI: config.bI, fZ: recipientPrefix, c5: config.c5});
								},
								config.f_)))
					]))
			]));
};
var $author$project$Page$Bids$viewCustomerCareInfo = $author$project$Contactbox$view(
	{
		bI: '',
		eM: 'Säljavdelningen',
		fc: $elm$core$Maybe$Just('/assets/images/innesalj.jpg'),
		fJ: '30',
		f_: _List_fromArray(
			['order']),
		c5: 'BID på Partnerzonen',
		gr: 'Behöver du hjälp? Hör av dig!'
	});
var $author$project$Page$Bids$ChangedModal = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Html$Styled$em = $rtfeldman$elm_css$Html$Styled$node('em');
var $author$project$Page$viewBidModal = function (closeMsg) {
	return A2(
		$author$project$SE$UI$Modal$modal,
		closeMsg,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$em(2)),
						$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white)
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title5('Vad är BID?'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vissa leverantörer erbjuder bättre priser för enskilda, unika projekt. Dessa är tidsbegränsade och gäller för en förutbestämd kvantitet.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Produkter som ingår i en BID får '),
										A2(
										$rtfeldman$elm_css$Html$Styled$em,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('inte')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' användas utanför projektet.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Kontakta en av våra säljare om du vill veta mer:'),
										A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
										$rtfeldman$elm_css$Html$Styled$text('Tel: 054-444 2030'),
										A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
										$rtfeldman$elm_css$Html$Styled$text('E-post: '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:order@specialelektronik.se?subject=Fr%C3%A5ga%20ang.%20BID')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('order@specialelektronik.se')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Bids$viewModal = function (modal) {
	if (!modal) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		return $author$project$Page$viewBidModal(
			$author$project$Page$Bids$ChangedModal(0));
	}
};
var $author$project$Page$Bids$BIDModal = 1;
var $author$project$Page$Bids$GotQuery = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Bids$NoOp = {$: 0};
var $author$project$Page$Bids$SubmittedQuery = {$: 6};
var $rtfeldman$elm_css$Html$Styled$Attributes$method = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('method');
var $author$project$Page$Bids$viewSearch = F2(
	function (query, loading) {
		var mods = A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Buttons$Color(9),
			loading ? _List_fromArray(
				[$author$project$SE$UI$Buttons$Loading]) : _List_Nil);
		var maybeMsg = function () {
			var _v0 = $elm$core$String$trim(query);
			if (_v0 === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just($author$project$Page$Bids$SubmittedQuery);
			}
		}();
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[$author$project$SE$UI$Utils$block]),
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Title$title5('Sök BID'),
					A2(
					$rtfeldman$elm_css$Html$Styled$form,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onSubmit(
							A2($elm$core$Maybe$withDefault, $author$project$Page$Bids$NoOp, maybeMsg)),
							$rtfeldman$elm_css$Html$Styled$Attributes$method('post')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_fromArray(
								[0, 1]),
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$expandedControl,
									loading,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withTrigger,
												0,
												A2(
													$author$project$SE$UI$Form$Input$withPlaceholder,
													'Sök på BIDnummer',
													A2($author$project$SE$UI$Form$Input$text, $author$project$Page$Bids$GotQuery, query))))
										])),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$button,
											A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Sök')
												]))
										]))
								]))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(0.5)),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(1.5))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
									$rtfeldman$elm_css$Css$hover(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
											$author$project$SE$UI$Colors$color(
											$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									$author$project$Page$Bids$ChangedModal(1))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Vad är BID?')
								]))
						]))
				]));
	});
var $author$project$Page$Bids$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Bids),
								A2($author$project$Page$viewLoggedInMenu, 7, user),
								$author$project$SE$UI$Title$title1('BID'),
								$author$project$SE$UI$Card$toHtml(
								$author$project$SE$UI$Card$content(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$columns(
											_List_fromArray(
												[
													$author$project$SE$UI$Columns$defaultColumn(
													_List_fromArray(
														[
															A2(
															$author$project$Page$Bids$viewSearch,
															model.d0,
															$author$project$Status$isLoading(model.R))
														])),
													A2(
													$author$project$SE$UI$Columns$column,
													_List_fromArray(
														[
															_Utils_Tuple2(3, 1)
														]),
													_List_fromArray(
														[$author$project$Page$Bids$viewCustomerCareInfo]))
												])),
											A3(
											$author$project$Status$view,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Api$errorToHtml,
												A2(
													$elm$core$Basics$composeR,
													$elm$core$List$singleton,
													$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
											A2(
												$author$project$Page$Bids$View$table,
												$author$project$Page$Bids$GotPage,
												_Utils_Tuple2(
													$author$project$Session$zone(model.K),
													model.el)),
											model.R)
										])))
							])),
						$author$project$Page$Bids$viewModal(model.cH)
					])),
			gr: 'BID'
		};
	}
};
var $author$project$Page$Blank$view = {
	eN: $rtfeldman$elm_css$Html$Styled$text(''),
	gr: ''
};
var $author$project$Status$viewApi = F2(
	function (loadedFn, status) {
		switch (status.$) {
			case 0:
				return $rtfeldman$elm_css$Html$Styled$text('laddar...');
			case 1:
				var value = status.a;
				return loadedFn(value);
			case 2:
				var value = status.a;
				return loadedFn(value);
			default:
				var error = status.a;
				return $author$project$Api$errorToHtml(error);
		}
	});
var $author$project$Brand$logo = function (_v0) {
	var internals = _v0;
	return internals.fu;
};
var $author$project$SE$UI$Image$V2$toSimpleHtml = F2(
	function (altAndSrc, _v0) {
		var internals = _v0;
		return A2(
			$author$project$SE$UI$Image$V2$figure,
			internals,
			_List_fromArray(
				[
					A2($author$project$SE$UI$Image$V2$img, altAndSrc, internals)
				]));
	});
var $author$project$Image$viewSimple = F3(
	function (key, alt, _v0) {
		var sizes = _v0.c1;
		var _v1 = A2($author$project$Image$findSize, key, sizes);
		if (_v1.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!_v1.a.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v2 = _v1.a;
				var head = _v2.a;
				return $elm$core$Maybe$Just(
					A2(
						$author$project$SE$UI$Image$V2$toSimpleHtml,
						{gM: alt, iI: head.cg},
						$author$project$SE$UI$Image$V2$create(
							{e7: head.e7, gE: head.gE})));
			}
		}
	});
var $author$project$Page$Brands$viewTitle = function (brand) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$h3,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$block,
				$author$project$SE$UI$Font$titleSizeEm(4),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'figure',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
							]))
					]))
			]),
		_List_Nil,
		$elm$core$List$singleton(
			A2(
				$elm$core$Maybe$withDefault,
				$rtfeldman$elm_css$Html$Styled$text(
					$author$project$Brand$name(brand)),
				A2(
					$elm$core$Maybe$andThen,
					A2(
						$author$project$Image$viewSimple,
						0,
						$author$project$Brand$name(brand)),
					$author$project$Brand$logo(brand)))));
};
var $author$project$Page$Brands$viewBrand = function (brand) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Css$boxShadow4,
				$rtfeldman$elm_css$Css$zero,
				$rtfeldman$elm_css$Css$px(4),
				$rtfeldman$elm_css$Css$px(10),
				$author$project$SE$UI$Colors$toCss(
					A2(
						$author$project$SE$UI$Colors$mapAlpha,
						$elm$core$Basics$always(0.15),
						$author$project$SE$UI$Colors$black))),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$rem(0.75)),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$img(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$auto)
							]))
					]))
			]),
		_List_fromArray(
			[
				$author$project$Route$href(
				A2(
					$author$project$Route$Brand,
					$author$project$QueryParams$empty,
					$author$project$Brand$slug(brand)))
			]),
		_List_fromArray(
			[
				$author$project$Page$Brands$viewTitle(brand)
			]));
};
var $author$project$Page$Brands$viewBrands = function (brands) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$block,
				A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
				A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1rem'),
				$author$project$SE$UI$Utils$fullhd(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fit, minmax(400px, 1fr))')
					])),
				$author$project$SE$UI$Utils$tablet(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fit, minmax(320px, 1fr))')
					]))
			]),
		_List_Nil,
		A2($elm$core$List$map, $author$project$Page$Brands$viewBrand, brands));
};
var $author$project$Page$Brands$view = function (model) {
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title1('Varumärken'),
							A2($author$project$Status$viewApi, $author$project$Page$Brands$viewBrands, model.bK)
						]))
				])),
		gr: 'Varumärken'
	};
};
var $author$project$SE$UI$Columns$OneQuarter = 4;
var $author$project$SE$UI$Columns$Widescreen = 4;
var $author$project$Cart$isEmpty = function (_v0) {
	var rs = _v0;
	return $elm$core$List$isEmpty(rs);
};
var $author$project$Cart$error = function (_v0) {
	var internals = _v0;
	return internals.dy;
};
var $author$project$Cart$isOrderable = function (_v0) {
	var rs = _v0;
	return A2(
		$elm$core$List$all,
		A2($elm$core$Basics$composeR, $author$project$Cart$error, $elm$core$String$isEmpty),
		rs);
};
var $elm$virtual_dom$VirtualDom$lazy5 = _VirtualDom_lazy5;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp4 = F5(
	function (fn, arg1, arg2, arg3, arg4) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			A4(fn, arg1, arg2, arg3, arg4));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy4 = F5(
	function (fn, arg1, arg2, arg3, arg4) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A6($elm$virtual_dom$VirtualDom$lazy5, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp4, fn, arg1, arg2, arg3, arg4));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy4 = $rtfeldman$elm_css$VirtualDom$Styled$lazy4;
var $author$project$Status$map2 = F3(
	function (fn, first, second) {
		var _v0 = _Utils_Tuple2(first, second);
		_v0$5:
		while (true) {
			_v0$6:
			while (true) {
				_v0$7:
				while (true) {
					switch (_v0.a.$) {
						case 1:
							switch (_v0.b.$) {
								case 1:
									var firstValue = _v0.a.a;
									var secondValue = _v0.b.a;
									return $author$project$Status$Reloading(
										A2(fn, firstValue, secondValue));
								case 2:
									var firstValue = _v0.a.a;
									var secondValue = _v0.b.a;
									return $author$project$Status$Reloading(
										A2(fn, firstValue, secondValue));
								case 0:
									break _v0$5;
								default:
									break _v0$7;
							}
						case 2:
							switch (_v0.b.$) {
								case 1:
									var firstValue = _v0.a.a;
									var secondValue = _v0.b.a;
									return $author$project$Status$Reloading(
										A2(fn, firstValue, secondValue));
								case 2:
									var firstValue = _v0.a.a;
									var secondValue = _v0.b.a;
									return $author$project$Status$Loaded(
										A2(fn, firstValue, secondValue));
								case 0:
									break _v0$5;
								default:
									break _v0$7;
							}
						case 0:
							var _v1 = _v0.a;
							return $author$project$Status$Loading;
						default:
							switch (_v0.b.$) {
								case 0:
									break _v0$5;
								case 3:
									break _v0$6;
								default:
									break _v0$6;
							}
					}
				}
				var error = _v0.b.a;
				return $author$project$Status$Failed(error);
			}
			var error = _v0.a.a;
			return $author$project$Status$Failed(error);
		}
		var _v2 = _v0.b;
		return $author$project$Status$Loading;
	});
var $author$project$Session$ClearedCart = {$: 9};
var $rtfeldman$elm_css$Css$Global$div = $rtfeldman$elm_css$Css$Global$typeSelector('div');
var $author$project$Cart$rows = function (_v0) {
	var rs = _v0;
	return rs;
};
var $author$project$Session$EnteredAmount = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Cart$brand = function (_v0) {
	var internals = _v0;
	return internals.dp;
};
var $author$project$Cart$categories = function (_v0) {
	var internals = _v0;
	return internals.gV;
};
var $rtfeldman$elm_css$Css$ChUnits = 0;
var $rtfeldman$elm_css$Css$ch = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'ch');
var $author$project$Cart$image = function (_v0) {
	var internals = _v0;
	return internals.dG;
};
var $rtfeldman$elm_css$Css$Global$input = $rtfeldman$elm_css$Css$Global$typeSelector('input');
var $author$project$ProductStatus$isActive = function (status) {
	return A2(
		$elm$core$List$member,
		status,
		_List_fromArray(
			[0, 1]));
};
var $rtfeldman$elm_css$Css$lineThrough = {bv: 0, iY: 'line-through'};
var $author$project$Cart$name = function (_v0) {
	var internals = _v0;
	return internals.hT;
};
var $author$project$Cart$price = function (_v0) {
	var internals = _v0;
	return internals.cR;
};
var $author$project$Cart$productExternalId = function (_v0) {
	var internals = _v0;
	return internals.d$;
};
var $author$project$Cart$productId = function (_v0) {
	var internals = _v0;
	return internals.cT;
};
var $author$project$Cart$slug = function (_v0) {
	var internals = _v0;
	return internals.gb;
};
var $author$project$Cart$source = function (_v0) {
	var internals = _v0;
	return internals.ge;
};
var $author$project$Cart$status = function (_v0) {
	var internals = _v0;
	return internals.gh;
};
var $author$project$SE$UI$Icon$V2$trash = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		448,
		512,
		_List_fromArray(
			['M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z'])));
var $author$project$Cart$unit = function (_v0) {
	var internals = _v0;
	return internals.em;
};
var $author$project$Page$Checkout$viewCartRowError = function (error) {
	if (error === '') {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var e = error;
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('tag', true)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Tag$tag,
					_List_fromArray(
						[
							$author$project$SE$UI$Tag$Color(12)
						]),
					e)
				]));
	}
};
var $author$project$Page$Checkout$viewCartRowSource = function (source) {
	if (!source.$) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var bidId = source.b;
		var bidExternalId = source.c;
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('BID: '),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							$author$project$Route$Bid(bidId))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$ExternalId$toString(bidExternalId))
						]))
				]));
	}
};
var $author$project$Page$Checkout$viewPrice = F3(
	function (amount, price, vat) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$p,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$lineHeight(
					$rtfeldman$elm_css$Css$num(1.2))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$strong,
					_List_fromArray(
						[
							$author$project$SE$UI$Font$bodySizeRem(-1)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Helpers$formatToMoney(amount * price))
						])),
					A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
							$author$project$SE$UI$Font$bodySizeRem(-3)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$VAT$toLabel(vat))
						]))
				]));
	});
var $author$project$SE$UI$Form$Input$withDisabled = function (input) {
	switch (input.$) {
		case 0:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Text(
				_Utils_update(
					rec,
					{ct: true}));
		case 1:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Number(
				_Utils_update(
					rec,
					{ct: true}));
		case 2:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Textarea(
				_Utils_update(
					rec,
					{ct: true}));
		case 3:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Date(
				_Utils_update(
					rec,
					{ct: true}));
		case 4:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Password(
				_Utils_update(
					rec,
					{ct: true}));
		case 5:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Select(
				_Utils_update(
					rec,
					{ct: true}));
		case 7:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Email(
				_Utils_update(
					rec,
					{ct: true}));
		case 8:
			var rec = input.a;
			return $author$project$SE$UI$Form$Input$Tel(
				_Utils_update(
					rec,
					{ct: true}));
		default:
			var type_ = input.a;
			var rec = input.b;
			return A2(
				$author$project$SE$UI$Form$Input$Button,
				type_,
				_Utils_update(
					rec,
					{ct: true}));
	}
};
var $author$project$Page$Checkout$viewCartRow = function (row) {
	var status = $author$project$Cart$status(row);
	var source = $author$project$Cart$source(row);
	var productName = $author$project$Cart$name(row);
	var productId = $author$project$Cart$productId(row);
	var productExternalId = $author$project$Cart$productExternalId(row);
	var productExternalIdString = $author$project$ExternalId$toString(productExternalId);
	var price = $author$project$Cart$price(row);
	var minimal = {
		dp: $author$project$Cart$brand(row),
		gV: $author$project$Cart$categories(row),
		hh: productExternalId,
		hx: productId,
		hT: productName
	};
	var imageWidth = 80;
	var amount = $author$project$Cart$amount(row);
	var unit = A2(
		$author$project$Unit$toSwedishString,
		amount,
		$author$project$Cart$unit(row));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$input(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$withAttribute,
								'type=number',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$minWidth(
										$rtfeldman$elm_css$Css$ch(10))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$withClass,
				'status\\:locked',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$p(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'not(.tag)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$lineThrough)
											]))
									]))
							]))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'status:' + $author$project$ProductStatus$toString(status),
						true)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$width(imageWidth)
					]),
				_List_fromArray(
					[
						A3(
						$author$project$Image$viewMaybeThumbnail,
						imageWidth,
						productExternalIdString,
						$author$project$Cart$image(row))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Cart$brand(row))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$a,
												_List_fromArray(
													[
														$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
													]),
												_List_fromArray(
													[
														$author$project$Route$href(
														A2(
															$author$project$Route$Product,
															$author$project$QueryParams$empty,
															_Utils_Tuple2(
																productId,
																$author$project$Cart$slug(row))))
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(productName)
													]))
											]))
									])),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$ExternalId$toString(productExternalId))
									])),
								$author$project$Page$Checkout$viewCartRowSource(source),
								$author$project$Page$Checkout$viewCartRowError(
								$author$project$Cart$error(row))
							]))
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Form$field,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Form$expandedControl,
								false,
								_List_fromArray(
									[
										$author$project$SE$UI$Form$Input$toHtml(
										function (i) {
											return $author$project$ProductStatus$isActive(status) ? $elm$core$Basics$identity(i) : $author$project$SE$UI$Form$Input$withDisabled(i);
										}(
											A2(
												$author$project$SE$UI$Form$Input$withStep,
												1,
												A2(
													$author$project$SE$UI$Form$Input$withRange,
													_Utils_Tuple2(1, 10000),
													A2(
														$author$project$SE$UI$Form$Input$withPlaceholder,
														'Antal',
														A2(
															$author$project$SE$UI$Form$Input$number,
															A2(
																$elm$core$Basics$composeL,
																$author$project$Page$Checkout$GotSessionMsg,
																A2($author$project$Session$EnteredAmount, minimal, source)),
															$elm$core$String$fromFloat(amount)))))))
									])),
								A2(
								$author$project$SE$UI$Form$control,
								false,
								_List_fromArray(
									[
										A2($author$project$SE$UI$Buttons$staticButton, _List_Nil, unit)
									]))
							]))
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A3($author$project$Page$Checkout$viewPrice, 1, price, 1)
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A3($author$project$Page$Checkout$viewPrice, amount, price, 1)
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$author$project$Page$Checkout$GotSessionMsg(
									A3($author$project$Session$EnteredAmount, minimal, source, '0')))
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$trash)
							]))
					]))
			]));
};
var $author$project$Page$Checkout$viewCart = function (user) {
	return $author$project$SE$UI$Card$toHtml(
		$author$project$SE$UI$Card$withBoxShadow(
			$author$project$SE$UI$Card$content(
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$div(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$important(
												A2($rtfeldman$elm_css$Css$property, 'margin', 'calc((0.88888888rem + 1px)*-1) calc(-0.88888888rem)')),
												$rtfeldman$elm_css$Css$important(
												A2($rtfeldman$elm_css$Css$property, 'max-width', 'calc(100% + 0.88888888rem + 0.88888888rem)')),
												$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
												$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$auto),
												$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$hidden)
											]))
									]))
							]),
						_List_Nil,
						_List_fromArray(
							[
								$author$project$SE$UI$Table$V2$toHtml(
								A2(
									$author$project$SE$UI$Table$V2$withFoot,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$td,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$colspan(6)
												]),
											_List_fromArray(
												[
													A3(
													$author$project$SE$UI$Buttons$button,
													_List_fromArray(
														[$author$project$SE$UI$Buttons$Text]),
													$elm$core$Maybe$Just(
														$author$project$Page$Checkout$GotSessionMsg($author$project$Session$ClearedCart)),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Töm varukorgen')
														]))
												]))
										]),
									A2(
										$author$project$SE$UI$Table$V2$withHead,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Produkt')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Antal och pris')
													])),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$important(
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right))
													]),
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Styckpris')
													])),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$important(
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right))
													]),
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Totalpris')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('')
													]))
											]),
										A2(
											$author$project$SE$UI$Table$V2$withModifiers,
											_List_fromArray(
												[0]),
											A2(
												$author$project$SE$UI$Table$V2$body,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$Global$children(
																_List_fromArray(
																	[
																		A2(
																		$rtfeldman$elm_css$Css$Global$selector,
																		'tbody td',
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
																			]))
																	]))
															]))
													]),
												A2(
													$elm$core$List$map,
													$author$project$Page$Checkout$viewCartRow,
													$author$project$Cart$rows(user.gT)))))))
							]))
					]))));
};
var $author$project$Page$Checkout$checkoutStyles = function (isLoading) {
	return isLoading ? _List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$before(
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$property, 'content', '\'\''),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
					$rtfeldman$elm_css$Css$zIndex(
					$rtfeldman$elm_css$Css$int(999)),
					$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$backgroundColor(
					A4($rtfeldman$elm_css$Css$rgba, 255, 255, 255, 0.5))
				]))
		]) : _List_Nil;
};
var $author$project$Address$metadata = function (address) {
	switch (address.$) {
		case 0:
			var metadata_ = address.a;
			return metadata_;
		case 1:
			var metadata_ = address.a;
			return metadata_;
		default:
			var delivery = address.a;
			if (!delivery.$) {
				var metadata_ = delivery.c;
				return metadata_;
			} else {
				var metadata_ = delivery.a;
				return metadata_;
			}
	}
};
var $author$project$SE$UI$Title$title3 = A2(
	$author$project$SE$UI$Title$internalTitle,
	$rtfeldman$elm_css$Html$Styled$h3,
	_List_fromArray(
		[
			$author$project$SE$UI$Font$titleSizeEm(4)
		]));
var $author$project$Page$Checkout$viewElectronicBilling = function (_v0) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[$author$project$SE$UI$Utils$block]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('Fakturan skickas som '),
				A2(
				$rtfeldman$elm_css$Html$Styled$strong,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('E-faktura')
					]))
			]));
};
var $author$project$Page$Checkout$viewEmailBilling = function (email) {
	return A2(
		$author$project$SE$UI$Form$field,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Form$label('Fakturan skickas till'),
				A2(
				$author$project$SE$UI$Form$control,
				false,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto: ' + email)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(email)
							]))
					]))
			]));
};
var $author$project$Page$Checkout$viewPostalBilling = function (metadata) {
	return A2(
		$author$project$SE$UI$Form$field,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Form$label('Fakturan skickas till'),
				A2(
				$author$project$SE$UI$Form$control,
				false,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						A2(
							$elm$core$List$intersperse,
							A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
							A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Html$Styled$text,
								A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
									_List_fromArray(
										[
											metadata.ip,
											metadata.iv,
											metadata.iw,
											metadata.ix,
											metadata.iy,
											$author$project$Country$name(metadata.g_)
										])))))
					]))
			]));
};
var $author$project$Page$Checkout$viewBilling = function (billing) {
	switch (billing.$) {
		case 0:
			var gln = billing.a;
			return $author$project$Page$Checkout$viewElectronicBilling(gln);
		case 1:
			var email = billing.a;
			return $author$project$Page$Checkout$viewEmailBilling(email);
		case 2:
			var name = billing.a;
			var subBilling = billing.b;
			return A2($author$project$Page$Checkout$viewInvoiceCompanyBilling, name, subBilling);
		case 3:
			var subBilling = billing.a;
			return $author$project$Page$Checkout$viewProFormaBilling(subBilling);
		default:
			var address = billing.a;
			return $author$project$Page$Checkout$viewPostalBilling(
				$author$project$Address$metadata(
					$author$project$Address$IsPostal(address)));
	}
};
var $author$project$Page$Checkout$viewInvoiceCompanyBilling = F2(
	function (name, subBilling) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(1.5))
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Fakturan skickas till ett annat bolag: '),
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(name)
								]))
						])),
					$author$project$Page$Checkout$viewBilling(subBilling)
				]));
	});
var $author$project$Page$Checkout$viewProFormaBilling = function (subBilling) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$h3(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
							]))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Title$title3('Förskottsfaktura'),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$rem(1.5))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Efter att du lagt ordern skickas en förskottsfaktura till nedanstående adress. Vi skickar din beställning när fakturan är betald. För att skynda på hanteringen kan du skicka en kvittens på betalningen till '),
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:info@specialelektronik.se')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('info@specialelektronik.se')
							])),
						$rtfeldman$elm_css$Html$Styled$text('.')
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$hr,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$margin2,
						$rtfeldman$elm_css$Css$rem(1.5),
						$rtfeldman$elm_css$Css$rem(-0.88888888))
					]),
				_List_Nil,
				_List_Nil),
				$author$project$Page$Checkout$viewBilling(subBilling)
			]));
};
var $author$project$Page$Checkout$ClickedCustomDeliveryAddress = {$: 5};
var $author$project$Page$Checkout$GotFormMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$SE$UI$Form$labelRequired = $author$project$SE$UI$Form$labelHelper(true);
var $author$project$Page$Checkout$EnteredReference = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Checkout$EnteredRow1 = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Checkout$EnteredRow2 = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Checkout$EnteredRow3 = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Checkout$SelectedCountry = function (a) {
	return {$: 11, a: a};
};
var $author$project$SE$UI$Form$Input$Option = F2(
	function (label, value) {
		return {cB: label, iY: value};
	});
var $author$project$Page$Checkout$viewErrorHelp = function (text_) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$rtfeldman$elm_css$Css$marginTop(
				$rtfeldman$elm_css$Css$rem(0.25)),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(text_)
			]));
};
var $author$project$Page$Checkout$viewCountriesFormField = F3(
	function (field, msg, countries) {
		var options = A2(
			$elm$core$List$map,
			function (c) {
				return A2(
					$author$project$SE$UI$Form$Input$Option,
					$author$project$Country$name(c),
					$author$project$Country$codeToString(
						$author$project$Country$code(c)));
			},
			countries);
		var _v0 = function () {
			var _v1 = field.hd;
			if (!_v1.$) {
				return _Utils_Tuple2(_List_Nil, _List_Nil);
			} else {
				var errors_ = _v1.a;
				return _Utils_Tuple2(
					errors_,
					_List_fromArray(
						[$author$project$SE$UI$Form$Input$Danger]));
			}
		}();
		var errors = _v0.a;
		var mods = _v0.b;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Form$label(field.cB + '*'),
					A2(
					$author$project$SE$UI$Form$control,
					false,
					A2(
						$elm$core$List$cons,
						$author$project$SE$UI$Form$Input$toHtml(
							A2(
								$author$project$SE$UI$Form$Input$withModifiers,
								mods,
								A3(
									$author$project$SE$UI$Form$Input$select,
									A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotFormMsg, msg),
									options,
									field.iY))),
						A2($elm$core$List$map, $author$project$Page$Checkout$viewErrorHelp, errors)))
				]));
	});
var $author$project$Page$Checkout$viewFormFieldHelper = F3(
	function (required, field, msg) {
		var label = required ? $author$project$SE$UI$Form$labelRequired : $author$project$SE$UI$Form$label;
		var _v0 = function () {
			var _v1 = field.hd;
			if (!_v1.$) {
				return _Utils_Tuple2(_List_Nil, _List_Nil);
			} else {
				var errors_ = _v1.a;
				return _Utils_Tuple2(
					errors_,
					_List_fromArray(
						[$author$project$SE$UI$Form$Input$Danger]));
			}
		}();
		var errors = _v0.a;
		var mods = _v0.b;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					label(field.cB),
					A2(
					$author$project$SE$UI$Form$control,
					false,
					A2(
						$elm$core$List$cons,
						$author$project$SE$UI$Form$Input$toHtml(
							A2(
								$author$project$SE$UI$Form$Input$withTrigger,
								0,
								A2(
									$author$project$SE$UI$Form$Input$withModifiers,
									mods,
									A2(
										$author$project$SE$UI$Form$Input$text,
										A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotFormMsg, msg),
										field.iY)))),
						A2($elm$core$List$map, $author$project$Page$Checkout$viewErrorHelp, errors)))
				]));
	});
var $author$project$Page$Checkout$viewFormField = $author$project$Page$Checkout$viewFormFieldHelper(false);
var $author$project$Page$Checkout$viewRequiredFormField = $author$project$Page$Checkout$viewFormFieldHelper(true);
var $author$project$Page$Checkout$viewCustomDeliveryForm = F2(
	function (countries, _v0) {
		var row1 = _v0.iv;
		var row2 = _v0.iw;
		var row3 = _v0.ix;
		var reference = _v0.ip;
		var country = _v0.g_;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Page$Checkout$viewRequiredFormField, row1, $author$project$Page$Checkout$EnteredRow1),
					A2($author$project$Page$Checkout$viewRequiredFormField, row2, $author$project$Page$Checkout$EnteredRow2),
					A2($author$project$Page$Checkout$viewRequiredFormField, row3, $author$project$Page$Checkout$EnteredRow3),
					A2($author$project$Page$Checkout$viewFormField, reference, $author$project$Page$Checkout$EnteredReference),
					A3($author$project$Page$Checkout$viewCountriesFormField, country, $author$project$Page$Checkout$SelectedCountry, countries)
				]));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$target = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var $author$project$Page$Checkout$viewPickup = A2(
	$author$project$SE$UI$Content$content,
	_List_Nil,
	_List_fromArray(
		[
			A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('När du fått ett meddelande om att ordern är plockad, hämtar du ordern på:')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Granlidsvägen 85'),
					A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
					$rtfeldman$elm_css$Html$Styled$text('653 51 Karlstad'),
					A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href('https://goo.gl/maps/5hDJU7rhUsfQonre7'),
							$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Hitta hit')
						]))
				]))
		]));
var $author$project$Page$Checkout$SelectedDeliveryAddress = function (a) {
	return {$: 4, a: a};
};
var $author$project$Address$nameToString = function (_v0) {
	var name_ = _v0;
	return name_;
};
var $author$project$Page$Checkout$addressToOption = function (address) {
	if (!address.$) {
		var id = address.a;
		var name = address.b;
		var metadata = address.c;
		return $elm$core$Maybe$Just(
			{
				cB: A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							$author$project$Address$nameToString(name),
							metadata.iv,
							metadata.iw,
							metadata.ix,
							metadata.iy,
							metadata.ip,
							$author$project$Country$name(metadata.g_)
						])),
				iY: $elm$core$String$fromInt(
					$author$project$Id$toInt(id))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Checkout$addressesToOptions = function (addresses) {
	return A2($elm$core$List$filterMap, $author$project$Page$Checkout$addressToOption, addresses);
};
var $author$project$Page$Checkout$viewDeliveryAddress = F2(
	function (addresses, currentId) {
		var maybeAddress = A3(
			$elm$core$List$foldl,
			F2(
				function (adr, carry) {
					if (!adr.$) {
						var id = adr.a;
						return _Utils_eq(id, currentId) ? $elm$core$Maybe$Just(adr) : carry;
					} else {
						return carry;
					}
				}),
			$elm$core$Maybe$Nothing,
			addresses);
		if (maybeAddress.$ === 1) {
			return $rtfeldman$elm_css$Html$Styled$text('');
		} else {
			if (maybeAddress.a.$ === 1) {
				return $rtfeldman$elm_css$Html$Styled$text('');
			} else {
				var _v1 = maybeAddress.a;
				var name = _v1.b;
				var metadata = _v1.c;
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Ordern levereras till:')
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Address$nameToString(name)),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									$rtfeldman$elm_css$Html$Styled$text(metadata.iv),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									$rtfeldman$elm_css$Html$Styled$text(metadata.iw),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									$rtfeldman$elm_css$Html$Styled$text(metadata.ix),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									$rtfeldman$elm_css$Html$Styled$text(metadata.iy),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									$rtfeldman$elm_css$Html$Styled$text(metadata.ip),
									A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Country$name(metadata.g_))
								]))
						]));
			}
		}
	});
var $author$project$Page$Checkout$viewSelectDeliveryAddress = F3(
	function (mods, addresses, maybeSelectedId) {
		var id = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				A2($elm$core$Basics$composeR, $author$project$Id$toInt, $elm$core$String$fromInt),
				maybeSelectedId));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Form$field,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Form$Input$toHtml(
							A2(
								$author$project$SE$UI$Form$Input$withModifiers,
								mods,
								$author$project$SE$UI$Form$Input$withRequired(
									A3(
										$author$project$SE$UI$Form$Input$select,
										$author$project$Page$Checkout$SelectedDeliveryAddress,
										$author$project$Page$Checkout$addressesToOptions(addresses),
										id))))
						])),
					A2(
					$author$project$Helpers$viewMaybe,
					$author$project$Page$Checkout$viewDeliveryAddress(addresses),
					maybeSelectedId)
				]));
	});
var $author$project$Page$Checkout$viewDeliveryForm = F4(
	function (countries, addresses, formData, showErrors) {
		var _v0 = formData.I;
		switch (_v0.$) {
			case 0:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Page$Checkout$viewSelectDeliveryAddress,
							showErrors ? _List_fromArray(
								[$author$project$SE$UI$Form$Input$Danger]) : _List_Nil,
							addresses,
							$elm$core$Maybe$Nothing),
							A2(
							$author$project$Helpers$viewIf,
							showErrors,
							$author$project$Page$Checkout$viewErrorHelp('Leveransadress måste väljas'))
						]));
			case 1:
				var id = _v0.a;
				return A3(
					$author$project$Page$Checkout$viewSelectDeliveryAddress,
					_List_Nil,
					addresses,
					$elm$core$Maybe$Just(id));
			case 3:
				return A2($author$project$Page$Checkout$viewCustomDeliveryForm, countries, formData);
			default:
				return $author$project$Page$Checkout$viewPickup;
		}
	});
var $author$project$SE$UI$Card$withSubTitle = F2(
	function (subTitle, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{c4: subTitle});
	});
var $author$project$Page$Checkout$viewDeliveryCard = F3(
	function (form, countries, company) {
		var _v0 = function () {
			switch (form.$) {
				case 0:
					var data = form.a;
					return _Utils_Tuple2(true, data);
				case 1:
					var data = form.a;
					return _Utils_Tuple2(false, data);
				case 2:
					var data = form.a;
					return _Utils_Tuple2(true, data);
				default:
					var data = form.a;
					return _Utils_Tuple2(false, data);
			}
		}();
		var showErrors = _v0.a;
		var formData = _v0.b;
		return $author$project$SE$UI$Card$toHtml(
			$author$project$SE$UI$Card$withBoxShadow(
				A2(
					$author$project$SE$UI$Card$withSubTitle,
					'Välj till vilken adress ordern ska skickas',
					A2(
						$author$project$SE$UI$Card$withTitle,
						'Leveransinformation',
						$author$project$SE$UI$Card$content(
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$field,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$labelRequired('Leveransadress'),
											A2(
											$author$project$SE$UI$Form$control,
											false,
											_List_fromArray(
												[
													$author$project$SE$UI$Form$Input$toHtml(
													A3(
														$author$project$SE$UI$Form$Input$radio,
														$author$project$Page$Checkout$GotFormMsg($author$project$Page$Checkout$ClickedUnselectDeliveryAddress),
														'Mina leveransadresser',
														function () {
															var _v2 = formData.I;
															switch (_v2.$) {
																case 0:
																	return true;
																case 1:
																	return true;
																default:
																	return false;
															}
														}())),
													$author$project$SE$UI$Form$Input$toHtml(
													A3(
														$author$project$SE$UI$Form$Input$radio,
														$author$project$Page$Checkout$GotFormMsg($author$project$Page$Checkout$ClickedCustomDeliveryAddress),
														'Lägg till adress manuellt',
														_Utils_eq(formData.I, $author$project$Page$Checkout$Custom))),
													$author$project$SE$UI$Form$Input$toHtml(
													A3(
														$author$project$SE$UI$Form$Input$radio,
														$author$project$Page$Checkout$GotFormMsg($author$project$Page$Checkout$ClickedPickupDeliveryAddress),
														'Hämta på Special-Elektronik i Karlstad',
														_Utils_eq(formData.I, $author$project$Page$Checkout$Pickup)))
												]))
										])),
									A4($author$project$Page$Checkout$viewDeliveryForm, countries, company.g9, formData, showErrors)
								]))))));
	});
var $author$project$Page$Checkout$EnteredMessage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Checkout$viewMessageForm = function (formData) {
	var warningText = ($elm$core$String$length(formData.fx.iY) > 0) ? 'När du skickar med ett meddelande hanteras din order manuellt vilket kan innebära att det tar lite längre tid innan du får en orderbekräftelse.' : '';
	return $author$project$SE$UI$Card$toHtml(
		$author$project$SE$UI$Card$withBoxShadow(
			A2(
				$author$project$SE$UI$Card$withSubTitle,
				'Här kan du lämna ett meddelande till oss Special-Elektronik',
				A2(
					$author$project$SE$UI$Card$withTitle,
					'Meddelande',
					$author$project$SE$UI$Card$content(
						_List_fromArray(
							[
								A2($author$project$Page$Checkout$viewFormField, formData.fx, $author$project$Page$Checkout$EnteredMessage),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(warningText)
									]))
							]))))));
};
var $author$project$Page$Checkout$EnteredGoodsLabel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Checkout$EnteredYourPurchaseOrderNumber = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Checkout$viewOrderDetailsForm = function (formData) {
	return $author$project$SE$UI$Card$toHtml(
		$author$project$SE$UI$Card$withBoxShadow(
			A2(
				$author$project$SE$UI$Card$withSubTitle,
				'Här kan du märka ordern med ert eget ordernummer samt godsmärke',
				A2(
					$author$project$SE$UI$Card$withTitle,
					'Märkning på order',
					$author$project$SE$UI$Card$content(
						_List_fromArray(
							[
								$author$project$SE$UI$Columns$columns(
								_List_fromArray(
									[
										$author$project$SE$UI$Columns$defaultColumn(
										_List_fromArray(
											[
												A2($author$project$Page$Checkout$viewFormField, formData.ag, $author$project$Page$Checkout$EnteredYourPurchaseOrderNumber)
											])),
										$author$project$SE$UI$Columns$defaultColumn(
										_List_fromArray(
											[
												A2($author$project$Page$Checkout$viewFormField, formData.aa, $author$project$Page$Checkout$EnteredGoodsLabel)
											]))
									]))
							]))))));
};
var $author$project$Page$Checkout$ClickedSend = {$: 6};
var $author$project$SE$UI$Buttons$Fullwidth = {$: 3};
var $author$project$SE$UI$Control$Large = 3;
var $author$project$SE$UI$Buttons$Size = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Checkout$viewSubmitButton = F2(
	function (isLoading, hasError) {
		var mods = isLoading ? _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Loading,
				$author$project$SE$UI$Buttons$Fullwidth,
				$author$project$SE$UI$Buttons$Size(3)
			]) : _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Fullwidth,
				$author$project$SE$UI$Buttons$Size(3)
			]);
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					hasError ? A3(
					$author$project$SE$UI$Buttons$button,
					A2(
						$elm$core$List$cons,
						$author$project$SE$UI$Buttons$Color(12),
						mods),
					$elm$core$Maybe$Nothing,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Du behöver rätta felen innan du kan skicka ordern')
						])) : A3(
					$author$project$SE$UI$Buttons$button,
					A2(
						$elm$core$List$cons,
						$author$project$SE$UI$Buttons$Color(9),
						mods),
					$elm$core$Maybe$Just($author$project$Page$Checkout$ClickedSend),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Skicka order')
						]))
				]));
	});
var $author$project$SE$UI$Colors$Black = 8;
var $author$project$SE$UI$Notification$custom = function (color) {
	return $author$project$SE$UI$Notification$internalNotification(
		$author$project$SE$UI$Colors$toHsla(color));
};
var $rtfeldman$elm_css$Html$Styled$h2 = $rtfeldman$elm_css$Html$Styled$node('h2');
var $author$project$Page$Checkout$viewWarning = A3(
	$author$project$SE$UI$Notification$custom,
	8,
	$elm$core$Maybe$Nothing,
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$h2,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Varning')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Din order kommer att hanteras manuellt och kan ta lite längre tid. Det kan bero på obetalda fakturor eller en förändrad kreditvärdighet.')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Kontakta '),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:info@specialelektronik.se')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('info@specialelektronik.se')
						])),
					$rtfeldman$elm_css$Html$Styled$text(' för mer information.')
				]))
		]));
var $author$project$Page$Checkout$viewCheckout = F3(
	function (cartIsOrderable, form, _v0) {
		var countries = _v0.a;
		var company = _v0.b;
		var _v1 = function () {
			switch (form.$) {
				case 0:
					var data = form.a;
					return _Utils_Tuple2(false, data);
				case 1:
					var data = form.a;
					return _Utils_Tuple2(false, data);
				case 2:
					var data = form.a;
					return _Utils_Tuple2(false, data);
				default:
					var data = form.a;
					return _Utils_Tuple2(true, data);
			}
		}();
		var isLoading = _v1.a;
		var formData = _v1.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Page$Checkout$checkoutStyles(isLoading),
			_List_Nil,
			_List_fromArray(
				[
					A3($author$project$Page$Checkout$viewDeliveryCard, form, countries, company),
					$author$project$SE$UI$Card$toHtml(
					$author$project$SE$UI$Card$withBoxShadow(
						A2(
							$author$project$SE$UI$Card$withTitle,
							'Fakturamottagare',
							$author$project$SE$UI$Card$content(
								_List_fromArray(
									[
										$author$project$Page$Checkout$viewBilling(company.gR),
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$author$project$SE$UI$Font$bodySizeRem(-1),
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Om du behöver byta fakturaadress, kontakta '),
												A2(
												$rtfeldman$elm_css$Html$Styled$a,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:info@specialelektronik.se')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('info@specialelektronik.se')
													])),
												$rtfeldman$elm_css$Html$Styled$text('.')
											]))
									]))))),
					$author$project$Page$Checkout$viewOrderDetailsForm(formData),
					$author$project$Page$Checkout$viewMessageForm(formData),
					A2($author$project$Helpers$viewIf, company.gh === 1, $author$project$Page$Checkout$viewWarning),
					A2(
					$author$project$Page$Checkout$viewSubmitButton,
					isLoading,
					$author$project$Page$Checkout$hasFormErrors(formData) || (!cartIsOrderable))
				]));
	});
var $rtfeldman$elm_css$Css$Global$button = $rtfeldman$elm_css$Css$Global$typeSelector('button');
var $rtfeldman$elm_css$Css$end = $rtfeldman$elm_css$Css$prop1('end');
var $rtfeldman$elm_css$Css$Structure$IdSelector = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Global$id = F2(
	function (str, styles) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
			styles,
			$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Structure$IdSelector(str)
					])));
	});
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $specialelektronik$elm_autocomplete$Autocomplete$GotFocus = {$: 1};
var $specialelektronik$elm_autocomplete$Autocomplete$GotQuery = function (a) {
	return {$: 0, a: a};
};
var $specialelektronik$elm_autocomplete$Autocomplete$Down = 1;
var $specialelektronik$elm_autocomplete$Autocomplete$GotArrowKey = function (a) {
	return {$: 5, a: a};
};
var $specialelektronik$elm_autocomplete$Autocomplete$Submitted = {$: 3};
var $specialelektronik$elm_autocomplete$Autocomplete$Up = 0;
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $specialelektronik$elm_autocomplete$Autocomplete$handleEnterOrArrowUpOrDown = function () {
	var isEnter = function (code) {
		switch (code) {
			case 13:
				return $elm$json$Json$Decode$succeed($specialelektronik$elm_autocomplete$Autocomplete$Submitted);
			case 38:
				return $elm$json$Json$Decode$succeed(
					$specialelektronik$elm_autocomplete$Autocomplete$GotArrowKey(0));
			case 40:
				return $elm$json$Json$Decode$succeed(
					$specialelektronik$elm_autocomplete$Autocomplete$GotArrowKey(1));
			default:
				return $elm$json$Json$Decode$fail('not ENTER');
		}
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
}();
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $specialelektronik$elm_autocomplete$Autocomplete$NoOp = {$: 6};
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $specialelektronik$elm_autocomplete$Autocomplete$mapNeverToMsg = function (msg) {
	return A2(
		$elm$html$Html$Attributes$map,
		function (_v0) {
			return $specialelektronik$elm_autocomplete$Autocomplete$NoOp;
		},
		msg);
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $specialelektronik$elm_autocomplete$Autocomplete$input = F3(
	function (config, attrs, _v0) {
		var query = _v0.im;
		return A2(
			$elm$html$Html$map,
			config.gu,
			A2(
				$elm$html$Html$input,
				_Utils_ap(
					A2($elm$core$List$map, $specialelektronik$elm_autocomplete$Autocomplete$mapNeverToMsg, attrs),
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput($specialelektronik$elm_autocomplete$Autocomplete$GotQuery),
							$elm$html$Html$Events$onFocus($specialelektronik$elm_autocomplete$Autocomplete$GotFocus),
							$specialelektronik$elm_autocomplete$Autocomplete$handleEnterOrArrowUpOrDown,
							$elm$html$Html$Attributes$value(query)
						])),
				_List_Nil));
	});
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Page$Checkout$placeholder = function (c) {
	return $rtfeldman$elm_css$Css$batch(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$pseudoElement, 'placeholder', c),
				A2($rtfeldman$elm_css$Css$pseudoElement, '-webkit-input-placeholder', c),
				A2($rtfeldman$elm_css$Css$pseudoElement, '-ms-input-placeholder', c)
			]));
};
var $author$project$Page$Checkout$quickAddStyle = _List_fromArray(
	[
		$author$project$SE$UI$Control$controlStyle(0),
		$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$darker),
		$rtfeldman$elm_css$Css$maxWidth(
		$rtfeldman$elm_css$Css$pct(100)),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		$author$project$Page$Checkout$placeholder(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$color(
				A4($rtfeldman$elm_css$Css$rgba, 96, 111, 123, 0.3))
			])),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border))
			])),
		$rtfeldman$elm_css$Css$focus(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$link),
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 0 0 0.125em rgba(50,115,220, 0.25)')
			])),
		$rtfeldman$elm_css$Css$active(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$link),
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 0 0 0.125em rgba(50,115,220, 0.25)')
			]))
	]);
var $author$project$Page$Checkout$AddedQuickAddToCart = {$: 3};
var $author$project$Page$Checkout$EnteredQuickAddAmount = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Helpers$onEnter = function (msg) {
	var isEnter = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $rtfeldman$elm_css$Html$Styled$Events$keyCode));
};
var $author$project$Page$Checkout$viewQuickAddAmount = function (amount) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$input,
		$author$project$Page$Checkout$quickAddStyle,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('number'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('quick-add-amount'),
				$author$project$SE$UI$Utils$onChange(
				A2($elm$core$Basics$composeL, $author$project$Page$Checkout$GotQuickAddMsg, $author$project$Page$Checkout$EnteredQuickAddAmount)),
				$author$project$Helpers$onEnter(
				$author$project$Page$Checkout$GotQuickAddMsg($author$project$Page$Checkout$AddedQuickAddToCart)),
				$rtfeldman$elm_css$Html$Styled$Attributes$value(
				$elm$core$String$fromFloat(amount)),
				$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Antal'),
				$rtfeldman$elm_css$Html$Styled$Attributes$step('1'),
				$rtfeldman$elm_css$Html$Styled$Attributes$min('1'),
				$rtfeldman$elm_css$Html$Styled$Attributes$max('10000')
			]),
		_List_Nil);
};
var $author$project$Page$Checkout$viewQuickAddButton = A3(
	$author$project$SE$UI$Buttons$button,
	_List_fromArray(
		[
			$author$project$SE$UI$Buttons$Color(11),
			$author$project$SE$UI$Buttons$Fullwidth
		]),
	$elm$core$Maybe$Just(
		$author$project$Page$Checkout$GotQuickAddMsg($author$project$Page$Checkout$AddedQuickAddToCart)),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('Lägg i varukorg')
		]));
var $author$project$Page$Checkout$suggestionsStyles = function (isEmpty) {
	return _List_fromArray(
		[
			isEmpty ? $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none) : $rtfeldman$elm_css$Css$batch(_List_Nil),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(99999)),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'li:last-child',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$borderBottomLeftRadius(
							$rtfeldman$elm_css$Css$px(5)),
							$rtfeldman$elm_css$Css$borderBottomRightRadius(
							$rtfeldman$elm_css$Css$px(5)),
							A4(
							$rtfeldman$elm_css$Css$boxShadow4,
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$px(2),
							$rtfeldman$elm_css$Css$px(3),
							A4($rtfeldman$elm_css$Css$rgba, 10, 10, 10, 0.1))
						]))
				]))
		]);
};
var $rtfeldman$elm_css$Css$copy = {a: 0, iY: 'copy'};
var $rtfeldman$elm_css$Css$ellipsis = {go: 0, iY: 'ellipsis'};
var $rtfeldman$elm_css$Css$borderRight3 = $rtfeldman$elm_css$Css$prop3('border-right');
var $author$project$Page$Checkout$suggestionStyles = function (isSelected) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
			$author$project$SE$UI$Colors$backgroundColor(
			isSelected ? $author$project$SE$UI$Colors$lightest : $author$project$SE$UI$Colors$white),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$px(16)),
			A3(
			$rtfeldman$elm_css$Css$borderLeft3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
			A3(
			$rtfeldman$elm_css$Css$borderRight3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightest)
				]))
		]);
};
var $rtfeldman$elm_css$Css$textOverflow = $rtfeldman$elm_css$Css$prop1('text-overflow');
var $author$project$Page$Checkout$viewQuickAddSuggestion = F3(
	function (maybeSelected, index, product) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$li,
			$author$project$Page$Checkout$suggestionStyles(
				_Utils_eq(
					A2($elm$core$Maybe$withDefault, -1, maybeSelected),
					index)),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$copy),
							$rtfeldman$elm_css$Css$padding(
							$rtfeldman$elm_css$Css$rem(0.75)),
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Page$Checkout$GotQuickAddMsg(
								$author$project$Page$Checkout$ChoseQuickAddProduct(product)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$ExternalId$toString(
								$author$project$Product$externalId(product)))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flexShrink(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$padding(
							$rtfeldman$elm_css$Css$rem(0.75)),
							$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
							$rtfeldman$elm_css$Css$textOverflow($rtfeldman$elm_css$Css$ellipsis),
							$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap)
						]),
					_List_fromArray(
						[
							$author$project$Route$href(
							A2(
								$author$project$Route$Product,
								$author$project$QueryParams$empty,
								_Utils_Tuple2(
									$author$project$Product$id(product),
									$author$project$Product$slug(product))))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Product$metadata(product).hT)
						]))
				]));
	});
var $author$project$Page$Checkout$viewQuickAddSuggestions = F2(
	function (maybeSelected, products) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$ul,
			$author$project$Page$Checkout$suggestionsStyles(
				$elm$core$List$isEmpty(products)),
			_List_Nil,
			A2(
				$elm$core$List$indexedMap,
				$author$project$Page$Checkout$viewQuickAddSuggestion(maybeSelected),
				products));
	});
var $author$project$Page$Checkout$viewQuickAdd = F4(
	function (userId, autocomplete, amount, error) {
		var _v0 = $specialelektronik$elm_autocomplete$Autocomplete$toView(autocomplete);
		var suggestions = _v0.iM;
		var index = _v0.hA;
		return $author$project$SE$UI$Card$toHtml(
			$author$project$SE$UI$Card$withBoxShadow(
				A2(
					$author$project$SE$UI$Card$withSubTitle,
					'Lägg till produkter via artikelkod',
					A2(
						$author$project$SE$UI$Card$withTitle,
						'Snabbkassa',
						$author$project$SE$UI$Card$content(
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
											A2($rtfeldman$elm_css$Css$property, 'gap', '0.75rem'),
											A2($rtfeldman$elm_css$Css$property, 'grid', 'auto auto / 1fr auto'),
											$rtfeldman$elm_css$Css$Global$children(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$Global$button(
													_List_fromArray(
														[
															A2($rtfeldman$elm_css$Css$property, 'grid-column', '1 / 3')
														]))
												])),
											$author$project$SE$UI$Utils$widescreen(
											_List_fromArray(
												[
													A2($rtfeldman$elm_css$Css$property, 'grid', 'auto / 1fr auto auto'),
													$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$end),
													$rtfeldman$elm_css$Css$Global$children(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$Global$button(
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Css$important(
																	A2($rtfeldman$elm_css$Css$property, 'grid-column', 'unset'))
																]))
														]))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('grid')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_Nil,
											_List_fromArray(
												[
													$author$project$SE$UI$Form$label('Artikelkod'),
													A4(
													$rtfeldman$elm_css$Html$Styled$styled,
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$Global$children(
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Css$Global$id, 'quick-add-product-code', $author$project$Page$Checkout$quickAddStyle)
																]))
														]),
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$fromUnstyled(
															A3(
																$specialelektronik$elm_autocomplete$Autocomplete$input,
																$author$project$Page$Checkout$autocompleteConfig(userId),
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$id('quick-add-product-code'),
																		$elm$html$Html$Attributes$placeholder('Artikelkod')
																	]),
																autocomplete))
														])),
													A2($author$project$Page$Checkout$viewQuickAddSuggestions, index, suggestions)
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_Nil,
											_List_fromArray(
												[
													$author$project$SE$UI$Form$label('Antal'),
													$author$project$Page$Checkout$viewQuickAddAmount(amount)
												])),
											$author$project$Page$Checkout$viewQuickAddButton
										])),
									A2(
									$author$project$Helpers$viewIf,
									error !== '',
									A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(error)
											])))
								]))))));
	});
var $author$project$Page$Checkout$viewEmptyCart = F4(
	function (userId, quickAddAutocomplete, quickAddAmount, quickAddError) {
		return A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
									$author$project$SE$UI$Utils$block
								]),
							_List_Nil,
							_List_fromArray(
								[
									$author$project$SE$UI$Title$title1('Tom varukorg'),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[$author$project$SE$UI$Utils$block]),
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Utan produkter är det ingen idé att lägga någon order.')
										])),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[$author$project$SE$UI$Utils$block]),
									_List_Nil,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$strong,
											_List_fromArray(
												[
													$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
												]),
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('TIPS! ')
												])),
											$rtfeldman$elm_css$Html$Styled$text('Om du kan artikelnumret så kan du snabbt beställa direkt här i vår snabbkassa.')
										]))
								])),
							A5($rtfeldman$elm_css$Html$Styled$Lazy$lazy4, $author$project$Page$Checkout$viewQuickAdd, userId, quickAddAutocomplete, quickAddAmount, quickAddError)
						]))
				]));
	});
var $author$project$Page$Checkout$viewGuestCheckout = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('This should never have happened'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('As a guest, you should never end up on this page. Please contact us so that we can make sure others won\'t be stuck at this dead end as well.')
									]))
							]))
					]))
			])),
	gr: 'Wow, how did you end up here?'
};
var $rtfeldman$elm_css$Css$sticky = {b1: 0, iY: 'sticky'};
var $author$project$Cart$sumChemicalTax = function (_v0) {
	var rs = _v0;
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v1, tot) {
				var r = _v1;
				return tot + (r.dq * r.cj);
			}),
		0,
		rs);
};
var $author$project$Cart$sumProducts = function (_v0) {
	var rs = _v0;
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v1, tot) {
				var r = _v1;
				return tot + (r.cR * r.cj);
			}),
		0,
		rs);
};
var $author$project$Page$Checkout$viewSummary = function (user) {
	var sumProducts = $author$project$Cart$sumProducts(user.gT);
	var sumChemicalTax = $author$project$Cart$sumChemicalTax(user.gT);
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$black),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
				A2(
				$rtfeldman$elm_css$Css$padding2,
				$rtfeldman$elm_css$Css$em(1.25),
				$rtfeldman$elm_css$Css$em(1.5)),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$sticky),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$rem(1.5))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Title$title5('Sammanställning'),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
							]),
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Summa produkter')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Helpers$formatToMoney(sumProducts))
									]))
							])),
						A2(
						$author$project$Helpers$viewIf,
						sumChemicalTax > 0,
						A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
								]),
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Kemikalieskatt')
										])),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$author$project$Helpers$formatToMoney(sumChemicalTax))
										]))
								]))),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
							]),
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('TOTALT')
											]))
									])),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												$author$project$Helpers$formatToMoney(sumProducts + sumChemicalTax))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Checkout$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$Checkout$viewGuestCheckout;
	} else {
		var user = _v0.a;
		return {
			eN: $author$project$Cart$isEmpty(user.gT) ? A4($author$project$Page$Checkout$viewEmptyCart, user.hx, model.V, model.aR, model.br) : A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$SE$UI$Title$title1('Varukorg'),
								$author$project$SE$UI$Columns$columns(
								_List_fromArray(
									[
										$author$project$SE$UI$Columns$defaultColumn(
										_List_fromArray(
											[
												A5($rtfeldman$elm_css$Html$Styled$Lazy$lazy4, $author$project$Page$Checkout$viewQuickAdd, user.hx, model.V, model.aR, model.br),
												$author$project$Page$Checkout$viewCart(user),
												A2(
												$author$project$Status$viewApi,
												A2(
													$author$project$Page$Checkout$viewCheckout,
													$author$project$Cart$isOrderable(user.gT),
													model.C),
												A3($author$project$Status$map2, $elm$core$Tuple$pair, model.cr, model.bL))
											])),
										A2(
										$author$project$SE$UI$Columns$column,
										_List_fromArray(
											[
												_Utils_Tuple2(4, 4)
											]),
										_List_fromArray(
											[
												$author$project$Page$Checkout$viewSummary(user)
											]))
									]))
							]))
					])),
			gr: 'Varukorg'
		};
	}
};
var $author$project$SE$UI$Container$Small = 1;
var $author$project$Page$Content$viewFailed = function (error) {
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_fromArray(
						[1]),
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title1('Sidan kunde inte laddas.'),
							A2(
							$author$project$SE$UI$Content$content,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Api$errorToHtml(error)
										]))
								]))
						]))
				])),
		gr: 'Sidan kunde inte laddas.'
	};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$download = function (fileName) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'download', fileName);
};
var $author$project$Page$Content$viewFile = function (file) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(file.cg),
						$rtfeldman$elm_css$Html$Styled$Attributes$download(file.hT)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(file.hT)
					]))
			]));
};
var $author$project$Page$Content$viewFiles = function (files) {
	return $elm$core$List$isEmpty(files) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
		$author$project$SE$UI$Content$content,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Filer')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_Nil,
				A2($elm$core$List$map, $author$project$Page$Content$viewFile, files))
			]));
};
var $author$project$Page$Content$viewLoaded = function (content) {
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_fromArray(
						[1]),
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title1(content.gr),
							content.bI,
							$author$project$Page$Content$viewFiles(content.hn)
						]))
				])),
		gr: content.gr
	};
};
var $author$project$Page$Content$viewLoading = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_fromArray(
					[1]),
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('Laddar...')
					]))
			])),
	gr: 'Laddar...'
};
var $author$project$Page$Content$view = function (model) {
	var _v0 = model.eN;
	switch (_v0.$) {
		case 0:
			return $author$project$Page$Content$viewLoading;
		case 1:
			var content = _v0.a;
			return $author$project$Page$Content$viewLoaded(content);
		case 2:
			var content = _v0.a;
			return $author$project$Page$Content$viewLoaded(content);
		default:
			var error = _v0.a;
			return $author$project$Page$Content$viewFailed(error);
	}
};
var $author$project$Breadcrumb$Home = {$: 0};
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp = F2(
	function (fn, arg) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			fn(arg));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy = F2(
	function (fn, arg) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A3($elm$virtual_dom$VirtualDom$lazy2, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp, fn, arg));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy = $rtfeldman$elm_css$VirtualDom$Styled$lazy;
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp2 = F3(
	function (fn, arg1, arg2) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			A2(fn, arg1, arg2));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy2 = F3(
	function (fn, arg1, arg2) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A4($elm$virtual_dom$VirtualDom$lazy3, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp2, fn, arg1, arg2));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy2 = $rtfeldman$elm_css$VirtualDom$Styled$lazy2;
var $author$project$SE$UI$Title$title2 = A2(
	$author$project$SE$UI$Title$internalTitle,
	$rtfeldman$elm_css$Html$Styled$h2,
	_List_fromArray(
		[
			$author$project$SE$UI$Font$titleSizeEm(5)
		]));
var $author$project$Image$viewCustom = $author$project$Image$viewSizeHelper;
var $author$project$Page$Home$viewCategory = function (cat) {
	var name = $author$project$Category$name(cat);
	var imageConfig = $author$project$SE$UI$Image$V2$create(
		{e7: 102, gE: 137});
	var image = A2(
		$elm$core$Maybe$withDefault,
		$rtfeldman$elm_css$Html$Styled$text(''),
		A2(
			$elm$core$Maybe$map,
			A3($author$project$Image$viewCustom, imageConfig, 0, name),
			$author$project$Category$image(cat)));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$rem(1)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				$author$project$SE$UI$Utils$tablet(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$unset),
						$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset)
					])),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$rem(1)),
				A2($rtfeldman$elm_css$Css$property, 'padding-right', 'calc(2rem + 137px)'),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
				$author$project$SE$UI$Font$bodySizeEm(-2),
				$rtfeldman$elm_css$Css$lineHeight(
				$rtfeldman$elm_css$Css$num(1.28571428571)),
				$rtfeldman$elm_css$Css$minHeight(
				$rtfeldman$elm_css$Css$px(102)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				A4(
				$rtfeldman$elm_css$Css$boxShadow4,
				$rtfeldman$elm_css$Css$zero,
				$rtfeldman$elm_css$Css$px(4),
				$rtfeldman$elm_css$Css$px(10),
				$author$project$SE$UI$Colors$toCss(
					A2(
						$author$project$SE$UI$Colors$mapAlpha,
						$elm$core$Basics$always(0.15),
						$author$project$SE$UI$Colors$black))),
				$rtfeldman$elm_css$Css$Transitions$transition(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Transitions$boxShadow(128)
					])),
				$rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
						A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 4px 10px hsla(0, 0%, 0%, 0.30), inset 0 -3px 0px 0px hsla(138, 100%, 31%, 1)')
					])),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'figure',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$px(-8)),
								$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero)
							]))
					]))
			]),
		_List_fromArray(
			[
				$author$project$Route$href(
				$author$project$Route$MainCategory(
					$author$project$Category$slug(cat)))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$strong,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(name)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$elm$core$Basics$never,
				$author$project$MD$fromString(
					$author$project$Category$description(cat))),
				image
			]));
};
var $author$project$Page$Home$viewCategories = function (categories) {
	var _v0 = $elm$core$List$length(categories);
	if (!_v0) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		return A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title2('Kategorier'),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$author$project$SE$UI$Utils$block,
									$author$project$SE$UI$Utils$tablet(
									_List_fromArray(
										[
											A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
											A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1rem'),
											A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fill, minmax(450px, 1fr))')
										]))
								]),
							_List_Nil,
							A2($elm$core$List$map, $author$project$Page$Home$viewCategory, categories))
						]))
				]));
	}
};
var $author$project$Constants$canonicalPartnerZoneName = 'Partnerzon - Special-Elektronik';
var $author$project$Page$Home$viewGuestAccount = F3(
	function (productsHtml, categoriesHtml, newsArticles) {
		return {
			eN: A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Section$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Home),
										$author$project$SE$UI$Columns$columns(
										_List_fromArray(
											[
												$author$project$SE$UI$Columns$defaultColumn(
												_List_fromArray(
													[
														$author$project$SE$UI$Title$title1('Välkommen till Special-Elektroniks Partnerzon.'),
														A2(
														$author$project$SE$UI$Content$content,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_Nil,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('Här kan du som partner lägga beställningar, se orderhistorik och fakturor.')
																	]))
															]))
													]))
											]))
									]))
							])),
						newsArticles,
						categoriesHtml,
						productsHtml
					])),
			gr: $author$project$Constants$canonicalPartnerZoneName
		};
	});
var $author$project$SE$UI$Font$textButtonStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
		$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
		$rtfeldman$elm_css$Css$fontWeight(
		$rtfeldman$elm_css$Css$int(600)),
		$author$project$SE$UI$Font$bodySizeEm(-2),
		$rtfeldman$elm_css$Css$letterSpacing(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$paddingBottom(
		$rtfeldman$elm_css$Css$px(2)),
		$rtfeldman$elm_css$Css$borderBottomWidth(
		$rtfeldman$elm_css$Css$px(2)),
		$rtfeldman$elm_css$Css$borderBottomStyle($rtfeldman$elm_css$Css$solid),
		$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$primary)
	]);
var $author$project$Page$Home$viewImage = function (imageUrl) {
	return A2(
		$author$project$SE$UI$Image$V2$toSimpleHtml,
		{gM: '', iI: imageUrl},
		A2(
			$author$project$SE$UI$Image$V2$withAspectRatio,
			_Utils_Tuple2(1280, 720),
			$author$project$SE$UI$Image$V2$create(
				{e7: 720, gE: 1280})));
};
var $author$project$SE$UI$Icon$V2$clock = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z'])));
var $author$project$SE$UI$Icon$V2$withContainerSize = F2(
	function (size, _v0) {
		var internals = _v0;
		return _Utils_update(
			internals,
			{cq: size});
	});
var $author$project$Page$Home$viewPublishedDateAndTag = F2(
	function (zone, meta) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$ul,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
					$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
					$author$project$SE$UI$Font$bodySizeRem(-3),
					$rtfeldman$elm_css$Css$fontWeight(
					$rtfeldman$elm_css$Css$int(600)),
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$rem(0.5)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$Global$children(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$li(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$Global$adjacentSiblings(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$Global$li(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginLeft(
													$rtfeldman$elm_css$Css$rem(1))
												]))
										]))
								]))
						])),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'icon',
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
								]))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Icon$V2$toHtml(
							A2($author$project$SE$UI$Icon$V2$withContainerSize, 1, $author$project$SE$UI$Icon$V2$clock)),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2($author$project$Helpers$viewDate, meta.il, zone))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Icon$V2$toHtml(
							A2($author$project$SE$UI$Icon$V2$withContainerSize, 1, $author$project$SE$UI$Icon$V2$campaign)),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(meta.iO)
								]))
						]))
				]));
	});
var $author$project$Page$Home$viewNewsArticle = F2(
	function (zone, article) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Css$boxShadow4,
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$px(4),
							$rtfeldman$elm_css$Css$px(10),
							$author$project$SE$UI$Colors$toCss(
								A2(
									$author$project$SE$UI$Colors$mapAlpha,
									$elm$core$Basics$always(0.15),
									$author$project$SE$UI$Colors$black))),
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
						])),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('article', true)
						])),
					$rtfeldman$elm_css$Html$Styled$Attributes$href(article.cg),
					$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
				]),
			_List_fromArray(
				[
					$author$project$Page$Home$viewImage(article.hy),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$margin(
							$rtfeldman$elm_css$Css$rem(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$flexGrow(
							$rtfeldman$elm_css$Css$int(1))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Page$Home$viewPublishedDateAndTag, zone, article),
							$author$project$SE$UI$Title$title5(article.gr),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop($rtfeldman$elm_css$Css$auto)
								]),
							_List_Nil,
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$span,
									$author$project$SE$UI$Font$textButtonStyles,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Läs mer')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Home$viewNewsArticles = F2(
	function (zone, articles) {
		var _v0 = $elm$core$List$length(articles);
		if (!_v0) {
			return $rtfeldman$elm_css$Html$Styled$text('');
		} else {
			return A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$author$project$SE$UI$Title$title2('Senaste nytt'),
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$a,
										$author$project$SE$UI$Font$textButtonStyles,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Constants$siteUrl + '/nyheter'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Alla nyheter')
											]))
									])),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$author$project$SE$UI$Utils$block,
										A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
										A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1rem'),
										$author$project$SE$UI$Utils$fullhd(
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fill, minmax(400px, 1fr))')
											])),
										$author$project$SE$UI$Utils$tablet(
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fill, minmax(320px, 1fr))')
											]))
									]),
								_List_Nil,
								A2(
									$elm$core$List$map,
									$author$project$Page$Home$viewNewsArticle(zone),
									articles))
							]))
					]));
		}
	});
var $author$project$Page$Home$ChangedDisplayDocType = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Home$DisplayInvoices = 1;
var $author$project$SE$UI$Tabs$V2$Button = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$SE$UI$Tabs$V2$button = $author$project$SE$UI$Tabs$V2$Button;
var $author$project$Page$Invoices$View$emptyTable = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
		]),
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Title$title1('Inga fakturor'),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Vi kan inte hitta några fakturor. Om du sökt på ett fakturanummer, kontrollera att du skrivit rätt.')
				]))
		]));
var $author$project$Invoice$internals = function (_v0) {
	var internals_ = _v0;
	return internals_;
};
var $author$project$SE$UI$Icon$V2$pdf = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		384,
		512,
		_List_fromArray(
			['M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z'])));
var $author$project$Invoice$Overdue = 2;
var $author$project$Invoice$Paid = 1;
var $author$project$Invoice$Unpaid = 0;
var $author$project$Invoice$status = F2(
	function (now, _v0) {
		var internals_ = _v0;
		if (internals_.fl) {
			return 1;
		} else {
			var _v1 = internals_.eW;
			if (_v1.$ === 1) {
				return 0;
			} else {
				var dueAt_ = _v1.a;
				return (_Utils_cmp(
					$elm$time$Time$posixToMillis(dueAt_),
					$elm$time$Time$posixToMillis(now)) < 0) ? 2 : 0;
			}
		}
	});
var $author$project$Page$Invoices$View$viewCreditInvoiceBadge = function (type_) {
	if (type_ === 1) {
		return ' (Kreditfaktura)';
	} else {
		return '';
	}
};
var $author$project$Page$Invoices$View$viewDueAt = F3(
	function (zone, status, dueAt) {
		return function () {
			if (status === 2) {
				return A3(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger),
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(600))
						]),
					_List_Nil);
			} else {
				return $rtfeldman$elm_css$Html$Styled$span(_List_Nil);
			}
		}()(
			$elm$core$List$singleton(
				$rtfeldman$elm_css$Html$Styled$text(
					A2($author$project$Helpers$viewDate, dueAt, zone))));
	});
var $author$project$Page$Invoices$View$viewOrderLink = function (maybeOrder) {
	if (maybeOrder.$ === 1) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var order = maybeOrder.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$author$project$Route$href(
					$author$project$Route$Order(
						$author$project$Order$internals(order).hx))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$author$project$Order$displayId(order))
				]));
	}
};
var $author$project$Invoice$statusColorLabel = function (status_) {
	switch (status_) {
		case 2:
			return _Utils_Tuple2(12, 'FÖRFALLEN');
		case 1:
			return _Utils_Tuple2(9, 'BETALD');
		default:
			return _Utils_Tuple2(2, 'OBETALD');
	}
};
var $author$project$Invoice$viewStatusTag = function (status_) {
	var _v0 = $author$project$Invoice$statusColorLabel(status_);
	var color = _v0.a;
	var label = _v0.b;
	return A2(
		$author$project$SE$UI$Tag$tag,
		_List_fromArray(
			[
				$author$project$SE$UI$Tag$Color(color),
				$author$project$SE$UI$Tag$Normal
			]),
		label);
};
var $author$project$Page$Invoices$View$yourRef = function (yr) {
	if (!yr.$) {
		var str = yr.a;
		return $rtfeldman$elm_css$Html$Styled$text(str);
	} else {
		var user = yr.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:' + user.be)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(user.hT)
				]));
	}
};
var $author$project$Page$Invoices$View$row = F2(
	function (_v0, invoice) {
		var zone = _v0.a;
		var now = _v0.b;
		var status = A2($author$project$Invoice$status, now, invoice);
		var internals = $author$project$Invoice$internals(invoice);
		var maybeOrderInternals = A2($elm$core$Maybe$map, $author$project$Order$internals, internals.h9);
		var name = function () {
			var _v1 = internals.cg;
			if (_v1 === '') {
				return $rtfeldman$elm_css$Html$Styled$text(
					_Utils_ap(
						internals.hT,
						$author$project$Page$Invoices$View$viewCreditInvoiceBadge(internals.iT)));
			} else {
				return A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href(internals.cg),
							$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$pdf),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									_Utils_ap(
										internals.hT,
										$author$project$Page$Invoices$View$viewCreditInvoiceBadge(internals.iT)))
								]))
						]));
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(1)),
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Invoice$viewStatusTag(status)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[name])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Page$Invoices$View$viewOrderLink(internals.h9)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Helpers$viewMaybe,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.i0;
								},
								$rtfeldman$elm_css$Html$Styled$text),
							maybeOrderInternals)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Helpers$viewMaybe,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.i1;
								},
								$author$project$Page$Invoices$View$yourRef),
							maybeOrderInternals)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Helpers$viewDate, internals.hD, zone))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Helpers$viewMaybe,
							A2($author$project$Page$Invoices$View$viewDueAt, zone, status),
							internals.eW)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Helpers$formatToMoney(internals.iS))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Helpers$viewDate, internals.iW, zone))
						]))
				]));
	});
var $author$project$Page$Invoices$View$tableHelper = F2(
	function (zoneAndNow, invoices) {
		return A2(
			$author$project$SE$UI$Table$V2$withHead,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(1)),
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Status')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Fakturanummer')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Vårt ordernummer')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Ert ordernummer')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Er referens')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Fakturadatum')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Förfallodatum')
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Totalt belopp')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Senast uppdaterad')
						]))
				]),
			A2(
				$author$project$SE$UI$Table$V2$withModifiers,
				_List_fromArray(
					[1, 0]),
				A2(
					$author$project$SE$UI$Table$V2$body,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$descendants(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Global$class,
											'tag',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontWeight(
													$rtfeldman$elm_css$Css$int(600)),
													$author$project$SE$UI$Font$bodySizeRem(-3)
												]))
										]))
								]))
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$Invoices$View$row(zoneAndNow),
						invoices))));
	});
var $author$project$Page$Invoices$View$simpleTable = F2(
	function (zoneAndNow, invoices) {
		return $elm$core$List$isEmpty(invoices) ? $author$project$Page$Invoices$View$emptyTable : $author$project$SE$UI$Table$V2$toHtml(
			A2($author$project$Page$Invoices$View$tableHelper, zoneAndNow, invoices));
	});
var $author$project$Page$Home$viewInvoices = F2(
	function (zoneAndNow, statusInvoices) {
		return A3(
			$author$project$Status$view,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Api$errorToHtml,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
			$author$project$Page$Invoices$View$simpleTable(zoneAndNow),
			statusInvoices);
	});
var $author$project$Page$Orders$View$emptyTable = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
		]),
	_List_Nil,
	_List_fromArray(
		[
			$author$project$SE$UI$Title$title1('Inga ordrar'),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Vi kan inte hitta någon order. Om du sökt på ett ordernummer, kontrollera att du skrivit rätt.')
				]))
		]));
var $author$project$Order$externalId = function (o) {
	if (!o.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var externalId_ = o.a;
		return $elm$core$Maybe$Just(externalId_);
	}
};
var $author$project$Page$Orders$View$yourRef = function (yr) {
	if (!yr.$) {
		var str = yr.a;
		return $rtfeldman$elm_css$Html$Styled$text(str);
	} else {
		var user = yr.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:' + user.be)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(user.hT)
				]));
	}
};
var $author$project$Page$Orders$View$row = F2(
	function (timezone, order) {
		var internals = $author$project$Order$internals(order);
		var status = function () {
			var _v0 = function ($) {
				return $.gh;
			}(internals);
			switch (_v0) {
				case 0:
					return 'Ej hanterad än...';
				case 1:
					return 'Ej skickad';
				case 3:
					return 'Makulerad';
				case 2:
					return 'Skickad';
				case 4:
					return 'Restorder';
				case 5:
					return 'Internorder';
				case 6:
					return 'Pågående';
				default:
					return 'Avslutad';
			}
		}();
		var external_id = A2(
			$elm$core$Maybe$withDefault,
			'Webb ID: W' + $elm$core$String$fromInt(
				$author$project$Id$toInt(internals.hx)),
			A2(
				$elm$core$Maybe$map,
				$author$project$ExternalId$toString,
				$author$project$Order$externalId(order)));
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(status)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$author$project$Route$href(
									$author$project$Route$Order(internals.hx))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(external_id)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(internals.i0)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Page$Orders$View$yourRef(internals.i1)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Helpers$viewDate, internals.g$, timezone))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Helpers$viewDate, internals.iW, timezone))
						]))
				]));
	});
var $author$project$Page$Orders$View$tableHelper = F2(
	function (timezone, orders) {
		return A2(
			$author$project$SE$UI$Table$V2$withHead,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Status')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Vårt ordernummer')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Ert ordernummer')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Er referens')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Orderdatum')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Senast updaterad ⯆')
						]))
				]),
			A2(
				$author$project$SE$UI$Table$V2$withModifiers,
				_List_fromArray(
					[1, 0]),
				A2(
					$author$project$SE$UI$Table$V2$body,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Page$Orders$View$row(timezone),
						orders))));
	});
var $author$project$Page$Orders$View$simpleTable = F2(
	function (timezone, orders) {
		return $elm$core$List$isEmpty(orders) ? $author$project$Page$Orders$View$emptyTable : $author$project$SE$UI$Table$V2$toHtml(
			A2($author$project$Page$Orders$View$tableHelper, timezone, orders));
	});
var $author$project$Page$Home$viewOrders = F2(
	function (zone, statusOrders) {
		return A3(
			$author$project$Status$view,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Api$errorToHtml,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
			$author$project$Page$Orders$View$simpleTable(zone),
			statusOrders);
	});
var $author$project$Page$Home$viewOrdersAndInvoices = F4(
	function (_v0, displayDocType, orders, invoices) {
		var zone = _v0.a;
		var now = _v0.b;
		var _v1 = function () {
			if (!displayDocType) {
				return _Utils_Tuple2(
					$author$project$Route$Orders($author$project$QueryParams$empty),
					'ordrar');
			} else {
				return _Utils_Tuple2(
					$author$project$Route$Invoices($author$project$QueryParams$empty),
					'fakturor');
			}
		}();
		var allRoute = _v1.a;
		var allLabel = _v1.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'card',
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, 'margin-top', 'calc(-1.5rem - 1px)'),
									$rtfeldman$elm_css$Css$zIndex(
									$rtfeldman$elm_css$Css$int(1))
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'tabs',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$zIndex(
									$rtfeldman$elm_css$Css$int(2))
								]))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							A2($rtfeldman$elm_css$Css$property, 'gap', '1.5rem')
						]),
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Tabs$V2$toHtml(
							$author$project$SE$UI$Tabs$V2$isBoxed(
								$author$project$SE$UI$Tabs$V2$create(
									_List_fromArray(
										[
											A3(
											$author$project$SE$UI$Tabs$V2$button,
											!displayDocType,
											$author$project$Page$Home$ChangedDisplayDocType(0),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Mina ordrar')
												])),
											A3(
											$author$project$SE$UI$Tabs$V2$button,
											displayDocType === 1,
											$author$project$Page$Home$ChangedDisplayDocType(1),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Mina fakturor')
												]))
										])))),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto)
								]),
							_List_Nil,
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$a,
									A2(
										$elm$core$List$cons,
										$rtfeldman$elm_css$Css$marginLeft(
											$rtfeldman$elm_css$Css$rem(1.5)),
										$author$project$SE$UI$Font$textButtonStyles),
									_List_fromArray(
										[
											$author$project$Route$href(allRoute)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Alla ' + allLabel)
										]))
								]))
						])),
					$author$project$SE$UI$Card$toHtml(
					$author$project$SE$UI$Card$withBoxShadow(
						$author$project$SE$UI$Card$content(
							_List_fromArray(
								[
									function () {
									if (!displayDocType) {
										return A2($author$project$Page$Home$viewOrders, zone, orders);
									} else {
										return A2(
											$author$project$Page$Home$viewInvoices,
											_Utils_Tuple2(zone, now),
											invoices);
									}
								}(),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(0.88888888)),
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
										]),
									_List_fromArray(
										[
											$author$project$Route$href(allRoute)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Visa alla ' + allLabel)
										]))
								]))))
				]));
	});
var $author$project$Page$Products$View$attributeLabelAndValue = function (attribute) {
	if (!attribute.$) {
		var label = attribute.a;
		var value = attribute.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(label, value));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_css$Css$listStyle = $rtfeldman$elm_css$Css$prop1('list-style');
var $author$project$Page$Products$View$viewAttribute = function (_v0) {
	var label = _v0.a;
	var value = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(label + (': ' + value))
			]));
};
var $author$project$Page$Products$View$viewAttributes = function (attributes) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$ul,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$pseudoClass,
						'not(:empty)',
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
								$author$project$SE$UI$Font$bodySizeRem(-3),
								$rtfeldman$elm_css$Css$listStyle($rtfeldman$elm_css$Css$disc),
								A4(
								$rtfeldman$elm_css$Css$padding4,
								$rtfeldman$elm_css$Css$zero,
								$rtfeldman$elm_css$Css$px(16),
								$rtfeldman$elm_css$Css$rem(1),
								$rtfeldman$elm_css$Css$px(16)),
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$unset)
							]))
					]))
			]),
		_List_Nil,
		A2(
			$elm$core$List$map,
			$author$project$Page$Products$View$viewAttribute,
			A2(
				$elm$core$List$take,
				3,
				A2($elm$core$List$filterMap, $author$project$Page$Products$View$attributeLabelAndValue, attributes))));
};
var $author$project$Page$Products$View$viewDatasheet = function (file) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						$author$project$SE$UI$Font$bodySizeRem(-2),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$href(file.cg),
				$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
			]),
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$pdf)
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Produktblad')
					]))
			]));
};
var $author$project$Product$availability = function (product) {
	if (!product.$) {
		var a = product.c;
		return $elm$core$Maybe$Just(a);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Products$View$viewAddToCart = F4(
	function (toSessionMsg, analyticsSource, _v0, product) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$SE$UI$Buttons$button,
					_List_fromArray(
						[
							$author$project$SE$UI$Buttons$Color(11),
							$author$project$SE$UI$Buttons$Fullwidth
						]),
					$elm$core$Maybe$Just(
						toSessionMsg(
							A4(
								$author$project$Session$AddedToCart,
								$author$project$Product$minimal(product),
								$author$project$Cart$NoSource,
								1,
								analyticsSource))),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Lägg i varukorg')
						]))
				]));
	});
var $author$project$SE$UI$Icon$V2$box = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M509.5 184.6L458.9 32.8C452.4 13.2 434.1 0 413.4 0H272v192h238.7c-.4-2.5-.4-5-1.2-7.4zM240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zM0 224v240c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V224H0'])));
var $author$project$Availability$balanceIcon = _Utils_Tuple2($author$project$SE$UI$Icon$V2$box, $author$project$SE$UI$Colors$primary);
var $author$project$Availability$contactUsLinkString = function (externalId) {
	var encodedExternalId = $elm$url$Url$percentEncode(
		$author$project$ExternalId$toString(externalId));
	return 'mailto:order@specialelektronik.se?subject=Leveransdatum%20' + (encodedExternalId + ('&body=P%C3%A5%20Partnerzonen%20visas%20inte%20lagersaldo%20eller%20leveranstid%20f%C3%B6r%20' + (encodedExternalId + '%2C%20har%20ni%20n%C3%A5got%20f%C3%B6rv%C3%A4ntat%20leveransdatum%3F')));
};
var $author$project$SE$UI$Colors$mapSaturation = F2(
	function (f, _v0) {
		var hue = _v0.a;
		var saturation = _v0.b;
		var lightness = _v0.c;
		var alpha = _v0.d;
		return A4(
			$author$project$SE$UI$Colors$Hsla,
			hue,
			f(saturation),
			lightness,
			alpha);
	});
var $author$project$SE$UI$Icon$V2$truck = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		640,
		512,
		_List_fromArray(
			['M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z'])));
var $author$project$Availability$incomingIcon = _Utils_Tuple2(
	$author$project$SE$UI$Icon$V2$truck,
	A2(
		$author$project$SE$UI$Colors$mapSaturation,
		$elm$core$Basics$mul(0.5),
		A2(
			$author$project$SE$UI$Colors$mapAlpha,
			$elm$core$Basics$mul(0.7),
			$author$project$SE$UI$Colors$primary)));
var $author$project$Availability$paragraph = F3(
	function (maybeIcon, link, label) {
		var _v0 = (link === '') ? _Utils_Tuple2(
			$rtfeldman$elm_css$Html$Styled$p,
			$rtfeldman$elm_css$Html$Styled$Attributes$classList(_List_Nil)) : _Utils_Tuple2(
			$rtfeldman$elm_css$Html$Styled$a,
			$rtfeldman$elm_css$Html$Styled$Attributes$href(link));
		var tag = _v0.a;
		var linkAttr = _v0.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			tag,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
					$rtfeldman$elm_css$Css$Global$children(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'icon',
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Tuple$second,
										A2($elm$core$Basics$composeR, $author$project$SE$UI$Colors$color, $elm$core$List$singleton)),
									maybeIcon)))
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$withClass,
					'delivery-request-link',
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
							$rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$children(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Global$class,
											'icon',
											_List_fromArray(
												[
													$author$project$SE$UI$Colors$color(
													$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
												]))
										]))
								]))
						])),
					$author$project$SE$UI$Font$bodySizeRem(-2)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('availability', true),
							_Utils_Tuple2('delivery-request-link', link !== '')
						])),
					linkAttr
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$text(''),
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$SE$UI$Icon$V2$toHtml),
						maybeIcon)),
					$rtfeldman$elm_css$Html$Styled$text(' ' + label)
				]));
	});
var $author$project$Availability$viewCompact = F2(
	function (externalId, availability) {
		if (availability.$ === 1) {
			if (!availability.b.b) {
				var balance = availability.a;
				return A3(
					$author$project$Availability$paragraph,
					$elm$core$Maybe$Just($author$project$Availability$balanceIcon),
					'',
					$elm$core$String$fromInt(balance) + ' i lager');
			} else {
				var balance = availability.a;
				return A3(
					$author$project$Availability$paragraph,
					$elm$core$Maybe$Just($author$project$Availability$balanceIcon),
					'',
					$elm$core$String$fromInt(balance) + ' i lager, fler på väg in');
			}
		} else {
			if (!availability.a.b) {
				if (availability.b.$ === 1) {
					var _v1 = availability.b;
					return A3(
						$author$project$Availability$paragraph,
						$elm$core$Maybe$Just(
							_Utils_Tuple2($author$project$SE$UI$Icon$V2$box, $author$project$SE$UI$Colors$base)),
						$author$project$Availability$contactUsLinkString(externalId),
						'Kontakta oss');
				} else {
					var leadtime = availability.b.a;
					return A3($author$project$Availability$paragraph, $elm$core$Maybe$Nothing, '', leadtime + ' in på vårt lager');
				}
			} else {
				return A3(
					$author$project$Availability$paragraph,
					$elm$core$Maybe$Just($author$project$Availability$incomingIcon),
					'',
					'På väg in');
			}
		}
	});
var $author$project$Page$Products$View$viewAvailability = F2(
	function (externalId, maybeAvailability) {
		return A2(
			$author$project$Helpers$viewMaybe,
			$author$project$Availability$viewCompact(externalId),
			maybeAvailability);
	});
var $author$project$SE$UI$Colors$DarkGreen = 14;
var $author$project$Product$viewMissingPrice = function (externalId_) {
	var encodedExternalId = $elm$url$Url$percentEncode(
		$author$project$ExternalId$toString(externalId_));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$a,
		A2(
			$author$project$SE$UI$Buttons$buttonStyles,
			_List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(14)
				]),
			false),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('missing-price-request-button'),
				$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:order@specialelektronik.se?subject=Prisf%C3%B6rfr%C3%A5gan%20' + (encodedExternalId + ('&body=Partnerzonen%20visar%20inget%20pris%20f%C3%B6r%20' + (encodedExternalId + '%2C%20kan%20ni%20skicka%20prisuppgift%20till%20mig%3F%0A%0AAntal%3A%0A%0ATelefon%3A%0A%0AEv.%20%C3%B6vriga%20uppgifter%3A'))))
			]),
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$envelope),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Skicka prisförfrågan')
					]))
			]));
};
var $author$project$Page$Products$View$viewGalleryPriceStockAndAddToCart = F4(
	function (toSessionMsg, analyticsSource, product, user) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginTop($rtfeldman$elm_css$Css$auto),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(100)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					A2($rtfeldman$elm_css$Css$property, 'gap', '0.75rem')
				]),
			_List_Nil,
			function () {
				var _v0 = $author$project$Product$prices(product);
				if (_v0.$ === 1) {
					return _List_fromArray(
						[
							$author$project$Product$viewMissingPrice(
							$author$project$Product$externalId(product))
						]);
				} else {
					if (!_v0.a.$) {
						var _v1 = _v0.a;
						return _List_fromArray(
							[
								$author$project$Product$viewMissingPrice(
								$author$project$Product$externalId(product))
							]);
					} else {
						var data = _v0.a.a;
						var metadata = $author$project$Product$metadata(product);
						return _List_fromArray(
							[
								A4($author$project$Price$viewSmallTag, metadata.d8, user.c0.gA, user.c0.ga, data),
								A4($author$project$Page$Products$View$viewAddToCart, toSessionMsg, analyticsSource, user, product),
								A2(
								$author$project$Page$Products$View$viewAvailability,
								$author$project$Product$externalId(product),
								$author$project$Product$availability(product))
							]);
					}
				}
			}());
	});
var $author$project$Page$Products$View$galleryImageContainerStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$minWidth(
		$rtfeldman$elm_css$Css$px(60)),
		$rtfeldman$elm_css$Css$marginRight(
		$rtfeldman$elm_css$Css$px(16)),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$minWidth($rtfeldman$elm_css$Css$unset),
				$rtfeldman$elm_css$Css$marginRight($rtfeldman$elm_css$Css$unset),
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$px(8)),
				$rtfeldman$elm_css$Css$maxHeight(
				$rtfeldman$elm_css$Css$px(200)),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$a(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$descendants(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$img(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$maxHeight(
												$rtfeldman$elm_css$Css$px(200)),
												A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto)
											]))
									]))
							]))
					]))
			]))
	]);
var $author$project$Page$Products$View$miniThumbnailsStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$author$project$SE$UI$Colors$backgroundColor(
				A2(
					$author$project$SE$UI$Colors$mapAlpha,
					$elm$core$Basics$always(0.2),
					$author$project$SE$UI$Colors$black)),
				A2($rtfeldman$elm_css$Css$property, 'backdrop-filter', 'blur(4px)'),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$right(
				$rtfeldman$elm_css$Css$px(-16)),
				$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$left(
				$rtfeldman$elm_css$Css$px(-16))
			]))
	]);
var $author$project$Page$Products$View$miniThumbnailContainerStyles = _List_fromArray(
	[
		A3(
		$rtfeldman$elm_css$Css$border3,
		$rtfeldman$elm_css$Css$px(1),
		$rtfeldman$elm_css$Css$solid,
		$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$px(30)),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px(30)),
		$rtfeldman$elm_css$Css$margin(
		$rtfeldman$elm_css$Css$px(4)),
		$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-active',
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$dark)
			]))
	]);
var $author$project$Page$Products$View$miniThumbnailStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$paddingTop(
		$rtfeldman$elm_css$Css$pct(100)),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'figure',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
						$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$pct(100)),
						$rtfeldman$elm_css$Css$maxHeight(
						$rtfeldman$elm_css$Css$px(28)),
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$typeSelector,
								'img',
								_List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$property, 'object-fit', 'contain'),
										$rtfeldman$elm_css$Css$height(
										$rtfeldman$elm_css$Css$px(28))
									]))
							]))
					]))
			]))
	]);
var $author$project$Page$Products$View$viewMiniThumbnail = F5(
	function (toMsg, itemIndex, activeImageIndex, imageIndex, image) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$li,
			$author$project$Page$Products$View$miniThumbnailContainerStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					A2(toMsg, itemIndex, imageIndex)),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'is-active',
							_Utils_eq(activeImageIndex, imageIndex))
						]))
				]),
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$Page$Products$View$miniThumbnailStyles,
					_List_Nil,
					_List_fromArray(
						[
							A3($author$project$Image$viewThumbnail, 28, '', image)
						]))
				]));
	});
var $author$project$Page$Products$View$viewMiniThumbnails = F4(
	function (toMsg, itemIndex, activeImageIndex, images) {
		return ($elm$core$List$length(images) <= 1) ? $rtfeldman$elm_css$Html$Styled$text('') : A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Page$Products$View$miniThumbnailsStyles,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
						]),
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						A3($author$project$Page$Products$View$viewMiniThumbnail, toMsg, itemIndex, activeImageIndex),
						images))
				]));
	});
var $author$project$Page$Products$View$viewProductsGalleryImage = F6(
	function (toMsg, _v0, itemIndex, activeImageIndex, images, maybeBrandImage) {
		var productId = _v0.a;
		var productSlug = _v0.b;
		var imageConfig = A2(
			$author$project$SE$UI$Image$V2$withAspectRatio,
			_Utils_Tuple2(16, 9),
			$author$project$SE$UI$Image$V2$create(
				{e7: 200, gE: 355}));
		var fallback = A2(
			$elm$core$Maybe$withDefault,
			$author$project$SE$UI$Image$V2$toMissingHtml(imageConfig),
			A2(
				$elm$core$Maybe$map,
				A3($author$project$Image$viewCustom, imageConfig, 0, ''),
				maybeBrandImage));
		var currentImageHtml = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, carry) {
					var index = _v1.a;
					var image = _v1.b;
					return (_Utils_cmp(index, activeImageIndex) < 1) ? A4($author$project$Image$viewCustom, imageConfig, 0, '', image) : carry;
				}),
			fallback,
			A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, images));
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Page$Products$View$galleryImageContainerStyles,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							A2(
								$author$project$Route$Product,
								$author$project$QueryParams$empty,
								_Utils_Tuple2(productId, productSlug)))
						]),
					_List_fromArray(
						[currentImageHtml])),
					A4($author$project$Page$Products$View$viewMiniThumbnails, toMsg, itemIndex, activeImageIndex, images)
				]));
	});
var $author$project$Page$Products$View$galleryItem = F4(
	function (config, maybeUser, index, _v0) {
		var product = _v0.b2;
		var activeImageIndex = _v0.gH;
		var productSlug = $author$project$Product$slug(product);
		var productId = $author$project$Product$id(product);
		var metadata = $author$project$Product$metadata(product);
		return _List_fromArray(
			[
				A6(
				$author$project$Page$Products$View$viewProductsGalleryImage,
				config.iC,
				_Utils_Tuple2(productId, productSlug),
				index,
				activeImageIndex,
				metadata.fd,
				$author$project$Brand$logo(metadata.dp)),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$width(
						A3(
							$rtfeldman$elm_css$Css$calc,
							$rtfeldman$elm_css$Css$pct(100),
							$rtfeldman$elm_css$Css$minus,
							$rtfeldman$elm_css$Css$px(76))),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset)
							]))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Page$Products$View$viewBrand(metadata.dp),
						$author$project$Page$Products$View$viewName(product),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(0.75))
							]),
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Page$Products$View$viewExternalId(
								$author$project$Product$externalId(product)),
								A2($author$project$Helpers$viewMaybe, $author$project$Page$Products$View$viewDatasheet, metadata.hm)
							]))
					])),
				$author$project$Page$Products$View$viewAttributes(metadata.gQ),
				A2(
				$author$project$Helpers$viewMaybe,
				A3(
					$author$project$Page$Products$View$viewGalleryPriceStockAndAddToCart,
					config.iF,
					config.gN(index),
					product),
				maybeUser),
				A2($author$project$Page$Products$View$viewStickersContainer, true, metadata.d8)
			]);
	});
var $author$project$Page$Products$View$galleryStyles = _List_fromArray(
	[
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
				A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1.5rem'),
				A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fill, minmax(320px , 1fr))')
			])),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'placeholder',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(260)),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$px(550))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'product',
				_List_fromArray(
					[
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero))
							])),
						A2(
						$rtfeldman$elm_css$Css$pseudoClass,
						'not(:last-child)',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1.5)),
								$author$project$SE$UI$Utils$desktop(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero))
									]))
							]))
					]))
			]))
	]);
var $author$project$Page$Products$View$viewStatusHelper = F4(
	function (styles, loadedFn, index, status) {
		var _v0 = function () {
			if (!status.$) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							_Utils_Tuple2('product', true),
							_Utils_Tuple2('placeholder', true)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('loading...')
						]));
			} else {
				var a = status.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							_Utils_Tuple2('product', true)
						]),
					A2(loadedFn, index, a));
			}
		}();
		var classes = _v0.a;
		var content = _v0.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			styles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id(
					$elm$core$String$fromInt(index)),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(classes)
				]),
			content);
	});
var $author$project$Page$Products$View$viewGalleryItemStatus = F3(
	function (index, fn, status) {
		return _Utils_Tuple2(
			'gallery-' + $elm$core$String$fromInt(index),
			A4(
				$author$project$Page$Products$View$viewStatusHelper,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100)),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
						$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
						$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
						$rtfeldman$elm_css$Css$borderWidth(
						$rtfeldman$elm_css$Css$px(1)),
						$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
						$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
						A4(
						$rtfeldman$elm_css$Css$boxShadow4,
						$rtfeldman$elm_css$Css$zero,
						$rtfeldman$elm_css$Css$px(4),
						$rtfeldman$elm_css$Css$px(10),
						$author$project$SE$UI$Colors$toCss(
							A2(
								$author$project$SE$UI$Colors$mapAlpha,
								$elm$core$Basics$always(0.15),
								$author$project$SE$UI$Colors$black))),
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$px(16)),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
								$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$unset)
							]))
					]),
				fn,
				index,
				status));
	});
var $author$project$Page$Products$View$gallery = F4(
	function (cssClass, config, session, items) {
		return A3(
			$rtfeldman$elm_css$Html$Styled$Keyed$node,
			'div',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('products'),
					$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Page$Products$View$galleryStyles),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(cssClass, true)
						]))
				]),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var rowNo = _v0.a;
					var v = _v0.b;
					return A3(
						$author$project$Page$Products$View$viewGalleryItemStatus,
						rowNo,
						A2(
							$author$project$Page$Products$View$galleryItem,
							config,
							$author$project$Session$user(session)),
						v);
				},
				$elm$core$Dict$toList(items)));
	});
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Analytics$HomePage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Home$SelectedImage = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$Home$viewConfig = {gN: $author$project$Analytics$HomePage, iC: $author$project$Page$Home$SelectedImage, iF: $author$project$Page$Home$GotSessionMsg};
var $author$project$Page$Home$viewProducts = F2(
	function (session, items) {
		return $elm$core$Dict$isEmpty(items) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title2('Senaste produkterna'),
							A4($author$project$Page$Products$View$gallery, 'lastest-news', $author$project$Page$Home$viewConfig, session, items)
						]))
				]));
	});
var $author$project$Page$Home$view = function (model) {
	var productsHtml = A3($rtfeldman$elm_css$Html$Styled$Lazy$lazy2, $author$project$Page$Home$viewProducts, model.K, model.hF);
	var newsArticlesHtml = A3(
		$rtfeldman$elm_css$Html$Styled$Lazy$lazy2,
		$author$project$Page$Home$viewNewsArticles,
		$author$project$Session$zone(model.K),
		model.cK);
	var categoriesHtml = A2($rtfeldman$elm_css$Html$Styled$Lazy$lazy, $author$project$Page$Home$viewCategories, model.cF);
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return A3($author$project$Page$Home$viewGuestAccount, productsHtml, categoriesHtml, newsArticlesHtml);
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Section$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Home),
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$author$project$SE$UI$Utils$block,
												A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
												A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1.5rem'),
												A2($rtfeldman$elm_css$Css$property, 'grid-template-rows', 'auto auto'),
												$author$project$SE$UI$Utils$widescreen(
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', '1fr auto'),
														A2($rtfeldman$elm_css$Css$property, 'grid-template-rows', 'auto')
													]))
											]),
										_List_Nil,
										_List_fromArray(
											[
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$author$project$SE$UI$Utils$widescreen(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$order(
																$rtfeldman$elm_css$Css$num(1))
															]))
													]),
												_List_Nil,
												_List_fromArray(
													[
														$author$project$SE$UI$Card$toHtml(
														$author$project$SE$UI$Card$withBoxShadow(
															$author$project$SE$UI$Card$content(
																_List_fromArray(
																	[
																		$author$project$Page$Account$View$profile(user)
																	]))))
													])),
												A4(
												$author$project$Page$Home$viewOrdersAndInvoices,
												_Utils_Tuple2(
													$author$project$Session$zone(model.K),
													model.el),
												model.cu,
												model.aP,
												model.hE)
											]))
									]))
							])),
						newsArticlesHtml,
						categoriesHtml,
						productsHtml
					])),
			gr: 'Special-Elektroniks Partnerzon'
		};
	}
};
var $author$project$Page$Invoices$GotPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Breadcrumb$Invoices = {$: 7};
var $author$project$Page$Invoices$View$table = F3(
	function (goToPageMsg, zoneAndNow, invoices) {
		if ($elm$core$List$isEmpty(invoices.g2)) {
			return $author$project$Page$Invoices$View$emptyTable;
		} else {
			var footerFn = (invoices.hJ === 1) ? $elm$core$Basics$identity : $author$project$SE$UI$Table$V2$withFoot(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(8)
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Pagination$V2$toHtml(
								$author$project$SE$UI$Pagination$V2$isCentered(
									$author$project$SE$UI$Pagination$V2$create(
										{g1: invoices.g1, hJ: invoices.hJ, cJ: goToPageMsg, hY: 'Nästa ⯈', ig: '⯇ Föregående'})))
							]))
					]));
			return $author$project$SE$UI$Table$V2$toHtml(
				footerFn(
					A2($author$project$Page$Invoices$View$tableHelper, zoneAndNow, invoices.g2)));
		}
	});
var $author$project$Page$Invoices$viewCustomerCareInfo = $author$project$Contactbox$view(
	{
		bI: '',
		eM: 'Ekonomiavdelningen',
		fc: $elm$core$Maybe$Just('/assets/images/ekonomi.png'),
		fJ: '00',
		f_: _List_fromArray(
			['info']),
		c5: 'Fakturahistorik på Partnerzonen',
		gr: 'Behöver du hjälp? Hör av dig!'
	});
var $author$project$SE$UI$Buttons$Attached = 0;
var $author$project$SE$UI$Level$Item = $elm$core$Basics$identity;
var $author$project$SE$UI$Level$item = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$flexBasis = $rtfeldman$elm_css$Css$prop1('flex-basis');
var $author$project$SE$UI$Level$itemStyles = function (isCentered) {
	var modsStyle = isCentered ? _List_fromArray(
		[
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$flexGrow(
			$rtfeldman$elm_css$Css$int(1))
		]) : _List_Nil;
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$flexBasis($rtfeldman$elm_css$Css$auto),
			$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
			$author$project$SE$UI$Utils$tablet(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginRight(
					$rtfeldman$elm_css$Css$rem(0.75))
				])),
			$author$project$SE$UI$Utils$mobile(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$pseudoClass,
					'not(:last-child)',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(0.75))
						]))
				])),
			$rtfeldman$elm_css$Css$batch(modsStyle)
		]);
};
var $author$project$SE$UI$Level$itemToHtml = F2(
	function (isCentered, _v0) {
		var html = _v0;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Level$itemStyles(isCentered),
			_List_Nil,
			html);
	});
var $author$project$SE$UI$Level$leftAndRightStyles = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$flexBasis($rtfeldman$elm_css$Css$auto),
			$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$author$project$SE$UI$Utils$tablet(
			_List_fromArray(
				[$rtfeldman$elm_css$Css$displayFlex]))
		]));
var $author$project$SE$UI$Level$leftStyles = _List_fromArray(
	[
		$author$project$SE$UI$Level$leftAndRightStyles,
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
		$author$project$SE$UI$Utils$mobile(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$adjacentSiblings(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'div',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(1.5))
							]))
					]))
			]))
	]);
var $author$project$SE$UI$Level$levelStyles = function (isMobile) {
	return _List_fromArray(
		[
			$author$project$SE$UI$Utils$block,
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$typeSelector,
					'code',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius)
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$typeSelector,
					'img',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
							$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
						]))
				])),
			$author$project$SE$UI$Utils$tablet(
			_List_fromArray(
				[$rtfeldman$elm_css$Css$displayFlex])),
			$rtfeldman$elm_css$Css$batch(
			isMobile ? _List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$Global$children(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$selector,
							'.left, .right',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$Global$adjacentSiblings(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Global$class,
											'.right',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginTop($rtfeldman$elm_css$Css$zero)
												]))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$selector,
							'.item:not(:last-child)',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$marginRight(
									$rtfeldman$elm_css$Css$rem(0.75))
								]))
						]))
				]) : _List_Nil)
		]);
};
var $author$project$SE$UI$Level$rightStyles = _List_fromArray(
	[
		$author$project$SE$UI$Level$leftAndRightStyles,
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd)
	]);
var $author$project$SE$UI$Level$internalLevel = F3(
	function (isMobile, lfts, rgts) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$nav,
			$author$project$SE$UI$Level$levelStyles(isMobile),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('level', true)
						]))
				]),
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Level$leftStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('left', true)
								]))
						]),
					A2(
						$elm$core$List$map,
						$author$project$SE$UI$Level$itemToHtml(false),
						lfts)),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Level$rightStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('right', true)
								]))
						]),
					A2(
						$elm$core$List$map,
						$author$project$SE$UI$Level$itemToHtml(false),
						rgts))
				]));
	});
var $author$project$SE$UI$Level$level = $author$project$SE$UI$Level$internalLevel(false);
var $author$project$SE$UI$Colors$Lightest = 1;
var $author$project$Session$UpdatedDocumentScope = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Invoices$viewInvoiceListToggleButton = F3(
	function (label, currentScope, scope) {
		var mods = _Utils_eq(currentScope, scope) ? _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(10)
			]) : _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(1)
			]);
		return A3(
			$author$project$SE$UI$Buttons$button,
			mods,
			$elm$core$Maybe$Just(
				$author$project$Page$Invoices$GotSessionMsg(
					$author$project$Session$UpdatedDocumentScope(scope))),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(label)
				]));
	});
var $author$project$Page$Invoices$viewInvoiceListToggle = function (currentScope) {
	return A2(
		$author$project$SE$UI$Level$level,
		_List_fromArray(
			[
				$author$project$SE$UI$Level$item(
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Buttons$buttons,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[
								A3($author$project$Page$Invoices$viewInvoiceListToggleButton, 'Mina', currentScope, 0),
								A3($author$project$Page$Invoices$viewInvoiceListToggleButton, 'Alla', currentScope, 1)
							]))
					]))
			]),
		_List_Nil);
};
var $author$project$Page$Invoices$GotQuery = function (a) {
	return {$: 5, a: a};
};
var $author$project$SE$UI$Control$Medium = 2;
var $author$project$Page$Invoices$NoOp = {$: 0};
var $author$project$SE$UI$Form$Input$Size = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Invoices$SubmittedQuery = {$: 6};
var $author$project$Page$Invoices$viewSearch = F2(
	function (query, loading) {
		var mods = A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Buttons$Color(9),
			A2(
				$elm$core$List$cons,
				$author$project$SE$UI$Buttons$Size(2),
				loading ? _List_fromArray(
					[$author$project$SE$UI$Buttons$Loading]) : _List_Nil));
		var maybeMsg = function () {
			var _v0 = $elm$core$String$trim(query);
			if (_v0 === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just($author$project$Page$Invoices$SubmittedQuery);
			}
		}();
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$rem(0.75))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$form,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onSubmit(
							A2($elm$core$Maybe$withDefault, $author$project$Page$Invoices$NoOp, maybeMsg)),
							$rtfeldman$elm_css$Html$Styled$Attributes$method('post')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_fromArray(
								[0, 1]),
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$expandedControl,
									false,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withModifier,
												$author$project$SE$UI$Form$Input$Size(2),
												A2(
													$author$project$SE$UI$Form$Input$withTrigger,
													0,
													A2(
														$author$project$SE$UI$Form$Input$withPlaceholder,
														'Fakturanummer eller ordernummer',
														A2($author$project$SE$UI$Form$Input$text, $author$project$Page$Invoices$GotQuery, query)))))
										])),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$button,
											A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Sök')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Invoices$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Invoices),
								A2($author$project$Page$viewLoggedInMenu, 6, user),
								$author$project$SE$UI$Title$title1('Fakturor'),
								$author$project$SE$UI$Card$toHtml(
								$author$project$SE$UI$Card$content(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$columns(
											_List_fromArray(
												[
													$author$project$SE$UI$Columns$defaultColumn(
													_List_fromArray(
														[
															A2(
															$author$project$Page$Invoices$viewSearch,
															model.E.d0,
															$author$project$Status$isLoading(model.R)),
															$author$project$Page$Invoices$viewInvoiceListToggle(model.E.c$)
														])),
													A2(
													$author$project$SE$UI$Columns$column,
													_List_fromArray(
														[
															_Utils_Tuple2(3, 1)
														]),
													_List_fromArray(
														[$author$project$Page$Invoices$viewCustomerCareInfo]))
												])),
											A3(
											$author$project$Status$view,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Api$errorToHtml,
												A2(
													$elm$core$Basics$composeR,
													$elm$core$List$singleton,
													$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
											A2(
												$author$project$Page$Invoices$View$table,
												$author$project$Page$Invoices$GotPage,
												_Utils_Tuple2(
													$author$project$Session$zone(model.K),
													model.el)),
											model.R)
										])))
							]))
					])),
			gr: 'Fakturor'
		};
	}
};
var $rtfeldman$elm_css$Css$margin4 = $rtfeldman$elm_css$Css$prop4('margin');
var $rtfeldman$elm_css$Html$Styled$Attributes$media = $rtfeldman$elm_css$VirtualDom$Styled$attribute('media');
var $author$project$Page$Login$View$frame = function (content) {
	return A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_fromArray(
					[1]),
				_List_fromArray(
					[
						$author$project$SE$UI$Card$toHtml(
						$author$project$SE$UI$Card$withBoxShadow(
							$author$project$SE$UI$Card$content(
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$margin(
												$rtfeldman$elm_css$Css$rem(2)),
												$author$project$SE$UI$Utils$tablet(
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
														A2($rtfeldman$elm_css$Css$property, 'grid-gap', '3rem'),
														A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', '2fr 3fr')
													]))
											]),
										_List_Nil,
										_List_fromArray(
											[
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
														$author$project$SE$UI$Utils$tablet(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
																A4(
																$rtfeldman$elm_css$Css$margin4,
																$rtfeldman$elm_css$Css$rem(-2.88888888),
																$rtfeldman$elm_css$Css$rem(1),
																$rtfeldman$elm_css$Css$rem(-2.88888888),
																$rtfeldman$elm_css$Css$rem(-2.88888888)),
																A2($rtfeldman$elm_css$Css$property, 'width', 'calc(100% + 2.88888888rem)'),
																$rtfeldman$elm_css$Css$important(
																$rtfeldman$elm_css$Css$maxWidth($rtfeldman$elm_css$Css$unset)),
																$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
															]))
													]),
												_List_Nil,
												_List_fromArray(
													[
														A3(
														$rtfeldman$elm_css$Html$Styled$node,
														'picture',
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$source,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$media(
																		'(max-width: ' + ($elm$core$String$fromFloat($author$project$SE$UI$Utils$tabletWidth - 1) + 'px)')),
																		A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'sizes', '1px'),
																		A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'srcset', 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7 1w')
																	]),
																_List_Nil),
																A2(
																$rtfeldman$elm_css$Html$Styled$source,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$media(
																		'(min-width: ' + ($elm$core$String$fromFloat($author$project$SE$UI$Utils$tabletWidth) + 'px)')),
																		A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'sizes', '1000px'),
																		A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'srcset', '/assets/images/login.jpg 1000w')
																	]),
																_List_Nil),
																A4(
																$rtfeldman$elm_css$Html$Styled$styled,
																$rtfeldman$elm_css$Html$Styled$img,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
																		$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
																		$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
																		$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
																		$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
																		$rtfeldman$elm_css$Css$width(
																		$rtfeldman$elm_css$Css$pct(100)),
																		$rtfeldman$elm_css$Css$height(
																		$rtfeldman$elm_css$Css$pct(100)),
																		A2($rtfeldman$elm_css$Css$property, 'object-fit', 'cover'),
																		A2($rtfeldman$elm_css$Css$property, 'object-position', 'right')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$src('/assets/images/login.jpg'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$width(1000),
																		$rtfeldman$elm_css$Html$Styled$Attributes$height(1000)
																	]),
																_List_Nil)
															]))
													])),
												content
											]))
									]))))
					]))
			]));
};
var $author$project$Page$Login$GotCheckboxToggle = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$GotFormMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$GotInput = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Login$SubmittedForm = {$: 3};
var $rtfeldman$elm_css$Html$Styled$Attributes$action = function (uri) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'action', uri);
};
var $author$project$CsrfToken$input = function (_v0) {
	var str = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$input,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('hidden'),
				$rtfeldman$elm_css$Html$Styled$Attributes$name('_token'),
				$rtfeldman$elm_css$Html$Styled$Attributes$value(str)
			]),
		_List_Nil);
};
var $author$project$Page$Login$View$fieldError = function (error) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
				$rtfeldman$elm_css$Css$fontSize(
				$rtfeldman$elm_css$Css$rem(0.75)),
				$rtfeldman$elm_css$Css$marginTop(
				$rtfeldman$elm_css$Css$rem(0.25)),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(error)
			]));
};
var $author$project$Page$Login$View$fieldErrors = function (errors) {
	if (!errors.b) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Page$Login$View$fieldError, errors));
	}
};
var $author$project$Page$Login$View$field = F3(
	function (label, errors, content) {
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Form$label(label),
					A2(
					$author$project$SE$UI$Form$control,
					false,
					_List_fromArray(
						[content])),
					$author$project$Page$Login$View$fieldErrors(errors)
				]));
	});
var $author$project$SE$UI$Form$Input$withName = F2(
	function (name_, input) {
		switch (input.$) {
			case 0:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Text(
					_Utils_update(
						rec,
						{hT: name_}));
			case 1:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Number(
					_Utils_update(
						rec,
						{hT: name_}));
			case 2:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Textarea(
					_Utils_update(
						rec,
						{hT: name_}));
			case 3:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Date(
					_Utils_update(
						rec,
						{hT: name_}));
			case 4:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Password(
					_Utils_update(
						rec,
						{hT: name_}));
			case 5:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Select(
					_Utils_update(
						rec,
						{hT: name_}));
			case 7:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Email(
					_Utils_update(
						rec,
						{hT: name_}));
			case 8:
				var rec = input.a;
				return $author$project$SE$UI$Form$Input$Tel(
					_Utils_update(
						rec,
						{hT: name_}));
			default:
				var type_ = input.a;
				var rec = input.b;
				return A2(
					$author$project$SE$UI$Form$Input$Button,
					type_,
					_Utils_update(
						rec,
						{hT: name_}));
		}
	});
var $author$project$Page$Login$View$passwordField = function (_v0) {
	var label = _v0.cB;
	var value = _v0.iY;
	var name = _v0.hT;
	var msg = _v0.cJ;
	var errors = _v0.hd;
	var mods = $elm$core$List$isEmpty(errors) ? _List_Nil : _List_fromArray(
		[$author$project$SE$UI$Form$Input$Danger]);
	return A3(
		$author$project$Page$Login$View$field,
		label,
		errors,
		$author$project$SE$UI$Form$Input$toHtml(
			A2(
				$author$project$SE$UI$Form$Input$withModifiers,
				mods,
				A2(
					$author$project$SE$UI$Form$Input$withName,
					name,
					$author$project$SE$UI$Form$Input$withRequired(
						A2(
							$author$project$SE$UI$Form$Input$withTrigger,
							0,
							A2($author$project$SE$UI$Form$Input$password, msg, value)))))));
};
var $author$project$Page$Login$View$textField = function (_v0) {
	var label = _v0.cB;
	var value = _v0.iY;
	var name = _v0.hT;
	var msg = _v0.cJ;
	var errors = _v0.hd;
	var mods = $elm$core$List$isEmpty(errors) ? _List_Nil : _List_fromArray(
		[$author$project$SE$UI$Form$Input$Danger]);
	return A3(
		$author$project$Page$Login$View$field,
		label,
		errors,
		$author$project$SE$UI$Form$Input$toHtml(
			A2(
				$author$project$SE$UI$Form$Input$withModifiers,
				mods,
				A2(
					$author$project$SE$UI$Form$Input$withName,
					name,
					$author$project$SE$UI$Form$Input$withRequired(
						A2(
							$author$project$SE$UI$Form$Input$withTrigger,
							0,
							A2($author$project$SE$UI$Form$Input$text, msg, value)))))));
};
var $author$project$Page$Login$viewSubmitButton = F2(
	function (isSending, errors) {
		var _v0 = _Utils_eq(errors, $author$project$Page$Login$noErrors) ? (isSending ? {
			ct: true,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			dd: 'Loggar in...'
		} : {
			ct: false,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			dd: 'Logga in'
		}) : {
			ct: true,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(12)
				]),
			dd: 'Rätta felen innan du loggar in'
		};
		var disabled = _v0.ct;
		var txt = _v0.dd;
		var mods = _v0.cI;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Buttons$buttons,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$input,
							A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(txt),
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disabled)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$Login$viewForm = F6(
	function (csrfToken, isSending, message, inputs, errors, errorMsg) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Page$Login$SubmittedForm),
					$rtfeldman$elm_css$Html$Styled$Attributes$method('post'),
					$rtfeldman$elm_css$Html$Styled$Attributes$action(
					$author$project$Route$toString($author$project$Route$Login))
				]),
			_List_fromArray(
				[
					$author$project$CsrfToken$input(csrfToken),
					$author$project$SE$UI$Title$title1('Logga in - Partnerzon'),
					$author$project$Page$viewMessage(message),
					$author$project$Page$viewNonDismissableErrorNotification(errorMsg),
					$author$project$Page$Login$View$textField(
					{
						hd: errors.be,
						cB: 'E-mail',
						cJ: A2(
							$elm$core$Basics$composeL,
							$author$project$Page$Login$GotFormMsg,
							$author$project$Page$Login$GotInput(
								F2(
									function (value, inputs_) {
										return _Utils_update(
											inputs_,
											{be: value});
									}))),
						hT: 'email',
						iY: inputs.be
					}),
					$author$project$Page$Login$View$passwordField(
					{
						hd: errors.an,
						cB: 'Lösenord',
						cJ: A2(
							$elm$core$Basics$composeL,
							$author$project$Page$Login$GotFormMsg,
							$author$project$Page$Login$GotInput(
								F2(
									function (value, inputs_) {
										return _Utils_update(
											inputs_,
											{an: value});
									}))),
						hT: 'password',
						iY: inputs.an
					}),
					A2(
					$author$project$SE$UI$Form$field,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Form$Input$toHtml(
							A3(
								$author$project$SE$UI$Form$Input$checkbox,
								$author$project$Page$Login$GotFormMsg(
									$author$project$Page$Login$GotCheckboxToggle(
										function (inputs_) {
											return _Utils_update(
												inputs_,
												{b4: !inputs_.b4});
										})),
								'Kom ihåg mig',
								inputs.b4))
						])),
					A2($author$project$Page$Login$viewSubmitButton, isSending, errors),
					A2(
					$author$project$SE$UI$Form$field,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Content$content,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$a,
											_List_fromArray(
												[
													$author$project$Route$href($author$project$Route$RequestNewPassword)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Glömt lösenord?')
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('I vår Partnerzon kan du som registrerad partner beställa produkter, spåra leveranser samt se orderhistorik och fakturor.')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Vill du bli återförsäljare för några av världens främsta varumärken inom AV-teknik, CATV och fiberoptik? Kontakta någon av våra '),
											A2(
											$rtfeldman$elm_css$Html$Styled$a,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Constants$siteUrl + '/kontakta-oss')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('säljare')
												])),
											$rtfeldman$elm_css$Html$Styled$text('.')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Login$viewStatus = F3(
	function (csrfToken, message, status) {
		switch (status.$) {
			case 0:
				var inputs = status.a;
				return A6($author$project$Page$Login$viewForm, csrfToken, false, message, inputs, $author$project$Page$Login$noErrors, '');
			case 1:
				var inputs = status.a;
				var errors = status.b;
				var errorMsg = status.c;
				return A6($author$project$Page$Login$viewForm, csrfToken, false, message, inputs, errors, errorMsg);
			case 2:
				var inputs = status.a;
				return A6($author$project$Page$Login$viewForm, csrfToken, false, message, inputs, $author$project$Page$Login$noErrors, '');
			default:
				var inputs = status.a;
				return A6($author$project$Page$Login$viewForm, csrfToken, true, message, inputs, $author$project$Page$Login$noErrors, '');
		}
	});
var $author$project$Page$Login$viewUserLogin = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('This should never have happened'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('As a logged in user, you should never end up on this page. Please contact us so that we can make sure others won\'t be stuck at this dead end as well.')
									]))
							]))
					]))
			])),
	gr: 'Wow, how did you end up here?'
};
var $author$project$Page$Login$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return {
			eN: $author$project$Page$Login$View$frame(
				A3(
					$author$project$Page$Login$viewStatus,
					$author$project$Session$userInterface(
						$author$project$Page$Login$toSession(model)).g0,
					model.fx,
					model.gh)),
			gr: 'Logga in'
		};
	} else {
		return $author$project$Page$Login$viewUserLogin;
	}
};
var $author$project$Page$MainCategory$viewFailed = function (error) {
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title1('Kategorin kunde inte hämtas.'),
							A2(
							$author$project$SE$UI$Notification$danger,
							$elm$core$Maybe$Nothing,
							_List_fromArray(
								[
									$author$project$Api$errorToHtml(error)
								]))
						]))
				])),
		gr: 'Kategorin kunde inte hämtas.'
	};
};
var $author$project$Breadcrumb$MainCategory = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$MainCategory$childStyles = _List_fromArray(
	[
		A4(
		$rtfeldman$elm_css$Css$boxShadow4,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$px(4),
		$rtfeldman$elm_css$Css$px(10),
		$author$project$SE$UI$Colors$toCss(
			A2(
				$author$project$SE$UI$Colors$mapAlpha,
				$elm$core$Basics$always(0.15),
				$author$project$SE$UI$Colors$black))),
		$rtfeldman$elm_css$Css$padding(
		$rtfeldman$elm_css$Css$rem(1)),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$a(
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
					]))
			]))
	]);
var $author$project$Page$MainCategory$viewLi = F2(
	function (mainCategorySlug, tree) {
		var label = $zwilias$elm_rosetree$Tree$label(tree);
		var name = $author$project$Category$name(label);
		var slug = $author$project$Category$slug(label);
		var id = $author$project$Category$id(label);
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							A3(
								$author$project$Route$Category,
								$author$project$QueryParams$empty,
								mainCategorySlug,
								_Utils_Tuple2(id, slug)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						]))
				]));
	});
var $author$project$Page$MainCategory$viewChild = F2(
	function (mainCategorySlug, tree) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Page$MainCategory$childStyles,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							A3(
								$author$project$Route$Category,
								$elm$core$Dict$empty,
								mainCategorySlug,
								_Utils_Tuple2(
									$author$project$Category$id(
										$zwilias$elm_rosetree$Tree$label(tree)),
									$author$project$Category$slug(
										$zwilias$elm_rosetree$Tree$label(tree)))))
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title5(
							$author$project$Category$name(
								$zwilias$elm_rosetree$Tree$label(tree)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Page$MainCategory$viewLi(mainCategorySlug),
						$zwilias$elm_rosetree$Tree$children(tree)))
				]));
	});
var $author$project$SE$UI$Icon$V2$Rotate = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Global$everything = function (styles) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil));
};
var $rtfeldman$elm_css$Html$Styled$i = $rtfeldman$elm_css$Html$Styled$node('i');
var $author$project$Sticker$viewButtonIcon = function (sticker) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$i,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(
				$author$project$Sticker$color(sticker)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$invert(
					$author$project$Sticker$color(sticker))),
				$rtfeldman$elm_css$Css$borderRadius(
				$rtfeldman$elm_css$Css$pct(50)),
				$rtfeldman$elm_css$Css$marginRight(
				$rtfeldman$elm_css$Css$rem(0.5)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$rem(2)),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$rem(2))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(
				$author$project$Sticker$icon(sticker))
			]));
};
var $author$project$Sticker$viewButton = F2(
	function (href, sticker) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightBlue),
					A3(
					$rtfeldman$elm_css$Css$border3,
					$rtfeldman$elm_css$Css$px(1),
					$rtfeldman$elm_css$Css$solid,
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(0.5),
					$rtfeldman$elm_css$Css$rem(0.75)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$Global$children(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$everything(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$lastChild(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto)
										]))
								]))
						])),
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
					$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
					$rtfeldman$elm_css$Css$hover(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
							$author$project$SE$UI$Colors$backgroundColor(
							$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$lightBlue)),
							$author$project$SE$UI$Colors$borderColor(
							$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$border)),
							$rtfeldman$elm_css$Css$Transitions$transition(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Transitions$backgroundColor(250)
								]))
						])),
					$rtfeldman$elm_css$Css$active(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
							$author$project$SE$UI$Colors$backgroundColor(
							$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$lightBlue)),
							$author$project$SE$UI$Colors$borderColor(
							$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$border))
						])),
					$author$project$SE$UI$Font$bodySizeRem(-2),
					$rtfeldman$elm_css$Css$fontWeight(
					$rtfeldman$elm_css$Css$int(600)),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(100)),
					$author$project$SE$UI$Utils$tablet(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$maxWidth(
							$rtfeldman$elm_css$Css$px(200))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$href(href),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('sticker-button')
				]),
			_List_fromArray(
				[
					$author$project$Sticker$viewButtonIcon(sticker),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Sticker$plural(sticker))
						])),
					$author$project$SE$UI$Icon$V2$toHtml(
					A2(
						$author$project$SE$UI$Icon$V2$withTransform,
						$author$project$SE$UI$Icon$V2$Rotate(-90),
						$author$project$SE$UI$Icon$V2$angleDown))
				]));
	});
var $author$project$Page$MainCategory$viewStickers = function (mainSlug) {
	var viewButton = function (sticker) {
		return A2(
			$author$project$Sticker$viewButton,
			$author$project$Route$toString(
				A3($author$project$Route$Sticker, $author$project$QueryParams$empty, mainSlug, sticker)),
			sticker);
	};
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$block,
				$rtfeldman$elm_css$Css$displayFlex,
				A2($rtfeldman$elm_css$Css$property, 'gap', '0.5rem'),
				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
				$author$project$SE$UI$Utils$tablet(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$unset)
					])),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'sticker-button',
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
								$rtfeldman$elm_css$Css$hover(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$backgroundColor(
										$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
									])),
								$rtfeldman$elm_css$Css$active(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$backgroundColor(
										$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$white))
									]))
							]))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				viewButton(0),
				viewButton(2),
				viewButton(1)
			]));
};
var $author$project$Page$MainCategory$viewLoaded = function (tree) {
	var cat = $zwilias$elm_rosetree$Tree$label(tree);
	var name = $author$project$Category$name(cat);
	var slug = $author$project$Category$slug(cat);
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Breadcrumb$toHtml(
							$author$project$Breadcrumb$MainCategory(
								$zwilias$elm_rosetree$Tree$label(tree))),
							$author$project$SE$UI$Title$title1(name),
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$elm$core$Basics$never,
							$author$project$MD$fromString(
								$author$project$Category$description(cat))),
							$author$project$Page$MainCategory$viewStickers(slug),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$author$project$SE$UI$Utils$block,
									A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
									A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1rem'),
									A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fit, minmax(300px, 1fr))')
								]),
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Page$MainCategory$viewChild(slug),
								$zwilias$elm_rosetree$Tree$children(tree)))
						]))
				])),
		gr: name
	};
};
var $author$project$Page$MainCategory$viewLoading = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Loading),
						$author$project$SE$UI$Title$title1('Hämtar kategorin...')
					]))
			])),
	gr: 'Hämtar kategorin...'
};
var $author$project$Page$MainCategory$view = function (model) {
	var _v0 = model.by;
	switch (_v0.$) {
		case 0:
			return $author$project$Page$MainCategory$viewLoading;
		case 1:
			var content = _v0.a;
			return $author$project$Page$MainCategory$viewLoaded(content);
		case 2:
			var content = _v0.a;
			return $author$project$Page$MainCategory$viewLoaded(content);
		default:
			var error = _v0.a;
			return $author$project$Page$MainCategory$viewFailed(error);
	}
};
var $author$project$Page$NotFound$view = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_fromArray(
					[1]),
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1.5))
							]),
						_List_Nil,
						_List_fromArray(
							[
								$author$project$SE$UI$Title$title1('Sidan kan inte hittas'),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vi ber verkligen om ursäkt, men det var nog inte denna sida du förväntade dig. Försök använda sökrutan, eller gå till Partnerzonens '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$author$project$Route$href($author$project$Route$Home)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('startsida')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						$author$project$SE$UI$Columns$columns(
						_List_fromArray(
							[
								$author$project$SE$UI$Columns$defaultColumn(
								_List_fromArray(
									[
										$author$project$Contactbox$view(
										{
											bI: '',
											eM: 'Säljavdelningen',
											fc: $elm$core$Maybe$Just('/assets/images/innesalj.jpg'),
											fJ: '30',
											f_: _List_fromArray(
												['order']),
											c5: '',
											gr: 'Behöver du hjälp? Hör av dig!'
										})
									]))
							]))
					]))
			])),
	gr: 'Sidan kan inte hittas'
};
var $author$project$Page$Order$orderToTitleHelper = function (order) {
	var purchaseNumber = $author$project$Order$internals(order).i0;
	return 'Order ' + ($author$project$Order$displayId(order) + ((purchaseNumber === '') ? '' : (' / ' + purchaseNumber)));
};
var $author$project$Page$Order$orderToTitle = function (order) {
	switch (order.$) {
		case 0:
			return 'Laddar...';
		case 3:
			return 'Ett fel uppstod...';
		case 2:
			var order_ = order.a;
			return $author$project$Page$Order$orderToTitleHelper(order_);
		default:
			var order_ = order.a;
			return $author$project$Page$Order$orderToTitleHelper(order_);
	}
};
var $author$project$Breadcrumb$Order = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Order$viewBreadcrumbs = function (status) {
	switch (status.$) {
		case 0:
			return $author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Loading);
		case 3:
			return A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil);
		case 2:
			var order = status.a;
			return $author$project$Breadcrumb$toHtml(
				$author$project$Breadcrumb$Order(order));
		default:
			var order = status.a;
			return $author$project$Breadcrumb$toHtml(
				$author$project$Breadcrumb$Order(order));
	}
};
var $author$project$SE$UI$Columns$ThreeQuarters = 10;
var $author$project$Order$body = function (order) {
	if (!order.$) {
		var bd = order.b;
		return bd;
	} else {
		var bd = order.c;
		return bd;
	}
};
var $author$project$SE$UI$Notification$link = $author$project$SE$UI$Notification$internalNotification($author$project$SE$UI$Colors$link);
var $author$project$Order$status = function (order) {
	if (!order.$) {
		return 0;
	} else {
		var internals_ = order.b;
		return internals_.gh;
	}
};
var $author$project$Page$Order$viewDeliveryAddress = F2(
	function (address, modeOfTransport) {
		var metadata = function () {
			if (!address.$) {
				var metadata_ = address.c;
				return metadata_;
			} else {
				var metadata_ = address.a;
				return metadata_;
			}
		}();
		var content = (modeOfTransport === 'Hämtas') ? _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Hämtas')
					]))
			]) : _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(metadata.iv)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(metadata.iw)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(metadata.ix)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(metadata.iy)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(metadata.ip)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Country$name(metadata.g_))
					]))
			]);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				$author$project$SE$UI$Title$title6('Leveransadress'),
				content));
	});
var $author$project$Page$Order$viewMissingUrlInvoice = function (name) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[$author$project$SE$UI$Utils$block]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Vi saknar PDF för ' + (name + '.'))
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$author$project$SE$UI$Font$bodySizeRem(-2),
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Kontakta '),
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:info@specialelektronik.se?subject=Ang. faktura ' + name)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('info@specialelektronik.se')
							])),
						$rtfeldman$elm_css$Html$Styled$text(' för att ta del av fakturan.')
					]))
			]));
};
var $author$project$Page$Order$viewInvoice = function (_v0) {
	var url = _v0.cg;
	var name = _v0.hT;
	return (url === '') ? $author$project$Page$Order$viewMissingUrlInvoice(name) : A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$author$project$SE$UI$Utils$block
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$href(url),
				$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
			]),
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(
				A2($author$project$SE$UI$Icon$V2$withSize, 2, $author$project$SE$UI$Icon$V2$pdf)),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(name)
					]))
			]));
};
var $author$project$OrderRow$backorderedQuantity = function (_v0) {
	var rec = _v0;
	return rec.ew;
};
var $author$project$OrderRow$basePrice = function (_v0) {
	var rec = _v0;
	return rec.ex;
};
var $author$project$OrderRow$deliveredQuantity = function (_v0) {
	var rec = _v0;
	return rec.eR;
};
var $author$project$OrderRow$deliveryDate = function (_v0) {
	var rec = _v0;
	return rec.eS;
};
var $author$project$OrderRow$externalId = function (_v0) {
	var rec = _v0;
	return rec.hh;
};
var $author$project$Helpers$formatToDiscount = function (amount) {
	return $author$project$Helpers$format(amount * 100) + ($author$project$Helpers$nbr + '%');
};
var $author$project$OrderRow$orderedQuantity = function (_v0) {
	var rec = _v0;
	return rec.fG;
};
var $author$project$OrderRow$price = function (_v0) {
	var rec = _v0;
	return rec.cR;
};
var $author$project$OrderRow$unit = function (_v0) {
	var rec = _v0;
	return rec.em;
};
var $author$project$Page$Order$viewAmount = F2(
	function (vat, amount) {
		return _List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$author$project$SE$UI$Font$bodySizeRem(2),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600)),
						$rtfeldman$elm_css$Css$lineHeight(
						$rtfeldman$elm_css$Css$int(1))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Helpers$formatToMoney(amount))
					])),
				A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
						$author$project$SE$UI$Font$bodySizeRem(-3),
						$rtfeldman$elm_css$Css$lineHeight(
						$rtfeldman$elm_css$Css$int(1))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$VAT$toLabel(vat))
					]))
			]);
	});
var $author$project$Page$Order$viewDeliveryDate = F2(
	function (timezone, maybeDeliveryDate) {
		if (maybeDeliveryDate.$ === 1) {
			return $rtfeldman$elm_css$Html$Styled$text('');
		} else {
			var deliveryDate = maybeDeliveryDate.a;
			return $rtfeldman$elm_css$Html$Styled$text(
				A2($author$project$Helpers$viewDate, deliveryDate, timezone));
		}
	});
var $author$project$OrderRow$productImage = function (_v0) {
	var rec = _v0;
	return rec.fS;
};
var $author$project$Page$Order$viewProductImage = function (row) {
	var imageConfig = A2(
		$author$project$SE$UI$Image$V2$withAspectRatio,
		_Utils_Tuple2(1, 1),
		$author$project$SE$UI$Image$V2$create(
			{e7: 75, gE: 75}));
	var _v0 = $author$project$OrderRow$productImage(row);
	if (_v0.$ === 1) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var image = _v0.a;
		return A4(
			$author$project$Image$viewCustom,
			imageConfig,
			0,
			$author$project$ExternalId$toString(
				$author$project$OrderRow$externalId(row)),
			image);
	}
};
var $author$project$OrderRow$name = function (_v0) {
	var rec = _v0;
	return rec.hT;
};
var $author$project$OrderRow$productLink = function (_v0) {
	var rec = _v0;
	return A2(
		$elm$core$Maybe$map,
		$author$project$Route$Product($author$project$QueryParams$empty),
		A3($elm$core$Maybe$map2, $elm$core$Tuple$pair, rec.cT, rec.fT));
};
var $author$project$Page$Order$viewProductName = function (row) {
	var content = A2(
		$rtfeldman$elm_css$Html$Styled$strong,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				$author$project$OrderRow$name(row))
			]));
	var _v0 = $author$project$OrderRow$productLink(row);
	if (_v0.$ === 1) {
		return content;
	} else {
		var link = _v0.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$author$project$Route$href(link)
				]),
			_List_fromArray(
				[content]));
	}
};
var $author$project$Page$Order$viewQuantity = F2(
	function (quantity, unit) {
		return (!quantity) ? $rtfeldman$elm_css$Html$Styled$text('') : $rtfeldman$elm_css$Html$Styled$text(
			_Utils_ap(
				$elm$core$String$fromFloat(quantity),
				_Utils_ap(
					$author$project$Helpers$nbr,
					A2($author$project$Unit$toSwedishString, quantity, unit))));
	});
var $author$project$OrderRow$serialNumber = function (_v0) {
	var rec = _v0;
	return rec.f8;
};
var $author$project$Page$Order$viewSerialNumber = function (internals) {
	var _v0 = $author$project$OrderRow$serialNumber(internals);
	if (_v0 === '') {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var serialNumber = _v0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Serienummer: ')
						])),
					$rtfeldman$elm_css$Html$Styled$text(serialNumber)
				]));
	}
};
var $author$project$OrderRow$source = function (_v0) {
	var rec = _v0;
	return rec.ge;
};
var $author$project$Page$Order$viewSource = function (internals) {
	var _v0 = $author$project$OrderRow$source(internals);
	if (!_v0.$) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var id = _v0.a;
		var externalId = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('BID: ')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							$author$project$Route$Bid(id))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$ExternalId$toString(externalId))
						]))
				]));
	}
};
var $author$project$Page$Order$viewRegularRow = F2(
	function (timezone, internals) {
		var unit = $author$project$OrderRow$unit(internals);
		var price = $author$project$OrderRow$price(internals);
		var orderedQuantity = $author$project$OrderRow$orderedQuantity(internals);
		var basePrice = $author$project$OrderRow$basePrice(internals);
		var discount = 1 - (price / (basePrice * orderedQuantity));
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$width(75)
						]),
					_List_fromArray(
						[
							$author$project$Page$Order$viewProductImage(internals)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Page$Order$viewProductName(internals)
										])),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
										]),
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$author$project$ExternalId$toString(
												$author$project$OrderRow$externalId(internals)))
										])),
									$author$project$Page$Order$viewSerialNumber(internals),
									$author$project$Page$Order$viewSource(internals)
								]))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Page$Order$viewDeliveryDate,
							timezone,
							$author$project$OrderRow$deliveryDate(internals))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Page$Order$viewQuantity, orderedQuantity, unit)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Page$Order$viewQuantity,
							$author$project$OrderRow$deliveredQuantity(internals),
							unit)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Page$Order$viewQuantity,
							$author$project$OrderRow$backorderedQuantity(internals),
							unit)
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					A2($author$project$Page$Order$viewAmount, 1, basePrice)),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Helpers$formatToDiscount(discount))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
						]),
					_List_Nil,
					A2(
						$author$project$Page$Order$viewAmount,
						1,
						(basePrice * $author$project$OrderRow$orderedQuantity(internals)) * (1 - discount)))
				]));
	});
var $author$project$Page$Order$viewTextRow = function (name) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$colspan(9)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(name)
					]))
			]));
};
var $author$project$Page$Order$viewOrderRow = F2(
	function (timezone, row) {
		if (!row.$) {
			var name = row.a;
			return $author$project$Page$Order$viewTextRow(name);
		} else {
			var internals = row.a;
			return A2($author$project$Page$Order$viewRegularRow, timezone, internals);
		}
	});
var $author$project$SE$UI$Icon$V2$ban = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'])));
var $author$project$SE$UI$Icon$V2$checkCircle = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z'])));
var $author$project$Page$Order$orderStatusToString = function (status_) {
	switch (status_) {
		case 0:
			return 'Ej hanterad än...';
		case 1:
			return 'Ej skickad';
		case 3:
			return 'Makulerad';
		case 2:
			return 'Skickad';
		case 4:
			return 'Restorder';
		case 5:
			return 'Internorder';
		case 6:
			return 'Pågående';
		default:
			return 'Avslutad';
	}
};
var $author$project$Page$Order$viewOrderStatus = function (status) {
	var _v0 = function () {
		switch (status) {
			case 1:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$checkCircle, $author$project$SE$UI$Colors$darkGreen);
			case 0:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$clock, $author$project$SE$UI$Colors$buy);
			case 3:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$ban, $author$project$SE$UI$Colors$danger);
			case 2:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$truck, $author$project$SE$UI$Colors$primary);
			case 4:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$clock, $author$project$SE$UI$Colors$primary);
			case 5:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$checkCircle, $author$project$SE$UI$Colors$dark);
			case 6:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$checkCircle, $author$project$SE$UI$Colors$darkGreen);
			default:
				return _Utils_Tuple2($author$project$SE$UI$Icon$V2$checkCircle, $author$project$SE$UI$Colors$primary);
		}
	}();
	var icon = _v0.a;
	var color = _v0.b;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(color),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$invert(color)),
				$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
				$rtfeldman$elm_css$Css$fontWeight(
				$rtfeldman$elm_css$Css$int(600)),
				$author$project$SE$UI$Font$bodySizeRem(-2),
				A2(
				$rtfeldman$elm_css$Css$padding2,
				$rtfeldman$elm_css$Css$px(9),
				$rtfeldman$elm_css$Css$px(12)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(icon),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$px(4))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Page$Order$orderStatusToString(status))
					]))
			]));
};
var $author$project$Package$forwarderName = function (_v0) {
	var forwarder = _v0.cx;
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.hT;
		},
		forwarder);
};
var $author$project$Package$forwarderShortName = function (_v0) {
	var forwarder = _v0.cx;
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.f9;
		},
		forwarder);
};
var $author$project$Page$Order$viewTrackingLogo = function (_package) {
	var wrapper = function (content) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$px(75)),
					$rtfeldman$elm_css$Css$margin(
					$rtfeldman$elm_css$Css$em(0.75))
				]),
			_List_Nil,
			_List_fromArray(
				[content]));
	};
	var _v0 = $author$project$Package$forwarderShortName(_package);
	if (_v0.$ === 1) {
		return wrapper(
			$author$project$SE$UI$Icon$V2$toHtml(
				A2($author$project$SE$UI$Icon$V2$withSize, 2, $author$project$SE$UI$Icon$V2$truck)));
	} else {
		var shortName = _v0.a;
		return wrapper(
			A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$maxWidth(
						$rtfeldman$elm_css$Css$px(75))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$src('/assets/app/images/' + (shortName + '.svg'))
					]),
				_List_Nil));
	}
};
var $author$project$Package$number = function (_v0) {
	var rec = _v0;
	return rec.fD;
};
var $author$project$Package$trackingUrl = function (_v0) {
	var forwarder = _v0.cx;
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.gs;
		},
		forwarder);
};
var $author$project$Page$Order$viewTrackingUrl = function (_package) {
	var _v0 = $author$project$Package$trackingUrl(_package);
	if (_v0.$ === 1) {
		return $rtfeldman$elm_css$Html$Styled$text(
			$author$project$Package$number(_package));
	} else {
		var url = _v0.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$href(url),
					$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$author$project$Package$number(_package))
				]));
	}
};
var $author$project$Page$Order$viewPackage = function (_package) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$author$project$SE$UI$Utils$block,
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$rem(0.75)),
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 2px 3px rgba(34, 41, 47, 0.1), 0 0 0 1px rgba(34, 41, 47, 0.1)')
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$Order$viewTrackingLogo(_package),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2(
							$elm$core$Maybe$withDefault,
							'Kollinummer',
							$author$project$Package$forwarderName(_package))),
						A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
						$author$project$Page$Order$viewTrackingUrl(_package)
					]))
			]));
};
var $author$project$Page$Order$viewPackages = function (packages) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$rem(1.5))
			]),
		_List_Nil,
		A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Title$title6('Följ din order'),
			A2($elm$core$List$map, $author$project$Page$Order$viewPackage, packages)));
};
var $author$project$OrderRow$chemicalTax = function (_v0) {
	var rec = _v0;
	return rec.dq;
};
var $author$project$Order$chemicalTax = function (order) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (row, tot) {
				if (!row.$) {
					return tot;
				} else {
					var internals_ = row.a;
					var orderedQuantity = $author$project$OrderRow$orderedQuantity(internals_);
					var chemicalTax_ = $author$project$OrderRow$chemicalTax(internals_);
					return tot + (chemicalTax_ * orderedQuantity);
				}
			}),
		0,
		$author$project$Order$body(order).cY);
};
var $author$project$Order$sum = function (order) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (row, tot) {
				if (!row.$) {
					return tot;
				} else {
					var internals_ = row.a;
					return tot + $author$project$OrderRow$price(internals_);
				}
			}),
		0,
		$author$project$Order$body(order).cY);
};
var $rtfeldman$elm_css$Html$Styled$h4 = $rtfeldman$elm_css$Html$Styled$node('h4');
var $author$project$SE$UI$Title$title4 = A2(
	$author$project$SE$UI$Title$internalTitle,
	$rtfeldman$elm_css$Html$Styled$h4,
	_List_fromArray(
		[
			$author$project$SE$UI$Font$titleSizeEm(3)
		]));
var $author$project$OrderRow$vatAmount = function (_v0) {
	var rec = _v0;
	return rec.gB;
};
var $author$project$Order$vat = function (order) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (row, tot) {
				if (!row.$) {
					return tot;
				} else {
					var internals_ = row.a;
					return tot + $author$project$OrderRow$vatAmount(internals_);
				}
			}),
		0,
		$author$project$Order$body(order).cY);
};
var $author$project$Page$Order$viewSummary = function (order) {
	var sumVat = $author$project$Order$vat(order);
	var sumProducts = $author$project$Order$sum(order);
	var sumChemicalTax = $author$project$Order$chemicalTax(order);
	var rowStyles = _List_fromArray(
		[
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$author$project$SE$UI$Font$bodySizeRem(-1)
		]);
	var hr = A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$hr,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$dark)
			]),
		_List_Nil,
		_List_Nil);
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darker),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
				A2(
				$rtfeldman$elm_css$Css$padding2,
				$rtfeldman$elm_css$Css$em(1.25),
				$rtfeldman$elm_css$Css$em(1.5)),
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$rem(1.5))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Title$title4('Sammanställning'),
				hr,
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						rowStyles,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Pris exkl. moms')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Helpers$formatToMoney(sumProducts))
									]))
							])),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						rowStyles,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Moms')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Helpers$formatToMoney(sumVat))
									]))
							])),
						A2(
						$author$project$Helpers$viewIf,
						sumChemicalTax > 0,
						A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							rowStyles,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Kemikalieskatt')
										])),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$author$project$Helpers$formatToMoney(sumChemicalTax))
										]))
								]))),
						hr,
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						rowStyles,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Totalt')
									])),
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_fromArray(
									[
										$author$project$SE$UI$Font$bodySizeRem(5)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Helpers$formatToMoney((sumProducts + sumVat) + sumChemicalTax))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Order$viewYourRef = function (yourRef) {
	if (!yourRef.$) {
		var name = yourRef.a;
		return $rtfeldman$elm_css$Html$Styled$text(name);
	} else {
		var userdata = yourRef.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:' + userdata.be)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(userdata.hT)
				]));
	}
};
var $author$project$Page$Order$viewOrder = F2(
	function (zone, order) {
		var notice = function () {
			if (!order.$) {
				return A2(
					$author$project$SE$UI$Notification$link,
					$elm$core$Maybe$Nothing,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Denna order har ännu inte registrerats i vårt affärssystem. Detta bör ske inom kort.')
						]));
			} else {
				return $rtfeldman$elm_css$Html$Styled$text('');
			}
		}();
		var internals = $author$project$Order$internals(order);
		var body = $author$project$Order$body(order);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					notice,
					$author$project$SE$UI$Card$toHtml(
					$author$project$SE$UI$Card$withBoxShadow(
						$author$project$SE$UI$Card$content(
							_List_fromArray(
								[
									$author$project$SE$UI$Columns$columns(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													$author$project$Page$Order$viewOrderStatus(
													$author$project$Order$status(order))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													'Beställd ' + (A2($author$project$Helpers$viewDate, internals.g$, zone) + ' av ')),
													$author$project$Page$Order$viewYourRef(internals.i1),
													$rtfeldman$elm_css$Html$Styled$text(' via '),
													A2(
													$rtfeldman$elm_css$Html$Styled$a,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(
															'mailto:order@specialelektronik.se?subject=Ang. order ' + $author$project$Order$displayId(order))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(body.iz)
														]))
												]))
										])),
									A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
									$author$project$SE$UI$Columns$columns(
									_Utils_ap(
										(!$elm$core$List$isEmpty(body.ib)) ? _List_fromArray(
											[
												$author$project$SE$UI$Columns$defaultColumn(
												_List_fromArray(
													[
														$author$project$Page$Order$viewPackages(body.ib)
													]))
											]) : _List_Nil,
										_List_fromArray(
											[
												$author$project$SE$UI$Columns$defaultColumn(
												_List_fromArray(
													[
														A2($author$project$Page$Order$viewDeliveryAddress, internals.g8, body.hR)
													])),
												$author$project$SE$UI$Columns$defaultColumn(
												_List_fromArray(
													[
														$author$project$SE$UI$Title$title6('Leveransdatum'),
														A2(
														$rtfeldman$elm_css$Html$Styled$p,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Se resp. orderrad')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$p,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Leveransdatum avser leverans från vårt lager')
															]))
													])),
												$author$project$SE$UI$Columns$defaultColumn(
												_List_fromArray(
													[
														$author$project$SE$UI$Title$title6('Leveransvillkor'),
														A2(
														$rtfeldman$elm_css$Html$Styled$p,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(body.iQ)
															]))
													])),
												$author$project$SE$UI$Columns$defaultColumn(
												_List_fromArray(
													[
														A4(
														$rtfeldman$elm_css$Html$Styled$styled,
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$marginBottom(
																$rtfeldman$elm_css$Css$rem(1.5))
															]),
														_List_Nil,
														A2(
															$elm$core$List$cons,
															$author$project$SE$UI$Title$title6('Faktura'),
															A2($elm$core$List$map, $author$project$Page$Order$viewInvoice, body.hE)))
													]))
											])))
								])))),
					$author$project$SE$UI$Columns$columns(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Columns$column,
							_List_fromArray(
								[
									_Utils_Tuple2(3, 10)
								]),
							_List_fromArray(
								[
									$author$project$SE$UI$Card$toHtml(
									$author$project$SE$UI$Card$withBoxShadow(
										$author$project$SE$UI$Card$content(
											_List_fromArray(
												[
													A4(
													$rtfeldman$elm_css$Html$Styled$styled,
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$Global$children(
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Css$Global$div(
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Css$important(
																			A2($rtfeldman$elm_css$Css$property, 'margin', 'calc((0.88888888rem + 1px)*-1) calc(-0.88888888rem)')),
																			$rtfeldman$elm_css$Css$important(
																			A2($rtfeldman$elm_css$Css$property, 'max-width', 'calc(100% + 0.88888888rem + 0.88888888rem)')),
																			$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
																			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$auto),
																			$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$hidden)
																		]))
																]))
														]),
													_List_Nil,
													_List_fromArray(
														[
															$author$project$SE$UI$Table$V2$toHtml(
															A2(
																$author$project$SE$UI$Table$V2$withHead,
																_List_fromArray(
																	[
																		A2(
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Produkt')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$title('Beräknat leveransdatum')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Lev. datum')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$title('Beställt antal')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Best')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$title('Levererat antal')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Lev')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$title('Restat antal')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Rest')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Grundpris/st')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Rabatt')
																			])),
																		A4(
																		$rtfeldman$elm_css$Html$Styled$styled,
																		$rtfeldman$elm_css$Html$Styled$th,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																			]),
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('Totalt netto')
																			]))
																	]),
																A2(
																	$author$project$SE$UI$Table$V2$withModifiers,
																	_List_fromArray(
																		[0]),
																	A2(
																		$author$project$SE$UI$Table$V2$body,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$css(
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Css$borderRadius(
																						$rtfeldman$elm_css$Css$px(2)),
																						$rtfeldman$elm_css$Css$Global$children(
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Css$Global$selector,
																								'tbody td',
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
																									]))
																							]))
																					]))
																			]),
																		A2(
																			$elm$core$List$map,
																			$author$project$Page$Order$viewOrderRow(zone),
																			body.cY)))))
														]))
												]))))
								])),
							$author$project$SE$UI$Columns$defaultColumn(
							_List_fromArray(
								[
									$author$project$Page$Order$viewSummary(order)
								]))
						]))
				]));
	});
var $author$project$Page$Order$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		var title = $author$project$Page$Order$orderToTitle(model.h9);
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Page$Order$viewBreadcrumbs(model.h9),
								A2($author$project$Page$viewLoggedInMenu, 5, user),
								$author$project$SE$UI$Title$title1(title),
								A3(
								$author$project$Status$view,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Api$errorToHtml,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
								$author$project$Page$Order$viewOrder(
									$author$project$Session$zone(model.K)),
								model.h9)
							]))
					])),
			gr: title
		};
	}
};
var $author$project$Page$Orders$GotPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Breadcrumb$Orders = {$: 6};
var $author$project$Page$Orders$View$table = F3(
	function (goToPageMsg, timezone, orders) {
		if ($elm$core$List$isEmpty(orders.g2)) {
			return $author$project$Page$Orders$View$emptyTable;
		} else {
			var footerFn = (orders.hJ === 1) ? $elm$core$Basics$identity : $author$project$SE$UI$Table$V2$withFoot(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(6)
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Pagination$V2$toHtml(
								$author$project$SE$UI$Pagination$V2$isCentered(
									$author$project$SE$UI$Pagination$V2$create(
										{g1: orders.g1, hJ: orders.hJ, cJ: goToPageMsg, hY: 'Nästa ⯈', ig: '⯇ Föregående'})))
							]))
					]));
			return $author$project$SE$UI$Table$V2$toHtml(
				footerFn(
					A2($author$project$Page$Orders$View$tableHelper, timezone, orders.g2)));
		}
	});
var $author$project$Page$Orders$viewCustomerCareInfo = $author$project$Contactbox$view(
	{
		bI: '',
		eM: 'Säljavdelningen',
		fc: $elm$core$Maybe$Just('/assets/images/innesalj.jpg'),
		fJ: '30',
		f_: _List_fromArray(
			['order']),
		c5: 'Orderhistorik på Partnerzonen',
		gr: 'Behöver du hjälp? Hör av dig!'
	});
var $author$project$Page$Orders$viewOrderListToggleButton = F3(
	function (label, currentDisplayOrder, scope) {
		var mods = _Utils_eq(currentDisplayOrder, scope) ? _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(10)
			]) : _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(1)
			]);
		return A3(
			$author$project$SE$UI$Buttons$button,
			mods,
			$elm$core$Maybe$Just(
				$author$project$Page$Orders$GotSessionMsg(
					$author$project$Session$UpdatedDocumentScope(scope))),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(label)
				]));
	});
var $author$project$Page$Orders$viewOrderListToggle = function (currentScope) {
	return A2(
		$author$project$SE$UI$Level$level,
		_List_fromArray(
			[
				$author$project$SE$UI$Level$item(
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Buttons$buttons,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[
								A3($author$project$Page$Orders$viewOrderListToggleButton, 'Mina', currentScope, 0),
								A3($author$project$Page$Orders$viewOrderListToggleButton, 'Alla', currentScope, 1)
							]))
					]))
			]),
		_List_Nil);
};
var $author$project$Page$Orders$GotQuery = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Orders$NoOp = {$: 0};
var $author$project$Page$Orders$SubmittedQuery = {$: 5};
var $author$project$Page$Orders$viewSearch = F2(
	function (query, loading) {
		var mods = A2(
			$elm$core$List$cons,
			$author$project$SE$UI$Buttons$Color(9),
			A2(
				$elm$core$List$cons,
				$author$project$SE$UI$Buttons$Size(2),
				loading ? _List_fromArray(
					[$author$project$SE$UI$Buttons$Loading]) : _List_Nil));
		var maybeMsg = function () {
			var _v0 = $elm$core$String$trim(query);
			if (_v0 === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just($author$project$Page$Orders$SubmittedQuery);
			}
		}();
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$rem(0.75))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$form,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onSubmit(
							A2($elm$core$Maybe$withDefault, $author$project$Page$Orders$NoOp, maybeMsg)),
							$rtfeldman$elm_css$Html$Styled$Attributes$method('post')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_fromArray(
								[0, 1]),
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$expandedControl,
									false,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withModifier,
												$author$project$SE$UI$Form$Input$Size(2),
												A2(
													$author$project$SE$UI$Form$Input$withTrigger,
													0,
													A2(
														$author$project$SE$UI$Form$Input$withPlaceholder,
														'Ordernummer',
														A2($author$project$SE$UI$Form$Input$text, $author$project$Page$Orders$GotQuery, query)))))
										])),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$button,
											A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Sök')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Orders$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$viewGuestAccount;
	} else {
		var user = _v0.a;
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Orders),
								A2($author$project$Page$viewLoggedInMenu, 5, user),
								$author$project$SE$UI$Title$title1('Ordrar'),
								$author$project$SE$UI$Card$toHtml(
								$author$project$SE$UI$Card$content(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$columns(
											_List_fromArray(
												[
													$author$project$SE$UI$Columns$defaultColumn(
													_List_fromArray(
														[
															A2(
															$author$project$Page$Orders$viewSearch,
															model.E.d0,
															$author$project$Status$isLoading(model.R)),
															$author$project$Page$Orders$viewOrderListToggle(model.E.c$)
														])),
													A2(
													$author$project$SE$UI$Columns$column,
													_List_fromArray(
														[
															_Utils_Tuple2(3, 1)
														]),
													_List_fromArray(
														[$author$project$Page$Orders$viewCustomerCareInfo]))
												])),
											A3(
											$author$project$Status$view,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Api$errorToHtml,
												A2(
													$elm$core$Basics$composeR,
													$elm$core$List$singleton,
													$author$project$SE$UI$Notification$danger($elm$core$Maybe$Nothing))),
											A2(
												$author$project$Page$Orders$View$table,
												$author$project$Page$Orders$GotPage,
												$author$project$Session$zone(model.K)),
											model.R)
										])))
							]))
					])),
			gr: 'Ordrar'
		};
	}
};
var $author$project$Session$cookieHosts = A2(
	$elm$core$Basics$composeR,
	$author$project$Session$userInterface,
	function ($) {
		return $.eO;
	});
var $author$project$Page$Product$viewFailed = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_fromArray(
					[1]),
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1.5))
							]),
						_List_Nil,
						_List_fromArray(
							[
								$author$project$SE$UI$Title$title1('Produkten kunde inte hittas'),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vi ber verkligen om ursäkt, men det var nog inte denna sida du förväntade dig. Försök använda sökrutan, eller gå till Partnerzonens '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$author$project$Route$href($author$project$Route$Home)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('startsida')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						$author$project$SE$UI$Columns$columns(
						_List_fromArray(
							[
								$author$project$SE$UI$Columns$defaultColumn(
								_List_fromArray(
									[
										$author$project$Contactbox$view(
										{
											bI: '',
											eM: 'Säljavdelningen',
											fc: $elm$core$Maybe$Just('/assets/images/innesalj.jpg'),
											fJ: '30',
											f_: _List_fromArray(
												['order']),
											c5: '',
											gr: 'Behöver du hjälp? Hör av dig!'
										})
									]))
							]))
					]))
			])),
	gr: 'Produkten kunde inte hittas'
};
var $author$project$Breadcrumb$Product = function (a) {
	return {$: 15, a: a};
};
var $rtfeldman$elm_css$Css$Animations$property = F2(
	function (key, value) {
		return key + (':' + value);
	});
var $rtfeldman$elm_css$Css$rowReverse = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{iY: 'row-reverse'});
var $rtfeldman$elm_css$Css$Global$svg = $rtfeldman$elm_css$Css$Global$typeSelector('svg');
var $author$project$Page$Product$sectionStyles = _List_fromArray(
	[
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'loadingBg',
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'background', 'linear-gradient(to right, #e5e6e6 4%, #c8c9cb 18%, #e5e6e6 46%)'),
						A2(
						$rtfeldman$elm_css$Css$backgroundSize2,
						$rtfeldman$elm_css$Css$px(600),
						$rtfeldman$elm_css$Css$px(100)),
						$rtfeldman$elm_css$Css$animationDuration(
						$rtfeldman$elm_css$Css$sec(0.9)),
						A2($rtfeldman$elm_css$Css$property, 'animation-fill-mode', 'forwards'),
						A2($rtfeldman$elm_css$Css$property, 'animation-iteration-count', 'infinite'),
						A2($rtfeldman$elm_css$Css$property, 'animation-timing-function', 'linear'),
						$rtfeldman$elm_css$Css$animationName(
						$rtfeldman$elm_css$Css$Animations$keyframes(
							_List_fromArray(
								[
									_Utils_Tuple2(
									0,
									_List_fromArray(
										[
											A2($rtfeldman$elm_css$Css$Animations$property, 'background-position', '0px 0')
										])),
									_Utils_Tuple2(
									100,
									_List_fromArray(
										[
											A2($rtfeldman$elm_css$Css$Animations$property, 'background-position', '600px 0')
										]))
								])))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'embeds',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$rowReverse)
							])),
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$svg(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$lighter)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'embed',
				_List_fromArray(
					[
						A3(
						$rtfeldman$elm_css$Css$border3,
						$rtfeldman$elm_css$Css$px(1),
						$rtfeldman$elm_css$Css$solid,
						$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$num(1)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100)),
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$class,
								'no-image',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$pct(80)),
										$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
										$rtfeldman$elm_css$Css$marginRight($rtfeldman$elm_css$Css$auto)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'thumbnails',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$order(
						$rtfeldman$elm_css$Css$num(2)),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$px(12)),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
								$rtfeldman$elm_css$Css$paddingTop($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$px(-6))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'thumbnail',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px(60)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(60)),
						$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
						$rtfeldman$elm_css$Css$borderWidth(
						$rtfeldman$elm_css$Css$px(1)),
						$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
						A2(
						$rtfeldman$elm_css$Css$Global$withClass,
						'is-active',
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$light)
							])),
						$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
						$rtfeldman$elm_css$Css$margin(
						$rtfeldman$elm_css$Css$px(6)),
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$svg(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										A3(
											$rtfeldman$elm_css$Css$calc,
											$rtfeldman$elm_css$Css$pct(100),
											$rtfeldman$elm_css$Css$minus,
											$rtfeldman$elm_css$Css$px(16)))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'video-thumbnail',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$borderWidth(
						$rtfeldman$elm_css$Css$px(0))
					]))
			]))
	]);
var $author$project$Analytics$RelatedProducts = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Products$View$listStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'product',
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$withClass,
						'placeholder',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$px(150))
							]))
					]))
			]))
	]);
var $author$project$Page$Products$View$viewListPriceAndAddToCart = F4(
	function (toSessionMsg, analyticsSource, product, user) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(100)),
					$rtfeldman$elm_css$Css$displayFlex,
					A2($rtfeldman$elm_css$Css$property, 'gap', '0.75rem'),
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$px(16)),
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right),
							$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset)
						]))
				]),
			_List_Nil,
			function () {
				var _v0 = $author$project$Product$prices(product);
				if (_v0.$ === 1) {
					return _List_fromArray(
						[
							$author$project$Product$viewMissingPrice(
							$author$project$Product$externalId(product))
						]);
				} else {
					if (!_v0.a.$) {
						var _v1 = _v0.a;
						return _List_fromArray(
							[
								$author$project$Product$viewMissingPrice(
								$author$project$Product$externalId(product))
							]);
					} else {
						var data = _v0.a.a;
						var metadata = $author$project$Product$metadata(product);
						return _List_fromArray(
							[
								A4($author$project$Price$viewSmallTag, metadata.d8, user.c0.gA, user.c0.ga, data),
								A4($author$project$Page$Products$View$viewAddToCart, toSessionMsg, analyticsSource, user, product)
							]);
					}
				}
			}());
	});
var $author$project$Page$Products$View$viewProductsListImage = F2(
	function (_v0, maybeImage) {
		var productId = _v0.a;
		var productSlug = _v0.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginRight(
					$rtfeldman$elm_css$Css$px(16)),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$px(60)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$px(60)),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$px(80)),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(80))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							A2(
								$author$project$Route$Product,
								$author$project$QueryParams$empty,
								_Utils_Tuple2(productId, productSlug)))
						]),
					_List_fromArray(
						[
							A3($author$project$Image$viewMaybeThumbnail, 80, '', maybeImage)
						]))
				]));
	});
var $author$project$Page$Products$View$listItem = F5(
	function (toSessionMsg, analyticsSource, maybeUser, index, _v0) {
		var product = _v0.b2;
		var productSlug = $author$project$Product$slug(product);
		var productId = $author$project$Product$id(product);
		var metadata = $author$project$Product$metadata(product);
		var maybeImage = function () {
			var _v1 = $elm$core$List$head(metadata.fd);
			if (_v1.$ === 1) {
				return $author$project$Brand$logo(metadata.dp);
			} else {
				var other = _v1;
				return other;
			}
		}();
		return _List_fromArray(
			[
				A2(
				$author$project$Page$Products$View$viewProductsListImage,
				_Utils_Tuple2(productId, productSlug),
				maybeImage),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$width(
						A3(
							$rtfeldman$elm_css$Css$calc,
							$rtfeldman$elm_css$Css$pct(100),
							$rtfeldman$elm_css$Css$minus,
							$rtfeldman$elm_css$Css$px(76))),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset),
								$rtfeldman$elm_css$Css$flexBasis(
								$rtfeldman$elm_css$Css$pct(25)),
								$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$marginRight(
								$rtfeldman$elm_css$Css$px(16))
							]))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Page$Products$View$viewBrand(metadata.dp),
						$author$project$Page$Products$View$viewName(product),
						$author$project$Page$Products$View$viewExternalId(
						$author$project$Product$externalId(product))
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
						$author$project$SE$UI$Utils$desktop(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$unset),
								$rtfeldman$elm_css$Css$marginRight(
								$rtfeldman$elm_css$Css$px(16))
							]))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Page$Products$View$viewAttributes(metadata.gQ)
					])),
				A2(
				$author$project$Helpers$viewMaybe,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Page$Products$View$viewDatasheet,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						A3(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
									$author$project$SE$UI$Utils$desktop(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$unset),
											$rtfeldman$elm_css$Css$marginRight(
											$rtfeldman$elm_css$Css$px(16))
										]))
								]),
							_List_Nil))),
				metadata.hm),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto)
					]),
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Page$Products$View$viewAvailability,
						$author$project$Product$externalId(product),
						$author$project$Product$availability(product))
					])),
				A2(
				$author$project$Helpers$viewMaybe,
				A3($author$project$Page$Products$View$viewListPriceAndAddToCart, toSessionMsg, analyticsSource, product),
				maybeUser),
				A2($author$project$Page$Products$View$viewStickersContainer, true, metadata.d8)
			]);
	});
var $author$project$Page$Products$View$listItemContainerStyles = _List_fromArray(
	[
		$author$project$SE$UI$Utils$block,
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		$rtfeldman$elm_css$Css$displayFlex,
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
		$rtfeldman$elm_css$Css$borderWidth(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
		$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
		A4(
		$rtfeldman$elm_css$Css$boxShadow4,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$px(4),
		$rtfeldman$elm_css$Css$px(10),
		$author$project$SE$UI$Colors$toCss(
			A2(
				$author$project$SE$UI$Colors$mapAlpha,
				$elm$core$Basics$always(0.15),
				$author$project$SE$UI$Colors$black))),
		$rtfeldman$elm_css$Css$padding(
		$rtfeldman$elm_css$Css$px(16)),
		$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$wrap),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$unset),
				$rtfeldman$elm_css$Css$flexWrap($rtfeldman$elm_css$Css$unset),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
			]))
	]);
var $author$project$Page$Products$View$simpleListItem = F5(
	function (toSessionMsg, toAnalyticsSource, maybeUser, index, product) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Page$Products$View$listItemContainerStyles)
				]),
			A5(
				$author$project$Page$Products$View$listItem,
				toSessionMsg,
				toAnalyticsSource(index),
				maybeUser,
				index,
				{gH: 0, cj: 1, b2: product}));
	});
var $author$project$Page$Products$View$simpleList = F4(
	function (toSessionMsg, toAnalyticsSource, maybeUser, products) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Page$Products$View$listStyles)
				]),
			A2(
				$elm$core$List$indexedMap,
				A3($author$project$Page$Products$View$simpleListItem, toSessionMsg, toAnalyticsSource, maybeUser),
				products));
	});
var $author$project$Page$Product$viewRelated = F3(
	function (maybeUser, related, minimal) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Utils$block,
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$h4(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero))
								]))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Title$title4('Kombinera med'),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$author$project$SE$UI$Utils$block,
							$author$project$SE$UI$Font$bodySizeRem(-1),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							'Handplockade produkter som passar bra ihop med ' + $author$project$ExternalId$toString(minimal.hh))
						])),
					A4(
					$author$project$Page$Products$View$simpleList,
					$author$project$Page$Product$GotSessionMsg,
					$author$project$Analytics$RelatedProducts(minimal.hx),
					maybeUser,
					related)
				]));
	});
var $author$project$Page$Product$viewTechnicalSpecificationRow = function (attribute) {
	if (!attribute.$) {
		var label = attribute.a;
		var value = attribute.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(value)
						]))
				]));
	} else {
		var label = attribute.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$author$project$SE$UI$Font$bodySizeRem(1)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label)
						]))
				]));
	}
};
var $author$project$Page$Product$viewTechnicalSpecification = function (attributes) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[$author$project$SE$UI$Utils$block]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Title$title4('Teknisk specifikation'),
				$author$project$SE$UI$Table$V2$toHtml(
				A2(
					$author$project$SE$UI$Table$V2$withModifiers,
					_List_fromArray(
						[1, 0]),
					A2(
						$author$project$SE$UI$Table$V2$body,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$Product$viewTechnicalSpecificationRow, attributes))))
			]));
};
var $author$project$Page$Product$viewAside = F4(
	function (maybeUser, status, attributes, minimal) {
		var _v0 = _Utils_Tuple2(
			$author$project$Status$toMaybe(status),
			attributes);
		if (_v0.a.$ === 1) {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				return $rtfeldman$elm_css$Html$Styled$text('');
			} else {
				var _v2 = _v0.a;
				var attrs = _v0.b;
				return A2(
					$author$project$SE$UI$Section$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Container$container,
							_List_fromArray(
								[1]),
							_List_fromArray(
								[
									$author$project$Page$Product$viewTechnicalSpecification(attrs)
								]))
						]));
			}
		} else {
			if (!_v0.a.a.b) {
				if (!_v0.b.b) {
					return $rtfeldman$elm_css$Html$Styled$text('');
				} else {
					var attrs = _v0.b;
					return A2(
						$author$project$SE$UI$Section$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_fromArray(
									[1]),
								_List_fromArray(
									[
										$author$project$Page$Product$viewTechnicalSpecification(attrs)
									]))
							]));
				}
			} else {
				if (!_v0.b.b) {
					var related = _v0.a.a;
					return A2(
						$author$project$SE$UI$Section$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_fromArray(
									[1]),
								_List_fromArray(
									[
										A3($author$project$Page$Product$viewRelated, maybeUser, related, minimal)
									]))
							]));
				} else {
					var related = _v0.a.a;
					var attrs = _v0.b;
					return A2(
						$author$project$SE$UI$Section$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_Nil,
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$author$project$SE$UI$Utils$widescreen(
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
														A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1.5rem'),
														A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', '1fr 1fr')
													]))
											]),
										_List_Nil,
										_List_fromArray(
											[
												$author$project$Page$Product$viewTechnicalSpecification(attrs),
												A3($author$project$Page$Product$viewRelated, maybeUser, related, minimal)
											]))
									]))
							]));
				}
			}
		}
	});
var $author$project$SE$UI$Columns$Half = 7;
var $author$project$SE$UI$Columns$Tablet = 2;
var $author$project$SE$UI$Font$family = 'BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", \"Helvetica\", \"Arial\", sans-serif';
var $rtfeldman$elm_css$Css$preWrap = {iY: 'pre-wrap', aU: 0};
var $author$project$Page$Product$contentStyles = _List_fromArray(
	[
		$author$project$SE$UI$Font$bodySizeRem(-2),
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'h1',
				_List_fromArray(
					[
						$author$project$SE$UI$Font$titleSizeRem(3)
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'h3',
				_List_fromArray(
					[
						$author$project$SE$UI$Font$titleSizeRem(1),
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero))
					])),
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'pre',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
						A2($rtfeldman$elm_css$Css$property, 'font-family', $author$project$SE$UI$Font$family),
						$author$project$SE$UI$Font$bodySizeRem(-2),
						$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$preWrap),
						$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent)
					]))
			]))
	]);
var $author$project$Availability$viewBalanceTable = function (balance) {
	return $author$project$SE$UI$Table$V2$toHtml(
		A2(
			$author$project$SE$UI$Table$V2$body,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Availability$paragraph,
							$elm$core$Maybe$Just($author$project$Availability$balanceIcon),
							'',
							$elm$core$String$fromInt(balance) + ' i lager')
						]))
				])));
};
var $author$project$Availability$viewIncomingDate = F2(
	function (zone, timestamp) {
		return $rtfeldman$elm_css$Html$Styled$text(
			A2($author$project$Helpers$viewDate, timestamp, zone) + '*');
	});
var $author$project$Availability$viewIncomingTr = F2(
	function (zone, incoming) {
		switch (incoming.$) {
			case 0:
				var timestamp = incoming.b;
				return A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(600))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Bekräftat')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									A2($author$project$Availability$viewIncomingDate, zone, timestamp)
								]))
						]));
			case 1:
				var timestamp = incoming.b;
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Obekräftat')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									A2($author$project$Availability$viewIncomingDate, zone, timestamp)
								]))
						]));
			default:
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Inget datum från leverantören')
								]))
						]));
		}
	});
var $author$project$Availability$viewIncomingTable = F2(
	function (zone, incoming) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					A2($rtfeldman$elm_css$Css$property, 'gap', '1.5rem'),
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
				]),
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Table$V2$toHtml(
					A2(
						$author$project$SE$UI$Table$V2$withFoot,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('*Avser uppskattad leveranstid till vårt lager och kan komma att ändras.')
									]))
							]),
						A2(
							$author$project$SE$UI$Table$V2$withHead,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
										]),
									_List_fromArray(
										[
											A3(
											$author$project$Availability$paragraph,
											$elm$core$Maybe$Just($author$project$Availability$incomingIcon),
											'',
											'På väg in')
										]))
								]),
							A2(
								$author$project$SE$UI$Table$V2$body,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$typeSelector,
														'tfoot',
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$italic),
																$author$project$SE$UI$Font$bodySizeRem(-3),
																$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
															])),
														A2(
														$rtfeldman$elm_css$Css$Global$typeSelector,
														'td',
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Css$pseudoClass,
																'last-child',
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
																	]))
															]))
													]))
											]))
									]),
								A2(
									$elm$core$List$map,
									$author$project$Availability$viewIncomingTr(zone),
									incoming))))),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor(
							A2(
								$author$project$SE$UI$Colors$mapAlpha,
								$elm$core$Basics$mul(0.1),
								$author$project$SE$UI$Colors$danger)),
							$rtfeldman$elm_css$Css$padding(
							$rtfeldman$elm_css$Css$rem(0.75)),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger),
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
							$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$danger),
							$rtfeldman$elm_css$Css$borderWidth(
							$rtfeldman$elm_css$Css$px(1)),
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$a(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($rtfeldman$elm_css$Css$inherit),
											$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$underline),
											$rtfeldman$elm_css$Css$hover(
											_List_fromArray(
												[
													$author$project$SE$UI$Colors$color(
													$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$danger))
												])),
											$rtfeldman$elm_css$Css$active(
											_List_fromArray(
												[
													$author$project$SE$UI$Colors$color(
													$author$project$SE$UI$Colors$active($author$project$SE$UI$Colors$danger))
												]))
										]))
								]))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('OBS ')
								])),
							$rtfeldman$elm_css$Html$Styled$text('För tillfället visar vi inte uppskattat leveransdatum  för ej lagerförda varor.')
						]))
				]));
	});
var $author$project$Availability$view = F3(
	function (zone, externalId, availability) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexEnd),
					$rtfeldman$elm_css$Css$Global$children(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$div(
							_List_fromArray(
								[$author$project$SE$UI$Utils$block]))
						]))
				]),
			_List_Nil,
			function () {
				if (availability.$ === 1) {
					if (!availability.b.b) {
						var balance = availability.a;
						return _List_fromArray(
							[
								$author$project$Availability$viewBalanceTable(balance)
							]);
					} else {
						var balance = availability.a;
						var incoming = availability.b;
						return _List_fromArray(
							[
								$author$project$Availability$viewBalanceTable(balance),
								A2($author$project$Availability$viewIncomingTable, zone, incoming)
							]);
					}
				} else {
					if (!availability.a.b) {
						if (availability.b.$ === 1) {
							var _v1 = availability.b;
							return _List_fromArray(
								[
									$author$project$SE$UI$Table$V2$toHtml(
									A2(
										$author$project$SE$UI$Table$V2$body,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
													]),
												_List_fromArray(
													[
														A3(
														$author$project$Availability$paragraph,
														$elm$core$Maybe$Just(
															_Utils_Tuple2($author$project$SE$UI$Icon$V2$box, $author$project$SE$UI$Colors$base)),
														$author$project$Availability$contactUsLinkString(externalId),
														'Kontakta oss')
													]))
											])))
								]);
						} else {
							var leadtime = availability.b.a;
							return _List_fromArray(
								[
									$author$project$SE$UI$Table$V2$toHtml(
									A2(
										$author$project$SE$UI$Table$V2$body,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
													]),
												_List_fromArray(
													[
														A3($author$project$Availability$paragraph, $elm$core$Maybe$Nothing, '', leadtime + ' in på vårt lager')
													]))
											])))
								]);
						}
					} else {
						var incoming = availability.a;
						return _List_fromArray(
							[
								A2($author$project$Availability$viewIncomingTable, zone, incoming)
							]);
					}
				}
			}());
	});
var $author$project$Page$Product$viewBargainDescription = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$rem(0.75))
		]),
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$author$project$SE$UI$Content$content,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Fyndvaror kan vara nedsatta av olika anledningar och består exempelvis av utgående produkter, demoexemplar eller kundreturer.')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Alla våra fyndvaror är fullt funktionstestade och kompletta i kartong om inget annat anges i produktbeskrivningen.')
						]))
				]))
		]));
var $author$project$Page$Product$emptyStatusHelper = F2(
	function (fn, status) {
		var _v0 = $author$project$Status$toMaybe(status);
		if (_v0.$ === 1) {
			return $rtfeldman$elm_css$Html$Styled$text('');
		} else {
			if (!_v0.a.b) {
				return $rtfeldman$elm_css$Html$Styled$text('');
			} else {
				var a = _v0.a;
				return fn(a);
			}
		}
	});
var $author$project$Page$Product$mergePrices = F3(
	function (config, acc, ss) {
		mergePrices:
		while (true) {
			var _v0 = _Utils_Tuple2(acc, ss);
			if (!_v0.b.b) {
				return acc;
			} else {
				if (!_v0.a.b) {
					if (!_v0.b.b.b) {
						var _v1 = _v0.b;
						var a = _v1.a;
						return _List_fromArray(
							[a]);
					} else {
						var _v3 = _v0.b;
						var a = _v3.a;
						var t = _v3.b;
						var $temp$config = config,
							$temp$acc = _List_fromArray(
							[a]),
							$temp$ss = t;
						config = $temp$config;
						acc = $temp$acc;
						ss = $temp$ss;
						continue mergePrices;
					}
				} else {
					if (!_v0.b.b.b) {
						var _v2 = _v0.b;
						var a = _v2.a;
						return _Utils_ap(
							acc,
							_List_fromArray(
								[config.fs, a]));
					} else {
						var _v4 = _v0.b;
						var a = _v4.a;
						var t = _v4.b;
						var $temp$config = config,
							$temp$acc = _Utils_ap(
							acc,
							_List_fromArray(
								[config.eK, a])),
							$temp$ss = t;
						config = $temp$config;
						acc = $temp$acc;
						ss = $temp$ss;
						continue mergePrices;
					}
				}
			}
		}
	});
var $author$project$Page$Product$viewBargainPrice = F2(
	function (settings, data) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$author$project$Route$href(
					A2(
						$author$project$Route$Product,
						$author$project$QueryParams$empty,
						_Utils_Tuple2(data.hx, data.gb)))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					A3($author$project$Price$toString, settings.gA, settings.ga, data.cR))
				]));
	});
var $author$project$Page$Product$viewBargainPrices = F2(
	function (settings, bargains) {
		return A3(
			$author$project$Page$Product$mergePrices,
			{
				eK: $rtfeldman$elm_css$Html$Styled$text(', '),
				fs: $rtfeldman$elm_css$Html$Styled$text(' och ')
			},
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Product$viewBargainPrice(settings),
				bargains));
	});
var $author$project$Page$Product$viewLoadedBargains = F2(
	function (settings, bargains) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Utils$block,
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$a(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(600))
								]))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$strong,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Finns som '),
								$author$project$Sticker$view(1),
								$rtfeldman$elm_css$Html$Styled$text(' för ')
							]),
						_Utils_ap(
							A2($author$project$Page$Product$viewBargainPrices, settings, bargains),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('.')
								]))))
				]));
	});
var $author$project$Page$Product$viewBargains = F2(
	function (settings, status) {
		return A2(
			$author$project$Page$Product$emptyStatusHelper,
			$author$project$Page$Product$viewLoadedBargains(settings),
			status);
	});
var $author$project$Page$Product$BIDModal = 3;
var $rtfeldman$elm_css$Html$Styled$header = $rtfeldman$elm_css$Html$Styled$node('header');
var $rtfeldman$elm_css$Css$alignSelf = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignSelf',
		'align-self',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $author$project$Page$Product$GotBidRowAmount = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Analytics$ProductPage = {$: 0};
var $author$project$Page$Product$viewAddBidRowToCart = F4(
	function (source, product, row, amount) {
		var _v0 = ($author$project$BidRow$orderableQuantity(row) <= 0) ? {cB: 'Går ej att beställa fler', cJ: $elm$core$Maybe$Nothing, d2: $author$project$SE$UI$Form$Input$withDisabled} : {
			cB: 'Lägg i varukorg',
			cJ: $elm$core$Maybe$Just(
				$author$project$Page$Product$GotSessionMsg(
					A4(
						$author$project$Session$AddedToCart,
						$author$project$Product$minimal(product),
						source,
						amount,
						$author$project$Analytics$ProductPage))),
			d2: $elm$core$Basics$identity
		};
		var msg = _v0.cJ;
		var label = _v0.cB;
		var readOnlyFn = _v0.d2;
		return _List_fromArray(
			[
				A2(
				$author$project$SE$UI$Form$field,
				_List_fromArray(
					[0]),
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Form$control,
						false,
						_List_fromArray(
							[
								$author$project$SE$UI$Form$Input$toHtml(
								readOnlyFn(
									A2(
										$author$project$SE$UI$Form$Input$withStep,
										1,
										A2(
											$author$project$SE$UI$Form$Input$withRange,
											_Utils_Tuple2(
												1,
												$author$project$BidRow$orderableQuantity(row)),
											A2(
												$author$project$SE$UI$Form$Input$withPlaceholder,
												'Ange antal',
												A2(
													$author$project$SE$UI$Form$Input$number,
													$author$project$Page$Product$GotBidRowAmount(row),
													$elm$core$String$fromFloat(amount)))))))
							])),
						A2(
						$author$project$SE$UI$Form$control,
						false,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Buttons$staticButton,
								_List_Nil,
								A2(
									$author$project$Unit$toSwedishString,
									amount,
									$author$project$Price$unit(
										$author$project$BidRow$priceData(row))))
							]))
					])),
				A2(
				$author$project$SE$UI$Form$field,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$author$project$SE$UI$Buttons$button,
						_List_fromArray(
							[
								$author$project$SE$UI$Buttons$Color(11)
							]),
						msg,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(label)
									]))
							]))
					]))
			]);
	});
var $author$project$Page$Product$viewLabelValue = F3(
	function (styles, label, value) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			styles,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label + ': ')
						])),
					$rtfeldman$elm_css$Html$Styled$text(value)
				]));
	});
var $author$project$Page$Product$viewBidRow = F6(
	function (zone, user, amountLookupFn, product, bid, row) {
		var unit = $author$project$Price$unit(
			$author$project$BidRow$priceData(row));
		var quantity = $author$project$BidRow$quantity(row);
		var orderableQuantity = $author$project$BidRow$orderableQuantity(row);
		var amount = amountLookupFn(
			$author$project$BidRow$id(row));
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
					$rtfeldman$elm_css$Css$padding(
					$rtfeldman$elm_css$Css$rem(1)),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
					A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
					A2($rtfeldman$elm_css$Css$property, 'gap', '0.5rem'),
					A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'auto 1fr'),
					A2($rtfeldman$elm_css$Css$property, 'grid-template-areas', '\n"header header"\n"info1 info1"\n"info2 info2"\n"info3 info3"\n"info4 info4"\n"price price"\n"amount add"\n'),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'price',
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, 'grid-area', 'price')
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'field',
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, 'grid-area', 'amount'),
									$rtfeldman$elm_css$Css$Global$adjacentSiblings(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Global$class,
											'field',
											_List_fromArray(
												[
													A2($rtfeldman$elm_css$Css$property, 'grid-area', 'add')
												]))
										]))
								]))
						])),
					$author$project$SE$UI$Utils$fullhd(
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'auto auto 1fr auto'),
							A2($rtfeldman$elm_css$Css$property, 'grid-template-rows', 'auto auto 1fr'),
							A2($rtfeldman$elm_css$Css$property, 'grid-template-areas', '\n"header header header price"\n"info1 info3 . price"\n"info2 info4 amount add"\n'),
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$class,
									'price',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
											$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$end)
										])),
									A2(
									$rtfeldman$elm_css$Css$Global$class,
									'field',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$zero)),
											$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$end),
											A2($rtfeldman$elm_css$Css$property, 'justify-self', 'end'),
											$rtfeldman$elm_css$Css$Global$adjacentSiblings(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Css$Global$class,
													'field',
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$zero)
														]))
												]))
										]))
								]))
						]))
				]),
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$author$project$SE$UI$Font$bodySizeRem(1),
								A2($rtfeldman$elm_css$Css$property, 'grid-area', 'header')
							]),
						_List_Nil,
						_List_fromArray(
							[
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$i,
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$danger),
										$author$project$SE$UI$Colors$color(
										$author$project$SE$UI$Colors$invert($author$project$SE$UI$Colors$danger)),
										$rtfeldman$elm_css$Css$borderRadius(
										$rtfeldman$elm_css$Css$pct(50)),
										$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$rem(1)),
										$rtfeldman$elm_css$Css$height(
										$rtfeldman$elm_css$Css$rem(1)),
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
									]),
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('ℹ️')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$span,
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(' ENDAST FÖR ')
											])),
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Bid$name(bid))
									]))
							])),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Css$property, 'grid-area', 'info1')
							]),
						_List_fromArray(
							[
								$author$project$Route$href(
								$author$project$Route$Bid(
									$author$project$Bid$id(bid)))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$author$project$ExternalId$toString(
									$author$project$Bid$externalId(bid)))
							])),
						A3(
						$author$project$Page$Product$viewLabelValue,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Css$property, 'grid-area', 'info2')
							]),
						'Beställ senast',
						A2(
							$author$project$Helpers$viewDate,
							$author$project$Bid$noOrdersAfter(bid),
							zone)),
						A3(
						$author$project$Page$Product$viewLabelValue,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Css$property, 'grid-area', 'info3')
							]),
						'Totalt antal',
						$elm$core$String$fromFloat(quantity) + (' ' + A2($author$project$Unit$toSwedishString, quantity, unit))),
						A3(
						$author$project$Page$Product$viewLabelValue,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Css$property, 'grid-area', 'info4')
							]),
						'Antal kvar',
						$elm$core$String$fromFloat(orderableQuantity) + (' ' + A2($author$project$Unit$toSwedishString, orderableQuantity, unit))),
						A4(
						$author$project$Price$viewSmallTag,
						$author$project$Product$metadata(product).d8,
						user.c0.gA,
						user.c0.ga,
						$author$project$BidRow$priceData(row))
					]),
				A4(
					$author$project$Page$Product$viewAddBidRowToCart,
					A3(
						$author$project$Cart$BidRowSource,
						$author$project$BidRow$id(row),
						$author$project$Bid$id(bid),
						$author$project$Bid$externalId(bid)),
					product,
					row,
					amount)));
	});
var $author$project$Page$Product$viewBid = F5(
	function (zone, user, amountLookupFn, product, bid) {
		return A2(
			$elm$core$List$map,
			A5($author$project$Page$Product$viewBidRow, zone, user, amountLookupFn, product, bid),
			$author$project$Bid$rows(bid));
	});
var $author$project$Page$Product$viewLoadedBids = F5(
	function (zone, user, amountLookupFn, product, bids) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darker),
					$author$project$SE$UI$Colors$color(
					$author$project$SE$UI$Colors$invert($author$project$SE$UI$Colors$darker)),
					$rtfeldman$elm_css$Css$padding(
					$rtfeldman$elm_css$Css$rem(1)),
					$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
					$rtfeldman$elm_css$Css$displayFlex,
					A2($rtfeldman$elm_css$Css$property, 'gap', '1rem'),
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					$author$project$SE$UI$Utils$block
				]),
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$header,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							A2($rtfeldman$elm_css$Css$property, 'gap', '0.5rem'),
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$baseline)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title3('BID-priser'),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$hover(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
											$author$project$SE$UI$Colors$color(
											$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									$author$project$Page$Product$ChangedModal(3))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Vad är BID?')
								])),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
									$rtfeldman$elm_css$Css$hover(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color(
											$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
										]))
								]),
							_List_fromArray(
								[
									$author$project$Route$href($author$project$Route$Bids)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Se alla dina BID-priser')
								]))
						])),
				A2(
					$elm$core$List$concatMap,
					A4($author$project$Page$Product$viewBid, zone, user, amountLookupFn, product),
					bids)));
	});
var $author$project$Page$Product$viewBids = F5(
	function (zone, user, amountLookupFn, product, status) {
		return A2(
			$author$project$Page$Product$emptyStatusHelper,
			A4($author$project$Page$Product$viewLoadedBids, zone, user, amountLookupFn, product),
			status);
	});
var $author$project$Campaign$description = function (_v0) {
	var internals = _v0;
	return internals.eT;
};
var $author$project$Campaign$end_at = function (_v0) {
	var internals = _v0;
	return internals.eZ;
};
var $author$project$Campaign$name = function (_v0) {
	var internals = _v0;
	return internals.hT;
};
var $author$project$Page$Product$viewCampaign = F2(
	function (zone, campaign) {
		var name = $author$project$Campaign$name(campaign);
		var end_at = $author$project$Campaign$end_at(campaign);
		var description = $author$project$Campaign$description(campaign);
		return A2(
			$author$project$SE$UI$Content$content,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor(
							$author$project$Sticker$color(2)),
							$author$project$SE$UI$Colors$color(
							$author$project$SE$UI$Colors$invert(
								$author$project$Sticker$color(2))),
							$rtfeldman$elm_css$Css$padding(
							$rtfeldman$elm_css$Css$rem(0.75))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(name)
								])),
							A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
							$rtfeldman$elm_css$Html$Styled$text(description),
							A2(
							$author$project$Helpers$viewIf,
							description !== '',
							A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil)),
							$rtfeldman$elm_css$Html$Styled$text(
							'Giltig t.o.m. ' + A2($author$project$Helpers$viewDate, end_at, zone))
						]))
				]));
	});
var $author$project$Page$Product$viewCampaigns = F2(
	function (zone, campaigns) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$rem(0.75))
				]),
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Product$viewCampaign(zone),
				campaigns));
	});
var $author$project$Page$Product$viewDatasheet = function (file) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(file.cg),
						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
							]),
						_List_Nil,
						_List_fromArray(
							[
								$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$pdf)
							])),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Produktblad')
							]))
					]))
			]));
};
var $rtfeldman$elm_css$Html$Styled$pre = $rtfeldman$elm_css$Html$Styled$node('pre');
var $author$project$Page$Product$viewDescription = function (desc) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				$author$project$SE$UI$Title$title3('Produktbeskrivning'),
				A2(
				$rtfeldman$elm_css$Html$Styled$pre,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(desc)
					]))
			]));
};
var $author$project$Page$Product$viewOutbound = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$strong,
	_List_fromArray(
		[
			$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger)
		]),
	_List_Nil,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('Utgående')
		]));
var $author$project$Page$Product$ChemicalTaxModal = 2;
var $author$project$SE$UI$Icon$V2$user = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		448,
		512,
		_List_fromArray(
			['M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'])));
var $author$project$SE$UI$Columns$Auto = 0;
var $author$project$Page$Product$GotAmount = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Product$viewAddToCart = F3(
	function (product, priceData, amount) {
		return $author$project$SE$UI$Columns$columns(
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Columns$column,
					_List_fromArray(
						[
							_Utils_Tuple2(0, 1),
							_Utils_Tuple2(1, 1)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_fromArray(
								[0]),
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withStep,
												1,
												A2(
													$author$project$SE$UI$Form$Input$withRange,
													_Utils_Tuple2(1, 10000),
													A2(
														$author$project$SE$UI$Form$Input$withPlaceholder,
														'Ange antal',
														A2(
															$author$project$SE$UI$Form$Input$number,
															$author$project$Page$Product$GotAmount,
															$elm$core$String$fromFloat(amount))))))
										])),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											A2(
											$author$project$SE$UI$Buttons$staticButton,
											_List_Nil,
											A2(
												$author$project$Unit$toSwedishString,
												amount,
												$author$project$Price$unit(priceData)))
										]))
								]))
						])),
					A2(
					$author$project$SE$UI$Columns$column,
					_List_fromArray(
						[
							_Utils_Tuple2(0, 0)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_Nil,
							_List_fromArray(
								[
									A3(
									$author$project$SE$UI$Buttons$button,
									_List_fromArray(
										[
											$author$project$SE$UI$Buttons$Color(11),
											$author$project$SE$UI$Buttons$Fullwidth
										]),
									$elm$core$Maybe$Just(
										$author$project$Page$Product$GotSessionMsg(
											A4(
												$author$project$Session$AddedToCart,
												$author$project$Product$minimal(product),
												$author$project$Cart$NoSource,
												amount,
												$author$project$Analytics$ProductPage))),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Lägg i varukorg')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Price$viewChemicalTax = F2(
	function (msg, chemicalTax) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$p,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark)
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
							$rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$color(
									$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(msg)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Kemikalieskatt')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(' tillkommer med ')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(chemicalTax)
						]))
				]));
	});
var $author$project$Price$viewLargeTag = F5(
	function (chemicalTaxLinkClick, maybeSticker, vat, showDiscount, data) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Price$priceTagStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('price', true),
							_Utils_Tuple2('is-large', true),
							_Utils_Tuple2('show-discount', showDiscount)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('net-price')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A3($author$project$Price$toString, vat, showDiscount, data))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('vat-label')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									' ' + $author$project$VAT$toLabel(vat))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('list-price-and-discount')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							'Listpris ' + A3($author$project$Price$toString, vat, false, data)),
							A2($author$project$Price$viewDiscount, maybeSticker, data),
							A2(
							$author$project$Helpers$viewMaybe,
							$author$project$Price$viewChemicalTax(chemicalTaxLinkClick),
							$author$project$Price$toChemicalTaxString(data))
						]))
				]));
	});
var $author$project$Page$Product$viewPricesAndAddToCart = F3(
	function (maybeUser, product, amount) {
		if (maybeUser.$ === 1) {
			return A2(
				$author$project$SE$UI$Buttons$buttons,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$a,
						A2(
							$author$project$SE$UI$Buttons$buttonStyles,
							_List_fromArray(
								[
									$author$project$SE$UI$Buttons$Color(10)
								]),
							false),
						_List_fromArray(
							[
								$author$project$Route$href($author$project$Route$Login)
							]),
						_List_fromArray(
							[
								$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$user),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Logga in för pris')
									]))
							]))
					]));
		} else {
			var user = maybeUser.a;
			var _v1 = $author$project$Product$prices(product);
			if (_v1.$ === 1) {
				return $author$project$Product$viewMissingPrice(
					$author$project$Product$externalId(product));
			} else {
				if (!_v1.a.$) {
					var _v2 = _v1.a;
					return $author$project$Product$viewMissingPrice(
						$author$project$Product$externalId(product));
				} else {
					var priceData = _v1.a.a;
					var metadata = $author$project$Product$metadata(product);
					return A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								A2($rtfeldman$elm_css$Css$property, 'gap', '1.5rem'),
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
							]),
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$Price$viewLargeTag,
								$author$project$Page$Product$ChangedModal(2),
								metadata.d8,
								user.c0.gA,
								user.c0.ga,
								priceData),
								A3($author$project$Page$Product$viewAddToCart, product, priceData, amount)
							]));
				}
			}
		}
	});
var $author$project$Page$Product$ChangedProduct = function (a) {
	return {$: 13, a: a};
};
var $author$project$Product$serializeRoute = function (_v0) {
	var id_ = _v0.a;
	var slug_ = _v0.b;
	return $elm$core$String$fromInt(
		$author$project$Id$toInt(id_)) + ('|' + $author$project$Slug$toString(slug_));
};
var $author$project$SE$UI$Colors$LightBlue = 15;
var $author$project$Page$Product$viewVariantTerm = F4(
	function (isLoading, currentProductId, data, _v0) {
		var label = _v0.cB;
		var id = _v0.hx;
		var slug = _v0.gb;
		var hydratedLabel = A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[data.dZ, label, data.ec]));
		var activeButtonModifiers = isLoading ? _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(14),
				$author$project$SE$UI$Buttons$Loading
			]) : _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(14)
			]);
		return A2($author$project$Id$equals, currentProductId, id) ? A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_Utils_ap(
				A2($author$project$SE$UI$Buttons$buttonStyles, activeButtonModifiers, false),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$default)
					])),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(hydratedLabel)
				])) : A3(
			$author$project$SE$UI$Buttons$button,
			_List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(15)
				]),
			$elm$core$Maybe$Just(
				$author$project$Page$Product$ChangedProduct(
					$author$project$Product$serializeRoute(
						_Utils_Tuple2(id, slug)))),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(hydratedLabel)
				]));
	});
var $author$project$Page$Product$viewVariantTerms = F4(
	function (isLoading, currentProductId, data, terms) {
		if ($elm$core$List$length(terms) > 10) {
			var toIdSlugString = function (_v0) {
				var id = _v0.hx;
				var slug = _v0.gb;
				return $author$project$Product$serializeRoute(
					_Utils_Tuple2(id, slug));
			};
			var currentIdSlugString = A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					toIdSlugString,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.hx;
								},
								$elm$core$Basics$eq(currentProductId)),
							terms))));
			return A2(
				$author$project$SE$UI$Form$field,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Form$Input$toHtml(
						A2(
							$author$project$SE$UI$Form$Input$withPlaceholder,
							data.cB,
							A3(
								$author$project$SE$UI$Form$Input$select,
								$author$project$Page$Product$ChangedProduct,
								A2(
									$elm$core$List$map,
									function (a) {
										return {
											cB: A2(
												$elm$core$String$join,
												'',
												_List_fromArray(
													[data.dZ, a.cB, data.ec])),
											iY: toIdSlugString(a)
										};
									},
									terms),
								currentIdSlugString)))
					]));
		} else {
			return A2(
				$author$project$SE$UI$Form$field,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Form$label(data.cB),
						A2(
						$author$project$SE$UI$Form$control,
						false,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Buttons$buttons,
								_List_fromArray(
									[0]),
								A2(
									$elm$core$List$map,
									A3($author$project$Page$Product$viewVariantTerm, isLoading, currentProductId, data),
									terms))
							]))
					]));
		}
	});
var $author$project$Page$Product$viewVariant = F3(
	function (isLoading, currentProductId, variant) {
		var data = variant.a;
		var terms = variant.b;
		return A4($author$project$Page$Product$viewVariantTerms, isLoading, currentProductId, data, terms);
	});
var $author$project$Page$Product$viewLoadedVariants = F3(
	function (isLoading, currentProductId, variants) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[$author$project$SE$UI$Utils$block]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('variants', true)
						]))
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Page$Product$viewVariant, isLoading, currentProductId),
				variants));
	});
var $author$project$Page$Product$viewVariants = F2(
	function (currentProductId, status) {
		switch (status.$) {
			case 0:
				return $rtfeldman$elm_css$Html$Styled$text('');
			case 3:
				return $rtfeldman$elm_css$Html$Styled$text('');
			case 2:
				var variants = status.a;
				return A3($author$project$Page$Product$viewLoadedVariants, false, currentProductId, variants);
			default:
				var variants = status.a;
				return A3($author$project$Page$Product$viewLoadedVariants, true, currentProductId, variants);
		}
	});
var $author$project$Page$Product$viewContent = F2(
	function (_v0, product) {
		var zone = _v0.gG;
		var maybeUser = _v0.dM;
		var amount = _v0.cj;
		var bidRowAmounts = _v0.aG;
		var bargains = _v0.aE;
		var variants = _v0.aA;
		var bids = _v0.aV;
		var metadata = $author$project$Product$metadata(product);
		var bidsHtml = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return A5(
						$author$project$Page$Product$viewBids,
						zone,
						u,
						$author$project$Amount$find(bidRowAmounts),
						product,
						bids);
				},
				maybeUser));
		var bargainHtml = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return A2($author$project$Page$Product$viewBargains, u.c0, bargains);
				},
				maybeUser));
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$Page$Product$contentStyles,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Brand$name(metadata.dp))
						])),
					$author$project$SE$UI$Title$title1(metadata.hT),
					$author$project$SE$UI$Columns$columns(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Columns$column,
							_List_Nil,
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$ul,
									_List_fromArray(
										[$author$project$SE$UI$Utils$block]),
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Artikelkod: ')
														])),
													A4(
													$rtfeldman$elm_css$Html$Styled$styled,
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
														]),
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$author$project$ExternalId$toString(
																$author$project$Product$externalId(product)))
														]))
												])),
											A2(
											$author$project$Helpers$viewIf,
											metadata.fv !== '',
											A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Tillverkarens artikelkod: ')
															])),
														A4(
														$rtfeldman$elm_css$Html$Styled$styled,
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
															]),
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(metadata.fv)
															]))
													]))),
											A2(
											$author$project$Helpers$viewIf,
											metadata.eX !== '',
											A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('E-nummer: ')
															])),
														A4(
														$rtfeldman$elm_css$Html$Styled$styled,
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
															]),
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(metadata.eX)
															]))
													]))),
											A2(
											$author$project$Helpers$viewIf,
											metadata.gh === 1,
											A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[$author$project$Page$Product$viewOutbound]))),
											A2($author$project$Helpers$viewMaybe, $author$project$Page$Product$viewDatasheet, metadata.hm)
										])),
									A2($author$project$Helpers$viewMaybe, $author$project$Sticker$view, metadata.d8),
									A2(
									$author$project$Helpers$viewIf,
									_Utils_eq(
										metadata.d8,
										$elm$core$Maybe$Just(1)),
									$author$project$Page$Product$viewBargainDescription),
									bargainHtml,
									A2(
									$author$project$Helpers$viewIf,
									A2($elm$core$Basics$composeR, $elm$core$List$isEmpty, $elm$core$Basics$not)(metadata.eE),
									A2($author$project$Page$Product$viewCampaigns, zone, metadata.eE))
								])),
							A2(
							$author$project$SE$UI$Columns$column,
							_List_fromArray(
								[
									_Utils_Tuple2(1, 7),
									_Utils_Tuple2(2, 1)
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Helpers$viewMaybe,
									A2(
										$author$project$Availability$view,
										zone,
										$author$project$Product$externalId(product)),
									$author$project$Product$availability(product))
								]))
						])),
					A2(
					$author$project$Page$Product$viewVariants,
					$author$project$Product$id(product),
					variants),
					bidsHtml,
					A3($author$project$Page$Product$viewPricesAndAddToCart, maybeUser, product, amount),
					A2(
					$author$project$Helpers$viewIf,
					metadata.eT !== '',
					$author$project$Page$Product$viewDescription(metadata.eT))
				]));
	});
var $author$project$Page$Product$EmbedModal = 1;
var $author$project$Cookie$UnknownHost = {$: 1};
var $author$project$Cookie$NoConsent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Cookie$hostIsActive = function (_v0) {
	var internals = _v0;
	return internals.dH ? $elm$core$Result$Ok(0) : $elm$core$Result$Err(
		$author$project$Cookie$NoConsent(internals.dC));
};
var $author$project$Cookie$hasGivenConsent = F2(
	function (hostName, hosts) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Result$Err($author$project$Cookie$UnknownHost),
			A2(
				$elm$core$Maybe$map,
				$author$project$Cookie$hostIsActive,
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var name = _v0.hT;
							return _Utils_eq(name, hostName);
						},
						hosts))));
	});
var $author$project$Embed$Video$toConsentedHtml = function (rec) {
	var paddingTop = function () {
		var _v0 = _Utils_Tuple2(rec.gE, rec.e7);
		if (!_v0.a) {
			return $rtfeldman$elm_css$Css$pct(56.25);
		} else {
			var w = _v0.a;
			var h = _v0.b;
			return $rtfeldman$elm_css$Css$pct((h / w) * 100);
		}
	}();
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$paddingTop(paddingTop),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$selector,
						'iframe',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$pct(100))
							]))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('aspect-ratio-box', true)
					]))
			]),
		rec.fa);
};
var $author$project$Embed$Video$viewChangeCookieConsentButton = function () {
	var color = $author$project$SE$UI$Colors$lighter;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$button,
		_List_fromArray(
			[
				A3(
				$rtfeldman$elm_css$Css$border3,
				$rtfeldman$elm_css$Css$px(1),
				$rtfeldman$elm_css$Css$solid,
				$rtfeldman$elm_css$Css$transparent),
				$rtfeldman$elm_css$Css$important(
				$author$project$SE$UI$Colors$borderColor(color)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$lineHeight(
					$rtfeldman$elm_css$Css$em(1.5))),
				$rtfeldman$elm_css$Css$important(
				A2($rtfeldman$elm_css$Css$property, 'padding', 'calc(0.5em - (Css.px 1)) calc(0.75em - (Css.px 1))')),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$fontSize(
					$rtfeldman$elm_css$Css$px(16))),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$borderRadius(
					$rtfeldman$elm_css$Css$px(2))),
				$rtfeldman$elm_css$Css$important(
				$author$project$SE$UI$Colors$backgroundColor(color)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$borderWidth(
					$rtfeldman$elm_css$Css$px(1))),
				$rtfeldman$elm_css$Css$important(
				$author$project$SE$UI$Colors$color(
					$author$project$SE$UI$Colors$invert(color))),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap)),
				$rtfeldman$elm_css$Css$important(
				$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none)),
				$rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$author$project$SE$UI$Colors$color(
							$author$project$SE$UI$Colors$invert(
								$author$project$SE$UI$Colors$hover(color)))),
						$rtfeldman$elm_css$Css$important(
						$author$project$SE$UI$Colors$backgroundColor(
							$author$project$SE$UI$Colors$hover(color)))
					])),
				$rtfeldman$elm_css$Css$active(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$author$project$SE$UI$Colors$color(
							$author$project$SE$UI$Colors$invert(
								$author$project$SE$UI$Colors$active(color)))),
						$rtfeldman$elm_css$Css$important(
						$author$project$SE$UI$Colors$backgroundColor(
							$author$project$SE$UI$Colors$active(color)))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('se-custom-ot-sdk-show-settings')
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('Cookiesinställningar')
			]));
}();
var $author$project$Embed$Video$viewConsentError = F2(
	function (host, error) {
		return A2(
			$author$project$SE$UI$Content$content,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$padding(
							$rtfeldman$elm_css$Css$rem(1.5))
						]))
				]),
			function () {
				if (!error.$) {
					var groupName = error.a;
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(host)
										])),
									$rtfeldman$elm_css$Html$Styled$text(' använder cookies på ett sätt som du inte accepterat.')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('För att visa innehållet behöver du acceptera cookies av typen: ')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(groupName)
										]))
								])),
							$author$project$Embed$Video$viewChangeCookieConsentButton
						]);
				} else {
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Vi känner inte till vilka cookies '),
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(host)
										])),
									$rtfeldman$elm_css$Html$Styled$text(' använder och vi kan därför inte visa innehållet. Prova att uppdatera sidan, om problemet kvarstår, hör av dig till '),
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:partnerzon@specialelektronik.se')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('partnerzon@specialelektronik.se')
										])),
									$rtfeldman$elm_css$Html$Styled$text(' för att få hjälp.')
								]))
						]);
				}
			}());
	});
var $author$project$Embed$Video$toHtml = F2(
	function (hosts, _v0) {
		var rec = _v0;
		var _v1 = A2($author$project$Cookie$hasGivenConsent, rec.hv, hosts);
		if (!_v1.$) {
			return $author$project$Embed$Video$toConsentedHtml(rec);
		} else {
			var error = _v1.a;
			return A2($author$project$Embed$Video$viewConsentError, rec.hv, error);
		}
	});
var $author$project$Page$Product$viewEmbed = F3(
	function (hosts, maybeEmbed, productCode) {
		var imageConfig = A2(
			$author$project$SE$UI$Image$V2$withAspectRatio,
			_Utils_Tuple2(1, 1),
			$author$project$SE$UI$Image$V2$create(
				{e7: 960, gE: 960}));
		var activeEmbedHtml = function () {
			if (!maybeEmbed.$) {
				if (maybeEmbed.a.$ === 1) {
					var image = maybeEmbed.a.a;
					return A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$author$project$Page$Product$ChangedModal(1))
							]),
						_List_fromArray(
							[
								A4($author$project$Image$viewCustom, imageConfig, 1, 'Bild av ' + productCode, image)
							]));
				} else {
					var video = maybeEmbed.a.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$elm$core$Basics$never,
						A2($author$project$Embed$Video$toHtml, hosts, video));
				}
			} else {
				return $author$project$SE$UI$Image$V2$toMissingHtml(imageConfig);
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('embed', true)
						]))
				]),
			_List_fromArray(
				[activeEmbedHtml]));
	});
var $author$project$SE$UI$Modal$fullWidthModalContentStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero),
		$rtfeldman$elm_css$Css$maxHeight(
		A3(
			$rtfeldman$elm_css$Css$calc,
			$rtfeldman$elm_css$Css$vh(100),
			$rtfeldman$elm_css$Css$minus,
			$rtfeldman$elm_css$Css$px(160))),
		$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$auto),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		$author$project$SE$UI$Utils$tablet(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto),
				$rtfeldman$elm_css$Css$maxHeight(
				A3(
					$rtfeldman$elm_css$Css$calc,
					$rtfeldman$elm_css$Css$vh(100),
					$rtfeldman$elm_css$Css$minus,
					$rtfeldman$elm_css$Css$px(40)))
			]))
	]);
var $author$project$SE$UI$Modal$fullWidthModal = F2(
	function (closeMsg, c) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Modal$modalStyles,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Modal$modalBackgroundStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(closeMsg)
						]),
					_List_Nil),
					A4($rtfeldman$elm_css$Html$Styled$styled, $rtfeldman$elm_css$Html$Styled$div, $author$project$SE$UI$Modal$fullWidthModalContentStyles, _List_Nil, c),
					A2($author$project$SE$UI$Delete$large, $author$project$SE$UI$Modal$closeStyles, closeMsg)
				]));
	});
var $author$project$Page$viewChemicalTaxModal = function (closeMsg) {
	return A2(
		$author$project$SE$UI$Modal$modal,
		closeMsg,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$em(2)),
						$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white)
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title5('Information angående skatt på kemikalier i viss elektronik'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Från den 1 juli 2017 tas skatt på kemikalier i viss elektronik ut. Syftet är att minska förekomst, spridning och exponering av farliga flamskyddsmedel. Skatt kommer utgå för bl.a. routrar, displayer och datorer. Vilka produkter som träffas av denna skatt finns specificerade på Skatteverkets hemsida.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('För mer specifik information vänligen besök '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('https://skatteverket.se/foretagochorganisationer/skatter/punktskatter/kemikalieskatt.4.3152d9ac158968eb8fd1f3c.html'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Skatteverkets hemsida')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.'),
										A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
										$rtfeldman$elm_css$Html$Styled$text('Vid övriga frågor eller funderingar kontaktas Galina Pimenova:'),
										A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
										$rtfeldman$elm_css$Html$Styled$text('Tel: 054-444 2000'),
										A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
										$rtfeldman$elm_css$Html$Styled$text('E-post: '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:galina.pimenova@specialelektronik.se')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('galina.pimenova@specialelektronik.se')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Image$viewLarge = A2(
	$author$project$Image$viewSizeHelper,
	$author$project$SE$UI$Image$V2$create(
		{e7: 960, gE: 960}),
	2);
var $author$project$Page$Product$viewModal = F4(
	function (hosts, modal, maybeEmbed, productCode) {
		var _v0 = _Utils_Tuple2(modal, maybeEmbed);
		switch (_v0.a) {
			case 0:
				var _v1 = _v0.a;
				return $rtfeldman$elm_css$Html$Styled$text('');
			case 1:
				if (_v0.b.$ === 1) {
					var _v2 = _v0.a;
					var _v3 = _v0.b;
					return $rtfeldman$elm_css$Html$Styled$text('');
				} else {
					if (_v0.b.a.$ === 1) {
						var _v4 = _v0.a;
						var currentImage = _v0.b.a.a;
						return A2(
							$author$project$SE$UI$Modal$fullWidthModal,
							$author$project$Page$Product$ChangedModal(0),
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$Global$descendants(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Css$Global$selector,
													'figure',
													_List_fromArray(
														[
															A2($rtfeldman$elm_css$Css$property, 'height', 'calc(100vh - 40px)'),
															A2($rtfeldman$elm_css$Css$property, 'max-width', 'calc(100% - 40px)'),
															A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto)
														])),
													$rtfeldman$elm_css$Css$Global$img(
													_List_fromArray(
														[
															A2($rtfeldman$elm_css$Css$property, 'object-fit', 'contain'),
															A2($rtfeldman$elm_css$Css$property, 'object-position', 'top'),
															$rtfeldman$elm_css$Css$width(
															$rtfeldman$elm_css$Css$pct(100)),
															$rtfeldman$elm_css$Css$height(
															$rtfeldman$elm_css$Css$pct(100)),
															$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block)
														]))
												]))
										]),
									_List_Nil,
									_List_fromArray(
										[
											A2($author$project$Image$viewLarge, productCode, currentImage)
										]))
								]));
					} else {
						var _v5 = _v0.a;
						var currentVideo = _v0.b.a.a;
						return A2(
							$author$project$SE$UI$Modal$fullWidthModal,
							$author$project$Page$Product$ChangedModal(0),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$map,
									$elm$core$Basics$never,
									A2($author$project$Embed$Video$toHtml, hosts, currentVideo))
								]));
					}
				}
			case 2:
				var _v6 = _v0.a;
				return $author$project$Page$viewChemicalTaxModal(
					$author$project$Page$Product$ChangedModal(0));
			default:
				var _v7 = _v0.a;
				return $author$project$Page$viewBidModal(
					$author$project$Page$Product$ChangedModal(0));
		}
	});
var $author$project$Page$Product$SetActiveEmbedIndex = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Product$viewImageThumbnail = F3(
	function (activeIndex, index, img) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('thumbnail', true),
							_Utils_Tuple2('image-thumbnail', true),
							_Utils_Tuple2(
							'is-active',
							_Utils_eq(activeIndex, index))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Page$Product$SetActiveEmbedIndex(index))
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Image$viewThumbnail,
					58,
					'Bild ' + $elm$core$String$fromInt(index + 1),
					img)
				]));
	});
var $author$project$SE$UI$Icon$V2$playCircle = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z'])));
var $rtfeldman$elm_css$Css$translate2 = F2(
	function (tx, ty) {
		return {
			gu: 0,
			iY: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'translate',
				_List_fromArray(
					[tx.iY, ty.iY]))
		};
	});
var $author$project$Embed$Video$viewThumbnail = function (_v0) {
	var thumbnail_url = _v0.gp;
	return (thumbnail_url === '') ? $rtfeldman$elm_css$Html$Styled$text('') : A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$img(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$pct(100))),
								A2($rtfeldman$elm_css$Css$property, 'object-fit', 'cover'),
								A2($rtfeldman$elm_css$Css$property, 'filter', 'brightness(50%)'),
								$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius)
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'icon',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$left(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$transform(
								A2(
									$rtfeldman$elm_css$Css$translate2,
									$rtfeldman$elm_css$Css$pct(-50),
									$rtfeldman$elm_css$Css$pct(-50))),
								$rtfeldman$elm_css$Css$Global$children(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$svg(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$important(
												$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white))
											]))
									]))
							]))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$src(thumbnail_url)
					]),
				_List_Nil),
				$author$project$SE$UI$Icon$V2$toHtml(
				A2($author$project$SE$UI$Icon$V2$withSize, 3, $author$project$SE$UI$Icon$V2$playCircle))
			]));
};
var $author$project$Page$Product$viewVideoThumbnail = F3(
	function (activeIndex, index, video) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('thumbnail', true),
							_Utils_Tuple2('video-thumbnail', true),
							_Utils_Tuple2(
							'is-active',
							_Utils_eq(activeIndex, index))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Page$Product$SetActiveEmbedIndex(index))
				]),
			_List_fromArray(
				[
					$author$project$Embed$Video$viewThumbnail(video)
				]));
	});
var $author$project$Page$Product$viewThumbnail = F3(
	function (activeIndex, index, embed) {
		if (!embed.$) {
			var v = embed.a;
			return A3($author$project$Page$Product$viewVideoThumbnail, activeIndex, index, v);
		} else {
			var i = embed.a;
			return A3($author$project$Page$Product$viewImageThumbnail, activeIndex, index, i);
		}
	});
var $author$project$Page$Product$viewThumbnails = F2(
	function (activeIndex, embeds) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('thumbnails')
				]),
			A2(
				$elm$core$List$indexedMap,
				$author$project$Page$Product$viewThumbnail(activeIndex),
				embeds));
	});
var $author$project$Page$Product$viewProduct = F2(
	function (meta, product) {
		var activeEmbedIndex = meta.aj;
		var modal = meta.cH;
		var cookieHosts = meta.eO;
		var metadata = $author$project$Product$metadata(product);
		var externalId = $author$project$ExternalId$toString(
			$author$project$Product$externalId(product));
		var currentEmbed = A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, carry) {
					var k = _v0.a;
					var embed = _v0.b;
					return _Utils_eq(k, activeEmbedIndex) ? $elm$core$Maybe$Just(embed) : carry;
				}),
			$elm$core$Maybe$Nothing,
			A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, metadata.eY));
		return $author$project$SE$UI$Columns$columns(
			_List_fromArray(
				[
					$author$project$SE$UI$Columns$defaultColumn(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('embeds')
								]),
							_List_fromArray(
								[
									A2($author$project$Page$Product$viewThumbnails, activeEmbedIndex, metadata.eY),
									A3($author$project$Page$Product$viewEmbed, cookieHosts, currentEmbed, externalId)
								]))
						])),
					$author$project$SE$UI$Columns$defaultColumn(
					_List_fromArray(
						[
							A2($author$project$Page$Product$viewContent, meta, product),
							A4($author$project$Page$Product$viewModal, cookieHosts, modal, currentEmbed, externalId)
						]))
				]));
	});
var $author$project$Page$Product$viewLoaded = F2(
	function (meta, product) {
		var maybeUser = meta.dM;
		var related = meta.a2;
		var metadata = $author$project$Product$metadata(product);
		return {
			eN: A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$author$project$SE$UI$Section$section,
						$author$project$Page$Product$sectionStyles,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Breadcrumb$toHtml(
										$author$project$Breadcrumb$Product(product)),
										A2($author$project$Page$Product$viewProduct, meta, product)
									]))
							])),
						A4(
						$author$project$Page$Product$viewAside,
						maybeUser,
						related,
						metadata.gQ,
						$author$project$Product$minimal(product))
					])),
			gr: metadata.hT
		};
	});
var $author$project$Page$Product$viewLoadingLineHelper = function (_v0) {
	var height = _v0.e7;
	var bottomSpace = _v0.bJ;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$px(height)),
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$px(bottomSpace))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loadingBg', true)
					]))
			]),
		_List_Nil);
};
var $author$project$Page$Product$viewLoadingLine = $author$project$Page$Product$viewLoadingLineHelper(
	{bJ: 7, e7: 18});
var $author$project$Page$Product$viewLoadingPrice = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_Nil,
	_List_fromArray(
		[
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$px(60)),
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$px(7)),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(50))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loadingBg', true)
						]))
				]),
			_List_Nil),
			$author$project$Page$Product$viewLoadingLine,
			$author$project$Page$Product$viewLoadingLine,
			$author$project$Page$Product$viewLoadingLineHelper(
			{bJ: 7, e7: 48})
		]));
var $author$project$Page$Product$viewLoadingSubTitle = $author$project$Page$Product$viewLoadingLineHelper(
	{bJ: 7, e7: 24});
var $author$project$Page$Product$viewLoadingTitle = $author$project$Page$Product$viewLoadingLineHelper(
	{bJ: 27, e7: 33});
var $author$project$Page$Product$viewLoading = function (isLoggedIn) {
	return {
		eN: A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$author$project$SE$UI$Section$section,
					$author$project$Page$Product$sectionStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('loading', true)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Container$container,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$Breadcrumb$toHtml($author$project$Breadcrumb$Loading),
									$author$project$SE$UI$Columns$columns(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('embeds')
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$class('thumbnails')
																]),
															_List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$div,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$classList(
																			_List_fromArray(
																				[
																					_Utils_Tuple2('thumbnail', true),
																					_Utils_Tuple2('image-thumbnail', true)
																				]))
																		]),
																	_List_fromArray(
																		[
																			A3($author$project$Image$viewMaybeThumbnail, 58, '', $elm$core$Maybe$Nothing)
																		]))
																])),
															A3($author$project$Page$Product$viewEmbed, _List_Nil, $elm$core$Maybe$Nothing, '')
														]))
												])),
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													$author$project$Page$Product$viewLoadingLine,
													$author$project$Page$Product$viewLoadingTitle,
													$author$project$SE$UI$Columns$columns(
													_List_fromArray(
														[
															A2(
															$author$project$SE$UI$Columns$column,
															_List_fromArray(
																[
																	_Utils_Tuple2(0, 7)
																]),
															_List_fromArray(
																[$author$project$Page$Product$viewLoadingLine, $author$project$Page$Product$viewLoadingLine])),
															A2(
															$author$project$SE$UI$Columns$column,
															_List_fromArray(
																[
																	_Utils_Tuple2(0, 7)
																]),
															_List_Nil)
														])),
													A2($author$project$Helpers$viewIf, isLoggedIn, $author$project$Page$Product$viewLoadingPrice),
													A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
													$author$project$Page$Product$viewLoadingSubTitle,
													$author$project$Page$Product$viewLoadingLine,
													$author$project$Page$Product$viewLoadingLine,
													$author$project$Page$Product$viewLoadingLine
												]))
										]))
								]))
						]))
				])),
		gr: 'Laddar produkt...'
	};
};
var $author$project$Page$Product$view = function (model) {
	var viewLoadedHelper = $author$project$Page$Product$viewLoaded(
		{
			aj: model.aj,
			cj: model.cj,
			aE: model.aE,
			aG: model.aG,
			aV: model.aV,
			eO: $author$project$Session$cookieHosts(model.K),
			dM: $author$project$Session$user(model.K),
			cH: model.cH,
			a2: model.a2,
			aA: model.aA,
			gG: $author$project$Session$zone(model.K)
		});
	var _v0 = model.b2;
	switch (_v0.$) {
		case 0:
			return $author$project$Page$Product$viewLoading(
				!_Utils_eq(
					$elm$core$Maybe$Nothing,
					$author$project$Session$user(model.K)));
		case 3:
			return $author$project$Page$Product$viewFailed;
		case 2:
			var product = _v0.a;
			return viewLoadedHelper(product);
		default:
			var product = _v0.a;
			return viewLoadedHelper(product);
	}
};
var $elm$virtual_dom$VirtualDom$lazy8 = _VirtualDom_lazy8;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp7 = F8(
	function (fn, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			A7(fn, arg1, arg2, arg3, arg4, arg5, arg6, arg7));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy7 = F8(
	function (fn, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A9($elm$virtual_dom$VirtualDom$lazy8, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp7, fn, arg1, arg2, arg3, arg4, arg5, arg6, arg7));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy7 = $rtfeldman$elm_css$VirtualDom$Styled$lazy7;
var $rtfeldman$elm_css$Html$Styled$main_ = $rtfeldman$elm_css$Html$Styled$node('main');
var $author$project$Page$Products$modeToCssClass = function (mode) {
	switch (mode.$) {
		case 0:
			return 'mode-category';
		case 3:
			var sticker = mode.b;
			return 'mode-sticker mode-sticker-' + $author$project$Sticker$toFilterValue(sticker);
		case 1:
			return 'mode-search';
		default:
			return 'mode-brand';
	}
};
var $author$project$Page$Products$title = function (mode) {
	switch (mode.$) {
		case 0:
			var zipper = mode.a;
			return $author$project$Category$name(
				$zwilias$elm_rosetree$Tree$Zipper$label(zipper));
		case 3:
			var zipper = mode.a;
			var sticker = mode.b;
			return $author$project$Sticker$plural(sticker) + (' inom ' + $author$project$Category$name(
				$zwilias$elm_rosetree$Tree$Zipper$label(zipper)));
		case 2:
			if (mode.a.b.$ === 1) {
				var _v1 = mode.a;
				var _v2 = _v1.b;
				return $author$project$Helpers$nbr;
			} else {
				var _v3 = mode.a;
				var brand = _v3.b.a;
				return $author$project$Brand$name(brand);
			}
		default:
			if (!mode.a.$) {
				var q = mode.a.a;
				return 'Söker efter \"' + (q + '\"...');
			} else {
				var q = mode.a.a;
				return 'Sökresultat för \"' + (q + '\"');
			}
	}
};
var $rtfeldman$elm_css$Html$Styled$article = $rtfeldman$elm_css$Html$Styled$node('article');
var $author$project$OrderBy$allForGuests = _List_fromArray(
	[
		$author$project$OrderBy$Recent,
		$author$project$OrderBy$ProductCode(0),
		$author$project$OrderBy$ProductCode(1),
		$author$project$OrderBy$Name(0),
		$author$project$OrderBy$Name(1),
		$author$project$OrderBy$Relevance
	]);
var $author$project$Session$layout = function (session) {
	if (!session.$) {
		var user_ = session.b;
		return user_.c0.hK;
	} else {
		var ui = session.b;
		return ui.hK;
	}
};
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $author$project$Filter$selectionsSize = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(
		A2($elm$core$Dict$remove, 'categories', dict));
};
var $author$project$Page$Products$ExpandDescription = {$: 10};
var $author$project$Page$Products$viewEmbed = function (_v0) {
	var embed = _v0.a;
	var hosts = _v0.b;
	if (!embed.$) {
		var video = embed.a;
		return A2(
			$rtfeldman$elm_css$Html$Styled$map,
			$elm$core$Basics$never,
			A2($author$project$Embed$Video$toHtml, hosts, video));
	} else {
		var image = embed.a;
		return A4(
			$author$project$Image$viewCustom,
			A2(
				$author$project$SE$UI$Image$V2$withAspectRatio,
				_Utils_Tuple2(16, 9),
				$author$project$SE$UI$Image$V2$create(
					{e7: 200, gE: 355})),
			1,
			'',
			image);
	}
};
var $author$project$Page$Products$viewDescriptionHelper = F3(
	function (isExpanded, maybeEmbedAndHosts, html) {
		var containerStyles = _List_fromArray(
			[
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'content',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$ch(100)),
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark)
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'has-aspect-ratio',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$descendants(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$img(
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$property, 'object-position', 'top')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'expand-desc',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$bottom(
								$rtfeldman$elm_css$Css$rem(-1.5))
							]))
					])),
				A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
				A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1.5rem'),
				$author$project$SE$UI$Utils$widescreen(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'auto 360px')
					])),
				$rtfeldman$elm_css$Css$batch(
				(!isExpanded) ? _List_fromArray(
					[
						$rtfeldman$elm_css$Css$maxHeight(
						$rtfeldman$elm_css$Css$rem(6)),
						$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden)
					]) : _List_Nil)
			]);
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[$author$project$SE$UI$Utils$block]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					containerStyles,
					_List_Nil,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$map, $elm$core$Basics$never, html),
							A2(
							$elm$core$Maybe$withDefault,
							$rtfeldman$elm_css$Html$Styled$text(''),
							A2($elm$core$Maybe$map, $author$project$Page$Products$viewEmbed, maybeEmbedAndHosts))
						])),
					(!isExpanded) ? A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$button,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
								$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]),
						$author$project$SE$UI$Font$textButtonStyles),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$Products$ExpandDescription),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('expand-desc')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Visa mer'),
							$author$project$SE$UI$Icon$V2$toHtml(
							A2($author$project$SE$UI$Icon$V2$withSize, 1, $author$project$SE$UI$Icon$V2$angleDown))
						])) : $rtfeldman$elm_css$Html$Styled$text('')
				]));
	});
var $author$project$Page$Products$viewDescription = F2(
	function (maybeEmbedAndHosts, desc) {
		switch (desc.$) {
			case 0:
				return $rtfeldman$elm_css$Html$Styled$text('');
			case 1:
				var html = desc.a;
				return A3($author$project$Page$Products$viewDescriptionHelper, false, maybeEmbedAndHosts, html);
			default:
				var html = desc.a;
				return A3($author$project$Page$Products$viewDescriptionHelper, true, maybeEmbedAndHosts, html);
		}
	});
var $author$project$SE$UI$Icon$V2$table = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		448,
		512,
		_List_fromArray(
			['M495.5 120h-479C7.4 120 0 112.5 0 103.4V48.5C0 39.4 7.4 32 16.5 32h478.9c9.1 0 16.5 7.4 16.5 16.5v54.9c.1 9.1-7.3 16.6-16.4 16.6zM495.5 240h-479C7.4 240 0 232.5 0 223.4v-54.9c0-9.1 7.4-16.5 16.5-16.5h478.9c9.1 0 16.5 7.4 16.5 16.5v54.9c.1 9.1-7.3 16.6-16.4 16.6zM495.5 360h-479C7.4 360 0 352.6 0 343.5v-54.9c0-9.1 7.4-16.5 16.5-16.5h478.9c9.1 0 16.5 7.4 16.5 16.5v54.9c.1 9.1-7.3 16.5-16.4 16.5zM495.5 480h-479C7.4 480 0 472.6 0 463.5v-54.9c0-9.1 7.4-16.5 16.5-16.5h478.9c9.1 0 16.5 7.4 16.5 16.5v54.9c.1 9.1-7.3 16.5-16.4 16.5z'])));
var $author$project$SE$UI$Icon$V2$thLarge = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z'])));
var $author$project$SE$UI$Icon$V2$thList = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z'])));
var $author$project$Layout$icon = F2(
	function (layout, size) {
		switch (layout) {
			case 0:
				return A2($author$project$SE$UI$Icon$V2$withSize, size, $author$project$SE$UI$Icon$V2$thLarge);
			case 1:
				return A2($author$project$SE$UI$Icon$V2$withSize, size, $author$project$SE$UI$Icon$V2$thList);
			default:
				return A2($author$project$SE$UI$Icon$V2$withSize, size, $author$project$SE$UI$Icon$V2$table);
		}
	});
var $author$project$Page$Products$viewLayoutButton = F2(
	function (current, layout) {
		var mods = _Utils_eq(current, layout) ? _List_fromArray(
			[
				$author$project$SE$UI$Buttons$Color(2)
			]) : _List_Nil;
		return A3(
			$author$project$SE$UI$Buttons$button,
			mods,
			$elm$core$Maybe$Just(
				$author$project$Page$Products$GotSessionMsg(
					$author$project$Session$UpdatedLayout(layout))),
			_List_fromArray(
				[
					$author$project$SE$UI$Icon$V2$toHtml(
					A2($author$project$Layout$icon, layout, 0)),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Layout$toLabel(layout))
						]))
				]));
	});
var $author$project$Page$Products$viewLayoutButtons = function (current) {
	return A2(
		$elm$core$List$map,
		$author$project$Page$Products$viewLayoutButton(current),
		$author$project$Layout$all);
};
var $author$project$Page$Products$viewDesktopSortAndLayout = F4(
	function (allOrderBy, orderBy, totalLabel, layout) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block)
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Level$level,
					_List_fromArray(
						[
							$author$project$SE$UI$Level$item(
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$author$project$SE$UI$Font$bodySizeRem(-1)
										]),
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(totalLabel)
										]))
								])),
							$author$project$SE$UI$Level$item(
							_List_fromArray(
								[
									$author$project$SE$UI$Form$Input$toHtml(
									A3(
										$author$project$SE$UI$Form$Input$select,
										A2($elm$core$Basics$composeL, $author$project$Page$Products$GotSessionMsg, $author$project$Session$UpdatedOrderBy),
										A2(
											$elm$core$List$map,
											function (ob) {
												return {
													cB: $author$project$OrderBy$toSwedishString(ob),
													iY: $author$project$OrderBy$toString(ob)
												};
											},
											allOrderBy),
										$author$project$OrderBy$toString(orderBy)))
								]))
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Level$item(
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Buttons$buttons,
									_List_fromArray(
										[0]),
									$author$project$Page$Products$viewLayoutButtons(layout))
								]))
						]))
				]));
	});
var $author$project$Page$Products$viewHeading = function (mode) {
	var title_ = $author$project$Page$Products$title(mode);
	var maybeImage = function () {
		if ((mode.$ === 2) && (!mode.a.b.$)) {
			var _v1 = mode.a;
			var brand = _v1.b.a;
			return $author$project$Brand$logo(brand);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$h1,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$block,
				$author$project$SE$UI$Font$titleSizeEm(6),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'figure',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
							]))
					]))
			]),
		_List_Nil,
		$elm$core$List$singleton(
			A2(
				$elm$core$Maybe$withDefault,
				$rtfeldman$elm_css$Html$Styled$text(title_),
				A2(
					$elm$core$Maybe$andThen,
					A2($author$project$Image$viewSimple, 0, title_),
					maybeImage))));
};
var $author$project$Page$Products$ToggledFilterMenuOpen = {$: 8};
var $author$project$SE$UI$Icon$V2$slidersH = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z'])));
var $author$project$Page$Products$viewTabletSortAndFilter = F4(
	function (allOrderBy, orderBy, totalLabel, size) {
		var badge = (size > 0) ? A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$black),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$px(24)),
					$rtfeldman$elm_css$Css$lineHeight(
					$rtfeldman$elm_css$Css$px(24)),
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
					$rtfeldman$elm_css$Css$borderRadius(
					$rtfeldman$elm_css$Css$pct(50)),
					$rtfeldman$elm_css$Css$marginLeft(
					$rtfeldman$elm_css$Css$px(4))
				]),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$elm$core$String$fromInt(size))
				])) : $rtfeldman$elm_css$Html$Styled$text('');
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Level$level,
					_List_fromArray(
						[
							$author$project$SE$UI$Level$item(
							_List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$author$project$SE$UI$Font$bodySizeRem(-1)
										]),
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(totalLabel)
										]))
								])),
							$author$project$SE$UI$Level$item(
							_List_fromArray(
								[
									A3(
									$author$project$SE$UI$Buttons$button,
									_List_fromArray(
										[$author$project$SE$UI$Buttons$Fullwidth]),
									$elm$core$Maybe$Just($author$project$Page$Products$ToggledFilterMenuOpen),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Filter & Kategorier'),
											badge,
											$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$slidersH)
										]))
								])),
							$author$project$SE$UI$Level$item(
							_List_fromArray(
								[
									$author$project$SE$UI$Form$Input$toHtml(
									A3(
										$author$project$SE$UI$Form$Input$select,
										A2($elm$core$Basics$composeL, $author$project$Page$Products$GotSessionMsg, $author$project$Session$UpdatedOrderBy),
										A2(
											$elm$core$List$map,
											function (ob) {
												return {
													cB: $author$project$OrderBy$toSwedishString(ob),
													iY: $author$project$OrderBy$toString(ob)
												};
											},
											allOrderBy),
										$author$project$OrderBy$toString(orderBy)))
								]))
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$Products$viewHeader = function (model) {
	var totalLabel = function () {
		var _v0 = model.f;
		if ((_v0.$ === 1) && (!_v0.a.$)) {
			return $author$project$Helpers$nbr;
		} else {
			return $elm$core$String$fromInt(model.F) + ' produkter';
		}
	}();
	var selectionsSize = $author$project$Filter$selectionsSize(model.iD);
	var orderBy = model.ia;
	var maybeEmbedAndHosts = A2(
		$elm$core$Maybe$map,
		function (embed_) {
			return _Utils_Tuple2(
				embed_,
				$author$project$Session$cookieHosts(model.K));
		},
		model.aH);
	var layout = $author$project$Session$layout(model.K);
	var allOrderBy = A2(
		$elm$core$Maybe$withDefault,
		$author$project$OrderBy$allForGuests,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always($author$project$OrderBy$all),
			$author$project$Session$userId(model.K)));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$header,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$rem(1.5))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$Products$viewHeading(model.f),
				A2($author$project$Page$Products$viewDescription, maybeEmbedAndHosts, model.eT),
				A2(
				$author$project$Helpers$viewIf,
				model.F > 0,
				A4($author$project$Page$Products$viewTabletSortAndFilter, allOrderBy, orderBy, totalLabel, selectionsSize)),
				A2(
				$author$project$Helpers$viewIf,
				model.F > 0,
				A4($author$project$Page$Products$viewDesktopSortAndLayout, allOrderBy, orderBy, totalLabel, layout))
			]));
};
var $author$project$Page$Products$ClearedSelections = {$: 6};
var $author$project$Page$Products$GotFilterMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Constants$canonicalPartnerZoneUrl = 'http://shop.dev1';
var $author$project$Filter$isSelectionsEmpty = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$isEmpty(
		A2($elm$core$Dict$remove, 'categories', dict));
};
var $author$project$Page$Products$viewSearchTips = $rtfeldman$elm_css$Html$Styled$text('');
var $author$project$Page$Products$extractTerm = function (s) {
	if (!s.$) {
		var term = s.a;
		return _Utils_Tuple2(term, $rtfeldman$elm_css$Html$Styled$text);
	} else {
		var suggestion = s.a;
		return _Utils_Tuple2(
			suggestion,
			A2(
				$elm$core$Basics$composeR,
				$rtfeldman$elm_css$Html$Styled$text,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$rtfeldman$elm_css$Html$Styled$strong(_List_Nil))));
	}
};
var $author$project$Page$Products$hasSuggestions = function (suggestions) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (s, acc) {
				if (s.$ === 1) {
					return true;
				} else {
					return acc;
				}
			}),
		false,
		suggestions);
};
var $author$project$Page$Products$viewSpellingSuggestion = function (model) {
	if ($elm$core$List$isEmpty(model.aq) || (!$author$project$Page$Products$hasSuggestions(model.aq))) {
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('')
			]);
	} else {
		var termsFuncs = A2($elm$core$List$map, $author$project$Page$Products$extractTerm, model.aq);
		var route = $author$project$Page$Products$toRoute(
			_Utils_update(
				model,
				{
					f: $author$project$Page$Products$SearchMode(
						$author$project$Page$Products$Searched(
							A2(
								$elm$core$String$join,
								' ',
								A2($elm$core$List$map, $elm$core$Tuple$first, termsFuncs))))
				}));
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('Menade du: '),
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$author$project$Route$href(route)
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var s = _v0.a;
						var fn = _v0.b;
						return fn(s + ' ');
					},
					termsFuncs))
			]);
	}
};
var $author$project$Page$Products$viewNoResult = function (model) {
	var selectionSuffix = (!$author$project$Filter$isSelectionsEmpty(model.iD)) ? ' och ditt urval av filter' : '';
	var titleSuffix = function () {
		var _v0 = model.f;
		if (_v0.$ === 1) {
			var q = _v0.a;
			return ' som matchar \'' + ($author$project$Page$Products$searchQuery(q) + ('\'' + selectionSuffix));
		} else {
			return '';
		}
	}();
	var filterTip = (!$author$project$Filter$isSelectionsEmpty(model.iD)) ? A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$strong,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
						$rtfeldman$elm_css$Css$hover(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color(
								$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick(
						$author$project$Page$Products$GotFilterMsg($author$project$Page$Products$ClearedSelections))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Rensa alla valda filter')
					]))
			])) : $rtfeldman$elm_css$Html$Styled$text('');
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$article,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexGrow(
				$rtfeldman$elm_css$Css$int(1))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$header,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1.5))
							]),
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Content$content,
								_List_Nil,
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$author$project$SE$UI$Font$bodySizeRem(3)
											]),
										_List_Nil,
										$author$project$Page$Products$viewSpellingSuggestion(model)),
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Vi kan inte hitta några produkter' + titleSuffix)
											])),
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$author$project$SE$UI$Font$bodySizeRem(3)
											]),
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Tips:')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$ul,
										_List_Nil,
										_List_fromArray(
											[
												filterTip,
												A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Kontrollera att du stavat rätt')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Prova att ta bort ett eller flera sökord')
													]))
											]))
									])),
								$author$project$Contactbox$view(
								{
									bI: 'Jag sökte efter en produkt på er partnerzon men hittade den inte: ' + ($author$project$Constants$canonicalPartnerZoneUrl + $author$project$Route$toString(
										$author$project$Page$Products$toRoute(model))),
									eM: 'Säljavdelningen',
									fc: $elm$core$Maybe$Just('/assets/images/innesalj.jpg'),
									fJ: '30',
									f_: _List_fromArray(
										['order']),
									c5: 'Jag hittar inte en produkt',
									gr: 'Behöver du hjälp? Hör av dig!'
								}),
								$author$project$Page$Products$viewSearchTips
							]))
					]))
			]));
};
var $author$project$Page$Products$containerCssClass = F2(
	function (layout, mode) {
		var zipperToString = function (z) {
			return $elm$core$String$fromInt(
				$author$project$Id$toInt(
					$author$project$Category$id(
						$zwilias$elm_rosetree$Tree$label(
							$zwilias$elm_rosetree$Tree$Zipper$tree(z)))));
		};
		return A2(
			$elm$core$String$join,
			'-',
			A2(
				$elm$core$List$cons,
				$author$project$Layout$toString(layout),
				function () {
					switch (mode.$) {
						case 0:
							var zipper = mode.a;
							return _List_fromArray(
								[
									'category',
									zipperToString(zipper)
								]);
						case 3:
							var zipper = mode.a;
							var sticker = mode.b;
							return _List_fromArray(
								[
									'sticker',
									$author$project$Sticker$toFilterValue(sticker),
									zipperToString(zipper)
								]);
						case 1:
							if (!mode.a.$) {
								var q = mode.a.a;
								return _List_fromArray(
									[
										'searching',
										$elm$url$Url$percentEncode(q)
									]);
							} else {
								var q = mode.a.a;
								return _List_fromArray(
									[
										'searched',
										$elm$url$Url$percentEncode(q)
									]);
							}
						default:
							var _v1 = mode.a;
							var slug = _v1.a;
							return _List_fromArray(
								[
									'brand',
									$author$project$Slug$toString(slug)
								]);
					}
				}()));
	});
var $author$project$Page$Products$View$viewListItemStatus = F3(
	function (index, loadedFn, status) {
		return _Utils_Tuple2(
			'list-' + $elm$core$String$fromInt(index),
			A4($author$project$Page$Products$View$viewStatusHelper, $author$project$Page$Products$View$listItemContainerStyles, loadedFn, index, status));
	});
var $author$project$Page$Products$View$list = F4(
	function (cssClass, config, session, items) {
		return A3(
			$rtfeldman$elm_css$Html$Styled$Keyed$node,
			'div',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('products'),
					$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Page$Products$View$listStyles),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(cssClass, true)
						]))
				]),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var rowNo = _v0.a;
					var v = _v0.b;
					return A3(
						$author$project$Page$Products$View$viewListItemStatus,
						rowNo,
						A3(
							$author$project$Page$Products$View$listItem,
							config.iF,
							config.gN(rowNo),
							$author$project$Session$user(session)),
						v);
				},
				$elm$core$Dict$toList(items)));
	});
var $author$project$Analytics$Bargains = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Analytics$BrandPage = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Analytics$Campaigns = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Analytics$Category = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Analytics$News = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Analytics$NoSource = {$: 12};
var $author$project$Analytics$SearchResult = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Products$modeToAnalyticsSource = function (mode) {
	switch (mode.$) {
		case 0:
			var zipper = mode.a;
			return $author$project$Analytics$Category(
				$author$project$Category$id(
					$zwilias$elm_rosetree$Tree$Zipper$label(zipper)));
		case 1:
			return $author$project$Analytics$SearchResult;
		case 2:
			if (mode.a.b.$ === 1) {
				var _v1 = mode.a;
				var _v2 = _v1.b;
				return $elm$core$Basics$always($author$project$Analytics$NoSource);
			} else {
				var _v3 = mode.a;
				var brand = _v3.b.a;
				return $author$project$Analytics$BrandPage(brand);
			}
		default:
			switch (mode.b) {
				case 0:
					var zipper = mode.a;
					var _v4 = mode.b;
					return $author$project$Analytics$News(
						$author$project$Category$id(
							$zwilias$elm_rosetree$Tree$Zipper$label(zipper)));
				case 2:
					var zipper = mode.a;
					var _v5 = mode.b;
					return $author$project$Analytics$Campaigns(
						$author$project$Category$id(
							$zwilias$elm_rosetree$Tree$Zipper$label(zipper)));
				default:
					var zipper = mode.a;
					var _v6 = mode.b;
					return $author$project$Analytics$Bargains(
						$author$project$Category$id(
							$zwilias$elm_rosetree$Tree$Zipper$label(zipper)));
			}
	}
};
var $author$project$Page$Products$View$tableItem = F4(
	function (config, maybeUser, index, _v0) {
		var product = _v0.b2;
		var metadata = $author$project$Product$metadata(product);
		var loggedInCells = function () {
			if (!maybeUser.$) {
				var user = maybeUser.a;
				var priceCells = function () {
					var _v2 = $author$project$Product$prices(product);
					if (_v2.$ === 1) {
						return _List_fromArray(
							[
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$td,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
									]),
								_List_fromArray(
									[
										$author$project$Product$viewMissingPrice(
										$author$project$Product$externalId(product))
									]))
							]);
					} else {
						if (!_v2.a.$) {
							var _v3 = _v2.a;
							return _List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$td,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2)
										]),
									_List_fromArray(
										[
											$author$project$Product$viewMissingPrice(
											$author$project$Product$externalId(product))
										]))
								]);
						} else {
							var data = _v2.a.a;
							return _List_fromArray(
								[
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$td,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
										]),
									_List_Nil,
									_List_fromArray(
										[
											A4($author$project$Price$viewSmallTag, metadata.d8, user.c0.gA, user.c0.ga, data)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											A4(
											$author$project$Page$Products$View$viewAddToCart,
											config.iF,
											config.gN(index),
											user,
											product)
										]))
								]);
						}
					}
				}();
				return A2(
					$elm$core$List$cons,
					A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Page$Products$View$viewAvailability,
								$author$project$Product$externalId(product),
								$author$project$Product$availability(product))
							])),
					priceCells);
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$author$project$Brand$name(metadata.dp))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$Page$Products$View$viewStickersContainer, false, metadata.d8),
								$author$project$Page$Products$View$viewName(product)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Page$Products$View$viewExternalId(
								$author$project$Product$externalId(product))
							]))
					]),
				loggedInCells));
	});
var $author$project$Page$Products$View$viewTableItemStatus = F3(
	function (loadedFn, index, status) {
		if (!status.$) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id(
						$elm$core$String$fromInt(index))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(6)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('loading...')
							]))
					]));
		} else {
			var a = status.a;
			return A2(loadedFn, index, a);
		}
	});
var $author$project$Page$Products$View$table = F4(
	function (cssClass, config, session, items) {
		var maybeUser = $author$project$Session$user(session);
		var defaultHeaderCells = _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Tillverkare')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Benämning')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Artikelkod')
					]))
			]);
		var headerCells = function () {
			if (maybeUser.$ === 1) {
				return defaultHeaderCells;
			} else {
				return _Utils_ap(
					defaultHeaderCells,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Lagersaldo')
								])),
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$td,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
								]),
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Pris')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('')
								]))
						]));
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('products'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(cssClass, true)
						]))
				]),
			$elm$core$List$singleton(
				$author$project$SE$UI$Table$V2$toHtml(
					A2(
						$author$project$SE$UI$Table$V2$withHead,
						headerCells,
						A2(
							$author$project$SE$UI$Table$V2$withModifiers,
							_List_fromArray(
								[0, 1]),
							A2(
								$author$project$SE$UI$Table$V2$body,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
												$rtfeldman$elm_css$Css$Global$children(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$selector,
														'tbody tr',
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$height(
																$rtfeldman$elm_css$Css$px(67))
															])),
														A2(
														$rtfeldman$elm_css$Css$Global$selector,
														'tbody td',
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
															]))
													]))
											]))
									]),
								A2(
									$elm$core$List$map,
									function (_v0) {
										var rowNo = _v0.a;
										var v = _v0.b;
										return A3(
											$author$project$Page$Products$View$viewTableItemStatus,
											A2(
												$author$project$Page$Products$View$tableItem,
												config,
												$author$project$Session$user(session)),
											rowNo,
											v);
									},
									$elm$core$Dict$toList(items))))))));
	});
var $author$project$Page$Products$SelectedImage = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$Products$viewConfig = function (analyticsSource) {
	return {gN: analyticsSource, iC: $author$project$Page$Products$SelectedImage, iF: $author$project$Page$Products$GotSessionMsg};
};
var $author$project$Page$Products$ClickedLoadProducts = {$: 4};
var $author$project$Page$Products$viewLoadMoreProgress = F2(
	function (count, total) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
					$rtfeldman$elm_css$Css$marginBottom(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
					$author$project$SE$UI$Font$bodySizeRem(-2),
					$rtfeldman$elm_css$Css$before(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$light),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(4)),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(100)),
							A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(0.5))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darkGreen),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct((count / total) * 100)),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
							$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
							$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(4)),
							$rtfeldman$elm_css$Css$after(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(2)),
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$px(6)),
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$px(-2)),
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darkGreen),
									$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto)
								]))
						]),
					_List_Nil,
					_List_Nil),
					$rtfeldman$elm_css$Html$Styled$text(
					'Visar ' + ($elm$core$String$fromFloat(count) + (' av ' + $elm$core$String$fromFloat(total))))
				]));
	});
var $author$project$Page$Products$viewLoadMore = F2(
	function (count, total) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$maxWidth(
							$rtfeldman$elm_css$Css$pct(50)),
							$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
							$rtfeldman$elm_css$Css$marginRight($rtfeldman$elm_css$Css$auto)
						])),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$button(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$property, 'overflow-anchor', 'none')
								]))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Page$Products$viewLoadMoreProgress, count, total),
					(_Utils_cmp(count, total) < 0) ? A3(
					$author$project$SE$UI$Buttons$button,
					_List_fromArray(
						[
							$author$project$SE$UI$Buttons$Color(14)
						]),
					$elm$core$Maybe$Just($author$project$Page$Products$ClickedLoadProducts),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Ladda fler')
						])) : $rtfeldman$elm_css$Html$Styled$text('')
				]));
	});
var $author$project$Page$Products$viewProducts = function (model) {
	var config = $author$project$Page$Products$viewConfig(
		$author$project$Page$Products$modeToAnalyticsSource(model.f));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				function () {
				var _v0 = $author$project$Session$layout(model.K);
				switch (_v0) {
					case 0:
						return A4(
							$author$project$Page$Products$View$gallery,
							A2($author$project$Page$Products$containerCssClass, 0, model.f),
							config,
							model.K,
							model.hF);
					case 1:
						return A4(
							$author$project$Page$Products$View$list,
							A2($author$project$Page$Products$containerCssClass, 1, model.f),
							config,
							model.K,
							model.hF);
					default:
						return A4(
							$author$project$Page$Products$View$table,
							A2($author$project$Page$Products$containerCssClass, 2, model.f),
							config,
							model.K,
							model.hF);
				}
			}(),
				A2(
				$author$project$Page$Products$viewLoadMore,
				$elm$core$List$length(
					$elm$core$Dict$toList(model.hF)),
				model.F)
			]));
};
var $author$project$Page$Products$viewArticle = function (model) {
	var content = function () {
		var _v0 = model.F;
		if (!_v0) {
			return $author$project$Page$Products$viewNoResult(model);
		} else {
			return $author$project$Page$Products$viewProducts(model);
		}
	}();
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$article,
		_List_fromArray(
			[
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$flexGrow(
						$rtfeldman$elm_css$Css$int(1))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$Products$viewHeader(model),
				content
			]));
};
var $rtfeldman$elm_css$Html$Styled$aside = $rtfeldman$elm_css$Html$Styled$node('aside');
var $rtfeldman$elm_css$Css$borderBottom3 = $rtfeldman$elm_css$Css$prop3('border-bottom');
var $rtfeldman$elm_css$Css$Transitions$EaseOut = {$: 3};
var $rtfeldman$elm_css$Css$Transitions$easeOut = $rtfeldman$elm_css$Css$Transitions$EaseOut;
var $rtfeldman$elm_css$Css$Global$footer = $rtfeldman$elm_css$Css$Global$typeSelector('footer');
var $rtfeldman$elm_css$Html$Styled$footer = $rtfeldman$elm_css$Html$Styled$node('footer');
var $rtfeldman$elm_css$Css$Global$global = function (snippets) {
	return $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode(
		A3(
			$elm$virtual_dom$VirtualDom$node,
			'style',
			_List_Nil,
			$elm$core$List$singleton(
				$elm$virtual_dom$VirtualDom$text(
					$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
						$elm$core$List$singleton(
							$rtfeldman$elm_css$Css$Preprocess$stylesheet(snippets)))))));
};
var $rtfeldman$elm_css$Css$Global$html = $rtfeldman$elm_css$Css$Global$typeSelector('html');
var $rtfeldman$elm_css$Css$Global$label = $rtfeldman$elm_css$Css$Global$typeSelector('label');
var $rtfeldman$elm_css$Css$Transitions$Opacity = 73;
var $rtfeldman$elm_css$Css$Transitions$fullTransition = F4(
	function (animation, duration, delay, timing) {
		return {
			ck: animation,
			cs: $elm$core$Maybe$Just(delay),
			bM: duration,
			c8: $elm$core$Maybe$Just(timing)
		};
	});
var $rtfeldman$elm_css$Css$Transitions$opacity3 = $rtfeldman$elm_css$Css$Transitions$fullTransition(73);
var $rtfeldman$elm_css$Css$Transitions$Transform = 89;
var $rtfeldman$elm_css$Css$Transitions$transform3 = $rtfeldman$elm_css$Css$Transitions$fullTransition(89);
var $author$project$Page$Products$categoryTreeStyles = function (isForest) {
	return _List_fromArray(
		[
			$author$project$SE$UI$Utils$desktop(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$unset)
				])),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$rem(1),
			$rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$class,
					'is-link',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
							$rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$color(
									$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
								])),
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$span(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
											$rtfeldman$elm_css$Css$fontWeight(
											$rtfeldman$elm_css$Css$int(400))
										]))
								]))
						])),
					(!isForest) ? A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'li',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
						])) : A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'ul li',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'li.is-empty',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none))
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'ul li',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$rem(1))
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'li .is-link',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							A2(
							$rtfeldman$elm_css$Css$padding2,
							$rtfeldman$elm_css$Css$px(4),
							$rtfeldman$elm_css$Css$zero)
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$each,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$selector('li.active'),
							$rtfeldman$elm_css$Css$Global$selector('li.active-parent')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$each,
									_List_fromArray(
										[$rtfeldman$elm_css$Css$Global$a, $rtfeldman$elm_css$Css$Global$div]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontWeight(
											$rtfeldman$elm_css$Css$int(600))
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$each,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$selector('li.active'),
							$rtfeldman$elm_css$Css$Global$selector('li.active-parent li.active-parent')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$selector,
									'ul > li',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block)
										]))
								]))
						]))
				]))
		]);
};
var $author$project$Page$Products$SetCategory = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Products$filterLabelToHtml = F2(
	function (_v0, cat) {
		var currentId = $author$project$Category$id(cat);
		return _Utils_Tuple3(
			currentId,
			!$author$project$Category$productCount(cat),
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick(
						$author$project$Page$Products$GotFilterMsg(
							$author$project$Page$Products$SetCategory(currentId))),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('is-link')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Category$name(cat)),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								' (' + ($elm$core$String$fromInt(
									$author$project$Category$productCount(cat)) + ')'))
							]))
					])));
	});
var $zwilias$elm_rosetree$Tree$Zipper$fromForest = F2(
	function (t, ts) {
		return {l: ts, p: _List_Nil, w: _List_Nil, hp: t};
	});
var $author$project$Page$Products$hierarchy = F2(
	function (acc, maybeZipper) {
		hierarchy:
		while (true) {
			if (maybeZipper.$ === 1) {
				return acc;
			} else {
				var zipper = maybeZipper.a;
				var $temp$acc = _Utils_ap(
					acc,
					_List_fromArray(
						[
							$author$project$Category$id(
							$zwilias$elm_rosetree$Tree$Zipper$label(zipper))
						])),
					$temp$maybeZipper = $zwilias$elm_rosetree$Tree$Zipper$parent(zipper);
				acc = $temp$acc;
				maybeZipper = $temp$maybeZipper;
				continue hierarchy;
			}
		}
	});
var $zwilias$elm_rosetree$Tree$mapAccumulateHelp = F4(
	function (f, state, acc, stack) {
		mapAccumulateHelp:
		while (true) {
			var _v0 = acc.y;
			if (!_v0.b) {
				var node = A2(
					$zwilias$elm_rosetree$Tree$Tree,
					acc.cB,
					$elm$core$List$reverse(acc.d));
				if (!stack.b) {
					return _Utils_Tuple2(state, node);
				} else {
					var top = stack.a;
					var rest = stack.b;
					var $temp$f = f,
						$temp$state = state,
						$temp$acc = _Utils_update(
						top,
						{
							d: A2($elm$core$List$cons, node, top.d)
						}),
						$temp$stack = rest;
					f = $temp$f;
					state = $temp$state;
					acc = $temp$acc;
					stack = $temp$stack;
					continue mapAccumulateHelp;
				}
			} else {
				if (!_v0.a.b.b) {
					var _v2 = _v0.a;
					var d = _v2.a;
					var rest = _v0.b;
					var _v3 = A2(f, state, d);
					var state_ = _v3.a;
					var label_ = _v3.b;
					var $temp$f = f,
						$temp$state = state_,
						$temp$acc = _Utils_update(
						acc,
						{
							d: A2(
								$elm$core$List$cons,
								A2($zwilias$elm_rosetree$Tree$Tree, label_, _List_Nil),
								acc.d),
							y: rest
						}),
						$temp$stack = stack;
					f = $temp$f;
					state = $temp$state;
					acc = $temp$acc;
					stack = $temp$stack;
					continue mapAccumulateHelp;
				} else {
					var _v4 = _v0.a;
					var d = _v4.a;
					var cs = _v4.b;
					var rest = _v0.b;
					var _v5 = A2(f, state, d);
					var state_ = _v5.a;
					var label_ = _v5.b;
					var $temp$f = f,
						$temp$state = state_,
						$temp$acc = {d: _List_Nil, cB: label_, y: cs},
						$temp$stack = A2(
						$elm$core$List$cons,
						_Utils_update(
							acc,
							{y: rest}),
						stack);
					f = $temp$f;
					state = $temp$state;
					acc = $temp$acc;
					stack = $temp$stack;
					continue mapAccumulateHelp;
				}
			}
		}
	});
var $zwilias$elm_rosetree$Tree$mapAccumulate = F3(
	function (f, s, _v0) {
		var d = _v0.a;
		var cs = _v0.b;
		var _v1 = A2(f, s, d);
		var s_ = _v1.a;
		var d_ = _v1.b;
		return A4(
			$zwilias$elm_rosetree$Tree$mapAccumulateHelp,
			f,
			s_,
			{d: _List_Nil, cB: d_, y: cs},
			_List_Nil);
	});
var $zwilias$elm_rosetree$Tree$map = F2(
	function (f, t) {
		return A3(
			$zwilias$elm_rosetree$Tree$mapAccumulate,
			F2(
				function (_v0, e) {
					return _Utils_Tuple2(
						0,
						f(e));
				}),
			0,
			t).b;
	});
var $zwilias$elm_rosetree$Tree$restructureHelp = F4(
	function (fLabel, fTree, acc, stack) {
		restructureHelp:
		while (true) {
			var _v0 = acc.y;
			if (!_v0.b) {
				var node = A2(
					fTree,
					acc.cB,
					$elm$core$List$reverse(acc.d));
				if (!stack.b) {
					return node;
				} else {
					var top = stack.a;
					var rest = stack.b;
					var $temp$fLabel = fLabel,
						$temp$fTree = fTree,
						$temp$acc = _Utils_update(
						top,
						{
							d: A2($elm$core$List$cons, node, top.d)
						}),
						$temp$stack = rest;
					fLabel = $temp$fLabel;
					fTree = $temp$fTree;
					acc = $temp$acc;
					stack = $temp$stack;
					continue restructureHelp;
				}
			} else {
				if (!_v0.a.b.b) {
					var _v2 = _v0.a;
					var l = _v2.a;
					var rest = _v0.b;
					var $temp$fLabel = fLabel,
						$temp$fTree = fTree,
						$temp$acc = _Utils_update(
						acc,
						{
							d: A2(
								$elm$core$List$cons,
								A2(
									fTree,
									fLabel(l),
									_List_Nil),
								acc.d),
							y: rest
						}),
						$temp$stack = stack;
					fLabel = $temp$fLabel;
					fTree = $temp$fTree;
					acc = $temp$acc;
					stack = $temp$stack;
					continue restructureHelp;
				} else {
					var _v3 = _v0.a;
					var l = _v3.a;
					var cs = _v3.b;
					var rest = _v0.b;
					var $temp$fLabel = fLabel,
						$temp$fTree = fTree,
						$temp$acc = {
						d: _List_Nil,
						cB: fLabel(l),
						y: cs
					},
						$temp$stack = A2(
						$elm$core$List$cons,
						_Utils_update(
							acc,
							{y: rest}),
						stack);
					fLabel = $temp$fLabel;
					fTree = $temp$fTree;
					acc = $temp$acc;
					stack = $temp$stack;
					continue restructureHelp;
				}
			}
		}
	});
var $zwilias$elm_rosetree$Tree$restructure = F3(
	function (convertLabel, convertTree, _v0) {
		var l = _v0.a;
		var c = _v0.b;
		return A4(
			$zwilias$elm_rosetree$Tree$restructureHelp,
			convertLabel,
			convertTree,
			{
				d: _List_Nil,
				cB: convertLabel(l),
				y: c
			},
			_List_Nil);
	});
var $author$project$Page$Products$toListItems = F3(
	function (activeIds, _v0, children) {
		var catId = _v0.a;
		var isEmpty = _v0.b;
		var label = _v0.c;
		var clss = function () {
			if (!activeIds.b) {
				return _List_Nil;
			} else {
				var head = activeIds.a;
				var tail = activeIds.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'active',
						_Utils_eq(head, catId)),
						_Utils_Tuple2(
						'active-parent',
						A2($elm$core$List$member, catId, tail))
					]);
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2('is-empty', isEmpty),
						clss))
				]),
			function () {
				if (!children.b) {
					return _List_fromArray(
						[label]);
				} else {
					return _List_fromArray(
						[
							label,
							A2($rtfeldman$elm_css$Html$Styled$ul, _List_Nil, children)
						]);
				}
			}());
	});
var $author$project$Category$mapProductCount = F2(
	function (_v0, productCount_) {
		var internals_ = _v0.a;
		var a = _v0.b;
		return A2(
			$author$project$Category$Category,
			_Utils_update(
				internals_,
				{cS: productCount_}),
			a);
	});
var $author$project$Page$Products$viewForestHelper = F2(
	function (filterRes, cat) {
		return A2(
			$author$project$Category$mapProductCount,
			cat,
			A2(
				$elm$core$Maybe$withDefault,
				0,
				A2(
					$elm$core$Dict$get,
					$author$project$Id$toInt(
						$author$project$Category$id(cat)),
					filterRes)));
	});
var $author$project$Page$Products$viewForest = F3(
	function (forest, selections, filterRes) {
		var trees = A2(
			$elm$core$List$map,
			$zwilias$elm_rosetree$Tree$map(
				$author$project$Page$Products$viewForestHelper(filterRes)),
			forest);
		var maybeCategory = $author$project$Filter$category(selections);
		var maybeZipper = function () {
			if (!forest.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var head = forest.a;
				var tail = forest.b;
				return A2(
					$zwilias$elm_rosetree$Tree$Zipper$findFromRoot,
					function (cat) {
						if (!maybeCategory.$) {
							var id = maybeCategory.a;
							return A2(
								$author$project$Id$equals,
								$author$project$Category$id(cat),
								id);
						} else {
							return false;
						}
					},
					A2($zwilias$elm_rosetree$Tree$Zipper$fromForest, head, tail));
			}
		}();
		var h = A2($author$project$Page$Products$hierarchy, _List_Nil, maybeZipper);
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(0.75),
					$rtfeldman$elm_css$Css$rem(1)),
					A3(
					$rtfeldman$elm_css$Css$borderBottom3,
					$rtfeldman$elm_css$Css$px(1),
					$rtfeldman$elm_css$Css$solid,
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lighter)),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Css$padding4,
							$rtfeldman$elm_css$Css$rem(0.75),
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$zero)
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Kategorier')
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$ul,
					$author$project$Page$Products$categoryTreeStyles(true),
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (child) {
							return A3(
								$zwilias$elm_rosetree$Tree$restructure,
								$author$project$Page$Products$filterLabelToHtml(h),
								$author$project$Page$Products$toListItems(h),
								child);
						},
						trees))
				]));
	});
var $author$project$Page$Products$labelToHtml = F2(
	function (routeHelper, cat) {
		return _Utils_Tuple3(
			$author$project$Category$id(cat),
			!$author$project$Category$productCount(cat),
			A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$author$project$Route$href(
						routeHelper(
							_Utils_Tuple2(
								$author$project$Category$id(cat),
								$author$project$Category$slug(cat)))),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('is-link')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Category$name(cat)),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								' (' + ($elm$core$String$fromInt(
									$author$project$Category$productCount(cat)) + ')'))
							]))
					])));
	});
var $author$project$Page$Products$viewTree = function (zipper) {
	var root = $zwilias$elm_rosetree$Tree$Zipper$tree(
		$zwilias$elm_rosetree$Tree$Zipper$root(zipper));
	var routeHelper = A2(
		$author$project$Route$Category,
		$elm$core$Dict$empty,
		$author$project$Category$slug(
			$zwilias$elm_rosetree$Tree$label(root)));
	var h = A2(
		$author$project$Page$Products$hierarchy,
		_List_Nil,
		$elm$core$Maybe$Just(zipper));
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$padding2,
				$rtfeldman$elm_css$Css$rem(0.75),
				$rtfeldman$elm_css$Css$rem(1)),
				A3(
				$rtfeldman$elm_css$Css$borderBottom3,
				$rtfeldman$elm_css$Css$px(1),
				$rtfeldman$elm_css$Css$solid,
				$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lighter)),
				$author$project$SE$UI$Utils$desktop(
				_List_fromArray(
					[
						A4(
						$rtfeldman$elm_css$Css$padding4,
						$rtfeldman$elm_css$Css$rem(0.75),
						$rtfeldman$elm_css$Css$zero,
						$rtfeldman$elm_css$Css$zero,
						$rtfeldman$elm_css$Css$zero)
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Category$name(
							$zwilias$elm_rosetree$Tree$label(root)))
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$ul,
				$author$project$Page$Products$categoryTreeStyles(false),
				_List_Nil,
				A2(
					$elm$core$List$map,
					function (child) {
						return A3(
							$zwilias$elm_rosetree$Tree$restructure,
							$author$project$Page$Products$labelToHtml(routeHelper),
							$author$project$Page$Products$toListItems(h),
							child);
					},
					$zwilias$elm_rosetree$Tree$children(root)))
			]));
};
var $author$project$Page$Products$viewCategoryNavigation = F4(
	function (mode, trees, categories, selections) {
		switch (mode.$) {
			case 0:
				var cat = mode.a;
				return $author$project$Page$Products$viewTree(cat);
			case 3:
				var zipper = mode.a;
				return A3(
					$author$project$Page$Products$viewForest,
					A2(
						$elm$core$List$filter,
						function (tree) {
							return A2(
								$author$project$Id$equals,
								$author$project$Category$id(
									$zwilias$elm_rosetree$Tree$Zipper$label(zipper)),
								$author$project$Category$id(
									$zwilias$elm_rosetree$Tree$label(tree)));
						},
						trees),
					selections,
					categories);
			case 2:
				return A3($author$project$Page$Products$viewForest, trees, selections, categories);
			default:
				return A3($author$project$Page$Products$viewForest, trees, selections, categories);
		}
	});
var $author$project$Filter$filterOptions = F2(
	function (_v0, options) {
		var set = _v0;
		return A2(
			$elm$core$List$filter,
			function (o) {
				return A2($elm$core$Set$member, o.gb, set);
			},
			options);
	});
var $author$project$Filter$currentlySelectedFilters = F2(
	function (filters, _v0) {
		var dict = _v0;
		return $elm$core$List$concat(
			A2(
				$elm$core$List$filterMap,
				function (f) {
					return A2(
						$elm$core$Maybe$andThen,
						function (s) {
							var _v1 = _Utils_Tuple2(s, f);
							_v1$2:
							while (true) {
								if (!_v1.b.$) {
									if (!_v1.a.$) {
										var stringSelection = _v1.a.a;
										var _v2 = _v1.b;
										var _v3 = _v2.b;
										var options = _v3.b;
										return function (a) {
											return $elm$core$List$isEmpty(a) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(a);
										}(
											A2(
												$elm$core$List$map,
												function (o) {
													return {e1: f, fF: o};
												},
												A2($author$project$Filter$filterOptions, stringSelection, options)));
									} else {
										break _v1$2;
									}
								} else {
									if (_v1.a.$ === 1) {
										var _v4 = _v1.a.a;
										var _v5 = _v1.b;
										var rec = _v5.b;
										var val = $elm$core$String$fromFloat(rec.fy) + (' - ' + $elm$core$String$fromFloat(rec.fw));
										return $elm$core$Maybe$Just(
											_List_fromArray(
												[
													{
													e1: f,
													fF: A3($author$project$Filter$Option, val, val, 0)
												}
												]));
									} else {
										break _v1$2;
									}
								}
							}
							return $elm$core$Maybe$Nothing;
						},
						A2(
							$elm$core$Dict$get,
							$author$project$Filter$slug(f),
							dict));
				},
				filters));
	});
var $author$project$Page$Products$ClearedFilter = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Products$ToggleStringFilter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Products$viewSelection = function (_v0) {
	var filter = _v0.e1;
	var option = _v0.fF;
	var msg = function () {
		if (!filter.$) {
			var stringFilter = filter.b;
			return $author$project$Page$Products$GotFilterMsg(
				A2($author$project$Page$Products$ToggleStringFilter, stringFilter, option.gb));
		} else {
			return $author$project$Page$Products$GotFilterMsg(
				$author$project$Page$Products$ClearedFilter(filter));
		}
	}();
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lighter),
				A3(
				$rtfeldman$elm_css$Css$border3,
				$rtfeldman$elm_css$Css$px(1),
				$rtfeldman$elm_css$Css$solid,
				$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$light)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				$rtfeldman$elm_css$Css$paddingLeft(
				$rtfeldman$elm_css$Css$rem(0.5)),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				A2(
				$rtfeldman$elm_css$Css$pseudoClass,
				'not(:last-child)',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$px(4))
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(option.hT)
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$author$project$SE$UI$Utils$unselectable,
						A2($rtfeldman$elm_css$Css$property, '-moz-appearance', 'none'),
						A2($rtfeldman$elm_css$Css$property, '-webkit-appearance', 'none'),
						$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$initial),
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
						A2($rtfeldman$elm_css$Css$property, 'pointer-events', 'auto'),
						$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
						$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
						$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$px(0)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(32)),
						$rtfeldman$elm_css$Css$maxHeight(
						$rtfeldman$elm_css$Css$px(32)),
						$rtfeldman$elm_css$Css$maxWidth(
						$rtfeldman$elm_css$Css$px(32)),
						$rtfeldman$elm_css$Css$minHeight(
						$rtfeldman$elm_css$Css$px(32)),
						$rtfeldman$elm_css$Css$minWidth(
						$rtfeldman$elm_css$Css$px(32)),
						$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none),
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
						$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px(32)),
						$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
						$rtfeldman$elm_css$Css$after(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$text),
								A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
								$rtfeldman$elm_css$Css$left(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$transforms(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$translateX(
										$rtfeldman$elm_css$Css$pct(-50)),
										$rtfeldman$elm_css$Css$translateY(
										$rtfeldman$elm_css$Css$pct(-50)),
										$rtfeldman$elm_css$Css$rotate(
										$rtfeldman$elm_css$Css$deg(45))
									])),
								$rtfeldman$elm_css$Css$Transitions$transition(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
									])),
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$px(2))
							])),
						$rtfeldman$elm_css$Css$before(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$text),
								A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
								$rtfeldman$elm_css$Css$left(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$transforms(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$translateX(
										$rtfeldman$elm_css$Css$pct(-50)),
										$rtfeldman$elm_css$Css$translateY(
										$rtfeldman$elm_css$Css$pct(-50)),
										$rtfeldman$elm_css$Css$rotate(
										$rtfeldman$elm_css$Css$deg(45))
									])),
								$rtfeldman$elm_css$Css$Transitions$transition(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
									])),
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$px(2)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(50))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick(msg)
					]),
				_List_fromArray(
					[
						A3(
						$author$project$SE$UI$Utils$visuallyHidden,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Ta bort ' + option.hT)
							]))
					]))
			]));
};
var $author$project$Page$Products$viewCurrentSelections = F2(
	function (filters, selections) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$paddingTop(
					$rtfeldman$elm_css$Css$rem(0.75))
				]),
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Valda filter: ')
								]))
						])),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$author$project$Page$Products$viewSelection,
						A2($author$project$Filter$currentlySelectedFilters, filters, selections)),
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart),
									$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
									A2($rtfeldman$elm_css$Css$property, 'padding-top', 'calc(0.75rem - 4px)'),
									$rtfeldman$elm_css$Css$hover(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color(
											$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									$author$project$Page$Products$GotFilterMsg($author$project$Page$Products$ClearedSelections))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Rensa alla filter'),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$author$project$SE$UI$Utils$unselectable,
											A2($rtfeldman$elm_css$Css$property, '-moz-appearance', 'none'),
											A2($rtfeldman$elm_css$Css$property, '-webkit-appearance', 'none'),
											$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$initial),
											$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
											A2($rtfeldman$elm_css$Css$property, 'pointer-events', 'auto'),
											$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
											$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$px(0)),
											$rtfeldman$elm_css$Css$height(
											$rtfeldman$elm_css$Css$px(24)),
											$rtfeldman$elm_css$Css$maxHeight(
											$rtfeldman$elm_css$Css$px(24)),
											$rtfeldman$elm_css$Css$maxWidth(
											$rtfeldman$elm_css$Css$px(24)),
											$rtfeldman$elm_css$Css$minHeight(
											$rtfeldman$elm_css$Css$px(24)),
											$rtfeldman$elm_css$Css$minWidth(
											$rtfeldman$elm_css$Css$px(24)),
											$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none),
											$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
											$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$px(24)),
											$rtfeldman$elm_css$Css$color($rtfeldman$elm_css$Css$currentColor),
											$rtfeldman$elm_css$Css$after(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$currentColor),
													A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
													$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
													$rtfeldman$elm_css$Css$left(
													$rtfeldman$elm_css$Css$pct(50)),
													$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
													$rtfeldman$elm_css$Css$top(
													$rtfeldman$elm_css$Css$pct(50)),
													$rtfeldman$elm_css$Css$transforms(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$translateX(
															$rtfeldman$elm_css$Css$pct(-50)),
															$rtfeldman$elm_css$Css$translateY(
															$rtfeldman$elm_css$Css$pct(-50)),
															$rtfeldman$elm_css$Css$rotate(
															$rtfeldman$elm_css$Css$deg(45))
														])),
													$rtfeldman$elm_css$Css$Transitions$transition(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
														])),
													$rtfeldman$elm_css$Css$height(
													$rtfeldman$elm_css$Css$pct(50)),
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$px(2))
												])),
											$rtfeldman$elm_css$Css$before(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$currentColor),
													A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
													$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
													$rtfeldman$elm_css$Css$left(
													$rtfeldman$elm_css$Css$pct(50)),
													$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
													$rtfeldman$elm_css$Css$top(
													$rtfeldman$elm_css$Css$pct(50)),
													$rtfeldman$elm_css$Css$transforms(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$translateX(
															$rtfeldman$elm_css$Css$pct(-50)),
															$rtfeldman$elm_css$Css$translateY(
															$rtfeldman$elm_css$Css$pct(-50)),
															$rtfeldman$elm_css$Css$rotate(
															$rtfeldman$elm_css$Css$deg(45))
														])),
													$rtfeldman$elm_css$Css$Transitions$transition(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
														])),
													$rtfeldman$elm_css$Css$height(
													$rtfeldman$elm_css$Css$px(2)),
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$pct(50))
												]))
										]),
									_List_Nil,
									_List_Nil)
								]))
						]))));
	});
var $author$project$Page$Products$viewFilterHeader = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$header,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$author$project$SE$UI$Font$bodySizeRem(1),
			$rtfeldman$elm_css$Css$minHeight(
			$rtfeldman$elm_css$Css$px(60)),
			$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$sticky),
			$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(99)),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(16)),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$px(4),
			$author$project$SE$UI$Colors$toCss(
				A2(
					$author$project$SE$UI$Colors$mapAlpha,
					$elm$core$Basics$always(0.15),
					$author$project$SE$UI$Colors$black))),
			$author$project$SE$UI$Utils$desktop(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
				]))
		]),
	_List_Nil,
	_List_fromArray(
		[
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
					$author$project$SE$UI$Font$bodySizeRem(-1),
					$rtfeldman$elm_css$Css$flexBasis(
					$rtfeldman$elm_css$Css$pct(33)),
					$rtfeldman$elm_css$Css$hover(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$color(
							$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Page$Products$GotFilterMsg($author$project$Page$Products$ClearedSelections))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Rensa alla filter')
				])),
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$h3,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$flexBasis(
					$rtfeldman$elm_css$Css$pct(34)),
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
				]),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Filter')
				])),
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$author$project$SE$UI$Utils$unselectable,
					A2($rtfeldman$elm_css$Css$property, '-moz-appearance', 'none'),
					A2($rtfeldman$elm_css$Css$property, '-webkit-appearance', 'none'),
					$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$initial),
					$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
					A2($rtfeldman$elm_css$Css$property, 'pointer-events', 'auto'),
					$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
					$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$fontSize(
					$rtfeldman$elm_css$Css$px(0)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$px(32)),
					$rtfeldman$elm_css$Css$maxHeight(
					$rtfeldman$elm_css$Css$px(32)),
					$rtfeldman$elm_css$Css$maxWidth(
					$rtfeldman$elm_css$Css$px(32)),
					$rtfeldman$elm_css$Css$minHeight(
					$rtfeldman$elm_css$Css$px(32)),
					$rtfeldman$elm_css$Css$minWidth(
					$rtfeldman$elm_css$Css$px(32)),
					$rtfeldman$elm_css$Css$outline($rtfeldman$elm_css$Css$none),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
					$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$px(32)),
					$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
					$rtfeldman$elm_css$Css$after(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$text),
							A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$left(
							$rtfeldman$elm_css$Css$pct(50)),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
							$rtfeldman$elm_css$Css$top(
							$rtfeldman$elm_css$Css$pct(50)),
							$rtfeldman$elm_css$Css$transforms(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$translateX(
									$rtfeldman$elm_css$Css$pct(-50)),
									$rtfeldman$elm_css$Css$translateY(
									$rtfeldman$elm_css$Css$pct(-50)),
									$rtfeldman$elm_css$Css$rotate(
									$rtfeldman$elm_css$Css$deg(45))
								])),
							$rtfeldman$elm_css$Css$Transitions$transition(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
								])),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$pct(50)),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$px(2))
						])),
					$rtfeldman$elm_css$Css$before(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$text),
							A2($rtfeldman$elm_css$Css$property, 'content', '\"\"'),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$left(
							$rtfeldman$elm_css$Css$pct(50)),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
							$rtfeldman$elm_css$Css$top(
							$rtfeldman$elm_css$Css$pct(50)),
							$rtfeldman$elm_css$Css$transforms(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$translateX(
									$rtfeldman$elm_css$Css$pct(-50)),
									$rtfeldman$elm_css$Css$translateY(
									$rtfeldman$elm_css$Css$pct(-50)),
									$rtfeldman$elm_css$Css$rotate(
									$rtfeldman$elm_css$Css$deg(45))
								])),
							$rtfeldman$elm_css$Css$Transitions$transition(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Transitions$transformOrigin(50)
								])),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(2)),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(50))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$Products$ToggledFilterMenuOpen)
				]),
			_List_fromArray(
				[
					A3(
					$author$project$SE$UI$Utils$visuallyHidden,
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Stäng')
						]))
				]))
		]));
var $author$project$Page$Products$ChangedFilterState = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$Products$SetMaxFilter = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Products$SetMinFilter = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Filter$stateToString = function (state) {
	switch (state) {
		case 0:
			return 'collapsed';
		case 1:
			return 'open';
		default:
			return 'expanded';
	}
};
var $author$project$Filter$ulStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$lineHeight(
		$rtfeldman$elm_css$Css$num(1.5)),
		A4(
		$rtfeldman$elm_css$Css$padding4,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$rem(1),
		$rtfeldman$elm_css$Css$rem(1),
		$rtfeldman$elm_css$Css$rem(1)),
		$author$project$SE$UI$Utils$desktop(
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Css$padding4,
				$rtfeldman$elm_css$Css$zero,
				$rtfeldman$elm_css$Css$rem(1),
				$rtfeldman$elm_css$Css$rem(1),
				$rtfeldman$elm_css$Css$zero)
			]))
	]);
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $author$project$Filter$viewBadge = function (maybeStringSet) {
	if (maybeStringSet.$ === 1) {
		return $rtfeldman$elm_css$Html$Styled$text('');
	} else {
		var set = maybeStringSet.a;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darker),
					$author$project$SE$UI$Colors$color(
					$author$project$SE$UI$Colors$invert($author$project$SE$UI$Colors$darker)),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$rtfeldman$elm_css$Css$borderRadius(
					$rtfeldman$elm_css$Css$pct(50)),
					$rtfeldman$elm_css$Css$marginRight(
					$rtfeldman$elm_css$Css$rem(0.5)),
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
				]),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$elm$core$String$fromInt(
						$elm$core$Set$size(set)))
				]));
	}
};
var $author$project$Filter$viewHeader = F4(
	function (msg, name, icon, maybeStringSet) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(0.75),
					$rtfeldman$elm_css$Css$rem(1)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
					$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$padding2,
							$rtfeldman$elm_css$Css$rem(0.75),
							$rtfeldman$elm_css$Css$zero)
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(msg)
				]),
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$h4,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginRight($rtfeldman$elm_css$Css$auto)
						]),
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					$author$project$Filter$viewBadge(maybeStringSet),
					$author$project$SE$UI$Icon$V2$toHtml(icon)
				]));
	});
var $author$project$Filter$viewNumberFilter = F6(
	function (state, _v0, minMsg, maxMsg, changedStateMsg, _v1) {
		var dict = _v0;
		var rec = _v1;
		var name = rec.hT;
		var total_min = rec.db;
		var total_max = rec.da;
		var _v2 = function () {
			var _v3 = A2($elm$core$Dict$get, rec.gb, dict);
			if ((!_v3.$) && (_v3.a.$ === 1)) {
				var _v4 = _v3.a.a;
				var min_ = _v4.a;
				var max_ = _v4.b;
				return _Utils_Tuple2(min_, max_);
			} else {
				return _Utils_Tuple2(total_min, total_max);
			}
		}();
		var inputMin = _v2.a;
		var inputMax = _v2.b;
		var _v5 = function () {
			if (!state) {
				return _Utils_Tuple2(1, $author$project$SE$UI$Icon$V2$angleDown);
			} else {
				return _Utils_Tuple2(
					0,
					A2($author$project$SE$UI$Icon$V2$withTransform, $author$project$SE$UI$Icon$V2$FlipY, $author$project$SE$UI$Icon$V2$angleDown));
			}
		}();
		var toggleState = _v5.a;
		var icon = _v5.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$withClass,
					'is-collapsed',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$typeSelector,
									'ul',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
										]))
								]))
						])),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$input(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white)),
									$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$light)
								]))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('filter-' + rec.gb, true),
							_Utils_Tuple2(
							'is-' + $author$project$Filter$stateToString(state),
							true)
						]))
				]),
			_List_fromArray(
				[
					A4(
					$author$project$Filter$viewHeader,
					A2(
						changedStateMsg,
						toggleState,
						A2($author$project$Filter$Num, state, rec)),
					name + (' (' + ($elm$core$String$fromFloat(total_min) + (' - ' + ($elm$core$String$fromFloat(total_max) + ')')))),
					icon,
					$elm$core$Maybe$Nothing),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$ul,
					$author$project$Filter$ulStyles,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_fromArray(
								[1]),
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$expandedControl,
									false,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withStep,
												1,
												A2(
													$author$project$SE$UI$Form$Input$withRange,
													_Utils_Tuple2(total_min, total_max),
													A2(
														$author$project$SE$UI$Form$Input$number,
														minMsg,
														$elm$core$String$fromFloat(inputMin)))))
										])),
									A2(
									$author$project$SE$UI$Form$expandedControl,
									false,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withStep,
												1,
												A2(
													$author$project$SE$UI$Form$Input$withRange,
													_Utils_Tuple2(total_min, total_max),
													A2(
														$author$project$SE$UI$Form$Input$number,
														maxMsg,
														$elm$core$String$fromFloat(inputMax)))))
										]))
								]))
						]))
				]));
	});
var $author$project$Filter$Expanded = 2;
var $author$project$Filter$viewDisabledFilterOption = function (value) {
	return _List_fromArray(
		[
			A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
					$rtfeldman$elm_css$Css$marginLeft(
					$rtfeldman$elm_css$Css$rem(1.5)),
					$author$project$SE$UI$Font$bodySizeRem(-1)
				]),
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(value)
				]))
		]);
};
var $author$project$Filter$viewEnabledFilterOption = F4(
	function (isChecked, msg, rec, option) {
		return _List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$Global$descendants(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$label(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
										$author$project$SE$UI$Font$bodySizeRem(-1)
									])),
								A2(
								$rtfeldman$elm_css$Css$Global$selector,
								'input[type=\"checkbox\"]',
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$pseudoClass,
										'not(:checked)',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$important(
												$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white)),
												$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$light)
											]))
									]))
							]))
					]),
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Form$Input$toHtml(
						A2(
							$author$project$SE$UI$Form$Input$withModifier,
							$author$project$SE$UI$Form$Input$Size(1),
							A3(
								$author$project$SE$UI$Form$Input$checkbox,
								msg(option.gb),
								_Utils_ap(
									rec.dZ,
									_Utils_ap(option.hT, rec.ec)),
								isChecked))),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(0.3)),
								$author$project$SE$UI$Font$bodySizeRem(-1)
							]),
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								' (' + ($elm$core$String$fromInt(option.bc) + ')'))
							]))
					]))
			]);
	});
var $author$project$Filter$viewFilterOption = F4(
	function (maybeStringSet, msg, rec, option) {
		var isChecked = (option.bc > 0) && A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (set) {
					return A2($elm$core$Set$member, option.gb, set);
				},
				maybeStringSet));
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Form$field,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$expandedControl,
							false,
							(option.bc > 0) ? A4($author$project$Filter$viewEnabledFilterOption, isChecked, msg, rec, option) : $author$project$Filter$viewDisabledFilterOption(
								_Utils_ap(
									rec.dZ,
									_Utils_ap(option.hT, rec.ec))))
						]))
				]));
	});
var $author$project$Filter$viewStringFilter = F5(
	function (state, _v0, msg, changedStateMsg, _v1) {
		var dict = _v0;
		var rec = _v1.a;
		var name = rec.hT;
		var options = _v1.b;
		var maybeStringSet = function () {
			var _v4 = A2($elm$core$Dict$get, rec.gb, dict);
			if ((!_v4.$) && (!_v4.a.$)) {
				var set = _v4.a.a;
				return $elm$core$Maybe$Just(set);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var _v2 = function () {
			switch (state) {
				case 0:
					return _Utils_Tuple3(1, $author$project$SE$UI$Icon$V2$angleDown, $elm$core$Basics$identity);
				case 1:
					return _Utils_Tuple3(
						0,
						A2($author$project$SE$UI$Icon$V2$withTransform, $author$project$SE$UI$Icon$V2$FlipY, $author$project$SE$UI$Icon$V2$angleDown),
						$elm$core$List$sortWith(
							F2(
								function (a, b) {
									return A2(
										$elm$core$Basics$compare,
										A3($elm$core$Basics$clamp, 0, 1, b.bc),
										A3($elm$core$Basics$clamp, 0, 1, a.bc));
								})));
				default:
					return _Utils_Tuple3(
						0,
						A2($author$project$SE$UI$Icon$V2$withTransform, $author$project$SE$UI$Icon$V2$FlipY, $author$project$SE$UI$Icon$V2$angleDown),
						$elm$core$Basics$identity);
			}
		}();
		var toggleState = _v2.a;
		var icon = _v2.b;
		var sortFn = _v2.c;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$withClass,
					'is-collapsed',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$typeSelector,
									'ul',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$withClass,
					'is-open',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$selector,
									'li:nth-child(1n+11)',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
										]))
								]))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('filter-' + rec.gb, true),
							_Utils_Tuple2(
							'is-' + $author$project$Filter$stateToString(state),
							true)
						]))
				]),
			_List_fromArray(
				[
					A4(
					$author$project$Filter$viewHeader,
					A2(
						changedStateMsg,
						toggleState,
						A2(
							$author$project$Filter$Str,
							state,
							A2($author$project$Filter$StringFilter, rec, options))),
					name,
					icon,
					maybeStringSet),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$ul,
					$author$project$Filter$ulStyles,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A3($author$project$Filter$viewFilterOption, maybeStringSet, msg, rec),
						sortFn(options))),
					A2(
					$author$project$Helpers$viewIf,
					(state === 1) && ($elm$core$List$length(options) > 10),
					A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link),
								$rtfeldman$elm_css$Css$hover(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$color(
										$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
									])),
								A4(
								$rtfeldman$elm_css$Css$padding4,
								$rtfeldman$elm_css$Css$zero,
								$rtfeldman$elm_css$Css$rem(1),
								$rtfeldman$elm_css$Css$rem(1),
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(-0.5)),
								$author$project$SE$UI$Utils$desktop(
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Css$padding4,
										$rtfeldman$elm_css$Css$zero,
										$rtfeldman$elm_css$Css$rem(1),
										$rtfeldman$elm_css$Css$rem(1),
										$rtfeldman$elm_css$Css$zero)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								A2(
									changedStateMsg,
									2,
									A2(
										$author$project$Filter$Str,
										state,
										A2($author$project$Filter$StringFilter, rec, options))))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Visa fler')
							])))
				]));
	});
var $author$project$Page$Products$viewFilter = F2(
	function (selections, filter) {
		if (!filter.$) {
			var state = filter.a;
			var strFilter = filter.b;
			return A5(
				$author$project$Filter$viewStringFilter,
				state,
				selections,
				A2(
					$elm$core$Basics$composeL,
					$author$project$Page$Products$GotFilterMsg,
					$author$project$Page$Products$ToggleStringFilter(strFilter)),
				$author$project$Page$Products$ChangedFilterState,
				strFilter);
		} else {
			var state = filter.a;
			var numFilter = filter.b;
			return A6(
				$author$project$Filter$viewNumberFilter,
				state,
				selections,
				A2(
					$elm$core$Basics$composeL,
					$author$project$Page$Products$GotFilterMsg,
					$author$project$Page$Products$SetMinFilter(numFilter)),
				A2(
					$elm$core$Basics$composeL,
					$author$project$Page$Products$GotFilterMsg,
					$author$project$Page$Products$SetMaxFilter(numFilter)),
				$author$project$Page$Products$ChangedFilterState,
				numFilter);
		}
	});
var $author$project$Page$Products$viewFilters = F2(
	function (selections, filters) {
		return A2(
			$elm$core$List$map,
			$author$project$Page$Products$viewFilter(selections),
			filters);
	});
var $author$project$Page$Products$viewAside = F7(
	function (mode, trees, filterMenuOpen, filters, selections, categories, total) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$aside,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$zIndex(
					$rtfeldman$elm_css$Css$int(50)),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(100)),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
					$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$vh(100)),
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$auto),
					$author$project$SE$UI$Font$bodySizeRem(-1),
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
					$rtfeldman$elm_css$Css$Transitions$transition(
					_List_fromArray(
						[
							A3($rtfeldman$elm_css$Css$Transitions$transform3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut),
							A3($rtfeldman$elm_css$Css$Transitions$opacity3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut)
						])),
					A2($rtfeldman$elm_css$Css$property, 'transform', 'translateX(calc(-100% - 1rem))'),
					A2(
					$rtfeldman$elm_css$Css$Global$withClass,
					'is-open',
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Css$property, 'transform', 'translateX(-1rem)')
						])),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$zIndex(
							$rtfeldman$elm_css$Css$int(30)),
							$author$project$SE$UI$Font$bodySizeRem(-2),
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$label(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$author$project$SE$UI$Font$bodySizeRem(-2))
										]))
								])),
							$rtfeldman$elm_css$Css$lineHeight(
							$rtfeldman$elm_css$Css$num(1.28571428571)),
							$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset),
							A2($rtfeldman$elm_css$Css$property, 'height', 'fit-content'),
							$rtfeldman$elm_css$Css$maxHeight(
							A3(
								$rtfeldman$elm_css$Css$calc,
								$rtfeldman$elm_css$Css$vh(100),
								$rtfeldman$elm_css$Css$minus,
								$rtfeldman$elm_css$Css$rem(3))),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$sticky),
							$rtfeldman$elm_css$Css$top(
							$rtfeldman$elm_css$Css$rem(1.5)),
							A2($rtfeldman$elm_css$Css$property, 'transition', 'unset'),
							A2($rtfeldman$elm_css$Css$property, 'transform', 'unset'),
							$rtfeldman$elm_css$Css$marginRight(
							$rtfeldman$elm_css$Css$rem(1.5)),
							$rtfeldman$elm_css$Css$flexBasis(
							$rtfeldman$elm_css$Css$px(320)),
							$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
							A3(
							$rtfeldman$elm_css$Css$border3,
							$rtfeldman$elm_css$Css$px(1),
							$rtfeldman$elm_css$Css$solid,
							$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lighter)),
							$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
							$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$auto),
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
							A2(
							$rtfeldman$elm_css$Css$padding2,
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$rem(1)),
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$selector,
									'[class*=\"filter-\"]:last-of-type',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$borderBottom($rtfeldman$elm_css$Css$unset)
										]))
								]))
						])),
					$rtfeldman$elm_css$Css$Global$children(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$each,
							_List_fromArray(
								[$rtfeldman$elm_css$Css$Global$div, $rtfeldman$elm_css$Css$Global$footer]),
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white)
								])),
							$rtfeldman$elm_css$Css$Global$footer(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop($rtfeldman$elm_css$Css$auto)
								]))
						])),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$h3(
							_List_fromArray(
								[
									$author$project$SE$UI$Font$bodySizeRem(1)
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$selector,
							'[class*=\"filter-\"]',
							_List_fromArray(
								[
									A3(
									$rtfeldman$elm_css$Css$borderBottom3,
									$rtfeldman$elm_css$Css$px(1),
									$rtfeldman$elm_css$Css$solid,
									$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lighter))
								]))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-open', filterMenuOpen)
						]))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$author$project$Helpers$viewIf,
						filterMenuOpen,
						$rtfeldman$elm_css$Css$Global$global(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$html(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden)),
											$author$project$SE$UI$Utils$desktop(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$unset))
												]))
										]))
								]))),
						$author$project$Page$Products$viewFilterHeader,
						A4($author$project$Page$Products$viewCategoryNavigation, mode, trees, categories, selections),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								A3(
								$rtfeldman$elm_css$Css$borderBottom3,
								$rtfeldman$elm_css$Css$px(1),
								$rtfeldman$elm_css$Css$solid,
								$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$lighter)),
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0.75),
								$rtfeldman$elm_css$Css$rem(1)),
								$author$project$SE$UI$Utils$desktop(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$rem(0.75),
										$rtfeldman$elm_css$Css$zero)
									]))
							]),
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h3,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Filter')
									])),
								A2(
								$author$project$Helpers$viewIf,
								!$author$project$Filter$isSelectionsEmpty(selections),
								A2($author$project$Page$Products$viewCurrentSelections, filters, selections))
							]))
					]),
				_Utils_ap(
					A2($author$project$Page$Products$viewFilters, selections, filters),
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$footer,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$sticky),
									$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
									A2(
									$rtfeldman$elm_css$Css$padding2,
									$rtfeldman$elm_css$Css$rem(0.5),
									$rtfeldman$elm_css$Css$rem(1)),
									$author$project$SE$UI$Utils$desktop(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
										]))
								]),
							_List_Nil,
							_List_fromArray(
								[
									A3(
									$author$project$SE$UI$Buttons$button,
									_List_fromArray(
										[
											$author$project$SE$UI$Buttons$Color(9),
											$author$project$SE$UI$Buttons$Fullwidth
										]),
									$elm$core$Maybe$Just($author$project$Page$Products$ToggledFilterMenuOpen),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											'Visa ' + ($elm$core$String$fromInt(total) + ' produkter'))
										]))
								]))
						]))));
	});
var $author$project$Breadcrumb$Brand = function (a) {
	return {$: 4, a: a};
};
var $author$project$Breadcrumb$Categories = function (a) {
	return {$: 2, a: a};
};
var $author$project$Breadcrumb$Search = function (a) {
	return {$: 16, a: a};
};
var $author$project$Breadcrumb$Sticker = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Products$viewBreadcrumbs = function (mode) {
	return $author$project$Breadcrumb$toHtml(
		function () {
			switch (mode.$) {
				case 0:
					var cat = mode.a;
					return $author$project$Breadcrumb$Categories(cat);
				case 2:
					if (mode.a.b.$ === 1) {
						var _v1 = mode.a;
						var _v2 = _v1.b;
						return $author$project$Breadcrumb$Loading;
					} else {
						var _v3 = mode.a;
						var brand = _v3.b.a;
						return $author$project$Breadcrumb$Brand(brand);
					}
				case 3:
					var cat = mode.a;
					var sticker = mode.b;
					return A2($author$project$Breadcrumb$Sticker, cat, sticker);
				default:
					var status = mode.a;
					return $author$project$Breadcrumb$Search(
						$author$project$Page$Products$searchQuery(status));
			}
		}());
};
var $author$project$Page$Products$view = function (model) {
	var body = function () {
		var _v0 = _Utils_Tuple2(
			model.F,
			$elm$core$List$isEmpty(model.J));
		if ((!_v0.a) && _v0.b) {
			return _List_fromArray(
				[
					$author$project$Page$Products$viewNoResult(model)
				]);
		} else {
			return _List_fromArray(
				[
					A8(
					$rtfeldman$elm_css$Html$Styled$Lazy$lazy7,
					$author$project$Page$Products$viewAside,
					model.f,
					$author$project$Session$categories(model.K),
					model.aI,
					model.J,
					model.iD,
					model.gV,
					model.F),
					$author$project$Page$Products$viewArticle(model)
				]);
		}
	}();
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class(
					$author$project$Page$Products$modeToCssClass(model.f))
				]),
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Html$Styled$Lazy$lazy, $author$project$Page$Products$viewBreadcrumbs, model.f),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$main_,
					_List_fromArray(
						[
							$author$project$SE$UI$Utils$desktop(
							_List_fromArray(
								[$rtfeldman$elm_css$Css$displayFlex]))
						]),
					_List_Nil,
					body)
				])),
		gr: $author$project$Page$Products$title(model.f)
	};
};
var $author$project$Page$RequestNewPassword$GotFormMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$RequestNewPassword$GotInput = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$RequestNewPassword$SubmittedForm = {$: 3};
var $author$project$Page$RequestNewPassword$viewSubmitButton = F2(
	function (isSending, errors) {
		var _v0 = _Utils_eq(errors, $author$project$Page$RequestNewPassword$noErrors) ? (isSending ? {
			ct: true,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			dd: 'Skickar...'
		} : {
			ct: false,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			dd: 'Skicka länk för återställning av lösenord'
		}) : {
			ct: true,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(12)
				]),
			dd: 'Rätta felen innan du sparar din profil'
		};
		var disabled = _v0.ct;
		var txt = _v0.dd;
		var mods = _v0.cI;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Buttons$buttons,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$input,
							A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(txt),
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disabled)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$RequestNewPassword$viewForm = F6(
	function (csrfToken, isSending, message, inputs, errors, errorMsg) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Page$RequestNewPassword$SubmittedForm),
					$rtfeldman$elm_css$Html$Styled$Attributes$method('post'),
					$rtfeldman$elm_css$Html$Styled$Attributes$action(
					$author$project$Route$toString($author$project$Route$Login))
				]),
			_List_fromArray(
				[
					$author$project$CsrfToken$input(csrfToken),
					$author$project$SE$UI$Title$title1('Återställ lösenord - Partnerzon'),
					$author$project$Page$viewMessage(message),
					$author$project$Page$viewNonDismissableErrorNotification(errorMsg),
					$author$project$Page$Login$View$textField(
					{
						hd: errors.be,
						cB: 'E-mail',
						cJ: A2(
							$elm$core$Basics$composeL,
							$author$project$Page$RequestNewPassword$GotFormMsg,
							$author$project$Page$RequestNewPassword$GotInput(
								F2(
									function (value, inputs_) {
										return _Utils_update(
											inputs_,
											{be: value});
									}))),
						hT: 'email',
						iY: inputs.be
					}),
					A2($author$project$Page$RequestNewPassword$viewSubmitButton, isSending, errors)
				]));
	});
var $author$project$Page$RequestNewPassword$viewStatus = F3(
	function (csrfToken, message, status) {
		switch (status.$) {
			case 0:
				var inputs = status.a;
				return A6($author$project$Page$RequestNewPassword$viewForm, csrfToken, false, message, inputs, $author$project$Page$RequestNewPassword$noErrors, '');
			case 1:
				var inputs = status.a;
				var errors = status.b;
				var errorMsg = status.c;
				return A6($author$project$Page$RequestNewPassword$viewForm, csrfToken, false, message, inputs, errors, errorMsg);
			case 2:
				var inputs = status.a;
				return A6($author$project$Page$RequestNewPassword$viewForm, csrfToken, false, message, inputs, $author$project$Page$RequestNewPassword$noErrors, '');
			default:
				var inputs = status.a;
				return A6($author$project$Page$RequestNewPassword$viewForm, csrfToken, true, message, inputs, $author$project$Page$RequestNewPassword$noErrors, '');
		}
	});
var $author$project$Page$RequestNewPassword$viewUserLogin = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('This should never have happened'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('As a logged in user, you should never end up on this page. Please contact us so that we can make sure others won\'t be stuck at this dead end as well.')
									]))
							]))
					]))
			])),
	gr: 'Wow, how did you end up here?'
};
var $author$project$Page$RequestNewPassword$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return {
			eN: $author$project$Page$Login$View$frame(
				A3(
					$author$project$Page$RequestNewPassword$viewStatus,
					$author$project$Session$userInterface(
						$author$project$Page$RequestNewPassword$toSession(model)).g0,
					model.fx,
					model.gh)),
			gr: 'Återställ lösenord'
		};
	} else {
		return $author$project$Page$RequestNewPassword$viewUserLogin;
	}
};
var $author$project$Page$ResetPassword$GotFormMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ResetPassword$GotInput = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$ResetPassword$SubmittedForm = {$: 3};
var $author$project$SE$UI$Form$Input$New = 1;
var $author$project$SE$UI$Form$Input$withNewPassword = function (input) {
	if (input.$ === 4) {
		var rec = input.a;
		return $author$project$SE$UI$Form$Input$Password(
			_Utils_update(
				rec,
				{dk: 1}));
	} else {
		return input;
	}
};
var $author$project$Page$Login$View$newPasswordField = function (_v0) {
	var label = _v0.cB;
	var value = _v0.iY;
	var name = _v0.hT;
	var msg = _v0.cJ;
	var errors = _v0.hd;
	var mods = $elm$core$List$isEmpty(errors) ? _List_Nil : _List_fromArray(
		[$author$project$SE$UI$Form$Input$Danger]);
	return A3(
		$author$project$Page$Login$View$field,
		label,
		errors,
		$author$project$SE$UI$Form$Input$toHtml(
			$author$project$SE$UI$Form$Input$withNewPassword(
				A2(
					$author$project$SE$UI$Form$Input$withModifiers,
					mods,
					A2(
						$author$project$SE$UI$Form$Input$withName,
						name,
						$author$project$SE$UI$Form$Input$withRequired(
							A2(
								$author$project$SE$UI$Form$Input$withTrigger,
								0,
								A2($author$project$SE$UI$Form$Input$password, msg, value))))))));
};
var $author$project$Page$ResetPassword$viewSubmitButton = F2(
	function (isSending, errors) {
		var _v0 = _Utils_eq(errors, $author$project$Page$ResetPassword$noErrors) ? (isSending ? {
			ct: true,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			dd: 'Sparar...'
		} : {
			ct: false,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(9)
				]),
			dd: 'Spara nytt lösenord'
		}) : {
			ct: true,
			cI: _List_fromArray(
				[
					$author$project$SE$UI$Buttons$Color(12)
				]),
			dd: 'Rätta felen innan du sparar din profil'
		};
		var disabled = _v0.ct;
		var txt = _v0.dd;
		var mods = _v0.cI;
		return A2(
			$author$project$SE$UI$Form$field,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Buttons$buttons,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$input,
							A2($author$project$SE$UI$Buttons$buttonStyles, mods, false),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(txt),
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disabled)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$ResetPassword$viewForm = F6(
	function (csrfToken, isSending, message, inputs, errors, errorMsg) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Page$ResetPassword$SubmittedForm),
					$rtfeldman$elm_css$Html$Styled$Attributes$method('post'),
					$rtfeldman$elm_css$Html$Styled$Attributes$action(
					$author$project$Route$toString($author$project$Route$Login))
				]),
			_List_fromArray(
				[
					$author$project$CsrfToken$input(csrfToken),
					$author$project$SE$UI$Title$title1('Återställ lösenord - Partnerzon'),
					$author$project$Page$viewMessage(message),
					$author$project$Page$viewNonDismissableErrorNotification(errorMsg),
					$author$project$Page$Login$View$textField(
					{
						hd: errors.be,
						cB: 'E-mail',
						cJ: A2(
							$elm$core$Basics$composeL,
							$author$project$Page$ResetPassword$GotFormMsg,
							$author$project$Page$ResetPassword$GotInput(
								F2(
									function (value, inputs_) {
										return _Utils_update(
											inputs_,
											{be: value});
									}))),
						hT: 'email',
						iY: inputs.be
					}),
					$author$project$Page$Login$View$newPasswordField(
					{
						hd: errors.an,
						cB: 'Lösenord',
						cJ: A2(
							$elm$core$Basics$composeL,
							$author$project$Page$ResetPassword$GotFormMsg,
							$author$project$Page$ResetPassword$GotInput(
								F2(
									function (value, inputs_) {
										return _Utils_update(
											inputs_,
											{an: value});
									}))),
						hT: 'password',
						iY: inputs.an
					}),
					$author$project$Page$Login$View$newPasswordField(
					{
						hd: errors.ao,
						cB: 'Bekräfta lösenord',
						cJ: A2(
							$elm$core$Basics$composeL,
							$author$project$Page$ResetPassword$GotFormMsg,
							$author$project$Page$ResetPassword$GotInput(
								F2(
									function (value, inputs_) {
										return _Utils_update(
											inputs_,
											{ao: value});
									}))),
						hT: 'password_confirmation',
						iY: inputs.ao
					}),
					A2($author$project$Page$ResetPassword$viewSubmitButton, isSending, errors)
				]));
	});
var $author$project$Page$ResetPassword$viewStatus = F3(
	function (csrfToken, message, status) {
		switch (status.$) {
			case 0:
				var inputs = status.a;
				return A6($author$project$Page$ResetPassword$viewForm, csrfToken, false, message, inputs, $author$project$Page$ResetPassword$noErrors, '');
			case 1:
				var inputs = status.a;
				var errors = status.b;
				var errorMsg = status.c;
				return A6($author$project$Page$ResetPassword$viewForm, csrfToken, false, message, inputs, errors, errorMsg);
			case 2:
				var inputs = status.a;
				return A6($author$project$Page$ResetPassword$viewForm, csrfToken, false, message, inputs, $author$project$Page$ResetPassword$noErrors, '');
			default:
				var inputs = status.a;
				return A6($author$project$Page$ResetPassword$viewForm, csrfToken, true, message, inputs, $author$project$Page$ResetPassword$noErrors, '');
		}
	});
var $author$project$Page$ResetPassword$viewUserLogin = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('This should never have happened'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('As a logged in user, you should never end up on this page. Please contact us so that we can make sure others won\'t be stuck at this dead end as well.')
									]))
							]))
					]))
			])),
	gr: 'Wow, how did you end up here?'
};
var $author$project$Page$ResetPassword$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return {
			eN: $author$project$Page$Login$View$frame(
				A3(
					$author$project$Page$ResetPassword$viewStatus,
					$author$project$Session$userInterface(
						$author$project$Page$ResetPassword$toSession(model)).g0,
					model.fx,
					model.gh)),
			gr: 'Återställ lösenord'
		};
	} else {
		return $author$project$Page$ResetPassword$viewUserLogin;
	}
};
var $author$project$Page$Service$viewFailed = function (error) {
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_fromArray(
						[1]),
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title1('Sidan kunde inte laddas.'),
							A2(
							$author$project$SE$UI$Content$content,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Api$errorToHtml(error)
										]))
								]))
						]))
				])),
		gr: 'Sidan kunde inte laddas.'
	};
};
var $author$project$Page$Service$viewService = function (service) {
	var title = A2($elm$core$String$endsWith, ' - Garanti och Service', service.gr) ? A2($elm$core$String$dropRight, 22, service.gr) : service.gr;
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href('/' + service.gb)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(title)
					]))
			]));
};
var $author$project$Page$Service$viewLoaded = function (services) {
	return {
		eN: A2(
			$author$project$SE$UI$Section$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_fromArray(
						[1]),
					_List_fromArray(
						[
							$author$project$SE$UI$Title$title1('Service & Garanti'),
							A2(
							$author$project$SE$UI$Content$content,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$ul,
									_List_Nil,
									A2($elm$core$List$map, $author$project$Page$Service$viewService, services))
								]))
						]))
				])),
		gr: 'Service & Garanti'
	};
};
var $author$project$Page$Service$viewLoading = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_fromArray(
					[1]),
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('Laddar...')
					]))
			])),
	gr: 'Laddar...'
};
var $author$project$Page$Service$view = function (model) {
	var _v0 = model.gh;
	switch (_v0.$) {
		case 0:
			return $author$project$Page$Service$viewLoading;
		case 1:
			var content = _v0.a;
			return $author$project$Page$Service$viewLoaded(content);
		case 2:
			var content = _v0.a;
			return $author$project$Page$Service$viewLoaded(content);
		default:
			var error = _v0.a;
			return $author$project$Page$Service$viewFailed(error);
	}
};
var $author$project$Page$ThankYou$viewGuestCheckout = {
	eN: A2(
		$author$project$SE$UI$Section$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$SE$UI$Container$container,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title1('This should never have happened'),
						A2(
						$author$project$SE$UI$Content$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('As a guest, you should never end up on this page. Please contact us so that we can make sure others won\'t be stuck at this dead end as well.')
									]))
							]))
					]))
			])),
	gr: 'Wow, how did you end up here?'
};
var $author$project$Page$ThankYou$view = function (model) {
	var _v0 = $author$project$Session$user(model.K);
	if (_v0.$ === 1) {
		return $author$project$Page$ThankYou$viewGuestCheckout;
	} else {
		return {
			eN: A2(
				$author$project$SE$UI$Section$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$SE$UI$Container$container,
						_List_fromArray(
							[1]),
						_List_fromArray(
							[
								A4(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$rem(1.5))
									]),
								_List_Nil,
								_List_fromArray(
									[
										$author$project$SE$UI$Icon$V2$toHtml(
										A2($author$project$SE$UI$Icon$V2$withSize, 3, $author$project$SE$UI$Icon$V2$checkCircle)),
										$author$project$SE$UI$Title$title1('Tack för din order!'),
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Det kommer en orderbekräftelse till din e-post när vi hanterat din order.')
											]))
									])),
								$author$project$SE$UI$Columns$columns(
								_List_fromArray(
									[
										$author$project$SE$UI$Columns$defaultColumn(
										_List_fromArray(
											[
												$author$project$SE$UI$Card$toHtml(
												$author$project$SE$UI$Card$content(
													_List_fromArray(
														[
															$author$project$SE$UI$Title$title5('Din order'),
															A4(
															$rtfeldman$elm_css$Html$Styled$styled,
															$rtfeldman$elm_css$Html$Styled$p,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Css$marginBottom(
																	$rtfeldman$elm_css$Css$rem(1.5))
																]),
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Du hittar alltid din order under '),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$a,
																	_List_fromArray(
																		[
																			$author$project$Route$href(
																			$author$project$Route$Orders($author$project$QueryParams$empty))
																		]),
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text('Ordrar')
																		])),
																	$rtfeldman$elm_css$Html$Styled$text(' på Mitt konto. Där ser du en sammanställning på ordern samt leveransstatus.')
																])),
															A4(
															$rtfeldman$elm_css$Html$Styled$styled,
															$rtfeldman$elm_css$Html$Styled$a,
															A2(
																$author$project$SE$UI$Buttons$buttonStyles,
																_List_fromArray(
																	[
																		$author$project$SE$UI$Buttons$Color(9),
																		$author$project$SE$UI$Buttons$Fullwidth
																	]),
																false),
															_List_fromArray(
																[
																	$author$project$Route$href(
																	$author$project$Route$Order(model.cN))
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Gå till order')
																]))
														])))
											])),
										$author$project$SE$UI$Columns$defaultColumn(
										_List_fromArray(
											[
												$author$project$Contactbox$view(
												{
													bI: '',
													eM: 'Säljavdelningen',
													fc: $elm$core$Maybe$Just('/assets/images/innesalj.jpg'),
													fJ: '30',
													f_: _List_fromArray(
														['order']),
													c5: '',
													gr: 'Behöver du hjälp? Hör av dig!'
												})
											]))
									]))
							]))
					])),
			gr: 'Tack för din order!'
		};
	}
};
var $rtfeldman$elm_css$Css$Global$body = $rtfeldman$elm_css$Css$Global$typeSelector('body');
var $rtfeldman$elm_css$Css$Global$article = $rtfeldman$elm_css$Css$Global$typeSelector('article');
var $rtfeldman$elm_css$Css$Global$aside = $rtfeldman$elm_css$Css$Global$typeSelector('aside');
var $rtfeldman$elm_css$Css$Global$header = $rtfeldman$elm_css$Css$Global$typeSelector('header');
var $rtfeldman$elm_css$Css$Global$section = $rtfeldman$elm_css$Css$Global$typeSelector('section');
var $author$project$SE$UI$Global$block = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Global$article,
			$rtfeldman$elm_css$Css$Global$aside,
			$rtfeldman$elm_css$Css$Global$typeSelector('figure'),
			$rtfeldman$elm_css$Css$Global$footer,
			$rtfeldman$elm_css$Css$Global$header,
			$rtfeldman$elm_css$Css$Global$typeSelector('hgroup'),
			$rtfeldman$elm_css$Css$Global$section
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block)
		]));
var $rtfeldman$elm_css$Css$normal = {dA: 0, bg: 0, aL: 0, dV: 0, iY: 'normal', aU: 0};
var $author$project$SE$UI$Global$body = $rtfeldman$elm_css$Css$Global$body(
	_List_fromArray(
		[
			$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$darkest),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1.5))
		]));
var $author$project$SE$UI$Global$code = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[$rtfeldman$elm_css$Css$Global$code, $rtfeldman$elm_css$Css$Global$pre]),
	_List_fromArray(
		[
			A2($rtfeldman$elm_css$Css$property, '-moz-osx-font-smoothing', 'auto'),
			A2($rtfeldman$elm_css$Css$property, '-webkit-font-smoothing', 'auto'),
			A2($rtfeldman$elm_css$Css$property, 'font-family', $author$project$SE$UI$Font$codeFamily)
		]));
var $rtfeldman$elm_css$Css$Global$select = $rtfeldman$elm_css$Css$Global$typeSelector('select');
var $rtfeldman$elm_css$Css$Global$textarea = $rtfeldman$elm_css$Css$Global$typeSelector('textarea');
var $author$project$SE$UI$Global$fonts = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[$rtfeldman$elm_css$Css$Global$body, $rtfeldman$elm_css$Css$Global$button, $rtfeldman$elm_css$Css$Global$input, $rtfeldman$elm_css$Css$Global$select, $rtfeldman$elm_css$Css$Global$textarea]),
	_List_fromArray(
		[
			A2($rtfeldman$elm_css$Css$property, 'font-family', $author$project$SE$UI$Font$family)
		]));
var $author$project$SE$UI$Font$desktopBaseSize = $rtfeldman$elm_css$Css$pct(112.5);
var $author$project$SE$UI$Font$mobileBaseSize = $rtfeldman$elm_css$Css$pct(100);
var $rtfeldman$elm_css$Css$optimizeLegibility = {bx: 0, iY: 'optimizeLegibility'};
var $rtfeldman$elm_css$Css$scroll = {bG: 0, eA: 0, fg: 0, bp: 0, iB: 0, iY: 'scroll'};
var $rtfeldman$elm_css$Css$textRendering = $rtfeldman$elm_css$Css$prop1('text-rendering');
var $author$project$SE$UI$Global$html = $rtfeldman$elm_css$Css$Global$html(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$fontSize($author$project$SE$UI$Font$mobileBaseSize),
			A2($rtfeldman$elm_css$Css$property, '-moz-osx-font-smoothing', 'grayscale'),
			A2($rtfeldman$elm_css$Css$property, '-webkit-font-smoothing', 'antialiased'),
			$rtfeldman$elm_css$Css$minWidth(
			$rtfeldman$elm_css$Css$px(300)),
			$rtfeldman$elm_css$Css$overflowX($rtfeldman$elm_css$Css$hidden),
			$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll),
			$rtfeldman$elm_css$Css$textRendering($rtfeldman$elm_css$Css$optimizeLegibility),
			A2($rtfeldman$elm_css$Css$property, 'textSizeAdjust', '100%'),
			$author$project$SE$UI$Utils$desktop(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$fontSize($author$project$SE$UI$Font$desktopBaseSize)
				]))
		]));
var $rtfeldman$elm_css$Css$Global$fieldset = $rtfeldman$elm_css$Css$Global$typeSelector('fieldset');
var $rtfeldman$elm_css$Css$Global$hr = $rtfeldman$elm_css$Css$Global$typeSelector('hr');
var $rtfeldman$elm_css$Css$padding3 = $rtfeldman$elm_css$Css$prop3('padding');
var $rtfeldman$elm_css$Css$Global$small = $rtfeldman$elm_css$Css$Global$typeSelector('small');
var $author$project$SE$UI$Global$inlineAndBlock = $rtfeldman$elm_css$Css$Global$html(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$a(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$color(
							$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$link)),
							$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
							$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none),
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$strong(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($rtfeldman$elm_css$Css$currentColor)
										]))
								])),
							$rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color(
									$author$project$SE$UI$Colors$toCss(
										$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link)))
								]))
						])),
					$rtfeldman$elm_css$Css$Global$code(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger),
							$author$project$SE$UI$Font$bodySizeEm(-1),
							$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
							A3(
							$rtfeldman$elm_css$Css$padding3,
							$rtfeldman$elm_css$Css$em(0.25),
							$rtfeldman$elm_css$Css$em(0.5),
							$rtfeldman$elm_css$Css$em(0.25))
						])),
					$rtfeldman$elm_css$Css$Global$hr(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
							$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(2)),
							A2(
							$rtfeldman$elm_css$Css$margin2,
							$rtfeldman$elm_css$Css$rem(1.5),
							$rtfeldman$elm_css$Css$zero)
						])),
					$rtfeldman$elm_css$Css$Global$small(
					_List_fromArray(
						[
							$author$project$SE$UI$Font$bodySizeEm(-1)
						])),
					$rtfeldman$elm_css$Css$Global$span(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$inherit),
							$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$inherit)
						])),
					$rtfeldman$elm_css$Css$Global$strong(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(600))
						])),
					$rtfeldman$elm_css$Css$Global$fieldset(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero)
						])),
					$rtfeldman$elm_css$Css$Global$pre(
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Css$property, '-webkit-overflow-scrolling', 'touch'),
							$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
							$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$darkest),
							$author$project$SE$UI$Font$bodySizeEm(-1),
							$rtfeldman$elm_css$Css$overflowX($rtfeldman$elm_css$Css$auto),
							A2(
							$rtfeldman$elm_css$Css$padding2,
							$rtfeldman$elm_css$Css$rem(1.25),
							$rtfeldman$elm_css$Css$rem(1.5)),
							$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$pre),
							A2($rtfeldman$elm_css$Css$property, 'word-wrap', 'normal'),
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$code(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$danger),
											$author$project$SE$UI$Font$bodySizeEm(1),
											$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero)
										]))
								]))
						])),
					$rtfeldman$elm_css$Css$Global$table(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$each,
									_List_fromArray(
										[$rtfeldman$elm_css$Css$Global$td, $rtfeldman$elm_css$Css$Global$th]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left),
											$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
										])),
									$rtfeldman$elm_css$Css$Global$th(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$darkest),
											$rtfeldman$elm_css$Css$fontWeight(
											$rtfeldman$elm_css$Css$int(600))
										]))
								]))
						]))
				]))
		]));
var $author$project$SE$UI$Global$base = _List_fromArray(
	[$author$project$SE$UI$Global$html, $author$project$SE$UI$Global$block, $author$project$SE$UI$Global$fonts, $author$project$SE$UI$Global$code, $author$project$SE$UI$Global$body, $author$project$SE$UI$Global$inlineAndBlock]);
var $rtfeldman$elm_css$Css$Global$audio = $rtfeldman$elm_css$Css$Global$typeSelector('audio');
var $author$project$SE$UI$Global$audio = $rtfeldman$elm_css$Css$Global$audio(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$maxWidth(
			$rtfeldman$elm_css$Css$pct(100))
		]));
var $rtfeldman$elm_css$Css$Global$dt = $rtfeldman$elm_css$Css$Global$typeSelector('dt');
var $rtfeldman$elm_css$Css$Global$legend = $rtfeldman$elm_css$Css$Global$typeSelector('legend');
var $author$project$SE$UI$Global$blocks = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Global$html,
			$rtfeldman$elm_css$Css$Global$body,
			$rtfeldman$elm_css$Css$Global$p,
			$rtfeldman$elm_css$Css$Global$ol,
			$rtfeldman$elm_css$Css$Global$ul,
			$rtfeldman$elm_css$Css$Global$li,
			$rtfeldman$elm_css$Css$Global$dl,
			$rtfeldman$elm_css$Css$Global$dt,
			$rtfeldman$elm_css$Css$Global$dd,
			$rtfeldman$elm_css$Css$Global$blockquote,
			$rtfeldman$elm_css$Css$Global$typeSelector('figure'),
			$rtfeldman$elm_css$Css$Global$fieldset,
			$rtfeldman$elm_css$Css$Global$legend,
			$rtfeldman$elm_css$Css$Global$textarea,
			$rtfeldman$elm_css$Css$Global$pre,
			$rtfeldman$elm_css$Css$Global$typeSelector('iframe'),
			$rtfeldman$elm_css$Css$Global$hr,
			$rtfeldman$elm_css$Css$Global$h1,
			$rtfeldman$elm_css$Css$Global$h2,
			$rtfeldman$elm_css$Css$Global$h3,
			$rtfeldman$elm_css$Css$Global$h4,
			$rtfeldman$elm_css$Css$Global$h5,
			$rtfeldman$elm_css$Css$Global$h6
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero)
		]));
var $author$project$SE$UI$Global$boxSizing = $rtfeldman$elm_css$Css$Global$html(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$boxSizing($rtfeldman$elm_css$Css$borderBox),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$each,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$everything,
							$rtfeldman$elm_css$Css$Global$selector('*::before'),
							$rtfeldman$elm_css$Css$Global$selector('*::after')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$boxSizing($rtfeldman$elm_css$Css$inherit)
						]))
				]))
		]));
var $rtfeldman$elm_css$Css$Global$form = $rtfeldman$elm_css$Css$Global$typeSelector('form');
var $author$project$SE$UI$Global$form = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[$rtfeldman$elm_css$Css$Global$form, $rtfeldman$elm_css$Css$Global$button, $rtfeldman$elm_css$Css$Global$input, $rtfeldman$elm_css$Css$Global$select, $rtfeldman$elm_css$Css$Global$textarea]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero)
		]));
var $author$project$SE$UI$Global$headings = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[$rtfeldman$elm_css$Css$Global$h1, $rtfeldman$elm_css$Css$Global$h2, $rtfeldman$elm_css$Css$Global$h3, $rtfeldman$elm_css$Css$Global$h4, $rtfeldman$elm_css$Css$Global$h5, $rtfeldman$elm_css$Css$Global$h6]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$fontWeight(
			$rtfeldman$elm_css$Css$int(600))
		]));
var $author$project$SE$UI$Global$iframe = A2(
	$rtfeldman$elm_css$Css$Global$typeSelector,
	'iframe',
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero)
		]));
var $author$project$SE$UI$Global$lists = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[$rtfeldman$elm_css$Css$Global$ul]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$listStyle($rtfeldman$elm_css$Css$none)
		]));
var $author$project$SE$UI$Global$media = A2(
	$rtfeldman$elm_css$Css$Global$each,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Global$selector('img:not([height])'),
			$rtfeldman$elm_css$Css$Global$selector('embed:not([height])'),
			$rtfeldman$elm_css$Css$Global$selector('iframe:not([height])'),
			$rtfeldman$elm_css$Css$Global$selector('object:not([height])'),
			$rtfeldman$elm_css$Css$Global$selector('video:not([height])')
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$auto),
			$rtfeldman$elm_css$Css$maxWidth(
			$rtfeldman$elm_css$Css$pct(100))
		]));
var $author$project$SE$UI$Global$misc = A2(
	$rtfeldman$elm_css$Css$Global$typeSelector,
	'address',
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal)
		]));
var $rtfeldman$elm_css$Css$borderCollapse = $rtfeldman$elm_css$Css$prop1('border-collapse');
var $rtfeldman$elm_css$Css$borderSpacing = $rtfeldman$elm_css$Css$prop1('border-spacing');
var $rtfeldman$elm_css$Css$collapse = {eC: 0, iY: 'collapse', ch: 0};
var $author$project$SE$UI$Global$table = $rtfeldman$elm_css$Css$Global$table(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderCollapse($rtfeldman$elm_css$Css$collapse),
			$rtfeldman$elm_css$Css$borderSpacing($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$each,
					_List_fromArray(
						[$rtfeldman$elm_css$Css$Global$td, $rtfeldman$elm_css$Css$Global$th]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
						]))
				]))
		]));
var $author$project$SE$UI$Global$reset = _List_fromArray(
	[$author$project$SE$UI$Global$blocks, $author$project$SE$UI$Global$headings, $author$project$SE$UI$Global$lists, $author$project$SE$UI$Global$form, $author$project$SE$UI$Global$boxSizing, $author$project$SE$UI$Global$media, $author$project$SE$UI$Global$audio, $author$project$SE$UI$Global$iframe, $author$project$SE$UI$Global$table, $author$project$SE$UI$Global$misc]);
var $author$project$SE$UI$Global$global = $rtfeldman$elm_css$Css$Global$global(
	_Utils_ap($author$project$SE$UI$Global$reset, $author$project$SE$UI$Global$base));
var $rtfeldman$elm_css$Svg$Styled$Attributes$fill = $rtfeldman$elm_css$VirtualDom$Styled$attribute('fill');
var $rtfeldman$elm_css$Svg$Styled$Attributes$fillRule = $rtfeldman$elm_css$VirtualDom$Styled$attribute('fill-rule');
var $author$project$SE$UI$Logo$se = function (rgb) {
	return A2(
		$rtfeldman$elm_css$Svg$Styled$svg,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('0 0 259.07 96.86')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M12.65 93.03c0-4.53-10.06-2.79-10.06-5.4 0-1 1.55-1.81 3.89-1.81a9.38 9.38 0 0 1 4.75 1.38l1.22-1.51a11.29 11.29 0 0 0-5.9-1.71c-3.79 0-6.2 1.71-6.2 3.94 0 4.39 10 2.48 10 5.34 0 .92-1.15 1.77-3.74 1.77a13.24 13.24 0 0 1-5.81-1.5l-.83 1.79a15.39 15.39 0 0 0 6.53 1.54c3.57 0 6.12-1.49 6.12-3.83z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb)
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M28.85 88.17v-.07c0-2.19-1.62-3.69-5.22-3.69h-6.51v12h2.21v-4.48h4.28c3.6 0 5.22-1.52 5.22-3.74zm-2.25.07c0 1-.68 1.88-2.54 1.88h-4.73v-3.84h4.61c1.85 0 2.64.81 2.64 1.82v.14z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fillRule('evenodd')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb),
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M44.16 96.39v-1.87h-8.63v-3.35h6.64v-1.81h-6.64v-3.08h8.63v-1.87H33.28v11.98h10.88zM61.52 86.37a8.61 8.61 0 0 0-6.09-2.39c-4.39 0-7.39 3.06-7.39 6.3v.39c0 3.24 2.75 6.19 7.29 6.19a9 9 0 0 0 6.19-2.39l-1.35-1.42a7 7 0 0 1-4.7 1.92c-3.35 0-5.16-2.12-5.16-4.39v-.23c0-2.28 1.8-4.48 5-4.48a7 7 0 0 1 4.8 1.93l1.41-1.43zM68.1 96.39V84.41h-2.25v11.98h2.25z')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M86.8 96.39l-6.58-12h-1.89l-6.48 12h2.37l1.53-3h7l1.58 3zm-4.87-4.6h-5.31l2.61-5 2.7 5z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fillRule('evenodd')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb),
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M102.17 96.39v-1.87H92.8V84.41h-2.25v11.98h11.62zM117.2 96.39v-1.87h-8.63v-3.35h6.63v-1.81h-6.63v-3.08h8.63v-1.87h-10.88v11.98h10.88zM133.42 96.39v-1.87h-9.38V84.41h-2.24v11.98h11.62zM148.45 96.39v-1.87h-8.64v-3.35h6.64v-1.81h-6.64v-3.08h8.64v-1.87h-10.89v11.98h10.89zM165.87 96.39l-7.1-6.4 6.94-5.58h-3l-7.38 6.14v-6.1h-2.24v12h2.24v-3.61l2-1.58 5.69 5.19zM181.78 86.28v-1.87h-12.72v1.87h5.23v10.11h2.25V86.28h5.24z')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M198.65 96.39v-.05a2.64 2.64 0 0 1-.74-2v-1c0-1.41-.52-2.47-2.86-2.65 2.34-.43 3-1.67 3-3 0-1.86-1.62-3.3-5.22-3.3h-6.5v12h2.25v-4.83h4.24c2.25 0 2.74.5 2.81 1.69l.07 1.22c.07 1.08.34 1.92.95 1.92zm-2.88-8.25c0 .95-.77 1.74-2.43 1.74h-4.8v-3.6h4.6c1.93 0 2.63.85 2.63 1.71v.15zM216.33 90.62v-.4c0-3.23-2.75-6.24-7-6.24s-7 3-7 6.24v.4c0 3.18 2.75 6.24 7 6.24s7-3.06 7-6.24zm-2.26-.07a4.76 4.76 0 0 1-9.49 0v-.29a4.76 4.76 0 0 1 9.49 0v.29z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fillRule('evenodd')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill(rgb),
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M233.54 96.39V84.41h-2.25v8.87l-.77-.86-7.56-8.01h-2.15v11.98h2.24v-8.87l.76.85 7.79 8.02h1.94zM241.02 96.39V84.41h-2.25v11.98h2.25zM259.07 96.39l-7.11-6.4 6.95-5.58h-3l-7.4 6.14v-6.1h-2.23v12h2.23v-3.61l2-1.58 5.69 5.19z')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M182.66 50.22v-1H162.4c0 5.5-3.33 9.55-17.95 9.55-15.92 0-19.24-5.35-19.24-13.89v-3.63h57.45V25.18c0-13.75-3.91-25.18-38.79-25.18s-38.78 11.45-38.78 25.18v21.13c0 13.6 4.49 25.18 39.36 25.18 30.39 0 38.21-7.67 38.21-21.27zM162.4 29.67h-37.19v-2.61c0-9 2.17-14.61 18.66-14.61s18.53 5.64 18.53 14.61v2.61z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill('#9c9c9e'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fillRule('evenodd')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M130.55 29.81l-25.22-1.59c-5.06-.43-7.52-2.31-7.52-6.66 0-5.49 2.31-8.53 18.38-8.53 14 0 16.35 2.89 16.35 8.25v.43h20.69v-1.88c0-12.59-6.36-19.68-37-19.68-27.93 0-39.08 6.65-39.08 21.7 0 11.73 5.65 19 23.74 20.12l25 1.59c5.21.29 7.67 2.17 7.67 6.66 0 5.5-2.46 8.53-18.52 8.53-14.47 0-17.37-2.89-17.37-8.24v-.44H77.15v1.88c0 12.45 6.52 19.69 37.92 19.69 28.07 0 39.07-6.66 39.07-21.86 0-12.88-4.77-18.81-23.59-20z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill('#359d37')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Svg$Styled$Attributes$d('M105.11 47.81c.35 12.94 5.76 23.68 39.34 23.68 30.39 0 38.21-7.67 38.21-21.27v-1H162.4c0 5.5-3.33 9.55-17.95 9.55-13.88 0-18.19-4.07-18.95-10.77l-20.39-.17z'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fill('#9c9c9e'),
						$rtfeldman$elm_css$Svg$Styled$Attributes$fillRule('evenodd')
					]),
				_List_Nil)
			]));
};
var $author$project$SE$UI$Logo$onWhite = $author$project$SE$UI$Logo$se('#000');
var $author$project$Page$viewErrorFrame = function (apiError) {
	return {
		bI: _List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('wrapper')
					]),
				_List_fromArray(
					[
						$author$project$SE$UI$Global$global,
						$rtfeldman$elm_css$Css$Global$global(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$body(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightBlue),
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$px(128))),
										$rtfeldman$elm_css$Css$minHeight(
										A3(
											$rtfeldman$elm_css$Css$calc,
											$rtfeldman$elm_css$Css$vh(100),
											$rtfeldman$elm_css$Css$minus,
											$rtfeldman$elm_css$Css$px(128))),
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
									]))
							])),
						A2(
						$author$project$SE$UI$Section$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$SE$UI$Container$container,
								_List_fromArray(
									[1]),
								_List_fromArray(
									[
										A4(
										$rtfeldman$elm_css$Html$Styled$styled,
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$maxWidth(
												$rtfeldman$elm_css$Css$px(240)),
												A2(
												$rtfeldman$elm_css$Css$margin2,
												$rtfeldman$elm_css$Css$rem(1.5),
												$rtfeldman$elm_css$Css$auto),
												$rtfeldman$elm_css$Css$Global$descendants(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$selector,
														'[fill=\"#359d37\"]',
														_List_fromArray(
															[
																A2($rtfeldman$elm_css$Css$property, 'transform', 'scale(1, -1) translate(0, -74%)')
															]))
													])),
												$rtfeldman$elm_css$Css$Global$adjacentSiblings(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Css$Global$class,
														'card',
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$maxWidth(
																$rtfeldman$elm_css$Css$px(640)),
																A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto)
															]))
													]))
											]),
										_List_Nil,
										_List_fromArray(
											[$author$project$SE$UI$Logo$onWhite])),
										$author$project$SE$UI$Card$toHtml(
										A2(
											$author$project$SE$UI$Card$withSubTitle,
											'Typiskt.',
											A2(
												$author$project$SE$UI$Card$withTitle,
												'Uh oh.',
												$author$project$SE$UI$Card$withBoxShadow(
													$author$project$SE$UI$Card$content(
														_List_fromArray(
															[
																$author$project$Api$errorToHtml(apiError)
															]))))))
									]))
							]))
					]))
			]),
		gr: 'Något gick fel'
	};
};
var $author$project$Page$snackbarConfig = function (sessionMsg) {
	return {
		gu: A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$GotSnackbarMsg)
	};
};
var $author$project$Footer$buildSiteUrl = function (paths) {
	return A2(
		$elm$core$String$join,
		'/',
		A2($elm$core$List$cons, $author$project$Constants$siteUrl, paths));
};
var $author$project$Footer$customBox = F2(
	function (title, content) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Title$title5(title),
					content
				]));
	});
var $author$project$Footer$liLink = function (_v0) {
	var label = _v0.a;
	var url = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(url)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(label)
					]))
			]));
};
var $author$project$Footer$defaultBox = F2(
	function (title, links) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SE$UI$Title$title5(title),
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_Nil,
					A2($elm$core$List$map, $author$project$Footer$liLink, links))
				]));
	});
var $author$project$SE$UI$Icon$V2$facebook = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		35,
		40,
		_List_fromArray(
			['M34.32 9.42L19 .57a2 2 0 00-2 0L1.68 9.42a2 2 0 00-1 1.73v17.69a2 2 0 001 1.73L17 39.42a2 2 0 002 0l15.32-8.85a2 2 0 001-1.73V11.15a2 2 0 00-1-1.73zM22.82 14h-1.41a1.8 1.8 0 00-1.44.53 1.84 1.84 0 00-.42 1.23v2.11h3.13l-.5 3.27h-2.63V29H16v-7.88h-2.82v-3.27H16v-2.46a5 5 0 01.56-2.39A3.41 3.41 0 0118 11.52a4.67 4.67 0 012.29-.52 10.08 10.08 0 011.19.07 6.46 6.46 0 011 .1h.36z'])));
var $author$project$SE$UI$Icon$V2$instagram = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		35,
		40,
		_List_fromArray(
			['M20.09 19.69a2.77 2.77 0 11-2.77-2.77 2.77 2.77 0 012.77 2.77z', 'M23.8 14.79a2.85 2.85 0 00-1.58-1.58c-1.09-.43-3.69-.33-4.9-.33s-3.8-.1-4.89.33a2.85 2.85 0 00-1.58 1.58c-.43 1.09-.34 3.69-.34 4.9s-.1 3.8.34 4.89a2.79 2.79 0 001.58 1.58c1.09.43 3.68.34 4.89.34s3.8.1 4.9-.34a2.79 2.79 0 001.58-1.58c.43-1.08.33-3.68.33-4.89s.1-3.8-.33-4.9zm-6.48 9.16a4.26 4.26 0 114.26-4.26 4.25 4.25 0 01-4.26 4.26zm4.43-7.7a1 1 0 010-2 1 1 0 110 2z', 'M33.64 9.11L18.32.27a2 2 0 00-2 0L1 9.11a2 2 0 00-1 1.73v17.69a2 2 0 001 1.74l15.32 8.84a2 2 0 002 0l15.32-8.84a2 2 0 001-1.74V10.84a2 2 0 00-1-1.73zm-8.07 14a5 5 0 01-1.34 3.48 4.93 4.93 0 01-3.48 1.35c-1.37.07-5.49.07-6.86 0a5 5 0 01-3.48-1.35 4.92 4.92 0 01-1.34-3.48c-.08-1.37-.08-5.48 0-6.85a5 5 0 011.34-3.48 5 5 0 013.48-1.34c1.37-.08 5.49-.08 6.86 0a5 5 0 013.48 1.34 4.94 4.94 0 011.34 3.48c.08 1.43.08 5.48 0 6.85z'])));
var $author$project$SE$UI$Icon$V2$linkedin = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		35,
		40,
		_List_fromArray(
			['M33.64 9.11L18.32.26a2 2 0 00-2 0L1 9.11a2 2 0 00-1 1.73v17.69a2 2 0 001 1.73l15.32 8.85a2 2 0 002 0l15.32-8.85a2 2 0 001-1.73V10.84a2 2 0 00-1-1.73zM12.96 27.54H9.69V17.03h3.27zm-1.64-11.92a1.91 1.91 0 01-1.88-1.93 1.89 1.89 0 013.77 0 1.92 1.92 0 01-1.89 1.93zm13.87 11.92h-3.26v-5.09c0-1.24 0-2.78-1.73-2.78s-1.88 1.3-1.88 2.67v5.2H15V17.03h3.13v1.44a3.52 3.52 0 013.1-1.69c3.3 0 3.93 2.18 3.93 5z'])));
var $author$project$SE$UI$Logo$onBlack = $author$project$SE$UI$Logo$se('#fff');
var $author$project$Newsletter$GotEmailInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Newsletter$GotUrlInput = function (a) {
	return {$: 1, a: a};
};
var $author$project$Newsletter$SubmittedForm = {$: 3};
var $rtfeldman$elm_css$Html$Styled$fieldset = $rtfeldman$elm_css$Html$Styled$node('fieldset');
var $rtfeldman$elm_css$Css$nthLastChild = function (str) {
	return $rtfeldman$elm_css$Css$pseudoClass('nth-last-child(' + (str + ')'));
};
var $author$project$Newsletter$GotClickedList = function (a) {
	return {$: 2, a: a};
};
var $author$project$Newsletter$viewCheckbox = function (_v0) {
	var checked = _v0.a;
	var key = _v0.b;
	var label = _v0.c;
	return $author$project$SE$UI$Form$Input$toHtml(
		A3(
			$author$project$SE$UI$Form$Input$checkbox,
			$author$project$Newsletter$GotClickedList(key),
			label,
			checked));
};
var $author$project$Newsletter$viewForm = F2(
	function (form, isSending) {
		var submittable = (!isSending) && (A2(
			$elm$core$List$any,
			function (_v0) {
				var c = _v0.a;
				return c;
			},
			form.a0) && (form.be !== ''));
		var label = isSending ? 'Skickar...' : 'Prenumerera';
		return A2(
			$rtfeldman$elm_css$Html$Styled$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Newsletter$SubmittedForm),
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$descendants(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$label(
									_List_fromArray(
										[
											$author$project$SE$UI$Utils$desktop(
											_List_fromArray(
												[
													$author$project$SE$UI$Font$bodySizeRem(-1)
												]))
										])),
									A2(
									$rtfeldman$elm_css$Css$Global$class,
									'field',
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$nthLastChild,
											'2',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$opacity($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
													$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
													$rtfeldman$elm_css$Css$zIndex(
													$rtfeldman$elm_css$Css$int(-1))
												]))
										]))
								]))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$fieldset,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$disabled(isSending)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$field,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$control,
									false,
									A2($elm$core$List$map, $author$project$Newsletter$viewCheckbox, form.a0))
								])),
							A2(
							$author$project$SE$UI$Form$field,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$SE$UI$Form$labelRequired('Epostadress'),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											$author$project$SE$UI$Form$Input$toHtml(
											A2(
												$author$project$SE$UI$Form$Input$withName,
												'email',
												$author$project$SE$UI$Form$Input$withRequired(
													A2(
														$author$project$SE$UI$Form$Input$withTrigger,
														0,
														A2($author$project$SE$UI$Form$Input$email, $author$project$Newsletter$GotEmailInput, form.be)))))
										]))
								])),
							A2(
							$author$project$SE$UI$Form$field,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$SE$UI$Form$label('Url'),
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$input,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
													$rtfeldman$elm_css$Html$Styled$Attributes$tabindex(-1),
													A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'autocomplete', 'false'),
													$rtfeldman$elm_css$Html$Styled$Attributes$name('url'),
													$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Newsletter$GotUrlInput)
												]),
											_List_Nil)
										]))
								])),
							A2(
							$author$project$SE$UI$Form$field,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$SE$UI$Form$control,
									false,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$input,
											A2(
												$author$project$SE$UI$Buttons$buttonStyles,
												_List_fromArray(
													[
														$author$project$SE$UI$Buttons$Color(9),
														$author$project$SE$UI$Buttons$Fullwidth
													]),
												false),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
													$rtfeldman$elm_css$Html$Styled$Attributes$value(label),
													$rtfeldman$elm_css$Html$Styled$Attributes$disabled(!submittable)
												]),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var $author$project$Newsletter$view = F2(
	function (transform, model) {
		switch (model.$) {
			case 3:
				var message = model.a;
				return A2(
					$author$project$SE$UI$Notification$primary,
					$elm$core$Maybe$Nothing,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(message)
						]));
			case 2:
				var form = model.a;
				var error = model.b;
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Notification$danger,
							$elm$core$Maybe$Nothing,
							_List_fromArray(
								[
									$author$project$Api$errorToHtml(error)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							transform,
							A2($author$project$Newsletter$viewForm, form, false))
						]));
			case 1:
				var form = model.a;
				return A2(
					$rtfeldman$elm_css$Html$Styled$map,
					transform,
					A2($author$project$Newsletter$viewForm, form, true));
			default:
				var form = model.a;
				return A2(
					$rtfeldman$elm_css$Html$Styled$map,
					transform,
					A2($author$project$Newsletter$viewForm, form, false));
		}
	});
var $rtfeldman$elm_css$Html$Styled$address = $rtfeldman$elm_css$Html$Styled$node('address');
var $rtfeldman$elm_css$Html$Styled$Attributes$itemprop = $rtfeldman$elm_css$VirtualDom$Styled$attribute('itemprop');
var $author$project$Footer$viewAddress = function (_v0) {
	var streetAddress = _v0.aS;
	var postalCode = _v0.aQ;
	var addressLocality = _v0.aB;
	var link = _v0.hM;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$address,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal)
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('streetAddress')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(streetAddress)
					])),
				A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('postalCode')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(postalCode)
					])),
				$rtfeldman$elm_css$Html$Styled$text(' '),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('addressLocality')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(addressLocality)
					])),
				A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(link)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Hitta hit')
					]))
			]));
};
var $author$project$Footer$viewName = function (name) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('name')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$strong,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(name)
					]))
			]));
};
var $author$project$Footer$viewOffices = function (displayPhoneAndEmail) {
	return $author$project$SE$UI$Columns$columns(
		_List_fromArray(
			[
				$author$project$SE$UI$Columns$defaultColumn(
				_List_fromArray(
					[
						$author$project$SE$UI$Title$title5('Våra kontor'),
						$author$project$SE$UI$Columns$columns(
						_List_fromArray(
							[
								$author$project$SE$UI$Columns$defaultColumn(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('address-container'),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/PostalAddress ')
											]),
										_List_fromArray(
											[
												$author$project$Footer$viewName('Karlstad (huvudkontor)'),
												$author$project$Footer$viewAddress(
												{aB: 'Karlstad', hM: 'https://goo.gl/maps/jhuqDTS3fn5UGfAL9', aQ: '653 51', aS: 'Granlidsvägen 85'}),
												$author$project$Footer$viewName('Postadress'),
												A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$address,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal)
													]),
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('postOfficeBoxNumber')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Box 8065')
															])),
														A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('postalCode')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('650 08')
															])),
														$rtfeldman$elm_css$Html$Styled$text(' '),
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$itemprop('addressLocality')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Karlstad')
															]))
													])),
												A2(
												$author$project$Helpers$viewIf,
												displayPhoneAndEmail,
												A2(
													$rtfeldman$elm_css$Html$Styled$p,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('tel:+46544442000')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('+46 54 444 2000')
																])),
															A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:info@specialelektronik.se')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('info@specialelektronik.se')
																]))
														]))),
												A2(
												$rtfeldman$elm_css$Html$Styled$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$strong,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Special-Elektronik i Karlstad AB')
															])),
														A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
														$rtfeldman$elm_css$Html$Styled$text('Organisationsnr: 556173-2883'),
														A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
														$rtfeldman$elm_css$Html$Styled$text('VAT-nr: SE556173288301')
													]))
											]))
									])),
								$author$project$SE$UI$Columns$defaultColumn(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('address-container'),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/PostalAddress')
											]),
										_List_fromArray(
											[
												$author$project$Footer$viewName('Uppsala'),
												$author$project$Footer$viewAddress(
												{aB: 'Uppsala', hM: 'https://goo.gl/maps/RPq7FmbjyZGT1r2A8', aQ: '754 19', aS: 'Vattholmavägen 16'})
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('address-container'),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/PostalAddress')
											]),
										_List_fromArray(
											[
												$author$project$Footer$viewName('Malmö'),
												$author$project$Footer$viewAddress(
												{aB: 'Limhamn', hM: 'https://goo.gl/maps/KYJaQC5hF4x4sris7', aQ: '216 16', aS: 'Ringugnsgatan 7'})
											]))
									])),
								$author$project$SE$UI$Columns$defaultColumn(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('address-container'),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/PostalAddress')
											]),
										_List_fromArray(
											[
												$author$project$Footer$viewName('Göteborg'),
												$author$project$Footer$viewAddress(
												{aB: 'Göteborg', hM: 'https://goo.gl/maps/uyjK3Xx6rVLn4fUo9', aQ: '414 51', aS: 'Banehagsgatan 22'})
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('address-container'),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/PostalAddress')
											]),
										_List_fromArray(
											[
												$author$project$Footer$viewName('Halmstad'),
												$author$project$Footer$viewAddress(
												{aB: 'Halmstad', hM: 'https://goo.gl/maps/E8kpHiU5kjZUEnHX7', aQ: '302 31', aS: 'Slottsmöllan 10B, Våning 3'})
											]))
									])),
								$author$project$SE$UI$Columns$defaultColumn(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('address-container'),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemscope', ''),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'itemtype', 'http://schema.org/PostalAddress')
											]),
										_List_fromArray(
											[
												$author$project$Footer$viewName('Umeå'),
												$author$project$Footer$viewAddress(
												{aB: 'Umeå', hM: 'https://goo.gl/maps/aNy3DN2EafQAB3QE6', aQ: '903 55', aS: 'Yxvägen 11'})
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$strong,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Öppettider')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('Mån-tors 8.00-17.00'),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('Fre 8.00-16.00'),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('Lunch 12.00-13.00')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$SE$UI$Icon$V2$youtube = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		35,
		40,
		_List_fromArray(
			['M15.29 16.75l5.18 2.95-5.18 2.94v-5.89z', 'M33.64 9.11L18.32.26a2 2 0 00-2 0L1 9.11a2 2 0 00-1 1.73v17.69a2 2 0 001 1.73l15.32 8.85a2 2 0 002 0l15.32-8.85a2 2 0 001-1.73V10.84a2 2 0 00-1-1.73zM26.81 24.5a2.45 2.45 0 01-1.75 1.73c-1.55.46-7.74.46-7.74.46s-6.19 0-7.74-.42a2.45 2.45 0 01-1.75-1.73 26.4 26.4 0 01-.41-4.85 26.4 26.4 0 01.41-4.8 2.49 2.49 0 011.75-1.76c1.55-.44 7.74-.44 7.74-.44s6.19 0 7.74.42a2.49 2.49 0 011.75 1.76 26.4 26.4 0 01.41 4.82 26.4 26.4 0 01-.41 4.81z'])));
var $author$project$Footer$view = F2(
	function (transform, newsletterModel) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$footer,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darkest),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
					$author$project$SE$UI$Utils$desktop(
					_List_fromArray(
						[
							$author$project$SE$UI$Font$bodySizeRem(-1)
						])),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$typeSelector,
							'p',
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$pseudoClass,
									'not(:first-child)',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(1.5))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$typeSelector,
							'label',
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white)
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$typeSelector,
							'a',
							_List_fromArray(
								[
									$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
									$rtfeldman$elm_css$Css$hover(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$color(
											$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'address-container',
							_List_fromArray(
								[$author$project$SE$UI$Utils$block]))
						]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Section$section,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A4(
											$rtfeldman$elm_css$Html$Styled$styled,
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$maxWidth(
													$rtfeldman$elm_css$Css$px(224)),
													$rtfeldman$elm_css$Css$marginBottom(
													$rtfeldman$elm_css$Css$rem(2.66666667))
												]),
											_List_Nil,
											_List_fromArray(
												[$author$project$SE$UI$Logo$onBlack]))
										])),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$author$project$SE$UI$Utils$block,
											A2($rtfeldman$elm_css$Css$property, 'display', 'grid'),
											A2($rtfeldman$elm_css$Css$property, 'grid-gap', '1rem'),
											A2($rtfeldman$elm_css$Css$property, 'grid-template-columns', 'repeat(auto-fit, minmax(300px, 1fr))')
										]),
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$Footer$defaultBox,
											'Tjänster',
											_List_fromArray(
												[
													_Utils_Tuple2(
													'Konsult och systemdesign',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['tjanster', 'konsult-och-systemdesign']))),
													_Utils_Tuple2(
													'Logistik och rumspackning',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['tjanster', 'logistik-och-rumspackning']))),
													_Utils_Tuple2(
													'Rack och skåpbyggnation',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['tjanster', 'rack-och-skapbyggnation']))),
													_Utils_Tuple2(
													'Reparation och service',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['tjanster', 'reparation-och-service']))),
													_Utils_Tuple2(
													'RMA',
													$author$project$Route$toString($author$project$Route$RMA)),
													_Utils_Tuple2(
													'Crestron support',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['tjanster', 'crestron-support'])))
												])),
											A2(
											$author$project$Footer$customBox,
											'Om oss',
											A2(
												$rtfeldman$elm_css$Html$Styled$ul,
												_List_Nil,
												_List_fromArray(
													[
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Om Special-Elektronik',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['om-oss'])))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Kontakta oss',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['kontakta-oss'])))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Jobba hos oss',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['karriar'])))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Historia',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['om-oss', 'historia'])))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Kurser',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['kurser'])))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Leveransvillkor',
															$author$project$Route$toString($author$project$Route$DeliveryTerms))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Integritetspolicy',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['privacy'])))),
														$author$project$Footer$liLink(
														_Utils_Tuple2(
															'Cookies',
															$author$project$Footer$buildSiteUrl(
																_List_fromArray(
																	['cookies'])))),
														A2(
														$rtfeldman$elm_css$Html$Styled$li,
														_List_Nil,
														_List_fromArray(
															[
																A4(
																$rtfeldman$elm_css$Html$Styled$styled,
																$rtfeldman$elm_css$Html$Styled$button,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Css$important(
																		A2($rtfeldman$elm_css$Css$property, 'all', 'unset')),
																		$rtfeldman$elm_css$Css$important(
																		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white)),
																		$rtfeldman$elm_css$Css$hover(
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$important(
																				$author$project$SE$UI$Colors$color(
																					$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))),
																				$rtfeldman$elm_css$Css$important(
																				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer))
																			]))
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('se-custom-ot-sdk-show-settings')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('Cookiesinställningar')
																	]))
															]))
													]))),
											A2(
											$author$project$Footer$defaultBox,
											'Nyheter och pressrum',
											_List_fromArray(
												[
													_Utils_Tuple2(
													'Nyheter',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['nyheter']))),
													_Utils_Tuple2(
													'Nyhetsarkiv',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['nyheter', 'arkiv']))),
													_Utils_Tuple2(
													'Pressmeddelanden',
													$author$project$Footer$buildSiteUrl(
														_List_fromArray(
															['nyheter', 'pressmeddelande'])))
												])),
											A2(
											$author$project$Footer$customBox,
											'Följ oss',
											A4(
												$rtfeldman$elm_css$Html$Styled$styled,
												$rtfeldman$elm_css$Html$Styled$ul,
												_List_fromArray(
													[$rtfeldman$elm_css$Css$displayFlex]),
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$li,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$a,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$href('https://facebook.com/specialelektronik')
																	]),
																_List_fromArray(
																	[
																		$author$project$SE$UI$Icon$V2$toHtml(
																		A2($author$project$SE$UI$Icon$V2$withSize, 3, $author$project$SE$UI$Icon$V2$facebook))
																	]))
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$li,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$a,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.instagram.com/specialelektronik/')
																	]),
																_List_fromArray(
																	[
																		$author$project$SE$UI$Icon$V2$toHtml(
																		A2($author$project$SE$UI$Icon$V2$withSize, 3, $author$project$SE$UI$Icon$V2$instagram))
																	]))
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$li,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$a,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.linkedin.com/company/specialelektronik-ab')
																	]),
																_List_fromArray(
																	[
																		$author$project$SE$UI$Icon$V2$toHtml(
																		A2($author$project$SE$UI$Icon$V2$withSize, 3, $author$project$SE$UI$Icon$V2$linkedin))
																	]))
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$li,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$a,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.youtube.com/channel/UC-dTyW9181xms8TpxXHF7oQ')
																	]),
																_List_fromArray(
																	[
																		$author$project$SE$UI$Icon$V2$toHtml(
																		A2($author$project$SE$UI$Icon$V2$withSize, 3, $author$project$SE$UI$Icon$V2$youtube))
																	]))
															]))
													])))
										])),
									$author$project$SE$UI$Columns$columns(
									_List_fromArray(
										[
											$author$project$SE$UI$Columns$defaultColumn(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$map,
													$elm$core$Basics$never,
													$author$project$Footer$viewOffices(true))
												])),
											A2(
											$author$project$SE$UI$Columns$column,
											_List_fromArray(
												[
													_Utils_Tuple2(2, 5)
												]),
											_List_fromArray(
												[
													$author$project$SE$UI$Title$title5('Nyhetsbrev'),
													A2(
													$rtfeldman$elm_css$Html$Styled$p,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$css(
															_List_fromArray(
																[$author$project$SE$UI$Utils$block]))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Prenumerera på vårt nyhetsbrev för att få de senaste nyheterna från Special-Elektronik.')
														])),
													A2($author$project$Newsletter$view, transform, newsletterModel)
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$SE$UI$Navbar$V2$CustomItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$SE$UI$Navbar$V2$Link = F3(
	function (href, label, icon) {
		return {dF: href, bR: icon, cB: label};
	});
var $author$project$Session$NavbarMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Navbar$Brand$Default = 0;
var $author$project$Navbar$Brand$Easter = 3;
var $author$project$Navbar$Brand$Halloween = 5;
var $author$project$Navbar$Brand$Midsummer = 4;
var $author$project$Navbar$Brand$ValentinesDay = 2;
var $author$project$Navbar$Brand$Xmas = 1;
var $author$project$Navbar$Brand$alternatives = _List_fromArray(
	[
		_Utils_Tuple2('1213', 1),
		_Utils_Tuple2('1214', 1),
		_Utils_Tuple2('1215', 1),
		_Utils_Tuple2('1216', 1),
		_Utils_Tuple2('1217', 1),
		_Utils_Tuple2('1218', 1),
		_Utils_Tuple2('1219', 1),
		_Utils_Tuple2('1220', 1),
		_Utils_Tuple2('1221', 1),
		_Utils_Tuple2('1222', 1),
		_Utils_Tuple2('1223', 1),
		_Utils_Tuple2('1224', 1),
		_Utils_Tuple2('1225', 1),
		_Utils_Tuple2('1226', 1),
		_Utils_Tuple2('1227', 1),
		_Utils_Tuple2('1228', 1),
		_Utils_Tuple2('1229', 1),
		_Utils_Tuple2('1230', 1),
		_Utils_Tuple2('1231', 1),
		_Utils_Tuple2('0101', 1),
		_Utils_Tuple2('0102', 1),
		_Utils_Tuple2('0103', 1),
		_Utils_Tuple2('0104', 1),
		_Utils_Tuple2('0105', 1),
		_Utils_Tuple2('0106', 1),
		_Utils_Tuple2('0208', 2),
		_Utils_Tuple2('0209', 2),
		_Utils_Tuple2('0210', 2),
		_Utils_Tuple2('0211', 2),
		_Utils_Tuple2('0212', 2),
		_Utils_Tuple2('0213', 2),
		_Utils_Tuple2('0214', 2),
		_Utils_Tuple2('0321', 3),
		_Utils_Tuple2('0322', 3),
		_Utils_Tuple2('0323', 3),
		_Utils_Tuple2('0324', 3),
		_Utils_Tuple2('0325', 3),
		_Utils_Tuple2('0326', 3),
		_Utils_Tuple2('0327', 3),
		_Utils_Tuple2('0328', 3),
		_Utils_Tuple2('0329', 3),
		_Utils_Tuple2('0330', 3),
		_Utils_Tuple2('0331', 3),
		_Utils_Tuple2('0401', 3),
		_Utils_Tuple2('0614', 4),
		_Utils_Tuple2('0615', 4),
		_Utils_Tuple2('0616', 4),
		_Utils_Tuple2('0617', 4),
		_Utils_Tuple2('0618', 4),
		_Utils_Tuple2('0619', 4),
		_Utils_Tuple2('0620', 4),
		_Utils_Tuple2('0621', 4),
		_Utils_Tuple2('0622', 4),
		_Utils_Tuple2('0623', 4),
		_Utils_Tuple2('1025', 5),
		_Utils_Tuple2('1026', 5),
		_Utils_Tuple2('1027', 5),
		_Utils_Tuple2('1028', 5),
		_Utils_Tuple2('1029', 5),
		_Utils_Tuple2('1030', 5),
		_Utils_Tuple2('1031', 5),
		_Utils_Tuple2('1101', 5)
	]);
var $author$project$SE$UI$Navbar$V2$CustomBrand = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$SE$UI$Navbar$V2$DefaultBrand = function (a) {
	return {$: 0, a: a};
};
var $author$project$Navbar$Brand$internalToBrand = function (a) {
	switch (a) {
		case 0:
			return $author$project$SE$UI$Navbar$V2$DefaultBrand;
		case 1:
			return $author$project$SE$UI$Navbar$V2$CustomBrand(
				{bY: '/assets/images/se-xmas-onBlack.svg', bZ: '/assets/images/se-xmas-onWhite.svg'});
		case 2:
			return $author$project$SE$UI$Navbar$V2$CustomBrand(
				{bY: '/assets/images/se-valentine-onBlack.svg', bZ: '/assets/images/se-valentine-onWhite.svg'});
		case 3:
			return $author$project$SE$UI$Navbar$V2$CustomBrand(
				{bY: '/assets/images/se-easter-onBlack.svg', bZ: '/assets/images/se-easter-onWhite.svg'});
		case 4:
			return $author$project$SE$UI$Navbar$V2$CustomBrand(
				{bY: '/assets/images/se-midsummer-onBlack.svg', bZ: '/assets/images/se-midsummer-onWhite.svg'});
		default:
			return $author$project$SE$UI$Navbar$V2$CustomBrand(
				{bY: '/assets/images/se-halloween-onBlack.svg', bZ: '/assets/images/se-halloween-onWhite.svg'});
	}
};
var $author$project$Navbar$Brand$toMonthDayString = F2(
	function (zone, time) {
		return _Utils_ap(
			A3(
				$elm$core$String$padLeft,
				2,
				'0',
				$elm$core$String$fromInt(
					$author$project$Helpers$monthToNumber(
						A2($elm$time$Time$toMonth, zone, time)))),
			A3(
				$elm$core$String$padLeft,
				2,
				'0',
				$elm$core$String$fromInt(
					A2($elm$time$Time$toDay, zone, time))));
	});
var $author$project$Navbar$Brand$fromPosix = F2(
	function (zone, time) {
		return $author$project$Navbar$Brand$internalToBrand(
			A3(
				$elm$core$List$foldl,
				F2(
					function (a, b) {
						return _Utils_eq(
							a.a,
							A2($author$project$Navbar$Brand$toMonthDayString, zone, time)) ? a.b : b;
					}),
				0,
				$author$project$Navbar$Brand$alternatives));
	});
var $author$project$Navbar$subNavStyles = _List_fromArray(
	[
		A2(
		$rtfeldman$elm_css$Css$pseudoClass,
		'not([hidden])',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
			])),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$typeSelector,
				'li',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$children(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Global$typeSelector,
								'a',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$fontWeight(
										$rtfeldman$elm_css$Css$int(600))
									])),
								A2(
								$rtfeldman$elm_css$Css$Global$typeSelector,
								'ul',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$Global$children(
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Global$typeSelector,
												'li',
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$Global$children(
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Css$Global$typeSelector,
																'a',
																_List_fromArray(
																	[
																		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
																		$rtfeldman$elm_css$Css$hover(
																		_List_fromArray(
																			[
																				$author$project$SE$UI$Colors$color(
																				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$link))
																			]))
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]))
	]);
var $author$project$Navbar$viewStickers = function (tree) {
	var mainSlug = $author$project$Category$slug(
		$zwilias$elm_rosetree$Tree$label(tree));
	var viewButton = function (sticker) {
		return A2(
			$author$project$Sticker$viewButton,
			$author$project$Route$toString(
				A3($author$project$Route$Sticker, $author$project$QueryParams$empty, mainSlug, sticker)),
			sticker);
	};
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$li,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
				A2($rtfeldman$elm_css$Css$property, 'gap', '0.5rem'),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$px(200))
			]),
		_List_Nil,
		_List_fromArray(
			[
				viewButton(0),
				viewButton(2),
				viewButton(1),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$a,
				A2(
					$author$project$SE$UI$Buttons$buttonStyles,
					_List_fromArray(
						[
							$author$project$SE$UI$Buttons$Color(9),
							$author$project$SE$UI$Buttons$Fullwidth
						]),
					false),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Constants$siteUrl + '/kontakta-oss')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Kontakta oss')
					]))
			]));
};
var $author$project$Navbar$viewSubCategoryItem = F3(
	function (mainCategorySlug, displayChildren, tree) {
		var children = displayChildren ? _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_Nil,
				A2(
					$elm$core$List$map,
					A2($author$project$Navbar$viewSubCategoryItem, mainCategorySlug, false),
					$zwilias$elm_rosetree$Tree$children(tree)))
			]) : _List_Nil;
		var cat = $zwilias$elm_rosetree$Tree$label(tree);
		var id = $author$project$Category$id(cat);
		var name = $author$project$Category$name(cat);
		var slug = $author$project$Category$slug(cat);
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$author$project$Route$href(
							A3(
								$author$project$Route$Category,
								$elm$core$Dict$empty,
								mainCategorySlug,
								_Utils_Tuple2(id, slug)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
				children));
	});
var $author$project$Navbar$megaItem = function (tree) {
	var cat = $zwilias$elm_rosetree$Tree$label(tree);
	var name = $author$project$Category$name(cat);
	var slug = $author$project$Category$slug(cat);
	return {
		eN: $elm$core$Maybe$Just(
			A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$ul,
				$author$project$Navbar$subNavStyles,
				_List_Nil,
				_Utils_ap(
					A2(
						$elm$core$List$map,
						A2($author$project$Navbar$viewSubCategoryItem, slug, true),
						$zwilias$elm_rosetree$Tree$children(tree)),
					_List_fromArray(
						[
							$author$project$Navbar$viewStickers(tree)
						])))),
		dF: $author$project$Route$href(
			$author$project$Route$MainCategory(slug)),
		cB: name
	};
};
var $author$project$Navbar$megaNav = function (trees) {
	return _Utils_ap(
		A2($elm$core$List$map, $author$project$Navbar$megaItem, trees),
		_List_fromArray(
			[
				{
				eN: $elm$core$Maybe$Nothing,
				dF: $author$project$Route$href($author$project$Route$Brands),
				cB: 'Varumärken'
			}
			]));
};
var $author$project$Cart$countRows = function (_v0) {
	var rs = _v0;
	return $elm$core$List$length(rs);
};
var $author$project$Navbar$viewCart = function (maybeUser) {
	if (maybeUser.$ === 1) {
		return _List_Nil;
	} else {
		var user = maybeUser.a;
		var countRows = $author$project$Cart$countRows(user.gT);
		var attribs = (countRows > 0) ? _List_fromArray(
			[
				$author$project$Route$href($author$project$Route$Checkout),
				A2(
				$rtfeldman$elm_css$Html$Styled$Attributes$attribute,
				'data-badge',
				$elm$core$String$fromInt(countRows))
			]) : _List_fromArray(
			[
				$author$project$Route$href($author$project$Route$Checkout)
			]);
		return _List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600)),
						$rtfeldman$elm_css$Css$displayFlex,
						$author$project$SE$UI$Font$bodySizeRem(-1),
						A2(
						$rtfeldman$elm_css$Css$padding2,
						$rtfeldman$elm_css$Css$rem(1),
						$rtfeldman$elm_css$Css$zero),
						$author$project$SE$UI$Utils$widescreen(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							])),
						$rtfeldman$elm_css$Css$hover(
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
							])),
						A2(
						$rtfeldman$elm_css$Css$Global$withAttribute,
						'data-badge',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$after(
								_List_fromArray(
									[
										$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$primary),
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
										$rtfeldman$elm_css$Css$borderRadius(
										$rtfeldman$elm_css$Css$pct(50)),
										$rtfeldman$elm_css$Css$minWidth(
										$rtfeldman$elm_css$Css$em(2)),
										$rtfeldman$elm_css$Css$height(
										$rtfeldman$elm_css$Css$em(2)),
										$rtfeldman$elm_css$Css$lineHeight(
										$rtfeldman$elm_css$Css$num(1)),
										$rtfeldman$elm_css$Css$padding(
										$rtfeldman$elm_css$Css$em(0.5)),
										$author$project$SE$UI$Font$bodySizeEm(-4),
										A2($rtfeldman$elm_css$Css$property, 'content', 'attr(data-badge)'),
										A5(
										$rtfeldman$elm_css$Css$boxShadow5,
										$rtfeldman$elm_css$Css$zero,
										$rtfeldman$elm_css$Css$zero,
										$rtfeldman$elm_css$Css$zero,
										$rtfeldman$elm_css$Css$px(2),
										$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$white)),
										$rtfeldman$elm_css$Css$transform(
										A2(
											$rtfeldman$elm_css$Css$translate2,
											$rtfeldman$elm_css$Css$pct(-25),
											$rtfeldman$elm_css$Css$pct(25))),
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$px(10)),
										$author$project$SE$UI$Utils$widescreen(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
												$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
												$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
												$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$auto),
												$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$auto),
												$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$unset)
											]))
									]))
							]))
					]),
				attribs,
				_List_fromArray(
					[
						$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$cart),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$author$project$SE$UI$Utils$widescreen(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
										$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$rem(0.6667)),
										$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
									]))
							]),
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Varukorg')
							]))
					]))
			]);
	}
};
var $author$project$SE$UI$Navbar$V2$LinkItem = function (a) {
	return {$: 0, a: a};
};
var $author$project$Navbar$viewLoginLinkItem = $author$project$SE$UI$Navbar$V2$LinkItem(
	{
		dF: $author$project$Route$href($author$project$Route$Login),
		bR: $elm$core$Maybe$Just($author$project$SE$UI$Icon$V2$user),
		cB: 'Logga in'
	});
var $author$project$Navbar$viewOpeningHours = function (openingHours) {
	var hours = function () {
		if (!openingHours.$) {
			var hours_ = openingHours.a;
			return hours_;
		} else {
			return 'Nej';
		}
	}();
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$displayFlex,
				$author$project$SE$UI$Font$bodySizeRem(-3),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				A2(
				$rtfeldman$elm_css$Css$padding2,
				$rtfeldman$elm_css$Css$rem(1),
				$rtfeldman$elm_css$Css$zero),
				$author$project$SE$UI$Utils$widescreen(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero)
					]))
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml($author$project$SE$UI$Icon$V2$phone),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$paddingLeft(
						$rtfeldman$elm_css$Css$em(0.75))
					]),
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$strong,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Prata med säljare?')
							])),
						A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href('tel:+46544442030')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('054 - 444 2030')
							]))
					])),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$paddingLeft(
						$rtfeldman$elm_css$Css$em(0.75)),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$em(0.75)),
						$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
						$author$project$SE$UI$Colors$borderColor($author$project$SE$UI$Colors$border),
						$rtfeldman$elm_css$Css$borderWidth($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$borderLeftWidth(
						$rtfeldman$elm_css$Css$px(1))
					]),
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$strong,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Öppet idag:')
							])),
						A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
						A4(
						$rtfeldman$elm_css$Html$Styled$styled,
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$base)
							]),
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(hours)
							]))
					]))
			]));
};
var $author$project$SE$UI$Navbar$V2$DropdownItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$SE$UI$Dropdown$Content = function (a) {
	return {$: 2, a: a};
};
var $author$project$SE$UI$Dropdown$content = $author$project$SE$UI$Dropdown$Content;
var $author$project$SE$UI$Dropdown$Link = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SE$UI$Dropdown$link = $author$project$SE$UI$Dropdown$Link;
var $author$project$Navbar$viewUser = F2(
	function (csrfToken, user) {
		var logout = $author$project$SE$UI$Dropdown$content(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$form,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$method('POST'),
							$rtfeldman$elm_css$Html$Styled$Attributes$action('/logout')
						]),
					_List_fromArray(
						[
							$author$project$CsrfToken$input(csrfToken),
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
											$rtfeldman$elm_css$Css$width(
											A3(
												$rtfeldman$elm_css$Css$calc,
												$rtfeldman$elm_css$Css$pct(100),
												$rtfeldman$elm_css$Css$plus,
												$rtfeldman$elm_css$Css$rem(2))),
											A2(
											$rtfeldman$elm_css$Css$margin2,
											$rtfeldman$elm_css$Css$rem(-0.375),
											$rtfeldman$elm_css$Css$rem(-1)),
											$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
											$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(0.875)),
											$rtfeldman$elm_css$Css$lineHeight(
											$rtfeldman$elm_css$Css$num(1.5)),
											A2(
											$rtfeldman$elm_css$Css$padding2,
											$rtfeldman$elm_css$Css$rem(0.375),
											$rtfeldman$elm_css$Css$rem(1)),
											$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
											$rtfeldman$elm_css$Css$paddingRight(
											$rtfeldman$elm_css$Css$rem(3)),
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left),
											$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
											$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
											$rtfeldman$elm_css$Css$hover(
											_List_fromArray(
												[
													$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background)
												]))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
									$rtfeldman$elm_css$Html$Styled$Attributes$value('Logga ut')
								]),
							_List_Nil)
						]))
				]));
		var adminLinks = $author$project$Role$hasAdminRights(user.it) ? _List_fromArray(
			[
				A2(
				$author$project$SE$UI$Dropdown$link,
				$author$project$Route$toString($author$project$Route$AccountAdministration),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Användare')
					]))
			]) : _List_Nil;
		return $author$project$SE$UI$Navbar$V2$DropdownItem(
			{
				hF: _Utils_ap(
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Dropdown$link,
							$author$project$Route$toString($author$project$Route$Account),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Mitt konto')
								])),
							A2(
							$author$project$SE$UI$Dropdown$link,
							$author$project$Route$toString(
								$author$project$Route$Orders($author$project$QueryParams$empty)),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Ordrar')
								])),
							A2(
							$author$project$SE$UI$Dropdown$link,
							$author$project$Route$toString(
								$author$project$Route$Invoices($author$project$QueryParams$empty)),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Fakturor')
								])),
							A2(
							$author$project$SE$UI$Dropdown$link,
							$author$project$Route$toString($author$project$Route$Bids),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('BID')
								]))
						]),
					_Utils_ap(
						adminLinks,
						_List_fromArray(
							[logout]))),
				cB: user.ho + (' ' + user.hI)
			});
	});
var $author$project$Navbar$navbarConfig = F2(
	function (sessionMsg, session) {
		var ui = $author$project$Session$userInterface(session);
		var maybeUser = $author$project$Session$user(session);
		return {
			dp: A3(
				$author$project$Navbar$Brand$fromPosix,
				$author$project$Session$zone(session),
				$author$project$Session$time(session),
				$author$project$Constants$siteUrl),
			hN: _List_fromArray(
				[
					$author$project$SE$UI$Navbar$V2$CustomItem(
					_List_fromArray(
						[
							$author$project$Navbar$viewOpeningHours(ui.h8)
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$author$project$Navbar$viewLoginLinkItem,
					A2(
						$elm$core$Maybe$map,
						$author$project$Navbar$viewUser(ui.g0),
						maybeUser)),
					$author$project$SE$UI$Navbar$V2$CustomItem(
					$author$project$Navbar$viewCart(maybeUser))
				]),
			hO: $author$project$Navbar$megaNav(
				$author$project$Session$categories(session)),
			is: _List_fromArray(
				[
					A3(
					$author$project$SE$UI$Navbar$V2$Link,
					$author$project$Route$href($author$project$Route$RMA),
					'RMA',
					$elm$core$Maybe$Nothing),
					A3(
					$author$project$SE$UI$Navbar$V2$Link,
					$author$project$Route$href($author$project$Route$Service),
					'Service och garanti',
					$elm$core$Maybe$Nothing)
				]),
			iH: _List_fromArray(
				[
					{bR: $author$project$SE$UI$Icon$V2$facebook, cg: 'https://facebook.com/specialelektronik'},
					{bR: $author$project$SE$UI$Icon$V2$instagram, cg: 'https://instagram.com/specialelektronik'},
					{bR: $author$project$SE$UI$Icon$V2$linkedin, cg: 'https://www.linkedin.com/company/specialelektronik-ab'},
					{bR: $author$project$SE$UI$Icon$V2$youtube, cg: 'https://www.youtube.com/channel/UC-dTyW9181xms8TpxXHF7oQ'}
				]),
			gu: A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$NavbarMsg)
		};
	});
var $author$project$Session$BlurredSearchbar = {$: 4};
var $author$project$Navbar$autocompleteConfig = function (sessionMsg) {
	return {
		gX: A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$ChoseSuggestion),
		hl: $author$project$SearchBar$list(
			A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$CompletedLoadSuggestions)),
		hp: sessionMsg($author$project$Session$FocusedSearchbar),
		iK: A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$SubmittedQuery),
		gu: A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$AutocompleteMsg)
	};
};
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$SE$UI$Icon$V2$search = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'])));
var $author$project$SearchBar$suggestionsStyles = function (isEmpty) {
	return _List_fromArray(
		[
			isEmpty ? $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none) : $rtfeldman$elm_css$Css$batch(_List_Nil),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(99999)),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'li:first-child > a',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$paddingTop(
							$rtfeldman$elm_css$Css$rem(0.375)),
							$author$project$SE$UI$Utils$widescreen(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$paddingTop(
									$rtfeldman$elm_css$Css$rem(0.25))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'li:last-child > a',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$borderBottomLeftRadius(
							$rtfeldman$elm_css$Css$px(5)),
							$rtfeldman$elm_css$Css$borderBottomRightRadius(
							$rtfeldman$elm_css$Css$px(5)),
							A4(
							$rtfeldman$elm_css$Css$boxShadow4,
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$px(2),
							$rtfeldman$elm_css$Css$px(3),
							A4($rtfeldman$elm_css$Css$rgba, 10, 10, 10, 0.1)),
							$rtfeldman$elm_css$Css$paddingBottom(
							$rtfeldman$elm_css$Css$rem(0.375)),
							$author$project$SE$UI$Utils$widescreen(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$paddingBottom(
									$rtfeldman$elm_css$Css$rem(0.25))
								]))
						]))
				]))
		]);
};
var $author$project$SearchBar$suggestionStyles = function (isSelected) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$rem(0.375),
			$rtfeldman$elm_css$Css$rem(0.75)),
			$author$project$SE$UI$Utils$widescreen(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(0.25),
					$rtfeldman$elm_css$Css$rem(0.5))
				])),
			$author$project$SE$UI$Colors$backgroundColor(
			isSelected ? $author$project$SE$UI$Colors$lightest : $author$project$SE$UI$Colors$white),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$px(16)),
			A3(
			$rtfeldman$elm_css$Css$borderLeft3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
			A3(
			$rtfeldman$elm_css$Css$borderRight3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightest)
				]))
		]);
};
var $author$project$SearchBar$suggestionImage = function (kids) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$px(48)),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$px(48)),
				$rtfeldman$elm_css$Css$marginRight(
				$rtfeldman$elm_css$Css$rem(0.5)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$light),
				$rtfeldman$elm_css$Css$Global$descendants(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$typeSelector,
						'figure',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(48))),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$px(48)))
							])),
						$rtfeldman$elm_css$Css$Global$img(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(48))),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$px(48))),
								A2($rtfeldman$elm_css$Css$property, 'object-fit', 'contain')
							]))
					]))
			]),
		_List_Nil,
		kids);
};
var $author$project$SearchBar$viewSuggestionHelper = F2(
	function (image, content) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[$rtfeldman$elm_css$Css$displayFlex]),
			_List_Nil,
			_List_fromArray(
				[
					$author$project$SearchBar$suggestionImage(image),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
						]),
					_List_Nil,
					content)
				]));
	});
var $author$project$SearchBar$viewBrandSuggestion = function (brand) {
	return A2(
		$author$project$SearchBar$viewSuggestionHelper,
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				$rtfeldman$elm_css$Html$Styled$text(''),
				A2(
					$elm$core$Maybe$map,
					A2($author$project$Image$viewThumbnail, 48, brand.hT),
					brand.dG))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(brand.hT)
			]));
};
var $author$project$SE$UI$Icon$V2$category = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		640,
		512,
		_List_fromArray(
			['M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z'])));
var $author$project$SearchBar$viewCategorySuggestion = function (category) {
	return A2(
		$author$project$SearchBar$viewSuggestionHelper,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(
				A2($author$project$SE$UI$Icon$V2$withSize, 2, $author$project$SE$UI$Icon$V2$category))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(category.hT),
				A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$px(13)),
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text)
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2(
							$elm$core$String$join,
							' / ',
							A3(
								$elm$core$List$foldr,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.hT;
									},
									$elm$core$List$cons),
								_List_Nil,
								category.di)))
					]))
			]));
};
var $author$project$SE$UI$Icon$V2$history = $author$project$SE$UI$Icon$V2$initInternals(
	_Utils_Tuple3(
		512,
		512,
		_List_fromArray(
			['M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z'])));
var $author$project$SearchBar$viewHistorySuggestion = function (q) {
	return A2(
		$author$project$SearchBar$viewSuggestionHelper,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(
				A2($author$project$SE$UI$Icon$V2$withSize, 2, $author$project$SE$UI$Icon$V2$history))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(q)
			]));
};
var $author$project$SearchBar$viewThumbnail = $author$project$Image$viewMaybeThumbnail(48);
var $author$project$SearchBar$viewProductSuggestion = function (product) {
	var code = $author$project$ExternalId$toString(product.fU);
	return A2(
		$author$project$SearchBar$viewSuggestionHelper,
		_List_fromArray(
			[
				A2($author$project$SearchBar$viewThumbnail, code, product.dG)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(product.hT),
				A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$px(13)),
						$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
					]),
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(code)
					]))
			]));
};
var $author$project$SearchBar$viewSuggestion = F3(
	function (maybeSelected, index, suggestion) {
		var content = function () {
			switch (suggestion.$) {
				case 0:
					var str = suggestion.a;
					return $author$project$SearchBar$viewHistorySuggestion(str);
				case 1:
					var p = suggestion.a;
					return $author$project$SearchBar$viewProductSuggestion(p);
				case 2:
					var c = suggestion.a;
					return $author$project$SearchBar$viewCategorySuggestion(c);
				default:
					var m = suggestion.a;
					return $author$project$SearchBar$viewBrandSuggestion(m);
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$a,
					$author$project$SearchBar$suggestionStyles(
						_Utils_eq(
							A2($elm$core$Maybe$withDefault, -1, maybeSelected),
							index)),
					_List_fromArray(
						[
							$author$project$Route$href(
							$author$project$SearchBar$toRoute(suggestion))
						]),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$SearchBar$view = F2(
	function (maybeSelected, suggestions) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$ul,
			$author$project$SearchBar$suggestionsStyles(
				$elm$core$List$isEmpty(suggestions)),
			_List_Nil,
			A2(
				$elm$core$List$indexedMap,
				$author$project$SearchBar$viewSuggestion(maybeSelected),
				suggestions));
	});
var $author$project$Navbar$search = F2(
	function (sessionMsg, ac) {
		var onClickOutsideAttributes = A2(
			$author$project$SE$UI$OuterClick$withId,
			'search-dropdown',
			sessionMsg($author$project$Session$BlurredSearchbar));
		var _v0 = $specialelektronik$elm_autocomplete$Autocomplete$toView(ac);
		var suggestions = _v0.iM;
		var index = _v0.hA;
		var query = _v0.im;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
					$rtfeldman$elm_css$Css$flexGrow(
					$rtfeldman$elm_css$Css$num(1)),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$class,
							'field',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero))
								])),
							A2(
							$rtfeldman$elm_css$Css$Global$id,
							'search-box',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$batch(
									$author$project$SE$UI$Form$Input$inputStyle(_List_Nil)),
									A2($rtfeldman$elm_css$Css$property, 'padding', 'calc(0.75em - 1px) calc(1em - 1px)')
								]))
						]))
				]),
			onClickOutsideAttributes,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Form$field,
					_List_fromArray(
						[0]),
					_List_fromArray(
						[
							A2(
							$author$project$SE$UI$Form$expandedControl,
							false,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									A3(
										$specialelektronik$elm_autocomplete$Autocomplete$input,
										$author$project$Navbar$autocompleteConfig(sessionMsg),
										_List_fromArray(
											[
												$elm$html$Html$Attributes$placeholder('Sök efter produkter, kategorier och fabrikat'),
												$elm$html$Html$Attributes$id('search-box'),
												A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
												$elm$html$Html$Attributes$name('q')
											]),
										ac)),
									A4(
									$rtfeldman$elm_css$Html$Styled$styled,
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
											$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
											$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
											$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$dark),
											$rtfeldman$elm_css$Css$hover(
											_List_fromArray(
												[
													$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$link)
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											sessionMsg(
												$author$project$Session$SubmittedQuery(query)))
										]),
									_List_fromArray(
										[
											A3(
											$author$project$SE$UI$Utils$visuallyHidden,
											$rtfeldman$elm_css$Html$Styled$span,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Sök')
												])),
											$author$project$SE$UI$Icon$V2$toHtml(
											A2($author$project$SE$UI$Icon$V2$withSize, 2, $author$project$SE$UI$Icon$V2$search))
										]))
								]))
						])),
					A2($author$project$SearchBar$view, index, suggestions)
				]));
	});
var $author$project$SE$UI$Navbar$V2$ClosedDropdown = {$: 1};
var $author$project$SE$UI$Navbar$V2$isMegaOpen = function (activeDropdownId) {
	return A2($elm$core$String$left, 4, activeDropdownId) === 'mega';
};
var $author$project$SE$UI$Navbar$V2$backdrop = F2(
	function (config, _v0) {
		var activeDropdownId = _v0.ai;
		return $author$project$SE$UI$Navbar$V2$isMegaOpen(activeDropdownId) ? A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor(
					A2(
						$author$project$SE$UI$Colors$mapAlpha,
						$elm$core$Basics$always(0.7),
						$author$project$SE$UI$Colors$black)),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
					$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
					$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					config.gu($author$project$SE$UI$Navbar$V2$ClosedDropdown))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$global(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$html(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden))
								]))
						]))
				])) : $rtfeldman$elm_css$Html$Styled$text('');
	});
var $author$project$SE$UI$Navbar$V2$brandHeight = 75;
var $author$project$SE$UI$Navbar$V2$ledHeight = 3;
var $author$project$SE$UI$Navbar$V2$megaHeight = function (config) {
	var _v0 = config.hO;
	if (!_v0.b) {
		return 0;
	} else {
		return 50;
	}
};
var $author$project$SE$UI$Navbar$V2$mobileSearchHeight = 48;
var $author$project$SE$UI$Navbar$V2$ribbonHeight = function (config) {
	var _v0 = config.is;
	if (!_v0.b) {
		return 0;
	} else {
		return 32;
	}
};
var $author$project$SE$UI$Navbar$V2$global = F2(
	function (config, isOpen) {
		return $rtfeldman$elm_css$Css$Global$global(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Global$body(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$paddingTop(
							$rtfeldman$elm_css$Css$px(($author$project$SE$UI$Navbar$V2$brandHeight + $author$project$SE$UI$Navbar$V2$ledHeight) + $author$project$SE$UI$Navbar$V2$mobileSearchHeight)),
							$author$project$SE$UI$Utils$widescreen(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$paddingTop(
									$rtfeldman$elm_css$Css$px(
										(($author$project$SE$UI$Navbar$V2$ribbonHeight(config) + $author$project$SE$UI$Navbar$V2$brandHeight) + $author$project$SE$UI$Navbar$V2$ledHeight) + $author$project$SE$UI$Navbar$V2$megaHeight(config)))
								]))
						])),
					$rtfeldman$elm_css$Css$Global$html(
					_List_fromArray(
						[
							isOpen ? $rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed)) : $rtfeldman$elm_css$Css$batch(_List_Nil)
						]))
				]));
	});
var $rtfeldman$elm_css$Css$Transitions$EaseInOut = {$: 4};
var $rtfeldman$elm_css$Css$Transitions$easeInOut = $rtfeldman$elm_css$Css$Transitions$EaseInOut;
var $author$project$SE$UI$Navbar$V2$navContainerStyles = function (activeDropdownId) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
			$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(40)),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$px(10),
			$author$project$SE$UI$Colors$toCss(
				A2(
					$author$project$SE$UI$Colors$mapAlpha,
					$elm$core$Basics$always(0.08),
					$author$project$SE$UI$Colors$black))),
			$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
			$author$project$SE$UI$Navbar$V2$isMegaOpen(activeDropdownId) ? $rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero) : $rtfeldman$elm_css$Css$batch(_List_Nil),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'headroom',
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$property, 'will-change', 'transform'),
					$rtfeldman$elm_css$Css$Transitions$transition(
					_List_fromArray(
						[
							A3($rtfeldman$elm_css$Css$Transitions$transform3, 200, 0, $rtfeldman$elm_css$Css$Transitions$easeInOut)
						]))
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'headroom--pinned',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$transform(
					$rtfeldman$elm_css$Css$translateY(
						$rtfeldman$elm_css$Css$pct(0)))
				])),
			A2(
			$rtfeldman$elm_css$Css$Global$withClass,
			'headroom--unpinned',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$transform(
					$rtfeldman$elm_css$Css$translateY(
						$rtfeldman$elm_css$Css$pct(-100)))
				]))
		]);
};
var $author$project$SE$UI$Navbar$V2$ToggledMenu = {$: 0};
var $rtfeldman$elm_css$Css$Transitions$backgroundColor3 = $rtfeldman$elm_css$Css$Transitions$fullTransition(1);
var $author$project$SE$UI$Navbar$V2$brandImageHeight = 48;
var $author$project$SE$UI$Navbar$V2$brandStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$stretch),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
		$rtfeldman$elm_css$Css$minHeight(
		$rtfeldman$elm_css$Css$px($author$project$SE$UI$Navbar$V2$brandHeight)),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$Transitions$transition(
		_List_fromArray(
			[
				A3($rtfeldman$elm_css$Css$Transitions$backgroundColor3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut)
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-open',
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darker)
			])),
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$each,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$typeSelector('svg'),
						$rtfeldman$elm_css$Css$Global$typeSelector('img')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px($author$project$SE$UI$Navbar$V2$brandImageHeight)),
						A2(
						$rtfeldman$elm_css$Css$margin2,
						$rtfeldman$elm_css$Css$zero,
						$rtfeldman$elm_css$Css$px(12))
					]))
			]))
	]);
var $rtfeldman$elm_css$Html$Styled$Attributes$for = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('htmlFor');
var $rtfeldman$elm_css$Css$nthChild = function (str) {
	return $rtfeldman$elm_css$Css$pseudoClass('nth-child(' + (str + ')'));
};
var $author$project$SE$UI$Navbar$V2$navbarBurger = F2(
	function (toggledMenuMsg, isOpen) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$label,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darker),
					$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$px(75)),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$px(75)),
					$rtfeldman$elm_css$Css$hover(
					_List_fromArray(
						[
							$author$project$SE$UI$Colors$backgroundColor(
							$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$darker))
						])),
					$rtfeldman$elm_css$Css$Global$descendants(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$Global$typeSelector,
							'span',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$currentColor),
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$px(2)),
									$rtfeldman$elm_css$Css$left(
									A3(
										$rtfeldman$elm_css$Css$calc,
										$rtfeldman$elm_css$Css$pct(50),
										$rtfeldman$elm_css$Css$minus,
										$rtfeldman$elm_css$Css$px(12))),
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
									$rtfeldman$elm_css$Css$Transitions$transition(
									_List_fromArray(
										[
											A3($rtfeldman$elm_css$Css$Transitions$backgroundColor3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut),
											A3($rtfeldman$elm_css$Css$Transitions$opacity3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut),
											A3($rtfeldman$elm_css$Css$Transitions$transform3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut)
										])),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(24)),
									A2(
									$rtfeldman$elm_css$Css$nthChild,
									'1',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$top(
											A3(
												$rtfeldman$elm_css$Css$calc,
												$rtfeldman$elm_css$Css$pct(50),
												$rtfeldman$elm_css$Css$minus,
												$rtfeldman$elm_css$Css$px(8)))
										])),
									A2(
									$rtfeldman$elm_css$Css$nthChild,
									'2',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$top(
											A3(
												$rtfeldman$elm_css$Css$calc,
												$rtfeldman$elm_css$Css$pct(50),
												$rtfeldman$elm_css$Css$minus,
												$rtfeldman$elm_css$Css$px(1)))
										])),
									A2(
									$rtfeldman$elm_css$Css$nthChild,
									'3',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$top(
											A3(
												$rtfeldman$elm_css$Css$calc,
												$rtfeldman$elm_css$Css$pct(50),
												$rtfeldman$elm_css$Css$plus,
												$rtfeldman$elm_css$Css$px(6)))
										])),
									$rtfeldman$elm_css$Css$batch(
									isOpen ? _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$nthChild,
											'1',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$transforms(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$translateY(
															$rtfeldman$elm_css$Css$px(7)),
															$rtfeldman$elm_css$Css$rotate(
															$rtfeldman$elm_css$Css$deg(45))
														]))
												])),
											A2(
											$rtfeldman$elm_css$Css$nthChild,
											'2',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$opacity($rtfeldman$elm_css$Css$zero)
												])),
											A2(
											$rtfeldman$elm_css$Css$nthChild,
											'3',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$transforms(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$translateY(
															$rtfeldman$elm_css$Css$px(-7)),
															$rtfeldman$elm_css$Css$rotate(
															$rtfeldman$elm_css$Css$deg(-45))
														]))
												]))
										]) : _List_Nil)
								]))
						])),
					$author$project$SE$UI$Utils$widescreen(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$for('menu'),
					A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'role', 'button'),
					A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-label', 'menu'),
					$rtfeldman$elm_css$Html$Styled$Events$onClick(toggledMenuMsg)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-hidden', 'true')
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-hidden', 'true')
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-hidden', 'true')
						]),
					_List_Nil)
				]));
	});
var $elm$core$Basics$round = _Basics_round;
var $author$project$SE$UI$Navbar$V2$viewCustomLogo = function (url) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$img,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$src(url),
				$rtfeldman$elm_css$Html$Styled$Attributes$height(
				$elm$core$Basics$round($author$project$SE$UI$Navbar$V2$brandImageHeight))
			]),
		_List_Nil);
};
var $author$project$SE$UI$Navbar$V2$viewBrand = F2(
	function (config, isOpen) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(config.dp, isOpen);
			if (!_v1.a.$) {
				if (_v1.b) {
					var url_ = _v1.a.a;
					return _Utils_Tuple2($author$project$SE$UI$Logo$onBlack, url_);
				} else {
					var url_ = _v1.a.a;
					return _Utils_Tuple2($author$project$SE$UI$Logo$onWhite, url_);
				}
			} else {
				if (_v1.b) {
					var _v2 = _v1.a;
					var onBlack = _v2.a.bY;
					var url_ = _v2.b;
					return _Utils_Tuple2(
						$author$project$SE$UI$Navbar$V2$viewCustomLogo(onBlack),
						url_);
				} else {
					var _v3 = _v1.a;
					var onWhite = _v3.a.bZ;
					var url_ = _v3.b;
					return _Utils_Tuple2(
						$author$project$SE$UI$Navbar$V2$viewCustomLogo(onWhite),
						url_);
				}
			}
		}();
		var logo = _v0.a;
		var url = _v0.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Navbar$V2$brandStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('navbar-brand', true),
							_Utils_Tuple2('is-open', isOpen)
						]))
				]),
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$lineHeight($rtfeldman$elm_css$Css$zero)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href(url)
						]),
					_List_fromArray(
						[logo])),
					A2(
					$author$project$SE$UI$Navbar$V2$navbarBurger,
					config.gu($author$project$SE$UI$Navbar$V2$ToggledMenu),
					isOpen)
				]));
	});
var $author$project$SE$UI$Navbar$V2$viewDesktopSearch = function (searchFn) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexGrow(
				$rtfeldman$elm_css$Css$int(1)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$marginLeft(
				$rtfeldman$elm_css$Css$px(12))
			]),
		_List_Nil,
		_List_fromArray(
			[searchFn]));
};
var $author$project$SE$UI$Navbar$V2$viewLED = A4(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$primary),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$px($author$project$SE$UI$Navbar$V2$ledHeight)),
			$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero)
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('navbar-led', true)
				]))
		]),
	_List_Nil);
var $author$project$SE$UI$Navbar$V2$mainNavStyles = function () {
	var linkStyles = _List_fromArray(
		[
			$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
			$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
			$rtfeldman$elm_css$Css$letterSpacing(
			$rtfeldman$elm_css$Css$px(1)),
			$rtfeldman$elm_css$Css$fontWeight(
			$rtfeldman$elm_css$Css$int(600)),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
					$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
				]))
		]);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(12)),
			$rtfeldman$elm_css$Css$Global$children(
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Global$typeSelector, 'a', linkStyles),
					A2(
					$rtfeldman$elm_css$Css$Global$selector,
					'.dropdown',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Global$each,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$Global$a,
											$rtfeldman$elm_css$Css$Global$selector('div[role=\'button\']')
										]),
									linkStyles)
								]))
						]))
				]))
		]);
}();
var $author$project$SE$UI$Navbar$V2$desktopBasicItemStyles = function (isActive) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$paddingLeft(
			$rtfeldman$elm_css$Css$px(12)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$px(12)),
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
		]);
};
var $author$project$SE$UI$Dropdown$CustomButton = function (a) {
	return {$: 1, a: a};
};
var $author$project$SE$UI$Dropdown$customButton = $author$project$SE$UI$Dropdown$CustomButton;
var $author$project$SE$UI$Navbar$V2$ToggledDropdown = function (a) {
	return {$: 2, a: a};
};
var $author$project$SE$UI$Navbar$V2$viewDropdownButton = F5(
	function (styles, config, isActive, id, label) {
		var icon = (isActive ? $author$project$SE$UI$Icon$V2$withTransform($author$project$SE$UI$Icon$V2$FlipY) : $elm$core$Basics$identity)($author$project$SE$UI$Icon$V2$angleDown);
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			styles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					config.gu(
						$author$project$SE$UI$Navbar$V2$ToggledDropdown(id))),
					A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'role', 'button')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label)
						])),
					$author$project$SE$UI$Icon$V2$toHtml(
					A2($author$project$SE$UI$Icon$V2$withSize, 1, icon))
				]));
	});
var $author$project$SE$UI$Navbar$V2$viewLinkHelper = F2(
	function (styles, link) {
		var icon = function () {
			var _v0 = link.bR;
			if (_v0.$ === 1) {
				return $rtfeldman$elm_css$Html$Styled$text('');
			} else {
				var i = _v0.a;
				return $author$project$SE$UI$Icon$V2$toHtml(i);
			}
		}();
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$a,
			styles,
			_List_fromArray(
				[link.dF]),
			_List_fromArray(
				[
					icon,
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(link.cB)
						]))
				]));
	});
var $author$project$SE$UI$Navbar$V2$viewItem = F5(
	function (itemStyles, config, activeDropdownId, index, item) {
		switch (item.$) {
			case 0:
				var link = item.a;
				return A2(
					$author$project$SE$UI$Navbar$V2$viewLinkHelper,
					itemStyles(false),
					link);
			case 1:
				var rec = item.a;
				var id = 'mobileNav-' + $elm$core$String$fromInt(index);
				var isActive = _Utils_eq(id, activeDropdownId);
				return A5(
					$author$project$SE$UI$Dropdown$dropdown,
					id,
					config.gu($author$project$SE$UI$Navbar$V2$ClosedDropdown),
					isActive,
					$author$project$SE$UI$Dropdown$customButton(
						A5(
							$author$project$SE$UI$Navbar$V2$viewDropdownButton,
							itemStyles(isActive),
							config,
							isActive,
							id,
							rec.cB)),
					rec.hF);
			default:
				var kids = item.a;
				return A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$px(12)),
							$rtfeldman$elm_css$Css$marginRight(
							$rtfeldman$elm_css$Css$px(12))
						]),
					_List_Nil,
					kids);
		}
	});
var $author$project$SE$UI$Navbar$V2$viewDesktopItem = $author$project$SE$UI$Navbar$V2$viewItem($author$project$SE$UI$Navbar$V2$desktopBasicItemStyles);
var $author$project$SE$UI$Navbar$V2$viewMainNav = F2(
	function (config, model) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$nav,
			$author$project$SE$UI$Navbar$V2$mainNavStyles,
			_List_Nil,
			A2(
				$elm$core$List$indexedMap,
				A2($author$project$SE$UI$Navbar$V2$viewDesktopItem, config, model.ai),
				config.hN));
	});
var $author$project$SE$UI$Navbar$V2$megaItemStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$displayFlex,
		$author$project$SE$UI$Font$bodySizeRem(-2),
		$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
		A2(
		$rtfeldman$elm_css$Css$padding2,
		$rtfeldman$elm_css$Css$px(14),
		$rtfeldman$elm_css$Css$px(14)),
		$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
		$rtfeldman$elm_css$Css$letterSpacing(
		$rtfeldman$elm_css$Css$px(1)),
		$rtfeldman$elm_css$Css$fontWeight(
		$rtfeldman$elm_css$Css$int(600)),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$primary)
			]))
	]);
var $author$project$SE$UI$Navbar$V2$viewMegaItemLink = F4(
	function (config, model, index, item) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$a,
					$author$project$SE$UI$Navbar$V2$megaItemStyles,
					_List_fromArray(
						[item.dF]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(item.cB)
								]))
						]))
				]));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$hidden = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('hidden');
var $author$project$SE$UI$Navbar$V2$viewMegaItemWithContent = F4(
	function (config, model, index, item) {
		var id = 'megaNav-' + $elm$core$String$fromInt(index);
		var isActive = _Utils_eq(id, model.ai);
		var icon = (isActive ? $author$project$SE$UI$Icon$V2$withTransform($author$project$SE$UI$Icon$V2$FlipY) : $elm$core$Basics$identity)($author$project$SE$UI$Icon$V2$angleDown);
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Navbar$V2$megaItemStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							config.gu(
								$author$project$SE$UI$Navbar$V2$ToggledDropdown(id)))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(item.cB)
								])),
							$author$project$SE$UI$Icon$V2$toHtml(
							A2($author$project$SE$UI$Icon$V2$withSize, 1, icon))
						])),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Css$pseudoClass,
							'not([hidden])',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
									$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
									$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
									$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$rem(1.33333333)),
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
									$rtfeldman$elm_css$Css$borderBottomLeftRadius($author$project$SE$UI$Utils$radius),
									$rtfeldman$elm_css$Css$borderBottomRightRadius($author$project$SE$UI$Utils$radius),
									$rtfeldman$elm_css$Css$maxHeight(
									A3(
										$rtfeldman$elm_css$Css$calc,
										$rtfeldman$elm_css$Css$vh(100),
										$rtfeldman$elm_css$Css$minus,
										$rtfeldman$elm_css$Css$px(
											(($author$project$SE$UI$Navbar$V2$ribbonHeight(config) + $author$project$SE$UI$Navbar$V2$brandHeight) + $author$project$SE$UI$Navbar$V2$ledHeight) + $author$project$SE$UI$Navbar$V2$megaHeight(config)))),
									$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$auto)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$hidden(!isActive)
						]),
					_List_fromArray(
						[
							item.eN,
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[item.dF]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Visa allt i ' + item.cB)
										]))
								]))
						]))
				]));
	});
var $author$project$SE$UI$Navbar$V2$viewMegaItem = F4(
	function (config, model, index, item) {
		var _v0 = item.eN;
		if (!_v0.$) {
			var content = _v0.a;
			return A4(
				$author$project$SE$UI$Navbar$V2$viewMegaItemWithContent,
				config,
				model,
				index,
				{eN: content, dF: item.dF, cB: item.cB});
		} else {
			return A4(
				$author$project$SE$UI$Navbar$V2$viewMegaItemLink,
				config,
				model,
				index,
				{dF: item.dF, cB: item.cB});
		}
	});
var $author$project$SE$UI$Navbar$V2$viewMegaNav = F2(
	function (config, model) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$nav,
			_List_fromArray(
				[
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
					A3(
					$rtfeldman$elm_css$Css$borderBottom3,
					$rtfeldman$elm_css$Css$px(1),
					$rtfeldman$elm_css$Css$solid,
					$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border)),
					$rtfeldman$elm_css$Css$flexGrow(
					$rtfeldman$elm_css$Css$int(1))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$SE$UI$Container$container,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$rtfeldman$elm_css$Html$Styled$styled,
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_fromArray(
								[$rtfeldman$elm_css$Css$displayFlex]),
							_List_Nil,
							A2(
								$elm$core$List$indexedMap,
								A2($author$project$SE$UI$Navbar$V2$viewMegaItem, config, model),
								config.hO))
						]))
				]));
	});
var $author$project$SE$UI$Navbar$V2$ribbonItemStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$batch(
		$author$project$SE$UI$Navbar$V2$desktopBasicItemStyles(false)),
		$rtfeldman$elm_css$Css$lineHeight(
		$rtfeldman$elm_css$Css$num(2.66666667)),
		$author$project$SE$UI$Font$bodySizeRem(-3),
		$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$hover($author$project$SE$UI$Colors$white))
			]))
	]);
var $author$project$SE$UI$Navbar$V2$ribbonStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
		$rtfeldman$elm_css$Css$maxWidth(
		$rtfeldman$elm_css$Css$px(1920)),
		A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto),
		A2(
		$rtfeldman$elm_css$Css$padding2,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$rem(2.66666667))
	]);
var $author$project$SE$UI$Navbar$V2$viewRibbon = function (links) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$darkest)
			]),
		_List_Nil,
		_List_fromArray(
			[
				A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$nav,
				$author$project$SE$UI$Navbar$V2$ribbonStyles,
				_List_Nil,
				A2(
					$elm$core$List$map,
					$author$project$SE$UI$Navbar$V2$viewLinkHelper($author$project$SE$UI$Navbar$V2$ribbonItemStyles),
					links))
			]));
};
var $author$project$SE$UI$Navbar$V2$viewDesktop = F3(
	function (config, searchFn, model) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
					$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
					$author$project$SE$UI$Utils$widescreen(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block)
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('navbar-desktop')
				]),
			_List_fromArray(
				[
					$author$project$SE$UI$Navbar$V2$viewRibbon(config.is),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$maxWidth(
							$rtfeldman$elm_css$Css$px(1920)),
							A2($rtfeldman$elm_css$Css$margin2, $rtfeldman$elm_css$Css$zero, $rtfeldman$elm_css$Css$auto),
							A2(
							$rtfeldman$elm_css$Css$padding2,
							$rtfeldman$elm_css$Css$zero,
							$rtfeldman$elm_css$Css$rem(2.66666667))
						]),
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$SE$UI$Navbar$V2$viewBrand, config, model.aw),
							$author$project$SE$UI$Navbar$V2$viewDesktopSearch(searchFn),
							A2($author$project$SE$UI$Navbar$V2$viewMainNav, config, model)
						])),
					$author$project$SE$UI$Navbar$V2$viewLED,
					A2($author$project$SE$UI$Navbar$V2$viewMegaNav, config, model)
				]));
	});
var $author$project$SE$UI$Navbar$V2$globalDropdownOverrideStyles = $author$project$SE$UI$Utils$widescreen(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Global$descendants(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Css$Global$class,
					'dropdown',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$Global$children(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$a(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$pct(100))
										])),
									$rtfeldman$elm_css$Css$Global$div(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
											$rtfeldman$elm_css$Css$paddingTop($rtfeldman$elm_css$Css$zero),
											$rtfeldman$elm_css$Css$important(
											$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background)),
											$rtfeldman$elm_css$Css$Global$children(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$Global$div(
													_List_fromArray(
														[
															$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
															$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none)
														]))
												]))
										]))
								]))
						]))
				]))
		]));
var $author$project$SE$UI$Navbar$V2$megaItemToItem = function (_v0) {
	var label = _v0.cB;
	var href = _v0.dF;
	return $author$project$SE$UI$Navbar$V2$LinkItem(
		{dF: href, bR: $elm$core$Maybe$Nothing, cB: label});
};
var $author$project$SE$UI$Navbar$V2$mobileNavStyles = _List_fromArray(
	[
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$Transitions$transition(
		_List_fromArray(
			[
				A3($rtfeldman$elm_css$Css$Transitions$transform3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut),
				A3($rtfeldman$elm_css$Css$Transitions$opacity3, 128, 0, $rtfeldman$elm_css$Css$Transitions$easeOut)
			])),
		$rtfeldman$elm_css$Css$transform(
		$rtfeldman$elm_css$Css$translateX(
			$rtfeldman$elm_css$Css$pct(100))),
		$rtfeldman$elm_css$Css$opacity($rtfeldman$elm_css$Css$zero),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-open',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$transform(
				$rtfeldman$elm_css$Css$translateX($rtfeldman$elm_css$Css$zero)),
				$rtfeldman$elm_css$Css$opacity(
				$rtfeldman$elm_css$Css$int(1)),
				$rtfeldman$elm_css$Css$height(
				A3(
					$rtfeldman$elm_css$Css$calc,
					$rtfeldman$elm_css$Css$vh(100),
					$rtfeldman$elm_css$Css$plus,
					$rtfeldman$elm_css$Css$px($author$project$SE$UI$Navbar$V2$brandHeight + $author$project$SE$UI$Navbar$V2$ledHeight))),
				$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
			])),
		$rtfeldman$elm_css$Css$marginTop(
		$rtfeldman$elm_css$Css$px(-48)),
		A5(
		$rtfeldman$elm_css$Css$boxShadow5,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$px(10),
		$rtfeldman$elm_css$Css$px(0),
		$author$project$SE$UI$Colors$toCss(
			A2(
				$author$project$SE$UI$Colors$mapAlpha,
				$elm$core$Basics$always(0.15),
				$author$project$SE$UI$Colors$black))),
		$rtfeldman$elm_css$Css$Global$descendants(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'dropdown',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
						$rtfeldman$elm_css$Css$Global$children(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Global$a(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$pct(100))
									])),
								$rtfeldman$elm_css$Css$Global$div(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$Global$withAttribute,
										'role=button',
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
											]))
									])),
								A2(
								$rtfeldman$elm_css$Css$Global$class,
								'dropdown-menu',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
										$rtfeldman$elm_css$Css$paddingTop($rtfeldman$elm_css$Css$zero),
										$rtfeldman$elm_css$Css$important(
										$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background)),
										$rtfeldman$elm_css$Css$Global$children(
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Global$typeSelector,
												'div',
												_List_fromArray(
													[
														$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$background),
														$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none)
													]))
											]))
									]))
							]))
					]))
			]))
	]);
var $author$project$SE$UI$Navbar$V2$mobileBasicItemStyles = function (isActive) {
	return _List_fromArray(
		[
			$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$text),
			$rtfeldman$elm_css$Css$fontWeight(
			$rtfeldman$elm_css$Css$int(600)),
			$author$project$SE$UI$Font$bodySizeRem(-1),
			$rtfeldman$elm_css$Css$padding(
			$rtfeldman$elm_css$Css$rem(1)),
			$rtfeldman$elm_css$Css$displayFlex,
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]);
};
var $author$project$SE$UI$Navbar$V2$viewMobileItem = $author$project$SE$UI$Navbar$V2$viewItem($author$project$SE$UI$Navbar$V2$mobileBasicItemStyles);
var $author$project$SE$UI$Navbar$V2$viewSocialMediaItem = function (item) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$color($author$project$SE$UI$Colors$darker)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$href(item.cg)
			]),
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(
				A2($author$project$SE$UI$Icon$V2$withSize, 3, item.bR))
			]));
};
var $author$project$SE$UI$Navbar$V2$viewSocialMedia = function (items) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$nav,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$padding(
				$rtfeldman$elm_css$Css$rem(1.33333333)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$flexGrow(
				$rtfeldman$elm_css$Css$num(1))
			]),
		_List_Nil,
		A2($elm$core$List$map, $author$project$SE$UI$Navbar$V2$viewSocialMediaItem, items));
};
var $author$project$SE$UI$Navbar$V2$viewMobileNav = F2(
	function (config, model) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$nav,
			$author$project$SE$UI$Navbar$V2$mobileNavStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-open', model.aw)
						]))
				]),
			$elm$core$List$singleton(
				A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$singleton,
							A3(
								$rtfeldman$elm_css$Html$Styled$styled,
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										A3(
										$rtfeldman$elm_css$Css$borderBottom3,
										$rtfeldman$elm_css$Css$px(1),
										$rtfeldman$elm_css$Css$solid,
										$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$border))
									]),
								_List_Nil)),
						function (htmls) {
							return _Utils_ap(
								htmls,
								_List_fromArray(
									[
										$author$project$SE$UI$Navbar$V2$viewSocialMedia(config.iH)
									]));
						}(
							A2(
								$elm$core$List$indexedMap,
								A2($author$project$SE$UI$Navbar$V2$viewMobileItem, config, model.ai),
								_Utils_ap(
									A2($elm$core$List$map, $author$project$SE$UI$Navbar$V2$megaItemToItem, config.hO),
									_Utils_ap(
										config.hN,
										A2($elm$core$List$map, $author$project$SE$UI$Navbar$V2$LinkItem, config.is)))))))));
	});
var $author$project$SE$UI$Navbar$V2$mobileSearchStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px($author$project$SE$UI$Navbar$V2$mobileSearchHeight))
	]);
var $author$project$SE$UI$Navbar$V2$viewMobileSearch = function (searchFn) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		$author$project$SE$UI$Navbar$V2$mobileSearchStyles,
		_List_Nil,
		_List_fromArray(
			[searchFn]));
};
var $author$project$SE$UI$Navbar$V2$viewMobile = F3(
	function (config, searchFn, model) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$author$project$SE$UI$Navbar$V2$globalDropdownOverrideStyles,
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$px(126)),
					A2(
					$rtfeldman$elm_css$Css$Global$withClass,
					'is-open',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$vh(100)),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(100))
						])),
					$author$project$SE$UI$Utils$widescreen(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('navbar-mobile', true),
							_Utils_Tuple2('is-open', model.aw)
						]))
				]),
			_List_fromArray(
				[
					A2($author$project$SE$UI$Navbar$V2$viewBrand, config, model.aw),
					$author$project$SE$UI$Navbar$V2$viewLED,
					$author$project$SE$UI$Navbar$V2$viewMobileSearch(searchFn),
					A2($author$project$SE$UI$Navbar$V2$viewMobileNav, config, model)
				]));
	});
var $author$project$SE$UI$Navbar$V2$view = F3(
	function (config, searchFn, model) {
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$header,
			$author$project$SE$UI$Navbar$V2$navContainerStyles(model.ai),
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$SE$UI$Navbar$V2$global, config, model.aw),
					A2($author$project$SE$UI$Navbar$V2$backdrop, config, model),
					A3($author$project$SE$UI$Navbar$V2$viewMobile, config, searchFn, model),
					A3($author$project$SE$UI$Navbar$V2$viewDesktop, config, searchFn, model)
				]));
	});
var $author$project$Navbar$view = F2(
	function (session, sessionMsg) {
		return A3(
			$author$project$SE$UI$Navbar$V2$view,
			A2($author$project$Navbar$navbarConfig, sessionMsg, session),
			A2(
				$author$project$Navbar$search,
				sessionMsg,
				$author$project$Session$autocomplete(session)),
			$author$project$Session$navbar(session));
	});
var $rtfeldman$elm_css$Css$columnReverse = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{iY: 'column-reverse'});
var $author$project$SE$UI$Snackbar$containerStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$zIndex(
		$rtfeldman$elm_css$Css$int(50)),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$columnReverse),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
		$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		A4(
		$rtfeldman$elm_css$Css$padding4,
		$rtfeldman$elm_css$Css$rem(1),
		$rtfeldman$elm_css$Css$rem(1),
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$rem(1)),
		$rtfeldman$elm_css$Css$Global$children(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Css$Global$class,
				'snackbar',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$rem(1))
					]))
			])),
		$author$project$SE$UI$Utils$widescreen(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$unset),
				$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
				$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset),
				A4(
				$rtfeldman$elm_css$Css$padding4,
				$rtfeldman$elm_css$Css$zero,
				$rtfeldman$elm_css$Css$rem(1),
				$rtfeldman$elm_css$Css$rem(1),
				$rtfeldman$elm_css$Css$rem(1)),
				$rtfeldman$elm_css$Css$Global$children(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$Global$class,
						'snackbar',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$unset)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]))
			]))
	]);
var $author$project$SE$UI$Snackbar$containerHtml = A2($rtfeldman$elm_css$Html$Styled$styled, $rtfeldman$elm_css$Html$Styled$div, $author$project$SE$UI$Snackbar$containerStyles);
var $author$project$SE$UI$Snackbar$Dismissed = function (a) {
	return {$: 2, a: a};
};
var $author$project$SE$UI$Snackbar$contentStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$marginLeft(
		$rtfeldman$elm_css$Css$rem(1))
	]);
var $author$project$SE$UI$Snackbar$dismissToHtml = function (msg) {
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto),
				$rtfeldman$elm_css$Css$paddingLeft(
				$rtfeldman$elm_css$Css$rem(1))
			]),
		_List_Nil,
		_List_fromArray(
			[
				A2($author$project$SE$UI$Delete$regular, _List_Nil, msg)
			]));
};
var $author$project$SE$UI$Snackbar$iconToHtml = function (_v0) {
	var icon = _v0.a;
	var color = _v0.b;
	return A4(
		$rtfeldman$elm_css$Html$Styled$styled,
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$author$project$SE$UI$Colors$backgroundColor(
				$author$project$SE$UI$Colors$toHsla(color)),
				$author$project$SE$UI$Colors$color(
				$author$project$SE$UI$Colors$invert(
					$author$project$SE$UI$Colors$toHsla(color))),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$px(36)),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$px(36)),
				$rtfeldman$elm_css$Css$borderRadius(
				$rtfeldman$elm_css$Css$pct(50)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero)
			]),
		_List_Nil,
		_List_fromArray(
			[
				$author$project$SE$UI$Icon$V2$toHtml(icon)
			]));
};
var $rtfeldman$elm_css$Css$Transitions$BorderBottom = 5;
var $rtfeldman$elm_css$Css$Transitions$borderBottom = $rtfeldman$elm_css$Css$Transitions$durationTransition(5);
var $rtfeldman$elm_css$Css$Transitions$MarginBottom = 56;
var $rtfeldman$elm_css$Css$Transitions$marginBottom = $rtfeldman$elm_css$Css$Transitions$durationTransition(56);
var $rtfeldman$elm_css$Css$Transitions$opacity = $rtfeldman$elm_css$Css$Transitions$durationTransition(73);
var $author$project$SE$UI$Snackbar$snackbarStyles = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$width(
		$rtfeldman$elm_css$Css$pct(100)),
		$author$project$SE$UI$Utils$widescreen(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$marginBottom($rtfeldman$elm_css$Css$unset),
				$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$unset)
			])),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
		$rtfeldman$elm_css$Css$padding(
		$rtfeldman$elm_css$Css$rem(1)),
		$author$project$SE$UI$Font$bodySizeEm(-2),
		$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$white),
		$rtfeldman$elm_css$Css$lineHeight(
		$rtfeldman$elm_css$Css$num(1.28571428571)),
		$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
		A4(
		$rtfeldman$elm_css$Css$boxShadow4,
		$rtfeldman$elm_css$Css$zero,
		$rtfeldman$elm_css$Css$px(4),
		$rtfeldman$elm_css$Css$px(10),
		$author$project$SE$UI$Colors$toCss(
			A2(
				$author$project$SE$UI$Colors$mapAlpha,
				$elm$core$Basics$always(0.15),
				$author$project$SE$UI$Colors$black))),
		$rtfeldman$elm_css$Css$opacity(
		$rtfeldman$elm_css$Css$int(1)),
		A3(
		$rtfeldman$elm_css$Css$borderBottom3,
		$rtfeldman$elm_css$Css$px(3),
		$rtfeldman$elm_css$Css$solid,
		$rtfeldman$elm_css$Css$transparent),
		$rtfeldman$elm_css$Css$Transitions$transition(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Transitions$borderBottom(128)
			])),
		A2(
		$rtfeldman$elm_css$Css$Global$withClass,
		'is-leaving',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Transitions$transition(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Transitions$opacity($author$project$SE$UI$Snackbar$leavingDuration),
						$rtfeldman$elm_css$Css$Transitions$marginBottom($author$project$SE$UI$Snackbar$leavingDuration)
					])),
				$rtfeldman$elm_css$Css$opacity($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$marginBottom(
				$rtfeldman$elm_css$Css$px(-64))
			])),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$borderBottomColor(
				$author$project$SE$UI$Colors$toCss($author$project$SE$UI$Colors$primary)),
				$rtfeldman$elm_css$Css$after(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$opacity(
						$rtfeldman$elm_css$Css$int(1))
					]))
			])),
		$rtfeldman$elm_css$Css$after(
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$property, 'content', '\'\''),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$top($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$zIndex(
				$rtfeldman$elm_css$Css$int(-1)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$borderRadius($author$project$SE$UI$Utils$radius),
				A2($rtfeldman$elm_css$Css$property, 'box-shadow', '0 4px 10px hsla(0, 0%, 0%, 0.15)'),
				$rtfeldman$elm_css$Css$opacity($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$Transitions$transition(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Transitions$opacity(128)
					]))
			]))
	]);
var $author$project$SE$UI$Snackbar$toHtml = F2(
	function (config, _v0) {
		var index = _v0.a;
		var internals = _v0.b;
		return A4(
			$rtfeldman$elm_css$Html$Styled$styled,
			$rtfeldman$elm_css$Html$Styled$div,
			$author$project$SE$UI$Snackbar$snackbarStyles,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('snackbar', true),
							_Utils_Tuple2('is-leaving', internals.c2 === 1)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$text(''),
					A2($elm$core$Maybe$map, $author$project$SE$UI$Snackbar$iconToHtml, internals.cz)),
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					$author$project$SE$UI$Snackbar$contentStyles,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('snackbar-content', true)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('snackbar-title', true)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(internals.cB)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('snackbar-message', true)
										]))
								]),
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Html$Styled$map, $elm$core$Basics$never, internals.fx)
								]))
						])),
					$author$project$SE$UI$Snackbar$dismissToHtml(
					config.gu(
						$author$project$SE$UI$Snackbar$Dismissed(index)))
				]));
	});
var $author$project$SE$UI$Snackbar$view = F2(
	function (config, model) {
		return A2(
			$author$project$SE$UI$Snackbar$containerHtml,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$SE$UI$Snackbar$toHtml(config),
				$elm$core$Dict$toList(model.ae)));
	});
var $author$project$Session$RemovedError = function (a) {
	return {$: 21, a: a};
};
var $author$project$Page$viewErrors = F2(
	function (sessionMsg, errors) {
		if ($elm$core$List$isEmpty(errors)) {
			return $rtfeldman$elm_css$Html$Styled$text('');
		} else {
			var notification = F2(
				function (i, e) {
					return A2(
						$author$project$SE$UI$Notification$danger,
						$elm$core$Maybe$Just(
							sessionMsg(
								$author$project$Session$RemovedError(i))),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Api$errorToHtml(e)
									]))
							]));
				});
			return A4(
				$rtfeldman$elm_css$Html$Styled$styled,
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$sticky),
						$rtfeldman$elm_css$Css$left($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$right($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$bottom($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(2147483647))
					]),
				_List_Nil,
				A2($elm$core$List$indexedMap, notification, errors));
		}
	});
var $author$project$Page$viewFrame = F3(
	function (session, sessionMsg, _v0) {
		var title = _v0.gr;
		var content = _v0.eN;
		var ui = $author$project$Session$userInterface(session);
		return {
			bI: _List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('wrapper')
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Global$global,
							$rtfeldman$elm_css$Css$Global$global(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$html(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$minHeight(
											$rtfeldman$elm_css$Css$vh(100))
										])),
									$rtfeldman$elm_css$Css$Global$body(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightBlue),
											$rtfeldman$elm_css$Css$minHeight(
											A3(
												$rtfeldman$elm_css$Css$calc,
												$rtfeldman$elm_css$Css$vh(100),
												$rtfeldman$elm_css$Css$minus,
												$rtfeldman$elm_css$Css$px(160))),
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
											$rtfeldman$elm_css$Css$Global$children(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$Global$footer(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$marginTop($rtfeldman$elm_css$Css$auto)
														]))
												]))
										]))
								])),
							A2($author$project$Navbar$view, session, sessionMsg),
							content,
							A2(
							$author$project$SE$UI$Snackbar$view,
							$author$project$Page$snackbarConfig(sessionMsg),
							ui.iG)
						])),
					A2(
					$author$project$Footer$view,
					A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$GotNewsletterMsg),
					ui.fB),
					A2($author$project$Page$viewErrors, sessionMsg, ui.hd)
				]),
			gr: title + (' - ' + $author$project$Constants$canonicalPartnerZoneName)
		};
	});
var $author$project$Page$viewFrameless = F3(
	function (session, sessionMsg, _v0) {
		var title = _v0.gr;
		var content = _v0.eN;
		var ui = $author$project$Session$userInterface(session);
		return {
			bI: _List_fromArray(
				[
					A4(
					$rtfeldman$elm_css$Html$Styled$styled,
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('wrapper')
						]),
					_List_fromArray(
						[
							$author$project$SE$UI$Global$global,
							$rtfeldman$elm_css$Css$Global$global(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$Global$body(
									_List_fromArray(
										[
											$author$project$SE$UI$Colors$backgroundColor($author$project$SE$UI$Colors$lightBlue),
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$marginTop(
												$rtfeldman$elm_css$Css$px(128))),
											$rtfeldman$elm_css$Css$minHeight(
											A3(
												$rtfeldman$elm_css$Css$calc,
												$rtfeldman$elm_css$Css$vh(100),
												$rtfeldman$elm_css$Css$minus,
												$rtfeldman$elm_css$Css$px(128))),
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
											$rtfeldman$elm_css$Css$Global$children(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$Global$footer(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$marginTop($rtfeldman$elm_css$Css$auto)
														]))
												]))
										]))
								])),
							content,
							A2($author$project$Page$viewErrors, sessionMsg, ui.hd)
						])),
					A2(
					$author$project$Footer$view,
					A2($elm$core$Basics$composeL, sessionMsg, $author$project$Session$GotNewsletterMsg),
					ui.fB)
				]),
			gr: title + (' - ' + $author$project$Constants$canonicalPartnerZoneName)
		};
	});
var $author$project$Main$view = function (model) {
	var session = $author$project$Main$toSession(model);
	var viewFramelessPage = F3(
		function (sessionMsg, toMsg, config) {
			var _v15 = A3($author$project$Page$viewFrameless, session, sessionMsg, config);
			var title = _v15.gr;
			var body = _v15.bI;
			return {
				bI: A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map(toMsg),
					body),
				gr: title
			};
		});
	var viewPage = F3(
		function (sessionMsg, toMsg, config) {
			var _v14 = A3($author$project$Page$viewFrame, session, sessionMsg, config);
			var title = _v14.gr;
			var body = _v14.bI;
			return {
				bI: A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map(toMsg),
					body),
				gr: title
			};
		});
	switch (model.$) {
		case 0:
			var apiError = model.b;
			return $author$project$Page$viewErrorFrame(apiError);
		case 1:
			return A3(
				$author$project$Page$viewFrame,
				session,
				function (_v1) {
					return $author$project$Main$Ignored;
				},
				$author$project$Page$Blank$view);
		case 2:
			return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
		case 3:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$Home$GotSessionMsg,
				$author$project$Main$GotHomeMsg,
				$author$project$Page$Home$view(subModel));
		case 21:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$Content$GotSessionMsg,
				$author$project$Main$GotContentMsg,
				$author$project$Page$Content$view(subModel));
		case 22:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$Service$GotSessionMsg,
				$author$project$Main$GotServiceMsg,
				$author$project$Page$Service$view(subModel));
		case 11:
			var subModel = model.a;
			return A3(
				viewFramelessPage,
				$author$project$Page$Login$GotSessionMsg,
				$author$project$Main$GotLoginMsg,
				$author$project$Page$Login$view(subModel));
		case 12:
			var subModel = model.a;
			return A3(
				viewFramelessPage,
				$author$project$Page$RequestNewPassword$GotSessionMsg,
				$author$project$Main$GotRequestNewPasswordMsg,
				$author$project$Page$RequestNewPassword$view(subModel));
		case 13:
			var subModel = model.a;
			return A3(
				viewFramelessPage,
				$author$project$Page$ResetPassword$GotSessionMsg,
				$author$project$Main$GotResetPasswordMsg,
				$author$project$Page$ResetPassword$view(subModel));
		case 5:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$Products$GotSessionMsg,
				$author$project$Main$GotProductsMsg,
				$author$project$Page$Products$view(subModel));
		case 4:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$MainCategory$GotSessionMsg,
				$author$project$Main$GotMainCategoryMsg,
				$author$project$Page$MainCategory$view(subModel));
		case 6:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$Product$GotSessionMsg,
				$author$project$Main$GotProductMsg,
				$author$project$Page$Product$view(subModel));
		case 7:
			var subModel = model.a;
			var _v2 = $author$project$Session$user(session);
			if (_v2.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Checkout$GotSessionMsg,
					$author$project$Main$GotCheckoutMsg,
					$author$project$Page$Checkout$view(subModel));
			}
		case 8:
			var subModel = model.a;
			var _v3 = $author$project$Session$user(session);
			if (_v3.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Orders$GotSessionMsg,
					$author$project$Main$GotOrdersMsg,
					$author$project$Page$Orders$view(subModel));
			}
		case 9:
			var subModel = model.a;
			var _v4 = $author$project$Session$user(session);
			if (_v4.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Order$GotSessionMsg,
					$author$project$Main$GotOrderMsg,
					$author$project$Page$Order$view(subModel));
			}
		case 23:
			var subModel = model.a;
			var _v5 = $author$project$Session$user(session);
			if (_v5.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Bids$GotSessionMsg,
					$author$project$Main$GotBidsMsg,
					$author$project$Page$Bids$view(subModel));
			}
		case 24:
			var subModel = model.a;
			var _v6 = $author$project$Session$user(session);
			if (_v6.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Bid$GotSessionMsg,
					$author$project$Main$GotBidMsg,
					$author$project$Page$Bid$view(subModel));
			}
		case 14:
			var subModel = model.a;
			var _v7 = $author$project$Session$user(session);
			if (_v7.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$ThankYou$GotSessionMsg,
					$author$project$Main$GotThankYouMsg,
					$author$project$Page$ThankYou$view(subModel));
			}
		case 10:
			var subModel = model.a;
			var _v8 = $author$project$Session$user(session);
			if (_v8.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Invoices$GotSessionMsg,
					$author$project$Main$GotInvoicesMsg,
					$author$project$Page$Invoices$view(subModel));
			}
		case 15:
			var subModel = model.a;
			return A3(
				viewPage,
				$author$project$Page$Brands$GotSessionMsg,
				$author$project$Main$GotBrandsMsg,
				$author$project$Page$Brands$view(subModel));
		case 16:
			var subModel = model.a;
			var _v9 = $author$project$Session$user(session);
			if (_v9.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Account$GotSessionMsg,
					$author$project$Main$GotAccountMsg,
					$author$project$Page$Account$view(subModel));
			}
		case 17:
			var subModel = model.a;
			var _v10 = $author$project$Session$user(session);
			if (_v10.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Account$Profile$GotSessionMsg,
					$author$project$Main$GotAccountProfileMsg,
					$author$project$Page$Account$Profile$view(subModel));
			}
		case 18:
			var subModel = model.a;
			var _v11 = $author$project$Session$user(session);
			if (_v11.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Account$Settings$GotSessionMsg,
					$author$project$Main$GotAccountSettingsMsg,
					$author$project$Page$Account$Settings$view(subModel));
			}
		case 19:
			var subModel = model.a;
			var _v12 = $author$project$Session$user(session);
			if (_v12.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Account$Password$GotSessionMsg,
					$author$project$Main$GotAccountPasswordMsg,
					$author$project$Page$Account$Password$view(subModel));
			}
		default:
			var subModel = model.a;
			var _v13 = $author$project$Session$user(session);
			if (_v13.$ === 1) {
				return A3(viewPage, $author$project$Page$NotFound$GotSessionMsg, $author$project$Main$GotNotFoundMsg, $author$project$Page$NotFound$view);
			} else {
				return A3(
					viewPage,
					$author$project$Page$Account$Administration$GotSessionMsg,
					$author$project$Main$GotAccountAdministrationMsg,
					$author$project$Page$Account$Administration$view(subModel));
			}
	}
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		hB: $author$project$Main$init,
		h5: $author$project$Main$ChangedUrl,
		h6: $author$project$Main$ClickedLink,
		iL: $author$project$Main$subscriptions,
		iU: $author$project$Main$update,
		iZ: A2($elm$core$Basics$composeR, $author$project$Main$view, $author$project$Main$toUnstyled)
	});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));